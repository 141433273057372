import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress, Typography, Box } from '@material-ui/core';
import { configurationSelector } from '../configuration/slice';

const useStyles = makeStyles((theme) => ({
    barDiv: {
        marginTop: "auto",
        marginBottom: "auto"
    },
    progressLine: {
        height: 10,
        borderRadius: 2,
    },
    progressNumber: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: theme.spacing(1)
    },
    progressNumberNoMargin: {
        display: "flex",
        justifyContent: "space-between",
    },
    flexCont: {
        height: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
}));


const ProgressBarOneLine = ({ total, current, measure, noMarginText, description, componentsView, percentualeOk }) => {
    const classes = useStyles();
    const { configuration } = useSelector(configurationSelector)
    return (
        <Box className={componentsView ? classes.flexCont : null}>
            <Box width="100%" mr={1} className={classes.barDiv}>
                <LinearProgress
                    className={classes.progressLine} variant="determinate" value={total > 0 ? (current / total * 100) < 100 ? (current / total * 100) : 100 : 0}
                    color={((componentsView && percentualeOk) || percentualeOk === null || percentualeOk === undefined) ? 'primary' : 'secondary'} />
            </Box>
            <div className={componentsView ? null : noMarginText ? classes.progressNumberNoMargin : classes.progressNumber}>
                <Typography variant={componentsView ? 'paragraph' : 'body2'}>
                    {description ? description : null}
                </Typography>
                <Typography variant={componentsView ? 'paragraph' : 'body2'} style={{ marginRight: componentsView ? '8px' : null }}>
                    {total > 0 ? (current / total * 100).toFixed() : 0}%
                </Typography>
                <Typography variant={componentsView ? 'paragraph' : 'body2'}>
                    {current ? Number(current.toFixed(componentsView ? configuration.componentDecimals : configuration.decimals)) : 0}/{total ? Number(total.toFixed(componentsView ? configuration.componentDecimals : configuration.decimals)) : 0} {measure}
                </Typography>
            </div>
        </Box>
    )
}

export default ProgressBarOneLine
