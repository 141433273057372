import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { customLabel } from '../../utility/customLabel';
import {
    DialogContent, FormControl, TextField, InputLabel, Select, MenuItem, Input, Tab, Tabs, FormControlLabel, Checkbox, Typography
} from "@material-ui/core";
import { mesStyles } from '../../utility/ultrafabStyles';

const ProcessForm = ({ process, handleChange, workcentres, onChangeProcess, lockProcesses, quantityAlert, bigQuantityAlert }) => {

    const myClasses = mesStyles();
    const [tabIndex, setTabIndex] = React.useState(0);
    const dispatch = useDispatch();
    const [hours, setHours] = React.useState(false);
    const [minutes, setMinutes] = React.useState(false);

    useEffect(() => {
        if (!hours && !minutes) {
            if (process.expectedSetupTime !== null) {
                setHours(Math.floor(process.expectedSetupTime / 3600))
                setMinutes(Math.floor((process.expectedSetupTime % 3600) / 60))
            } else {
                setHours("");
                setMinutes("");
            }
        }
    }, [process, hours, minutes, setHours, setMinutes]);

    //sposta il tab 
    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    };

    const renderWorkcentreMachines = () => {
        if (process.workcentre.id !== null && workcentres && workcentres.length > 0) {
            let indexW = workcentres.findIndex(w => {
                return w.id.toString() === process.workcentre.id.toString()
            })
            if (indexW !== -1) {
                return (
                    workcentres[indexW].machines.map((m) => {
                        return <MenuItem key={m.id} value={m.id}>{m.code}-{m.name}</MenuItem>
                    })
                )
            }
        } else {
            return null;
        }
    }


    const renderWorkcentres = () => {
        if (workcentres && workcentres.length > 0) {
            return (
                workcentres.map((w) => {
                    return (
                        <MenuItem key={w.id} value={w.id}>{w.code}: {w.code}-{w.name}</MenuItem>
                    )
                })
            )
        } else {
            return null;
        }
    }

    const handleChangeHours = (event) => {
        let value;
        if (event.target.value === "") {
            setHours("");
            value = (minutes * 60);
        } else {
            setHours(event.target.value);
            value = (event.target.value * 3600) + (minutes * 60);
        }
        if (event.target.value === "" && minutes === "") {
            value = null;
        }
        dispatch(onChangeProcess({ name: "expectedSetupTime", value: value }));
    }

    const handleChangeMinutes = (event) => {
        let value;
        if (event.target.value === "") {
            setMinutes("");
            value = (hours * 3600);
        } else {
            setMinutes(event.target.value);
            value = (event.target.value * 60) + (hours * 3600);
        }
        if (event.target.value === "" && hours === "") {
            value = null;
        }
        dispatch(onChangeProcess({ name: "expectedSetupTime", value: value }));
    }

    return (

        <DialogContent>
            <Tabs value={tabIndex} onChange={handleChangeTab} style={{ backgroundColor: "white", color: "black" }} variant="scrollable" scrollButtons="auto">
                <Tab label={customLabel("odp.details")} value={0} />
                <Tab label={customLabel("function.advanced")} value={1} />
            </Tabs>
            {tabIndex === 0 ? (
                <div className={myClasses.dialogForm400}>
                    <FormControl margin="normal" fullWidth disabled={(process.startDate !== null && !process.paused) || !workcentres}>
                        <InputLabel>{customLabel("workcentre.workcentre")}</InputLabel>
                        <Select name="workcentre" value={process && process.workcentre && process.workcentre.id !== null ? process.workcentre.id : ""} onChange={(e) => handleChange(e)}>
                            <MenuItem value={null}>
                                <em>{customLabel("workcentre.none")}</em>
                            </MenuItem>
                            {renderWorkcentres()}
                        </Select>
                    </FormControl>
                    <FormControl margin="normal" fullWidth disabled={(process.startDate !== null && !process.paused) || process.workcentre.id === null || !workcentres}>
                        <InputLabel>{customLabel("machine.machine")}</InputLabel>
                        <Select name="machine" value={process && process.machine && process.machine.id !== null ? process.machine.id : ""} onChange={(e) => handleChange(e)}>
                            <MenuItem value={null}>
                                <em>{customLabel("machine.none")}</em>
                            </MenuItem>
                            {process.workcentre.id !== null ? (
                                renderWorkcentreMachines()
                            ) : null}
                        </Select>
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="quantity">{customLabel("process.quantity")}</InputLabel>
                        <Input name="quantity" error={process.quantity < 0 || process.quantity === "" ? true : false} value={process.quantity}
                            onChange={handleChange} type="number" />
                    </FormControl>
                    {(bigQuantityAlert) ?
                        <Typography component="h3" variant="h6" className={myClasses.alertMessage}>Attenzione, quantità elevata con variabili seriali!</Typography> : null
                    }
                    {(quantityAlert) ?
                        <Typography component="h3" variant="h6" className={myClasses.alertMessage}>Modificando la quantità verranno ripristinate tutte le variabili seriali e quelle inserite manualmente verranno eliminate!</Typography> : null
                    }
                    <FormControl margin="normal" fullWidth disabled={process.endDate !== null}>
                        <InputLabel htmlFor="priority">{customLabel("odp.priority")}</InputLabel>
                        <Select name="priority" value={process.priority !== null ? process.priority : 9999999} onChange={handleChange}>
                            <MenuItem value={9999999}>{customLabel("odp.priorityNotSet")}</MenuItem>
                            <MenuItem value={0}>1</MenuItem>
                            <MenuItem value={1}>2</MenuItem>
                            <MenuItem value={2}>3</MenuItem>
                            <MenuItem value={3}>4</MenuItem>
                            <MenuItem value={4}>5</MenuItem>
                            <MenuItem value={5}>6</MenuItem>
                            <MenuItem value={6}>7</MenuItem>
                            <MenuItem value={7}>8</MenuItem>
                            <MenuItem value={8}>9</MenuItem>
                            <MenuItem value={9}>10</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="quantity">{customLabel("process.theoreticalCycleTime")}</InputLabel>
                        <Input name="cycleTimeEdit" error={process.cycleTimeEdit < 0 || process.cycleTimeEdit === "" ? true : false} value={process.cycleTimeEdit}
                            disabled={process.startDate === null || process.endDate !== null} onChange={handleChange} type="number" />
                    </FormControl>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <FormControl margin="normal" style={{ width: "30%" }}>
                            <InputLabel htmlFor="hours">{customLabel("process.expectedSetupTimeHH")}</InputLabel>
                            <Input name="hours" value={hours} onChange={handleChangeHours} type="number" error={hours < 0}
                            />
                        </FormControl>
                        <FormControl margin="normal" style={{ width: "30%", marginLeft: "8px" }}>
                            <InputLabel htmlFor="minutes">{customLabel("process.expectedSetupTimeMM")}</InputLabel>
                            <Input name="minutes" value={minutes} onChange={handleChangeMinutes} type="number" error={minutes < 0}
                            />
                        </FormControl>
                        <FormControl margin="normal" style={{ width: "30%", marginLeft: "8px" }}>
                            <InputLabel htmlFor="expectedSetupTime">{customLabel("process.expectedSetupTimeSS")}</InputLabel>
                            <Input name="expectedSetupTime" value={process.expectedSetupTime !== null ? process.expectedSetupTime : ""} disabled type="number"
                            />
                        </FormControl>
                    </div>
                    <FormControl margin="normal" fullWidth>
                        <TextField
                            variant="outlined" name="note" label={customLabel("function.note")} multiline rows={2} value={process && process.note ? process.note : ""}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <TextField
                            variant="outlined" name="note2" label={customLabel("function.operatorViewNote")} multiline rows={2} value={process && process.note2 ? process.note2 : ""}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <TextField
                            variant="outlined" name="note3" label={customLabel("function.odpListNote")} multiline rows={2} value={process && process.note3 ? process.note3 : ""}
                            onChange={handleChange}
                        />
                    </FormControl>
                </div>
            ) : tabIndex === 1 ? (
                <div className={myClasses.dialogForm400}>
                    {lockProcesses ?
                        <FormControl margin="normal" fullWidth>
                            <InputLabel htmlFor="nextMode">{customLabel("bom.bomProcess.nextMode")}</InputLabel>
                            <Select name="nextMode" value={process.nextMode} onChange={handleChange}>
                                <MenuItem value="">{customLabel("function.none")}</MenuItem>
                                <MenuItem value="open">{customLabel("bom.bomProcess.open")}</MenuItem>
                                <MenuItem value="close">{customLabel("bom.bomProcess.close")}</MenuItem>
                            </Select>
                        </FormControl>
                        : null}
                    <FormControlLabel className={myClasses.formLabelBlock}
                        control={<Checkbox checked={process.external} onChange={handleChange} name="external" color="default" disabled={process.startDate !== null} />}
                        label={customLabel("processType.external")}
                    />
                    <FormControlLabel className={myClasses.formLabelBlock}
                        control={<Checkbox checked={process.repeatable} onChange={handleChange} name="repeatable" color="default" />}
                        label={customLabel("processType.repeatable")}
                    />
                    <FormControlLabel className={myClasses.formLabelBlock}
                        control={<Checkbox checked={process.optional} onChange={handleChange} name="optional" color="default" />}
                        label={customLabel("processType.optional")}
                    />
                </div>
            ) : null}
        </DialogContent>
    )
}

export default ProcessForm;