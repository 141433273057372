import React from "react";
import { Link } from "react-router-dom";
import { ChromePicker } from "react-color";
import { Grid, FormControl, FormLabel, FormGroup, InputLabel, Input, Button, Typography, Checkbox, FormControlLabel } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";

export const Form = ({ userEventedit, handleChange, cancelEdit, save, roles, loading }) => {
  const myClasses = mesStyles();

  const handleChangeComplete = (color) => {
    //active color
    let c = "rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + ",1)";
    let e = { preventDefault: function () { }, persist: function () { } };
    let t = { name: "backgroundColor", value: c };
    //disactive color
    let c2 = "rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + ",0.4)";
    let e2 = { preventDefault: function () { }, persist: function () { } };
    let t2 = { name: "disactiveBackgroundColor", value: c2 };
    e["target"] = t;
    e2["target"] = t2
    handleChange(e);
    handleChange(e2)
  };
  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h3" variant="h6" color="inherit">{customLabel("userEvent.userEvent")}</Typography>
        <LockOpenIcon />
      </div>
      <Grid spacing={1} container>
        <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
          <form className={myClasses.formContainer} noValidate autoComplete="off">
            <FormControl className={myClasses.form50}>
              <InputLabel htmlFor="name">{customLabel("userEvent.name")}</InputLabel>
              <Input autoFocus name="name" error={userEventedit.name === ""} value={userEventedit.name} onChange={handleChange} />
            </FormControl>
            <div>
              <InputLabel htmlFor="userRoles">{customLabel("user.roles")}</InputLabel>
              <FormControl>
                <FormLabel component="legend">
                  {roles !== false ? (
                    <FormGroup>
                      {roles.map((role) => {
                        let checked = userEventedit.roles.findIndex((r) => {
                          return r.id === role.id;
                        });
                        return (<FormControlLabel key={role.id} control={<Checkbox color="primary" checked={checked > -1} name="roles" onChange={handleChange} value={role.id} />} label={role.authority} />);
                      })}
                    </FormGroup>
                  ) : null}
                </FormLabel>
              </FormControl>
            </div>
          </form>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className={myClasses.formContainer}>
            <FormControl margin="normal" >
              <InputLabel htmlFor="backgroundColor">{customLabel("userEvent.backgroundColor")}</InputLabel>
              <Input name="backgroundColor" value={userEventedit.backgroundColor} error={userEventedit.backgroundColor === ""} onChange={handleChange} />
            </FormControl>
            <ChromePicker color={userEventedit.backgroundColor !== null && userEventedit.backgroundColor !== false ? userEventedit.backgroundColor : "rgba(0,0,0)"} onChangeComplete={(color) => handleChangeComplete(color)} />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className={myClasses.formContainer}>
            <FormControl margin="normal" >
              <InputLabel htmlFor="disactiveBackgroundColor">{customLabel("userEvent.disactiveBackgroundColor")}</InputLabel>
              <Input name="disactiveBackgroundColor" value={userEventedit.disactiveBackgroundColor}
                error={userEventedit.disactiveBackgroundColor === ""} onChange={handleChange} />
            </FormControl>
            <ChromePicker color={userEventedit.disactiveBackgroundColor !== null && userEventedit.disactiveBackgroundColor !== false ? userEventedit.disactiveBackgroundColor : "rgba(0,0,0)"}
              onChangeComplete={(color) => handleChangeComplete(color)} />
          </div>
        </Grid>
      </Grid>
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple onClick={save}
          disabled={loading || userEventedit.name === "" || userEventedit.backgroundColor === "" || userEventedit.disactiveBackgroundColor === ""
            || userEventedit.backgroundColor === null || userEventedit.backgroundColor === false || userEventedit.disactiveBackgroundColor === null || userEventedit.disactiveBackgroundColor === false}
          color="primary">{customLabel("button.save")}</Button>
        {userEventedit.id !== "new" ? (
          <Button focusRipple onClick={cancelEdit} disabled={loading}>{customLabel("button.cancel")}</Button>
        ) : (
          <Button component={Link} to={`/admin/userEvents`} disabled={loading}>{customLabel("button.back")}</Button>
        )}
      </div>
    </PaperForm >
  );
};
