import React from "react";
import {
  Typography, Table, TableCell, TableRow, TableBody, Button, TableHead
} from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import CheckIcon from "@material-ui/icons/Check";
import { mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";

export const Details = ({ machine, edit, toggleDelete, deleting, doDelete, select, loading }) => {
  const myClasses = mesStyles();
  return (
    <React.Fragment>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">
          {customLabel("machine.machine")}
        </Typography>
      </div>
      <div className={myClasses.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell >{customLabel("machine.code")}</TableCell>
              <TableCell>{customLabel("machine.name")}</TableCell>
              <TableCell align="right">{customLabel("machine.external")}</TableCell>
              <TableCell align="right">{customLabel("machine.closeUserEventOnPause")}</TableCell>
              <TableCell align="right">{customLabel("machine.stapling")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{machine.code}</TableCell>
              <TableCell>{machine.name}</TableCell>
              <TableCell align="right">
                {machine.external ? (
                  <CheckIcon />
                ) : null}
              </TableCell>
              <TableCell align="right">
                {machine.closeUserEvent ? (
                  <CheckIcon />
                ) : null}
              </TableCell>
              <TableCell align="right">
                {machine.stapling ? (
                  <CheckIcon />
                ) : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className={myClasses.buttonsFormUpdate}>
          <Button focusRipple key={machine.id} onClick={edit} color="primary" disabled={loading}>
            {customLabel("button.edit")}
          </Button>
          <Button color="secondary" onClick={toggleDelete} disabled={loading}>
            {customLabel("button.delete")}
          </Button>
          <Button color="default" onClick={() => select(false)} disabled={loading}>
            {customLabel("button.back")}
          </Button>
        </div>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={toggleDelete} disabled={loading} />
    </React.Fragment>
  );
};
