import React, { useEffect } from 'react';
import {
    Table, TableBody, Paper, Button, Dialog, DialogTitle,
    DialogActions, DialogContent, Typography
} from '@material-ui/core';
import { customLabel } from '../../utility/customLabel';
import { odpsSelector } from '../slice';
import { useSelector, useDispatch } from 'react-redux';
import { mesStyles } from "../../utility/ultrafabStyles";
import {
    checksSelector, getListChecksByNameAPI, setCheckToSee, setReload as setChecksReload, changeFilter as changeChecksFilter,
    resetState as resetStateChecks
} from '../../checks/slice';
import TableChecksDetails from '../../checks/TableChecksDetails';
import { renderChecksByType } from "../../checks/utility";
import SimpleTableHeader from '../../utility/SimpleTableHeader';
import { configurationSelector } from '../../configuration/slice';

const Checks = () => {
    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { odp, staplingChecks } = useSelector(odpsSelector);
    const { checkList, checkToSee, results: checksResults, filter: checksFilter, reload: checksReload, loading: loadingChecks } = useSelector(checksSelector);
    const { configuration } = useSelector(configurationSelector);

    useEffect(() => {
        if (checksReload && checkToSee) {
            dispatch(getListChecksByNameAPI(checksFilter));
        }
    }, [dispatch, checksFilter, checksReload, checkToSee])

    /**
     * 
     * @param {*} odpId 
     * @param {*} editProcessId 
     * @param {*} currentCheck 
     * @param {*} stapling 
     */
    const setChecksDetails = (odpId, editProcessId, currentCheck, stapling) => {
        dispatch(setCheckToSee({ check: currentCheck, odpId: odpId, processId: editProcessId, stapling: stapling }));
        dispatch(setChecksReload());
    };

    const unSetChecksDetails = () => {
        dispatch(resetStateChecks());
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(changeChecksFilter({ name: "rowsPerPage", value: parseInt(event.target.value, 10) }))
        dispatch(changeChecksFilter({ name: "page", value: 0 }))
        dispatch(setChecksReload());
    };
    const handleChangePage = (event, newPage, doReload) => {
        dispatch(changeChecksFilter({ name: "page", value: newPage }))
        if (doReload) {
            dispatch(setChecksReload());
        }
    };

    return (
        <React.Fragment>
            <Paper className={myClasses.processDetailsPaper}>
                <Typography component="h6" variant="h6" color="inherit" className={myClasses.titleTableTypography}>{customLabel("process.processChecks")}</Typography>
                <Table size="small" stickyHeader>
                    <SimpleTableHeader list={[customLabel("processType.check.type").toUpperCase(), customLabel("function.name"), customLabel("function.value"),
                    customLabel("function.date"), customLabel("function.operator").toUpperCase(), customLabel("function.note").toUpperCase(), customLabel("function.actions").toUpperCase()]} />
                    <TableBody>
                        {renderChecksByType(odp, true, false, staplingChecks ? staplingChecks.filter((l) => l.start) : [], customLabel("processType.check.start").toUpperCase(), true, false, setChecksDetails, false, configuration.decimals)}
                        {renderChecksByType(odp, true, false, staplingChecks ? staplingChecks.filter((l) => l.pause) : [], customLabel("processType.check.pause").toUpperCase(), true, false, setChecksDetails, false, configuration.decimals)}
                        {renderChecksByType(odp, true, false, staplingChecks ? staplingChecks.filter((l) => l.restart) : [], customLabel("processType.check.restart").toUpperCase(), true, false, setChecksDetails, false, configuration.decimals)}
                        {renderChecksByType(odp, true, false, staplingChecks ? staplingChecks.filter((l) => l.close) : [], customLabel("processType.check.close").toUpperCase(), true, false, setChecksDetails, false, configuration.decimals)}
                        {renderChecksByType(odp, true, false, staplingChecks ? staplingChecks.filter((l) => l.onDemand) : [], customLabel("processType.check.onDemand").toUpperCase(), true, false, setChecksDetails, false, configuration.decimals)}
                    </TableBody>
                </Table>
            </Paper>
            {/*Dialog visualizzare lo storico di un controllo*/}
            <Dialog open={checkToSee ? true : false} keepMounted maxWidth={"md"} fullWidth={true}>
                <DialogTitle>
                    {customLabel("function.historicalCheck")}: {checkToSee.name}
                </DialogTitle>
                <DialogContent>
                    <TableChecksDetails checkList={checkList} page={checksFilter.page} setPage={handleChangePage}
                        rowsPerPage={checksFilter.rowsPerPage} setRowsPerPage={handleChangeRowsPerPage} results={checksResults}
                        showOperator={true} decimals={configuration.decimals} loading={loadingChecks} />
                </DialogContent>
                <DialogActions>
                    <Button disabled={loadingChecks} onClick={unSetChecksDetails} color="default">{customLabel("button.close")}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Checks
