import { Button, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from "moment";
import React from 'react';
import { bishopFormats } from '../../constants';
import ProgressBar from "../utility/ProgressBarOneLine";
import { customLabel } from "../utility/customLabel";

/**
 * Restituisce tabella con elenco componenti: consumi e scarti
 * @param {array} components 
 * @param {function} declareNewComp 
 * @param {function} declareEditComponent 
 * @param {function} finishDeclaration se diverso da null mostra elenco per inserimento del controllo, altrimenti lista normale
 * @param {boolean} loading
 * @param {object} currentValue
 * @returns 
 */
export const renderComponents = (components, declareNewComp, declareEditComponent, finishDeclaration, endDeclaration, loading, currentValue, configuration, componentDetails, handleToggleDetails, operatorView, lotInfo) => {
    if (components && components.length > 0) {
        return (
            <React.Fragment>
                <div style={finishDeclaration !== null ? { maxHeight: "450px", overflow: "auto" } : null}>
                    {components.map((c, index) => {
                        let hasConsumption = c.consumptions && c.consumptions.length > 0
                        let hasWaste = c.wastes && c.wastes.length > 0
                        let totalWastes = null
                        let lotRemainingQuantity = 0
                        let currentLot = hasConsumption ? c.consumptions[0]?.lot : null
                        if(lotInfo && lotInfo[currentLot]){
                            lotRemainingQuantity = Number(lotInfo[currentLot].toFixed(configuration.componentDecimals))
                        }
                        if(hasWaste){
                            totalWastes = c.wastes.reduce((tot, waste) => tot + waste.quantity, 0)
                        }
                        return (
                            <React.Fragment key={c.id}>
                                <Grid container style={{ padding: '8px 12px' }} alignItems="center">
                                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                                        <span>{customLabel("component.code")}: <b>{c.code}</b></span>
                                        <span>{customLabel("component.name")}: <b>{c.name}</b></span>
                                        {currentLot ? <span>{customLabel("component.lot")}: <b>{currentLot} {configuration.showLotRemainingQuantity ? "(Qta: " + lotRemainingQuantity + ")" : null}</b></span> : null}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={5} xl={5} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <div style={{ minWidth: '150px' }}>
                                            <ProgressBar
                                                total={c?.expectedQuantity} current={c?.consumptions.reduce((acc, obj) => acc + obj.quantity, 0)}
                                                measure={null} componentsView
                                                percentualeOk={configuration?.componentPercentualeOk !== null ? Number((c?.consumptions.reduce((acc, obj) => acc + obj.quantity, 0) / c?.expectedQuantity * 100).toFixed()) > configuration?.componentPercentualeOk : null}
                                            />
                                        </div>
                                        {totalWastes ? <span>{customLabel("component.wastes")}: {totalWastes}</span> : null}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '6px' }}>
                                            <Button color="primary" disabled={loading} fullWidth size="small" variant="contained" onClick={() => declareNewComp(c.id, false, false)}>
                                                {customLabel("button.declare")}
                                            </Button>
                                            <Button color="primary" disabled={loading} fullWidth size="small" variant="contained" onClick={() => declareNewComp(c.id, true, false)}>
                                                {customLabel("button.discard")}
                                            </Button>
                                            {configuration.changeComponent && (
                                                <Button color="primary" disabled={loading} fullWidth size="small" variant="contained" onClick={() => declareNewComp(c.id, false, true)}>
                                                    {customLabel("button.substitute")}
                                                </Button>
                                            )}
                                            {componentDetails !== undefined && (
                                                <Button color="default" startIcon={<VisibilityIcon />} disabled={loading} fullWidth size="small" variant="contained" onClick={() => handleToggleDetails(c.id)}>
                                                    {customLabel("button.details")}
                                                </Button>
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Dialog
                                    open={componentDetails !== undefined && componentDetails === c.id} keepMounted maxWidth={"md"} fullWidth>
                                    <DialogContent>
                                        {renderComponentDetails(c, declareEditComponent, loading, configuration, operatorView, declareNewComp)}
                                    </DialogContent>
                                    <DialogActions style={{ padding: '16px 24px' }}>
                                        <Button onClick={() => handleToggleDetails(c.id)} variant="contained" color="secondary" disabled={loading} style={{ padding: '16px' }}>
                                            <span style={{ fontSize: '20px' }}>{customLabel("button.close")}</span>
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </React.Fragment>
                        )
                    })
                    }
                </div >
                {finishDeclaration !== null ? (
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "16px" }}>
                        <Button color="primary" disabled={loading || endDeclaration || currentValue} variant="contained" onClick={() => finishDeclaration()}>
                            {customLabel("button.finishDeclaration")}
                        </Button>
                    </div>

                ) : null}
            </React.Fragment >
        )
    } else {
        return (
            <Table>
                <tbody>
                    <TableRow>
                        <TableCell>{customLabel("function.notAvailable").toUpperCase()}!</TableCell>
                    </TableRow>
                </tbody>
            </Table>
        )
    }
}

export const renderOdpComponents = (components, loading, configuration, componentDetails, handleToggleDetails, operatorView, lotInfo) => {
    if (components && components.length > 0) {
        return (
            components.map((c, index) => {
                let hasConsumption = c.consumptions && c.consumptions.length > 0
                let hasWaste = c.wastes && c.wastes.length > 0
                let totalWastes = null
                let lotRemainingQuantity = 0
                let currentLot = hasConsumption ? c.consumptions[0]?.lot : null
                if(lotInfo && lotInfo[currentLot]){
                    lotRemainingQuantity = Number(lotInfo[currentLot].toFixed(configuration.componentDecimals))
                }
                if (hasWaste) {
                    totalWastes = c.wastes.reduce((tot, waste) => tot + waste.quantity, 0)
                }
                return (
                    <TableRow key={c.id}>
                        <TableCell style={{ maxWidth: '30%' }}>{c.code}</TableCell>
                        <TableCell style={{ width: '30%' }}>{c.name}</TableCell>
                        <TableCell style={{ width: '15%' }}>
                            {currentLot && (
                                <>
                                    {currentLot}
                                    {configuration.showLotRemainingQuantity && ` (Qta: ${Number(lotRemainingQuantity.toFixed(configuration.componentDecimals))})`}
                                </>
                            )}
                        </TableCell>
                        <TableCell style={{ width: '15%' }}>
                            <ProgressBar
                                total={c?.expectedQuantity} current={c?.consumptions.reduce((acc, obj) => acc + obj.quantity, 0)}
                                measure={null} componentsView
                                percentualeOk={configuration?.componentPercentualeOk !== null ? Number((c?.consumptions.reduce((acc, obj) => acc + obj.quantity, 0) / c?.expectedQuantity * 100).toFixed()) > configuration?.componentPercentualeOk : null}
                            />
                        </TableCell>
                        <TableCell style={{ width: '5%' }} align='center'>{totalWastes && totalWastes}</TableCell>
                        <TableCell style={{ width: '5%' }} align='center'>
                            {componentDetails !== undefined && (
                                <IconButton size="small" color="primary" onClick={() => handleToggleDetails(c.id)}>
                                    <VisibilityIcon />
                                </IconButton>
                            )}
                        </TableCell>
                        <Dialog
                            open={componentDetails !== undefined && componentDetails === c.id} keepMounted maxWidth={"md"} fullWidth>
                            <DialogContent>
                                {renderComponentDetails(c, false, loading, configuration, operatorView)}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleToggleDetails(c.id)} color="default" disabled={loading}>{customLabel("button.close")}</Button>
                            </DialogActions>
                        </Dialog>
                    </TableRow>
                )
            })
        )
    } else {
        return (
            <TableRow>
                <TableCell colSpan={6} size="small">{customLabel("function.notAvailable").toUpperCase()}</TableCell>
            </TableRow>
        )
    }
}

export const renderComponentDetails = (component, onClickEdit, loading, configuration, operatorView, declareNewComp) => {
    if (component) {
        return (
            <>
                <Typography variant="h6" gutterBottom>
                    {customLabel("component.consumptions")}
                </Typography>
                <Table size="small" style={{ marginBottom: '20px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ wordWrap: 'break-word', maxWidth: "100px", minWidth: "100px" }}>{customLabel("component.lot")}</TableCell>
                            <TableCell style={{ wordWrap: 'break-word', maxWidth: "75px", minWidth: "75px" }}>{customLabel("function.qta")}</TableCell>
                            <TableCell style={{ wordWrap: 'break-word', maxWidth: "100px", minWidth: "100px" }}>{customLabel("function.startDate")}</TableCell>
                            <TableCell style={{ wordWrap: 'break-word', maxWidth: "100px", minWidth: "100px" }}>{customLabel("function.endDate")}</TableCell>
                            {operatorView ? <TableCell style={{ wordWrap: 'break-word', maxWidth: "75px", minWidth: "75px" }}></TableCell> : null}
                            {configuration.editComponent && operatorView ? <TableCell style={{ wordWrap: 'break-word', maxWidth: "50px", minWidth: "50px" }}></TableCell> : null}

                        </TableRow>
                    </TableHead>
                    {component.consumptions && component.consumptions.map((c) => (
                        <TableBody>
                            <TableRow key={c.id + "_" + component.id}>
                                <TableCell style={{ wordWrap: 'break-word', maxWidth: "100px", minWidth: "100px" }}>{c.lot}</TableCell>
                                <TableCell style={{ wordWrap: 'break-word', maxWidth: "75px", minWidth: "75px" }}>{Number(c.quantity.toFixed(configuration?.componentDecimals))}</TableCell>
                                <TableCell style={{ wordWrap: 'break-word', maxWidth: "100px", minWidth: "100px" }}>{c.startDate ? moment(c.startDate).format(bishopFormats.LTS) : null}</TableCell>
                                <TableCell style={{ wordWrap: 'break-word', maxWidth: "100px", minWidth: "100px" }}>{c.endDate ? moment(c.endDate).format(bishopFormats.LTS) : null}</TableCell>
                                {operatorView ? (
                                    <TableCell style={{ wordWrap: 'break-word', maxWidth: "75px", minWidth: "75px" }}>
                                        <Button color="primary" disabled={loading || c.quantity === 0} fullWidth size="small" variant="outlined" onClick={() => declareNewComp(component.id, false, false, Number(c.quantity.toFixed(configuration?.componentDecimals)), c.lot)}>
                                            {customLabel("component.reverse")}
                                        </Button>
                                    </TableCell>
                                ) : null}
                                {configuration.editComponent && operatorView ?
                                    <TableCell style={{ wordWrap: 'break-word', maxWidth: "50px", minWidth: "50px" }}>
                                        <IconButton disabled={loading} size="small" color="primary" variant="contained" onClick={() => onClickEdit(component.id, c.id)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell> : null}
                            </TableRow>
                        </TableBody>
                    ))}
                </Table>
                <Typography variant="h6" gutterBottom>
                    {customLabel("component.wastes")}
                </Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ wordWrap: 'break-word', maxWidth: "100px", minWidth: "100px" }}>{customLabel("component.lot")}</TableCell>
                            <TableCell style={{ wordWrap: 'break-word', maxWidth: "75px", minWidth: "75px" }}>{customLabel("function.qta")}</TableCell>
                            <TableCell style={{ wordWrap: 'break-word', maxWidth: "100px", minWidth: "100px" }}>{customLabel("component.scrapReason")}</TableCell>
                            <TableCell style={{ wordWrap: 'break-word', maxWidth: "100px", minWidth: "100px" }}>{customLabel("component.dateWaste")}</TableCell>
                            {operatorView ? <TableCell style={{ wordWrap: 'break-word', maxWidth: "75px", minWidth: "75px" }}></TableCell> : null}
                            {configuration.editComponent && operatorView ? <TableCell style={{ maxWidth: "50px", minWidth: "50px" }}></TableCell> : null}
                        </TableRow>
                    </TableHead>
                    {component.wastes && component.wastes.map((w) => (
                        <TableBody>
                            <TableRow key={w.id + "_" + component.id}>
                                <TableCell style={{ wordWrap: 'break-word', maxWidth: "100px", minWidth: "100px" }}>{w.lot}</TableCell>
                                <TableCell style={{ wordWrap: 'break-word', maxWidth: "75px", minWidth: "75px" }}>{Number(w.quantity.toFixed(configuration?.componentDecimals))}</TableCell>
                                <TableCell style={{ wordWrap: 'break-word', maxWidth: "100px", minWidth: "100px" }}>{w.reason}</TableCell>
                                <TableCell style={{ wordWrap: 'break-word', maxWidth: "100px", minWidth: "100px" }}>{w.creationDate ? moment(w.creationDate).format(bishopFormats.LTS) : null}</TableCell>
                                {operatorView ? (
                                    <TableCell style={{ wordWrap: 'break-word', maxWidth: "75px", minWidth: "75px" }}>
                                        <Button color="primary" disabled={loading || w.quantity === 0} fullWidth size="small" variant="outlined" onClick={() => declareNewComp(component.id, true, false, Number(w.quantity.toFixed(configuration?.componentDecimals)), w.lot, w.reason)}>
                                            {customLabel("component.reverse")}
                                        </Button>
                                    </TableCell>
                                ) : null}
                                {configuration.editComponent && operatorView ? <TableCell style={{ maxWidth: "50px", minWidth: "50px" }}></TableCell> : null}
                            </TableRow>
                        </TableBody>
                    ))}
                </Table>
            </>
        )
    }
}