import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import {
    Table, TableHead, TableCell, TableRow, TableBody, Button, TextField, IconButton
} from "@material-ui/core";
import { maintenerViewSelector, getUserOdpsAPI, startAPI, initFilter, changeFilter, setReload } from "./slice";
import moment from "moment";
import { bishopFormats } from '../../constants';
import { makeStyles } from "@material-ui/core/styles";
import PaperAutocomplete from "../utility/PaperAutocomplete";
import { Autocomplete } from "@material-ui/lab";
import { maintenanceTypesSelector, getListAPI as getMaintenanceTypes } from "../maintenanceTypes/slice";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    buttonNM: {
        marginLeft: theme.spacing(),
        padding: theme.spacing(),
        fontSize: "15pt !important"
    }
}));

const Ordinary = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const { maintener, odps, filter, reload } = useSelector(maintenerViewSelector);
    const { maintenanceTypes } = useSelector(maintenanceTypesSelector);

    useEffect(() => {
        dispatch(getUserOdpsAPI(maintener, true, false));
        dispatch(getMaintenanceTypes(0, 99999, "name", "asc", false, false, false, false));
    }, [dispatch, maintener]);

    useEffect(() => {
        dispatch(setReload());
    }, [dispatch, filter]);

    useEffect(() => {
        if (filter && reload) {
            dispatch(getUserOdpsAPI(maintener, true, false, filter));
        }
    }, [dispatch, maintener, filter, reload])

    useEffect(() => {
        dispatch(initFilter(true))
    }, [dispatch])

    const onChangeFilterAutocomplete = (event, value, reason, name) => {
        if (reason === "clear") {
            changeFilters(name, false)
        } else {
            changeFilters(name, value)
        }
    }

    const handleChangeDate = (name, value) => {
        if (name.endsWith("From")) {
            const newValue = value.startOf('day')
            dispatch(changeFilter({ name: name, value: new Date(newValue.format()).getTime() }))
        }
        else if (name.endsWith("To")) {
            const newValue = value.endOf('day')
            dispatch(changeFilter({ name: name, value: new Date(newValue.format()).getTime() }))
        }
    }

    const handleResetDate = (e, name) => {
        e.stopPropagation();
        dispatch(changeFilter({ name: name, value: false }))
    }

    const changeFilters = (name, value) => {
        dispatch(changeFilter({ name: name, value: value }))
    }

    const machines = odps ? Object.values(odps.reduce((acc, obj) => {
        if (!acc[obj.machine.code]) {
            acc[obj.machine.code] = obj.machine
        }
        return acc;
    }, {})) : []

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{customLabel("maintenance.machine")}</TableCell>
                        <TableCell>{customLabel("maintenance.maintenanceType")}</TableCell>
                        <TableCell>{customLabel("maintenancePhase.maintenancePhase")}</TableCell>
                        <TableCell>{customLabel("maintenance.deliveryDate")}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    {filter.showFilter && odps ? (
                        <TableRow>
                            <TableCell>
                                <Autocomplete
                                    PaperComponent={PaperAutocomplete}
                                    options={machines} filterSelectedOptions size="small"
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(event, value, reason) => onChangeFilterAutocomplete(event, value, reason, "machine")}
                                    getOptionLabel={(option) => option.code}
                                    renderInput={(params) => (<TextField {...params} placeholder={customLabel("machine.machine")} />)}
                                    value={filter.machine ? filter.machine : null} />
                            </TableCell>
                            <TableCell>
                                {maintenanceTypes ? (
                                    <Autocomplete
                                        PaperComponent={PaperAutocomplete}
                                        options={maintenanceTypes} filterSelectedOptions size="small"
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        onChange={(event, value, reason) => onChangeFilterAutocomplete(event, value, reason, "maintenanceType")}
                                        getOptionLabel={(option) => option.extraordinary ? customLabel("maintenance.extraordinary").toUpperCase() : option.name}
                                        renderInput={(params) => (<TextField {...params} placeholder={customLabel("maintenanceType.maintenanceTypes")} />)}
                                        value={filter.maintenanceType ? filter.maintenanceType : null}
                                    />
                                ) : null}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <DatePicker
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton size="small" onClick={(e) => handleResetDate(e, "deliveryDateFrom")}>
                                                    <CloseIcon />
                                                </IconButton>
                                            )
                                        }}
                                        disableToolbar
                                        allowKeyboardControl={false}
                                        variant="inline"
                                        format={bishopFormats.L}
                                        autoOk
                                        onChange={(date) => handleChangeDate("deliveryDateFrom", date)}
                                        label={customLabel("function.from")}
                                        value={filter.deliveryDateFrom ? filter.deliveryDateFrom : null}
                                    />
                                </MuiPickersUtilsProvider>
                                <br />
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <DatePicker
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton size="small" onClick={(e) => handleResetDate(e, "deliveryDateTo")}>
                                                    <CloseIcon />
                                                </IconButton>
                                            )
                                        }}
                                        disableToolbar
                                        allowKeyboardControl={false}
                                        variant="inline"
                                        format={bishopFormats.L}
                                        autoOk
                                        onChange={(date) => handleChangeDate("deliveryDateTo", date)}
                                        label={customLabel("function.to")}
                                        value={filter.deliveryDateTo ? filter.deliveryDateTo : null}
                                    />
                                </MuiPickersUtilsProvider>
                            </TableCell>
                        </TableRow>
                    ) : null}
                </TableHead>
                {odps ? (
                    <TableBody>
                        {odps.map((o) => {
                            if (o.endDate === null) {
                                return (
                                    <TableRow key={o.id}>
                                        <TableCell>{o.machine.code} - {o.machine.name}</TableCell>
                                        <TableCell>{o.odp.maintenanceType.name}</TableCell>
                                        <TableCell>{o.type.name}</TableCell>
                                        <TableCell>{moment(o.deliveryDate).format(bishopFormats.L)}</TableCell>
                                        <TableCell align={"right"}>
                                            <Button onClick={() => dispatch(startAPI(o, maintener, false))} className={classes.buttonNM} variant="contained" color="primary">{customLabel("button.start")}</Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            return null;
                        })}
                    </TableBody>
                ) : null}
            </Table>
        </div>
    )
}

export default Ordinary;