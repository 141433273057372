/**
 * Oggetto contenente le chiavi (con i valori di default) da inserire nel local storage per il salvataggio dei filtri dello stato commesse
 */
export const maintenanceStatusFilter = {
    mesName: "maintenanceStatusFilter", //serve solamente per capire di che filtro si tratta, non usato per i filtri
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "p.odp.maintenanceType.name",
    deliveryDateFrom: false,
    deliveryDateTo: false,
    status: false,
    maintenanceType: false,
    machine: false,
    workcentre: false,
    visible: false
};
export const odpStatusFilter = {
    mesName: "odpStatusFilter", //serve solamente per capire di che filtro si tratta, non usato per i filtri
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "p.odp.code",
    code: false,
    customerOrder: false,
    customer: false,
    deliveryDateFrom: false,
    deliveryDateTo: false,
    status: false,
    currentProcessType: false,
    machineEvent: false,
    machine: false,
    workcentre: false,
    nextProcessType: false,
    visible: false
};
export const odpMachineEventsFilter = {
    mesName: "odpMachineEventsFilter", //serve solamente per capire di che filtro si tratta, non usato per i filtri
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "startDate",
    type: false,
    machine: false,
    user: false,
    startDateFrom: false,
    startDateTo: false,
    endDateFrom: false,
    endDateTo: false,
    visible: false
};
/**
 * Scrive nel local storage l'oggetto passato come parametro, solo se oggetto.mesName === null, quindi solo la prima volta
 * @param {object} filter 
 */
export const writeFirstTimeLs = (filter) => {
    if (localStorage.getItem(filter.mesName) === null) {
        localStorage.setItem(filter.mesName, JSON.stringify(filter));
    };
};

/**
 * Scrive nel local storage chiave-valore passati come parametri, parsando in stringa il valore
 * @param {string} item 
 * @param {object} value 
 */
export const myLocalStorageSet = (item, value) => {
    localStorage.setItem(item, JSON.stringify(value));
};

/**
 * Recupera dal local storage il valore della chiave passata come parametro parsando in JSON il valore
 * @param {string} item 
 * @returns valore
 */
export const myLocalStorageGet = (item) => {
    return JSON.parse(localStorage.getItem(item));
};

/**
 * Verifica che all'interno del local storage esista un campo col nome del parametro passato
 * @param {string} name 
 * @returns true se esiste, false atrimenti
 */
export const myLocalStorageExist = (name) => {
    if (localStorage.getItem(name) !== null) {
        return true;
    } else {
        return false;
    }
};
