import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { configurationSelector, change, updateAPI, cancelEdit } from "./slice";
import { customLabel } from "../utility/customLabel";
import { Tab, Tabs, DialogContent, Button, DialogActions } from "@material-ui/core";
import Company from "./forms/Company";
import Operator from "./forms/Operator";
import Components from "./forms/Components";
import General from "./forms/General";
import Evolution from "./forms/Evolution";
import Timeline from "./forms/Timeline";
import Planner from "./forms/Planner";
import Report from "./forms/Report";
import { tabIndexConfig } from "./utility";
import Stapling from './forms/Stapling';
import Labels from './forms/Labels';

const ConfForm = () => {
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = React.useState(0);
    const { configurationedit, loading, incremental } = useSelector(configurationSelector);
    const [logoFile, setLogoFile] = React.useState(null);
    const [labelFile, setLabelFile] = React.useState(null);

    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    };
    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (event.target.type === "checkbox") {
            value = event.target.checked;
        }
        if (event.target.type === "number" && event.target.value !== "") {
            value = event.target.valueAsNumber;
        }
        dispatch(change({ name: name, value: value }));
    };
    const onSelectLogo = (event) => {
        //per evitare che si rompi nel caso l'utente schiacci annulla su dialog di inserimento del file
        if (event.target.files && event.target.files.length > 0) {
            setLogoFile(event.target.files)
        }
    };
    const save = () => {
        dispatch(updateAPI(configurationedit, logoFile, labelFile));
        setLogoFile(null);
        setLabelFile(null);
        setTabIndex(0);
    };
    const resetInput = event => {
        event.target.value = null;
    };

    const onSelectLabel = event => {
        if (event.target.files && event.target.files.length > 0) {
            setLabelFile(event.target.files)
        }
    };
    const cancelLabel = () => {
        setLabelFile(null)
    };
    const cancelLogo = () => {
        setLogoFile(null)
    };
    const onCancelEdit = () => {
        dispatch(cancelEdit(configurationedit));
        setLogoFile(null);
        setLabelFile(null);
        setTabIndex(0);
    };

    return (
        <React.Fragment>
            <DialogContent>
                <Tabs value={tabIndex} onChange={handleChangeTab} style={{ backgroundColor: "white", color: "black" }}
                    variant="scrollable" scrollButtons="auto">
                    <Tab label={customLabel("configuration.company")} key={"company"} value={tabIndexConfig["company"]} />
                    <Tab label={customLabel("configuration.evolution")} key={"evolution"} value={tabIndexConfig["evolution"]} />
                    <Tab label={customLabel("configuration.general")} key={"general"} value={tabIndexConfig["general"]} />
                    <Tab label={customLabel("configuration.operatorView")} key={"operatorView"} value={tabIndexConfig["operatorView"]} />
                    <Tab label={customLabel("configuration.components")} key={"components"} value={tabIndexConfig["components"]} />
                    <Tab label={customLabel("timeline.timeline")} key={"timeline"} value={tabIndexConfig["timeline"]} />
                    <Tab label={customLabel("function.planner")} key={"planner"} value={tabIndexConfig["planner"]} />
                    <Tab label={customLabel("function.report")} key={"report"} value={tabIndexConfig["report"]} />
                    <Tab label={customLabel("stapling.stapling")} key={"stapling"} value={tabIndexConfig["stapling"]} />
                    <Tab label={customLabel("configuration.labels")} key={"labels"} value={tabIndexConfig["labels"]} />
                </Tabs>
                <div style={{ marginTop: "8px" }}></div>
                {tabIndex === tabIndexConfig["company"] ? (
                    <Company configurationedit={configurationedit} handleChange={handleChange} labelFile={labelFile} loading={loading}
                        resetInput={resetInput} cancelLabel={cancelLabel} onSelectLabel={onSelectLabel}
                        logoFile={logoFile} onSelectLogo={onSelectLogo} cancelLogo={cancelLogo} />
                ) : tabIndex === tabIndexConfig["evolution"] ? (
                    <Evolution configurationedit={configurationedit} handleChange={handleChange} />
                )
                    : tabIndex === tabIndexConfig["general"] ? (
                        <General configurationedit={configurationedit} handleChange={handleChange} />
                    )
                        : tabIndex === tabIndexConfig["operatorView"] ? (
                            <Operator configurationedit={configurationedit} handleChange={handleChange} />
                        )
                            : tabIndex === tabIndexConfig["components"] ? (
                                <Components configurationedit={configurationedit} handleChange={handleChange} />
                            )
                                : tabIndex === tabIndexConfig["timeline"] ? (
                                    <Timeline configurationedit={configurationedit} handleChange={handleChange} />
                                )
                                    : tabIndex === tabIndexConfig["planner"] ? (
                                        <Planner configurationedit={configurationedit} handleChange={handleChange} />
                                    )
                                        : tabIndex === tabIndexConfig["report"] ? (
                                            <Report configurationedit={configurationedit} handleChange={handleChange} />
                                        )
                                            : tabIndex === tabIndexConfig["stapling"] ? (
                                                <Stapling configurationedit={configurationedit} handleChange={handleChange} incremental={incremental} />
                                            )
                                                : tabIndex === tabIndexConfig["labels"] ? (
                                                    <Labels />
                                                )
                                                    : null
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={save} color="primary"
                    disabled={loading || configurationedit.name === "" || configurationedit.decimals === "" || configurationedit.decimals < 0 || configurationedit.decimals === null ||
                        configurationedit.dataRetentionCloseOdpDays < 0 || configurationedit.dataRetentionVarsOpenOdpsDays < 0 || configurationedit.dataRetentionDataOpenOdpsDays < 0 ||
                        configurationedit.dataRetentionCloseOdpDays === "" || configurationedit.dataRetentionVarsOpenOdpsDays === "" || configurationedit.dataRetentionDataOpenOdpsDays === "" ||
                        configurationedit.plannerDaysLimit === "" || configurationedit.plannerDaysLimit === null || configurationedit.labelOdpOpView === ""}>
                    {customLabel("button.save")}
                </Button>
                <Button onClick={onCancelEdit} color="default" disabled={loading}>{customLabel("button.close")}</Button>
            </DialogActions>
        </React.Fragment >
    )
};



export default ConfForm;
