import { createSlice } from '@reduxjs/toolkit'
import { SERVER_URL } from '../../constants';
import { customLabel } from "../utility/customLabel";

export const initialState = {
  loading: false,
  isAuthenticated: false,
  isRefreshed: false,
  error: false,
  success: false,
  isUser: false,
  isAdmin: false,
  isSuperAdmin: false,
  currentUser: false,
  serverNotResponding: false
}

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    refresh: state => {
      state.loading = true
      state.isRefreshed = false
      state.error = false
    },
    refreshSuccess: (state, { payload }) => {
      localStorage.setItem('bishop_current_user', JSON.stringify(payload))
      state.isUser = payload.roles.indexOf("ROLE_ADMIN") < 0 || payload.roles.indexOf("ROLE_SUPER_ADMIN") < 0
      state.isAdmin = payload.roles.indexOf("ROLE_ADMIN") > -1
      state.isSuperAdmin = payload.roles.indexOf("ROLE_SUPER_ADMIN") > -1
      state.loading = false
      state.isRefreshed = true
    },
    logout: (state, { payload }) => {
      localStorage.removeItem('bishop_current_user')
      state.isRefreshed = false
      state.loading = false
      state.currentUser = false
      state.isAdmin = false
      state.isSuperAdmin = false
      state.isUser = false
    },
    showError: (state, { payload }) => {
      state.error = payload
      state.loading = false;
      state.error = true;
    },
    showSuccess: (state, { payload }) => {
      state.success = payload
    },
    getting: state => {
      state.currentUser = false
      state.loading = true
      state.error = false
    },
    getSuccess: (state, { payload }) => {
      state.currentUser = payload
      state.loading = false
    },
    enableErrors: (state) => {
      state.loading = false;
      state.error = true;
    },
    setServerNotResponding: (state, { payload }) => {
      state.serverNotResponding = payload;
    }
  }
})

export const { showError, showSuccess, refresh, refreshSuccess, logout, getSuccess, getting, enableErrors, setServerNotResponding } = mainSlice.actions
export const mainSelector = state => state.main
export default mainSlice.reducer

export function refreshAPI() {
  return async dispatch => {
    dispatch(refresh())
    var details = {
      'refresh_token': JSON.parse(localStorage.getItem("bishop_current_user")).refresh_token,
      'grant_type': 'refresh_token'
    };
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const response = await fetch(SERVER_URL + '/oauth/access_token', {
      mode: 'cors',
      method: 'POST',
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody
    });

    const data = await response.json()
    if (response.status === 200) {
      dispatch(refreshSuccess(data))
      dispatch(getCurrentUser(data.username))
    } else if (response.status === 403) {
      dispatch(logout())
    } else {
      dispatch(showError(data.message))
    }
  }
}
export function getCurrentUser() {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  let username = JSON.parse(localStorage.getItem('bishop_current_user')).username
  return async dispatch => {
    dispatch(getting())
    const response = await fetch(SERVER_URL + '/api/user/username/' + username, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(getSuccess(data))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}
