import { Button } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from '../../utility/customLabel';
import {
    changeMachineEventType,
    operatorViewSelector,
    resetMachineEventType,
    startMachineEventAPI
} from "../slice";
import DialogOperator from "../utility/DialogOperator";
import { opViewStyles } from "../utility/customStyles";

const MachineEventDialog = () => {
    const customClasses = opViewStyles();
    const dispatch = useDispatch();
    const { machine, operator, odp, loading, machineEventType, machineEventTypes,
        flagDialog, process, modeStapling, errorsMachineEventTypes } = useSelector(operatorViewSelector);

    //API che crea Evento macchina
    const addMachineEventType = () => {
        if (!modeStapling) {
            dispatch(startMachineEventAPI(process, false, operator, machineEventType, machine));
        } else {
            dispatch(startMachineEventAPI(false, odp, operator, machineEventType, machine));
        }
    };

    //chiude dialog di aggiunta del machineEventType
    const closeMachineEventTypeDialog = () => {
        dispatch(resetMachineEventType());
    }

    const renderDialogActions = () => {
        return (
            <React.Fragment>
                <Button className={customClasses.dialogButton} onClick={() => addMachineEventType()} variant="contained" disabled={machineEventType === false || loading || (machineEventType && machineEventType.systemEvent)} color="primary">{customLabel("button.confirm")}</Button>
                <Button className={customClasses.dialogButton} onClick={() => closeMachineEventTypeDialog()} variant="contained" color="secondary" disabled={loading}>{customLabel("button.close")}</Button>
            </React.Fragment>
        )
    }

    //restituisce gli eventi macchina disonibili, non quelli di sistema
    const renderCountMachineEventTypes = () => {
        let count = 0;
        if (machineEventTypes) {
            for (let i = 0; i < machineEventTypes.length; i++) {
                if (!machineEventTypes[i].systemEvent) {
                    count++;
                }
            }
        }
        return count
    };

    //pulsanti che contengono i machineEventType, sul click valorizza lo stato machineEventType
    const handleChangeMachineEventType = (id) => {
        dispatch(changeMachineEventType({ value: id, events: machineEventTypes }));
    };

    const renderDialogForm = () => {
        if (errorsMachineEventTypes) {
            return (
                <React.Fragment>
                    {customLabel("function.loadingError").toUpperCase()}
                </React.Fragment>
            )
        }
        if (renderCountMachineEventTypes() > 0) {
            return (
                machineEventTypes.map((m) => {
                    if (!m.systemEvent) {
                        return (
                            <div key={m.id}>
                                <Button value={m.name} className={customClasses.innerButton} color={machineEventType.id === m.id ? "primary" : "default"}
                                    variant={machineEventType.id === m.id ? "contained" : "outlined"} onClick={() => handleChangeMachineEventType(m.id)}
                                    disabled={loading}>
                                    {m.name}
                                </Button>
                            </div>
                        )
                    } else return null
                })
            )
        } else {
            return (
                <React.Fragment>
                    {customLabel("function.notAvailable").toUpperCase()}
                </React.Fragment>
            )
        }
    }

    return (
        <DialogOperator flag={flagDialog.flagMachineEventType} title={customLabel("operatorView.createMachineEvent")}
            form={() => renderDialogForm()} actions={() => renderDialogActions()} transparentBackround={false} />
    );
}

export default MachineEventDialog;