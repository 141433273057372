import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { getListAPI as getMaintenanceTypes, maintenanceTypesSelector } from "../maintenanceTypes/slice";
import {
  addCheck,
  addProcess,
  addVar,
  cancelEdit,
  cancelIsCreated,
  change,
  changeCheck,
  changeCurrentVar,
  changeObject,
  changeTabIndex,
  changeVar,
  confirmDelete,
  create,
  createAPI,
  createCheckAPI,
  createVar,
  deleteAPI,
  disableFlagEditBom,
  disableInsertVarFlag,
  disableVarFlag,
  edit,
  enableFlagEditBom,
  enableInsertVarFlag, enableVarFlag,
  getAPI,
  insertVarsAPI,
  maintenancesSelector,
  onChangeSort,
  removeProcess,
  reopenProcessAPI,
  resetCheck,
  resetState,
  resetVar,
  restoreProcessAPI,
  selectProcess,
  setBomProcesses,
  setCheck,
  setEditProcess, setReload,
  setReloadMachineEvents,
  setVar,
  stopOdpAPI,
  updateAPI,
  updateCheckAPI,
  updateProcessesAPI,
  updateVar
} from "./slice";
//import { doChange, addNavigation, selector as dashbboardSelector } from "../dashboard/slice";
import { configurationSelector } from "../configuration/slice";
import { getListAPI as getMachineEventsAPI } from "../machineEvents/slice";
import { getFullListAPI as getMachines, machinesSelector } from "../machines/slice";
import DetailsBom from "../odps/DetailsBom";
import DetailsOdp from "../odps/DetailsOdp";
import NewDetailsBom from "../odps/NewDetailsBom";
import NewProductionData from "../odps/NewProductionData";
import OdpHome from "../odps/OdpHome";
import Home from "../odps/editProcess/Home";
import { getListAPI as getProcessStatusesAPI } from "../processStatuses/slice";
import { getListAPI as getProcessTypesAPI } from '../processTypes/slice';
import { getListAPI as getUserEventsAPI } from "../userEvents/slice";
import { Form } from "./Form";

const OdpPage = ({ match }) => {
  const dispatch = useDispatch();
  const { odp, odpedit, editmode, deleting, isCreated, isDeleted, loading, hasErrors,
    bomProcesses, flagEditBom, currentProcess, editProcess, process,
    currentMachineEvents, loadingMachineEvents, reloadMachineEvents, resultsMachineEvents, tabIndex,
    choosenVar, insertVarFlag, listVarFlag, currentVar, editVar, currentVars, processMachineVars, editCheck, currentChecks } = useSelector(maintenancesSelector);
  const { maintenanceTypes } = useSelector(maintenanceTypesSelector);
  const { configuration } = useSelector(configurationSelector)
  //const { current, currentNavigation } = useSelector(dashbboardSelector)
  const { machines } = useSelector(machinesSelector);
  const [closeOdpFlag, setCloseOdpFlag] = React.useState(false);

  useEffect(() => {
    dispatch(getProcessTypesAPI(0, 100000, "code", "asc", false, false, true));
    dispatch(getUserEventsAPI(0, 10000, "name", "asc", false));
    dispatch(getMachineEventsAPI(0, 10000, "name", "asc", false));
    dispatch(getProcessStatusesAPI(0, 10000, "name", "asc", false));
  }, [dispatch])

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    dispatch(change({ name: name, value: value, maintenanceTypes: maintenanceTypes, machines: machines }));
  };

  const handleChangeDate = (name, value) => {
    dispatch(change({ name: name, value: new Date(value.format()).getTime() }));
  };

  const handleChangeObject = (name, value) => {
    if (value !== false) {
      dispatch(change({ name: name, value: value, maintenanceTypes: maintenanceTypes, machines: machines }))
      if (name === "machine.id") {
        let indexOfMachines = machines.findIndex((l) => {
          return l.id === value
        });
        dispatch(getMaintenanceTypes(0, 99999, "name", "asc", false, machines[indexOfMachines], false, false));
      }
    }
    else {
      dispatch(changeObject({ name: name, value: false }))
      if (name === "machine.id") {
        dispatch(getMaintenanceTypes(0, 99999, "name", "asc", false, false, false, false));
      }
    }
  };

  const toggleDelete = (event) => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(odpedit));
  };

  const onEdit = () => {
    dispatch(edit());
  };

  const doDelete = () => {
    dispatch(deleteAPI(odp));
  };

  const save = () => {
    if (odp.id === "new") {
      dispatch(createAPI(odpedit));
    } else {
      dispatch(updateAPI(odpedit));
    }
  };

  useEffect(() => {
    const { id } = match.params;
    //se l'odp è stato creato ed è già avvenuto il redirect in fondo alla pagina, rimuovo isCreated
    if (isCreated && odp.id.toString() === id) {
      dispatch(cancelIsCreated())
    }
    // se l'odp è appena stato selezionato dalla table list o è cambiato l'id nell'url:
    if (!isCreated && (odp === false || odp.id.toString() !== id) && !loading && !hasErrors) {
      if (id !== "new") {
        // se l'id nell'url è !== da new me lo faccio dare dal server
        dispatch(getAPI(id));
      } else {
        // se l'id nell'url è === new creo l'oggetto
        dispatch(create());
      }
    }
  }, [dispatch, match, odp, loading, isCreated, hasErrors]);

  useEffect(() => {
    // una volta caricato/creato l'odp carico i tipi manutenzione (se non è già stato fatto)
    if (odp !== false && !loading && maintenanceTypes === false) {
      dispatch(getMaintenanceTypes(0, 99999, "name", "asc", false, false, false, false));
    }
  }, [dispatch, maintenanceTypes, loading, odp]);

  useEffect(() => {
    // una volta caricato/creato l'odp e i tipi manutenzione, carico le macchine (se non è già stato fatto)
    if (odp !== false && !loading && maintenanceTypes !== false && machines === false) {
      dispatch(getMachines());
    }
  }, [dispatch, maintenanceTypes, machines, loading, odp]);

  //usato per la navigazione, dashboard-->slice-->navigation
  /*   useEffect(() => {
      const { id } = match.params;
      let name = customLabel("maintenance.maintenance") + " " + odp.code
      // se il menù non è evidenziato correttamente)
      if (current.indexOf("/maintenances/" + odp.id) < 0) {
        dispatch(doChange("/maintenances/" + odp.id))
      }
      // aggiungo alla navigazione l'elemento corrente
      if (odp !== false && odp.id.toString() === id && (currentNavigation === false || currentNavigation.n.name !== name)) {
        dispatch(addNavigation({ url: "/maintenances/" + odp.id, name: name }))
      };
    }, [dispatch, match, current, currentNavigation, odp]) */

  //viene chiamato solo la prima volta, quando editProcess è false, ed inizializzato con il currentProcess
  useEffect(() => {
    if (!editProcess && odp && currentProcess !== false && odp.processes.length > 0) {
      dispatch(setEditProcess({ currentProcessId: currentProcess.id, processes: odp.processes }))
    }
  }, [dispatch, odp, editProcess, currentProcess])

  const changeEditProcess = (processId) => {
    dispatch(setEditProcess({ currentProcessId: processId, processes: odp.processes }))
  };

  const editBom = () => {
    dispatch(setBomProcesses(odp.processes))
    dispatch(enableFlagEditBom())
  };

  const resetOdpsState = () => {
    dispatch(resetState());
  }

  const enableCloseOdpFlag = () => {
    setCloseOdpFlag(true);
  };

  const saveBomProcesses = () => {
    dispatch(disableFlagEditBom())
    dispatch(updateProcessesAPI(odp, bomProcesses))
  };

  const confirmCloseOdp = () => {
    dispatch(stopOdpAPI(odp));
    setCloseOdpFlag(false);
  };

  const cancelCloseOdp = () => {
    setCloseOdpFlag(false);
  };

  const renderDetailsOdp = () => {
    return (
      <DetailsOdp odp={odp} onEdit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete} loading={loading} />
    )
  }

  const renderFormMaintenance = () => {
    return (
      <Form odpedit={odpedit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save} handleChangeDate={handleChangeDate}
        resetOdp={resetOdpsState} maintenanceTypes={maintenanceTypes} machines={machines} handleChangeObject={handleChangeObject} />
    )
  }

  const renderNewDetailsBom = () => {
    return (
      <NewDetailsBom odp={odp} loading={loading} bomProcesses={bomProcesses} flagEditBom={flagEditBom}
        editBom={editBom} enableCloseOdpFlag={enableCloseOdpFlag} saveBomProcesses={saveBomProcesses} closeOdpFlag={closeOdpFlag} confirmCloseOdp={confirmCloseOdp}
        cancelCloseOdp={cancelCloseOdp} addProcess={addProcess} removeProcess={removeProcess} onChangeSort={onChangeSort}
        disableFlagEditBom={disableFlagEditBom} />
    )
  }

  const renderProductionData = () => {
    return (
      <NewProductionData maintenance={odp.maintenance} startDate={odp.startDate} endDate={odp.endDate} status={odp.status} stapling={odp.stapling} />
    )
  }

  const renderDetailsBom = () => {
    return (
      <DetailsBom odp={odp} loading={loading} bomProcesses={bomProcesses} flagEditBom={flagEditBom}
        editBom={editBom} enableCloseOdpFlag={enableCloseOdpFlag} saveBomProcesses={saveBomProcesses} closeOdpFlag={closeOdpFlag} confirmCloseOdp={confirmCloseOdp}
        cancelCloseOdp={cancelCloseOdp} addProcess={addProcess} removeProcess={removeProcess} onChangeSort={onChangeSort}
        disableFlagEditBom={disableFlagEditBom} changeEditProcess={changeEditProcess} editProcess={editProcess} />
    )
  }

  const renderHome = () => {
    return (
      <Home odp={odp} loading={loading} editProcess={editProcess} setReload={setReload} process={process} selectProcess={selectProcess}
        restoreProcessAPI={restoreProcessAPI} reopenProcessAPI={reopenProcessAPI} currentMachineEvents={currentMachineEvents} loadingMachineEvents={loadingMachineEvents}
        reloadMachineEvents={reloadMachineEvents} resultsMachineEvents={resultsMachineEvents} setReloadMachineEvents={setReloadMachineEvents}
        choosenVar={choosenVar} insertVarFlag={insertVarFlag} listVarFlag={listVarFlag}
        currentVar={currentVar} editVar={editVar} currentVars={currentVars} processMachineVars={processMachineVars}
        insertVarsAPI={insertVarsAPI} changeCurrentVar={changeCurrentVar} enableInsertVarFlag={enableInsertVarFlag} enableVarFlag={enableVarFlag}
        disableVarFlag={disableVarFlag} disableInsertVarFlag={disableInsertVarFlag} setVar={setVar} resetVar={resetVar} addVar={addVar}
        createVar={createVar} updateVar={updateVar} changeVar={changeVar} editCheck={editCheck} currentChecks={currentChecks} changeCheck={changeCheck} resetCheck={resetCheck}
        addCheck={addCheck} updateCheckAPI={updateCheckAPI} createCheckAPI={createCheckAPI} setCheck={setCheck} />
    )
  }

  const renderodp = () => {
    if (odp !== false && match.params.id === odp.id.toString()) {
      return (
        <React.Fragment>
          <OdpHome match={match} loading={loading} odp={odp} renderDetailsOdp={renderDetailsOdp}
            renderNewDetailsBom={renderNewDetailsBom} renderProductionData={renderProductionData} renderDetailsBom={renderDetailsBom}
            renderHome={renderHome} renderFormMaintenance={renderFormMaintenance} editmode={editmode} odpedit={odpedit} changeTabIndex={changeTabIndex}
            tabIndex={tabIndex} configuration={configuration}/>
        </React.Fragment>
      );
    }
    return;
  };

  if (isDeleted && match.params.id === odp.id.toString()) {
    return <Redirect to={"/maintenances/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/maintenances/" + odp.id} />;
  }

  return <Box>{renderodp()}</Box>;
};

export default OdpPage;
