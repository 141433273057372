import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket from 'react-use-websocket';
import useInterval from 'react-useinterval';
import { WEBSOCKET_URL_ERROR } from '../../../constants';
import { useBoolean } from "../../customHooks/useBoolean";
import { showError } from "../../notifications/slice";
import {
    getProcessChecksAPI,
    getProcessVarsAPI, getUserEventTypesAPI,
    operatorViewSelector, reloadingInterfaceOdpModeAPI,
    stopProcessAPI
} from "../slice";
import { socketOptions } from "../utility/constants";

const ReloadingAPI = ({ configuration }) => {
    const dispatch = useDispatch();
    const { process, odp, machine, operator, closeChecks, errors, loading, interfaceNeedUpdate, reloading, modeMaintenance, flagDialog
    } = useSelector(operatorViewSelector);
    const { lastMessage } = useWebSocket(WEBSOCKET_URL_ERROR, socketOptions);
    const [userEventsCall, { setTrue: setUsEventsTrue }] = useBoolean(false);
    const [checksCall, { setTrue: setChecksTrue }] = useBoolean(false);
    const [varsCall, { setTrue: setVarsTrue }] = useBoolean(false);

    useInterval(() => updatingInterface(), 10000);

    //WS errori
    useEffect(() => {
        if (lastMessage != null) {
            let j = JSON.parse(lastMessage.data);
            if (j.process.toString() === process.id.toString()) {
                dispatch(showError(j.error));
            }
        }
    }, [dispatch, lastMessage, process])

    //carico eventi utente prima volta
    useEffect(() => {
        if (!userEventsCall) {
            dispatch(getUserEventTypesAPI(operator, process, odp, configuration.enableComponents, configuration.retrieveLotInfo));
            setUsEventsTrue()
        };
    }, [dispatch, userEventsCall, operator, process, odp, configuration.enableComponents, setUsEventsTrue,configuration.retrieveLotInfo])

    //carico checks prima volta
    useEffect(() => {
        if (!checksCall) {
            dispatch(getProcessChecksAPI(odp, process));
            setChecksTrue();
        }
    }, [dispatch, checksCall, odp, process, setChecksTrue])

    //carico vars prima volta
    useEffect(() => {
        if (!varsCall) {
            dispatch(getProcessVarsAPI(process));
            setVarsTrue()
        }
    }, [dispatch, varsCall, process, setVarsTrue])

    useEffect(() => {
        //API per start close pause process ogni qualvolta i relativi checks vengono azzerati
        if (flagDialog.flagClose && closeChecks && closeChecks.length === 0 && !errors) {
            dispatch(stopProcessAPI(odp, process, operator, machine));
        };
    }, [dispatch, flagDialog, closeChecks, errors, odp, process, operator, machine]);

    //unico reload in caso di errori o di interazione con interfaccia
    useEffect(() => {
        if (interfaceNeedUpdate && !loading && !reloading && !errors) {
            dispatch(reloadingInterfaceOdpModeAPI(odp, process, machine, operator, configuration.enableFreeLotComponents, false, modeMaintenance, true, configuration.retrieveLotInfo));
        };
    }, [dispatch, interfaceNeedUpdate, loading, reloading, errors, odp, process, machine, operator, configuration, modeMaintenance])

    //metodo che aggiornano i dati al interno del mes  
    const updatingInterface = () => {
        if (!reloading && !loading &&
            !flagDialog.flagClose &&
            !flagDialog.flagUserEventType &&
            !flagDialog.flagStopProcess
        ) {
            dispatch(reloadingInterfaceOdpModeAPI(odp, process, machine, operator, configuration.enableComponents, true, modeMaintenance, true, configuration.retrieveLotInfo));
        }
    };

    return (
        null
    );
}

export default ReloadingAPI;