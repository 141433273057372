import React, { useEffect } from 'react'
import DashboardPage from '../dashboard/DashboardPage'
import NotificationsPage from '../notifications/NotificationsPage'

import { Redirect } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { mainSelector, refreshAPI } from './slice'
import { configurationSelector, getAPI as getConfigurationAPI } from "../configuration/slice";
import T from "i18n-react"
import dictionary from "../../texts/texts-it.json";
import { odpStatusFilter, writeFirstTimeLs } from "../utility/localStorageKeys";

const MainPage = ({ match }) => {
  const { isRefreshed, currentUser, loading } = useSelector(mainSelector)
  const { configuration, loading: loadingConf, hasErrors: errorsConf } = useSelector(configurationSelector);
  const dispatch = useDispatch()

  /**
   * Creazione dei campi necessari all'interno del Local Storage
   */
  useEffect(() => {
    if (localStorage.getItem('bishop_current_user')) {
      dispatch(refreshAPI());
    }
    //Local storage: creazione parametri per filtro stato commesse
    writeFirstTimeLs(odpStatusFilter);
  }, [dispatch, match])

  if (configuration === false && !loadingConf && !errorsConf) {
    dispatch(getConfigurationAPI());
  }

  if (configuration && configuration.label !== null) {
    T.setTexts(JSON.parse(configuration.label), {
      notFound: (key) => `${key} <- missing!`,
    });
  }

  if (configuration && configuration.label === null) {
    T.setTexts(dictionary, {
      notFound: (key) => `${key} <- missing!`,
    });
  }

  if (configuration && isRefreshed && !loading && currentUser !== false) {
    return (
      <div>
        <DashboardPage currentUser={currentUser} />
        <NotificationsPage />
      </div>
    )
  }

  if (!isRefreshed && !localStorage.getItem('bishop_current_user')) {
    return (
      <Redirect to={"/login"} />
    )
  }

  return null;
}
export default MainPage
