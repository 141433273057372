import React from "react";
import {
    Box, Input, FormControl, InputLabel
} from "@material-ui/core";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";


const Planner = ({ configuration }) => {
    const myClasses = mesStyles();

    return (
        <Box className={myClasses.confDetailsBox}>
            <form className={myClasses.formContainer} noValidate autoComplete="off">
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="plannerDaysLimit">{customLabel("configuration.plannerDaysLimit")}</InputLabel>
                    <Input value={configuration.plannerDaysLimit} />
                </FormControl>
            </form>
        </Box>
    );
};
export default Planner;