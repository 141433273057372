import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { customLabel } from "../utility/customLabel";

import { useDispatch, useSelector } from "react-redux";

import { notificationsSelector, resetErrors, showError, showSocketError, showSuccess } from "./slice";


const NotificationsPage = ({ match }) => {
  const dispatch = useDispatch();

  const { error, success, translate, socketError, socketErrorType } = useSelector(notificationsSelector);

  const handleClose = (event) => {
    dispatch(showError(false));
    dispatch(resetErrors());
  };

  const handleCloseSuccess = (event) => {
    dispatch(showSuccess(false));
  };
  const handleCloseSocket = (event) => {
    dispatch(showSocketError(false));
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <div>
      <Snackbar open={success !== false} autoHideDuration={2000} onClose={handleCloseSuccess}>
        <Alert onClose={handleCloseSuccess} severity="success">
          {translate ? customLabel(success) : success}
        </Alert>
      </Snackbar>
      <Snackbar open={error !== false}>
        <Alert onClose={handleClose} severity="error">
          {customLabel("function.error").toUpperCase()}: {error}
        </Alert>
      </Snackbar>
      <Snackbar style={{ "zIndex": 1000 }} open={socketError !== false}>
        <Alert onClose={handleCloseSocket} severity="error">
          {customLabel("function.error").toUpperCase() + " " + socketErrorType}: {socketError}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default NotificationsPage;
