import React from 'react'
import { TableCell, TableRow, TableHead } from "@material-ui/core";

/**
 * Restituisce una TableRow (TableHead) 
 * @param {string} title il titolo del parent dei documenti 
 * @param {array} documents i documenti da mostrare
 * @returns restituisce una tabella con l elenco dei documenti da mostrare
 */
const SimpleTableHeader = ({ list }) => {

    return (
        <TableHead>
            <TableRow>
                {list.map((l, ind) => {
                    return (
                        <TableCell key={l + "_" + ind}>{l}</TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

export default SimpleTableHeader;