import React from "react";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Button, TableSortLabel, TextField,
  IconButton
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check"
import { customLabel } from "../utility/customLabel";
import moment from "moment";
import { bishopFormats } from '../../constants';
import { Autocomplete } from "@material-ui/lab";
import classNames from "classnames";
import { mesStyles } from "./ultrafabStyles";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CloseIcon from '@material-ui/icons/Close';
import PaperAutocomplete from "./PaperAutocomplete";

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  selected: {
    backgroundColor: "#0000001a"
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
  actionCell: {
    textAlign: "right",
  },
}));

export default function EnhancedTable(props) {
  const { items, headers, mainObject, page, setPage, rowsPerPage, setRowsPerPage, results, odp,
    onRequestSort, order, orderBy, changeFilters, resetFilter, buttonAction, buttonText, disabledButton, startDateFrom, startDateTo, endDateFrom, endDateTo } = props;

  const classes = useStyles();
  const myClasses = mesStyles();
  const [filter, setFilter] = React.useState(false);
  let reloadTimeout = null;

  const toggleFilter = () => {
    setFilter(!filter);
    if (filter) {
      resetFilter();
      setPage(null, 0, true);
    }
  };

  const onChangeFilterAutocomplete = (event, value, reason, name) => {
    setPage(null, 0, false);
    if (reason === "clear") {
      changeFilters(name, false);
    } else {
      changeFilters(name, value);
    }
  };

  const onDoChangeFilter = (name, value) => {
    setPage(null, 0, false);
    changeFilters(name, value);
  }

  const handleChangeDate = (name, value) => {
    if (name.endsWith("From")) {
      //const newValue = value.startOf('day')
      changeFilters(name, new Date(value.format()).getTime())
    }
    else if (name.endsWith("To")) {
      //const newValue = value.endOf('day')
      changeFilters(name, new Date(value.format()).getTime())
    }
  };

  const handleResetDate = (e, name) => {
    e.stopPropagation();
    changeFilters(name, false)
  };

  const onChangeFilter = (event) => {
    clearTimeout(reloadTimeout)
    let name = event.target.name
    let value = event.target.value
    reloadTimeout = setTimeout(() => {
      onDoChangeFilter(name, value);
    }, 500);
  };

  const returnCheck = (val) => {
    if (val) {
      return <CheckIcon />
    }
    else {
      return null
    }
  };

  const returnBooleanValue = (val, values) => {
    if (val) {
      return values[1]
    }
    else {
      return values[0]
    }
  };

  const returnObjectList = (list) => {
    return list.map((item) => {
      return <span key={item.name}>{item.name},</span>
    })
  };
  /**
   * Se l'header è di tipo custom, ritornerà le proprietà specificate nella chiave fields separate da uno spazio
   * @param {*} header 
   * @param {*} item 
   * @returns 
   */
  const returnCustomField = (header, item) => {
    let value = "";
    if (header.fields && header.fields.length > 0) {
      header.fields.forEach((h, i) => {
        value += item[h] + " "
      })
      return value;
    } else {
      return null;
    }
  };

  return (
    <div className={classes.root}>
      <TableContainer className={myClasses.tableListOneButton}>
        <Table stickyHeader className={classes.table} size="small">
          <TableHead>
            <TableRow>
              {Object.keys(headers).map((h) => {
                return (
                  <TableCell key={h} align={headers[h].type === "numeric" || headers[h].type === "date" || headers[h].type === "datetime" ? "right" : "left"}
                    sortDirection={orderBy === h ? order : false}>
                    <TableSortLabel active={orderBy === h} direction={orderBy === h ? order : "asc"} onClick={() => onRequestSort(h)}>
                      {customLabel(mainObject + h).toUpperCase()}
                      {orderBy === h ? (<span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>) : null}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
              <TableCell className={classes.actionCell}>
                <Button style={{ padding: 0 }} className={classNames({ [classes.selected]: filter })} color="default" onClick={toggleFilter}>
                  {customLabel("function.filter")}
                </Button>
              </TableCell>
            </TableRow>
            {filter ? (
              <TableRow>
                {Object.keys(headers).map((h) => {
                  if (headers[h].filter && headers[h].type !== "date" && headers[h].type !== "datetime") {
                    return (
                      <TableCell key={"filter" + h}>
                        {props[h + "List"] !== false ? (
                          <Autocomplete
                            PaperComponent={PaperAutocomplete}
                            options={props[h + "List"]} filterSelectedOptions
                            value={props[h] ? props[h] : null}
                            getOptionSelected={(option, value) => {
                              return (
                                headers[h].type === "object" || headers[h].type === "objectList" ? option.id === value.id : value
                              )
                            }}
                            onChange={(event, value, reason) => onChangeFilterAutocomplete(event, value, reason, h)}
                            getOptionLabel={(option) => headers[h].type === "object" || headers[h].type === "objectList" ? option.name : headers[h].type === "stringL" ?
                              customLabel(mainObject + h + "." + option) : headers[h].type === "custom" ? option[headers[h].autocomplete] : option}
                            renderInput={(params) => (<TextField {...params} placeholder={customLabel(mainObject + h)} />)} />) : null}
                        {props[h + "List"] === false ? (<TextField name={h} onChange={onChangeFilter} />) : null}
                      </TableCell>
                    );
                  } else if (headers[h].filter && (headers[h].type === "date" || headers[h].type === "datetime")) {
                    return (
                      <TableCell key={"filter" + h} align="right">
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                          <DateTimePicker className={myClasses.dateTimePickerTable}
                            InputProps={{
                              endAdornment: (
                                <IconButton size="small" onClick={(e) => handleResetDate(e, headers[h]["when"] + "From")}>
                                  <CloseIcon />
                                </IconButton>
                              )
                            }}
                            okLabel={customLabel("button.confirm")}
                            cancelLabel={customLabel("button.cancel")}
                            ampm={false}
                            label={customLabel("function.from")}
                            value={headers[h]["when"] === "startDate" && startDateFrom ? startDateFrom : headers[h]["when"] === "endDate" && endDateFrom ? endDateFrom : null}
                            onChange={(date) => handleChangeDate(headers[h]["when"] + "From", date)}
                            format={bishopFormats.LT}
                          />
                        </MuiPickersUtilsProvider>
                        <br />
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                          <DateTimePicker className={myClasses.dateTimePickerTable}
                            InputProps={{
                              endAdornment: (
                                <IconButton size="small" onClick={(e) => handleResetDate(e, headers[h]["when"] + "To")}>
                                  <CloseIcon />
                                </IconButton>
                              )
                            }}
                            okLabel={customLabel("button.confirm")}
                            cancelLabel={customLabel("button.cancel")}
                            ampm={false}
                            label={customLabel("function.to")}
                            value={headers[h]["when"] === "startDate" && startDateTo ? startDateTo : headers[h]["when"] === "endDate" && endDateTo ? endDateTo : null}
                            onChange={(date) => handleChangeDate(headers[h]["when"] + "To", date)}
                            format={bishopFormats.LT}
                          />
                        </MuiPickersUtilsProvider>
                      </TableCell>
                    )
                  }
                  else {
                    return <TableCell key={"filter" + h}></TableCell>;
                  }
                })}
                <TableCell></TableCell>
              </TableRow>
            ) : null}
          </TableHead>
          <TableBody>
            {items.map((row) => {
              return (
                <StyledTableRow key={row.id}>
                  {Object.keys(headers).map((h) => {
                    let obj = headers[h];
                    if (row[h] !== null) {
                      return (
                        <TableCell key={row.id + h} align={obj.type === "numeric" || obj.type === "date" || obj.type === "datetime" ? "right" : "justify"}>
                          {obj.type === "object" ? row[h].name : obj.type === "objectList" ? returnObjectList(row[obj.name]) : obj.type === "date" ?
                            moment(row[h]).format(bishopFormats.L) : obj.type === "datetime" ? moment(row[h]).format(bishopFormats.LTS) : obj.type === "boolean" ?
                              returnCheck(row[h]) : obj.type === "booleanString" ? returnBooleanValue(row[h], props[h + "List"]) : obj.type === "stringL" ?
                                customLabel(mainObject + h + "." + row[h]) : obj.type === "custom" ? returnCustomField(obj, row[h]) : row[h]}
                        </TableCell>
                      );
                    } else {
                      return <TableCell key={row.id + h}></TableCell>;
                    }
                  })}
                  <TableCell align="right">
                    {!odp.maintenance ? <Button disabled={disabledButton} onClick={() => buttonAction(row)} color="primary">{buttonText}</Button> : null}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination labelRowsPerPage={customLabel("function.rowsPerPage")} rowsPerPageOptions={[2, 5, 10, 25]} component="div" count={results}
        rowsPerPage={rowsPerPage} page={page} onChangePage={(event, page) => setPage(event, page, true)} onChangeRowsPerPage={setRowsPerPage} />
    </div>
  );
}
