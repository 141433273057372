import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../constants";
import { showError, showSuccess, resetErrors as resetNotificationErrors } from "../notifications/slice";
import { logout, refreshAPI } from "../main/slice";
import _ from 'lodash';
import { customLabel } from "../utility/customLabel";
import { setServerNotResponding } from "../main/slice";
import { SERVER_NOT_RESPONDING_MESSAGE } from "../../constants";

export const initialState = {
  loading: false,
  hasErrors: false,
  machines: false,
  editmode: false,
  editmachine: false,
  machine: false,
  deleting: false,
  colors: false,
  machineVar: false,
  machineVars: false,
  insertImage: false,
  deletingImage: false,
  today: false,
  yesterday: false,
  thisWeek: false,
  lastWeek: false,
  thisMonth: false,
  lastMonth: false,
  //al momento utilizzato solo questo
  customEvents: false
};

const machinesSlice = createSlice({
  name: "machines",
  initialState,
  reducers: {
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    reset: (state) => {
      state.machines = false;
      state.machine = false;
      state.editmachine = false;
      state.editmode = false;
    },
    getting: (state) => {
      state.loading = true;
      state.isChanged = false;
      state.hasErrors = false;
    },
    getSuccess: (state, { payload }) => {
      state.machines = payload;
      state.loading = false;
      state.editmachine = false;
    },
    getMachineSuccess: (state, { payload }) => {
      state.loading = false;
      state.machine = payload;
      state.machineVars = payload.vars;
    },
    edit: (state) => {
      state.editmode = true;
      state.editmachine = state.machine;
    },
    cancelEdit: (state) => {
      state.editmachine = false;
      state.editmode = false;
    },
    create: (state) => {
      state.editmode = true;
      state.editmachine = { id: "new", name: "", code: "", external: false, closeUserEvent: true, stapling: false };
    },
    change: (state, { payload }) => {
      let p = state.editmachine;
      if (payload.name === "workcentre") {
        p[payload.name] = { 'id': payload.value }
      } else {
        p[payload.name] = payload.value;
      }
      state.editmachine = p;
    },
    creating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    createSuccess: (state, { payload }) => {
      state.machine = payload;
      state.machineVars = payload.vars
      state.editmode = false;
      state.editmachine = false;
      state.loading = false;
      state.isChanged = true;
    },
    updating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    updateSuccess: (state, { payload }) => {
      state.machine = payload;
      state.editMachine = false;
      state.loading = false;
      state.editmode = false;
      state.isChanged = true;
    },
    select: (state, { payload }) => {
      state.machine = payload;
      state.machineVars = payload.vars;
    },
    confirmDelete: (state) => {
      state.deleting = !state.deleting;
    },
    deleting: (state) => {
      state.deleting = false;
      state.loading = true;
      state.hasErrors = false;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.machine = false;
      state.isChanged = true;
    },
    enableErrors: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    getEventsSuccess: (state, { payload }) => {
      let colors = {}
      for (let e in payload.me) {
        let mt = payload.me[e]
        colors[mt.name] = mt.backgroundColor
      }
      let events = []
      for (let x in payload.data["events"]) {
        let me = payload.data["events"][x]
        for (let e in payload.me) {
          let mt = payload.me[e]
          if (mt.name === me.name) {
            let v = 0
            if (payload.data.tot[0] !== null) {
              v = me.duration / payload.data.tot[0].duration * 100
            }
            events.push({ id: mt.name, label: mt.name, color: mt.backgroundColor, value: v.toFixed(2), svalue: me.duration })
          }
        }
      }
      //state[payload.callbackItem] = events;
      state.customEvents = events;
      state.colors = colors;
    },
    creatingVar: state => {
      state.loading = true
      state.hasErrors = false
    },
    createVarSuccess: (state, { payload }) => {
      state.loading = false
      state.machineVar = false
      state.machineVars.push(payload)
      state.isChanged = true;
    },
    updatingVar: state => {
      state.loading = true
      state.hasErrors = false
    },
    updateVarSuccess: (state, { payload }) => {
      state.loading = false
      state.machineVar = false
      let mvs = _.cloneDeep(state.machineVars)
      let index = mvs.findIndex(v => {
        return v.id === payload.id;
      });
      mvs[index] = payload
      state.machineVars = [...mvs]
      state.isChanged = true;
    },
    deletingVar: state => {
      state.loading = true
      state.hasErrors = false
      state.machineVar = false
    },
    deleteVarSuccess: (state, { payload }) => {
      state.loading = false
      state.machineVar = false
      let mvs = _.cloneDeep(state.machineVars)
      let index = mvs.findIndex(v => {
        return v.id === payload.id;
      });
      state.machineVars = [...mvs.filter((_, i) => i !== index)]
      state.isChanged = true;
    },
    newVar: state => {
      state.machineVar = { "id": "new", "type": "", multiple: false, name: "", incremental: false, operatorEdit: false, operatorView: false, measure: "" }
    },
    selectVar: (state, action) => {
      state.machineVar = action.payload
    },
    changeVar: (state, { payload }) => {
      let c = state.machineVar
      c[payload.name] = payload.value
      if (payload.name === "operatorEdit" && payload.value) {
        c["operatorView"] = true
      }
      state.machineVar = c
    },
    enableInsertImage: (state) => {
      state.insertImage = true;
    },
    disableInsertImage: (state) => {
      state.insertImage = false;
    },
    updateImageSuccess: (state, { payload }) => {
      state.loading = false;
      state.machine = payload;
      state.insertImage = false;
    },
    enableDeletingImage: (state) => {
      state.deletingImage = true;
    },
    disableDeletingImage: (state) => {
      state.deletingImage = false;
    },
    deleteImageSuccess: (state, { payload }) => {
      state.loading = false;
      state.machine = payload;
      state.deletingImage = false;
    }
  },
});

export const { resetState, reset, getting, getSuccess, create, createSuccess, creating, edit, cancelEdit, change, enableErrors,
  updating, updateSuccess, select, confirmDelete, deleting, deleteSuccess, getEventsSuccess,
  creatingVar, createVarSuccess, updatingVar, updateVarSuccess, deletingVar, deleteVarSuccess,
  newVar, selectVar, changeVar, enableInsertImage, disableInsertImage, updateImageSuccess, deleteImageSuccess, enableDeletingImage,
  disableDeletingImage, getMachineSuccess } = machinesSlice.actions;
export const machinesSelector = (state) => state.machines;
export default machinesSlice.reducer;

export function getAPI(workcentre) {
  return async (dispatch) => {
    dispatch(getting());
    //dispatch(reset());
    const response = await fetch(SERVER_URL + "/workcentre/" + workcentre.id + "/machine",
      {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      }
    );
    const data = await response.json();
    if (response.status === 200) {
      dispatch(getSuccess(data));
    } else if (response.status === 403) {
      dispatch(logout());
    } else {
      dispatch(showError(data.message));
      dispatch(enableErrors());
    }
  };
}

export function getMachineAPI(machine) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(getting());
    const response = await fetch(SERVER_URL + "/machine/" + machine.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
      }
    );

    const data = await response.json();
    if (response.status === 200) {
      dispatch(getMachineSuccess(data));
    } else if (response.status === 403) {
      dispatch(logout());
    } else {
      dispatch(showError(data.message));
      dispatch(enableErrors());
    }
  };
}

export function getFullListAPI() {
  return async (dispatch) => {
    dispatch(getting());
    dispatch(reset());
    try {
      const response = await fetch(SERVER_URL + "/machine",
        {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8"
          },
        }
      );
      const data = await response.json();
      dispatch(setServerNotResponding(false));
      dispatch(resetNotificationErrors());
      if (response.status === 200) {
        dispatch(getSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      dispatch(enableErrors());
      dispatch(setServerNotResponding(true));
      dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
    }
  };
}

export function createAPI(workcentre, machine) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(creating());
    let params = { machine: machine };
    const response = await fetch(SERVER_URL + "/api/workcentre/" + workcentre.id + "/machine",
      {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(params),
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
        dispatch(cancelEdit());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function updateAPI(workcentre, machine) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(updating());
    let params = { machine: machine };
    const response = await fetch(SERVER_URL + "/api/workcentre/" + workcentre.id + "/machine/" + machine.id,
      {
        mode: "cors",
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(params),
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
        dispatch(cancelEdit());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function deleteAPI(workcentre, machine) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(deleting());
    const response = await fetch(SERVER_URL + "/api/workcentre/" + workcentre.id + "/machine/" + machine.id,
      {
        mode: "cors",
        method: "DELETE",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(deleteSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}


export function getEvents(machine, from, to, machineEvents) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    const response = await fetch(SERVER_URL + "/machine/" + machine.id + "/events?from=" + from + "&to=" + to,
      {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getEventsSuccess({ data: data, me: machineEvents }));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function createVarAPI(machine, machineVar) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(creatingVar())
    let params = { var: machineVar }
    const response = await fetch(SERVER_URL + '/api/workcentre/' + machine.workcentre.id + "/machine/" + machine.id + '/var', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(createVarSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function updateVarAPI(machine, machineVar) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(updatingVar())
    let params = { var: machineVar }
    const response = await fetch(SERVER_URL + '/api/workcentre/' + machine.workcentre.id + "/machine/" + machine.id + '/var/' + machineVar.id, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updateVarSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function deleteVarAPI(machine, machineVar) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(deletingVar())
    const response = await fetch(SERVER_URL + '/api/workcentre/' + machine.workcentre.id + "/machine/" + machine.id + '/var/' + machineVar.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });
    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(deleteVarSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}
export function saveImageAPI(workcentre, machine, imageFile) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(updating());
    const formData = new FormData();
    //serve per valorizzare immagine a seconda che i parametri siano null oppure no
    let image = null;
    if (imageFile !== null) {
      image = imageFile[0]
    }
    formData.append('imageFile', image)
    const response = await fetch(SERVER_URL + "/api/workcentre/" + workcentre.id + "/machine/" + machine.id + "/image/",
      {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + access_token
        },
        body: formData,
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateImageSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function deleteImageAPI(workcentre, machine) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(updating());
    const response = await fetch(SERVER_URL + "/api/workcentre/" + workcentre.id + "/machine/" + machine.id + "/image/",
      {
        mode: 'cors',
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + access_token
        },
      }
    );
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(deleteImageSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}