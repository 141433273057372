import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { machineEventsSelector, getAPI, removeMachineAPI, addMachinesAPI } from "./slice";
import { machinesSelector, getFullListAPI as getMachines } from "../machines/slice";
import { Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import PaperAutocomplete from "../utility/PaperAutocomplete";

const MachinesPage = ({ machineEvent, extLoading }) => {
    const dispatch = useDispatch();
    const myClasses = mesStyles();
    const { machines } = useSelector(machinesSelector);
    const { loading, reload } = useSelector(machineEventsSelector);
    const [removeMachine, setRemoveMachine] = React.useState(false);
    const [linking, setLinking] = React.useState(false);
    const [wl, setWl] = React.useState([]);

    useEffect(() => {
        dispatch(getMachines(0, 100000, "name", "asc", false, false));
    }, [dispatch]);

    useEffect(() => {
        if (reload === true && loading === false) {
            dispatch(getAPI(machineEvent.id));
        }
    }, [dispatch, reload, loading, machineEvent])

    const onUnlick = (w) => {
        setRemoveMachine(w);
    };

    const cancelUnlink = () => {
        setRemoveMachine(false);
    };

    const doUnlink = () => {
        dispatch(removeMachineAPI(machineEvent, removeMachine));
        setRemoveMachine(false);
    };

    const onLink = () => {
        setLinking(true);
    };

    const cancelLink = () => {
        setLinking(false);
        setWl([]);
    };

    const doLink = () => {
        dispatch(addMachinesAPI(machineEvent, wl));
        setLinking(false);
    };

    const linkAll = () => {
        if (machines) {
            let machinesToAdd = machines.filter((w) => machineEvent.machines.every((pw) => w.id !== pw.machine_id))
            dispatch(addMachinesAPI(machineEvent, machinesToAdd))
        }
    }

    if (machines !== false) {
        const availableMachines = machines.filter((w) =>
            machineEvent.machines.every((pw) => w.id !== pw.machine_id)
        );

        return (
            <PaperForm>
                <div className={myClasses.titleFormSpaceBetween}>
                    <Typography component="h1" variant="h6" color="inherit">{customLabel("machine.machines")}</Typography>
                </div>
                <div className={myClasses.tableContainer}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{customLabel("workcentre.workcentre")}</TableCell>
                                <TableCell>{customLabel("machine.name")}</TableCell>
                                <TableCell>{customLabel("machine.code")}</TableCell>
                                <TableCell align="right" style={{ width: '250px' }}>
                                    <Button color="primary" onClick={linkAll} disabled={loading || extLoading}>{customLabel("button.linkAll")}</Button>
                                    <Button color="primary" onClick={onLink} disabled={loading || extLoading}>{customLabel("button.link")}</Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {machineEvent.machines.map((w) => {
                                return (
                                    <TableRow key={w.id}>
                                        <TableCell>{w.workcentre.code} - {w.workcentre.name}</TableCell>
                                        <TableCell>{w.name}</TableCell>
                                        <TableCell>{w.code}</TableCell>
                                        <TableCell align="right" className={myClasses.tableCell1Button}>
                                            <Button onClick={() => { onUnlick(w) }} color="secondary" disabled={loading || extLoading}>
                                                {customLabel("button.unlink")}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                    <Dialog open={removeMachine !== false} keepMounted>
                        <DialogTitle>{customLabel("function.confirm").toUpperCase()}</DialogTitle>
                        <DialogContent><DialogContentText>{customLabel("function.confirmUnlink")}</DialogContentText></DialogContent>
                        <DialogActions>
                            <Button disabled={loading || extLoading} onClick={doUnlink} color="primary">{customLabel("button.confirm")}</Button>
                            <Button disabled={loading || extLoading} onClick={cancelUnlink} color="default">{customLabel("button.cancel")}</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={linking} keepMounted fullWidth>
                        <DialogTitle>{customLabel("machineEvent.linkMachines")}</DialogTitle>
                        <DialogContent>
                            <Autocomplete
                                PaperComponent={PaperAutocomplete}
                                value={wl} onChange={(event, newValue) => { setWl(newValue) }} multiple options={availableMachines}
                                getOptionLabel={(option) => option.code + " - " + option.name + " (" + option.workcentre.code + ")"} filterSelectedOptions
                                renderInput={(params) => (<TextField {...params} label={customLabel("machine.machine")} error={wl.length === 0} />)} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={doLink} color="primary" disabled={wl.length === 0 || loading || extLoading}>{customLabel("button.confirm")}</Button>
                            <Button disabled={loading || extLoading} onClick={cancelLink} color="default">{customLabel("button.close")}</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </PaperForm>
        );
    }
    return null;
};

export default MachinesPage;
