import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { customLabel } from "../../utility/customLabel";
import useInterval from 'react-useinterval';
import Timeline, {
    TimelineHeaders,
    TimelineMarkers,
    DateHeader,
    TodayMarker,
    SidebarHeader,
} from "react-calendar-timeline";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, CircularProgress, Divider, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import 'react-calendar-timeline/lib/Timeline.css';
import moment from "moment";
import { bishopFormats } from '../../../constants';
import { machinesSelector, getFullListAPI as getMachines } from '../../machines/slice'
import { maintenancesSelector, getListAPI } from '../../maintenances/slice'
import { selector, createDeadlines, cancelReloadTimeline, setReloadTimeline } from './slice'
import { doChange } from '../slice'
import PopoverLegend from "../../utility/PopoverLegend";
import LegendPreview from "../../utility/LegendPreview";
import { mesStyles } from "../../utility/ultrafabStyles";
import { useAnchor } from "../../customHooks/useAnchor";
import { EXTRA_LEGEND_ITEMS } from "./utility";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: '#676767',
    },
    title: {
        display: "flex",
        justifyContent: "space-between",
    },
    headerLeft: {
        backgroundColor: '#676767',
        width: 150,
        padding: theme.spacing(2),
        color: "#fff",
        fontWeight: "bold",
        fontSize: "14pt"
    },
    event: {
        margin: theme.spacing(2),
        padding: "5px",
        fontSize: "8pt",
        borderRadius: "5px"
    },
    button: {
        margin: theme.spacing(1),
    },
    button_details: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    button_odp: {
        margin: theme.spacing(1),
        float: 'right',
        textAlign: 'left',
        justifyContent: 'flex-start',
        //width: 300
    },
    form: {
        width: "calc(50% - 32px)",
        marginRight: theme.spacing(1)
    },
    legend: {
        margin: theme.spacing() / 1,
        textAlign: "right"
    },
}));

let moved
let downListener = () => {
    moved = false
}
document.addEventListener('mousedown', downListener)
let moveListener = () => {
    moved = true
}
document.addEventListener('mousemove', moveListener)

let reloadTimeout;

const MaintenancesTimeline = () => {
    const classes = useStyles();
    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { odps, loading } = useSelector(maintenancesSelector);
    const { machines } = useSelector(machinesSelector);
    const { creatingLoading, reloadTimeline } = useSelector(selector);
    const [visibleTimeStart, setVisibleTimeStart] = React.useState(moment().add(-10, 'day').startOf('day').unix() * 1000);
    const [visibleTimeEnd, setVisibleTimeEnd] = React.useState(moment().add(30, 'day').endOf('day').unix() * 1000);
    const [groups, setGroups] = React.useState([]);
    const [items, setItems] = React.useState([]);
    const [selectedOdp, setSelectedOdp] = React.useState(false);
    const [create, setCreate] = React.useState(false);
    const [refresh, setRefresh] = React.useState(true);
    const [prevLength, setPrevLength] = React.useState(0);
    const [from, setFrom] = React.useState(new Date().getTime())
    const [to, setTo] = React.useState(new Date().getTime())
    const [anchorPopover, { handleOpen: handleOpenPopover, handleClose: handleClosePopover }, openPopover] = useAnchor();

    useEffect(() => {
        dispatch(getMachines());
        dispatch(setReloadTimeline());
    }, [dispatch]);

    useEffect(() => {
        if (reloadTimeline) {
            dispatch(cancelReloadTimeline());
            dispatch(getListAPI(0, 999999, "deliveryDate", "asc", null, null, false, false, visibleTimeStart.toFixed(), visibleTimeEnd.toFixed()));
        }
    }, [dispatch, reloadTimeline, visibleTimeEnd, visibleTimeStart]);

    useInterval(() => {
        setRefresh(true)
        dispatch(getListAPI(0, 999999, "deliveryDate", "asc", null, null, false, false, visibleTimeStart.toFixed(), visibleTimeEnd.toFixed()));
    }, 10000);

    useInterval(() => {
        redesignItems()
    }, 1000);

    const handleChangeDate = (name, value) => {
        if (name === "from") {
            setFrom(new Date(value.format()).getTime());
        } else {
            setTo(new Date(value.format()).getTime());
        }
    };

    const myFormat = {
        year: { long: 'YYYY', mediumLong: 'YYYY', medium: 'YYYY', short: 'YYYY' },
        month: { long: 'MM/YYYY', mediumLong: 'MM/YYYY', medium: 'MM/YY', short: 'MM' },
        week: { long: 'DD/MM', mediumLong: 'DD/MM', medium: 'DD/MM', short: 'DD/MM' },
        day: { long: 'DD/MM/YYYY', mediumLong: 'DD/MM/YY', medium: 'DD', short: 'DD' },
        hour: { long: 'DD/MM/YYYY HH:00', mediumLong: 'DD/MM, HH:00', medium: 'HH:00', short: 'HH' },
        minute: { long: 'DD/MM/YYYY HH:mm', mediumLong: 'HH:mm', medium: 'HH:mm', short: 'mm', },
        second: { long: 'HH:mm:ss', mediumLong: 'HH:mm:ss', medium: 'mm:ss', short: 'ss', }
    }
    const formatLabel = ([timeStart, timeEnd], unit, labelWidth, formatOptions: LabelFormat = myFormat) => {
        let format
        if (labelWidth >= 150) {
            format = formatOptions[unit]['long']
        } else if (labelWidth >= 100) {
            format = formatOptions[unit]['mediumLong']
        } else if (labelWidth >= 50) {
            format = formatOptions[unit]['medium']
        } else {
            format = formatOptions[unit]['short']
        }
        return timeStart.format(format)
    }

    const onItemSelect = (itemId, e, time) => {
        let i = items.findIndex(e => {
            return e.id === itemId;
        });
        let odp = items[i].itemProps.odp;
        setSelectedOdp(odp);
    }

    const onItemClick = (itemId, e, time) => {
        let i = items.findIndex(e => {
            return e.id === itemId;
        });
        let odp = items[i].itemProps.odp;
        if (selectedOdp && selectedOdp.code === odp.code) {
            setSelectedOdp(false)
        } else {
            setSelectedOdp(odp.code)
        }
    }

    const onCanvasClick = (groupId) => {
        setSelectedOdp(false)
    }

    const doCreateDeadlines = () => {
        setCreate(false)
        dispatch(createDeadlines(from, to))
    }

    const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
        return (
            <div
                {...getItemProps({
                    style: {
                        backgroundColor: item.bgColor,
                        background: item.bgColor,
                        color: item.color,
                        borderColor: "#4c4c4c",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderRadius: 4,
                        borderLeftWidth: 1,
                        borderRightWidth: 1
                    },
                    onMouseUp: () => {
                        if (!moved) {
                            if (selectedOdp && selectedOdp.id === item.itemProps.odp.id) {
                                setSelectedOdp(false);
                            } else {
                                setSelectedOdp(item.itemProps.odp);
                            }
                            setGroups([])
                        }
                    }
                })}
            >
                {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

                <div
                    style={{
                        height: itemContext.dimensions.height,
                        overflow: "hidden",
                        paddingLeft: 3,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {itemContext.title}
                </div>

                {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
            </div>
        );
    };

    const toggleCreate = () => {
        setCreate(!create);
    }

    const toggleOdp = () => {
        setSelectedOdp(false);
        setRefresh(true)
    }

    const redesignItems = () => {
        let ni = [];
        for (let i in odps) {
            let event = odps[i];
            var item = {};
            item.id = event.id;
            item.backgroundColor = event.toBeProcessed ? "#ffaffa" : "#8dd8dd";
            item.bgColor = item.backgroundColor;
            item.background = item.backgroundColor;
            if (event.extraordinary) {
                item.title = customLabel("maintenance.extraordinary").toUpperCase()
            } else {
                item.title = event.maintenanceType.name
            }
            item.color = "#000";
            item.group = event.machine.id;
            if (event.startDate === null) {
                item.start_time = moment(new Date(event.deliveryDate)).startOf("day");
                item.end_time = moment(new Date(event.deliveryDate)).endOf("day");
            } else {
                item.start_time = moment(new Date(event.startDate)).startOf("day");
                item.end_time = moment(new Date(event.startDate)).endOf("day");
            }
            item.itemProps = {
                'odp': event
            };

            ni.push(item);
        }
        setItems(ni);
        return ni
    }

    if (groups.length !== 0 && refresh && odps.length !== prevLength) {
        let ni = redesignItems()
        setItems(ni);
        setPrevLength(ni.length)
        setRefresh(false)
    }

    if (groups.length === 0 && machines !== false && machines.length > 0) {
        let ng = []
        for (let m in machines) {
            let item = {};
            item.id = machines[m].id;
            item.title = machines[m].name;
            ng.push(item);
        }
        setGroups(ng)
    }

    const reloadMaintenances = (vts, vte) => {
        dispatch(getListAPI(0, 999999, "deliveryDate", "asc", null, null, false, false, vts.toFixed(), vte.toFixed()));
    }

    const onTimeChange = (vts, vte, updateScrollCanvas) => {
        if (reloadTimeout !== undefined) {
            clearTimeout(reloadTimeout)
        }
        setVisibleTimeStart(vts)
        setVisibleTimeEnd(vte)
        updateScrollCanvas(vts, vte)
        let rl = setTimeout(() => {
            reloadMaintenances(vts, vte)
            setRefresh(true)
        }, 250)
        reloadTimeout = rl;
    }
    return (
        <React.Fragment>
            <div>
                <Divider />
                <Grid container alignContent="space-around">
                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                        {loading || creatingLoading ? <CircularProgress /> : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <Button variant="outlined" color="secondary" className={classes.button_odp} onClick={toggleOdp}>
                            {customLabel("timeline.selectedMaintenance")}: {selectedOdp && selectedOdp.maintenanceType ? selectedOdp.maintenanceType.name + " - " + selectedOdp.machine.name : null}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                        <Button variant="outlined" className={classes.button_details} disabled={!selectedOdp} component={Link} to={"/maintenances/" + selectedOdp.id}
                            onClick={() => dispatch(doChange("/maintenances"))}>
                            {customLabel("odp.details")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                        <Button color={"primary"} className={classes.button} onClick={toggleCreate}>
                            {customLabel("maintenance.createDeadlines")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                        <div className={classes.legend}>
                            <div className={myClasses.dashedUnderline} onMouseEnter={(e) => handleOpenPopover(e.currentTarget, false)} onMouseLeave={handleClosePopover}>
                                <LegendPreview name={customLabel("legend.legend")} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
                {groups.length !== 0 ? (
                    <Timeline
                        groups={groups}
                        items={items}
                        minZoom={60 * 1000}
                        itemHeightRatio={0.9}
                        traditionalZoom={true}
                        onTimeChange={onTimeChange}
                        itemRenderer={itemRenderer}
                        defaultTimeStart={moment.unix(visibleTimeStart)}
                        defaultTimeEnd={moment.unix(visibleTimeEnd)}
                        visibleTimeStart={visibleTimeStart}
                        visibleTimeEnd={visibleTimeEnd}
                        onItemSelect={onItemSelect}
                        onItemClick={onItemClick}
                        onCanvasClick={onCanvasClick}
                    >
                        <TimelineHeaders>
                            <SidebarHeader>
                                {({ getRootProps }) => {
                                    return <div className={classes.headerLeft}>{customLabel("machine.machines")}</div>
                                }}
                            </SidebarHeader>
                            <DateHeader unit="primaryHeader" labelFormat={formatLabel} className={classes.header} />
                            <DateHeader labelFormat={formatLabel} />
                        </TimelineHeaders>
                        <TimelineMarkers>
                            <TodayMarker />
                        </TimelineMarkers>
                    </Timeline>
                ) : null}
                <Dialog open={create !== false} keepMounted fullWidth maxWidth="md">
                    <DialogTitle>{customLabel("maintenance.createDeadlines")}</DialogTitle>
                    <DialogContent>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <DatePicker className={classes.form}
                                disableToolbar
                                allowKeyboardControl={false}
                                variant="inline"
                                format={bishopFormats.L}
                                autoOk
                                onChange={(date) => handleChangeDate("from", date)}
                                label={customLabel("function.from")}
                                value={from}
                            />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <DatePicker className={classes.form}
                                disableToolbar
                                allowKeyboardControl={false}
                                variant="inline"
                                format={bishopFormats.L}
                                autoOk
                                onChange={(date) => handleChangeDate("to", date)}
                                label={customLabel("function.to")}
                                value={to}
                            />
                        </MuiPickersUtilsProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={doCreateDeadlines} color="primary">{customLabel("button.confirm")}</Button>
                        <Button onClick={toggleCreate} color="default">{customLabel("button.cancel")}</Button>
                    </DialogActions>
                </Dialog>
            </div>
            {anchorPopover ? (
                <PopoverLegend name={customLabel("legend.legend").toUpperCase()} list={EXTRA_LEGEND_ITEMS} open={openPopover} anchorEl={anchorPopover}
                    openPopover={handleOpenPopover} closePopover={handleClosePopover}
                    anchorVertical={"bottom"} anchorHorizontal={"right"} transformVertical={"top"} transormHorizontal={"left"}
                />
            ) : null}
        </React.Fragment >
    );
};

export default MaintenancesTimeline;
