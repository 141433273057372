import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInterval from 'react-useinterval';

import { Grid, Container } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import NotificationsPage from "../notifications/NotificationsPage";
//import { configurationSelector, getAPI as getConfigurationAPI } from "../configuration/slice";
import { machineViewSelector, /*getMachineAPI,*/ getMachineEventAPI, getLastOdpsAPI, getNextOdpsAPI, getProcessVarsAPI, getEvents } from "./slice";
import { machineEventsSelector } from "../machineEvents/slice";
import CurrentProcess from "./CurrentProcess";
import CurrentMachineData from "./CurrentMachineData";
import Odps from "./Odps";
import { doChange } from "../dashboard/slice";
import PopoverLinkItem from "../utility/PopoverLinkItem";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        paddingRight: 24,
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        backgroundColor: "#d01a28",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title: {
        flexGrow: 1,
        textDecoration: "none",
        fontWeight: "normal",
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        padding: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(1),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
    selected: {
        backgroundColor: "#0000001a",
    },
    operator: {
        dispay: "flex",
        alignItems: "center"
    },
    fullIcon: {
        marginLeft: theme.spacing(10)
    }
}));
/**
 * Rendreizzato solo se machine è valorizzato
 * @param {object} machine 
 * @param {object} configuration 
 * @returns 
 */
const MachineViewPage = ({ machine, configuration }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { currentProcess } = useSelector(machineViewSelector);
    const { machineEvents } = useSelector(machineEventsSelector);
    const [startDate, setStartDate] = React.useState(moment().startOf("day").unix() * 1000)
    const [endDate, setEndDate] = React.useState(moment().unix() * 1000)
    const [anchorText, setAnchorText] = React.useState(null);
    const [textLegend, setTextLegend] = React.useState(false);
    const [parentIdLegend, setParentIdLegend] = React.useState(false);
    const [parentUrlLegend, setParentUrlLegend] = React.useState(false);
    const openText = Boolean(anchorText)

    useInterval(() => updatingInterface(), 10000);

    const updatingInterface = () => {
        if (machine) {
            dispatch(getMachineEventAPI(machine.id, false));
            dispatch(getNextOdpsAPI(machine.id, "0", "5"));
            dispatch(getLastOdpsAPI(machine.id));
            dispatch(getEvents(machine, startDate, endDate, machineEvents));
            if (currentProcess) {
                dispatch(getProcessVarsAPI(currentProcess))
            }
        }
    };

    useEffect(() => {
        dispatch(getMachineEventAPI(machine.id, false));
        dispatch(getNextOdpsAPI(machine.id, "0", "5"));
        dispatch(getLastOdpsAPI(machine.id));
        dispatch(getEvents(machine, startDate, endDate, machineEvents));
    }, [dispatch, machine, machineEvents, startDate, endDate]);

    useEffect(() => {
        if (currentProcess) {
            dispatch(getProcessVarsAPI(currentProcess))
        }
    }, [dispatch, currentProcess])

    /**
     * 
     * @param {*} newTarget 
     * @param {*} currentTarget 
     * @param {*} text 
     * @param {*} parentId 
     * @param {*} parentUrl 
     */
    const handlePopoverOpenText = (newTarget, currentTarget, text, parentId, parentUrl) => {
        if (typeof currentTarget === "object") {
            setAnchorText(currentTarget);
        } else if (typeof newTarget === "object") {
            setAnchorText(newTarget);
        } else {
            setTextLegend(null);
        }
        setTextLegend(text);
        setParentIdLegend(parentId);
        setParentUrlLegend(parentUrl);
    };
    const handlePopoverCloseText = () => {
        setAnchorText(null);
        setTextLegend(false);
    };

    const handleChangeDate = (name, value) => {
        if ((name === "startDate" && value < endDate) || (name === "endDate" && value > startDate)) {
            if (name === "startDate") {
                setStartDate(value.startOf('day'));
            }
            if (name === "endDate") {
                setEndDate(value.endOf('day'));
            }
        }
    };

    const changeUrl = (url) => {
        let businessObject = "";
        if (url.split("/").length === 1) {
            businessObject = url.split()[0]
        } else if (url.split("/").length === 2) {
            businessObject = url.split()[1]
        }
        dispatch(doChange(`/${businessObject}`))
    }

    return (
        <div className={classes.root}>
            <NotificationsPage />
            <PopoverLinkItem text={textLegend} openPopover={handlePopoverOpenText} open={openText} anchorEl={anchorText} closePopover={handlePopoverCloseText}
                anchorVertical={"top"} anchorHorizontal={"center"} transformVertical={"bottom"} transormHorizontal={"right"} changeUrl={changeUrl}
                parentId={parentIdLegend} parentUrl={parentUrlLegend} />
            <main className={classes.content}>
                <Container maxWidth={false} className={classes.container}>
                    <Grid container spacing={1} alignItems="flex-start" justify="center" >
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <CurrentProcess machine={machine} configuration={configuration} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <CurrentMachineData process={currentProcess} machine={machine} configuration={configuration}
                                startDate={startDate} endDate={endDate} handleChangeDate={handleChangeDate}
                                handlePopoverOpenText={handlePopoverOpenText} handlePopoverCloseText={handlePopoverCloseText} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Odps handlePopoverOpenText={handlePopoverOpenText} handlePopoverCloseText={handlePopoverCloseText} />
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    )
}

export default MachineViewPage;