import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { processTypesSelector, getListAPI, setReload, initMaintenancePhaseFilter, changeMaintenancePhaseFilter } from "../processTypes/slice";
import { Box, Typography, Divider } from "@material-ui/core";
import TableList from "../utility/TableList";
import { headers } from "./utility";
import HeaderTableList from "../utility/HeaderTableList";
import { configurationSelector } from "../configuration/slice";
import { useBoolean } from "../customHooks/useBoolean";

const MaintenanceTypesPage = () => {
  const dispatch = useDispatch();
  const { processTypes, loading, reload, results, maintenancePhaseFilter } = useSelector(processTypesSelector);
  const { configuration } = useSelector(configurationSelector);
  const [firstFetchDone, { setTrue: setFirstFetchDoneTrue }] = useBoolean(false);

  useEffect(() => {
    if (!maintenancePhaseFilter && configuration) {
      dispatch(initMaintenancePhaseFilter(configuration.showFilters))
    }
  }, [configuration, maintenancePhaseFilter, dispatch])

  useEffect(() => {
    if (maintenancePhaseFilter && !firstFetchDone) {
      dispatch(getListAPI(maintenancePhaseFilter.page, maintenancePhaseFilter.rowsPerPage, maintenancePhaseFilter.orderBy, maintenancePhaseFilter.order, maintenancePhaseFilter.name, maintenancePhaseFilter.code, maintenancePhaseFilter.maintenance));
      window.scrollTo(0, 0);
      setFirstFetchDoneTrue();
    }
  }, [dispatch, maintenancePhaseFilter, firstFetchDone, setFirstFetchDoneTrue]);

  if (reload && !loading) {
    dispatch(getListAPI(maintenancePhaseFilter.page, maintenancePhaseFilter.rowsPerPage, maintenancePhaseFilter.orderBy, maintenancePhaseFilter.order, maintenancePhaseFilter.name, maintenancePhaseFilter.code, maintenancePhaseFilter.maintenance));
  }

  const handleChangePage = (event, newPage, doReload) => {
    dispatch(changeMaintenancePhaseFilter({ name: "page", value: newPage }))
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changeMaintenancePhaseFilter({ name: "rowsPerPage", value: parseInt(event.target.value, 10) }))
    dispatch(changeMaintenancePhaseFilter({ name: "page", value: 0 }))
  };

  const onRequestSort = (property) => {
    const isAsc = maintenancePhaseFilter.orderBy === property && maintenancePhaseFilter.order === "asc"
    let newOrder = isAsc ? "desc" : "asc";
    dispatch(changeMaintenancePhaseFilter({ name: "order", value: newOrder }))
    dispatch(changeMaintenancePhaseFilter({ name: "orderBy", value: property }))
  };

  const changeFilters = (name, value) => {
    dispatch(changeMaintenancePhaseFilter({ name: name, value: value }))
  };

  const resetFilter = () => {
    dispatch(initMaintenancePhaseFilter(true));
  };

  const toggleFilter = () => {
    if (!maintenancePhaseFilter.showFilter) {
      dispatch(initMaintenancePhaseFilter(true));
    } else {
      dispatch(initMaintenancePhaseFilter(false));
    }
  }

  const applyReload = useCallback(() => {
    dispatch(setReload());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Typography component="h1" variant="h6" color="inherit">
        {customLabel("maintenancePhase.maintenancePhases")}
      </Typography>
      <HeaderTableList filter={maintenancePhaseFilter} toggleFilter={toggleFilter} showAdd={true} businessObject={"admin/maintenancePhases"} />
      <Divider />
      <Box>
        {processTypes && maintenancePhaseFilter ? (
          <TableList items={processTypes} headers={headers} mainObject={"maintenancePhase."} redirectObject={"admin/maintenancePhases"}
            setPage={handleChangePage} setRowsPerPage={handleChangeRowsPerPage} results={results}
            onRequestSort={onRequestSort} nameList={false} codeList={false} changeFilters={changeFilters}
            resetFilter={resetFilter} filter={maintenancePhaseFilter} applyReload={applyReload} />
        ) : null}
        {loading ? <div>{customLabel("function.loading").toUpperCase()}</div> : null}
      </Box>
    </React.Fragment>
  );
};

export default MaintenanceTypesPage;
