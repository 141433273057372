import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../constants";
import { showError, showSuccess } from "../notifications/slice";
import { logout, refreshAPI } from "../main/slice";
import { customLabel } from "../utility/customLabel";
import { customFilter } from "./utility";

export const initialState = {
  loading: false,
  hasErrors: false,
  users: false,
  reload: false,
  results: 0,
  editmode: false,
  user: false,
  useredit: false,
  deleting: false,
  isCreated: false,
  isDeleted: false,
  filter: false
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetState: (state) => {
      if (!state.filter) {
        Object.assign(state, initialState);
      } else {
        let tempState = {};
        let currentFilter = { ...state.filter }
        Object.assign(tempState, initialState);
        tempState.filter = { ...currentFilter }
        Object.assign(state, tempState);
      }
    },
    gettingList: (state) => {
      state.loading = true;
      state.reload = false;
      state.hasErrors = false;
    },
    getListSuccess: (state, { payload }) => {
      state.users = payload[1];
      state.results = payload[0].total_entries;
      state.loading = false;
    },
    getListByRolesSuccess: (state, { payload }) => {
      state.users = payload;
      state.loading = false;
    },
    setReload: (state) => {
      state.reload = true;
    },
    reset: (state) => {
      state.user = false;
      state.useredit = false;
      state.editmode = false;
      state.isDeleted = false;
      state.isCreated = false;
    },
    getting: (state) => {
      state.user = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.loading = true;
      state.editmode = false;
      state.hasErrors = false;
    },
    getSuccess: (state, { payload }) => {
      payload.confirmPassword = payload.password;
      state.user = payload;
      state.loading = false;
    },
    creating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    create: (state) => {
      let newuser = { id: "new", username: "", firstName: "", lastName: "", email: "", code: "", enabled: false, password: "", errorpassword: "", confirmPassword: "", roles: [] };
      state.user = newuser;
      state.useredit = newuser;
      state.editmode = true;
    },
    createSuccess: (state, { payload }) => {
      state.user = payload;
      state.useredit = false;
      state.loading = false;
      state.isCreated = true;
    },
    resetEdit: (state) => {
      state.editmode = true;
      state.useredit = state.user;
      state.loading = false;
      state.hasErrors = true;
    },
    edit: (state) => {
      state.editmode = true;
      state.useredit = state.user;
    },
    cancelEdit: (state) => {
      state.editmode = false;
      state.useredit = false;
    },
    change: (state, { payload }) => {
      let u = state.useredit;
      let ur = state.useredit.roles.slice();
      let listRoles = payload.roles.slice()

      if (payload.name === "roles") {
        if (payload.checked) {
          let indexOfR = listRoles.findIndex((r) => {
            return r.id.toString() === payload.value;
          })
          ur.push(listRoles[indexOfR])
        } else {
          let indexOfRole = ur.findIndex((r) => {
            return r.id.toString() === payload.value;
          });
          ur = [...ur.filter((_, i) => i !== indexOfRole)];
        }
        u.roles = [...ur];
      } else if (payload.name === "enabled") {
        u[payload.name] = payload.checked;
      } else {
        u[payload.name] = payload.value;
      }
      let errorpassword = false;
      if (u.id === "new") {
        errorpassword = state.useredit.password !== state.useredit.confirmPassword || state.useredit.password === "";
      } else {
        errorpassword = state.useredit.password !== state.useredit.confirmPassword && state.useredit.password !== "";
      }
      u.errorpassword = errorpassword;
      state.useredit = u;
    },
    updating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    updateSuccess: (state, { payload }) => {
      state.user = payload;
      state.loading = false;
      state.editmode = false;
    },
    confirmDelete: (state) => {
      state.deleting = !state.deleting;
    },
    deleting: (state) => {
      state.deleting = false;
      state.loading = true;
      state.hasErrors = false;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.isDeleted = true;
    },
    enableErrors: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    changeFilter: (state, { payload }) => {
      state.filter[payload.name] = payload.value;
    },
    /**
     * 
     * @param {*} state 
     * @param {boolean} payload showFilter 
     */
    initFilter: (state, { payload }) => {
      let tempFilter = { ...customFilter };
      tempFilter["showFilter"] = payload;
      state.filter = { ...tempFilter };
    }
  },
});

export const { resetState, gettingList, getListSuccess, setReload, reset, getting, getSuccess, create, creating, createSuccess,
  edit, cancelEdit, change, updating, updateSuccess, confirmDelete, enableErrors, deleting, deleteSuccess, resetEdit, getListByRolesSuccess,
  changeFilter, initFilter } = usersSlice.actions;

export const usersSelector = (state) => state.users;

export default usersSlice.reducer;

export function getListAPI(page, rowsPerPage, orderBy, order, username, code, role) {

  return async (dispatch) => {
    dispatch(gettingList());
    dispatch(reset());

    let url = SERVER_URL + "/user?page=" + page + "&per_page=" + rowsPerPage + "&orderBy=" + orderBy + "&order=" + order;
    if (username !== false) {
      url = url + "&username=" + username;
    }
    if (code !== false) {
      url = url + "&code=" + code;
    }
    if (role !== false) {
      url = url + "&role=" + role.id;
    }
    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getListSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function getListByRolesAPI(roles) {

  return async (dispatch) => {
    dispatch(gettingList());
    dispatch(reset());

    let url = SERVER_URL + "/roles/user/" + roles.toString()
    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getListByRolesSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function updateAPI(user) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { user: user };
  return async (dispatch) => {
    dispatch(updating());
    const response = await fetch(SERVER_URL + "/api/user/" + user.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(resetEdit());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function createAPI(user) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(creating());
    let params = { user: user };
    const response = await fetch(SERVER_URL + "/api/user", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function getAPI(id) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(getting());
    const response = await fetch(SERVER_URL + "/api/user/" + id, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function deleteAPI(user) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(deleting());
    const response = await fetch(SERVER_URL + "/api/user/" + user.id, {
      mode: "cors",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(deleteSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function getFullListAPI() {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(gettingList());
    dispatch(reset());
    const response = await fetch(SERVER_URL + "/api/user",
      {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getListSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}