import React from 'react'
import { useSelector } from "react-redux";

import { operatorViewSelector } from "../slice";
import { customLabel } from '../../utility/customLabel';
import { Grid, Table, TableCell, TableRow, TableBody, TableHead } from "@material-ui/core";
import { mainSelector } from '../../main/slice';

const OperatorMeasurePage = () => {
  const { process } = useSelector(operatorViewSelector);
  const { serverNotResponding } = useSelector(mainSelector);

  //tabella con elenco documenti passati da parametro
  const renderMeasureTools = () => {
    if (process && process.measureTools && process.measureTools.length > 0 && !serverNotResponding) {
      return (
        process.measureTools.map((d) => {
          return (
            <TableRow key={d.id + "_" + d.name}>
              <TableCell>{d.code}</TableCell>
              <TableCell>{d.name}</TableCell>
              <TableCell>{d.serial}</TableCell>
            </TableRow>
          )
        })
      )
    } else {
      return (
        <TableRow>
          <TableCell colSpan={3}>{customLabel("function.notAvailable").toUpperCase()}</TableCell>
        </TableRow>
      )
    }
  };

  return (
    <div style={{ maxHeight: "642px", overflow: "auto" }}>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{customLabel("measureTool.code").toUpperCase()}</TableCell>
                <TableCell>{customLabel("measureTool.name").toUpperCase()}</TableCell>
                <TableCell>{customLabel("measureTool.serial").toUpperCase()}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderMeasureTools()}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </div>
  )
}
export default OperatorMeasurePage;
