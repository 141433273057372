import { ResponsiveBar } from '@nivo/bar'

const BarChart = ({ data, getColor, keys, getPercColor, vertical }) => {
    return (
        <ResponsiveBar
            data={data}
            keys={keys}
            margin={{ bottom: vertical ? 25 : 0 }}
            padding={vertical ? 0.4 : 0.2}
            layout={vertical ? "vertical" : "horizontal"}
            groupMode={vertical ? "grouped" : null}
            indexBy={vertical ? "date" : null}
            axisBottom={{ tickSize: 0, tickPadding: 10 }}
            enableGridY={false}
            enableGridX={false}
            colors={getColor}
            labelSkipWidth={20}
            labelSkipHeight={10}
            label={d => vertical ? `${d.value}` : `${d.value}%`}
            labelTextColor={getPercColor}
            tooltip={({ id, value, color }) => (
                <div style={{ padding: 10, background: '#fff', width: 'auto', boxShadow: '0 0 1px 1px rgba(224, 224, 224, 1)' }}>
                    {vertical ?
                        <b>{id}: {value}</b>
                        : <b>{id}: {value}%</b>}
                </div>
            )}
        />
    )
}

export default BarChart