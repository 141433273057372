export const customFilter = {
    mesName: "users",
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "code",
    showFilter: false,
    code: false,
    username: false,
    roles: false
}

export const headers = {
    code: { filter: true, type: "string" },
    username: { filter: true, type: "string" },
    name: { filter: false, type: "string" },
    roles: { filter: true, type: "objectList", name: "roles" },
    email: { filter: false, type: "string" },
    enabled: { filter: false, type: "boolean" },
};
