import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { bomsSelector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete } from "./slice";
import { Box } from "@material-ui/core";

import { Form } from "./Form";
import { Details } from "./Details";
import BomProcessesPage from "./BomProcessesPage";

const BomPage = ({ match }) => {
  const dispatch = useDispatch();

  const { bom, bomedit, editmode, deleting, isCreated, isDeleted, boms, loading } = useSelector(bomsSelector);

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    dispatch(change({ name: name, value: value }));
  };

  const toggleDelete = (event) => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(bomedit));
  };

  const onEdit = () => {
    dispatch(edit());
  };
  const doDelete = () => {
    dispatch(deleteAPI(bom));
  };

  const save = () => {
    if (bom.id === "new") {
      dispatch(createAPI(bomedit));
    } else {
      dispatch(updateAPI(bomedit));
    }
  };

  useEffect(() => {
    const { id } = match.params;
    if (id !== "new") {
      dispatch(getAPI(id));
    } else {
      dispatch(create());
    }
  }, [dispatch, match]);

  const renderBom = () => {
    if (
      bom !== false &&
      !editmode &&
      match.params.id === bom.id.toString()
    )
      return (
        <div>
          <Details bom={bom} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete} loading={loading} />
          <BomProcessesPage bom={bom} />
        </div>
      );
    if (bom !== false && editmode)
      return (
        <Form bomedit={bomedit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save}
          boms={boms} loading={loading} />
      );
    return;
  };

  if (isDeleted && match.params.id === bom.id.toString()) {
    return <Redirect to={"/admin/boms/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/admin/boms/" + bom.id} />;
  }

  return <Box>{renderBom()}</Box>;
};

export default BomPage;
