import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDrProcessEventsAPI } from "../slice";
import { Paper, Box, Divider } from "@material-ui/core";
import { mesStyles } from '../../utility/ultrafabStyles';
import TableListOneButton from "../../utility/TableListOneButton";
import { machineEventsSelector, getListAPI as getMachineEventsAPI } from "../../machineEvents/slice";
import { machinesSelector, getFullListAPI as getMachinesAPI } from "../../machines/slice";
import ButtonProcessStapling from "../../utility/ButtonProcessStapling";
import { customLabel } from '../../utility/customLabel';

const ProcessEvents = ({ odp, loading, drProcessEvents, loadingDrProcessEvents, reloadDrProcessEvents, resultsDrProcessEvents,
    setReloadDrProcessEvents, editProcess }) => {
    const dispatch = useDispatch();
    const myClasses = mesStyles();
    const { machineEvents } = useSelector(machineEventsSelector);
    const { machines, loading: machinesLoading } = useSelector(machinesSelector);
    //oggetti per parametri chiamata list
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("startDate");
    const [type, setType] = React.useState(false);
    const [machine, setMachine] = React.useState(false);
    const [user, setUser] = React.useState(false);
    const [startDateFrom, setStartDateFrom] = React.useState(false);
    const [startDateTo, setStartDateTo] = React.useState(false);
    const [endDateFrom, setEndDateFrom] = React.useState(false);
    const [endDateTo, setEndDateTo] = React.useState(false);

    useEffect(() => {
        if (!machines && !machinesLoading) {
            dispatch(getMachinesAPI());
        }
        if (!machineEvents && machines) {
            dispatch(getMachineEventsAPI(0, 10000, "name", "asc", false));
        }
    }, [dispatch, machines, machineEvents, machinesLoading])

    useEffect(() => {
        setType(false);
        setMachine(false);
        setUser(false);
        setStartDateFrom(false);
        setEndDateFrom(false);
        setStartDateTo(false);
        setEndDateTo(false);
        setPage(0)
        setRowsPerPage(10)
    }, [editProcess.id])

    useEffect(() => {
        if (reloadDrProcessEvents && !loadingDrProcessEvents) {
            dispatch(getDrProcessEventsAPI(odp, editProcess, page, rowsPerPage, orderBy, order, type, machine, startDateFrom, endDateFrom, startDateTo, endDateTo));
        }
    }, [dispatch, odp, editProcess, page, rowsPerPage, orderBy, order, type, machine, user, startDateFrom, endDateFrom, startDateTo, endDateTo, reloadDrProcessEvents, loadingDrProcessEvents]);

    //metodi tablelist
    const handleChangePage = (event, newPage, doReload) => {
        setPage(newPage);
        if (doReload) {
            dispatch(setReloadDrProcessEvents());
        }
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        dispatch(getDrProcessEventsAPI(odp, editProcess, 0, event.target.value, orderBy, order, type, machine, startDateFrom, endDateFrom, startDateTo, endDateTo));
        dispatch(setReloadDrProcessEvents());
    };
    const onRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        let newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        dispatch(setReloadDrProcessEvents());
    };
    const changeFilters = (name, value) => {
        const f =
            name === "type" ? setType : name === "machine" ? setMachine : name === "startDateFrom" ? setStartDateFrom :
                name === "endDateFrom" ? setEndDateFrom : name === "startDateTo" ? setStartDateTo :
                    name === "endDateTo" ? setEndDateTo : null;
        f(value);
        dispatch(setReloadDrProcessEvents());
    };
    const resetFilter = () => {
        setType(false);
        setMachine(false);
        setStartDateFrom(false);
        setEndDateFrom(false);
        setStartDateTo(false);
        setEndDateTo(false);
        dispatch(setReloadDrProcessEvents());
    };
    const headers = {
        type: { filter: true, type: "object" },
        machine: { filter: true, type: "object" },
        startDate: { filter: true, type: "datetime", when: "startDate" },
        endDate: { filter: true, type: "datetime", when: "endDate" },
    };

    return (
        <React.Fragment>
            <Paper className={myClasses.processDetailsPaper}>
                {editProcess.stapling.id !== null ? (
                    <React.Fragment>
                        <ButtonProcessStapling message={customLabel("process.processInStapling").toUpperCase()} stapling={editProcess.stapling} />
                        <Divider />
                    </React.Fragment>
                ) : null}
                <Box>
                    {drProcessEvents !== false ? (
                        <TableListOneButton
                            items={drProcessEvents} headers={headers} mainObject={"machineEvent."} page={page}
                            setPage={handleChangePage} rowsPerPage={rowsPerPage} setRowsPerPage={handleChangeRowsPerPage}
                            results={resultsDrProcessEvents} onRequestSort={onRequestSort} order={order} orderBy={orderBy}
                            changeFilters={changeFilters} resetFilter={resetFilter}
                            typeList={machineEvents} machineList={machines} type={type} machine={machine} odp={odp}
                            startDateFrom={startDateFrom} startDateTo={startDateTo} endDateFrom={endDateFrom} endDateTo={endDateTo} />
                    ) : null}
                </Box>
            </Paper>
        </React.Fragment >
    );
};

export default ProcessEvents;
