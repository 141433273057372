import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import useInterval from 'react-useinterval';
import { machineEventsSelector } from "../../machineEvents/slice";
import {
    getComponentsAPI,
    getOdpEventsAPI,
    getProcessChecksAPI,
    getProcessMachineVarsAPI,
    getProcessUserEventsAPI,
    getProcessVarsAPI,
    odpsSelector, reloadingOdpAPI,
    setCurrentProcess,
    setReloadDrProcessEvents,
    setReloadMachineEvents,
    setReloadProcessEvents,
    unSetCurrentProcess
} from '../slice';

const ReloadingStandardOdp = ({configuration}) => {
    const dispatch = useDispatch();
    const { odp, reload, reloading, loading, odpedit, currentProcess, editProcess, editVar, editCheck, flagEditBom, events,
        errorsOdpEvents, errorsVars, errorsChecks, errorsProcessMachineVars, errorsUserEvents } = useSelector(odpsSelector);
    const { machineEvents } = useSelector(machineEventsSelector);

    useInterval(() => updatingOdp(), 10000);

    //bloccato se finestra dialog checks e vars
    const updatingOdp = () => {
        //if (!newMachineEvent) {
        if (!odpedit && odp && !editVar && !editCheck && !flagEditBom && odp.id !== "new") {
            dispatch(reloadingOdpAPI(odp, machineEvents, errorsOdpEvents))
        }
    };
    //DOPPIO: ricarico l'odp quando reload a true
    useEffect(() => {
        if (reload && !loading && !reloading && odp.id !== "new") {
            dispatch(reloadingOdpAPI(odp, machineEvents, errorsOdpEvents));
        };
    }, [dispatch, odp, reload, loading, reloading, machineEvents, errorsOdpEvents])

    //DOPPIO: primo caricamento di events, dopo di che con lo use interval
    useEffect(() => {
        if (odp && odp.id !== "new" && machineEvents && !events && !errorsOdpEvents) {
            dispatch(getOdpEventsAPI(odp, machineEvents))
        }
    }, [dispatch, odp, machineEvents, events, errorsOdpEvents])

    //STANDARD: viene chiamato ogni volta che cambia l odp e l edit process, quindi su ogni useInterval oppure dopo reloadodp
    useEffect(() => {
        if (editProcess && odp) {
            dispatch(setReloadDrProcessEvents());
            dispatch(setReloadMachineEvents());
            dispatch(setReloadProcessEvents());
            if (!errorsVars) {
                dispatch(getProcessVarsAPI(odp, editProcess));
            }
            if (!errorsChecks) {
                dispatch(getProcessChecksAPI(odp, editProcess));
            }
            if (!errorsProcessMachineVars) {
                dispatch(getProcessMachineVarsAPI(odp, editProcess));
            }
            if (configuration.enableComponents) {
                dispatch(getComponentsAPI(odp, editProcess, configuration.retrieveLotInfo));
            }
        }
    }, [dispatch, editProcess, odp, errorsVars, errorsChecks, errorsProcessMachineVars, configuration.retrieveLotInfo,configuration.enableComponents])

    //STANDARD: ricarico eventi utente ogni qualvolta cambia l'odp, quind ogni useInterval
    useEffect(() => {
        if (odp && odp.id !== "new" && odp.processes.length > 0) {
            odp.processes.forEach(p => {
                if (((currentProcess !== null && p.id === currentProcess.id) || (p.startDate !== null && p.endDate === null)) && !errorsUserEvents) {
                    dispatch(getProcessUserEventsAPI(odp, p))
                }
            })
        }
    }, [dispatch, odp, currentProcess, errorsUserEvents])

    //STANDARD: tenere sempre aggiornato il currentProcess quando cambia l'odp
    useEffect(() => {
        if (odp && odp.id !== "new" && odp.current !== null && odp.current.id !== null && odp.processes.length > 0) {
            dispatch(setCurrentProcess({ currentProcessIndex: odp.currentProcess, processes: odp.processes }))
        } else {
            dispatch(unSetCurrentProcess())
        }
    }, [dispatch, odp]);

    return (
        null
    )
};

export default ReloadingStandardOdp;
