import React from 'react'
import { Link } from 'react-router-dom'
import {
  Typography, FormControl, InputLabel, Input, Divider, Button, TextField
} from '@material-ui/core'
import { customLabel } from "../utility/customLabel";
import LockIcon from '@material-ui/icons/Lock';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from '../utility/DialogConfirmAction';

export const Details = ({ measureTool, edit, toggleDelete, deleting, doDelete, loading }) => {
  const myClasses = mesStyles();

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit"> {customLabel("measureTool.measureTool")}</Typography>
        <LockIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50} disabled>
          <InputLabel htmlFor="code">{customLabel("measureTool.code")}</InputLabel>
          <Input value={measureTool.code} />
        </FormControl>
        <FormControl className={myClasses.form50} disabled>
          <InputLabel htmlFor="name">{customLabel("measureTool.name")}</InputLabel>
          <Input value={measureTool.name} />
        </FormControl>
        <FormControl className={myClasses.form50} disabled>
          <InputLabel htmlFor="serial">{customLabel("measureTool.serial")}</InputLabel>
          <Input value={measureTool.serial} />
        </FormControl>
        <FormControl className={myClasses.form50} disabled>
          <InputLabel htmlFor="measure">{customLabel("measureTool.measure")}</InputLabel>
          <Input value={measureTool.measure} />
        </FormControl>
        <FormControl className={myClasses.form50} disabled>
          <InputLabel htmlFor="constructor">{customLabel("measureTool.constructor")}</InputLabel>
          <Input value={measureTool.constructor} />
        </FormControl>
        <FormControl className={myClasses.form100} disabled>
          <TextField disabled
            variant="outlined" name="note" multiline label={customLabel("function.note")} rows={4}
            value={measureTool.note}
          />
        </FormControl>
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple key={measureTool.id} onClick={edit} color="primary" disabled={loading}>{customLabel("button.edit")}</Button>
        <Button color="secondary" onClick={toggleDelete} disabled={loading}>{customLabel("button.delete")}</Button>
        <Button component={Link} to={`/measureTools`} disabled={loading}>{customLabel("button.back")}</Button>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={toggleDelete} disabled={loading} />
    </PaperForm>
  )
}
