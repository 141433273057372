import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import {
    TableBody, TableCell, TableRow, TableHead, Table, Button
} from '@material-ui/core';
import { customLabel } from '../../utility/customLabel';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { operatorViewSelector } from "../slice";
import TableChecksDetails from '../../checks/TableChecksDetails';
import {
    checksSelector, getListChecksByNameAPI, setCheckToSee, setReload as setChecksReload, changeFilter as changeChecksFilter,
    resetState as resetStateChecks
} from '../../checks/slice';
import DialogOperator from '../utility/DialogOperator';
import { opViewStyles } from '../utility/customStyles';

const ChecksDetails = ({ configuration }) => {
    const customClasses = opViewStyles();
    const dispatch = useDispatch();
    const { startChecksShow, pauseChecksShow, restartChecksShow, closeChecksShow, odp, process, modeStapling, onDemandChecksShow, modeMaintenance } = useSelector(operatorViewSelector);
    const { checkList, checkToSee, results: checksResults, filter: checksFilter, reload: checksReload, loading: loadingChecks } = useSelector(checksSelector);

    useEffect(() => {
        if (checksReload && checkToSee) {
            dispatch(getListChecksByNameAPI(checksFilter));
        }
    }, [dispatch, checksFilter, checksReload, checkToSee])

    const renderChecksByTypeOpView = (checkType, checks) => {
        if (checks && checks.length > 0) {
            return (
                checks.map((check) => {
                    return (
                        <TableRow key={checkType + "_" + check.id} onClick={() => setChecksDetails(odp.id, process.id, check, modeStapling)} style={{ cursor: "pointer" }}>
                            <TableCell className={customClasses.tableCellBreak100}>{checkType}</TableCell>
                            <TableCell className={customClasses.tableCellBreak130}>{check.name}</TableCell>
                            <TableCell className={customClasses.tableCellBreak130}>
                                {(check.type === "boolean" && check.success === true) || (check.type === "component" && check.value === "true") ? <CheckIcon /> :
                                    (check.type === "boolean" && check.success === false) || (check.type === "component" && check.value === "false") ? <ClearIcon /> :
                                        (check.type === "boolean" && check.success === null) || (check.type === "component" && check.value === null) ? "-" :
                                            check.value !== null && check.type === "number" ? Number(check.value).toFixed(configuration.decimals) :
                                                check.value !== null ? check.value : "-"}
                            </TableCell>
                        </TableRow>
                    )
                })
            )
        } else {
            return (
                <TableRow>
                    <TableCell colSpan={3}>
                        {customLabel("function.notAvailable").toUpperCase()}
                    </TableCell>
                </TableRow>
            )
        }
    }

    /**
     * 
     * @param {*} odpId 
     * @param {*} editProcessId 
     * @param {*} currentCheck 
     * @param {*} stapling 
     */
    const setChecksDetails = (odpId, editProcessId, currentCheck, stapling) => {
        dispatch(setCheckToSee({ check: currentCheck, odpId: odpId, processId: editProcessId, stapling: stapling }));
        dispatch(setChecksReload());
    };

    const unSetChecksDetails = () => {
        dispatch(resetStateChecks());
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(changeChecksFilter({ name: "rowsPerPage", value: parseInt(event.target.value, 10) }))
        dispatch(changeChecksFilter({ name: "page", value: 0 }))
        dispatch(setChecksReload());
    };
    const handleChangePage = (event, newPage, doReload) => {
        dispatch(changeChecksFilter({ name: "page", value: newPage }))
        if (doReload) {
            dispatch(setChecksReload());
        }
    };

    const renderHistoricalChecksContent = () => {
        return (
            <TableChecksDetails checkList={checkList} page={checksFilter.page} setPage={handleChangePage}
                rowsPerPage={checksFilter.rowsPerPage} setRowsPerPage={handleChangeRowsPerPage} results={checksResults}
                showOperator={true} decimals={configuration.decimals} loading={loadingChecks} />
        )
    }

    const renderHistoricalChecksActions = () => {
        return (
            <Button disabled={loadingChecks} className={customClasses.dialogButton} onClick={unSetChecksDetails} variant="contained" color="secondary">{customLabel("button.close")}</Button>
        )
    }

    const renderAllChecks = () => {
        return (
            <React.Fragment>
                {!modeMaintenance ? (
                    <>
                        {renderChecksByTypeOpView(customLabel("processType.check.start").toUpperCase(), startChecksShow)}
                        {renderChecksByTypeOpView(customLabel("processType.check.pause").toUpperCase(), pauseChecksShow)}
                        {renderChecksByTypeOpView(customLabel("processType.check.restart").toUpperCase(), restartChecksShow)}
                        {renderChecksByTypeOpView(customLabel("processType.check.close").toUpperCase(), closeChecksShow)}
                        {renderChecksByTypeOpView(customLabel("processType.check.onDemand").toUpperCase(), onDemandChecksShow)}
                    </>
                ) : (
                    <>
                        {renderChecksByTypeOpView(customLabel("processType.check.close").toUpperCase(), closeChecksShow)}
                    </>
                )}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={customClasses.tableCellBreak100}>
                            {customLabel("processType.check.type").toUpperCase()}
                        </TableCell>
                        <TableCell className={customClasses.tableCellBreak130}>
                            {customLabel("function.name")}
                        </TableCell>
                        <TableCell className={customClasses.tableCellBreak130}>
                            {customLabel("function.value")}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderAllChecks()}
                </TableBody>
            </Table>
            {/*Dialog visualizzare lo storico di un controllo*/}
            <DialogOperator flag={checkToSee ? true : false} title={customLabel("function.historicalCheck")}
                form={() => renderHistoricalChecksContent()}
                actions={() => renderHistoricalChecksActions()} transparentBackround={false} />
        </React.Fragment>
    )
}

export default ChecksDetails
