import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Paper,
    Table, TableBody,
    Typography
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { configurationSelector } from '../../configuration/slice';
import { mainSelector } from '../../main/slice';
import VarForm from '../../processTypes/VarForm';
import { processTypesSelector } from '../../processTypes/slice';
import ButtonProcessStapling from '../../utility/ButtonProcessStapling';
import SimpleTableHeader from '../../utility/SimpleTableHeader';
import { customLabel } from '../../utility/customLabel';
import { mesStyles } from '../../utility/ultrafabStyles';
import TableVarsDetails from '../../vars/TableVarsDetails';
import {
    changeFilter as changeVarsFilter,
    getListMachineVarsByNameAPI,
    getListVarsByNameAPI,
    getListVarsByTypeAPI,
    resetState as resetStateVars,
    setVarToSee, setReload as setVarsReload,
    varsSelector
} from '../../vars/slice';
import { renderFormInsertVar, renderListMachineVars, renderListVars, renderVarsListToInsert } from '../../vars/utility';

const Vars = ({ odp, editProcess, loading, choosenVar, insertVarFlag, listVarFlag, currentVar, editVar, currentVars, processMachineVars,
    insertVarsAPI, changeCurrentVar, enableInsertVarFlag, enableVarFlag, disableVarFlag,
    disableInsertVarFlag, setVar, resetVar, addVar, createVar, updateVar, changeVar }) => {
    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { varToSee, varList, results: varsResults, filter: varsFilter, reload: varsReload, loading: varsLoading } = useSelector(varsSelector);
    const [currentMachineId, setCurrentMachineId] = React.useState(false);
    const { currentUser } = useSelector(mainSelector);
    const { processTypes } = useSelector(processTypesSelector)
    const { configuration } = useSelector(configurationSelector);
    const [confirmAsked, setConfirmAsked] = React.useState(false);
    const [processTypeVar, setProcessTypeVar] = React.useState(false)

    useEffect(() => {
        if (processTypes) {
            for (let i = 0; i < processTypes.length; i++) {
                if (processTypes[i].code === editProcess.type.code) {
                    setProcessTypeVar(processTypes[i].totalPieces !== "" ? processTypes[i].totalPieces : processTypes[i].validPieces)
                }
            }
        }
    }, [processTypes, editProcess])

    /**
     * 
     * @param {*} odpId 
     * @param {*} editProcessId 
     * @param {*} cv 
     * @param {*} machine 
     * @param {*} stapling 
     */
    const setVarsDetails = (odpId, editProcessId, cv, machine, stapling, machineId) => {
        let serialNo = false;
        if (cv.serial) {
            serialNo = cv.serialNo
        }
        dispatch(setVarToSee({ var: cv, odpId: odpId, processId: editProcessId, serialNo: serialNo, machine: machine, stapling: stapling, machineId: machineId }));
        dispatch(setVarsReload());
    };
    const unSetVarsDetails = () => {
        dispatch(resetStateVars());
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(changeVarsFilter({ name: "rowsPerPage", value: parseInt(event.target.value, 10) }))
        dispatch(changeVarsFilter({ name: "page", value: 0 }))
        dispatch(setVarsReload());
    };
    const handleChangePage = (event, newPage, doReload) => {
        dispatch(changeVarsFilter({ name: "page", value: newPage }))
        if (doReload) {
            dispatch(setVarsReload());
        }
    };
    useEffect(() => {
        if (varsReload && !varsFilter.machine) {
            if (varToSee && varToSee.varDef && varToSee.varDef.id !== null) {
                dispatch(getListVarsByTypeAPI(varsFilter));
            } else {
                dispatch(getListVarsByNameAPI(varsFilter));
            }
        } else if (varsReload && varsFilter.machine) {
            dispatch(getListMachineVarsByNameAPI(varsFilter));
        }
    }, [dispatch, varsReload, varsFilter, varToSee]);

    //apre modifica variabile
    const onEditVar = (cv) => {
        dispatch(setVar(cv));
    };
    //apre aggiunta variabile
    const onAddVar = () => {
        dispatch(addVar(odp, editProcess));
    };
    //rimuove inserimento variabile
    const cancelEditVar = () => {
        dispatch(resetVar());
    };
    //salva variabile
    const saveVar = () => {
        if (editVar.id === "new") {
            dispatch(createVar(odp, editProcess, editVar))
        } else {
            dispatch(updateVar(odp, editProcess, editVar))
        }
    }
    //modifica variabile
    const onChangeVar = event => {
        let name = event.target.name
        let value = event.target.value
        if (event.target.type === "checkbox") {
            value = event.target.checked;
        }
        dispatch(changeVar({ name: name, value: value }));
    };

    //apre step 1 inserimento variabile
    const settingVar = (process) => {
        let machineId = process.lastMachineEvent.machine.id !== null && process.machineEvent.machine.id === null ? process.lastMachineEvent.machine.id : process.machineEvent.machine.id
        setCurrentMachineId(machineId);
        dispatch(enableVarFlag());
    };
    //API che inserisce la variabile selezionata
    const confirmVar = () => {
        if(!choosenVar.askConfirmation || confirmAsked){
            setConfirmAsked(false)
            dispatch(insertVarsAPI(odp.id, editProcess.id, choosenVar.id, currentVar, currentUser.username, currentMachineId))
        } else {
            setConfirmAsked(true)
        }
    };
    //tornare a step 1 di inserimento variabile
    const cancelInsertVar = () => {
        dispatch(disableInsertVarFlag());
    };
    //step 2 inserimento variabile
    const settingInsertVar = (v) => {
        dispatch(enableInsertVarFlag(v));
    };
    ///viene valorizzato lo stato currentVar che rappresenta il valore della variabile di tipo numerico/stringa
    const handleChangeVarText = (event) => {
        dispatch(changeCurrentVar({ value: event.target.value }));
    };
    //viene valorizzato lo stato currentVar che rappresenta il valore della variabile di tipo boolean
    const handleChangeVarBool = (event, value) => {
        dispatch(changeCurrentVar({ value: value }));
    };

    return (
        <React.Fragment>
            <Paper className={myClasses.processDetailsPaper}>
                {editProcess.stapling.id !== null ? (
                    <React.Fragment>
                        <ButtonProcessStapling message={customLabel("process.processInStapling").toUpperCase()} stapling={editProcess.stapling} />
                        <Divider />
                    </React.Fragment>
                ) : null}
                <div className={myClasses.buttonsFlexRight}>
                    <Button color="primary" disabled={loading || editProcess.stapling.id !== null}
                        onClick={() => onAddVar(editProcess.machineEvent.machine.id)}>
                        {customLabel("button.add")}
                    </Button>
                    <Button color="primary" disabled={loading || editProcess.stapling.id !== null}
                        onClick={() => settingVar(editProcess)}>
                        {customLabel("button.declare")}
                    </Button>
                </div>
                <Divider />
                <Typography component="h6" variant="h6" color="inherit" className={myClasses.titleTableTypography}>{customLabel("process.processVars")}</Typography>
                <Table size="small" stickyHeader>
                    <SimpleTableHeader list={[customLabel("function.name"), customLabel("function.serial").toUpperCase(), customLabel("function.value"),
                    customLabel("function.date"), customLabel("function.operator").toUpperCase(), customLabel("function.actions").toUpperCase()]} />
                    <TableBody>
                        {renderListVars(odp, false, editProcess, currentVars, true, editProcess.stapling.id === null, setVarsDetails, onEditVar, configuration.decimals)}
                    </TableBody>
                </Table>
                <Typography component="h6" variant="h6" color="inherit" className={myClasses.titleTableTypography}>{customLabel("machine.machineVars")}</Typography>
                <Table size="small" stickyHeader>
                    <SimpleTableHeader list={[customLabel("function.name"), customLabel("machine.machine").toUpperCase(), customLabel("function.value"),
                    customLabel("function.date"), customLabel("function.actions").toUpperCase()]} />
                    <TableBody>
                        {renderListMachineVars(odp, false, editProcess, processMachineVars, true, setVarsDetails, configuration.decimals)}
                    </TableBody>
                </Table>
                {/*dialog per mostrare lo storico di una variabile */}
                <Dialog open={varToSee ? true : false} keepMounted maxWidth={"md"} fullWidth={true}>
                    <DialogTitle>
                        {customLabel("function.historicalVariable")}: {varToSee && !varsFilter.machine ? varToSee.name : varToSee && varsFilter.machine ? varToSee.var_name : null} {!varsFilter.machine && varToSee && varToSee.serial ? " - " + varToSee.serialNo : null}  {!varsFilter.machine && varToSee && varToSee.measure !== "" && varToSee.measure !== null ? " (" + varToSee.measure + ")" : null}
                    </DialogTitle>
                    <DialogContent>
                        <TableVarsDetails varList={varList} varToSee={varToSee} page={varsFilter.page}
                            setPage={handleChangePage} rowsPerPage={varsFilter.rowsPerPage} setRowsPerPage={handleChangeRowsPerPage} results={varsResults}
                            showOperator={true} decimals={configuration.decimals} loading={varsLoading} />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={varsLoading} onClick={unSetVarsDetails} color="default">{customLabel("button.close")}</Button>
                    </DialogActions>
                </Dialog>
            </Paper>
            {/*Dialog per elencare le variabili da inserire*/}
            <Dialog fullWidth maxWidth="md" open={listVarFlag}>
                <DialogTitle>
                    {customLabel("processType.var.listVariables")}
                </DialogTitle>
                <DialogContent>
                    {editProcess ? renderVarsListToInsert(false, currentVars, settingInsertVar, configuration.decimals, processTypeVar, editProcess) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => dispatch(disableVarFlag())} color="default" disabled={loading}>
                        {customLabel("button.close")}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Dialog per inserire una determinata variabile scelta da quelle dispnibili*/}
            <Dialog fullWidth maxWidth="md" open={insertVarFlag}>
                <DialogTitle>
                    {customLabel("processType.var.insertVariable")}
                </DialogTitle>
                <DialogContent>
                    {renderFormInsertVar(choosenVar, currentVar, handleChangeVarText, handleChangeVarBool, loading, false, false, null, null, null, confirmAsked, false, null, null, null)}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" disabled={currentVar === null || currentVar === "" || loading} onClick={() => confirmVar()}>
                        {customLabel("button.confirm")}
                    </Button>
                    <Button onClick={() => cancelInsertVar()} color="default" disabled={loading}>{customLabel("button.back")}</Button>
                </DialogActions>
            </Dialog>
            {/*Dialog per modificare/aggiungere una variabile*/}
            {
                editVar !== false ? (
                    <Dialog open={editVar !== false} keepMounted maxWidth={"sm"} fullWidth>
                        <DialogTitle>{customLabel(odp.maintenance ? "maintenancePhase.var" : "processType.var.var")}</DialogTitle>
                        <DialogContent>
                            <VarForm processVar={editVar} onChange={onChangeVar} />
                            {(editVar.serial === true) && (editProcess.quantity > 99) ?
                                <Typography component="h3" variant="h6" className={myClasses.alertMessage}>Attenzione, quantità elevata con variabili seriali!</Typography> : null
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={saveVar} color="primary" disabled={editVar.name === "" || editVar.type === "" || loading}>{customLabel("button.save")}</Button>
                            <Button onClick={cancelEditVar} disabled={loading} color="default">{customLabel("button.close")}</Button>
                        </DialogActions>
                    </Dialog>
                ) : null
            }
        </React.Fragment >
    )
}

export default Vars
