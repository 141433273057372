import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Box, AppBar, Toolbar, Typography, Container, IconButton } from '@material-ui/core';
import MenuPage from './MenuPage';
//import History from './History';
import HomePage from "./HomePage";
import TimelinePage from "./timeline/TimelinePage";
import OdpCurrentStatusPage from "./odpCurrentStatus/OdpCurrentStatusPage";
import MachineCurrentStatusPage from "./machineCurrentStatus/MachineCurrentStatusPage";
import UsersPage from "../users/UsersPage";
import UserPage from "../users/UserPage";
import WorkcentresPage from "../workcentres/WorkcentresPage";
import WorkcentrePage from "../workcentres/WorkcentrePage";
import RolesPage from "../roles/RolesPage";
import RolePage from "../roles/RolePage";
import BomsPage from "../boms/BomsPage";
import BomPage from "../boms/BomPage";
import ProcessTypesPage from "../processTypes/ProcessTypesPage";
import ProcessTypePage from "../processTypes/ProcessTypePage";
import MaintenancePhasesPage from "../maintenancePhases/MaintenancePhasesPage";
import MaintenancePhasePage from "../maintenancePhases/MaintenancePhasePage";
import MaintenanceTypesPage from "../maintenanceTypes/MaintenanceTypesPage";
import MaintenanceTypePage from "../maintenanceTypes/MaintenanceTypePage";
import ProcessStatesPage from "../processStatuses/ProcessStatusesPage";
import ProcessStatePage from "../processStatuses/ProcessStatusPage";
import CustomerPage from "../customers/CustomerPage";
import CustomersPage from "../customers/CustomersPage";
import OdpStatusesPage from "../odpStatuses/OdpStatusesPage";
import OdpStatusPage from "../odpStatuses/OdpStatusPage";
import UserEventsPage from "../userEvents/UserEventsPage";
import UserEventPage from "../userEvents/UserEventPage";
import MachineEventPage from "../machineEvents/MachineEventPage";
import MachineEventsPage from "../machineEvents/MachineEventsPage";
import OdpPage from "../odps/OdpPage";
import OdpsPage from "../odps/OdpsPage";
import CustomerOrderPage from "../customerOrders/CustomerOrderPage";
import CustomerOrdersPage from "../customerOrders/CustomerOrdersPage";
import ArticlePage from "../articles/ArticlePage";
import ArticlesPage from "../articles/ArticlesPage";
import ConfigurationPage from "../configuration/ConfigurationPage";
import SupplierPage from "../suppliers/SupplierPage";
import SuppliersPage from "../suppliers/SuppliersPage";
// import ManageOdpsPage from "./manageOdps/ManageOdpsPage";
import PlannerPage from "./planner/PlannerPage";
import MaintenancesTimelinePage from "./maintenanceCurrentStatus/MaintenancesPage";
import MaintenancesPage from "../maintenances/MaintenancesPage";
import MaintenancePage from "../maintenances/MaintenancePage";
import SmartAddOdpPage from "../smartOdp/SmartOdpPage";
import MeasureToolsPage from "../measureTools/MeasureToolsPage";
import MeasureToolPage from "../measureTools/MeasureToolPage";
import WorkcentreViewPage from "./workcentreView/WorkcentreViewPage";
import ButtonsPage from "../buttons/ButtonsPage"
import ButtonPage from "../buttons/ButtonPage"
import PageNotFound from "./PageNotFound";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { customLabel } from "../utility/customLabel";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { Link } from "react-router-dom";
import { doChange } from "./slice";
import { configurationSelector, getAPI as getConfigurationAPI } from "../configuration/slice";
import { SERVER_URL } from "../../constants";
import { toggleFullScreen } from "../utility/vari";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: "#d01a28",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    textDecoration: "none",
    fontWeight: "normal",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "auto",
  },
  container: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  logobishop: {
    marginRight: theme.spacing(2),
  },
}));

export default function DashboardPage({ currentUser }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const logobishop = process.env.PUBLIC_URL + "/logobishop.png";
  const { configuration } = useSelector(configurationSelector);
  //const { navigation, currentNavigation } = useSelector(selector);
  useEffect(() => {
    if (!configuration) {
      dispatch(getConfigurationAPI())
    }
  }, [dispatch, configuration]);

  const change = (url) => {
    dispatch(doChange(url))
  };

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" color="inherit" className={classes.title} component={Link} onClick={() => change("/")} to="/">
              <b>{customLabel("bishop.bishopMes").toUpperCase()}</b>
            </Typography>
            <div className={classes.title}>
              <img height="50" alt="ultrafab_logo" src={logobishop} />
            </div>
            {configuration && configuration !== null ? (
              <Typography variant="h6" color="inherit" className={classes.title}>
                <b>{configuration.name ? configuration.name : customLabel("function.customer")}</b>
              </Typography>
            ) : null}

            {configuration && configuration.logo !== null ? (
              <div className={classes.title}>
                <img src={SERVER_URL + "/configuration/logo?" + new Date().getTime()} height="50" alt="logo_cliente" />
              </div>
            ) : null}
            <IconButton onClick={() => toggleFullScreen(document.body)}>
              <FullscreenIcon style={{ color: "white" }} fontSize="large" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <MenuPage configuration={configuration} currentUser={currentUser} />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {/*<History navigation={navigation} currentNavigation={currentNavigation}/>*/}

          <Container maxWidth={false} className={classes.container}>
            <Switch>

              <Route exact path="/" component={HomePage} />
              <Route exact path="/timeline" component={TimelinePage} />
              <Route exact path="/odpStatus" component={OdpCurrentStatusPage} />
              <Route exact path="/machineStatus" component={MachineCurrentStatusPage} />
              {/* <Route exact path="/manageOdps/" component={ManageOdpsPage} /> */}
              <Route exact path="/planner/" component={PlannerPage} />
              <Route exact path="/workcentreView/" component={WorkcentreViewPage} />
              <Route exact path="/maintenancesTimeline/" component={MaintenancesTimelinePage} />
              <Route exact path="/maintenances/" component={MaintenancesPage} />
              <Route exact path="/maintenances/:id" component={MaintenancePage} />
              <Route exact path="/smart_odp" component={SmartAddOdpPage} />
              <Route exact path="/odps" component={OdpsPage} />
              <Route exact path="/odps/:id" component={OdpPage} />
              <Route exact path="/odps/:id/:customerOrder" component={OdpPage} />
              <Route exact path="/customerOrders" component={CustomerOrdersPage} />
              <Route exact path="/customerOrders/:id" component={CustomerOrderPage} />
              <Route exact path="/articles" component={ArticlesPage} />
              <Route exact path="/articles/:id" component={ArticlePage} />
              <Route exact path="/workcentres" component={WorkcentresPage} />
              <Route exact path="/workcentres/:id" component={WorkcentrePage} />
              <Route exact path="/measureTools" component={MeasureToolsPage} />
              <Route exact path="/measureTools/:id" component={MeasureToolPage} />
              <Route exact path="/admin/users" component={UsersPage} />
              <Route exact path="/admin/users/:id" component={UserPage} />
              <Route exact path="/admin/roles" component={RolesPage} />
              <Route exact path="/admin/roles/:id" component={RolePage} />
              <Route exact path="/customers/:id" component={CustomerPage} />
              <Route exact path="/customers/" component={CustomersPage} />
              <Route exact path="/suppliers/:id" component={SupplierPage} />
              <Route exact path="/suppliers/" component={SuppliersPage} />
              <Route exact path="/admin/boms" component={BomsPage} />
              <Route exact path="/admin/boms/:id" component={BomPage} />
              <Route exact path="/admin/processTypes" component={ProcessTypesPage} />
              <Route exact path="/admin/processTypes/:id" component={ProcessTypePage} />
              <Route exact path="/admin/maintenanceTypes" component={MaintenanceTypesPage} />
              <Route exact path="/admin/maintenanceTypes/:id" component={MaintenanceTypePage} />
              <Route exact path="/admin/maintenancePhases" component={MaintenancePhasesPage} />
              <Route exact path="/admin/maintenancePhases/:id" component={MaintenancePhasePage} />
              <Route exact path="/admin/processStatuses" component={ProcessStatesPage} />
              <Route exact path="/admin/processStatuses/:id" component={ProcessStatePage} />
              <Route exact path="/admin/odpStatuses/:id" component={OdpStatusPage} />
              <Route exact path="/admin/odpStatuses/" component={OdpStatusesPage} />
              <Route exact path="/admin/userEvents/" component={UserEventsPage} />
              <Route exact path="/admin/userEvents/:id" component={UserEventPage} />
              <Route exact path="/admin/machineEvents/:id" component={MachineEventPage} />
              <Route exact path="/admin/machineEvents/" component={MachineEventsPage} />
              <Route exact path="/admin/buttons/" component={ButtonsPage} />
              <Route exact path="/admin/buttons/:id" component={ButtonPage} />
              <Route exact path="/admin/configuration/" component={ConfigurationPage} />
              <Route component={PageNotFound} />
            </Switch>
            <Box pt={4}>
            </Box>
          </Container>
        </main>
      </div>
    </Router>
  );
}
