import React from 'react';
import Popover from '@material-ui/core/Popover';
import { TableRow, TableCell, Table } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { customLabel } from "../utility/customLabel";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    legendLine: {
        display: "flex",
        justifyContent: "space-between"
    },
    legendSquare: {
        height: "15px",
        width: "15px",
        border: "solid 1px black",
        marginTop: "auto",
        marginLeft: "10px"
    },
    popoverContent: {
        pointerEvents: 'auto',
    }
}));

const PopoverLegend = ({ name, list, open, anchorEl, openPopover, closePopover, anchorVertical, anchorHorizontal, transformVertical, transormHorizontal }) => {
    const classes = useStyles();

    const renderListColor = () => {
        if (list && list.length > 0) {
            return (
                list.map((l) => {
                    return (
                        <TableRow key={l.id}>
                            <TableCell>
                                <div className={classes.legendLine}>
                                    {l.name}
                                    <div className={classes.legendSquare} style={{ backgroundColor: l.backgroundColor, background: l.backgroundColor }}></div>
                                </div>
                            </TableCell>
                        </TableRow >
                    )
                })
            )
        } else {
            return (
                <TableRow>
                    <TableCell>
                        {customLabel("function.notAvailable").toUpperCase()}
                    </TableCell>
                </TableRow>
            )
        }
    }

    return (
        <div>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.popoverContent,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: anchorVertical,
                    horizontal: anchorHorizontal,
                }}
                transformOrigin={{
                    vertical: transformVertical,
                    horizontal: transormHorizontal,
                }}
                onClose={closePopover}
                disableRestoreFocus
                PaperProps={{ onMouseEnter: (e) => openPopover(e.currentTarget, anchorEl), onMouseLeave: closePopover }}
            >
                <Table size="small">
                    <tbody>
                        {renderListColor()}
                    </tbody>
                </Table>
            </Popover>
        </div>
    );
}
export default PopoverLegend;
