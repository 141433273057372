import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Input,
    InputLabel
} from "@material-ui/core";
import React from "react";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";

const Evolution = ({ configuration }) => {
    const myClasses = mesStyles();

    return (
        <Box>
            <Grid container size="small" className={myClasses.confDetailsBox}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <form className={myClasses.formContainer} noValidate autoComplete="off">
                        <FormControl disabled className={myClasses.form100}>
                            <InputLabel htmlFor="evoUrl">{customLabel("bishop.evolution")}</InputLabel>
                            <Input value={configuration.evoUrl !== "null" && configuration.evoUrl !== null ? configuration.evoUrl : ""} />
                        </FormControl>
                        <FormControl disabled className={myClasses.form100}>
                            <InputLabel htmlFor="generatorTopLevel">{customLabel("configuration.generatorTopLevel")}</InputLabel>
                            <Input value={configuration.generatorTopLevel !== "null" && configuration.generatorTopLevel !== null ? configuration.generatorTopLevel : ""} />
                        </FormControl>
                        <FormControl disabled className={myClasses.form100}>
                            <InputLabel htmlFor="generatorLocation">{customLabel("configuration.generatorLocation")}</InputLabel>
                            <Input value={configuration.generatorLocation !== "null" && configuration.generatorLocation !== null ? configuration.generatorLocation : ""} />
                        </FormControl>
                        <FormControlLabel disabled
                            control={
                                <Checkbox checked={configuration.sendCurrentMachineEvent} name="sendCurrentMachineEvent" color="default" />
                            }
                            label={customLabel("configuration.sendCurrentMachineEvent")}
                        />
                    </form>
                </Grid>
            </Grid>
        </Box>
    );
};
export default Evolution;