import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { removeMeasureToolAPI, addMeasureToolsAPI } from "./slice";
import { measureToolsSelector, getListAPI as getMeasureToolsAPI } from "../measureTools/slice";
import { Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import PaperAutocomplete from "../utility/PaperAutocomplete";

const MeasureToolsPage = ({ processType, loading }) => {
  const dispatch = useDispatch();
  const myClasses = mesStyles();
  const { measureTools } = useSelector(measureToolsSelector);
  const [removeMeasureTool, setRemoveMeasureTool] = React.useState(false);
  const [linking, setLinking] = React.useState(false);
  const [wl, setWl] = React.useState([]);

  useEffect(() => {
    dispatch(getMeasureToolsAPI(0, 100000, "name", "asc", false, false, false, false));
  }, [dispatch]);

  const onUnlick = (w) => {
    setRemoveMeasureTool(w);
  };

  const cancelUnlink = () => {
    setRemoveMeasureTool(false);
  };

  const doUnlink = () => {
    dispatch(removeMeasureToolAPI(processType, removeMeasureTool));
    setRemoveMeasureTool(false);
  };

  const onLink = () => {
    setLinking(true);
  };

  const cancelLink = () => {
    setLinking(false);
    setWl([]);
  };

  const doLink = () => {
    dispatch(addMeasureToolsAPI(processType, wl));
    setLinking(false);
    setWl([]);
  };

  if (measureTools !== false) {
    const availableMeasureTools = measureTools.filter((w) =>
      processType.measureTools.every((pw) => w.id !== pw.measureTool_id)
    );

    return (
      <PaperForm>
        <div className={myClasses.titleFormSpaceBetween}>
          <Typography component="h1" variant="h6" color="inherit">{customLabel("measureTool.measureTools")}</Typography>
        </div>
        <div className={myClasses.tableContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{customLabel("measureTool.code")}</TableCell>
                <TableCell>{customLabel("measureTool.name")}</TableCell>
                <TableCell>{customLabel("measureTool.serial")}</TableCell>
                <TableCell className={myClasses.tableCell1Button} align="right">
                  <Button onClick={onLink} color="primary" disabled={loading}>{customLabel("button.link")}</Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {processType.measureTools.map((w) => {
                return (
                  <TableRow key={w.id}>
                    <TableCell>{w.code}</TableCell>
                    <TableCell>{w.name}</TableCell>
                    <TableCell>{w.serial}</TableCell>
                    <TableCell align="right" className={myClasses.tableCell1Button}>
                      <Button onClick={() => { onUnlick(w) }} color="secondary" disabled={loading}>
                        {customLabel("button.unlink")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Dialog open={removeMeasureTool !== false} keepMounted>
            <DialogTitle>{customLabel("function.confirm").toUpperCase()}</DialogTitle>
            <DialogContent><DialogContentText>{customLabel("function.confirmUnlink")}</DialogContentText></DialogContent>
            <DialogActions>
              <Button onClick={doUnlink} disabled={loading} color="primary">{customLabel("button.confirm")}</Button>
              <Button onClick={cancelUnlink} disabled={loading} color="default">{customLabel("button.cancel")}</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={linking} keepMounted fullWidth>
            <DialogTitle>{customLabel("processType.linkMeasureTools")}</DialogTitle>
            <DialogContent>
              <Autocomplete
                PaperComponent={PaperAutocomplete}
                value={wl} onChange={(event, newValue) => { setWl(newValue) }} multiple options={availableMeasureTools}
                getOptionLabel={(option) => option.name} filterSelectedOptions
                renderInput={(params) => (<TextField {...params} label={customLabel("measureTool.measureTool")} error={wl.length === 0} />)} />
            </DialogContent>
            <DialogActions>
              <Button onClick={doLink} color="primary" disabled={wl.length === 0 || loading}>{customLabel("button.confirm")}</Button>
              <Button onClick={cancelLink} disabled={loading} color="default">{customLabel("button.close")}</Button>
            </DialogActions>
          </Dialog>
        </div>
      </PaperForm>
    );
  }
  return null;
};

export default MeasureToolsPage;
