import React from "react";
import { Link } from "react-router-dom";
import { FormControl, InputLabel, Input, Divider, Button, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";

export const Form = ({ useredit, handleChange, cancelEdit, save, roles, loading }) => {
  const myClasses = mesStyles();

  const roleChecked = (id) => {
    let index = useredit.roles.findIndex((r) => r.id === id)
    if (index > -1) {
      return true;
    }
    else {
      return false;
    }
  }

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">{customLabel("user.user")}</Typography>
        <LockOpenIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="code">{customLabel("user.code")}</InputLabel>
          <Input autoFocus name="code" error={useredit.code === ""} value={useredit.code} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form50} disabled={useredit.id !== "new"}>
          <InputLabel htmlFor="username">{customLabel("user.username")}</InputLabel>
          <Input name="username" error={useredit.username === ""} value={useredit.username} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="firstName">{customLabel("user.firstName")}</InputLabel>
          <Input name="firstName" error={useredit.firstName === ""} value={useredit.firstName} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="lastName">{customLabel("user.lastName")}</InputLabel>
          <Input name="lastName" error={useredit.lastName === ""} value={useredit.lastName} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="email">{customLabel("user.email")}</InputLabel>
          <Input name="email" value={useredit.email} error={useredit.email === ""} onChange={handleChange} />
        </FormControl>
        <FormControlLabel control={<Checkbox checked={useredit.enabled} onChange={handleChange} name="enabled" color="default" />} label={customLabel("user.enabled")} />
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="password">{customLabel("user.password")}</InputLabel>
          <Input name="password" type="password" value={useredit.password} onChange={handleChange} error={useredit.password === "" || useredit.errorpassword} />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="confirmPassword">{customLabel("user.confirmPassword")}</InputLabel>
          <Input name="confirmPassword" type="password" error={useredit.confirmPassword === "" || useredit.errorpassword} value={useredit.confirmPassword} onChange={handleChange} />
        </FormControl>
        <div>
          <InputLabel htmlFor="userRoles">{customLabel("user.roles")}</InputLabel>
          {roles ? (
            <div>
              {roles.map((r) => {
                return (<FormControlLabel key={r.id} control={<Checkbox name="roles" value={r.id} checked={roleChecked(r.id)} color="default" onChange={handleChange} />} label={r.authority} />);
              })}
            </div>
          ) : null}
        </div>
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple onClick={save}
          disabled={useredit.username === "" || useredit.errorpassword || loading} color="primary">{customLabel("button.save")}</Button>
        {useredit.id !== "new" ? (
          <Button focusRipple onClick={cancelEdit} disabled={loading}>{customLabel("button.cancel")}</Button>
        ) : (
          <Button component={Link} to={`/admin/users`} disabled={loading}>{customLabel("button.back")}</Button>
        )}
      </div>
    </PaperForm>
  );
};
