import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { odpsSelector } from "../slice";
import { customLabel } from '../../utility/customLabel';
import { Paper, Divider } from "@material-ui/core";
import { mesStyles } from '../../utility/ultrafabStyles';
import { machineEventsSelector, getListAPI as getMachineEventsAPI } from "../../machineEvents/slice";
import { getProcessEventsAPI } from "../slice"
import NewProductionData from "../NewProductionData";
import ButtonProcessStapling from "../../utility/ButtonProcessStapling";

const ProcessMachineEventsStats = () => {
    const dispatch = useDispatch();
    const myClasses = mesStyles();
    const { machineEvents } = useSelector(machineEventsSelector);
    const { odp, editProcess, currentProcessTotEvents, currentProcessTotEventsKpi,
        currentProcessEvents, currentProcessKpiEvents, currentProcessColors,
        reloadProcessEvents, loadingProcessEvents, currentProcessBarEvents, currentProcessBarKpiEvents, dailyProd } = useSelector(odpsSelector);

    useEffect(() => {
        if (!machineEvents) {
            dispatch(getMachineEventsAPI(0, 10000, "name", "asc", false));
        }
    }, [dispatch, machineEvents])

    useEffect(() => {
        if (reloadProcessEvents && !loadingProcessEvents && machineEvents) {
            dispatch(getProcessEventsAPI(odp, editProcess, machineEvents));
        }
    }, [dispatch, odp, editProcess, machineEvents, reloadProcessEvents, loadingProcessEvents]);

    return (
        <Paper className={myClasses.processDetailsPaper}>
            {editProcess.stapling.id !== null ? (
                <React.Fragment>
                    <ButtonProcessStapling message={customLabel("process.processInStapling").toUpperCase()} stapling={editProcess.stapling} />
                    <Divider />
                </React.Fragment>
            ) : null}
            <NewProductionData events={currentProcessEvents} colors={currentProcessColors} totEvents={currentProcessTotEvents}
                kpiEvents={currentProcessKpiEvents} totEventsKpi={currentProcessTotEventsKpi} barEvents={currentProcessBarEvents} barKpiEvents={currentProcessBarKpiEvents}
                startDate={editProcess.startDate} endDate={editProcess.endDate} status={editProcess.status} stapling={odp.stapling}
                maintenance={odp.maintenance} dailyProd={dailyProd} stats />
        </Paper>
    );
};

export default ProcessMachineEventsStats;