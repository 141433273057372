export const customFilter = {
    mesName: "maintenanceTypes",
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "name",
    showFilter: false,
    name: false,
    machine: false,
    processType: false,
    extraordinary: false
}

export const headers = {
    name: { filter: true, type: "string" },
    processType: { filter: true, type: "objectList", name: "processTypesLinks" },
    machine: { filter: true, type: "objectList", name: "machinesLinks" },
};
