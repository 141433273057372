import React from "react";
import { Link } from "react-router-dom";
import {
  Typography, FormControl, InputLabel, Input, Divider, Button, TextField
} from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockIcon from '@material-ui/icons/Lock';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";

export const Details = ({ supplier, edit, toggleDelete, deleting, doDelete, loading }) => {
  const myClasses = mesStyles();

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">
          {customLabel("supplier.supplier")}
        </Typography>
        <LockIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50} disabled>
          <InputLabel htmlFor="code">{customLabel("supplier.code")}</InputLabel>
          <Input value={supplier.code} />
        </FormControl>
        <FormControl className={myClasses.form50} disabled>
          <InputLabel htmlFor="name">{customLabel("supplier.name")}</InputLabel>
          <Input value={supplier.name} />
        </FormControl>
        <FormControl disabled className={myClasses.form50}>
          <InputLabel htmlFor="machine">{customLabel("supplier.externalMachine")}</InputLabel>
          <Input value={supplier.machine.id !== "" ? supplier.machine.code + " - " + supplier.machine.name : null} />
        </FormControl>
        <FormControl className={myClasses.form100} disabled>
          <TextField
            disabled variant="outlined" name="note" multiline label={customLabel("function.note")} rows={4}
            value={supplier.note}
          />
        </FormControl>
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple key={supplier.id} onClick={edit} color="primary" disabled={loading}>
          {customLabel("button.edit")}
        </Button>
        <Button color="secondary" onClick={toggleDelete} disabled={loading}>
          {customLabel("button.delete")}
        </Button>
        <Button component={Link} to={`/suppliers`} disabled={loading}>
          {customLabel("button.back")}
        </Button>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={toggleDelete} disabled={loading} />
    </PaperForm>
  );
};
