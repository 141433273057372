import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;
export const splitStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#d01a28",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        paddingRight: 24,
    },
    title: {
        flexGrow: 1,
        textDecoration: "none",
        fontWeight: "normal",
    },
    fullIcon: {
        marginLeft: theme.spacing(10)
    },
    root: {
        display: "flex",
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        padding: theme.spacing(1),
    },
    selectionDiv: {
        maxHeight: "680px",
        overflow: "auto"
    },
    selectionPaper: {
        margin: "5px",
        minHeight: "710px",
        maxHeight: "710px",
        overflow: "auto"
    },
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    actionButton: {
        margin: "5px",
        padding: "15px",
        width: "100px"
    },
    processRow: {
        display: "flex",
        justifyContent: "space-between"
    },
    dialogButton: {
        padding: "15px",
        fontSize: "15pt !important"
    },
    form100: {
        width: "calc(100% - 32px)",
    },
    rightAlign: {
        display: 'flex',
        justifyContent: 'flex-end',
    }
}));

