import { Button, Grid, Input, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from '../../utility/customLabel';
import { changeCurrentValue, changeStaplingList, getAllProcessesAPI, operatorViewSelector, resetCurrentValue, resetOdp } from "../slice";
import { INDEX_STAPLING } from "../utility/constants";
import { opViewStyles } from "../utility/customStyles";
import { renderProcessLabel } from "../utility/utility";

const FormStaplingProcesses = ({ configuration }) => {
    const customClasses = opViewStyles();
    const dispatch = useDispatch();
    const { staplingList, loading, allProcesses, currentValue, machine, operator, index } = useSelector(operatorViewSelector);
    const [ keyEnter, setKeyEnter ] = React.useState(false)
    const isInitialMount = useRef(true);
    
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else if(staplingList.length>0){
            let reloadTimeout = setTimeout(() => {
                if (configuration.opViewEnableOdpSearch && index === INDEX_STAPLING) {
                    dispatch(getAllProcessesAPI(machine, operator, staplingList[0], currentValue===false?'':currentValue, 0, configuration.opViewOdpListSize));
                }
            }, 500)
            return () => {
            clearTimeout(reloadTimeout)
            }
        } else if(staplingList.length===0){
            dispatch(resetOdp())
        }
    }, [dispatch, currentValue, configuration.opViewEnableOdpSearch, machine, operator, staplingList, configuration.opViewOdpListSize, configuration.enableInsertExtCode, index])


    //mette focus sull'input
    const handleFocus = (event) => {
        event.preventDefault();
        return false;
    };

    const handleChangeValueText = (event) => {
        dispatch(changeCurrentValue({ value: event.target.value }));
    };

    const checkOdpIsInList = useCallback((v) => {
        let process;
        const indexP = allProcesses.findIndex((p) => {
            return p.code.toString() === v.toString() || p.id.toString() === v.toString()
                || (configuration.enableInsertExtCode && p.odp.extCode !== null && p.odp.extCode !== "" && p.odp.extCode.toString() === v.toString());
        })
        if (indexP !== -1) {
            process = allProcesses[indexP];
            dispatch(changeStaplingList(process));
            dispatch(resetCurrentValue());
            setKeyEnter(false)
        }else{
            setKeyEnter(true)
        }
    }, [dispatch, allProcesses, configuration.enableInsertExtCode]);

    useEffect(()=> {
        if(keyEnter && allProcesses.length===1){
            checkOdpIsInList(currentValue)
        }
    }, [keyEnter, checkOdpIsInList, currentValue, allProcesses.length, setKeyEnter]);

    //gestisce click invio nella lista delle lavorazioni per la pinzatura
    const handleClickEnter = (event) => {
        if (event.key === "Enter") {
            checkOdpIsInList(event.target.value)
        } else {
            return;
        }
    };
    return (
        <React.Fragment>
            <div className={customClasses.listStaplingContainer}>
                <Grid container spacing={1} className={customClasses.width100}>
                    <Grid item xs={6}>
                        <div className={customClasses.containerStaplingProcessesChoice}>
                            <Typography component="p" variant="h6" color="inherit" style={{ textAlign: "center" }}>
                                {customLabel("operatorView.added")}
                            </Typography>
                        </div>
                        {staplingList && staplingList.length > 0 ?
                            (staplingList.map((p) => (
                                <div key={p.id.toString() + "_added"}>
                                    <Button value={p.id} className={customClasses.staplingButton} color="primary"
                                        variant="contained" onClick={() => dispatch(changeStaplingList(p))}
                                        disabled={loading}>
                                        {renderProcessLabel(p, configuration)}
                                    </Button>
                                </div>))
                            ) : null}
                    </Grid>
                    <Grid item xs={6}>
                        <div className={customClasses.containerStaplingProcessesChoice}>
                            <Typography component="p" variant="h6" color="inherit" style={{ textAlign: "center" }}>
                                {customLabel("operatorView.availables")}
                            </Typography>
                            <div className={customClasses.formAvailableStaplingProcesses}>
                                <Input onFocus={handleFocus} disabled={!configuration.opViewEnableOdpSearch && loading}
                                    inputRef={(input) => {
                                        if (input !== null) {
                                            input.focus();
                                        }
                                    }}
                                    onKeyPress={handleClickEnter}
                                    value={currentValue ? currentValue : ""} fullWidth name="stapling" onChange={(e) => handleChangeValueText(e)}></Input>
                            </div>
                        </div>
                        {allProcesses !== false && allProcesses.length > 0 ?
                            (allProcesses.map((p) => (
                                <div key={p.id + "_available"}>
                                    <Button value={p.id} className={customClasses.staplingButton} color="default"
                                        variant={"outlined"} onClick={() => dispatch(changeStaplingList(p))}
                                        disabled={loading || staplingList.some(s => s["id"] === p.id)}>
                                        {renderProcessLabel(p, configuration)}
                                    </Button>
                                </div>))
                            ) : null}
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default FormStaplingProcesses;