export const FLAG_SPLIT_PROCESS = "flagSplitProcess";
export const FLAG_DELETE_PROCESS = "flagDeleteProcess";
export const FLAG_EDIT_PROCESS = "flagEditProcess";
export const HTTP_HEADER = {
    mode: "cors",
    method: "GET",
    headers: {
        "Content-Type": "application/json;charset=utf-8"
    }
}
export const editSplitPayload = {
    newQuantity: "newQuantity",
    newMachine: "newMachine",
    newProcess: "newProcess"
}