import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Clock from 'react-live-clock';
import clsx from "clsx";
import classNames from "classnames";
import {
  Grid, Drawer, CssBaseline, Divider, List, ListItem, ListItemText, ListItemIcon, Container,
  AppBar, Toolbar, IconButton, Typography,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import T from "i18n-react"
import { customLabel } from "../utility/customLabel";
import NotificationsPage from "../notifications/NotificationsPage";
import CurrentSelection from "./CurrentSelection";
import ManageProcess from "./standardOdp/ManageProcess";
import ManageStapling from "./staplingOdp/ManageStapling";
import InputOperator from "./input/InputOperator";
import RealTimePage from "./realtimedata/RealTimePage";
import { operatorViewSelector, changeOperator, resetOperator, resetErrors } from "./slice";
import { configurationSelector, getAPI as getConfigurationAPI } from "../configuration/slice";
import { notificationsSelector } from "../notifications/slice";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { toggleFullScreen } from "../utility/vari";
import { resetState as resetStateWorkcentres } from "../workcentres/slice";
import { resetState as resetStateMachines } from "../machines/slice";
import { resetState as resetStateUsers } from "../users/slice";
import { resetState as resetStateRoles } from "../roles/slice";
import { INDEX_INTERFACE } from "./utility/constants";
import SplitPage from "../split/SplitPage";
import ManageMaintenance from "./maintenance/ManageMaintenance";
import { opViewStyles } from "./utility/customStyles";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: "#d01a28",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    textDecoration: "none",
    fontWeight: "normal",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  fullIcon: {
    marginLeft: theme.spacing(10)
  },
}));

const OperatorViewPage = () => {
  const classes = useStyles();
  const customClasses = opViewStyles();
  const dispatch = useDispatch();
  const { operatorsLogged, operator, index, errors, modeMaintenance, modeStapling, odp, process: mesProcess, machine, workcentre } = useSelector(operatorViewSelector);
  const { configuration, loading: loadingConf, hasErrors: errorsConf } = useSelector(configurationSelector);
  const { error } = useSelector(notificationsSelector);

  const [open, setOpen] = React.useState(false);
  const logobishop = process.env.PUBLIC_URL + "/logobishop.png";

  useEffect(() => {
    if (configuration === false && !loadingConf && !errorsConf) {
      dispatch(getConfigurationAPI());
    }
    if (configuration && configuration.label !== null) {
      T.setTexts(JSON.parse(configuration.label), {
        notFound: (key) => `${key} <- missing!`,
      });
    }
  }, [dispatch, configuration, loadingConf, errorsConf]);

  useEffect(() => {
    if (!error && errors) {
      dispatch(resetErrors())
    }
  }, [dispatch, error, errors]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    //crea campi localStorage se non ci sono
    if (localStorage.getItem("machines") === null) {
      localStorage.setItem("machines", "[]")
    };
    if (localStorage.getItem("workcentres") === null) {
      localStorage.setItem("workcentres", "[]")
    };
    if (localStorage.getItem("roles") === null) {
      localStorage.setItem("roles", "[]")
    };
    if (localStorage.getItem("workstation") === null) {
      localStorage.setItem("workstation", "")
    };
    if (localStorage.getItem("changeWorkcentre") === null) {
      localStorage.setItem("changeWorkcentre", "false")
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getConfigurationAPI());
  }, [dispatch]);

  const handleChangeOperator = (operator) => {
    dispatch(changeOperator(operator));
  };

  const handleNewOperator = () => {
    dispatch(resetOperator({ value: false }))
    dispatch(resetStateWorkcentres());
    dispatch(resetStateMachines());
    dispatch(resetStateUsers());
    dispatch(resetStateRoles());
  };

  const renderOperators = () => {
    if (index === INDEX_INTERFACE) {
      return (
        <div>
          <ListItem button onClick={() => handleNewOperator()}>
            <ListItemIcon>
              <PersonAddIcon color="primary" />
            </ListItemIcon>
            <ListItemText>{customLabel("function.enter")}</ListItemText>
          </ListItem>
          {operatorsLogged.map((u) => (
            <ListItem button key={u.id} onClick={() => handleChangeOperator(u)} value={u.id} className={classNames({ [customClasses.grayBackgroundOperator]: operator.id === u.id })}>
              <ListItemText primary={open ? u.name : u.username.substring(0, 4)} />
            </ListItem>
          ))}
        </div>
      );
    }
    else {
      return null;
    }
  }

  return (
    <div className={classes.root}>
      <NotificationsPage />
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap className={classes.title}>
            <b>{customLabel("bishop.bishop").toUpperCase()}</b>
          </Typography>
          <Typography variant="h6" color="inherit" noWrap className={classes.title}>
            <b>{configuration.name ? configuration.name : customLabel("function.customer")}</b>
          </Typography>
          <Typography variant="h6" color="inherit" noWrap className={classes.title}>
            <b><Clock format={'HH:mm:ss'} ticking={true} timezone={'Europe/Rome'} /></b>
          </Typography>
          <img height="50" alt="ultrafab_logo" src={logobishop} />
          <IconButton className={classes.fullIcon} onClick={() => toggleFullScreen(document.body)}>
            <FullscreenIcon style={{ color: "white" }} fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent" classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }} open={open}>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {renderOperators()}
        </List>
        <Divider />
      </Drawer>
      {odp && mesProcess && operator && machine && <SplitPage process={mesProcess} operator={operator} machine={machine} odp={odp} workcentre={workcentre} />}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={customClasses.opViewPageContainer}>
          {index === INDEX_INTERFACE ? (
            <Grid container spacing={1} alignItems="flex-start" justify="center" >
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <CurrentSelection configuration={configuration} />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                {modeStapling ?
                  <ManageStapling configuration={configuration} />
                  : modeMaintenance ?
                    <ManageMaintenance configuration={configuration} />
                    :
                    <ManageProcess configuration={configuration} />
                }
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <RealTimePage configuration={configuration} />
              </Grid>
            </Grid>
          ) : <InputOperator configuration={configuration} />
          }
        </Container>
      </main>
    </div>
  )
}

export default OperatorViewPage;