import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { suppliersSelector, getListAPI, setReload, changeFilter, initFilter } from "./slice";
import TableList from "../utility/TableList";
import { Box, Divider, Typography } from "@material-ui/core";
import { useBoolean } from "../customHooks/useBoolean";
import HeaderTableList from "../utility/HeaderTableList";
import { configurationSelector } from "../configuration/slice";
import { headers } from "./utility";

const SuppliersPage = () => {
  const dispatch = useDispatch();
  const { suppliers, loading, reload, results, filter } = useSelector(suppliersSelector);
  const [firstFetchDone, { setTrue: setFirstFetchDoneTrue }] = useBoolean(false);
  const { configuration } = useSelector(configurationSelector);

  useEffect(() => {
    if (!filter && configuration) {
      dispatch(initFilter(configuration.showFilters))
    }
  }, [configuration, filter, dispatch])

  useEffect(() => {
    if (filter && !firstFetchDone) {
      dispatch(getListAPI(filter.page, filter.rowsPerPage, filter.orderBy, filter.order, filter.name, filter.code));
      window.scrollTo(0, 0)
      setFirstFetchDoneTrue();
    }
  }, [dispatch, filter, firstFetchDone, setFirstFetchDoneTrue]);

  if (reload && !loading) {
    dispatch(getListAPI(filter.page, filter.rowsPerPage, filter.orderBy, filter.order, filter.name, filter.code));
  };

  const handleChangePage = (event, newPage, doReload) => {
    dispatch(changeFilter({ name: "page", value: newPage }))
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changeFilter({ name: "rowsPerPage", value: parseInt(event.target.value, 10) }))
    dispatch(changeFilter({ name: "page", value: 0 }))
  };

  const onRequestSort = (property) => {
    const isAsc = filter.orderBy === property && filter.order === "asc"
    let newOrder = isAsc ? "desc" : "asc";
    dispatch(changeFilter({ name: "order", value: newOrder }))
    dispatch(changeFilter({ name: "orderBy", value: property }))
  };

  const changeFilters = (name, value) => {
    dispatch(changeFilter({ name: name, value: value }))
  };

  const resetFilter = () => {
    dispatch(initFilter(true));
  };

  const toggleFilter = () => {
    if (!filter.showFilter) {
      dispatch(initFilter(true));
    } else {
      dispatch(initFilter(false));
    }
  }

  const applyReload = useCallback(() => {
    dispatch(setReload());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Typography component="h1" variant="h6" color="inherit">
        {customLabel("supplier.suppliers")}
      </Typography>
      <HeaderTableList filter={filter} toggleFilter={toggleFilter} showAdd={true} businessObject={"suppliers"} />
      <Divider />
      <Box>
        {suppliers && filter ? (
          <TableList items={suppliers} headers={headers} mainObject={"supplier."} redirectObject={"suppliers"} setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage} results={results} onRequestSort={onRequestSort}
            nameList={false} codeList={false} changeFilters={changeFilters} resetFilter={resetFilter}
            filter={filter} applyReload={applyReload}
          />
        ) : null}
        {loading ? <div>{customLabel("function.loading").toUpperCase()}</div> : null}
      </Box>
    </React.Fragment>
  );
};

export default SuppliersPage;
