import React from "react";
import {
    FormControl, Select, InputLabel, MenuItem, Checkbox, FormControlLabel
} from "@material-ui/core";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";
import { labelTimeline, labelTimelineMachines, labelTimelineWorkcentres } from "../utility";

const Timeline = ({ configurationedit, handleChange }) => {
    const myClasses = mesStyles();

    return (
        <div className={myClasses.dialogForm500}>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="labelTimeline">{customLabel("configuration.labelTimeline.labelTimeline")}</InputLabel>
                <Select name="labelTimeline" value={configurationedit.labelTimeline} onChange={handleChange}>
                    <MenuItem value={labelTimeline["odpCode"]}>{customLabel("configuration.labelTimeline.odpCode")}</MenuItem>
                    <MenuItem value={labelTimeline["odpProcessTypeCode"]}>{customLabel("configuration.labelTimeline.odpProcessTypeCode")}</MenuItem>
                    <MenuItem value={labelTimeline["odpProcessTypeName"]}>{customLabel("configuration.labelTimeline.odpProcessTypeName")}</MenuItem>
                    <MenuItem value={labelTimeline["odpCustomerCode"]}>{customLabel("configuration.labelTimeline.odpCustomerCode")}</MenuItem>
                    <MenuItem value={labelTimeline["odpCustomerName"]}>{customLabel("configuration.labelTimeline.odpCustomerName")}</MenuItem>
                    <MenuItem value={labelTimeline["odpArticleCode"]}>{customLabel("configuration.labelTimeline.odpArticleCode")}</MenuItem>
                    <MenuItem value={labelTimeline["odpArticleName"]}>{customLabel("configuration.labelTimeline.odpArticleName")}</MenuItem>
                </Select>
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="labelTimelineMachines">{customLabel("configuration.labelTimelineMachines.labelTimelineMachines")}</InputLabel>
                <Select name="labelTimelineMachines" value={configurationedit.labelTimelineMachines} onChange={handleChange}>
                    <MenuItem value={labelTimelineMachines["name"]}>{customLabel("configuration.labelTimelineMachines.name")}</MenuItem>
                    <MenuItem value={labelTimelineMachines["code"]}>{customLabel("configuration.labelTimelineMachines.code")}</MenuItem>
                </Select>
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="labelTimelineWorkcentres">{customLabel("configuration.labelTimelineWorkcentres.labelTimelineWorkcentres")}</InputLabel>
                <Select name="labelTimelineWorkcentres" value={configurationedit.labelTimelineWorkcentres} onChange={handleChange}>
                    <MenuItem value={labelTimelineWorkcentres["name"]}>{customLabel("configuration.labelTimelineWorkcentres.name")}</MenuItem>
                    <MenuItem value={labelTimelineWorkcentres["code"]}>{customLabel("configuration.labelTimelineWorkcentres.code")}</MenuItem>
                </Select>
            </FormControl>
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.showExtTimeline} onChange={handleChange} name="showExtTimeline" color="default" />
                }
                label={customLabel("configuration.showExtTimeline")}
            />
        </div>
    );
};
export default Timeline;