import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from 'react';
import { useSelector } from 'react-redux';
import PaperAutocomplete from "../utility/PaperAutocomplete";
import { customLabel } from "../utility/customLabel";
import { mesStyles } from "../utility/ultrafabStyles";
import { processTypesSelector } from './slice';

const ReasonForm = ({removeReason, newReasons, setReasons}) => {

    const myClasses = mesStyles();
    const { reasons, processType } = useSelector(processTypesSelector)
    
    const availableReasons = processType.vars.filter((v) =>
        reasons.every((pw) => v.id !== pw.reason.id)
    );

    return (
        <form className={myClasses.dialogForm} noValidate autoComplete="off">
            <Table size="small">
                <TableHead>
                <TableRow>
                    <TableCell>{customLabel("processType.var.reason")}</TableCell>
                    <TableCell className={myClasses.tableCell1Button} align="right"></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {reasons.map((w) => {
                    return (
                    <TableRow key={w.id}>
                        <TableCell>{w.reason.name}</TableCell>
                        <TableCell align="right" className={myClasses.tableCell1Button}>
                        <Button onClick={() => { removeReason(w) }} color="secondary">
                            {customLabel("button.remove")}
                        </Button>
                        </TableCell>
                    </TableRow>
                    );
                })}
                </TableBody>
            </Table><br/>
            <Autocomplete
                PaperComponent={PaperAutocomplete}
                value={newReasons} onChange={(event, newValue) => { setReasons(newValue) }} multiple options={availableReasons}
                getOptionLabel={(option) =>  option.name } filterSelectedOptions
                renderInput={(params) => (<TextField {...params} label={customLabel("processType.var.reason")} />)} />
        </form>
    )
}

export default ReasonForm