import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../constants";
import { showError, resetErrors as resetNotificationErrors } from "../notifications/slice";
import { setServerNotResponding } from "../main/slice";
import { SERVER_NOT_RESPONDING_MESSAGE } from "../../constants";

//usato per valorizzare lo storico di una variabile, in questo slice si tratta di varList
//usato in odpAdmin e int operatore
export const initialState = {
    loading: false,
    hasErrors: false,
    varList: false,
    reload: false,
    results: 0,
    varToSee: false,
    filter: {
        mesName: "varsFilter",
        page: 0,
        rowsPerPage: 10,
        odpId: false,
        processId: false,
        varTypeId: false,
        varName: false,
        serialNo: false,
        machine: false,
        machineId: false,
        stapling: false
    },
};

const varsSlice = createSlice({
    name: "vars",
    initialState,
    reducers: {
        resetState: (state) => {
            Object.assign(state, initialState);
        },
        gettingList: (state) => {
            state.loading = true;
            state.reload = false;
            state.hasErrors = false;
        },
        getListSuccess: (state, { payload }) => {
            state.varList = payload[1];
            state.results = payload[0].total_entries;
            state.loading = false;
        },
        setReload: (state) => {
            state.reload = true;
        },
        enableErrors: (state) => {
            state.loading = false;
            state.hasErrors = true;
            state.reload = false;
        },
        setVarToSee: (state, { payload }) => {
            state.varToSee = payload.var;
            state.filter["odpId"] = payload.odpId;
            state.filter["stapling"] = payload.stapling;
            state.filter["processId"] = payload.processId;
            if (!payload.machine) {
                if (payload.var && payload.var.varDef && payload.var.varDef.id !== null) {
                    state.filter["varTypeId"] = payload.var.varDef.id;
                } else {
                    state.filter["varName"] = payload.var.name;
                }
                state.filter["serialNo"] = payload.serialNo;
            } else {
                state.filter["machine"] = true;
                state.filter["machineId"] = payload.machineId;
                //in int operatore arriva oggetto quindi var.name, in admin campo sql quindi var_name
                if (payload.var.var_name) {
                    state.filter["varName"] = payload.var.var_name;
                } else {
                    state.filter["varName"] = payload.var.name;
                }
            }
        },
        unSetVarToSee: (state) => {
            state.varToSee = false;
        },
        changeFilter: (state, { payload }) => {
            state.filter[payload.name] = payload.value;
        },
        initFilter: (state) => {
            state.filter = {
                mesName: "varsFilter",
                page: 0,
                rowsPerPage: 10,
                odpId: false,
                processId: false,
                varTypeId: false,
                varName: false,
                serialNo: false,
                machine: false,
                machineId: false
            }
        }
    },
});

export const { resetState, gettingList, getListSuccess, setReload, enableErrors, setVarToSee, unSetVarToSee, changeFilter, initFilter } = varsSlice.actions;
export const varsSelector = (state) => state.vars;
export default varsSlice.reducer;

export function getListVarsByTypeAPI(filter) {
    let url;
    if (!filter.stapling) {
        url = SERVER_URL + "/odp/" + filter.odpId + "/process/" + filter.processId + "/var/type/" + filter.varTypeId +
            "?page=" + filter.page + "&per_page=" + filter.rowsPerPage;
    } else {
        url = SERVER_URL + "/odp/" + filter.odpId + "/stapling/var/type/" + filter.varTypeId +
            "?page=" + filter.page + "&per_page=" + filter.rowsPerPage;;
    }
    if (filter.serialNo) {
        url = url + "?serialNo=" + filter.serialNo
    }
    return async dispatch => {
        try {
            dispatch(gettingList());
            const response = await fetch(url, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            });

            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(getListSuccess(data));
            } else {
                dispatch(resetState());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(resetState());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};

export function getListVarsByNameAPI(filter) {
    let url;
    if (!filter.stapling) {
        url = SERVER_URL + "/odp/" + filter.odpId + "/process/" + filter.processId + "/var/name/" + filter.varName +
            "?page=" + filter.page + "&per_page=" + filter.rowsPerPage;
    } else {
        url = SERVER_URL + "/odp/" + filter.odpId + "/stapling/var/name/" + filter.varName +
            "?page=" + filter.page + "&per_page=" + filter.rowsPerPage;;
    }

    if (filter.serial) {
        url = url + "?serialNo=" + filter.serialNo
    }
    return async dispatch => {
        dispatch(gettingList());
        try {
            const response = await fetch(url, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            });

            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(getListSuccess(data));
            } else {
                dispatch(resetState());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(resetState());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};

export function getListMachineVarsByNameAPI(filter) {
    let url;
    if (!filter.stapling) {
        url = SERVER_URL + "/odp/" + filter.odpId + "/process/" + filter.processId + "/machineVar/name/" + filter.varName +
            "?page=" + filter.page + "&per_page=" + filter.rowsPerPage;
    } else {
        url = SERVER_URL + "/odp/" + filter.odpId + "/machineVar/stapling/name/" + filter.varName +
            "?page=" + filter.page + "&per_page=" + filter.rowsPerPage;;
    }
    if (filter.machineId) {
        url = url + "&machineId=" + filter.machineId
    }
    if (filter.serial) {
        url = url + "?serialNo=" + filter.serialNo
    }
    return async dispatch => {
        dispatch(gettingList());
        try {
            const response = await fetch(url, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            });

            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(getListSuccess(data));
            } else {
                dispatch(resetState());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(resetState());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};