export const customFilter = {
    mesName: "machineEvents",
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "name",
    showFilter: false,
    name: false
}

export const headers = {
    name: { filter: true, type: "string" },
    systemEvent: { filter: false, type: "boolean" },
    excludeFromKPI: { filter: false, type: "boolean" },
    productive: { filter: false, type: "boolean" },
    setup: { filter: false, type: "boolean" }
}