import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { operatorViewSelector, getProcessVarsAPI, getStaplingVarsAPI } from "../slice";
import {
    TableBody, TableCell, TableRow, TableHead, Button, Table
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { customLabel } from '../../utility/customLabel';
import moment from "moment";
import { bishopFormats } from '../../../constants';
import {
    varsSelector, getListVarsByTypeAPI, getListVarsByNameAPI, setVarToSee, setReload as setVarsReload,
    changeFilter as changeVarsFilter, resetState as resetStateVars, getListMachineVarsByNameAPI
} from '../../vars/slice';
import TableVarsDetails from "../../vars/TableVarsDetails";
import DialogOperator from "../utility/DialogOperator";
import { opViewStyles } from "../utility/customStyles";

const MaintenanceVars = ({ configuration }) => {
    const customClasses = opViewStyles();
    const dispatch = useDispatch();
    const { varToSee, varList, results: varsResults, filter: varsFilter, reload: varsReload } = useSelector(varsSelector);
    const { vars, process, modeStapling, odp } = useSelector(operatorViewSelector);

    const settingVarToSee = (odpId, editProcessId, cv, machine, stapling) => {
        let serialNo = false;
        if (cv.serial) {
            serialNo = cv.serialNo
        }
        if (!stapling) {
            dispatch(getProcessVarsAPI(process));
        }
        if (stapling && odp) {
            dispatch(getStaplingVarsAPI(odp))
        }
        dispatch(setVarToSee({ var: cv, odpId: odpId, processId: editProcessId, serialNo: serialNo, machine: machine, stapling: stapling }));
        dispatch(setVarsReload());
    };

    const unSetVarsDetails = () => {
        dispatch(resetStateVars());
    };
    const handleChangeRowsPerPage = (event) => {
        dispatch(changeVarsFilter({ name: "rowsPerPage", value: parseInt(event.target.value, 10) }))
        dispatch(changeVarsFilter({ name: "page", value: 0 }))
        dispatch(setVarsReload());
    };
    const handleChangePage = (event, newPage, doReload) => {
        dispatch(changeVarsFilter({ name: "page", value: newPage }))
        if (doReload) {
            dispatch(setVarsReload());
        }
    };
    useEffect(() => {
        if (varsReload && !varsFilter.machine) {
            if (varToSee && varToSee.varDef && varToSee.varDef.id !== null) {
                dispatch(getListVarsByTypeAPI(varsFilter));
            } else {
                dispatch(getListVarsByNameAPI(varsFilter));
            }
        } else if (varsReload && varsFilter.machine) {
            dispatch(getListMachineVarsByNameAPI(varsFilter));
        }
    }, [dispatch, varsReload, varsFilter, varToSee]);

    const renderVars = () => {
        if (vars && vars.length > 0) {
            return (
                vars.map((v) => {
                    return (
                        <TableRow key={v.name + "_" + v.id} style={{ cursor: "pointer" }}
                            onClick={modeStapling ? () => settingVarToSee(odp.id, false, v, false, true) : () => settingVarToSee(odp.id, process.id, v, false, false)}>
                            <TableCell className={customClasses.tableCellBreak140}>
                                {v.serial ? v.name + " (" + v.serialNo + ")" : v.name}
                            </TableCell>
                            <TableCell className={customClasses.tableCellBreak100}>
                                {v.type === "boolean" && v.value === "false" ? <ClearIcon /> : v.type === "boolean" && v.value === "true" ? <CheckIcon /> :
                                    v.value !== null && v.type !== "number" ? v.value : v.value !== null && v.type === "number" ? Number(v.value).toFixed(configuration.decimals) :
                                        "-"}
                            </TableCell>
                            <TableCell align="right" className={customClasses.tableCellBreak110}>
                                {v.timestamp !== null ? moment(v.timestamp).format(bishopFormats.L) : null}
                                <br />
                                {v.timestamp !== null ? moment(v.timestamp).format(bishopFormats.H) : null}
                            </TableCell>
                        </TableRow>
                    )
                })
            )
        }
        else return (
            <TableRow >
                <TableCell colSpan={5}>
                    {customLabel("function.notAvailable").toUpperCase()}
                </TableCell>
            </TableRow>
        );
    };

    const renderHistoricalVarsContent = () => {
        return (
            <TableVarsDetails varList={varList} varToSee={varToSee} page={varsFilter.page}
                setPage={handleChangePage} rowsPerPage={varsFilter.rowsPerPage} setRowsPerPage={handleChangeRowsPerPage} results={varsResults}
                showOperator={false} decimals={configuration.decimals} />
        )
    }
    const renderHistoricalVarsActions = () => {
        return (
            <Button className={customClasses.dialogButton} onClick={() => unSetVarsDetails()} variant="contained" color="secondary">{customLabel("button.close")}</Button>
        )
    }

    return (
        <React.Fragment>
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={customClasses.tableCellBreak140}>
                                {customLabel("function.name").toUpperCase()}
                            </TableCell>
                            <TableCell className={customClasses.tableCellBreak100}>
                                {customLabel("function.value")}
                            </TableCell>
                            <TableCell align="right" className={customClasses.tableCellBreak110}>
                                {customLabel("function.time")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderVars()}
                    </TableBody>
                </Table>
            </div>
            {/*Dialog per mostrare lo storico variabili */}
            <DialogOperator flag={varList && varToSee ? true : false}
                title={`${customLabel("function.historicalDeclaration")}: ${varToSee ? varToSee.name : ""} ${varToSee && varToSee.serial ? " - " + varToSee.serialNo : ""}  ${varToSee && varToSee.measure !== "" && varToSee.measure !== null ? " (" + varToSee.measure + ")" : ""}`}
                form={() => renderHistoricalVarsContent()}
                actions={() => renderHistoricalVarsActions()} transparentBackround={false} />
        </React.Fragment>
    );
};
export default MaintenanceVars;