export const LOADING_USER_EVENTS_TYPES = "loadingUserEventTypes";
export const ERRORS_USER_EVENT_TYPES = "errorsUserEventTypes";
export const LOADING_MACHINE_EVENT_TYPES = "loadingMachineEventTypes";
export const ERRORS_MACHINE_EVENT_TYPES = "errorsMachineEventTypes";
export const LOADING_MACHINE_VARS = "loadingMachineVars";
export const ERRORS_MACHINE_VARS = "errorsMachineVars";
export const LOADING_COMPONENTS = "loadingComponents";
export const ERRORS_COMPONENTS = "errorsComponents";

export const LOADING_STAPLING_CHECKS = "loadingStaplingChecks";
export const ERRORS_STAPLING_CHECKS = "errorsStaplingChecks";
export const LOADING_STAPLING_VARS = "loadingStaplingVars";
export const ERRORS_STAPLING_VARS = "errorsStaplingVars";
export const LOADING_STAPLING_ODP = "loadingStaplingOdp";
export const ERRORS_STAPLING_ODP = "errorsStaplingOdp";
export const LOADING_STAPLING_OEE = "loadingStaplingOee";
export const ERRORS_STAPLING_OEE = "errorsStaplingOee";
export const LOADING_STAPLING_MACHINE_EVENT = "loadingStaplingMachineEvent";
export const ERRORS_STAPLING_MACHINE_EVENT = "errorsStaplingMachineEvent";
export const LOADING_STAPLING_USER_EVENT = "loadingStaplingUserEvent";
export const ERRORS_STAPLING_USER_EVENT = "errorsStaplingUserEvent";

export const LOADING_PROCESS_CHECKS = "loadingProcessChecks";
export const ERRORS_PROCESS_CHECKS = "errorsProcessChecks";
export const LOADING_PROCESS_VARS = "loadingProcessVars";
export const ERRORS_PROCESS_VARS = "errorsProcessVars";
