import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  suppliersSelector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete,
} from "./slice";
import { workcentresSelector, getListAPI as getWorkcentresAPI } from "../workcentres/slice";

import { Box } from "@material-ui/core";
import { Form } from "./Form";
import { Details } from "./Details";

const SupplierPage = ({ match }) => {
  const dispatch = useDispatch();
  const { supplier, supplieredit, editmode, deleting, isCreated, isDeleted, suppliers, loading } = useSelector(suppliersSelector);
  const { workcentres } = useSelector(workcentresSelector);

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    dispatch(change({ name: name, value: value }));
  };

  const handleChangeObject = (name, value) => {
    if (value) {
      dispatch(change({ name: name, value: value }))
    }
    else {
      dispatch(change({ name: name, value: false }))
    }
  };

  const toggleDelete = (event) => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(supplieredit));
  };

  const onEdit = () => {
    dispatch(edit());
  };
  const doDelete = () => {
    dispatch(deleteAPI(supplier));
  };

  const save = () => {
    if (supplier.id === "new") {
      dispatch(createAPI(supplieredit));
    } else {
      dispatch(updateAPI(supplieredit));
    }
  };

  useEffect(() => {
    const { id } = match.params;
    if (id !== "new") {
      dispatch(getAPI(id));
    } else {
      dispatch(create());
    }
    dispatch(getWorkcentresAPI(0, 10000, "code", "asc", false, false));
  }, [dispatch, match]);

  const rendersupplier = () => {
    if (supplier !== false && !editmode && match.params.id === supplier.id.toString())
      return (
        <div>
          <Details supplier={supplier} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting}
            doDelete={doDelete} loading={loading}
          />
        </div>
      );
    if (supplier !== false && editmode)
      return (
        <Form supplieredit={supplieredit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save}
          suppliers={suppliers} workcentres={workcentres} handleChangeObject={handleChangeObject} loading={loading}
        />
      );
    return;
  };

  if (isDeleted && match.params.id === supplier.id.toString()) {
    return <Redirect to={"/suppliers/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/suppliers/" + supplier.id} />;
  }

  return <Box>{rendersupplier()}</Box>;
};

export default SupplierPage;
