import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../../utility/customLabel";
import { Tab, AppBar, Tabs } from "@material-ui/core";
import { BoxForm } from "../../utility/ultrafabStyles";
import Started from "./lists/Started";
import Paused from "./lists/Paused";
import NotScheduled from "./lists/NotScheduled";
import NotStarted from "./lists/NotStarted";
import External from "./lists/External";
import Closed from "./lists/Closed";
import { resetOdps, changeCustomFilter, enableCustomReload } from "./slice";
import { doChange } from "../slice";
import { Redirect } from 'react-router';
import { configurationSelector } from "../../configuration/slice";
import { currentList } from './utility';
import { selector } from './slice';

const Home = () => {
  const dispatch = useDispatch();
  const [redirectOdp, setRedirectOdp] = React.useState(false);
  const { configuration } = useSelector(configurationSelector);
  const { tabIndex } = useSelector(selector);

  const enableReload = useCallback(() => {
    dispatch(enableCustomReload());
  }, [dispatch]);

  useEffect(() => {
    dispatch(enableCustomReload());
  }, [dispatch]);

  /**
   * Funzione chiamata sul click del tab
   * @param {event} event evento chiamato sul onClick
   * @param {int} newValue valore del tab
   */
  const handleChangeTab = (event, newValue) => {
    dispatch(resetOdps(newValue));
  };

  const handleClickRedirect = (id) => {
    dispatch(doChange("/odps"));
    setRedirectOdp(`/odps/${id}`);
  }

  const handleChangePage = (event, newPage, typeStatus) => {
    dispatch(changeCustomFilter({ name: "page", value: newPage, typeStatus: typeStatus }));
  };

  const handleChangeRowsPerPage = (event, typeStatus) => {
    dispatch(changeCustomFilter({ name: "rowsPerPage", value: parseInt(event.target.value, 10), typeStatus: typeStatus }));
    dispatch(changeCustomFilter({ name: "page", value: 0, typeStatus: typeStatus }));
  };


  const onChangeFilter = (event, typeStatus) => {
    let name = event.target.name;
    let value = event.target.value;
    dispatch(changeCustomFilter({ name: name, value: value, typeStatus: typeStatus }));
  };

  const changeFilters = (name, value, typeStatus) => {
    dispatch(changeCustomFilter({ name: name, value: value, typeStatus: typeStatus }));
  };

  const onChangeFilterAutocomplete = (event, value, reason, name, typeStatus) => {
    dispatch(changeCustomFilter({ name: "page", value: 0, typeStatus: typeStatus }));
    if (reason === "clear") {
      changeFilters(name, false, typeStatus);
    } else {
      changeFilters(name, value, typeStatus);
    }
  };

  const handleChangeDate = (name, value, typeStatus) => {
    if (name.endsWith("From")) {
      const newValue = value.startOf('day')
      dispatch(changeCustomFilter({ name: name, value: new Date(newValue.format()).getTime(), typeStatus: typeStatus }));
    }
    else if (name.endsWith("To")) {
      const newValue = value.endOf('day');
      dispatch(changeCustomFilter({ name: name, value: new Date(newValue.format()).getTime(), typeStatus: typeStatus }));
    }
  };

  const handleResetDate = (e, name, typeStatus) => {
    e.stopPropagation();
    dispatch(changeCustomFilter({ name: name, value: false, typeStatus: typeStatus }));
  }

  if (redirectOdp) {
    return <Redirect to={redirectOdp} />;
  }

  return (
    <BoxForm>
      <AppBar position="static">
        <Tabs value={tabIndex} onChange={handleChangeTab} style={{ backgroundColor: "white", color: "black" }} variant="scrollable" scrollButtons="auto">
          <Tab label={customLabel("odp.odpCurrentStatus.odpsStarted")} key={"odpsStarted"} value={0} />
          <Tab label={customLabel("odp.odpCurrentStatus.odpsPaused")} key={"odpsPaused"} value={1} />
          <Tab label={customLabel("odp.odpCurrentStatus.odpsNotStarted")} key={"odpsNotStarted"} value={2} />
          <Tab label={customLabel("odp.odpCurrentStatus.odpsNotScheduled")} key={"odpsNotScheduled"} value={3} />
          <Tab label={customLabel("odp.odpCurrentStatus.odpsExternal")} key={"odpsExternal"} value={4} />
          <Tab label={customLabel("odp.odpCurrentStatus.odpsClosed")} key={"odpsClosed"} value={5} />
        </Tabs>
      </AppBar>
      {tabIndex === currentList.started.tabIndex ? (
        <Started handleClickRedirect={handleClickRedirect} onChangeFilter={onChangeFilter} handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage} onChangeFilterAutocomplete={onChangeFilterAutocomplete} handleChangeDate={handleChangeDate}
          handleResetDate={handleResetDate} configuration={configuration} enableReload={enableReload} />
      ) : tabIndex === currentList.paused.tabIndex ? (
        <Paused handleClickRedirect={handleClickRedirect} onChangeFilter={onChangeFilter} handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage} onChangeFilterAutocomplete={onChangeFilterAutocomplete} handleChangeDate={handleChangeDate}
          handleResetDate={handleResetDate} configuration={configuration} enableReload={enableReload} />
      )
        : tabIndex === currentList.notStarted.tabIndex ? (
          <NotStarted handleClickRedirect={handleClickRedirect} onChangeFilter={onChangeFilter} handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage} onChangeFilterAutocomplete={onChangeFilterAutocomplete} handleChangeDate={handleChangeDate}
            handleResetDate={handleResetDate} configuration={configuration} enableReload={enableReload} />
        )
          : tabIndex === currentList.notScheduled.tabIndex ? (
            <NotScheduled handleClickRedirect={handleClickRedirect} onChangeFilter={onChangeFilter} handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage} onChangeFilterAutocomplete={onChangeFilterAutocomplete} handleChangeDate={handleChangeDate}
              handleResetDate={handleResetDate} configuration={configuration} enableReload={enableReload} />
          )
            : tabIndex === currentList.external.tabIndex ? (
              <External handleClickRedirect={handleClickRedirect} onChangeFilter={onChangeFilter} handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage} onChangeFilterAutocomplete={onChangeFilterAutocomplete} handleChangeDate={handleChangeDate}
                handleResetDate={handleResetDate} configuration={configuration} enableReload={enableReload} />
            )
              : tabIndex === currentList.closed.tabIndex ? (
                <Closed handleClickRedirect={handleClickRedirect} onChangeFilter={onChangeFilter} handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage} onChangeFilterAutocomplete={onChangeFilterAutocomplete} handleChangeDate={handleChangeDate}
                  handleResetDate={handleResetDate} configuration={configuration} enableReload={enableReload} />
              )
                : null
      }
    </BoxForm>
  )
};

export default Home;
