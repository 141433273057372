import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../../constants";
import { showError, showSuccess } from "../../notifications/slice";
import { logout, refreshAPI } from "../../main/slice";
import _ from 'lodash';
import { customLabel } from "../../utility/customLabel";

export const initialState = {
  loading: false,
  editing: false,
  hasErrors: false,
  processes: false,
  orphans: false,
  expiredOrphans: false,
  machines: false,
  selectedProcess: false,
  savingProcess: false,
};

const plannerSlice = createSlice({
  name: "planner",
  initialState,
  reducers: {
    enableErrors: (state) => {
      state.hasErrors = true;
      state.loading = false;
    },
    setUpMachines: (state, { payload }) => {
      let processes = {}
      for (let m in payload) {
        if (processes[payload[m].code] === undefined) {
          processes[payload[m].code] = false
        }
      }
      state.processes = processes;
    },
    gettingProcesses: (state, { payload }) => {
      state.loading = true;
      if (payload.editing !== false) {
        let editing = state.editing
        if (editing === false) {
          editing = {}
        }
        if (payload.machine === "ORPHAN") {
          editing["ORPHAN"] = true
        } else if (payload.machine === "EXPIRED_ORPHAN") {
          editing["EXPIRED_ORPHAN"] = true
        } else {
          editing[payload.machine.code] = true
        }
        state.editing = editing
      }
      state.hasErrors = false;
      state.reload = false;
    },
    getProcessesSuccess: (state, { payload }) => {
      let code = payload.machine.code
      state.loading = false;
      let processes = state.processes
      processes[code] = []
      for (let p in payload.data) {
        let process = payload.data[p]
        processes[code].push(process)
      }
      state.processes = processes;
      let editing = state.editing;
      delete editing[code]
      state.editing = editing
    },
    getOrphanProcessesSuccess: (state, { payload }) => {
      state.loading = false;
      state.orphans = payload
      let editing = state.editing;
      delete editing["ORPHAN"]
      state.editing = editing
    },
    getExpiredOrphanProcessesSuccess: (state, { payload }) => {
      state.loading = false;
      state.expiredOrphans = payload
      let editing = state.editing;
      delete editing["EXPIRED_ORPHAN"]
      state.editing = editing
    },
    updatingProcessMachine: (state, { payload }) => {
      let editing = state.editing
      if (editing === false) {
        editing = {}
      }
      editing[payload.machine.code] = true
      state.editing = editing;
      state.hasErrors = false;
      state.reload = false;
      state.selectedProcess = false;
    },
    updatingProcessMachineSuccess: (state) => {
      state.selectedProcess = false;
    },
    enableEditing: (state, { payload }) => {
      let editing = state.editing
      if (editing === false) {
        editing = {}
      }
      editing[payload.code] = true
      state.editing = editing;
    },
    selectProcess: (state, { payload }) => {
      let selectedProcess = _.cloneDeep(payload);
      if (payload && payload.cycleTime !== null) {
        let cycleTime = payload.cycleTime.cycleTime;
        selectedProcess["cycleTimeEdit"] = cycleTime;
      }
      state.selectedProcess = selectedProcess;
    },
    onChangeProcess: (state, { payload }) => {
      let process = state.selectedProcess
      if (payload.name === "workcentre") {
        process["machine"] = { id: null }
      }
      if (payload.name === "machine" || payload.name === "workcentre") {
        process[payload.name] = { 'id': payload.value }
      } else {
        process[payload.name] = payload.value
      }
      state.selectedProcess = process;
    },
    updatingProcess: (state, { payload }) => {
      state.savingProcess = true
    },
    updatedProcess: (state, { payload }) => {
      state.selectedProcess = false
      state.savingProcess = false
    }
  },
});

export const { enableErrors, gettingProcesses, getProcessesSuccess, updatingProcessMachine, updatingProcessMachineSuccess,
  enableEditing, selectProcess, onChangeProcess, updatedProcess, updatingProcess, getOrphanProcessesSuccess, setUpMachines,
  getExpiredOrphanProcessesSuccess } = plannerSlice.actions;

export const selector = (state) => state.planner;

export default plannerSlice.reducer;

export function getProcesses(machine, editing) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(gettingProcesses({ machine: machine, editing: editing }));
    let url = SERVER_URL + "/api/planner/processes/" + machine.id
    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getProcessesSuccess({ data: data, machine: machine }));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function getOrphanProcesses(editing) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(gettingProcesses({ "machine": "ORPHAN", "editing": editing }));
    let url = SERVER_URL + "/api/planner/orphanProcesses"
    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getOrphanProcessesSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function getExpiredOrphanProcesses(editing) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(gettingProcesses({ "machine": "EXPIRED_ORPHAN", "editing": editing }));
    let url = SERVER_URL + "/api/planner/expiredOrphanProcesses"
    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getExpiredOrphanProcessesSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function updateProcessMachine(machine, process, processes, index, oldMachine) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { machine: machine, processes: processes, index: index };
  return async (dispatch) => {
    dispatch(updatingProcessMachine({ machine: machine }));
    let url = SERVER_URL + "/api/planner/process/" + process + "/machine"
    const response = await fetch(url, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updatingProcessMachineSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
        dispatch(getProcesses(machine, true))
        if (oldMachine.code === "ORPHAN") {
          dispatch(getOrphanProcesses("ORPHAN"))
        } else if (oldMachine.code === "EXPIRED_ORPHAN") {
          dispatch(getExpiredOrphanProcesses("EXPIRED_ORPHAN"))
        } else {
          dispatch(getProcesses(oldMachine, true))
        }
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
        dispatch(getProcesses(oldMachine, false))
        dispatch(getProcesses(machine, false))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function setFirst(process) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { machine: process.machine };
  return async (dispatch) => {
    dispatch(updatingProcessMachine({ machine: process.machine }));
    let url = SERVER_URL + "/api/planner/process/" + process.id + "/first"
    const response = await fetch(url, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updatingProcessMachineSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
        dispatch(getProcesses(process.machine, true))
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function setLast(process) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { machine: process.machine };
  return async (dispatch) => {
    dispatch(updatingProcessMachine({ machine: process.machine }));
    let url = SERVER_URL + "/api/planner/process/" + process.id + "/last"
    const response = await fetch(url, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updatingProcessMachineSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
        dispatch(getProcesses(process.machine, true))
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function updateProcess(process, oldMachine, newMachine) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { process: process };
  return async (dispatch) => {
    dispatch(updatingProcess());
    const response = await fetch(SERVER_URL + "/api/odp/" + process.odp.id + "/process/" + process.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updatedProcess());
        dispatch(showSuccess("function.operationSuccess"));
        if (newMachine.code === "ORPHAN") {
          dispatch(getOrphanProcesses("ORPHAN"))
        } else if (newMachine.code === "EXPIRED_ORPHAN") {
          dispatch(getExpiredOrphanProcesses("EXPIRED_ORPHAN"))
        } else {
          dispatch(getProcesses(newMachine, true))
        }
        if (oldMachine.code === "ORPHAN") {
          dispatch(getOrphanProcesses("ORPHAN"))
        } else if (oldMachine.code === "EXPIRED_ORPHAN") {
          dispatch(getExpiredOrphanProcesses("EXPIRED_ORPHAN"))
        } else {
          dispatch(getProcesses(oldMachine, true))
        }
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};