import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../constants";
import { showError, showSuccess, resetErrors as resetNotificationErrors } from "../notifications/slice";
import { logout, refreshAPI } from "../main/slice";
import { customLabel } from "../utility/customLabel";
import { customFilter } from "./utility";
import { setServerNotResponding } from "../main/slice";
import { SERVER_NOT_RESPONDING_MESSAGE } from "../../constants";

export const initialState = {
  loading: false,
  hasErrors: false,
  workcentres: false,
  reload: false,
  results: 0,
  editmode: false,
  workcentre: false,
  workcentreedit: false,
  deleting: false,
  isCreated: false,
  isDeleted: false,
  filter: false
};

const workcentresSlice = createSlice({
  name: "workcentres",
  initialState,
  reducers: {
    resetState: (state) => {
      if (!state.filter) {
        Object.assign(state, initialState);
      } else {
        let tempState = {};
        let currentFilter = { ...state.filter }
        Object.assign(tempState, initialState);
        tempState.filter = { ...currentFilter }
        Object.assign(state, tempState);
      }
    },
    gettingList: (state) => {
      state.loading = true;
      state.reload = false;
      state.hasErrors = false;
    },
    getListSuccess: (state, { payload }) => {
      state.workcentres = payload[1];
      state.results = payload[0].total_entries;
      state.loading = false;
    },
    setReload: (state) => {
      state.reload = true;
    },
    reset: (state) => {
      state.workcentre = false;
      state.workcentreedit = false;
      state.editmode = false;
      state.isDeleted = false;
      state.isCreated = false;
    },
    getting: (state) => {
      state.workcentre = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.loading = true;
      state.editmode = false;
      state.hasErrors = false;
    },
    getSuccess: (state, { payload }) => {
      state.workcentre = payload;
      state.loading = false;
    },
    creating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    create: (state) => {
      let newworkcentre = { id: "new", name: "", code: "", note: "" };
      state.workcentre = newworkcentre;
      state.workcentreedit = newworkcentre;
      state.editmode = true;
    },
    createSuccess: (state, { payload }) => {
      state.workcentre = payload;
      state.workcentreedit = false;
      state.loading = false;
      state.isCreated = true;
    },
    resetEdit: (state) => {
      state.editmode = true;
      state.workcentreedit = state.workcentre;
      state.loading = false;
      state.hasErrors = true;
    },
    edit: (state) => {
      state.editmode = true;
      state.workcentreedit = state.workcentre;
    },
    cancelEdit: (state) => {
      state.editmode = false;
      state.workcentreedit = false;
    },
    change: (state, { payload }) => {
      let u = state.workcentreedit;
      u[payload.name] = payload.value;
      state.workcentreedit = u;
    },
    updating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    updateSuccess: (state, { payload }) => {
      state.workcentre = payload;
      state.loading = false;
      state.editmode = false;
    },
    confirmDelete: (state) => {
      state.deleting = !state.deleting;
    },
    deleting: (state) => {
      state.deleting = false;
      state.loading = true;
      state.hasErrors = false;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.isDeleted = true;
    },
    enableErrors: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    changeFilter: (state, { payload }) => {
      state.filter[payload.name] = payload.value;
    },
    /**
     * 
     * @param {*} state 
     * @param {boolean} payload showFilter
     */
    initFilter: (state, { payload }) => {
      let tempFilter = { ...customFilter };
      tempFilter["showFilter"] = payload;
      state.filter = { ...tempFilter };
    }
  },
});

export const { resetState, gettingList, getListSuccess, setReload, reset, getting, enableErrors, getSuccess, create, creating, createSuccess, edit,
  cancelEdit, change, updating, updateSuccess, confirmDelete, deleting, deleteSuccess, resetEdit, loadingWorkcentres, loadingWorkcentresSuccess,
  changeFilter, initFilter } = workcentresSlice.actions;
export const workcentresSelector = (state) => state.workcentres;
export default workcentresSlice.reducer;

export function getListAPI(page, rowsPerPage, orderBy, order, name, code) {
  return async (dispatch) => {
    dispatch(gettingList());
    dispatch(reset());
    let url = SERVER_URL + "/workcentre?page=" + page + "&per_page=" + rowsPerPage + "&orderBy=" + orderBy + "&order=" + order;
    if (name !== false) {
      url = url + "&name=" + name;
    }
    if (code !== false) {
      url = url + "&code=" + code;
    }
    try {
      const response = await fetch(url, {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const data = await response.json();
      dispatch(setServerNotResponding(false));
      dispatch(resetNotificationErrors());
      if (response.status === 200) {
        dispatch(getListSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      dispatch(enableErrors());
      dispatch(setServerNotResponding(true));
      dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
    }
  };
}

export function updateAPI(workcentre) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { workcentre: workcentre };
  return async (dispatch) => {
    dispatch(updating());
    const response = await fetch(
      SERVER_URL + "/api/workcentre/" + workcentre.id,
      {
        mode: "cors",
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(params),
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(resetEdit());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function createAPI(workcentre) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(creating());
    let params = { workcentre: workcentre };
    const response = await fetch(SERVER_URL + "/api/workcentre", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function getAPI(id) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(getting());
    const response = await fetch(SERVER_URL + "/api/workcentre/" + id, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function deleteAPI(workcentre) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(deleting());
    const response = await fetch(
      SERVER_URL + "/api/workcentre/" + workcentre.id,
      {
        mode: "cors",
        method: "DELETE",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(deleteSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
