import {
    Checkbox, FormControl,
    FormControlLabel,
    Input, InputLabel
} from "@material-ui/core";
import React from "react";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";

const Components = ({ configurationedit, handleChange }) => {
    const myClasses = mesStyles();

    return (
        <div className={myClasses.dialogForm500}>
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.enableComponents} onChange={handleChange} name="enableComponents" color="default" />
                }
                label={customLabel("configuration.enableComponents")}
            />
            <FormControlLabel labelPlacement="end" disabled={!configurationedit.enableComponents}
                control={
                    <Checkbox checked={configurationedit.verifyComponentLot} onChange={handleChange} name="verifyComponentLot" color="default" />
                }
                label={customLabel("configuration.verifyComponentLot")}
            />
            <FormControlLabel labelPlacement="end" disabled={!configurationedit.enableComponents}
                control={
                    <Checkbox checked={configurationedit.enableFreeLotComponents} onChange={handleChange} name="enableFreeLotComponents" color="default" />
                }
                label={customLabel("configuration.enableFreeLotComponents")}
            />
            <FormControlLabel labelPlacement="end" disabled={!configurationedit.enableComponents}
                control={
                    <Checkbox checked={configurationedit.scrapReasonComponents} onChange={handleChange} name="scrapReasonComponents" color="default" />
                }
                label={customLabel("configuration.scrapReasonComponents")}
            />
            <FormControlLabel labelPlacement="end" disabled={!configurationedit.enableComponents}
                control={
                    <Checkbox checked={configurationedit.changeComponent} onChange={handleChange} name="changeComponent" color="default" />
                }
                label={customLabel("configuration.changeComponent")}
            />
            <FormControlLabel labelPlacement="end" disabled={!configurationedit.enableComponents}
                control={
                    <Checkbox checked={configurationedit.forceComponent} onChange={handleChange} name="forceComponent" color="default" />
                }
                label={customLabel("configuration.forceComponent")}
            />
            <FormControlLabel labelPlacement="end" disabled={!configurationedit.enableComponents}
                control={
                    <Checkbox checked={configurationedit.forceWithVerifyComponent} onChange={handleChange} name="forceWithVerifyComponent" color="default" />
                }
                label={customLabel("configuration.forceWithVerifyComponent")}
            />
            <FormControlLabel labelPlacement="end" disabled={!configurationedit.enableComponents}
                control={
                    <Checkbox checked={configurationedit.editComponent} onChange={handleChange} name="editComponent" color="default" />
                }
                label={customLabel("configuration.editComponent")}
            />
            <FormControlLabel labelPlacement="end" disabled={!configurationedit.enableComponents}
                control={
                    <Checkbox checked={configurationedit.hideComponentLot} onChange={handleChange} name="hideComponentLot" color="default" />
                }
                label={customLabel("configuration.hideComponentLot")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.showLotRemainingQuantity} onChange={handleChange} name="showLotRemainingQuantity" color="default" />
                }
                label={customLabel("configuration.showLotRemainingQuantity")}
            />
            <FormControlLabel labelPlacement="end" disabled={!configurationedit.enableComponents}
                control={
                    <Checkbox checked={configurationedit.showCloseLot} onChange={handleChange} name="showCloseLot" color="default" />
                }
                label={customLabel("configuration.showCloseLot")}
            />
            <FormControlLabel labelPlacement="end" disabled={!configurationedit.enableComponents}
                control={
                    <Checkbox checked={configurationedit.componentUseAlwaysFactor} onChange={handleChange} name="componentUseAlwaysFactor" color="default" />
                }
                label={customLabel("configuration.componentUseAlwaysFactor")}
            />
            <FormControlLabel labelPlacement="end" disabled={!configurationedit.enableComponents}
                control={
                    <Checkbox checked={configurationedit.retrieveLotInfo} onChange={handleChange} name="retrieveLotInfo" color="default" />
                }
                label={customLabel("configuration.componentRetrieveLotInfo")}
            />
            <FormControlLabel labelPlacement="end" disabled={!configurationedit.enableComponents}
                control={
                    <Checkbox checked={configurationedit.insertArticleIfForcedLot} onChange={handleChange} name="insertArticleIfForcedLot" color="default" />
                }
                label={customLabel("configuration.insertArticleIfForcedLot")}
            />
            <FormControl margin="normal" fullWidth disabled={!configurationedit.enableComponents}>
                <InputLabel htmlFor="componentUrl">{customLabel("configuration.componentUrl")}</InputLabel>
                <Input name="componentUrl" value={configurationedit.componentUrl !== "null" && configurationedit.componentUrl !== null ? configurationedit.componentUrl : ""} onChange={handleChange} />
            </FormControl>
            <FormControl margin="normal" fullWidth disabled={!configurationedit.enableComponents}>
                <InputLabel htmlFor="componentDecimals">{customLabel("configuration.decimals")}</InputLabel>
                <Input name="componentDecimals" type="number"
                    error={configurationedit.componentDecimals === "" || configurationedit.componentDecimals < 0} value={configurationedit.componentDecimals !== false ? configurationedit.componentDecimals : ""}
                    onChange={handleChange} />
            </FormControl>
            <FormControl margin="normal" fullWidth disabled={!configurationedit.enableComponents}>
                <InputLabel htmlFor="componentPercentualeOk">{customLabel("configuration.percentualeOk")}</InputLabel>
                <Input name="componentPercentualeOk" type="number" error={configurationedit.componentPercentualeOk === "" || configurationedit.componentPercentualeOk < 0 || configurationedit.componentPercentualeOk > 100}
                    value={configurationedit.componentPercentualeOk !== false ? configurationedit.componentPercentualeOk : null} onChange={handleChange} />
            </FormControl>
        </div>
    );
};
export default Components;