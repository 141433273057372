import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Input, InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import React from "react";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";

const General = ({ configurationedit, handleChange }) => {
    const myClasses = mesStyles();

    return (
        <div className={myClasses.dialogForm500}>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="decimals">{customLabel("configuration.decimals")}</InputLabel>
                <Input name="decimals" type="number"
                    error={configurationedit.decimals === "" || configurationedit.decimals < 0} value={configurationedit.decimals !== false ? configurationedit.decimals : ""}
                    onChange={handleChange} />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="dataRetentionCloseOdpDays">{customLabel("configuration.dataRetentionCloseOdpDays")}</InputLabel>
                <Input name="dataRetentionCloseOdpDays" type="number"
                    error={configurationedit.dataRetentionCloseOdpDays === "" || configurationedit.dataRetentionCloseOdpDays < 0} value={configurationedit.dataRetentionCloseOdpDays !== false ? configurationedit.dataRetentionCloseOdpDays : ""}
                    onChange={handleChange} />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="dataRetentionVarsOpenOdpsDays">{customLabel("configuration.dataRetentionVarsOpenOdpsDays")}</InputLabel>
                <Input name="dataRetentionVarsOpenOdpsDays" type="number"
                    error={configurationedit.dataRetentionVarsOpenOdpsDays === "" || configurationedit.dataRetentionVarsOpenOdpsDays < 0} value={configurationedit.dataRetentionVarsOpenOdpsDays !== false ? configurationedit.dataRetentionVarsOpenOdpsDays : ""}
                    onChange={handleChange} />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="dataRetentionDataOpenOdpsDays">{customLabel("configuration.dataRetentionDataOpenOdpsDays")}</InputLabel>
                <Input name="dataRetentionDataOpenOdpsDays" type="number"
                    error={configurationedit.dataRetentionDataOpenOdpsDays === "" || configurationedit.dataRetentionDataOpenOdpsDays < 0} value={configurationedit.dataRetentionDataOpenOdpsDays !== false ? configurationedit.dataRetentionDataOpenOdpsDays : ""}
                    onChange={handleChange} />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="machineEventReasonMode">{customLabel("configuration.machineEventReasonMode")}</InputLabel>
                <Select name="machineEventReasonMode" value={configurationedit.machineEventReasonMode} onChange={handleChange}>
                    <MenuItem value="createNew">{customLabel("configuration.machineEventReasonMode.createNew")}</MenuItem>
                    <MenuItem value="changeExistent">{customLabel("configuration.machineEventReasonMode.changeExistent")}</MenuItem>
                </Select>
            </FormControl>
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.showUserEvents} onChange={handleChange} name="showUserEvents" color="default" />
                }
                label={customLabel("configuration.showUserEvents")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.singleUserEvent} onChange={handleChange} name="singleUserEvent" color="default" />
                }
                label={customLabel("configuration.singleUserEvent")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.showFilters} onChange={handleChange} name="showFilters" color="default" />
                }
                label={customLabel("configuration.showFilters")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.showArticleOrder} onChange={handleChange} name="showArticleOrder" color="default" />
                }
                label={customLabel("configuration.showArticleOrder")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.showOdpDeliveryTime} onChange={handleChange} name="showOdpDeliveryTime" color="default" />
                }
                label={customLabel("configuration.showOdpDeliveryTime")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.enableMaintenance} onChange={handleChange} name="enableMaintenance" color="default" />
                }
                label={customLabel("configuration.enableMaintenance")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.enableCheckDialogColor} onChange={handleChange} name="enableCheckDialogColor" color="default" />
                }
                label={customLabel("configuration.enableCheckDialogColor")}
            />
        </div>
    );
};
export default General;