import { AppBar, Paper, Tab, Tabs } from "@material-ui/core";
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import DocumentsPage from "../documents/DocumentsPage";
import ComponentsPage from '../operatorView/components/ComponentsPage';
import { customLabel } from "../utility/customLabel";
import { BoxForm } from "../utility/ultrafabStyles";
import ExportDataList from "./ExportDataList";
import InfoOdp from './InfoOdp';
import InfoProcesses from "./InfoProcesses";
import ReloadingMaintenance from './reloading/ReloadingMaintenance';
import ReloadingStandardOdp from './reloading/ReloadingStandardOdp';
import ReloadingStaplingOdp from "./reloading/ReloadingStaplingOdp";
import StaplingChecks from "./stapling/Checks";
import StaplingVars from "./stapling/Vars";
import {
    ODP_HOME_TAB_DOCUMENTS, ODP_HOME_TAB_EXPORT,
    ODP_HOME_TAB_MACHINE_EVENTS,
    ODP_HOME_TAB_ODP,
    ODP_HOME_TAB_PROCESSES, ODP_HOME_TAB_STAPLING,
    ODP_HOME_TAB_STAPLING_CHECKS,
    ODP_HOME_TAB_STAPLING_COMPONENTS,
    ODP_HOME_TAB_STAPLING_VARS
} from './utility/constants';

const OdpHome = ({ match, odp, loading, editmode, odpedit, renderNewDetailsBom, renderDetailsOdp, renderFormOdp,
    renderProductionData, renderDetailsBom, renderHome, renderFormMaintenance, renderMachineEvents, changeTabIndex, tabIndex, configuration }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeTabIndex(ODP_HOME_TAB_ODP))
    }, [dispatch, changeTabIndex])

    //sposta il tab fra menu e documents
    const handleChangeTab = (event, newValue) => {
        dispatch(changeTabIndex(newValue));
    };

    const renderStandardOdpTabs = () => {
        return (
            <Tabs value={tabIndex} onChange={handleChangeTab} style={{ backgroundColor: "white", color: "black" }} variant="scrollable" scrollButtons="auto">
                <Tab label={customLabel("odp.odp")} key={"odp"} value={ODP_HOME_TAB_ODP} />
                <Tab label={customLabel("process.processes")} key={"processes"} value={ODP_HOME_TAB_PROCESSES}
                    disabled={odp && odp.processes && odp.processes.length > 0 ? false : true} />
                <Tab label={customLabel("document.documents")} key={"documents"} value={ODP_HOME_TAB_DOCUMENTS} />
                <Tab label={customLabel("odp.export")} key={"export"} value={ODP_HOME_TAB_EXPORT} />
            </Tabs>
        )
    }

    const renderStaplingOdpTabs = () => {
        return (
            <Tabs value={tabIndex} onChange={handleChangeTab} style={{ backgroundColor: "white", color: "black" }} variant="scrollable" scrollButtons="auto">
                <Tab label={customLabel("stapling.stapling")} key={"stapling"} value={ODP_HOME_TAB_STAPLING} />
                <Tab label={customLabel("machineEvent.machineEvents")} key={"machineEvents"} value={ODP_HOME_TAB_MACHINE_EVENTS} />
                <Tab label={customLabel("stapling.vars")} key={"vars"} value={ODP_HOME_TAB_STAPLING_VARS} />
                <Tab label={customLabel("component.components")} key={"components"} value={ODP_HOME_TAB_STAPLING_COMPONENTS} />
                <Tab label={customLabel("stapling.checks")} key={"checks"} value={ODP_HOME_TAB_STAPLING_CHECKS} />
                <Tab label={customLabel("odp.export")} key={"export"} value={ODP_HOME_TAB_EXPORT} />
            </Tabs>
        )
    }

    const renderMaintenanceTabs = () => {
        return (
            <Tabs value={tabIndex} onChange={handleChangeTab} style={{ backgroundColor: "white", color: "black" }} variant="scrollable" scrollButtons="auto">
                <Tab label={customLabel("maintenance.maintenance")} key={"maintenance"} value={ODP_HOME_TAB_ODP} />
                <Tab label={customLabel("maintenance.processes")} key={"processes"} value={ODP_HOME_TAB_PROCESSES}
                    disabled={odp && odp.processes && odp.processes.length > 0 ? false : true} />
            </Tabs>
        )
    }

    const renderStep = () => {
        switch (tabIndex) {
            //case ODP_HOME_TAB_ODP && !odp.stapling: return <InfoOdp match={match} />;
            //case ODP_HOME_TAB_STAPLING && odp.stapling: return <InfoOdp match={match} />;
            case ODP_HOME_TAB_ODP: return <InfoOdp match={match} odp={odp} loading={loading} renderDetailsOdp={renderDetailsOdp}
                renderFormOdp={renderFormOdp} renderNewDetailsBom={renderNewDetailsBom} renderProductionData={renderProductionData}
                renderFormMaintenance={renderFormMaintenance} editmode={editmode} odpedit={odpedit} />;
            case ODP_HOME_TAB_PROCESSES: return <InfoProcesses odp={odp} loading={loading} renderDetailsBom={renderDetailsBom} renderHome={renderHome} />;
            case ODP_HOME_TAB_DOCUMENTS: return (
                <Paper>
                    <DocumentsPage parentId={odp.id} parentType="odps" extLoading={loading} title={false} size="small" />
                </Paper>)
            case ODP_HOME_TAB_EXPORT: return (
                <Paper style={{ overflowX: "auto" }}>
                    <ExportDataList odp={odp} stapling={odp.stapling} loading={loading} />
                </Paper>
            )
            case ODP_HOME_TAB_MACHINE_EVENTS: return renderMachineEvents()
            case ODP_HOME_TAB_STAPLING_VARS: return <StaplingVars />
            case ODP_HOME_TAB_STAPLING_CHECKS: return <StaplingChecks />
            case ODP_HOME_TAB_STAPLING_COMPONENTS: return (
                <Paper style={{ overflow: "hidden", minHeight: 'calc(75vh - 38px)', height: 'auto' }}>
                    <ComponentsPage configuration={configuration} operatorView={false} />
                </Paper>
            )
            default: return null;
        }
    };

    return (
        <BoxForm>
            <AppBar position="static">
                {odp && !odp.stapling && !odp.maintenance ? renderStandardOdpTabs()
                    : odp && odp.stapling && !odp.maintenance ? renderStaplingOdpTabs()
                        : odp && !odp.stapling && odp.maintenance ? renderMaintenanceTabs()
                            : null}
            </AppBar>
            <div style={{ marginTop: "8px" }}></div>
            {renderStep()}
            {odp && !odp.stapling && !odp.maintenance ? <ReloadingStandardOdp configuration={configuration}/>
                : odp && odp.stapling && !odp.maintenance ? <ReloadingStaplingOdp configuration={configuration} />
                    : odp && !odp.stapling && odp.maintenance ? <ReloadingMaintenance /> : null}
        </BoxForm>
    )
};

export default OdpHome;
