import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { rolesSelector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete } from './slice'
import { Box } from "@material-ui/core";

import { Form } from './Form'
import { Details } from './Details'

const RolePage = ({ match }) => {
  const dispatch = useDispatch()
  const { role, roleedit, editmode, deleting, isCreated, isDeleted, roles, loading } = useSelector(rolesSelector)

  const handleChange = event => {
    let name = event.target.name
    let value = event.target.value
    if (event.target.type === "checkbox") {
      value = event.target.checked
    }
    dispatch(change({ name: name, value: value }));
  };

  const toggleDelete = event => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(roleedit))
  }

  const onEdit = () => {
    dispatch(edit())
  }
  const doDelete = () => {
    dispatch(deleteAPI(role))
  }

  const save = () => {
    if (role.id === "new") {
      dispatch(createAPI(roleedit));
    } else {
      dispatch(updateAPI(roleedit));
    }
  };

  useEffect(() => {
    const { id } = match.params
    if (id !== "new") {
      dispatch(getAPI(id))
    } else {
      dispatch(create())
    }
  }, [dispatch, match])

  const renderRole = () => {
    if (role !== false && !editmode && match.params.id === role.id.toString()) {
      return <Details role={role} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete} loading={loading} />
    }
    if (role !== false && editmode) {
      return <Form roleedit={roleedit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save} roles={roles} loading={loading} />
    }
    return
  }

  if (isDeleted && match.params.id === role.id.toString()) {
    return (<Redirect to={"/admin/roles/"} />)
  }

  if (isCreated) {
    return (<Redirect to={"/admin/roles/" + role.id} />)
  }

  return (
    <Box>
      {renderRole()}
    </Box>
  )
}

export default RolePage
