import React from "react";
import { Link } from "react-router-dom";
import { FormControl, InputLabel, Input, Button, Typography, TextField } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Autocomplete } from "@material-ui/lab"
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import PaperAutocomplete from "../utility/PaperAutocomplete";

export const Form = ({ maintenanceTypeedit, handleChange, cancelEdit, save, processTypes, handleChangeObject, loading }) => {
  const myClasses = mesStyles();

  const onChangeFilterAutocomplete = (name, value, reason) => {
    if (reason === "clear") {
      handleChangeObject(name, false)
    } else {
      handleChangeObject(name, value.id)
    }
  };

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h3" variant="h6" color="inherit">{customLabel("maintenanceType.maintenanceType")}</Typography>
        <LockOpenIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="name">{customLabel("maintenanceType.name")}</InputLabel>
          <Input autoFocus name="name" error={maintenanceTypeedit.name === ""} value={maintenanceTypeedit.name} onChange={handleChange} />
        </FormControl>
        {processTypes ? (
          <Autocomplete
            PaperComponent={PaperAutocomplete}
            style={{ display: "inline-block" }}
            className={myClasses.form50}
            value={maintenanceTypeedit.processType.id !== "" ? maintenanceTypeedit.processType : null}
            getOptionSelected={(option, value) => maintenanceTypeedit.processType.id !== '' ? option.id === value.id : null}
            onChange={(event, value, reason) => onChangeFilterAutocomplete("processType.id", value, reason)}
            options={processTypes}
            getOptionLabel={(option) => option.code + " - " + option.name}
            id="processTypeAutocomplete"
            renderInput={(params) => <TextField {...params} label={customLabel("maintenancePhase.maintenancePhase")} error={maintenanceTypeedit.processType.id === ''} />
            }
          />)
          : null}
      </form>
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple onClick={save}
          disabled={maintenanceTypeedit.name === "" || maintenanceTypeedit.backgroundColor === "" || maintenanceTypeedit.disactiveBackgroundColor === "" || loading
            || maintenanceTypeedit.backgroundColor === null || maintenanceTypeedit.backgroundColor === false || maintenanceTypeedit.disactiveBackgroundColor === null || maintenanceTypeedit.disactiveBackgroundColor === false}
          color="primary">{customLabel("button.save")}</Button>
        {maintenanceTypeedit.id !== "new" ? (
          <Button focusRipple onClick={cancelEdit} disabled={loading}>{customLabel("button.cancel")}</Button>
        ) : (
          <Button component={Link} to={`/admin/maintenanceTypes`} disabled={loading}>{customLabel("button.back")}</Button>
        )}
      </div>
    </PaperForm>
  );
};
