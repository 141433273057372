import React from "react";
import {
    Input, InputLabel, FormControl
} from "@material-ui/core";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";

const Planner = ({ configurationedit, handleChange }) => {
    const myClasses = mesStyles();

    return (
        <div className={myClasses.dialogForm500}>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="plannerDaysLimit">{customLabel("configuration.plannerDaysLimit")}</InputLabel>
                <Input name="plannerDaysLimit" type="number"
                    error={configurationedit.plannerDaysLimit === ""} value={configurationedit.plannerDaysLimit !== false ? configurationedit.plannerDaysLimit : ""}
                    onChange={handleChange} />
            </FormControl>
        </div>
    );
};
export default Planner;