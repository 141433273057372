import moment from "moment";
import React from 'react';
import { bishopFormats } from '../../../constants';
import { calcRemainingSetupTime, calcRemainingTime } from "../../utility/vari";
import ProgressBarOneLine from "../../utility/ProgressBarOneLine";
import { Draggable } from 'react-beautiful-dnd';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import EventIcon from '@material-ui/icons/Event';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import WorkIcon from '@material-ui/icons/Work';
import ExtensionIcon from '@material-ui/icons/Extension';
import LayersIcon from '@material-ui/icons/Layers';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    box: {
        height: "auto",
        padding: theme.spacing(1),
        marginRight: theme.spacing(1),
        cursor: "move",
        maxWidth: "300px",
        minWidth: "300px",
    },
    icon: {
        fontSize: "0.95rem"
    },
    clickIcon: {
        fontSize: "0.95rem",
        cursor: "pointer"
    },
    text: {
        display: "inline-block",
        maxWidth: "100px",
        minWidth: "100px",
        whiteSpace: "nowrap",
        overflow: "hidden !important",
        textOverflow: "ellipsis"
    }
}));
export const Process = ({ index, process, handleClosePopoverPs, handleOpenPopoverPs, openProcessDetails, selectOdp, selectedOdp }) => {

    const classes = useStyles();

    const backgroundColor = selectedOdp !== false && selectedOdp.id === process.odp.id && process.available ? "#6ca6fc" :
        selectedOdp !== false && selectedOdp.id === process.odp.id && !process.available ? "#8ca3c7" :
            (selectedOdp === false || selectedOdp.id !== process.odp.id) && !process.available ? "#c8c8c8" :
                null;

    const returnOdpData = (process) => {
        let rt = calcRemainingTime(process.cycleTime ? process.cycleTime.cycleTime : null, process.validPieces, process.totalPieces, process.quantity, true);
        let rst = calcRemainingSetupTime(process.expectedSetupTime, process.setupTime);
        return (
            <Paper onClick={() => selectOdp(process.odp)} className={classes.box} key={process.id}
                style={{ backgroundColor: backgroundColor, borderLeft: "5px solid " + process.status.backgroundColor }}
                onMouseEnter={(e) => handleOpenPopoverPs(e.currentTarget, false)} onMouseLeave={handleClosePopoverPs}>
                <Grid container spacing={0}>
                    <Grid item xs={6}><WorkIcon className={classes.icon} /> <span className={classes.text}><b>{process.code}</b></span> <EditIcon className={classes.clickIcon} onClick={() => openProcessDetails(process)} /></Grid>
                    <Grid item xs={6}><SupervisedUserCircleIcon className={classes.icon} /> <span className={classes.text}><b>{process.odp.customer.name}</b></span></Grid>
                    <Grid item xs={6}><LayersIcon className={classes.icon} /> <span className={classes.text}>{process.name}</span></Grid>
                    <Grid item xs={6}><EventIcon className={classes.icon} /> {moment(process.deliveryDate).format(bishopFormats.L)}</Grid>
                    <Grid item xs={6}><AccessTimeIcon className={classes.icon} /> {rt}</Grid>
                    <Grid item xs={6}><ExtensionIcon className={classes.icon} /> <span className={classes.text}>{process.article.code}</span></Grid>
                    <Grid item xs={6}>{rst !== null ? <React.Fragment><AddAlarmIcon className={classes.icon} /> {rst}</React.Fragment> : null}</Grid>
                    <Grid item xs={6}>
                        <ProgressBarOneLine total={process !== null ? process.quantity : null}
                            current={process !== null && process.validPieces !== null ? process.validPieces :
                                process !== null && process.totalPieces !== null ? process.totalPieces - process.rejectedPieces : 0}
                            measure={null} noMarginText={true} />
                    </Grid>
                    <Grid item xs={12}></Grid>
                </Grid>
            </Paper>
        )
    }
    return (
        <Draggable key={process.id.toString()} draggableId={process.id.toString()} index={index}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    {returnOdpData(process)}
                </div>
            )}
        </Draggable>
    )
}