import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "../features/dashboard/slice";
import timelineReducer from "../features/dashboard/timeline/slice";
import odpCurrentStatusReducer from "../features/dashboard/odpCurrentStatus/slice";
import notificationsReducer from "../features/notifications/slice";
import loginReducer from "../features/login/loginSlice";
import mainReducer from "../features/main/slice";
import workcentresReducer from "../features/workcentres/slice";
import machinesReducer from "../features/machines/slice";
import usersReducer from "../features/users/slice";
import rolesReducer from "../features/roles/slice";
import bomsReducer from "../features/boms/slice";
import processTypesReducer from "../features/processTypes/slice";
import processStatusesReducer from "../features/processStatuses/slice";
import customersReducer from "../features/customers/slice";
import odpStatusesReducer from "../features/odpStatuses/slice";
import userEventsReducer from "../features/userEvents/slice";
import maintenanceTypesReducer from "../features/maintenanceTypes/slice";
import maintenancesReducer from "../features/maintenances/slice";
import machineEventsReducer from "../features/machineEvents/slice";
import odpsReducer from "../features/odps/slice";
import customerOrdersReducer from "../features/customerOrders/slice";
import operatorViewReducer from "../features/operatorView/slice";
import documentsReducer from "../features/documents/slice";
import articlesReducer from "../features/articles/slice";
import configurationReducer from "../features/configuration/slice";
import buttonsReducer from "../features/buttons/slice";
import suppliersReducer from "../features/suppliers/slice";
import manageOdpsReducer from "../features/dashboard/manageOdps/slice";
import maintenanceCurrentStatusReducer from "../features/dashboard/maintenanceCurrentStatus/slice";
import maintenerViewSelector from "../features/maintainerView/slice";
import machineViewReducer from "../features/machinesViewDetails/slice";
import smartOdpReducer from "../features/smartOdp/slice";
import measureToolsReducer from "../features/measureTools/slice";
import plannerReducer from "../features/dashboard/planner/slice";
import workcentreViewReducer from "../features/dashboard/workcentreView/slice";
import varsReducer from "../features/vars/slice";
import alertsReducer from "../features/alerts/slice";
import checksReducer from "../features/checks/slice";
import splitReducer from "../features/split/slice";

export default configureStore({
  reducer: {
    alerts: alertsReducer,
    articles: articlesReducer,
    boms: bomsReducer,
    buttons: buttonsReducer,
    checks: checksReducer,
    configuration: configurationReducer,
    customerOrders: customerOrdersReducer,
    customers: customersReducer,
    dashboard: dashboardReducer,
    documents: documentsReducer,
    login: loginReducer,
    machineEvents: machineEventsReducer,
    machineView: machineViewReducer,
    machines: machinesReducer,
    main: mainReducer,
    maintenanceCurrentStatus: maintenanceCurrentStatusReducer,
    maintenanceTypes: maintenanceTypesReducer,
    maintenances: maintenancesReducer,
    maintenerView: maintenerViewSelector,
    manageOdps: manageOdpsReducer,
    measureTools: measureToolsReducer,
    notifications: notificationsReducer,
    odpCurrentStatus: odpCurrentStatusReducer,
    odpStatuses: odpStatusesReducer,
    odps: odpsReducer,
    operatorView: operatorViewReducer,
    planner: plannerReducer,
    processStatuses: processStatusesReducer,
    processTypes: processTypesReducer,
    roles: rolesReducer,
    smartOdp: smartOdpReducer,
    split: splitReducer,
    suppliers: suppliersReducer,
    timeline: timelineReducer,
    userEvents: userEventsReducer,
    users: usersReducer,
    vars: varsReducer,
    workcentres: workcentresReducer,
    workcentreView: workcentreViewReducer,
  }
});
