import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../../utility/customLabel";
import { machinesSelector, getFullListAPI as getMachinesAPI } from "../../machines/slice";
import { getListAPI as getMachineEventsAPI } from "../../machineEvents/slice";
import MachineViewPage from "../../machinesViewDetails/MachineViewPage";
import {
  Typography, Divider, Select, MenuItem, FormControl, InputLabel
} from '@material-ui/core';
import { mesStyles } from "../../utility/ultrafabStyles";
import { resetData } from '../../machinesViewDetails/slice'
import { configurationSelector } from "../../configuration/slice";
import { machineEventsSelector } from "../../machineEvents/slice";

export default function EnhancedTable() {
  const myClasses = mesStyles();
  const dispatch = useDispatch();
  const { machines } = useSelector(machinesSelector);
  const { configuration } = useSelector(configurationSelector)
  const { machineEvents } = useSelector(machineEventsSelector)
  const [savedMachine, setSavedMachine] = React.useState(false);

  useEffect(() => {
    if (localStorage.getItem("machine") === null) {
      localStorage.setItem("machine", "")
    };
    dispatch(getMachinesAPI());
    dispatch(getMachineEventsAPI(0, 10000, "name", "asc", false));
    //salvo nello useState la macchina attualmente nel local storage
    if (localStorage["machine"])
      setSavedMachine(JSON.parse(localStorage.getItem("machine")));
  }, [dispatch]);

  const handleChange = (event) => {
    let code = event.target.value;
    let indexMachine = machines.findIndex((m) => {
      return m.code.toString() === code.toString()
    })
    if (indexMachine !== -1) {
      localStorage.setItem("machine", JSON.stringify(machines[indexMachine]))
      setSavedMachine(machines[indexMachine])
      dispatch(resetData())
    }
  };

  return (
    <React.Fragment>
      <div className={myClasses.headerSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">
          {customLabel("menuItem.machineStatuses")}
        </Typography>
      </div>
      <Divider />
      <FormControl className={myClasses.form25}>
        <InputLabel htmlFor="name">{customLabel("machine.machine")}</InputLabel>
        <Select value={savedMachine && savedMachine.code ? savedMachine.code : ''} onChange={handleChange}>
          {machines ? machines.map((m) => {
            if (!m.external) {
              return (
                <MenuItem key={m.id} value={m.code}>
                  {m.code}
                </MenuItem>
              );
            } else return null;
          }) : null}
        </Select>
      </FormControl>
      {savedMachine && machineEvents ? <MachineViewPage machine={savedMachine} configuration={configuration} index={0} /> : null}
    </React.Fragment>
  );
}