import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../constants";
import { showError, showSuccess } from "../notifications/slice";
import { logout, refreshAPI } from "../main/slice";
import _ from 'lodash';
import { customLabel } from "../utility/customLabel";
import { customFilter } from "./utility";

export const initialState = {
  loading: false,
  hasErrors: false,
  maintenanceTypes: false,
  reload: false,
  results: 0,
  editmode: false,
  maintenanceType: false,
  maintenanceTypeedit: false,
  deleting: false,
  isCreated: false,
  isDeleted: false,
  machine: false,
  filter: false
};

const maintenanceTypesSlice = createSlice({
  name: "maintenanceTypes",
  initialState,
  reducers: {
    resetState: (state) => {
      if (!state.filter) {
        Object.assign(state, initialState);
      } else {
        let tempState = {};
        let currentFilter = { ...state.filter }
        Object.assign(tempState, initialState);
        tempState.filter = { ...currentFilter }
        Object.assign(state, tempState);
      }
    },
    gettingList: (state) => {
      state.loading = true;
      state.reload = false;
      state.hasErrors = false;
    },
    getListSuccess: (state, { payload }) => {
      state.maintenanceTypes = payload[1];
      state.results = payload[0].total_entries;
      state.loading = false;
    },
    setReload: (state) => {
      state.reload = true;
    },
    reset: (state) => {
      state.maintenanceType = false;
      state.maintenanceTypeedit = false;
      state.editmode = false;
      state.isDeleted = false;
      state.isCreated = false;
    },
    getting: (state) => {
      state.maintenanceType = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.loading = true;
      state.editmode = false;
      state.hasErrors = false;
    },
    getSuccess: (state, { payload }) => {
      state.maintenanceType = payload;
      state.loading = false;
    },
    creating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    create: (state) => {
      let newmaintenanceType = { id: "new", name: '', processType: { id: "", name: "" } };
      state.maintenanceType = newmaintenanceType;
      state.maintenanceTypeedit = newmaintenanceType;
      state.editmode = true;
    },
    createSuccess: (state, { payload }) => {
      state.maintenanceType = payload;
      state.maintenanceTypeedit = false;
      state.loading = false;
      state.isCreated = true;
    },
    resetEdit: (state) => {
      state.editmode = true;
      state.maintenanceTypeedit = state.maintenanceType;
      state.loading = false;
      state.hasErrors = true;
    },
    enableErrors: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    edit: (state) => {
      state.editmode = true;
      state.maintenanceTypeedit = state.maintenanceType;
    },
    cancelEdit: (state) => {
      state.editmode = false;
      state.maintenanceTypeedit = false;
    },
    change: (state, { payload }) => {
      let u = state.maintenanceTypeedit;
      u[payload.name] = payload.value;
      state.maintenanceTypeedit = u;
    },
    updating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    updateSuccess: (state, { payload }) => {
      state.maintenanceType = payload;
      state.loading = false;
      state.editmode = false;
    },
    confirmDelete: (state) => {
      state.deleting = !state.deleting;
    },
    deleting: (state) => {
      state.deleting = false;
      state.loading = true;
      state.hasErrors = false;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.isDeleted = true;
    },
    changeProcessType: (state, { payload }) => {
      let pt = state.processType;
      pt[payload.name] = payload.value;
      state.processType = pt;
    },
    addingProcessType: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    addProcessTypeSuccess: (state, { payload }) => {
      state.loading = false;
      state.reload = true;
      state.maintenanceType = payload
    },
    sortProcessTypesSuccess: (state, { payload }) => {
      state.reload = true;
      state.loading = false;
      state.maintenanceType = payload
    },
    removingProcessType: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    removeProcessTypeSuccess: (state, { payload }) => {
      state.loading = false;
      state.reload = true;
      state.maintenanceType = payload
    },
    enableLoading: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    removingMachine: state => {
      state.loading = true
      state.hasErrors = false
    },
    removeMachineSuccess: (state, { payload }) => {
      state.loading = false
      state.maintenanceType = payload
    },
    addingMachine: state => {
      state.loading = true
      state.hasErrors = false
    },
    addMachineSuccess: (state, { payload }) => {
      state.loading = false
      state.maintenanceType = payload
    },
    selectMachine: (state, { payload }) => {
      state.machine = payload
    },
    changeMachine: (state, { payload }) => {
      if (payload.name === "var") {
        let lista = payload.vars.slice();
        let index = lista.findIndex((l) => {
          return l.id === payload.value
        });
        state.machine[payload.name] = lista[index]
      } else {
        state.machine[payload.name] = payload.value;
      }
      //se cambio tipo scadenza ripristino a null tutti i valori
      if (payload.name === "deadlineType") {
        state.machine.frequency = null;
        state.machine.workingHours = null;
        state.machine.varValue = null;
        state.machine.var = { id: null, name: null }
      }
    },
    updatingMachine: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    updateMachineSuccess: (state, { payload }) => {
      state.loading = false;
      let mt = state.maintenanceType
      let ms = _.cloneDeep(mt.machinesLinks)
      let index = ms.findIndex(v => {
        return v.id === payload.id;
      });
      ms[index] = payload
      state.maintenanceType.machinesLinks = [...ms]
      state.machine = false
    },
    changeFilter: (state, { payload }) => {
      state.filter[payload.name] = payload.value;
    },
    initFilter: (state, { payload }) => {
      let tempFilter = { ...customFilter };
      tempFilter["showFilter"] = payload;
      state.filter = { ...tempFilter };
    }
  },
});

export const { gettingList, getListSuccess, setReload, reset, getting, getSuccess, create, creating, createSuccess, edit, cancelEdit, enableErrors,
  change, updating, updateSuccess, confirmDelete, deleting, deleteSuccess, resetEdit, loadingmaintenanceTypes, loadingmaintenanceTypesSuccess,
  removingMachine, removeMachineSuccess, addingMachine, addMachineSuccess, selectMachine, changeMachine, updatingMachine, updateMachineSuccess,
  addingProcessType, addProcessTypeSuccess, enableLoading, sortProcessTypesSuccess, removingProcessType, removeProcessTypeSuccess, resetState,
  initFilter, changeFilter } = maintenanceTypesSlice.actions;

export const maintenanceTypesSelector = (state) => state.maintenanceTypes;

export default maintenanceTypesSlice.reducer;

/**
 * 
 * @param {*} page 
 * @param {*} rowsPerPage 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} name 
 * @param {*} machine 
 * @param {*} processType 
 * @param {*} extraordinary 
 * @returns 
 */
export function getListAPI(page, rowsPerPage, orderBy, order, name, machine, processType, extraordinary) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(gettingList());
    dispatch(reset());
    let url = SERVER_URL + "/api/maintenanceType?page=" + page + "&per_page=" + rowsPerPage + "&orderBy=" + orderBy + "&order=" + order;
    if (name !== false) {
      url = url + "&name=" + name;
    }
    if (machine !== false) {
      url = url + "&machine=" + machine.id;
    }
    if (processType !== false) {
      url = url + "&processType=" + processType.id;
    }
    if (extraordinary !== null) {
      url = url + "&extraordinary=" + extraordinary;
    }
    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getListSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function updateAPI(maintenanceType) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { maintenanceType: maintenanceType };
  return async (dispatch) => {
    dispatch(updating());
    const response = await fetch(
      SERVER_URL + "/api/maintenanceType/" + maintenanceType.id,
      {
        mode: "cors",
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(params),
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(resetEdit());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function createAPI(maintenanceType) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(creating());
    let params = { maintenanceType: maintenanceType };
    const response = await fetch(SERVER_URL + "/api/maintenanceType", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function getAPI(id) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(getting());
    const response = await fetch(SERVER_URL + "/api/maintenanceType/" + id, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function deleteAPI(maintenanceType) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(deleting());
    const response = await fetch(
      SERVER_URL + "/api/maintenanceType/" + maintenanceType.id,
      {
        mode: "cors",
        method: "DELETE",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(deleteSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function addProcessTypesAPI(maintenanceType, processTypes) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(addingProcessType());
    let params = { processTypesLinks: processTypes };
    const response = await fetch(
      SERVER_URL + "/api/maintenanceType/" + maintenanceType.id + "/processType",
      {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(params),
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(addProcessTypeSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function sortProcessTypesAPI(maintenanceType, processTypes) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(enableLoading());
    let params = { processTypes: processTypes };
    const response = await fetch(
      SERVER_URL + "/api/maintenanceType/" + maintenanceType.id + "/processType/sort",
      {
        mode: "cors",
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(params),
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(sortProcessTypesSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function removeProcessTypeAPI(maintenanceType, processType) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(removingProcessType());
    const response = await fetch(SERVER_URL + "/api/maintenanceType/" + maintenanceType.id + "/processType/" + processType.id,
      {
        mode: "cors",
        method: "DELETE",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(removeProcessTypeSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}


export function removeMachineAPI(maintenanceType, machine) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(removingMachine())
    const response = await fetch(SERVER_URL + '/api/maintenanceType/' + maintenanceType.id + '/machine/' + machine.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(removeMachineSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function addMachinesAPI(maintenanceType, machines, workcentres) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(addingMachine())
    let params = { machines: machines, workcentres: workcentres }
    const response = await fetch(SERVER_URL + '/api/maintenanceType/' + maintenanceType.id + '/machine', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(addMachineSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function updateMachineAPI(maintenanceType, machine) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { machine: machine };
  return async (dispatch) => {
    dispatch(updatingMachine());
    const response = await fetch(
      SERVER_URL + "/api/maintenanceType/" + maintenanceType.id + "/machine/" + machine.id,
      {
        mode: "cors",
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(params),
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateMachineSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}