import React from 'react'
import MainPage from './features/main/MainPage'
import LoginPage from './features/login/LoginPage'
import OperatorViewPage from './features/operatorView/OperatorViewPage';
import SettingsPage from './features/settings/SettingsPage';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import MaintenerViewPage from './features/maintainerView/MaintenerViewPage';
import "moment/locale/it"

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/operatorView" component={OperatorViewPage} />
        <Route path="/maintenerView" component={MaintenerViewPage} />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/" component={MainPage} />
      </Switch>
    </Router>
  )
}
export default App;
