import React from "react";
import { Droppable } from 'react-beautiful-dnd';
import { Process } from './Process';
import { Paper, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { customLabel } from "../../utility/customLabel";
const useStyles = makeStyles((theme) => ({
  break: {
    "marginBottom": theme.spacing(1),
    "marginTop": theme.spacing(1),
  },
  machineBox: {
    height: "auto",
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: "130px",
    maxWidth: "130px",
    fontSize: "120%",
    minHeight: "108px"
  },
}));

export const Machine = ({ machine, processes, openProcessDetails, handleClosePopoverPs, handleOpenPopoverPs, handleClosePopoverMe,
  handleOpenPopoverMe, editing, draggingProcess, selectOdp, selectedOdp, orphans, expiredOrphans }) => {

  const classes = useStyles();

  const getListStyle = (isDraggingOver, isAvailable) => ({
    background: isAvailable && isDraggingOver ? '#73d179' : isAvailable ? "#d1ffd4" : null,
    display: 'flex',
    overflow: 'auto',
  });
  const accept = (machine === "ORPHAN" || machine === "EXPIRED_ORPHAN") ? null : machine.processTypes.map(function (pt) { return pt.code });

  const isAvailable = (machine !== "ORPHAN" && machine !== "EXPIRED_ORPHAN") && draggingProcess && accept.indexOf(draggingProcess.type.code) > -1
  if (!machine.external) {
    return (
      <div key={machine.id}>
        <Droppable droppableId={(machine === "ORPHAN" || machine === "EXPIRED_ORPHAN") ? machine : machine.id.toString()} direction="horizontal">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver, isAvailable)} {...provided.droppableProps}>
              <Paper className={classes.machineBox} onMouseEnter={(e) => handleOpenPopoverMe(e.currentTarget, false)} onMouseLeave={handleClosePopoverMe}
                style={{ borderLeft: (machine !== "ORPHAN" && machine !== "EXPIRED_ORPHAN") && processes[machine.code] !== undefined && processes[machine.code].length > 0 && processes[machine.code][0].machineEvent.name != null ? "5px solid " + processes[machine.code][0].machineEvent.backgroundColor : "5px solid rgb(255,255,255)" }}>
                <b>{machine === "ORPHAN" ? customLabel("function.toBeAssigned") : machine === "EXPIRED_ORPHAN" ? customLabel("function.expired") : machine.code}</b>
              </Paper>
              {(machine !== "ORPHAN" && machine !== "EXPIRED_ORPHAN") && processes[machine.code] !== false && processes[machine.code] !== undefined && (editing === false || editing[machine.code] === undefined) ? (
                <React.Fragment>
                  {processes[machine.code].map((process, index) => {
                    return (
                      <Process index={index} key={process.id} process={process} handleClosePopoverPs={handleClosePopoverPs}
                        handleOpenPopoverPs={handleOpenPopoverPs} openProcessDetails={openProcessDetails} selectOdp={selectOdp} selectedOdp={selectedOdp} />)
                  })}
                </React.Fragment>
              ) : editing[machine.code] ? <CircularProgress /> : machine === "ORPHAN" && editing["ORPHAN"] ? <CircularProgress /> :
                machine === "EXPIRED_ORPHAN" && editing["EXPIRED_ORPHAN"] ? <CircularProgress /> : machine === "ORPHAN" && orphans ?
                  <React.Fragment>
                    {orphans.map((process, index) => {
                      return (
                        <Process index={index} key={process.id} process={process} handleClosePopoverPs={handleClosePopoverPs}
                          handleOpenPopoverPs={handleOpenPopoverPs} openProcessDetails={openProcessDetails} selectOdp={selectOdp} selectedOdp={selectedOdp} />)
                    })}
                  </React.Fragment>
                  : machine === "EXPIRED_ORPHAN" && expiredOrphans ?
                    <React.Fragment>
                      {expiredOrphans.map((process, index) => {
                        return (
                          <Process index={index} key={process.id} process={process} handleClosePopoverPs={handleClosePopoverPs}
                            handleOpenPopoverPs={handleOpenPopoverPs} openProcessDetails={openProcessDetails} selectOdp={selectOdp} selectedOdp={selectedOdp} />)
                      })}
                    </React.Fragment> : null}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <div className={classes.break}><hr /></div>
      </div>
    )
  }
  return null;
}
