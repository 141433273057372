import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { removeUserEventTypeAPI, addUserEventTypesAPI } from "./slice";
import { userEventsSelector, getListAPI as getUserEventTypes } from "../userEvents/slice";
import { Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import PaperAutocomplete from "../utility/PaperAutocomplete";

const UserEventTypesPage = ({ processType, loading }) => {
  const dispatch = useDispatch();
  const myClasses = mesStyles();
  const { userEvents } = useSelector(userEventsSelector);
  const [removeUserEventType, setRemoveUserEventType] = React.useState(false);
  const [linking, setLinking] = React.useState(false);
  const [wl, setWl] = React.useState([]);

  useEffect(() => {
    dispatch(getUserEventTypes(0, 100000, "name", "asc", false, false));
  }, [dispatch]);

  const onUnlick = (w) => {
    setRemoveUserEventType(w);
  };

  const cancelUnlink = () => {
    setRemoveUserEventType(false);
  };

  const doUnlink = () => {
    dispatch(removeUserEventTypeAPI(processType, removeUserEventType));
    setRemoveUserEventType(false);
  };

  const onLink = () => {
    setLinking(true);
  };

  const cancelLink = () => {
    setLinking(false);
    setWl([]);
  };

  const doLink = () => {
    dispatch(addUserEventTypesAPI(processType, wl));
    setLinking(false);
  };

  if (userEvents !== false) {
    const availableUserEventTypes = userEvents.filter((w) =>
      processType.userEventTypes.every((pw) => w.id !== pw.userEventType_id)
    );

    return (
      <PaperForm>
        <div className={myClasses.titleFormSpaceBetween}>
          <Typography component="h1" variant="h6" color="inherit">{customLabel("userEvent.userEvents")}</Typography>
        </div>
        <div className={myClasses.tableContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{customLabel("userEvent.name")}</TableCell>
                <TableCell className={myClasses.tableCell1Button} align="right">
                  <Button onClick={onLink} color="primary" disabled={loading}>{customLabel("button.link")}</Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {processType.userEventTypes.map((w) => {
                return (
                  <TableRow key={w.id}>
                    <TableCell>{w.name}</TableCell>
                    <TableCell align="right" className={myClasses.tableCell1Button}>
                      <Button onClick={() => { onUnlick(w) }} color="secondary" disabled={loading}>
                        {customLabel("button.unlink")}
                      </Button></TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Dialog open={removeUserEventType !== false} keepMounted>
            <DialogTitle>{customLabel("function.confirm").toUpperCase()}</DialogTitle>
            <DialogContent><DialogContentText>{customLabel("function.confirmUnlink")}</DialogContentText></DialogContent>
            <DialogActions>
              <Button onClick={doUnlink} disabled={loading} color="primary">{customLabel("button.confirm")}</Button>
              <Button onClick={cancelUnlink} disabled={loading} color="default">{customLabel("button.cancel")}</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={linking} keepMounted fullWidth>
            <DialogTitle>{customLabel("processType.linkUserEvents")}</DialogTitle>
            <DialogContent>
              <Autocomplete
                PaperComponent={PaperAutocomplete}
                value={wl} onChange={(event, newValue) => { setWl(newValue) }} multiple options={availableUserEventTypes}
                getOptionLabel={(option) => option.name} filterSelectedOptions
                renderInput={(params) => (<TextField {...params} label={customLabel("userEvent.userEvent")} error={wl.length === 0} />)} />
            </DialogContent>
            <DialogActions>
              <Button onClick={doLink} color="primary" disabled={wl.length === 0 || loading}>{customLabel("button.confirm")}</Button>
              <Button onClick={cancelLink} disabled={loading} color="default">{customLabel("button.close")}</Button>
            </DialogActions>
          </Dialog>
        </div>
      </PaperForm>
    );
  }
  return null;
};

export default UserEventTypesPage;
