import { Box, Button, Divider, Typography } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import _ from 'lodash';
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { articlesSelector, getListAPI as getArticlesAPI } from "../articles/slice";
import { bomsSelector, getListAPI as getBomsAPI } from "../boms/slice";
import { configurationSelector } from "../configuration/slice";
import { useBoolean } from "../customHooks/useBoolean";
import { getListAPI as getCustomerOrdersAPI, resetState } from "../customerOrders/slice";
import { customersSelector, getListAPI as getCustomersAPI } from "../customers/slice";
import { getListAPI as getStatusesAPI, odpStatusesSelector } from "../odpStatuses/slice";
import HeaderTableList from "../utility/HeaderTableList";
import TableList from "../utility/TableList";
import TableListConfig from "./TableListConfig";
import { changeFilter, getListAPI, initFilter, odpsSelector, setReload } from "./slice";

const OdpsPage = () => {
  const dispatch = useDispatch();
  const { odps, loading, results, reload, filter } = useSelector(odpsSelector);
  const { customers } = useSelector(customersSelector);
  const { articles } = useSelector(articlesSelector);
  const { odpStatuses } = useSelector(odpStatusesSelector);
  const { boms } = useSelector(bomsSelector);
  const { configuration } = useSelector(configurationSelector);
  const [firstFetchDone, { setTrue: setFirstFetchDoneTrue }] = useBoolean(false)
  const toBeProcessedList = [{ id: 0, name: customLabel("function.yes") }, { id: 1, name: customLabel("function.no") }]
  const [customize, { setTrue: setCustomizeTrue, setFalse: setCustomizeFalse }] = useBoolean(false)

  useEffect(() => {
    if (localStorage.getItem("odpsListConfig") == null) {
      localStorage.setItem("odpsListConfig", JSON.stringify(["code", "extCode", "customerOrder", "customer", "bom", "article", "deliveryDate", "startDate", "endDate"]))
    }
  })

  useEffect(() => {
    if (!filter && configuration) {
      dispatch(initFilter(configuration.showFilters))
      dispatch(resetState())
    }
  }, [configuration, filter, dispatch])

  useEffect(() => {
    if (filter && !firstFetchDone) {
      dispatch(getListAPI(filter.page, filter.rowsPerPage, filter.orderBy, filter.order, filter.customerOrder, filter.code, filter.customer, filter.bom, filter.article, filter.extCode, false, filter.deliveryDateFrom, filter.deliveryDateTo, filter.startDateFrom, filter.startDateTo, filter.endDateFrom, filter.endDateTo, filter.toBeProcessed, filter.status, filter.priority, filter.dateCreatedFrom, filter.dateCreatedTo));
      dispatch(getCustomersAPI(0, 100000, "code", "asc", false, false));
      dispatch(getBomsAPI(0, 100000, "name", "asc", false, false));
      dispatch(getCustomerOrdersAPI(0, 100000, "code", "asc", false, false, false, false, false, false));
      dispatch(getArticlesAPI(0, 100000, "code", "asc", false, false, false, false, false));
      dispatch(getStatusesAPI(0, 100000, "name", "asc", false))
      window.scrollTo(0, 0);
      setFirstFetchDoneTrue();
    }
  }, [dispatch, filter, firstFetchDone, setFirstFetchDoneTrue]);

  if (reload && !loading) {
    dispatch(getListAPI(filter.page, filter.rowsPerPage, filter.orderBy, filter.order, filter.customerOrder, filter.code, filter.customer, filter.bom, filter.article, filter.extCode, false, filter.deliveryDateFrom, filter.deliveryDateTo, filter.startDateFrom, filter.startDateTo, filter.endDateFrom, filter.endDateTo, filter.toBeProcessed, filter.status, filter.priority, filter.dateCreatedFrom, filter.dateCreatedTo));
  };

  const handleChangePage = (event, newPage, doReload) => {
    dispatch(changeFilter({ name: "page", value: newPage }))
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changeFilter({ name: "rowsPerPage", value: parseInt(event.target.value, 10) }))
    dispatch(changeFilter({ name: "page", value: 0 }))
  };

  const onRequestSort = (property) => {
    const isAsc = filter.orderBy === property && filter.order === "asc"
    let newOrder = isAsc ? "desc" : "asc";
    dispatch(changeFilter({ name: "order", value: newOrder }))
    dispatch(changeFilter({ name: "orderBy", value: property }))
  };

  const changeFilters = (name, value) => {
    dispatch(changeFilter({ name: name, value: value }))
  };

  const resetFilter = () => {
    dispatch(initFilter(true));
  };

  const headers = {
    code: { filter: true, type: "string" },
    extCode: { filter: true, type: "string" },
    customerOrder: { filter: true, type: "object" },
    customer: { filter: true, type: "object", properties: ["code", "name"] },
    bom: { filter: true, type: "object" },
    article: { filter: true, type: "object", properties: ["code", "name", "extCode"] },
    status: { filter: true, type: "object", multiple: true },
    toBeProcessed: { filter: true, type: "booleanString" },
    priority: { filter: true, type: "string" },
    note: { filter: false, type: "string" },
    note2: { filter: false, type: "string" },
    note3: { filter: false, type: "string" },
    quantity: { filter: false, type: "numeric" },
    deliveryDate: {
      filter: true, type: configuration && configuration.showOdpDeliveryTime ? "datetime" : "date", name: "deliveryDate"
    },
    startDate: { filter: true, type: "datetime", name: "startDate" },
    endDate: { filter: true, type: "datetime", name: "endDate" },
    dateCreated: { filter: true, type: "datetime", name: "dateCreated" }
  };

  const renderHeaders = () => {
    const config = JSON.parse(localStorage.getItem("odpsListConfig"))
    const result = {}
    config.forEach(x => {
      if (x.includes(".")) {
        let splitted = x.split(".")
        let v = _.cloneDeep(headers[splitted[0]])
        v["val"] = splitted[1]
        result[x] = v
      } else {
        result[x] = headers[x]
      }
    })
    return result
  }

  const toggleFilter = () => {
    if (!filter.showFilter) {
      dispatch(initFilter(true));
    } else {
      dispatch(initFilter(false));
    }
  }

  const applyReload = useCallback(() => {
    dispatch(setReload());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Typography component="h1" variant="h6" color="inherit">
        {customLabel("odp.odps")}
      </Typography>
      <Button onClick={() => setCustomizeTrue()} color="default">{customLabel("odp.customize")}</Button>
      <HeaderTableList filter={filter} toggleFilter={toggleFilter} showAdd={false} businessObject={"odps"} />
      <Divider />
      <Box>
        {odps && filter ? (
          <TableList items={odps} headers={renderHeaders()} mainObject={"odp."} redirectObject={"odps"}
            setPage={handleChangePage} setRowsPerPage={handleChangeRowsPerPage}
            results={results} onRequestSort={onRequestSort}
            articleList={articles} customerList={customers} customerOrderList={false}
            bomList={boms} codeList={false} priorityList={false} extCodeList={false} changeFilters={changeFilters}
            parentList={false} rootList={false} statusList={odpStatuses} toBeProcessedList={toBeProcessedList}
            resetFilter={resetFilter} filter={filter} applyReload={applyReload} />
        ) : null}
        {loading ? <div>{customLabel("function.loading").toUpperCase()}</div> : null}
      </Box>
      <TableListConfig open={customize} close={setCustomizeFalse} headers={headers} item={"odpsListConfig"} mainObject={"odp."} />
    </React.Fragment>
  );
};

export default OdpsPage;
