import React from 'react';
import { customLabel } from "../utility/customLabel";

const PageNotFound = () => {
  return (
    <h1>
      {customLabel("function.pageNotFound")}
    </h1>
  )
}

export default PageNotFound
