import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  hasErrors: false,
  current: window.location.pathname,
  navigation: [],
  currentNavigation: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    doChange: (state, { payload }) => {
      state.current = payload;
    },
    navigate: (state, { payload }) => {
      state.currentNavigation = payload
    },
    addNavigation: (state, { payload }) => {
      state.navigation.slice()
      let o = { "url": payload.url, "name": payload.name }
      state.navigation.push(o)
      if (state.navigation.length > 10) {
        state.navigation.shift()
      }
      state.currentNavigation = { n: o, i: state.navigation.length - 1 }
    }
  },
});

export const { doChange, addNavigation, navigate } = dashboardSlice.actions;

export const selector = (state) => state.dashboard;

export default dashboardSlice.reducer;