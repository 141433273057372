import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { processTypesSelector, removeRoleAPI, addRolesAPI, updateRoleAPI, selectRole, changeRole } from "./slice";
import { rolesSelector, getListAPI as getRoleTypes } from "../roles/slice";
import { Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, FormControl, FormControlLabel, Checkbox, Input, InputLabel } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CheckIcon from '@material-ui/icons/Check';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogForm from "../utility/DialogForm";
import PaperAutocomplete from "../utility/PaperAutocomplete";

const RoleTypesPage = ({ processType, loading }) => {
  const dispatch = useDispatch();
  const myClasses = mesStyles();
  const { roles } = useSelector(rolesSelector);
  const { processRole } = useSelector(processTypesSelector)
  const [removeRole, setRemoveRole] = React.useState(false);
  const [linking, setLinking] = React.useState(false);
  const [wl, setWl] = React.useState([]);

  useEffect(() => {
    dispatch(getRoleTypes(0, 100000, "authority", "asc", false));
  }, [dispatch]);

  const onUnlick = (w) => {
    setRemoveRole(w);
  };

  const cancelUnlink = () => {
    setRemoveRole(false);
  };

  const doUnlink = () => {
    dispatch(removeRoleAPI(processType, removeRole));
    setRemoveRole(false);
  };

  const onLink = () => {
    setLinking(true);
  };

  const cancelLink = () => {
    setLinking(false);
    setWl([]);
  };

  const doLink = () => {
    dispatch(addRolesAPI(processType, wl));
    setLinking(false);
  };

  if (roles !== false) {
    const availableRoleTypes = roles.filter((w) =>
      processType.roles.every((pw) => w.id !== pw.role_id)
    );

    const onUpdateClick = (processRole) => {
      dispatch(selectRole(processRole))
    }

    const onChange = (e) => {
      let name = e.target.name
      let value = e.target.checked
      dispatch(changeRole({ name: name, value: value }))
    }

    const save = () => {
      dispatch(updateRoleAPI(processType, processRole))
    }

    const cancelEdit = () => {
      dispatch(selectRole(false))
    }

    const renderRoleForm = () => {
      return (
        <form className={myClasses.dialogForm} noValidate autoComplete="off">
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="name">{customLabel("role.authority")}</InputLabel>
            <Input name="name" value={processRole.authority} disabled />
          </FormControl>
          <FormControl margin="normal">
            <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="unmountProcess" onChange={onChange} checked={processRole.unmountProcess} />}
              label={customLabel("role.unmountProcess")} />
          </FormControl>
        </form>
      )
    }

    return (
      <PaperForm>
        <div className={myClasses.titleFormSpaceBetween}>
          <Typography component="h1" variant="h6" color="inherit">{customLabel("role.roles")}</Typography>
        </div>
        <div className={myClasses.tableContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{customLabel("role.authority")}</TableCell>
                <TableCell>{customLabel("role.unmountProcess")}</TableCell>
                <TableCell className={myClasses.tableCell1Button} align="right">
                  <Button onClick={onLink} disabled={loading} color="primary">{customLabel("button.link")}</Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {processType.roles.map((w) => {
                return (
                  <TableRow key={w.id}>
                    <TableCell>{w.authority}</TableCell>
                    <TableCell>{w.unmountProcess ? <CheckIcon /> : null}</TableCell>
                    <TableCell align="right" className={myClasses.tableCell2Buttons}>
                      <Button disabled={loading} onClick={() => { onUpdateClick(w) }} color="primary">
                        {customLabel("button.update")}
                      </Button>
                      <Button disabled={loading} onClick={() => { onUnlick(w) }} color="secondary">
                        {customLabel("button.unlink")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Dialog open={removeRole !== false} keepMounted>
            <DialogTitle>{customLabel("function.confirm").toUpperCase()}</DialogTitle>
            <DialogContent><DialogContentText>{customLabel("function.confirmUnlink")}</DialogContentText></DialogContent>
            <DialogActions>
              <Button onClick={doUnlink} disabled={loading} color="primary">{customLabel("button.confirm")}</Button>
              <Button onClick={cancelUnlink} disabled={loading} color="default">{customLabel("button.cancel")}</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={linking} keepMounted fullWidth>
            <DialogTitle>{customLabel("processType.linkRoles")}</DialogTitle>
            <DialogContent>
              <Autocomplete
                PaperComponent={PaperAutocomplete}
                value={wl} onChange={(event, newValue) => { setWl(newValue) }} multiple options={availableRoleTypes}
                getOptionLabel={(option) => option.authority} filterSelectedOptions
                renderInput={(params) => (<TextField {...params} label={customLabel("role.role")} error={wl.length === 0} />)} />
            </DialogContent>
            <DialogActions>
              <Button onClick={doLink} color="primary" disabled={wl.length === 0 || loading}>{customLabel("button.confirm")}</Button>
              <Button onClick={cancelLink} disabled={loading} color="default">{customLabel("button.close")}</Button>
            </DialogActions>
          </Dialog>

          {processRole !== false ? (
            <DialogForm open={processRole !== false} title={customLabel("role.role")}
              closeDisabled={loading} saveDisabled={loading} close={cancelEdit}
              form={renderRoleForm} save={save} />
          ) : null}
        </div>
      </PaperForm>
    );
  }
  return null;
};

export default RoleTypesPage;
