import React from "react";
import { Autocomplete } from "@material-ui/lab"
import { customLabel } from "../utility/customLabel";
import { Button, Divider, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, Table, TableHead, TableRow, TableBody, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PaperAutocomplete from "../utility/PaperAutocomplete";

const useStyles = makeStyles((theme) => ({
    buttonNM: {
        padding: theme.spacing(),
        fontSize: "15pt !important"
    },
    form: {
        width: "100%"
    },
    title: {
        padding: theme.spacing()
    }
}));
export const Checks = ({ check, odp, onAddCheck, selectCheck, cancelAddCheck, save, onChange, onChangeAutocompleteCheck, onChangeInputCheck, checkDefs }) => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Divider />
            <h2 className={classes.title}>{customLabel("maintenance.checks")}</h2>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{customLabel("processType.name")}</TableCell>
                        <TableCell>{customLabel("process.note")}</TableCell>
                        <TableCell align="right"><Button onClick={onAddCheck} className={classes.buttonNM} variant="contained" color="primary">{customLabel("button.add")}</Button></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {odp.closeChecks.map((c) => {
                        return (
                            <TableRow key={c.id}>
                                <TableCell>{c.name}</TableCell>
                                <TableCell>{c.notes}</TableCell>
                                <TableCell align="right"><Button onClick={() => selectCheck(c)} className={classes.buttonNM} variant="contained" color="default">{customLabel("button.details")}</Button></TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            {check !== false ? (
                <Dialog open={check !== false} keepMounted maxWidth={"sm"} fullWidth={true}>
                    <DialogTitle>{customLabel("maintenancePhase.check")}</DialogTitle>
                    <DialogContent>
                        <form className={classes.root} noValidate autoComplete="off">
                            {checkDefs ?
                                <Autocomplete
                                    PaperComponent={PaperAutocomplete}
                                    freeSolo
                                    disableClearable
                                    value={check.name}
                                    onInputChange={onChangeAutocompleteCheck}
                                    options={checkDefs.map((option) => option.name)}
                                    renderInput={(params) => (<TextField {...params} label={customLabel("maintenancePhase.check")} InputProps={{ ...params.InputProps, type: 'search' }} />)}
                                />
                                : null}
                            <br />
                            <br />
                            <FormControl className={classes.form}>
                                <TextField
                                    variant="outlined" name="notes" label={customLabel("function.note")} multiline rows={4} value={check.notes === null ? "" : check.notes}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={save} color="primary" disabled={check.name === ""} >{customLabel("button.save")}</Button>
                        <Button onClick={cancelAddCheck} color="default">{customLabel("button.close")}</Button>
                    </DialogActions>
                </Dialog>
            ) : null}
        </React.Fragment>
    )
}