import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Paper, Box } from '@material-ui/core/';

export const layout = {
    header: {
        fontSize: "1.5em",
        color: "blue"
    },
    footer: {
        fontSize: "0.8em"
    },
    legendSquare: {
        width: "30px",
        height: "15px",
        border: "solid 1px black",
        marginTop: "auto"
    }
};

export const operatorStyles = makeStyles((theme) => ({

}));

export const mesStyles = makeStyles((theme) => ({
    legendRectangle: {
        width: "30px",
        height: "15px",
        border: "solid 1px black",
        margin: "auto 0",
        marginLeft: "5px"
    },
    paper: {
        overflowX: "auto",
        minHeight: "480px",
        maxHeight: "480px",
    },
    processStatusLegendDiv: {
        display: "flex",
        justifyContent: "flex-end",
    },
    smallTabs: {
        height: "32px !important",
        minHeight: "32px !important"
    },
    smallTab: {
        height: "32px !important",
        minHeight: "32px !important"
    },
    titleFormSpaceBetween: {
        display: "flex",
        justifyContent: "space-between",
        margin: theme.spacing(2),
    },
    subtitle: {
        display: "flex",
        justifyContent: "flex-start",
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    headerSpaceBetween: {
        display: "flex",
        justifyContent: "space-between",
    },
    buttonsFormUpdate: {
        textAlign: "right",
        margin: "0 auto"
    },
    buttonsSmartProgress: {
        textAlign: "right",
        margin: "0 auto"
    },
    formContainer: {
        '& > *': {
            margin: theme.spacing(2) + 'px !important',
        },
        "& .Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
        }
    },
    dialogFormCascade: {
        '& > *': {
            margin: theme.spacing(2) + 'px !important',
        },
        "& .Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
        },
    },
    tableContainer: {
        margin: theme.spacing(2),
    },
    paperContainer: {
        margin: theme.spacing(2),
    },
    form25: {
        width: "calc(25% - 32px)",
    },
    form50: {
        width: "calc(50% - 32px)",
    },
    form100: {
        width: "calc(100% - 32px)",
    },
    dialogForm: {
        //per ora niente
    },
    dialogForm400: {
        minHeight: "400px",
        maxHeight: "400px"
    },
    dialogForm500: {
        minHeight: "500px",
        maxHeight: "500px"
    },
    backgroundColor: {
        height: 25,
        width: 25,
        borderRadius: 5,
        padding: theme.spacing(1),
    },
    customTableHeadRow: {
        fontWeight: "bold",
        borderBottomWidth: "2px",
        borderTopWidth: "0px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderColor: "black",
        borderStyle: "solid",
    },
    dividerMarginVert1: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing()
    },
    dividerMarginVert2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    listItemProcess: {
        borderBottom: "1px solid #000000de",
    },
    listItemProcessSquare: {
        marginRight: theme.spacing(2),
        border: "1px solid grey",
        padding: theme.spacing(1)
    },
    listProcessContainer: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    alarmFont: {
        color: theme.palette.secondary.main,
        fontWeight: "bold"
    },
    divSmartCreation: {
        margin: theme.spacing(2)
    },
    formLabelBlock: {
        display: "block"
    },
    hiddenForm: {
        display: "none !important"
    },
    confDetailsPaper: {
        overflowX: "auto",
        minHeight: "540px",
        maxHeight: "540px",
        position: "relative"
    },
    confDetailsBox: {
        minHeight: "500px",
        maxHeight: "500px",
        overflow: "auto"
    },
    processDetailsPaper: {
        overflow: "hidden",
        minHeight: 'calc(75vh - 38px)',
        height: 'auto',
    },
    progressOdpPaper: {
        overflowX: "auto",
        minHeight: "198px",
        maxHeight: "198px"
    },
    odpDetailsPaperMD: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
        minHeight: '75vh',
        height: 'auto',
    },
    odpDetailsContainerMD: {
        height: 'auto'
    },
    odpDetailsContainerLG: {
        height: 'auto'
    },
    odpDetailsPaperLG: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
        minHeight: '75vh',
        height: 'auto',
    },
    buttonCell120: {
        width: 120
    },
    buttonsFlexRight: {
        display: "flex",
        justifyContent: "flex-end"
    },
    divFlexRight: {
        display: "flex",
        justifyContent: "center",
    },
    buttonsFlexBetween: {
        display: "flex",
        justifyContent: "space-between"
    },
    datePickerTop2: {
        marginTop: theme.spacing(2),
        width: "100%"
    },
    buttonsDivBottom: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        width: "100%"
    },
    insertFileDiv: {
        marginTop: "15px",
        textAlign: "center",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    tableListOneButton: {
        maxHeight: "550px"
    },
    dateTimePickerTable: {
        maxWidth: "130px",
    },
    datePickerTable: {
        minWidth: "110px",
        maxWidth: "110px"
    },
    datePickerTableLabel: {
        minWidth: "155px",
        maxWidth: "155px"
    },
    tableCell2Buttons: {
        width: "220px",
    },
    tCell2Dates: {
        width: "390px",
    },
    tableCell1Button: {
        width: "120px"
    },
    tableCell1LongButton: {
        width: "220px"
    },
    operatorButton: {
        margin: "5px",
        padding: "15px",
    },
    docImageContainer: {
        width: "100%",
        margin: 'auto',
        textAlign: "center"
    },
    docImageMedia: {
        maxHeight: '100%',
        maxWidth: '100%',
    },
    docPdfContainer: {
        display: "flex",
        justifyContent: "center",
    },
    tableCellArticle: {
        verticalAlign: 'top',
        borderBottom: "1px solid rgba(146, 146, 146, 1)"
    },
    accordionSummary: {
        borderRadius: "3px",
        border: "1px solid rgba(0, 0, 0, 0.3)"
    },
    accordionDetails: {
        display: "block"
    },
    tableCellEventPlusColor: {
        display: "flex",
        justifyContent: "space-between"
    },
    titleTableTypography: {
        margin: theme.spacing(1),
        textAlign: "center"
    },
    operatorAlert: {
        paddingTop: 0,
        paddingBottom: 0,
        margin: theme.spacing() / 2,
        wordBreak: "break-word"
    },
    staplingButtonDiv: {
        color: theme.palette.secondary.main,
        fontWeight: "bold",
        textAlign: "center",
        margin: theme.spacing(0)
    },
    alertMessage: {
        color: theme.palette.secondary.main,
        fontWeight: "bold",
    },
    tabelCellBotMargin: {
        borderBottom: "1px solid rgba(0,0,0)",
        borderTop: "1px solid rgba(0,0,0)"
    },
    tableCellColorBorder: {
        borderLeftWidth: "10px",
        borderLeftStyle: "solid",
    },
    cursorPointer: {
        cursor: "pointer"
    },
    //non usato
    placeHolderAuto: {
        "& input::label": {
            fontSize: "18px"
        }
    },
    placeholderLinearProgress: {
        height: theme.spacing(),
    },
    headerTimeline: {
        position: "sticky",
        top: 0,
        zIndex: theme.zIndex.drawer
    },
    divTimeline: {
        overflowX: "auto",
        maxHeight: "700px",
        position: "relative"
    },
    dividerTimeline: {
        height: theme.spacing(2),
    },
    dashedUnderline: {
        textDecoration: 'underline',
        textDecorationStyle: 'dashed',
    },
    noBorderB: {
        borderBottom: "none",
    },
    dragDropContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        maxWidth: 600,
    },
    labelDragDrop: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '12px',
        cursor: "default",
    },
    backgroundGray: {
        background: theme.palette.action.selected,
    }
}));

export const PaperForm = withStyles((theme) => ({
    root: {
        margin: theme.spacing() / 2,
        padding: theme.spacing() / 2,
        overflowX: "auto"
    }
}))(Paper);

export const BoxForm = withStyles((theme) => ({
    root: {
        margin: theme.spacing() / 2,
    }
}))(Box);

