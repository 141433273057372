import React from 'react'
import { useDispatch } from "react-redux";
import { customLabel } from "../utility/customLabel";
import {
  Table, TableCell, TableRow, Button, Input, TableBody, TableHead
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { changeAdminVar } from "./slice";

const useStyles = makeStyles((theme) => ({
  varButtons: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

const FormProcessVars = ({ adminVars, loading, waitForLoadingObjects }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChangeVarText = (event, tempId) => {
    dispatch(changeAdminVar({ value: event.target.value, tempId: tempId }));
  };
  //viene valorizzato lo stato currentVar che rappresenta il valore della variabile di tipo boolean
  const handleChangeVarBool = (event, value, tempId) => {
    dispatch(changeAdminVar({ value: value, tempId }));
  };

  const renderVars = () => {
    return (
      adminVars.map((v) => {
        if (v.type === "string") {
          return (
            <TableRow key={v.defId + "_" + v.serialNo + "_" + v.sortOrderProcess}>
              <TableCell>{v.process}</TableCell>
              <TableCell>{v.name}</TableCell>
              <TableCell>{v.serial ? v.serialNo : null}</TableCell>
              <TableCell>
                <Input value={v.value ? v.value : ""} onChange={(e) => handleChangeVarText(e, v.tempId)}
                  margin="dense" fullWidth name="operator" disabled={loading || waitForLoadingObjects}></Input>
              </TableCell>
            </TableRow>
          )
        } else if (v.type === "boolean") {
          return (
            <TableRow key={v.defId + "_" + v.serialNo + "_" + v.sortOrderProcess}>
              <TableCell>{v.process}</TableCell>
              <TableCell>{v.name}</TableCell>
              <TableCell>{v.serial ? v.serialNo : null}</TableCell>
              <TableCell>
                <div className={classes.varButtons}>
                  <Button color="primary" onClick={(e) => handleChangeVarBool(e, true, v.tempId)}
                    variant={v.value === true ? "contained" : "outlined"} disabled={loading || waitForLoadingObjects}>{customLabel("function.true")}
                  </Button>
                  <Button color="secondary" onClick={(e) => handleChangeVarBool(e, false, v.tempId)}
                    variant={v.value === false ? "contained" : "outlined"} disabled={loading || waitForLoadingObjects}>{customLabel("function.false")}
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          )
        } else if (v.type === "number") {
          return (
            <TableRow key={v.defId + "_" + v.serialNo + "_" + v.sortOrderProcess}>
              <TableCell>{v.process}</TableCell>
              <TableCell>{v.name}</TableCell>
              <TableCell>{v.serial ? v.serialNo : null}</TableCell>
              <TableCell>
                <Input value={v.value ? v.value : ""} onChange={(e) => handleChangeVarText(e, v.tempId)}
                  margin="dense" fullWidth name="operator" type="number" disabled={loading || waitForLoadingObjects}></Input>
              </TableCell>
            </TableRow>
          )
        } else {
          return null;
        }
      })
    )
  }
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{customLabel("processType.processType").toUpperCase()}</TableCell>
          <TableCell>{customLabel("processType.var.var").toUpperCase()}</TableCell>
          <TableCell>{customLabel("processType.serial").toUpperCase()}</TableCell>
          <TableCell>{customLabel("function.value").toUpperCase()}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {renderVars()}
      </TableBody>
    </Table>
  )
}

export default FormProcessVars;
