import React from 'react'
import { useSelector } from "react-redux";
import {
    TableBody, TableCell, TableRow, TableHead, Table
} from '@material-ui/core';
import { customLabel } from '../../utility/customLabel';
import { operatorViewSelector } from "../slice";

const OeeDetails = () => {
    const { oee, oee_efficiency, oee_quality, oee_availability, } = useSelector(operatorViewSelector);

    return (
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{customLabel("function.datum")}</TableCell>
                        <TableCell align="right">{customLabel("function.value")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            {customLabel("operatorView.availability")}
                        </TableCell>
                        <TableCell align="right">
                            {oee_availability ? (oee_availability.availability * 100).toFixed(2) + "%" : null}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell >
                            {customLabel("operatorView.quality")}
                        </TableCell>
                        <TableCell align="right">
                            {oee_quality ? (oee_quality.quality * 100).toFixed(2) + "%" : null}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {customLabel("operatorView.efficiency")}
                        </TableCell>
                        <TableCell align="right">
                            {oee_efficiency ? (oee_efficiency.efficiency * 100).toFixed(2) + "%" : null}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {customLabel("operatorView.oee")}
                        </TableCell>
                        <TableCell align="right">
                            {oee ? (oee.oee * 100).toFixed(2) + "%" : null}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </React.Fragment>
    )
}

export default OeeDetails
