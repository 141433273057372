import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../constants";
import { logout, refreshAPI } from "../main/slice";
import { showError, showSuccess } from "../notifications/slice";
import { customLabel } from "../utility/customLabel";
import { customFilter } from "./utility";

export const initialState = {
  loading: false,
  hasErrors: false,
  machineEvents: false,
  reload: false,
  results: 0,
  editmode: false,
  machineEvent: false,
  machineEventedit: false,
  callback: false,
  deleting: false,
  isCreated: false,
  isDeleted: false,
  filter: false
};

const machineEventsSlice = createSlice({
  name: "machineEvents",
  initialState,
  reducers: {
    resetState: (state) => {
      if (!state.filter) {
        Object.assign(state, initialState);
      } else {
        let tempState = {};
        let currentFilter = { ...state.filter }
        Object.assign(tempState, initialState);
        tempState.filter = { ...currentFilter }
        Object.assign(state, tempState);
      }
    },
    gettingList: (state) => {
      state.loading = true;
      state.reload = false;
      state.hasErrors = false;
    },
    getListSuccess: (state, { payload }) => {
      state.machineEvents = payload[1];
      state.results = payload[0].total_entries;
      state.loading = false;
      state.hasErrors = false;
    },
    setReload: (state) => {
      state.reload = true;
    },
    reset: (state) => {
      state.machineEvent = false;
      state.machineEventedit = false;
      state.editmode = false;
      state.isDeleted = false;
      state.isCreated = false;
    },
    getting: (state) => {
      state.machineEvent = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.loading = true;
      state.editmode = false;
      state.reload = false;
      state.hasErrors = false;
    },
    getSuccess: (state, { payload }) => {
      state.machineEvent = payload;
      state.loading = false;
    },
    creating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    create: (state) => {
      let newmachineEvent = {
        id: "new",
        name: "",
        systemEvent: false,
        odpStatus: false,
        processStatus: false,
        backgroundColor: false,
        disactiveBackgroundColor: false,
        excludeFromKPI: false,
        productive: false,
        setup: false
      };
      state.machineEvent = newmachineEvent;
      state.machineEventedit = newmachineEvent;
      state.editmode = true;
    },
    createSuccess: (state, { payload }) => {
      state.machineEvent = payload;
      state.machineEventedit = false;
      state.loading = false;
      state.isCreated = true;
    },
    resetEdit: (state) => {
      state.editmode = true;
      state.machineEventedit = state.machineEvent;
      state.loading = false;
      state.hasErrors = true;
    },
    edit: (state) => {
      state.editmode = true;
      state.machineEventedit = state.machineEvent;
    },
    cancelEdit: (state) => {
      state.editmode = false;
      state.machineEventedit = false;
    },
    change: (state, { payload }) => {
      let u = state.machineEventedit;

      if (payload.name === "odpStatus.id") {
        let lista = payload.odpStatuses.slice();
        let indexOfOdp = lista.findIndex((l) => {
          return l.id === payload.value;
        });
        u["odpStatus"] = lista[indexOfOdp];
      } else if (payload.name === "processStatus.id") {
        let lista = payload.processStatuses.slice();
        let indexOfProcess = lista.findIndex((l) => {
          return l.id === payload.value;
        });
        u["processStatus"] = lista[indexOfProcess];
      } else {
        u[payload.name] = payload.value;
      }

      state.machineEventedit = u;
    },

    confirmDelete: (state) => {
      state.deleting = !state.deleting;
    },
    updateSuccess: (state, { payload }) => {
      state.machineEvent = payload;
      state.loading = false;
      state.editmode = false;
    },
    deleting: (state) => {
      state.deleting = false;
      state.loading = true;
      state.hasErrors = false;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.isDeleted = true;
    },
    deletingCallback: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    deleteCallbackSuccess: (state) => {
      state.loading = false;
      state.reload = true;
    },
    creatingCallback: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    createCallbackSuccess: (state) => {
      state.loading = false;
      state.callback = false;
      state.reload = true;
    },
    updating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    updateCallbackSuccess: (state) => {
      state.loading = false;
      state.callback = false;
      state.reload = true;
    },
    newCallback: (state) => {
      state.callback = {
        generatorActionType: "",
        actionType: "",
        action: { id: "" },
        emit: false,
        id: "new"
      };
    },
    selectCallback: (state, action) => {
      state.callback = action.payload;
    },
    resetCallback: state => {
      state.callback = false;
    },
    changeCallback: (state, { payload }) => {
      let c = state.callback;
      if (payload.name === "action.id") {
        let lista = payload.machineEvents.slice();

        let index = lista.findIndex((l) => {
          return l.id.toString() === payload.value;
        });
        c["action"] = lista[index];
      } else {
        c[payload.name] = payload.value;
        if (payload.value === "start") {
          c["actionType"] = "close"
        } else {
          c["actionType"] = "open"
        }
      }
      state.callback = c;
    },
    enableErrors: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    removingMachine: state => {
      state.loading = true;
      state.hasErrors = false;
    },
    removeMachineSuccess: state => {
      state.loading = false;
      state.reload = true;
    },
    removingReason: state => {
      state.loading = true;
      state.hasErrors = false;
    },
    removeReasonSuccess: state => {
      state.loading = false;
      state.reload = true;
    },
    addingMachine: state => {
      state.loading = true;
      state.hasErrors = false;
    },
    addMachineSuccess: state => {
      state.loading = false;
      state.reload = true;
    },
    addingReason: state => {
      state.loading = true;
      state.hasErrors = false;
    },
    addReasonSuccess: state => {
      state.loading = false;
      state.reload = true;
    },
    changeFilter: (state, { payload }) => {
      state.filter[payload.name] = payload.value;
    },
    /**
     * 
     * @param {*} state 
     * @param {boolean} payload showFilter 
     */
    initFilter: (state, { payload }) => {
      let tempFilter = { ...customFilter };
      tempFilter["showFilter"] = payload;
      state.filter = { ...tempFilter };
    }
  },
});

export const { resetState, gettingList, getListSuccess, setReload, getting, reset, getSuccess, create, creating, createSuccess, edit, cancelEdit, change, updating,
  updateSuccess, confirmDelete, deleting, deleteSuccess, resetEdit, loadingmachineEvents, enableErrors,
  loadingmachineEventsSuccess, newCallback, creatingCallback, createCallbackSuccess, changeCallback, removeReasonSuccess, removingReason,
  selectCallback, deletingCallback, deleteCallbackSuccess, updateCallbackSuccess, removeMachineSuccess, removingMachine, addMachineSuccess, addingMachine, addReasonSuccess, addingReason,
  resetCallback, changeFilter, initFilter } = machineEventsSlice.actions;

export const machineEventsSelector = (state) => state.machineEvents;

export default machineEventsSlice.reducer;

export function getListAPI(page, rowsPerPage, orderBy, order, name) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(gettingList());
    dispatch(reset())
    let url = SERVER_URL + "/machineEventType?page=" + page + "&per_page=" + rowsPerPage + "&orderBy=" +
      orderBy + "&order=" + order;

    if (name !== false) {
      url = url + "&name=" + name;
    }
    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getListSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function updateAPI(machineEvent) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { machineEventType: machineEvent };
  return async (dispatch) => {
    dispatch(updating());
    const response = await fetch(
      SERVER_URL + "/api/machineEventType/" + machineEvent.id,
      {
        mode: "cors",
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(params),
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(resetEdit());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function createAPI(machineEvent) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(creating());
    let params = { machineEventType: machineEvent };
    const response = await fetch(SERVER_URL + "/api/machineEventType", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function getAPI(id) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(getting());
    const response = await fetch(SERVER_URL + "/api/machineEventType/" + id, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function deleteAPI(machineEvent) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(deleting());
    const response = await fetch(
      SERVER_URL + "/api/machineEventType/" + machineEvent.id,
      {
        mode: "cors",
        method: "DELETE",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(deleteSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function createCallbackAPI(callback, id) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(creatingCallback());
    let params = { callback: callback };
    const response = await fetch(
      SERVER_URL + "/api/machineEventType/" + id + "/callback",
      {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(params),
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createCallbackSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function deleteCallbackAPI(callback, id) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(deletingCallback());
    const response = await fetch(
      SERVER_URL + "/api/machineEventType/" + id + "/callback/" + callback.id,
      {
        mode: "cors",
        method: "DELETE",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(deleteCallbackSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function updateCallbackAPI(callback, id) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { callback: callback };
  return async (dispatch) => {
    dispatch(updating());
    const response = await fetch(
      SERVER_URL + "/api/machineEventType/" + id + "/callback/" + callback.id,
      {
        mode: "cors",
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(params),
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateCallbackSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function removeMachineAPI(machineEventType, machine) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(removingMachine())
    const response = await fetch(SERVER_URL + '/api/machineEventType/' + machineEventType.id + '/machine/' + machine.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(removeMachineSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}
export function removeReasonAPI(machineEventType, reason) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(removingMachine())
    const response = await fetch(SERVER_URL + '/api/machineEventType/' + machineEventType.id + '/reason/' + reason.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(removeReasonSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function addMachinesAPI(machineEventType, machines) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(addingMachine())
    let params = { machines: machines }
    const response = await fetch(SERVER_URL + '/api/machineEventType/' + machineEventType.id + '/machine', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(addMachineSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}
export function addReasonsAPI(machineEventType, reasons) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(addingReason())
    let params = { reasons: reasons }
    const response = await fetch(SERVER_URL + '/api/machineEventType/' + machineEventType.id + '/reason', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(addReasonSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}