import React from 'react'
import { Table, TableRow, TableCell, Grid, Divider } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { bishopFormats } from '../../constants';
import { setColor } from "../utility/vari";
import BarChart from './utility/BarChart';

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        textAlign: "right",
    },
    pieHeader: {
        textAlign: "center",
        marginTop: theme.spacing(1),
    },
    container: {
        width: "100%"
    },
    verticalBarHeader: {
        textAlign: "center",
        marginTop: theme.spacing(1),
    },
}));

const NewProductionData = ({ events, colors, totEvents, kpiEvents, totEventsKpi, startDate, endDate, status, stapling, barEvents, barKpiEvents, maintenance, stats, dailyProd, crossingTime }) => {
    const classes = useStyles();

    /*     if (reload && !loading && !reloading) {
            dispatch(reloadingOdpAPI(odp));
        }; */

    const getColor = bar => {
        return colors[bar.id]
    }

    const secondsToHms = (seconds) => {
        seconds = Number(seconds);
        //var d = Math.floor(seconds / (3600 * 24));
        var h = Math.floor(seconds / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 60);

        //var dDisplay = d > 0 ? d + " gg " : "";
        var hDisplay = h > 0 ? h + " h " : "";
        var mDisplay = m > 0 ? m + " m " : "";
        var sDisplay = s > 0 ? s + " s" : "";
        return hDisplay + mDisplay + sDisplay;
    };

    const getPercColor = (e) => {
        if (e && e.color) {
            return (
                setColor(e.color)
            )
        } else {
            return "black"
        }
    };

    return (
        <div className={classes.container}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={!stats ? 12 : 6} xl={!stats ? 12 : 6}>
                    <Table size="small">
                        <tbody>
                            <TableRow>
                                <TableCell colSpan={2} align="center">
                                    <b>{customLabel("odp.productionData")}</b>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{customLabel("odp.startDate")}</TableCell>
                                <TableCell align='right'>{startDate !== null ? moment(startDate).format(bishopFormats.LTS) : null}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{customLabel("odp.endDate")}</TableCell>
                                <TableCell align='right'>{endDate !== null ? moment(endDate).format(bishopFormats.LTS) : null}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{customLabel(maintenance ? "maintenance.status" : stapling ? "stapling.staplingStatus" : "odpStatus.odpStatus")}</TableCell>
                                <TableCell align='right'>{status.id !== null ? status.name : null}</TableCell>
                            </TableRow>
                            {!maintenance ? (
                                <React.Fragment>
                                    <TableRow>
                                        <TableCell>{customLabel("odpStatus.duration")}</TableCell>
                                        <TableCell align='right'>{totEvents ?
                                            <span>
                                                {secondsToHms(totEvents)}
                                            </span>
                                            : null}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("odpStatus.durationKpi")}</TableCell>
                                        <TableCell align='right'>{totEventsKpi ?
                                            <span>
                                                {secondsToHms(totEventsKpi)}
                                            </span>
                                            : null}</TableCell>
                                    </TableRow>
                                    {!stats ? (
                                        <TableRow>
                                            <TableCell>{customLabel("odpStatus.crossingTime")}</TableCell>
                                            <TableCell align='right'>{crossingTime ?
                                                <span>
                                                    {secondsToHms(crossingTime)}
                                                </span>
                                                : null}</TableCell>
                                        </TableRow>
                                    ) : null}
                                </React.Fragment>
                            ) : null}
                        </tbody>
                    </Table>
                </Grid>
                {!maintenance ? (
                    <Grid item xs={12} sm={12} md={12} lg={!stats ? 12 : 6} xl={!stats ? 12 : 6}>
                        <div className={classes.pieHeader}>
                            <b>{customLabel("odpStatus.completePie")}</b>
                        </div>
                        {barEvents && barEvents.length > 0 ?
                            <div style={{ width: '90%', height: '50px', margin: '0 auto' }}>
                                <BarChart data={barEvents} getColor={getColor} keys={Object.keys(colors)} getPercColor={getPercColor} />
                            </div>
                            : null
                        }
                        <Table size="small">
                            <tbody>
                                <TableRow>
                                    <TableCell><b>{customLabel("odpStatus.activities")}</b></TableCell>
                                    <TableCell align={"right"}><b>%</b></TableCell>
                                    <TableCell align={"right"}><b>{customLabel("odpStatus.HHMMSS")}</b></TableCell>
                                </TableRow>
                                {events ? (
                                    events.map((e) => {
                                        return (
                                            <TableRow key={e.id}>
                                                <TableCell style={{ borderLeft: "5px solid " + e.color }}>{e.id}</TableCell>
                                                <TableCell align={"right"}>{e.value >= 0.00 ? e.value : "0.00"}%</TableCell>
                                                <TableCell align={"right"}>{secondsToHms(e.svalue)}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                ) : null}
                            </tbody>
                        </Table>
                        <div className={classes.pieHeader}>
                            <b>{customLabel("odpStatus.kpiPie")}</b>
                        </div>
                        {barKpiEvents && barKpiEvents.length > 0 ?
                            <div style={{ width: '90%', height: '50px', margin: '0 auto' }}>
                                <BarChart data={barKpiEvents} getColor={getColor} keys={Object.keys(colors)} getPercColor={getPercColor} />
                            </div>
                            : null
                        }
                        <Table size="small">
                            <tbody>
                                <TableRow>
                                    <TableCell><b>{customLabel("odpStatus.kpiActivities")}</b></TableCell>
                                    <TableCell align={"right"}><b>%</b></TableCell>
                                    <TableCell align={"right"}><b>{customLabel("odpStatus.HHMMSS")}</b></TableCell>
                                </TableRow>
                                {kpiEvents ? (
                                    kpiEvents.map((e) => {
                                        return (
                                            <TableRow key={e.id}>
                                                <TableCell style={{ borderLeft: "5px solid " + e.color }}>{e.id}</TableCell>
                                                <TableCell align={"right"}>{e.value >= 0.00 ? e.value : "0.00"}%</TableCell>
                                                <TableCell align={"right"}>{secondsToHms(e.svalue)}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                ) : null}
                            </tbody>
                        </Table>
                    </Grid>
                ) : null}
            </Grid>
            {!maintenance && stats ? (
                <>
                    <Divider />
                    <div className={classes.verticalBarHeader}>
                        <b>{customLabel("odpStatus.dailyProd")}</b>
                    </div>
                    <div style={{ overflowX: 'auto' }}>
                        {dailyProd && dailyProd.length > 0 ?
                            <div style={{ height: dailyProd.length === 0 ? '0' : '350px', width: dailyProd.length > 13 ? dailyProd.length * 90 : 'auto', padding: '30px 0 10px 0', margin: '0 auto' }}>
                                <BarChart data={dailyProd} keys={["total"]} getColor={"#74C365"} getPercColor={getPercColor} vertical />
                            </div> : null
                        }
                    </div>
                </>
            ) : null}
        </div>
    )
}
export default NewProductionData