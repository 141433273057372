import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from '../../utility/customLabel';
import {
    operatorViewSelector, disableFlagButtons, runButtonAPI, enableFlagInsertButton, disableFlagInsertButton, changeCurrentValue, enableFlagAskConfirmation, disableFlagAskConfirmation
} from "../slice";
import { enableSplitDialog } from '../../split/slice'
import { Button, Input } from "@material-ui/core";
import { buttonType } from "../../buttons/utility";
import DialogOperator from '../utility/DialogOperator';
import { opViewStyles } from '../utility/customStyles';

const ButtonsDialog = ({ buttons }) => {
    const customClasses = opViewStyles();
    const dispatch = useDispatch();
    const { flagDialog, loading, process, operator, machine, odp, machineEvent, userEvent, choosenButton,
        currentValue } = useSelector(operatorViewSelector);

    const renderListButtonsForm = () => {
        if (buttons && buttons.length === 0) {
            return (
                <div>
                    {customLabel("function.notAvailable").toUpperCase()}
                </div>
            )
        }
        else if (buttons && buttons.length > 0) {
            return (
                <div className={customClasses.buttonContainer}>
                    {buttons.map((b) => {
                        return (
                            <Button key={b.id} onClick={() => clickButton(b)} disabled={loading} className={customClasses.listButton} color="primary" variant="contained">
                                <span style={{ wordBreak: "break-word" }}>{b.name}</span>
                            </Button>
                        )
                    })}
                </div>
            )
        }
        else return null;
    };
    //Aggiornamento dello proprietà current value tramite Input field
    const handleChangeValueText = (event) => {
        dispatch(changeCurrentValue({ value: event.target.value }));
    };

    const renderInsertButtonForm = () => {
        if (choosenButton) {
            return (
                <div style={{ textAlign: "center" }} key={choosenButton.id}>
                    <h3>{choosenButton.nameValue}</h3>
                    <div style={{
                        textAlign: "center",
                        marginTop: "20px"
                    }}>
                        <Input
                            inputRef={(input) => {
                                if (input !== null) {
                                    input.focus();
                                }
                            }}
                            value={currentValue ? currentValue : ""} margin="dense" fullWidth name="operator" onChange={handleChangeValueText}></Input>
                    </div>
                </div>
            );
        }
    }

    const renderAskConfirmationForm = () => {
        if (choosenButton) {
            return (
                <>Hai premuto {choosenButton.name}, vuoi continuare?</>
            );
        }
    }

    /**
     * Se bottone di tipo rest esegue chiamata altrimenti, se di tipo newWindow apre una nuova pagina nel browser
     * @param {object} b bottone
     * @returns 
     */
    const clickButton = (b) => {
        switch (b.type) {
            case buttonType.rest:
                if (!b.askValue && !b.askConfirmation) {
                    sendApi(b);
                } else if (b.askValue) {
                    dispatch(enableFlagInsertButton(b))
                } else {
                    dispatch(enableFlagAskConfirmation(b))
                }
                break;
            case buttonType.newWindow:
                //window.open(b.api + "/user/" + operator.id + "/" + operator.code + "/machine/" + machine.id + "/odp/" + odp.id + "/process/" + process.id);
                dispatch(disableFlagButtons())
                dispatch(enableSplitDialog())
                break;
            default: return;
        }
    };

    const sendApi = (button) => {
        let params = {
            id: false, api: false, process: false, user: false, odp: false, customerOrder: false, machineEvent: false,
            userEvent: false
        }
        params.id = button.button_id;
        params.api = button.api;
        if (button.sendProcess) {
            params.process = process
        };
        params.user = operator
        params.machine = machine
        if (button.sendOdp) {
            params.odp = odp
        };
        if (button.sendCustomerOrder) {
            params.customerOrder = odp.customerOrder
        };
        if (button.sendMachineEvent) {
            params.machineEvent = machineEvent
        };
        if (button.sendUserEvent) {
            params.userEvent = userEvent
        };
        if (button.askValue && currentValue && currentValue !== "") {
            params.value = currentValue;
            params.nameValue = button.nameValue;
        } else {
            params.value = false;
            params.nameValue = false;
        }
        dispatch(runButtonAPI(params));
    };

    const renderListButtonsActions = () => {
        return (
            <Button className={customClasses.dialogButton} disabled={loading} variant="contained" onClick={() => dispatch(disableFlagButtons())} color="secondary">{customLabel("button.close")}</Button>
        )
    }

    const renderInsertButtonActions = () => {
        return (
            <React.Fragment>
                <Button className={customClasses.dialogButton} variant="contained" onClick={() => sendApi(choosenButton)} color="primary"
                    disabled={currentValue === false || currentValue === "" || loading}>
                    {customLabel("button.confirm")}
                </Button>
                <Button className={customClasses.dialogButton} disabled={loading} variant="contained" onClick={() => dispatch(disableFlagInsertButton())} color="secondary">{customLabel("button.back")}
                </Button>
            </React.Fragment>
        )
    }

    const renderAskConfirmationActions = () => {
        return (
            <React.Fragment>
                <Button className={customClasses.dialogButton} variant="contained" onClick={() => sendApi(choosenButton)} color="primary"
                    disabled={loading}>
                    {customLabel("button.confirm")}
                </Button>
                <Button className={customClasses.dialogButton} disabled={loading} variant="contained" onClick={() => dispatch(disableFlagAskConfirmation())} color="secondary">{customLabel("button.back")}
                </Button>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <DialogOperator flag={flagDialog.flagButtons ? true : false} title={customLabel("operatorView.availableActions")}
                form={() => renderListButtonsForm()}
                actions={() => renderListButtonsActions()} transparentBackround={false} />
            <DialogOperator flag={flagDialog.flagInsertButton ? true : false} title={`${customLabel("operatorView.insertActionParam")}: ${choosenButton.name}`}
                form={() => renderInsertButtonForm()}
                actions={() => renderInsertButtonActions()} transparentBackround={false} />
            <DialogOperator flag={flagDialog.flagAskConfirmation} title={customLabel("function.confirm")} form={() => renderAskConfirmationForm()}
                actions={() => renderAskConfirmationActions()} />
        </React.Fragment>
    )
}

export default ButtonsDialog
