import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Input, InputLabel, FormControl, Button, Grid, Table, TableHead, TableRow, TableCell, TableBody, IconButton
} from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";
import { addLabelAPI, configurationSelector, deleteLabelAPI, getLabelsAPI } from "../slice";

const Labels = () => {
    const myClasses = mesStyles();
    const dispatch = useDispatch()
    const { configuration, reload, loading } = useSelector(configurationSelector)
    const [label, setLabel] = useState({ code: "", value: "" })

    useEffect(() => {
        if (reload && !loading) {
            dispatch(getLabelsAPI(configuration.id));
        }
    }, [dispatch, reload, loading, configuration])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLabel((prevLabel) => ({
            ...prevLabel,
            [name]: value
        }));
    }

    const handleAdd = () => {
        dispatch(addLabelAPI(label, configuration.id))
        setLabel({ code: "", value: "" });
    };

    const handleDelete = (labelId) => {
        dispatch(deleteLabelAPI(labelId, configuration.id))
    }

    return (
        <div className={myClasses.dialogForm500}>
            <Grid container size="small">
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5} direction="column">
                    <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="code">{customLabel("configuration.labelCode")}</InputLabel>
                        <Input name="code" type="string" value={label.code} onChange={handleChange} />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="value">{customLabel("configuration.labelValue")}</InputLabel>
                        <Input name="value" type="string" value={label.value} onChange={handleChange} />
                        <Button color="primary" size="small" style={{ alignSelf: 'flex-start', marginTop: '5px' }} onClick={handleAdd}>
                            {customLabel("button.add")}
                        </Button>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7} style={{ padding: '24px' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{customLabel("configuration.labelCode")}</TableCell>
                                <TableCell>{customLabel("configuration.labelValue")}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {configuration && configuration.labels && configuration.labels.map((l) => {
                                return (
                                    <TableRow key={l.id}>
                                        <TableCell>{l.code}</TableCell>
                                        <TableCell>{l.value}</TableCell>
                                        <TableCell>
                                            <IconButton size="small" onClick={() => handleDelete(l.id)}><HighlightOffIcon fontSize="small" /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </div>
    );
};
export default Labels;