import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { StyledTableRow } from "../../../utility/TableList";
import { TableContainer, Table, TableCell, TableRow, TableBody, TableHead, CircularProgress } from '@material-ui/core';
import { customLabel } from '../../../utility/customLabel';
import { selector, loadCustomOdpsAPI, initCustomFilter } from "../slice";
import { currentList } from "../utility";
import moment from "moment";
import { bishopFormats } from '../../../../constants';
import { mesStyles } from '../../../utility/ultrafabStyles';
import { customersSelector, getListAPI as getCustomersAPI } from "../../../customers/slice";
import { articlesSelector, getListAPI as getArticles } from "../../../articles/slice";
import FilterRow from "./tableUtility/FilterRow";
import ButtonsFragment from './tableUtility/ButtonsFragment';
import PaginationFragment from './tableUtility/PaginationFragment';
import { suppliersSelector, getListAPI as getSuppliersAPI } from "../../../suppliers/slice";

const External = ({ handleClickRedirect, onChangeFilter, onChangeFilterAutocomplete, handleChangeDate, handleResetDate,
    handleChangePage, handleChangeRowsPerPage, configuration, enableReload }) => {

    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { odpsExternal, loadingExternal, errorsExternal, externalFilter, totalExternal, customReload } = useSelector(selector);
    const { customers } = useSelector(customersSelector);
    const { articles } = useSelector(articlesSelector);
    const { suppliers } = useSelector(suppliersSelector);

    useEffect(() => {
        dispatch(getCustomersAPI(0, 100000, "code", "asc", false, false));
        dispatch(getArticles(0, 100000, "code", "asc", false, false, false, false, false));
        dispatch(getSuppliersAPI(0, 1000, "code", "asc", false, false))
    }, [dispatch])

    //inizializzo filtro
    useEffect(() => {
        if (!externalFilter && configuration) {
            dispatch(initCustomFilter({ filter: currentList.external.filter, showFilter: configuration.showFilters }))
        }
    }, [configuration, externalFilter, dispatch])

    //reload dopo cambio filtro
    useEffect(() => {
        if (externalFilter && customReload && !errorsExternal && !loadingExternal) {
            dispatch(loadCustomOdpsAPI(currentList.external, externalFilter))
        }
    }, [dispatch, customReload, errorsExternal, externalFilter, loadingExternal])

    const toggleFilter = () => {
        if (!externalFilter.showFilter) {
            dispatch(initCustomFilter({ filter: currentList.external.filter, showFilter: true }))
        } else {
            dispatch(initCustomFilter({ filter: currentList.external.filter, showFilter: false }))
        }
    }

    const emptyFilter = () => {
        dispatch(initCustomFilter({ filter: currentList.external.filter, showFilter: true }))
    };

    return (
        <React.Fragment>
            <ButtonsFragment filter={externalFilter} toggleFilter={toggleFilter} emptyFilter={emptyFilter} />
            <TableContainer style={{ maxHeight: "90%" }}>
                <Table stickyHeader
                    aria-labelledby="tableTitle"
                    size={'small'}
                    aria-label="enhanced table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell><b>{customLabel("odp.odp")}</b></TableCell>
                            <TableCell><b>{customLabel("customerOrder.customerOrder")}</b></TableCell>
                            <TableCell><b>{customLabel("customer.customer")}</b></TableCell>
                            <TableCell><b>{customLabel("article.article")}</b></TableCell>
                            <TableCell><b>{customLabel("odp.deliveryDate")}</b></TableCell>
                            <TableCell><b>{customLabel("odp.startDate")}</b></TableCell>
                            <TableCell><b>{customLabel("supplier.supplier")}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/*7 colonne */}
                        {externalFilter && odpsExternal && articles && customers && suppliers && !loadingExternal ? (
                            <FilterRow customFilter={externalFilter} customers={customers} articles={articles} suppliers={suppliers} onChangeFilter={onChangeFilter}
                                onChangeFilterAutocomplete={onChangeFilterAutocomplete} handleChangeDate={handleChangeDate} handleResetDate={handleResetDate}
                                colSpan={[1, 1, 1, 1, 1, 1, 0, 1, 0]} typeStatus={currentList.external.url} showDeliveryFilter={true} showStartDateFilter={true}
                                showEndDateFilter={false} showSuppliersFilter={true} showMachinesFilter={false} showWorkcentresFilter={false} machines={false}
                                workcentres={false} enableReload={enableReload} />
                        ) : null}
                        {odpsExternal && odpsExternal.length > 0 && articles && customers && suppliers && !loadingExternal ? (
                            odpsExternal.map((o) => {
                                return (
                                    <StyledTableRow key={o.id} onClick={() => handleClickRedirect(o.id)} className={myClasses.cursorPointer}>
                                        <TableCell>{o.code}</TableCell>
                                        <TableCell>{o.customerOrder?.code}</TableCell>
                                        <TableCell>{o.customer?.code} {o.customer?.name}</TableCell>
                                        <TableCell>{o.article?.code} {o.article?.name}</TableCell>
                                        <TableCell>{o.deliveryDate ? moment(o.deliveryDate).format(configuration && configuration.showOdpDeliveryTime ? bishopFormats.LT : bishopFormats.L) : null}</TableCell>
                                        <TableCell>{o.startDate ? moment(o.startDate).format(bishopFormats.LT) : null}</TableCell>
                                        <TableCell>
                                            {o.currentProcess !== null && o.processes[o.currentProcess].machineEvent.id ?
                                                o.processes[o.currentProcess].machineEvent.machine.name : o.machine.id !== null ? o.machine.name : null}
                                        </TableCell>
                                    </StyledTableRow>
                                )
                            })
                        ) : odpsExternal && odpsExternal.length === 0 && !loadingExternal ?
                            (
                                <TableRow>
                                    <TableCell colSpan={7}>{customLabel("odp.noOdpsAvailable").toUpperCase()}</TableCell>
                                </TableRow>
                            )
                            : loadingExternal || !customers || !articles || !suppliers ?
                                (<TableRow>
                                    <TableCell align="center" colSpan={7}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>)
                                :
                                null}
                    </TableBody>
                </Table>
            </TableContainer>
            {totalExternal !== false && !loadingExternal ? (
                <PaginationFragment total={totalExternal} customFilter={externalFilter} handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} typeStatus={currentList.external.url} disabledChange={loadingExternal} />
            ) : null}
        </React.Fragment>
    )
}

export default External
