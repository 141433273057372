import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    alerts: [],
    closedAlerts: []
};

const alertsSlice = createSlice({
    name: "alerts",
    initialState,
    reducers: {
        resetState: (state) => {
            Object.assign(state, initialState);
        },
        addAlert: (state, { payload }) => {
            let currentAlerts = [...state.alerts];
            let closedAlerts = [...state.closedAlerts];
            let indexCurrent = currentAlerts.findIndex((a) => {
                return a.name === payload.name;
            })
            let indexClosed = closedAlerts.findIndex((a) => {
                return a === payload.name;
            })
            if (indexCurrent === -1 && indexClosed === -1) {
                let newAlert = { name: "", message: "", type: "" };
                newAlert.name = payload.name;
                newAlert.message = payload.message;
                newAlert.type = payload.type;
                currentAlerts.push(newAlert)
                state.alerts = [...currentAlerts];
            }
        },
        closeAlert: (state, { payload }) => {
            let alertToClose = payload;
            let currentAlerts = [...state.alerts];
            let closedAlerts = [...state.closedAlerts];
            currentAlerts.splice(currentAlerts.findIndex(a => a.name === alertToClose), 1);
            let index = closedAlerts.findIndex((a) => {
                return a === alertToClose;
            })
            if (index === -1) {
                closedAlerts.push(alertToClose);
            }
            state.alerts = currentAlerts;
            state.closedAlerts = closedAlerts;
        },
        resetClosedAlerts: (state) => {
            state.closedAlerts = [];
        }
    },
});

export const { resetState, addAlert, closeAlert, resetClosedAlerts } = alertsSlice.actions;
export const alertsSelector = (state) => state.alerts;
export default alertsSlice.reducer;
