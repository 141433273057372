import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { Redirect } from "react-router-dom";

import {
  machinesSelector, getAPI, create, edit, cancelEdit, change, createAPI, updateAPI, confirmDelete,
  deleteAPI, getMachineAPI, select
} from "./slice";
import {
  Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Grid, DialogTitle, DialogActions, DialogContent, Dialog
} from "@material-ui/core";
import { Form } from "./Form";
import { Item } from "./Item";
import { Details } from "./Details";
//import { Graphs } from "./Graphs";
import VarsPage from "./VarsPage";
import DocumentsPage from '../documents/DocumentsPage';
//import moment from "moment";
//import { machineEventsSelector, getListAPI as getMachineEventsAPI } from "../machineEvents/slice";
//import { workcentresSelector, getListAPI as getWorkcentresAPI } from "../workcentres/slice";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import Image from "./Image";

export const MachinesPage = ({ workcentre, workcentreLoading, workcentres }) => {
  const dispatch = useDispatch();
  const myClasses = mesStyles();
  const { machines, editmachine, machine, editmode, deleting, isChanged, loading } = useSelector(machinesSelector);
  //const { machineEvents } = useSelector(machineEventsSelector);
  //const { workcentres } = useSelector(workcentresSelector);

  useEffect(() => {
    //api per lista macchina di questo workcentre
    dispatch(getAPI(workcentre));
    /*  if (!workcentres) {
       dispatch(getWorkcentresAPI(0, 10000, "code", "asc", false, false));
     } */
    //dispatch(getMachineEventsAPI(0, 10000, "name", "asc", false));
  }, [dispatch, workcentre]);

  useEffect(() => {
    if (isChanged) {
      dispatch(getAPI(workcentre));
    }
  }, [dispatch, workcentre, isChanged]);


  if (machine && workcentre && workcentre.id !== machine.workcentre.id) {
    return <Redirect to={"/workcentres/" + machine.workcentre.id} />;
  }

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    dispatch(change({ name: name, value: value }));
  };

  const save = () => {
    if (editmachine.id === "new") {
      dispatch(createAPI(workcentre, editmachine));
    } else {
      dispatch(updateAPI(workcentre, editmachine));
    }
  };

  const onSelect = (machine) => {
    if (machine) {
      dispatch(getMachineAPI(machine));
    } else {
      dispatch(select(false));
    }
    dispatch(getAPI(workcentre));
    /*  if (machine) {
      GETEVENTS OBSOLETO
       dispatch(getEvents(machine, moment().startOf("day").unix() * 1000, moment().endOf("day").unix() * 1000, "today", machineEvents));
       //dispatch(getEvents(machine, moment().add(-1, 'day').startOf("day").unix() * 1000, moment().add(-10, 'day').endOf("day").unix() * 1000, "yesterday", machineEvents));
       dispatch(getEvents(machine, moment().add(-1, 'day').startOf("day").unix() * 1000, moment().add(-1, 'day').endOf("day").unix() * 1000, "yesterday", machineEvents));
       dispatch(getEvents(machine, moment().add(-7, 'day').startOf("day").unix() * 1000, moment().endOf("day").unix() * 1000, "thisWeek", machineEvents));
       dispatch(getEvents(machine, moment().add(-14, 'day').startOf("day").unix() * 1000, moment().add(-8, 'day').endOf("day").unix() * 1000, "lastWeek", machineEvents));
       dispatch(getEvents(machine, moment().add(-1, 'month').startOf("day").unix() * 1000, moment().endOf("day").unix() * 1000, "thisMonth", machineEvents));
       dispatch(getEvents(machine, moment().add(-2, 'month').startOf("day").unix() * 1000, moment().add(-1, 'month').endOf("day").unix() * 1000, "lastMonth", machineEvents));
     } */
  };

  const onEdit = () => {
    dispatch(edit());
  };
  const toggleDelete = () => {
    dispatch(confirmDelete());
  };

  const doDelete = () => {
    dispatch(deleteAPI(workcentre, machine));
  };

  return (
    <React.Fragment>
      <PaperForm>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <div className={myClasses.titleFormSpaceBetween}>
              <Typography component="h1" variant="h6" color="inherit">
                {customLabel("machine.machines")}
              </Typography>
            </div>
            <div className={myClasses.tableContainer}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{customLabel("machine.code")}</TableCell>
                    <TableCell>{customLabel("machine.name")}</TableCell>
                    <TableCell>
                      <Button color="primary" onClick={() => dispatch(create())} disabled={workcentreLoading || loading}>
                        {customLabel("button.add")}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {machines ? (
                  <TableBody>
                    {machines.map((m) => {
                      return (
                        <Item key={m.id} machine={machine} m={m} select={onSelect} />
                      );
                    })}
                  </TableBody>
                ) : null}
              </Table>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            {machine ? (
              <div>
                <Details machine={machine} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} select={onSelect} doDelete={doDelete} loading={loading} />
                <VarsPage machine={machine} loading={loading} />
                <DocumentsPage parentId={machine.id} parentType="machines" extLoading={loading} title={true} size="small" />
                <Image workcentre={workcentre} machine={machine} loading={loading} />
              </div>
            ) : null}
          </Grid>
        </Grid>
      </PaperForm>
      {/*  {
        machine ? (
          <Graphs machineEvents={machineEvents} />
        ) : null
      } */}
      <Dialog open={editmode} keepMounted maxWidth={"md"} fullWidth aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">
          {customLabel("machine.machine")}
        </DialogTitle>
        <DialogContent>
          <Form editmachine={editmachine} handleChange={handleChange} workcentres={workcentres} />
        </DialogContent>
        <DialogActions>
          <Button onClick={save} disabled={editmachine.name === "" || editmachine.code === "" || loading} color="primary">
            {customLabel("button.save")}
          </Button>
          <Button onClick={() => dispatch(cancelEdit())} color="default" disabled={loading}>
            {customLabel("button.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
