import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { StyledTableRow } from "../../../utility/TableList";
import { TableContainer, Table, TableCell, TableRow, TableBody, TableHead, CircularProgress } from '@material-ui/core';
import { customLabel } from '../../../utility/customLabel';
import { selector, loadCustomOdpsAPI, initCustomFilter } from "../slice";
import { currentList } from "../utility";
import moment from "moment";
import { bishopFormats } from '../../../../constants';
import { mesStyles } from '../../../utility/ultrafabStyles';
import { customersSelector, getListAPI as getCustomersAPI } from "../../../customers/slice";
import { articlesSelector, getListAPI as getArticles } from "../../../articles/slice";
import FilterRow from "./tableUtility/FilterRow";
import ButtonsFragment from './tableUtility/ButtonsFragment';
import PaginationFragment from './tableUtility/PaginationFragment';

const Paused = ({ handleClickRedirect, onChangeFilter, onChangeFilterAutocomplete, handleChangeDate, handleResetDate,
    handleChangePage, handleChangeRowsPerPage, configuration, enableReload }) => {

    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { odpsPaused, loadingPaused, errorsPaused, pausedFilter, totalPaused, customReload } = useSelector(selector);
    const { customers } = useSelector(customersSelector);
    const { articles } = useSelector(articlesSelector);

    useEffect(() => {
        dispatch(getCustomersAPI(0, 100000, "code", "asc", false, false));
        dispatch(getArticles(0, 100000, "code", "asc", false, false, false, false, false));
    }, [dispatch])

    //inizializzo filtro
    useEffect(() => {
        if (!pausedFilter && configuration) {
            dispatch(initCustomFilter({ filter: currentList.paused.filter, showFilter: configuration.showFilters }))
        }
    }, [configuration, pausedFilter, dispatch])

    //reload dopo cambio filtro
    useEffect(() => {
        if (pausedFilter && customReload && !errorsPaused && !loadingPaused) {
            dispatch(loadCustomOdpsAPI(currentList.paused, pausedFilter))
        }
    }, [dispatch, customReload, errorsPaused, pausedFilter, loadingPaused])

    const toggleFilter = () => {
        if (!pausedFilter.showFilter) {
            dispatch(initCustomFilter({ filter: currentList.paused.filter, showFilter: true }))
        } else {
            dispatch(initCustomFilter({ filter: currentList.paused.filter, showFilter: false }))
        }
    }

    const emptyFilter = () => {
        dispatch(initCustomFilter({ filter: currentList.paused.filter, showFilter: true }))
    };

    return (
        <React.Fragment>
            <ButtonsFragment filter={pausedFilter} toggleFilter={toggleFilter} emptyFilter={emptyFilter} />
            <TableContainer style={{ maxHeight: "90%" }}>
                <Table stickyHeader
                    aria-labelledby="tableTitle"
                    size={'small'}
                    aria-label="enhanced table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell><b>{customLabel("odp.odp")}</b></TableCell>
                            <TableCell><b>{customLabel("customerOrder.customerOrder")}</b></TableCell>
                            <TableCell><b>{customLabel("customer.customer")}</b></TableCell>
                            <TableCell><b>{customLabel("article.article")}</b></TableCell>
                            <TableCell><b>{customLabel("odp.deliveryDate")}</b></TableCell>
                            <TableCell><b>{customLabel("odp.startDate")}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/*6 colonne */}
                        {pausedFilter && odpsPaused && articles && customers && !loadingPaused ? (
                            <FilterRow customFilter={pausedFilter} customers={customers} articles={articles} suppliers={false} onChangeFilter={onChangeFilter}
                                onChangeFilterAutocomplete={onChangeFilterAutocomplete} handleChangeDate={handleChangeDate} handleResetDate={handleResetDate}
                                colSpan={[1, 1, 1, 1, 1, 1, 0, 0, 0]} typeStatus={currentList.paused.url} showDeliveryFilter={true} showStartDateFilter={true}
                                showEndDateFilter={false} showSuppliersFilter={false} showMachinesFilter={false} showWorkcentresFilter={false} machines={false}
                                workcentres={false} enableReload={enableReload} />
                        ) : null}
                        {odpsPaused && odpsPaused.length > 0 && articles && customers && !loadingPaused ? (
                            odpsPaused.map((o) => {
                                return (
                                    <StyledTableRow key={o.id} onClick={() => handleClickRedirect(o.id)} className={myClasses.cursorPointer}>
                                        <TableCell>{o.code}</TableCell>
                                        <TableCell>{o.customerOrder?.code}</TableCell>
                                        <TableCell>{o.customer?.code} {o.customer?.name}</TableCell>
                                        <TableCell>{o.article?.code} {o.article?.name}</TableCell>
                                        <TableCell>{o.deliveryDate ? moment(o.deliveryDate).format(configuration && configuration.showOdpDeliveryTime ? bishopFormats.LT : bishopFormats.L) : null}</TableCell>
                                        <TableCell>{o.startDate ? moment(o.startDate).format(bishopFormats.LT) : null}</TableCell>
                                    </StyledTableRow>
                                )
                            })
                        ) : odpsPaused && odpsPaused.length === 0 && !loadingPaused ?
                            (
                                <TableRow>
                                    <TableCell colSpan={6}>{customLabel("odp.noOdpsAvailable").toUpperCase()}</TableCell>
                                </TableRow>
                            )
                            : loadingPaused || !customers || !articles ?
                                (<TableRow>
                                    <TableCell align="center" colSpan={6}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>)
                                :
                                null}
                    </TableBody>
                </Table>
            </TableContainer>
            {totalPaused !== false && !loadingPaused ? (
                <PaginationFragment total={totalPaused} customFilter={pausedFilter} handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} typeStatus={currentList.paused.url} disabledChange={loadingPaused} />
            ) : null}
        </React.Fragment>
    )
}

export default Paused
