import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProcessTypeAPI, splitSelector, disableSplitDialog } from "./slice";
import {
    Grid, Container, Dialog, DialogContent, IconButton
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { splitStyles } from "./utility/splitStyles";
import T from "i18n-react"
import { configurationSelector, getAPI as getConfigurationAPI } from "../configuration/slice";
import CurrentSelection from './CurrentSelection';
import MainSplit from './MainSplit';
import { enableFlagButtons } from '../operatorView/slice';

const SplitPage = ({ process, machine, operator, odp, workcentre }) => {
    const classes = splitStyles();
    const dispatch = useDispatch();
    //const logobishop = process.env.PUBLIC_URL + "/logobishop.png";
    const { configuration, loading: loadingConf, hasErrors: errorsConf } = useSelector(configurationSelector);
    const { splitDialog, processes } = useSelector(splitSelector)
    // const { user, machine, process } = useSelector(splitSelector);
    //const [open, setOpen] = React.useState(false);

    useEffect(() => {
        //getProcessAPI chiama in callback il processType per valorizzare le machines disponibili
        dispatch(getProcessTypeAPI(process.type.id))
    }, [dispatch, process]);

    useEffect(() => {
        if (configuration === false && !loadingConf && !errorsConf) {
            dispatch(getConfigurationAPI());
        }
        if (configuration && configuration.label !== null) {
            T.setTexts(JSON.parse(configuration.label), {
                notFound: (key) => `${key} <- missing!`,
            });
        }
    }, [dispatch, configuration, loadingConf, errorsConf]);

    const onCloseDialog = () => {
        dispatch(disableSplitDialog())
        dispatch(enableFlagButtons())
    }

    let q = 0;
    if (processes) {
        processes.map((p) => {
            return q += p.quantity
        })
    }

    return (
        <React.Fragment>
            <Dialog fullWidth maxWidth="xl" open={splitDialog}>
                <div className={classes.root}>
                    <DialogContent>
                        <IconButton onClick={onCloseDialog}>
                            <CloseIcon />
                        </IconButton>
                        <Container className={classes.container} maxWidth={false}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <CurrentSelection process={process} operator={operator} machine={machine} workcentre={workcentre} q={q} configuration={configuration} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <MainSplit process={process} operator={operator} machine={machine} workcentre={workcentre} q={q} />
                                </Grid>
                            </Grid>
                        </Container>
                    </DialogContent>
                </div>
            </Dialog>
        </React.Fragment >
    )
}

export default SplitPage
