import React from "react";
import { Link } from "react-router-dom";
import {
  FormControl, InputLabel, Input, Divider, Button, Typography, FormControlLabel, Checkbox, TextField
} from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";

export const Form = ({ bomedit, handleChange, cancelEdit, save, loading }) => {
  const myClasses = mesStyles();

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">
          {customLabel("bom.bom")}
        </Typography>
        <LockOpenIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="code">{customLabel("bom.code")}</InputLabel>
          <Input autoFocus name="code" error={bomedit.code === ""} value={bomedit.code} onChange={handleChange}
          />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="name">{customLabel("bom.name")}</InputLabel>
          <Input name="name" error={bomedit.name === ""} value={bomedit.name} onChange={handleChange}
          />
        </FormControl>
        <FormControl className={myClasses.form100}>
          <TextField
            variant="outlined" name="note" label={customLabel("function.note")} multiline rows={4} value={bomedit.note}
            onChange={handleChange}
          />
        </FormControl>
        <FormControlLabel labelPlacement="end"
          control={
            <Checkbox checked={bomedit.lockProcesses} onChange={handleChange} name="lockProcesses" color="default" />
          }
          label={customLabel("bom.lockProcesses")}
        />
        <FormControlLabel labelPlacement="end"
          control={
            <Checkbox checked={bomedit.autoClose} onChange={handleChange} name="autoClose" color="default" />
          }
          label={customLabel("bom.autoClose")}
        />
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple onClick={save} disabled={bomedit.name === "" || bomedit.code === "" || loading} color="primary">
          {customLabel("button.save")}
        </Button>
        {bomedit.id !== "new" ? (
          <Button focusRipple onClick={cancelEdit} disabled={loading}>
            {customLabel("button.cancel")}
          </Button>
        ) : (
          <Button component={Link} to={`/admin/boms`} disabled={loading}>
            {customLabel("button.back")}
          </Button>
        )}
      </div>
    </PaperForm>
  );
};
