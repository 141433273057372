import React from "react";
import {
    FormControlLabel, Button, Input, InputLabel, FormControl, Checkbox, TextField
} from "@material-ui/core";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";

const Company = ({ configurationedit, handleChange, labelFile, loading, resetInput, cancelLabel, onSelectLabel,
    logoFile, onSelectLogo, cancelLogo }) => {
    const myClasses = mesStyles();

    return (
        <div className={myClasses.dialogForm500}>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="name">{customLabel("configuration.nameCompany")}</InputLabel>
                <Input autoFocus name="name" error={configurationedit.name === ""} value={configurationedit.name ? configurationedit.name : ""} onChange={handleChange} />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel>{customLabel("document.labels")}</InputLabel>
                <Input disabled value={labelFile ? labelFile[0].name : customLabel("document.noFileSelected").toUpperCase()} />
            </FormControl>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button component="label" color="primary" style={{ marginTop: "10px" }} disabled={loading}>
                    {configurationedit.label === null ? customLabel("button.uploadLabels") : customLabel("button.changeLabels")}
                    <input type="file" hidden onChange={onSelectLabel} onClick={resetInput} />
                </Button>
                <Button component="label" color="secondary" style={{ marginTop: "10px" }} onClick={cancelLabel}
                    disabled={labelFile === null || loading}>
                    {customLabel("button.emptyLabels")}
                </Button>
            </div>
            <FormControl margin="normal" fullWidth>
                <InputLabel>  {customLabel("configuration.logo")}</InputLabel>
                <Input disabled value={logoFile ? logoFile[0].name : customLabel("document.noFileSelected").toUpperCase()} />
            </FormControl>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button component="label" color="primary" disabled={loading}>
                    {customLabel("button.uploadLogo")}
                    <input type="file" onChange={onSelectLogo} hidden />
                </Button>
                <div style={{ flex: "1 0 0" }} />
                <Button component="label" color="primary" disabled={logoFile === null || loading} onClick={cancelLogo}>
                    {customLabel("button.emptyLogo")}
                </Button>
            </div>
            <FormControl margin="normal" fullWidth>
                <TextField
                    variant="outlined" name="note" multiline label={customLabel("function.note")} rows={4} onChange={handleChange}
                    value={configurationedit.note ? configurationedit.note : ""}
                />
            </FormControl>
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit ? configurationedit.deleteLabel : false} onChange={handleChange} name="deleteLabel" color="default" />
                }
                label={customLabel("configuration.deleteLabel")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit ? configurationedit.deleteLogo : false} onChange={handleChange} name="deleteLogo" color="default" />
                }
                label={customLabel("configuration.deleteLogo")}
            />
        </div>
    );
};
export default Company;