import React from "react";
import { Link } from "react-router-dom";
import {
  Typography, FormControl, InputLabel, Input, Divider, Button, TextField
} from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockIcon from '@material-ui/icons/Lock';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";

export const Details = ({ article, edit, toggleDelete, deleting, doDelete, loading }) => {
  const myClasses = mesStyles();

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">
          {customLabel("article.article")}
        </Typography>
        <LockIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50} disabled>
          <InputLabel htmlFor="code">{customLabel("article.code")}</InputLabel>
          <Input value={article.code} />
        </FormControl>
        <FormControl className={myClasses.form50} disabled>
          <InputLabel htmlFor="extCode">{customLabel("article.extCode")}</InputLabel>
          <Input value={article.extCode !== null ? article.extCode : ""} />
        </FormControl>
        <FormControl className={myClasses.form50} disabled>
          <InputLabel htmlFor="name">{customLabel("article.name")}</InputLabel>
          <Input value={article.name} />
        </FormControl>
        <FormControl disabled className={myClasses.form50}>
          <InputLabel htmlFor="type">{customLabel("article.type.type")}</InputLabel>
          <Input value={article.type === "F" ? customLabel("article.type.F") : article.type === "SF" ? customLabel("article.type.SF") :
            article.type === "RM" ? customLabel("article.type.RM") : ""} />
        </FormControl>
        <FormControl disabled className={myClasses.form50}>
          <InputLabel htmlFor="bom">{customLabel("bom.bom")}</InputLabel>
          <Input value={article.bom.code !== null ? article.bom.code + " - " + article.bom.name : ""} />
        </FormControl>
        <FormControl disabled className={myClasses.form50}>
          <InputLabel htmlFor="measure">{customLabel("article.measureUnit")}</InputLabel>
          <Input value={article.measure !== null ? article.measure : ""} />
        </FormControl>
        <FormControl disabled className={myClasses.form100}>
          <TextField
            disabled variant="outlined" name="note" multiline label={customLabel("function.note")} rows={4}
            value={article.note !== null ? article.note : ""}
          />
        </FormControl>
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple key={article.id} onClick={edit} color="primary" disabled={loading}>
          {customLabel("button.edit")}
        </Button>
        <Button color="secondary" onClick={toggleDelete} disabled={loading}>
          {customLabel("button.delete")}
        </Button>
        <Button component={Link} to={`/articles`} disabled={loading}>
          {customLabel("button.back")}
        </Button>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={toggleDelete} disabled={loading} />
    </PaperForm>
  );
};
