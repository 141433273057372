import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table, TableBody, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button,
    Typography
} from '@material-ui/core';
import { customLabel } from '../../utility/customLabel';
import {
    odpsSelector, setVar,
} from "../slice";
import { renderListVars, renderListMachineVars } from '../../vars/utility';
import { mesStyles } from '../../utility/ultrafabStyles';
import {
    varsSelector, getListVarsByTypeAPI, getListVarsByNameAPI, setVarToSee, setReload as setVarsReload,
    changeFilter as changeVarsFilter, resetState as resetStateVars, getListMachineVarsByNameAPI
} from '../../vars/slice';
import TableVarsDetails from '../../vars/TableVarsDetails';
import SimpleTableHeader from '../../utility/SimpleTableHeader';
import { configurationSelector } from '../../configuration/slice';

const Vars = () => {
    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { odp, staplingVars, staplingMachineVars } = useSelector(odpsSelector);
    const { varToSee, varList, results: varsResults, filter: varsFilter, reload: varsReload, loading: varsLoading } = useSelector(varsSelector);
    const { configuration } = useSelector(configurationSelector);

    /**
     * 
     * @param {*} odpId 
     * @param {*} editProcessId 
     * @param {*} cv 
     * @param {*} machine 
     * @param {*} stapling 
     */
    const setVarsDetails = (odpId, editProcessId, cv, machine, stapling, machineId) => {
        let serialNo = false;
        if (cv.serial) {
            serialNo = cv.serialNo
        }
        dispatch(setVarToSee({ var: cv, odpId: odpId, processId: editProcessId, serialNo: serialNo, machine: machine, stapling: stapling, machineId: machineId }));
        dispatch(setVarsReload());
    };
    const unSetVarsDetails = () => {
        dispatch(resetStateVars());
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(changeVarsFilter({ name: "rowsPerPage", value: parseInt(event.target.value, 10) }))
        dispatch(changeVarsFilter({ name: "page", value: 0 }))
        dispatch(setVarsReload());
    };
    const handleChangePage = (event, newPage, doReload) => {
        dispatch(changeVarsFilter({ name: "page", value: newPage }))
        if (doReload) {
            dispatch(setVarsReload());
        }
    };
    useEffect(() => {
        if (varsReload && !varsFilter.machine) {
            if (varToSee && varToSee.varDef && varToSee.varDef.id !== null) {
                dispatch(getListVarsByTypeAPI(varsFilter));
            } else {
                dispatch(getListVarsByNameAPI(varsFilter));
            }
        } else if (varsReload && varsFilter.machine) {
            dispatch(getListMachineVarsByNameAPI(varsFilter));
        }
    }, [dispatch, varsReload, varsFilter, varToSee]);

    //apre modifica variabile
    const onEditVar = (cv) => {
        dispatch(setVar(cv));
    };

    return (
        <React.Fragment>
            <Paper className={myClasses.processDetailsPaper}>
                <Typography component="h6" variant="h6" color="inherit" className={myClasses.titleTableTypography}>{customLabel("process.processVars")}</Typography>
                <Table size="small" stickyHeader>
                    <SimpleTableHeader list={[customLabel("function.name"), customLabel("function.serial").toUpperCase(), customLabel("function.value"),
                    customLabel("function.date"), customLabel("function.operator").toUpperCase(), customLabel("function.actions").toUpperCase()]} />
                    <TableBody>
                        {renderListVars(odp, true, false, staplingVars, true, false, setVarsDetails, onEditVar, configuration.decimals)}
                    </TableBody>
                </Table>
                <Typography component="h6" variant="h6" color="inherit" className={myClasses.titleTableTypography}>{customLabel("machine.machineVars")}</Typography>
                <Table size="small" stickyHeader>
                    <SimpleTableHeader list={[customLabel("function.name"), customLabel("machine.machine").toUpperCase(), customLabel("function.value"),
                    customLabel("function.date"), customLabel("function.actions").toUpperCase()]} />
                    <TableBody>
                        {renderListMachineVars(odp, true, false, staplingMachineVars, true, setVarsDetails, configuration.decimals)}
                    </TableBody>
                </Table>
                {/*dialog per mostrare lo storico di una variabile */}
                <Dialog open={varToSee ? true : false} keepMounted maxWidth={"md"} fullWidth>
                    <DialogTitle>
                        {customLabel("function.historicalVariable")}: {varToSee && !varsFilter.machine ? varToSee.name : varToSee && varsFilter.machine ? varToSee.var_name : null} {!varsFilter.machine && varToSee && varToSee.serial ? " - " + varToSee.serialNo : null}  {!varsFilter.machine && varToSee && varToSee.measure !== "" && varToSee.measure !== null ? " (" + varToSee.measure + ")" : null}
                    </DialogTitle>
                    <DialogContent>
                        <TableVarsDetails varList={varList} varToSee={varToSee} page={varsFilter.page}
                            setPage={handleChangePage} rowsPerPage={varsFilter.rowsPerPage} setRowsPerPage={handleChangeRowsPerPage} results={varsResults}
                            showOperator={true} decimals={configuration.decimals} loading={varsLoading} />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={varsLoading} onClick={unSetVarsDetails} color="default">{customLabel("button.close")}</Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </React.Fragment >
    )
}

export default Vars
