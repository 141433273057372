export const MACHINES = "machines";
export const MAINTENANCE_TYPES = "maintenance_types";
export const PROCESS_TYPES = "process_types";
export const ODPS = "odps";
export const ARTICLES = "articles";
export const PROCESSES = "processes";

export const INDEX_OPERATOR = 0;
export const INDEX_USER_EVENT = 1;
export const INDEX_WORKCENTRE = 2;
export const INDEX_MACHINE = 3;
export const INDEX_ODP = 4;
export const INDEX_PROCESS = 5;
export const INDEX_STAPLING = 6;
export const INDEX_INTERFACE = 7;

export const MACHINE_EVENT_OFF = "OFF";

//VARIABILI STATE
export const MANUAL_INTERACTION_PROCESS = "manualInteractionProcess";
export const MANUAL_INTERACTION_EVENT = "manualInteractionEvent";
export const MANUAL_INTERACTION_STAPLING = "manualInteractionStapling";
export const START_CHECKS = "startChecks";
export const PAUSE_CHECKS = " pauseChecks";
export const RESTART_CHECKS = "restartChecks";
export const CLOSE_CHECKS = "closeChecks";
export const ON_DEMAND_CHECKS = "onDemandChecks";
export const START_CHECKS_SHOW = "startChecksShow";
export const PAUSE_CHECKS_SHOW = " pauseChecksShow";
export const RESTART_CHECKS_SHOW = "restartChecksShow";
export const CLOSE_CHECKS_SHOW = "closeChecksShow";
export const ON_DEMAND_CHECKS_SHOW = "onDemandChecksShow";
export const EXT_CHECKS = "extChecks";
//verificare

export const FLAG_MAIN_CHECKS = "flagMainChecks"; //noreloading standard
export const FLAG_START = "flagStart";
export const FLAG_CLOSE = "flagClose";
export const FLAG_PAUSE = "flagPause";
export const FLAG_RESTART = "flagRestart";
export const FLAG_ON_DEMAND = "flagOnDemand";
export const FLAG_USER_EVENT_TYPE = "flagUserEventType";
export const FLAG_MACHINE_EVENT_TYPE = "flagMachineEventType";
export const FLAG_MACHINE_EVENT_REASON = "flagMachineEventReason";
export const FLAG_USER_EVENTS = "flagUserEvents";
export const FLAG_VARS = "flagVars"; //noreloading standard
export const FLAG_INSERT_VAR = "flagInsertVar"; //noreloading standard
export const FLAG_BUTTONS = "flagButtons";
export const FLAG_INSERT_BUTTON = "flagInsertButton";
export const FLAG_CHOOSE_PAUSE = "flagChoosePause";
export const FLAG_STOP_PROCESS = "flagStopProcess";
export const FLAG_COMP_CONSUM = "flagCompConsum";
export const FLAG_COMP_WASTE = "flagCompWaste";
export const FLAG_COMP_SUB = "flagCompSubstitute";
export const FLAG_EXT_CHECKS = "flagExtChecks"; //noreloading standard
export const FLAG_INSERT_CHECK = "flagInsertCheck"; //noreloading standard
export const FLAG_ASK_CONFIRMATION = "flagAskConfirmation";

export const FLAG_ALL_DIALOG = {
    FLAG_MAIN_CHECKS: false,
    FLAG_START: false,
    FLAG_CLOSE: false,
    FLAG_PAUSE: false,
    FLAG_RESTART: false,
    FLAG_ON_DEMAND: false,
    FLAG_USER_EVENT_TYPE: false,
    FLAG_MACHINE_EVENT_TYPE: false,
    FLAG_MACHINE_EVENT_REASON: false,
    FLAG_VARS: false,
    FLAG_INSERT_VAR: false,
    FLAG_BUTTONS: false,
    FLAG_INSERT_BUTTON: false,
    FLAG_CHOOSE_PAUSE: false,
    FLAG_STOP_PROCESS: false,
    FLAG_COMP_CONSUM: false,
    FLAG_EXT_CHECKS: false,
    FLAG_INSERT_CHECK: false,
    FLAG_COMP_WASTE: false,
    FLAG_COMP_SUB: false,
}

//per reset
export const LIST_ALL_CHECKS = [START_CHECKS, PAUSE_CHECKS, RESTART_CHECKS, CLOSE_CHECKS, START_CHECKS_SHOW, PAUSE_CHECKS_SHOW, RESTART_CHECKS_SHOW,
    CLOSE_CHECKS_SHOW, ON_DEMAND_CHECKS, EXT_CHECKS];

export const LIST_ALL_INTERACTIONS = [MANUAL_INTERACTION_EVENT, MANUAL_INTERACTION_PROCESS, MANUAL_INTERACTION_STAPLING];

export const socketOptions = {
    onClose: event => console.log('onClose', event),
    onError: error => console.log('onError', error),
    onOpen: event => console.log('onError', event),
    shouldReconnect: () => true,
    retryOnError: false,
    reconnectInterval: 1000,
    reconnectAttempts: 10
}

