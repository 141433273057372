import { AppBar, Button, Paper, Tab, Table, TableCell, TableRow, Tabs } from "@material-ui/core";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EXTRAORDINARY, bishopFormats } from '../../constants';
import { resetState as resetStateMachines } from "../machines/slice";
import { mainSelector } from "../main/slice";
import { showSuccess } from "../notifications/slice";
import { resetState as resetStateRoles } from "../roles/slice";
import { resetState as resetStateUsers } from "../users/slice";
import { customLabel } from "../utility/customLabel";
import { resetState as resetStateWorkcentres } from "../workcentres/slice";
import OperatorDocPage from "./docs/OperatorDocPage";
import OperatorDocStapling from "./docs/OperatorDocStapling";
import {
  createStaplingObjects,
  enableFlagUserEventType,
  getMachineOdpsAPI,
  operatorViewSelector,
  resetDocuments,
  resetOdp,
  resetOperator,
  resetWorkcentre,
  stopUserEventAPI,
  unmountProcessAPI, unmountStaplingAPI
} from "./slice";
import { INDEX_INTERFACE, MACHINE_EVENT_OFF } from "./utility/constants";
import { opViewStyles } from "./utility/customStyles";
import { renderMachineLabel, renderWorkcentreLabel } from "./utility/utility";

const CurrentSelection = ({ configuration }) => {
  const dispatch = useDispatch();
  const customClasses = opViewStyles();

  const { operator, workcentre, machine, odp, process, index, userEvent, loading, modeMaintenance, modeStapling, staplingList, machineEvent } = useSelector(operatorViewSelector);
  const { serverNotResponding } = useSelector(mainSelector);

  const [tabIndex, setTabIndex] = React.useState(0);
  //impostazione postazione salvate nel localStorage 
  const [changeWorkcentre, setChangeWorkcentre] = React.useState(false);
  const [workstation, setWorkstation] = React.useState("");

  let operatorUnmount = ((!modeStapling && process.roles && process.roles.some((r) => r.unmountProcess && operator.authorities.includes(r.authority)))
    || (modeStapling && staplingList && staplingList.length > 0 && staplingList[0].roles && staplingList[0].roles.some((r) => r.unmountProcess && operator.authorities.includes(r.authority))))

  useEffect(() => {
    //caricamento impostazioni localStorage
    if (localStorage.getItem("changeWorkcentre") === "true") {
      setChangeWorkcentre(true);
    } else {
      setChangeWorkcentre(false);
    };
    setWorkstation(localStorage.getItem("workstation"));
  }, [dispatch]);


  //resetta i documenti ogni volta che cambia tabIndex per far in modo di caricare ogni click su tab documenti
  useEffect(() => {
    dispatch(resetDocuments())
    if (modeStapling) {
      dispatch(createStaplingObjects());
    }
  }, [dispatch, tabIndex, modeStapling]);

  //tab del menu in alto
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };
  const stopUserEvent = () => {
    dispatch(stopUserEventAPI(userEvent, false, true, false));
  };
  //apre il dialog per l inserimento dello userEvent
  const enableUserEventType = () => {
    dispatch(enableFlagUserEventType(false));
  };
  /**
   * Se eventoUtente in corso viene chiuso dopodiché resetWorkcentre;
   * cambio commessa --> resetOdp()
   * cambio centro lavoro --> resetWorkcentre()
   */
  const move = () => {
    if (userEvent && changeWorkcentre) {
      dispatch(stopUserEventAPI(userEvent, true, false, false));//true serve per resetWokcentre
      dispatch(resetStateWorkcentres());
      dispatch(resetStateMachines());
    } else if (!userEvent && changeWorkcentre) {
      dispatch(resetWorkcentre());
      //ricarico macchine e workcentre dal db per confrontarli con quelli nel local storage
      dispatch(resetStateWorkcentres());
      dispatch(resetStateMachines());
    }
  };
  const changeOdp = async () => {
    if (userEvent) {
      dispatch(stopUserEventAPI(userEvent, false, true, false));
    }
    if (!modeStapling && process && process.status.id < 4 && operatorUnmount) {
      await dispatch(unmountProcessAPI(odp, process, operator, machine));
    } else if (modeStapling && odp && odp.status.id < 4 && operatorUnmount) {
      await dispatch(unmountStaplingAPI(odp, operator, machine));
    }
    dispatch(resetOdp());
    dispatch(getMachineOdpsAPI(machine.id, operator.id, modeMaintenance, '', 0, configuration.opViewOdpListSize, false, configuration.enableInsertExtCode));
  }
  //toglie l'operatore dalla lista
  const handleLogout = () => {
    dispatch(resetOperator({ value: true }));
    dispatch(showSuccess("function.operationSuccess"));
    dispatch(resetStateWorkcentres());
    dispatch(resetStateMachines());
    dispatch(resetStateUsers());
    dispatch(resetStateRoles());
  };

  return (
    <React.Fragment>
      <Paper className={customClasses.leftPaper}>
        <AppBar position="static">
          <Tabs value={tabIndex} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
            <Tab label={customLabel("operatorView.summary")} key={"summary"} value={0} />
            <Tab label={customLabel("operatorView.documents")} key={"documents"} value={1} />
          </Tabs >
        </AppBar >
        <div style={{ maxHeight: "680px", overflow: "auto" }}>
          {tabIndex === 0 ? (
            <Table size={"small"}>
              <tbody>
                <TableRow>
                  <TableCell>
                    {customLabel("settings.workstationName")}:<br />{workstation !== "" ? <b>{workstation}</b>
                      : <b>{customLabel("settings.notSet").toUpperCase()}</b>}
                  </TableCell>
                </TableRow>
                {operator !== false ? (
                  <TableRow>
                    <TableCell>
                      {customLabel("operatorView.operator")}:<br /><b>{operator.name}</b>
                    </TableCell>
                  </TableRow>
                ) : null}
                {operator !== false ? (
                  <TableRow>
                    <TableCell align="center">
                      <Button disabled={loading || (!operatorUnmount && (!modeStapling && process && process.status.id === 1)) || (!operatorUnmount && (modeStapling && odp && odp.status.id === 1)) || serverNotResponding ? true : false ||
                        (!operatorUnmount && machineEvent && (!machineEvent.type.systemEvent || machineEvent.type.name === MACHINE_EVENT_OFF))}
                        fullWidth className={customClasses.actionButton} variant="contained" color="primary" onClick={() => changeOdp()}>
                        {!modeMaintenance ? customLabel("button.changeOdp") : customLabel("button.changeManutention")}
                      </Button>
                      {changeWorkcentre ?
                        <Button disabled={loading || serverNotResponding} fullWidth className={customClasses.actionButton} variant="contained" color="primary" onClick={() => move()}>
                          {customLabel("button.changeWorkstation")}
                        </Button>
                        : null
                      }
                      <Button disabled={loading} fullWidth className={customClasses.actionButton} variant="contained" color="secondary" onClick={() => handleLogout()}>
                        {customLabel("button.exit")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : null}
                {workcentre ? (
                  <TableRow>
                    <TableCell>{customLabel("workcentre.workcentre")}:<br /><b>{renderWorkcentreLabel(configuration, workcentre)}</b></TableCell>
                  </TableRow>
                ) : null}
                {machine ? (
                  <TableRow>
                    <TableCell>{customLabel("machine.machine")}:<br /><b>{renderMachineLabel(configuration, machine)}</b></TableCell>
                  </TableRow>
                ) : null}
                {odp && odp.customerOrder && !modeMaintenance && !modeStapling ? (
                  <TableRow>
                    <TableCell>{customLabel("customerOrder.customerOrder")}:<br /><b>{odp.customerOrder.code}</b></TableCell>
                  </TableRow>
                ) : null}
                {odp && odp.customer && !modeMaintenance && configuration.showCustomerInterface && !modeStapling ? (
                  <TableRow>
                    <TableCell>{customLabel("customer.customer")}:<br /><b>{odp.customer.name}</b></TableCell>
                  </TableRow>
                ) : null}
                {odp && !modeStapling ? (
                  <TableRow>
                    <TableCell>
                      {!modeMaintenance ? customLabel("odp.odp") : customLabel("maintenance.maintenance")}:
                      <br />
                      {!modeMaintenance && odp.extCode && odp.extCode !== "" && odp.extCode !== null ? (
                        <b>{odp.code + " (" + odp.extCode + ")"}</b>
                      ) : !modeMaintenance ? (
                        <b>{odp.code}</b>
                      ) :
                        <b>{odp.maintenanceType && odp.maintenanceType.name !== EXTRAORDINARY ?
                          odp.maintenanceType.name
                          :
                          customLabel("maintenance.extraordinary").toUpperCase() + " - " + moment(odp.deliveryDate).format(bishopFormats.L)}</b>}
                    </TableCell>
                  </TableRow>
                ) : null}
                {modeStapling ? (
                  <TableRow>
                    <TableCell>{customLabel("stapling.stapling")}:<br />{odp ? <b>{odp.code}</b> : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}</TableCell>
                  </TableRow>
                ) : null}
                {index === INDEX_INTERFACE && configuration.showUserEvents ? (
                  <TableRow>
                    <TableCell>{customLabel("userEvent.userEvent")}:<br />{userEvent !== false ? <b>{userEvent.type.name}</b> :
                      <b className={customClasses.alarmColor}>{customLabel("function.notAvailable").toUpperCase()}</b>
                    }</TableCell>
                  </TableRow>
                ) : null}
                {userEvent && index === INDEX_INTERFACE && configuration.showUserEvents ? (
                  <TableRow>
                    <TableCell align="center">
                      <Button className={customClasses.actionButton} variant="contained" fullWidth color="secondary"
                        disabled={(!modeStapling && process.status.id === null) || loading || (modeStapling && odp.status.id === null) || serverNotResponding ? true : null}
                        onClick={() => stopUserEvent()}>{customLabel("button.conclude")}</Button>
                    </TableCell>
                  </TableRow>
                ) : null}
                {!userEvent && index === INDEX_INTERFACE && configuration.showUserEvents ? (
                  <TableRow>
                    <TableCell align="center">
                      <Button className={customClasses.actionButton} fullWidth variant="contained" color="primary"
                        disabled={loading || (!modeStapling && (process === false || process.status.id === null || process.status.id === 4))
                          || (modeStapling && (odp === false || odp.status.id === null || odp.status.id === 4)) || serverNotResponding ? true : false}
                        onClick={() => enableUserEventType()}>{customLabel("button.initiate")}</Button>
                    </TableCell>
                  </TableRow>
                ) : null}
              </tbody>
            </Table>
          ) : tabIndex === 1 && !modeStapling ?
            <OperatorDocPage />
            : tabIndex === 1 && modeStapling ?
              <OperatorDocStapling />
              : null}
        </div>
      </Paper>
    </React.Fragment>
  );
}

export default CurrentSelection;