import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  customersSelector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete,
} from "./slice";

import { Box } from "@material-ui/core";
import { Form } from "./Form";
import { Details } from "./Details";

const CustomerPage = ({ match }) => {
  const dispatch = useDispatch();
  const { customer, customeredit, editmode, deleting, isCreated, isDeleted, customers, loading } = useSelector(customersSelector);

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    dispatch(change({ name: name, value: value }));
  };

  const toggleDelete = (event) => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(customeredit));
  };

  const onEdit = () => {
    dispatch(edit());
  };
  const doDelete = () => {
    dispatch(deleteAPI(customer));
  };

  const save = () => {
    if (customer.id === "new") {
      dispatch(createAPI(customeredit));
    } else {
      dispatch(updateAPI(customeredit));
    }
  };

  useEffect(() => {
    const { id } = match.params;
    if (id !== "new") {
      dispatch(getAPI(id));
    } else {
      dispatch(create());
    }
  }, [dispatch, match]);

  const rendercustomer = () => {
    if (customer !== false && !editmode && match.params.id === customer.id.toString())
      return (
        <div>
          <Details customer={customer} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting}
            doDelete={doDelete} loading={loading}
          />
        </div>
      );
    if (customer !== false && editmode)
      return (
        <Form customeredit={customeredit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save}
          customers={customers} loading={loading}
        />
      );
    return;
  };

  if (isDeleted && match.params.id === customer.id.toString()) {
    return <Redirect to={"/customers/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/customers/" + customer.id} />;
  }

  return <Box>{rendercustomer()}</Box>;
};

export default CustomerPage;
