import React, { useRef, useEffect } from 'react';
import {
    TableCell, TableRow, TextField, Input, IconButton, Typography, InputLabel, FormControl
} from '@material-ui/core';
import { customLabel } from '../../../../utility/customLabel';
import moment from "moment";
import { bishopFormats } from '../../../../../constants';
import { mesStyles } from '../../../../utility/ultrafabStyles';
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import PaperAutocomplete from '../../../../utility/PaperAutocomplete';

/**
 * 
 * @param {object} customFilter
 * @param {array} customers
 * @param {array} articles  
 * @param {array} suppliers false se il filtro non è richiesto
 * @param {array} workcentres false se il filtro non è richiesto
 * @param {array} machines false se il filtro non è richiesto
 * @param {function} onChangeFilter  
 * @param {function} onChangeFilterAutocomplete
 * @param {function} handleChangeDate
 * @param {function} handleResetDate  
 * @param {array} colSpan indica la colspan per ogni cella delle 5, e dell'ultima, solo se maggiore di 0
 * @param {string} typeStatus tipo stati odp
 * @param {boolean} showDeliveryFilter mostra filtro su data consegna
 * @param {boolean} showStartDateFilter mostra filtro su data inizio
 * @param {boolean} showEndDateFilter mostra filtro su data fine
 * @param {boolean} showSuppliersFilter mostra filtro su fornitori, in questo caso il param suppliers dev essere un array
 * @param {boolean} showWorkcentresFilter mostrare filtro centro lavooro, su seconda riga, in questo caso workcentres dev essere array
 * @param {boolean} showMachinesFilter mostrare filtro macchina, su seconda riga, in questo caso machines dev essere array
 * @returns 
 */
const FilterRow = ({ customFilter, customers, articles, suppliers, workcentres, machines, onChangeFilter, onChangeFilterAutocomplete, handleChangeDate, handleResetDate, colSpan, typeStatus,
    showDeliveryFilter, showStartDateFilter, showEndDateFilter, showSuppliersFilter, showWorkcentresFilter, showMachinesFilter, enableReload }) => {
    const myClasses = mesStyles();
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            let reloadTimeout = setTimeout(() => {
                enableReload()
            }, 500)
            return () => {
                clearTimeout(reloadTimeout)
            }
        }
    }, [customFilter, enableReload])

    if (!customFilter.showFilter) {
        return null
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell colSpan={colSpan[0]}>
                    <FormControl>
                        <InputLabel htmlFor="code">{customLabel("odp.odp")}</InputLabel>
                        <Input name="code" style={{ marginTop: "16px" }} value={customFilter.code ? customFilter.code : ""} onChange={(e) => onChangeFilter(e, typeStatus)} />
                    </FormControl>
                </TableCell>
                <TableCell colSpan={colSpan[1]}>
                    <FormControl>
                        <InputLabel htmlFor="customerOrder">{customLabel("odp.customerOrder")}</InputLabel>
                        <Input name="customerOrder" style={{ marginTop: "16px" }} value={customFilter.customerOrder ? customFilter.customerOrder : ""} onChange={(e) => onChangeFilter(e, typeStatus)} />
                    </FormControl>
                </TableCell>
                <TableCell colSpan={colSpan[2]}>
                    {customers ? (
                        <Autocomplete
                            PaperComponent={PaperAutocomplete}
                            options={customers} filterSelectedOptions size="medium"
                            renderOption={(option) => (
                                <Typography style={{ fontSize: "14px" }}>{option.code + " " + option.name}</Typography>
                            )}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value, reason) => onChangeFilterAutocomplete(event, value, reason, "customer", typeStatus)}
                            value={customFilter.customer ? customFilter.customer : null}
                            getOptionLabel={(option) => option.code + " " + option.name}
                            id="customerAutocomplete"
                            renderInput={(params) => (
                                <TextField
                                    {...params} label={customLabel("customer.customer")} />)}
                        />
                    ) : null}
                </TableCell>
                <TableCell colSpan={colSpan[3]}>
                    {articles ? (
                        <Autocomplete
                            PaperComponent={PaperAutocomplete}
                            options={articles}
                            filterSelectedOptions
                            size="medium"
                            renderOption={(option) => (
                                <Typography style={{ fontSize: "14px" }}>{option.code + " " + option.name}</Typography>
                            )}
                            value={customFilter.article ? customFilter.article : null}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value, reason) => onChangeFilterAutocomplete(event, value, reason, "article", typeStatus)}
                            getOptionLabel={(option) => option.code + " " + option.name}
                            id="articleAutocomplete"
                            renderInput={(params) => (<TextField classes={{ root: myClasses.placeHolderAuto }}
                                {...params} label={customLabel("article.article")} />)}
                        />
                    ) : null}
                </TableCell>
                <TableCell colSpan={colSpan[4]} className={myClasses.datePickerTableLabel}>
                    {showDeliveryFilter ? (
                        <React.Fragment>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} >
                                <DatePicker
                                    className={myClasses.datePickerTableLabel}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton size="small" style={{ padding: 0 }} onClick={(e) => handleResetDate(e, "deliveryDateFrom", typeStatus)}>
                                                <CloseIcon />
                                            </IconButton>
                                        )
                                    }}
                                    disableToolbar
                                    allowKeyboardControl={false}
                                    variant="inline"
                                    format={bishopFormats.L}
                                    autoOk
                                    onChange={(date) => handleChangeDate("deliveryDateFrom", date, typeStatus)}
                                    label={customLabel("function.fromDeliveryDate")}
                                    value={customFilter.deliveryDateFrom ? customFilter.deliveryDateFrom : null}
                                />
                            </MuiPickersUtilsProvider>
                            <br />
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <DatePicker
                                    className={myClasses.datePickerTableLabel}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton size="small" style={{ padding: 0 }} onClick={(e) => handleResetDate(e, "deliveryDateTo", typeStatus)}>
                                                <CloseIcon />
                                            </IconButton>
                                        )
                                    }}
                                    disableToolbar
                                    allowKeyboardControl={false}
                                    variant="inline"
                                    format={bishopFormats.L}
                                    autoOk
                                    onChange={(date) => handleChangeDate("deliveryDateTo", date, typeStatus)}
                                    label={customLabel("function.to")}
                                    value={customFilter.deliveryDateTo ? customFilter.deliveryDateTo : null}
                                />
                            </MuiPickersUtilsProvider>
                        </React.Fragment>
                    ) : null}
                </TableCell>
                {showStartDateFilter ? (
                    <TableCell colSpan={colSpan[5]} className={myClasses.datePickerTableLabel}>
                        <React.Fragment>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} >
                                <DatePicker
                                    className={myClasses.datePickerTableLabel}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton size="small" style={{ padding: 0 }} onClick={(e) => handleResetDate(e, "startDateFrom", typeStatus)}>
                                                <CloseIcon />
                                            </IconButton>
                                        )
                                    }}
                                    disableToolbar
                                    allowKeyboardControl={false}
                                    variant="inline"
                                    format={bishopFormats.L}
                                    autoOk
                                    onChange={(date) => handleChangeDate("startDateFrom", date, typeStatus)}
                                    label={customLabel("function.fromStartDate")}
                                    value={customFilter.startDateFrom ? customFilter.startDateFrom : null}
                                />
                            </MuiPickersUtilsProvider>
                            <br />
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <DatePicker
                                    className={myClasses.datePickerTableLabel}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton size="small" style={{ padding: 0 }} onClick={(e) => handleResetDate(e, "startDateTo", typeStatus)}>
                                                <CloseIcon />
                                            </IconButton>
                                        )
                                    }}
                                    disableToolbar
                                    allowKeyboardControl={false}
                                    variant="inline"
                                    format={bishopFormats.L}
                                    autoOk
                                    onChange={(date) => handleChangeDate("startDateTo", date, typeStatus)}
                                    label={customLabel("function.to")}
                                    value={customFilter.startDateTo ? customFilter.startDateTo : null}
                                />
                            </MuiPickersUtilsProvider>
                        </React.Fragment>
                    </TableCell>
                ) : null}
                {showEndDateFilter ? (
                    <TableCell colSpan={colSpan[6]} className={myClasses.datePickerTableLabel}>
                        <React.Fragment>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} >
                                <DatePicker
                                    className={myClasses.datePickerTableLabel}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton size="small" style={{ padding: 0 }} onClick={(e) => handleResetDate(e, "endDateFrom", typeStatus)}>
                                                <CloseIcon />
                                            </IconButton>
                                        )
                                    }}
                                    disableToolbar
                                    allowKeyboardControl={false}
                                    variant="inline"
                                    format={bishopFormats.L}
                                    autoOk
                                    onChange={(date) => handleChangeDate("endDateFrom", date, typeStatus)}
                                    label={customLabel("function.fromEndDate")}
                                    value={customFilter.endDateFrom ? customFilter.endDateFrom : null}
                                />
                            </MuiPickersUtilsProvider>
                            <br />
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <DatePicker
                                    className={myClasses.datePickerTableLabel}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton size="small" style={{ padding: 0 }} onClick={(e) => handleResetDate(e, "endDateTo", typeStatus)}>
                                                <CloseIcon />
                                            </IconButton>
                                        )
                                    }}
                                    disableToolbar
                                    allowKeyboardControl={false}
                                    variant="inline"
                                    format={bishopFormats.L}
                                    autoOk
                                    onChange={(date) => handleChangeDate("endDateTo", date, typeStatus)}
                                    label={customLabel("function.to")}
                                    value={customFilter.endDateTo ? customFilter.endDateTo : null}
                                />
                            </MuiPickersUtilsProvider>
                        </React.Fragment>
                    </TableCell>
                ) : null}
                {suppliers && showSuppliersFilter ? (
                    <TableCell colSpan={colSpan[7]}>
                        <Autocomplete
                            PaperComponent={PaperAutocomplete}
                            options={suppliers}
                            filterSelectedOptions
                            size="medium"
                            renderOption={(option) => (
                                <Typography style={{ fontSize: "14px" }}>{option.code + " " + option.name}</Typography>
                            )}
                            value={customFilter.supplier ? customFilter.supplier : null}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value, reason) => onChangeFilterAutocomplete(event, value, reason, "supplier", typeStatus)}
                            getOptionLabel={(option) => option.code + " " + option.name}
                            id="supplierAutocomplete"
                            renderInput={(params) => (<TextField classes={{ root: myClasses.placeHolderAuto }}
                                {...params} label={customLabel("supplier.supplier")} />)}
                        />
                    </TableCell>
                ) : null}
                {colSpan[8] > 0 ?
                    <TableCell colSpan={colSpan[8]}></TableCell>
                    : null
                }
            </TableRow>
            {showMachinesFilter || showWorkcentresFilter ? (
                <TableRow>
                    {showWorkcentresFilter && workcentres ? (
                        <TableCell colSpan={2}>
                            <Autocomplete
                                PaperComponent={PaperAutocomplete}
                                options={workcentres}
                                filterSelectedOptions
                                size="medium"
                                renderOption={(option) => (
                                    <Typography style={{ fontSize: "14px" }}>{option.code + " " + option.name}</Typography>
                                )}
                                value={customFilter.workcentre ? customFilter.workcentre : null}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, value, reason) => onChangeFilterAutocomplete(event, value, reason, "workcentre", typeStatus)}
                                getOptionLabel={(option) => option.code + " " + option.name}
                                id="workcentreAutocomplete"
                                renderInput={(params) => (<TextField classes={{ root: myClasses.placeHolderAuto }}
                                    {...params} label={customLabel("workcentre.workcentre")} />)}
                            />
                        </TableCell>
                    ) : null}
                    {showMachinesFilter && machines ? (
                        <TableCell colSpan={2}>
                            <Autocomplete
                                PaperComponent={PaperAutocomplete}
                                options={customFilter.workcentre ? customFilter.workcentre.machines : machines}
                                filterSelectedOptions
                                size="medium"
                                renderOption={(option) => (
                                    <Typography style={{ fontSize: "14px" }}>{option.code + " " + option.name}</Typography>
                                )}
                                value={customFilter.machine ? customFilter.machine : null}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, value, reason) => onChangeFilterAutocomplete(event, value, reason, "machine", typeStatus)}
                                getOptionLabel={(option) => option.code + " " + option.name}
                                id="machineAutocomplete"
                                renderInput={(params) => (<TextField classes={{ root: myClasses.placeHolderAuto }}
                                    {...params} label={customLabel("machine.machine")} />)}
                            />
                        </TableCell>
                    ) : null}
                </TableRow>)
                : null}
        </React.Fragment>
    )
}

export default FilterRow
