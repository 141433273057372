import {
    Box, Checkbox,
    FormControl,
    FormControlLabel, Input,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import React from "react";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";


const General = ({ configuration }) => {
    const myClasses = mesStyles();

    return (
        <Box className={myClasses.confDetailsBox}>
            <form className={myClasses.formContainer} noValidate autoComplete="off">
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="decimals">{customLabel("configuration.decimals")}</InputLabel>
                    <Input value={configuration.decimals} />
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="dataRetentionCloseOdpDays">{customLabel("configuration.dataRetentionCloseOdpDays")}</InputLabel>
                    <Input value={configuration.dataRetentionCloseOdpDays} />
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="dataRetentionVarsOpenOdpsDays">{customLabel("configuration.dataRetentionVarsOpenOdpsDays")}</InputLabel>
                    <Input value={configuration.dataRetentionVarsOpenOdpsDays} />
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="dataRetentionDataOpenOdpsDays">{customLabel("configuration.dataRetentionDataOpenOdpsDays")}</InputLabel>
                    <Input value={configuration.dataRetentionDataOpenOdpsDays} />
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="machineEventReasonMode">{customLabel("configuration.machineEventReasonMode.machineEventReasonMode")}</InputLabel>
                    <Select name="machineEventReasonMode" value={configuration.machineEventReasonMode}>
                        <MenuItem value="createNew">{customLabel("configuration.machineEventReasonMode.createNew")}</MenuItem>
                        <MenuItem value="changeExistent">{customLabel("configuration.machineEventReasonMode.changeExistent")}</MenuItem>
                    </Select>
                </FormControl>
                <br />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.showUserEvents} name="showUserEvents" color="default" />
                    }
                    label={customLabel("configuration.showUserEvents")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.singleUserEvent} name="singleUserEvent" color="default" />
                    }
                    label={customLabel("configuration.singleUserEvent")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.showFilters} name="showFilters" color="default" />
                    }
                    label={customLabel("configuration.showFilters")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.showArticleOrder} name="showArticleOrder" color="default" />
                    }
                    label={customLabel("configuration.showArticleOrder")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.showOdpDeliveryTime} name="showOdpDeliveryTime" color="default" />
                    }
                    label={customLabel("configuration.showOdpDeliveryTime")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.enableMaintenance} name="enableMaintenance" color="default" />
                    }
                    label={customLabel("configuration.enableMaintenance")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.enableCheckDialogColor} name="enableCheckDialogColor" color="default" />
                    }
                    label={customLabel("configuration.enableCheckDialogColor")}
                />
            </form>
        </Box>
    );
};
export default General;