import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from '../../utility/customLabel';
import {
    startProcessAPI, stopProcessAPI, pauseProcessAPI, operatorViewSelector, enableFlagCloseChecks,
    enableFlagUserEventType, enableFlagStopProcess, disableFlagStopProcess, enableFlagVars
} from "../slice";
import moment from "moment";
import { bishopFormats } from '../../../constants';
import {
    Paper, Table, Divider, TableRow, TableCell, AppBar, Tab, Tabs, Button, Grid,
    TextField, FormControl
} from "@material-ui/core";
import ChecksDialog from "../checks/ChecksDialog";
import { EXTRAORDINARY } from "../../../constants";
import UserEventDialog from "../events/UserEventDialog";
import ReloadingAPI from "./ReloadingAPI";
import DialogOperator from "../utility/DialogOperator";
import VarsDialog from "../vars/VarsDialog";
import { mainSelector } from "../../main/slice";
import { opViewStyles } from "../utility/customStyles";

const ManageMaintenance = ({ configuration }) => {
    const customClasses = opViewStyles();
    const dispatch = useDispatch();
    const { process, odp, machine, operator, userEventType, machineEvent, closeChecks, errors, loading,
        flagDialog, unmountProcess, vars } = useSelector(operatorViewSelector);
    const { serverNotResponding } = useSelector(mainSelector);
    const [tabIndex, setTabIndex] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    };
    /**
     * Gestisce l'avvio o la ripresa della manutenzione
     */
    const handleAddProcess = () => {
        if (!errors) {
            dispatch(startProcessAPI(odp, process, operator, machine, false));
        }
    };

    /**
     * Gestisce l'apertura dell' evento utente dialog contemporanemente all avvio della lavorazione
     */
    const handleOpenUserEvent = () => {
        dispatch(enableFlagUserEventType(true));
        dispatch(startProcessAPI(odp, process, operator, machine, false));
    }
    /**
     * Gestisce il conferma chiudi lavorazione, se esistono check di chiusura li chiede altrimenti ferma la lavorazione
     */
    const handleCloseProcess = () => {
        if (closeChecks && closeChecks.length > 0) {
            dispatch(enableFlagCloseChecks());
        } else if (!errors) {
            dispatch(stopProcessAPI(odp, process, operator, machine));
        }
    };

    //mettere in pausa la manutenzione 
    const handlePauseProcess = () => {
        if (!errors) {
            dispatch(pauseProcessAPI(odp, process, operator, machine, unmountProcess));
        }
    };

    const renderStopProcessContentDialog = () => {
        return (
            <React.Fragment>
                {customLabel("operatorView.confirmStopMaintenaceType")}
            </React.Fragment>
        )
    };

    const renderStopProcessActionsDialog = () => {
        return (
            <React.Fragment>
                <Button className={customClasses.dialogButton} variant="contained" disabled={loading} onClick={() => handleCloseProcess()} color="primary">{customLabel("button.confirm")}</Button>
                <Button className={customClasses.dialogButton} variant="contained" onClick={() => dispatch(disableFlagStopProcess())} disabled={loading} color="secondary">{customLabel("button.cancel")}</Button>
            </React.Fragment>
        )
    }

    return (
        <Paper className={customClasses.middlePaper}>
            <AppBar position="static">
                <Tabs value={tabIndex} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                    <Tab label={customLabel("maintenance.maintenance")} key={"maintenance"} value={0} />
                </Tabs>
            </AppBar>
            {tabIndex === 0 && process ? (
                <React.Fragment>
                    <Grid container alignItems="flex-start" justify="center">
                        <Grid item xs={8}>
                            <React.Fragment>
                                <h3 className={customClasses.divTitleManagePages}>{customLabel("maintenanceType.processType")}</h3>
                                <Divider />
                            </React.Fragment>
                            <Table size={"small"}>
                                <tbody>
                                    <TableRow>
                                        <TableCell>{customLabel("processType.name")}</TableCell>
                                        <TableCell>{process && process.name === EXTRAORDINARY ? <b>{customLabel("maintenance.extraordinary").toUpperCase()}</b> : process && process.name !== EXTRAORDINARY ?
                                            <b>{process.name}</b> : null}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            {customLabel("maintenance.deliveryDate")}
                                        </TableCell>
                                        <TableCell><b>{process && process.deliveryDate ? moment(process.deliveryDate).format(configuration && configuration.showOdpDeliveryTime ? bishopFormats.LT : bishopFormats.L) : null}</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("process.startDate")}</TableCell>
                                        <TableCell><b>{process && process.startDate ? moment(process.startDate).format(bishopFormats.LTS) : null}</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("process.endDate")}</TableCell>
                                        <TableCell><b>{process && process.endDate ? moment(process.endDate).format(bishopFormats.LTS) : null}</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            {customLabel("maintenance.status")}</TableCell>
                                        <TableCell>{process && process.status.id !== null ? (<b>{process.status.name}</b>) : null}</TableCell>
                                    </TableRow>
                                    {process.note && process.note !== "" && process.note !== "false" ? (
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <FormControl className={customClasses.formNoteManagePages} fullWidth>
                                                    <TextField variant="outlined" name="note" multiline label={customLabel("operatorView.note")}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        value={process && process.note && process.note !== "false" ? process.note : null} rows={8}>
                                                    </TextField>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                </tbody>
                            </Table>
                        </Grid>
                        <Grid item xs={4}>
                            <React.Fragment>
                                <h3 className={customClasses.divTitleManagePages}>{customLabel("maintenanceType.progress")}</h3>
                                <Divider />
                            </React.Fragment>
                            <Table size={"small"}>
                                <tbody>
                                    <TableRow>
                                        <TableCell>
                                            <Button variant="contained" color="primary" className={customClasses.actionButtonManagePages} fullWidth
                                                disabled={loading || process.status.id === 1 || process.status.id === 4 || (machineEvent && !machineEvent.type.systemEvent) || serverNotResponding ? true : false}
                                                onClick={!userEventType && configuration.askUserEvent ? () => handleOpenUserEvent() : () => handleAddProcess()}>
                                                {customLabel("button.start")}
                                            </Button>
                                            <Button variant="contained" color="secondary" className={customClasses.actionButtonManagePages} fullWidth
                                                disabled={loading || process.status.id >= 2 || (machineEvent && !machineEvent.type.systemEvent) || serverNotResponding ? true : false}
                                                onClick={() => handlePauseProcess()}>
                                                {customLabel("button.pause")}
                                            </Button>
                                            <Button variant="contained" color="secondary" className={customClasses.actionButtonManagePages} fullWidth
                                                onClick={() => dispatch(enableFlagStopProcess())}
                                                disabled={loading || (process.status.id >= 4 && process.status.systemStatus) || (machineEvent && !machineEvent.type.systemEvent) || serverNotResponding ? true : false}>
                                                {customLabel("button.stop")}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    {vars && vars.length > 0 ? (
                                        <TableRow>
                                            <TableCell>
                                                {process.type ? (
                                                    <Button variant="contained" color="primary" fullWidth className={customClasses.actionButtonManagePages}
                                                        disabled={loading || process.startDate == null || process.endDate != null || serverNotResponding} onClick={() => dispatch(enableFlagVars())}
                                                    >
                                                        {customLabel("button.declare")}
                                                    </Button>
                                                ) : null}
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                </tbody>
                            </Table>
                        </Grid>
                    </Grid >
                </React.Fragment>
            )
                : null
            }
            {process ? (
                <React.Fragment>
                    <ChecksDialog presetVarCheckValue={null} configuration={configuration} />
                    <VarsDialog configuration={configuration} />
                    <UserEventDialog onConfirm={handleAddProcess} existChecks={false} />
                    <ReloadingAPI configuration={configuration} />
                    <DialogOperator flag={flagDialog.flagStopProcess} title={customLabel("function.confirm")} form={renderStopProcessContentDialog}
                        actions={renderStopProcessActionsDialog} transparentBackround={false} />
                </React.Fragment>
            ) : null}
        </Paper>
    );
}

export default ManageMaintenance;