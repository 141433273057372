import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../../constants";
import { showError, showSuccess } from "../../notifications/slice";
import { logout, refreshAPI } from "../../main/slice";
import { customLabel } from "../../utility/customLabel";
import { customFilter, TAB_INDEX_STATUS, TAB_INDEX_TIMELINE } from "./utility";

export const initialState = {
  loading: false,
  creatingLoading: false,
  hasErrors: false,
  odps: false,
  total: 0,
  reload: false,
  page: 0,
  filter: false,
  toProcess: [],
  tabIndex: TAB_INDEX_TIMELINE //usato per tenere traccia del tab
};

const maintenanceCurrentStatusSlice = createSlice({
  name: "maintenanceCurrentStatus",
  initialState,
  reducers: {
    enableErrors: (state) => {
      state.hasErrors = true;
      state.loading = false;
      state.creatingLoading = false;
    },
    loadingOdp: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.reload = false;
    },
    loadOdpsSuccess: (state, { payload }) => {
      state.loading = false;
      state.odps = payload[1];
      state.total = payload[0].total_entries;
    },
    changeTabIndex: (state, { payload }) => {
      state.tabIndex = payload;
      if (payload === TAB_INDEX_STATUS) {
        state.reload = true;
      }
    },
    setReload: (state) => {
      state.reload = true;
    },
    changeFilter: (state, { payload }) => {
      state.filter[payload.name] = payload.value;
    },
    initFilter: (state, { payload }) => {
      let tempFilter = { ...customFilter };
      tempFilter["showFilter"] = payload;
      state.filter = { ...tempFilter };
      state.toProcess = [];
    },
    changeWork: (state) => {
      state.work = !state.work
    },
    addToProcess: (state, { payload }) => {
      state.toProcess.push(payload)
    },
    removeToProcess: (state, { payload }) => {
      let index = state.toProcess.indexOf(payload);
      if (index > -1) {
        state.toProcess.splice(index, 1);
      }
    },
    sendToProcessSuccess: (state, { payload }) => {
      state.toProcess = []
      for (let x in payload) {
        let index = state.odps.findIndex((o) => {
          return o.id === payload[x].id
        });
        state.odps[index] = payload[x]
      }
    },
    setReloadTimeline: (state) => {
      state.reloadTimeline = true;
    },
    creating: (state) => {
      state.creatingLoading = true;
    },
    createDeadlineSuccess: (state, { payload }) => {
      state.creatingLoading = false;
      state.reloadTimeline = true;
    },
    cancelReloadTimeline: (state) => {
      state.reloadTimeline = false;
    }
  },
});

export const { enableErrors, loadingOdp, loadOdpsSuccess, setReload, initFilter, changeFilter, changeWork, addToProcess, removeToProcess, sendToProcessSuccess, creating, createDeadlineSuccess, cancelReloadTimeline, setReloadTimeline,
  changeTabIndex } = maintenanceCurrentStatusSlice.actions;

export const selector = (state) => state.maintenanceCurrentStatus;

export default maintenanceCurrentStatusSlice.reducer;

export function loadMaintenancesAPI(filter) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(loadingOdp());
    let url = SERVER_URL + "/api/maintenance/current?page=" + filter.page + "&per_page=" + filter.rowsPerPage + "&orderBy=" + filter.orderBy + "&order=" + filter.order
    if (filter.maintenanceType) {
      url = url + "&maintenanceType=" + filter.maintenanceType.id
    }
    if (filter.status) {
      url = url + "&status=" + filter.status.id
    }
    if (filter.workcentre) {
      url = url + "&workcentre=" + filter.workcentre.id
    }
    if (filter.machine) {
      url = url + "&machine=" + filter.machine.id
    }
    if (filter.deliveryDateFrom) {
      url = url + "&deliveryDateFrom=" + filter.deliveryDateFrom
    }
    if (filter.deliveryDateTo) {
      url = url + "&deliveryDateTo=" + filter.deliveryDateTo
    }
    if (filter.startDateFrom) {
      url = url + "&startDateFrom=" + filter.startDateFrom
    }
    if (filter.startDateTo) {
      url = url + "&startDateTo=" + filter.startDateTo
    }
    if (filter.endDateFrom) {
      url = url + "&endDateFrom=" + filter.endDateFrom
    }
    if (filter.endDateTo) {
      url = url + "&endDateTo=" + filter.endDateTo
    }

    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(loadOdpsSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function sendToProcessAPI(odps) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(loadingOdp());
    let url = SERVER_URL + "/api/odp/toProcess"
    let params = { odps: odps };
    const response = await fetch(url, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(sendToProcessSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function createDeadlines(from, to) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(creating());
    let params = { from: from, to: to };
    const response = await fetch(SERVER_URL + "/api/maintenance/deadlines", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createDeadlineSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}