import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { odpStatusesSelector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete } from "./slice";

import { Box } from "@material-ui/core";

import { Form } from "./Form";
import { Details } from "./Details";

const OdpStatusPage = ({ match }) => {
  const dispatch = useDispatch();
  const { odpStatus, odpStatusedit, editmode, deleting, isCreated, isDeleted, odpStatuses, loading } = useSelector(odpStatusesSelector);

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    dispatch(change({ name: name, value: value }));
  };

  const toggleDelete = (event) => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(odpStatusedit));
  };

  const onEdit = () => {
    dispatch(edit());
  };
  const doDelete = () => {
    dispatch(deleteAPI(odpStatus));
  };

  const save = () => {
    if (odpStatus.id === "new") {
      dispatch(createAPI(odpStatusedit));
    } else {
      dispatch(updateAPI(odpStatusedit));
    }
  };

  useEffect(() => {
    const { id } = match.params;
    if (id !== "new") {
      dispatch(getAPI(id));
    } else {
      dispatch(create());
    }
  }, [dispatch, match]);

  const renderodpStatus = () => {
    if (
      odpStatus !== false &&
      !editmode &&
      match.params.id === odpStatus.id.toString()
    )
      return (
        <div>
          <Details odpStatus={odpStatus} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting}
            doDelete={doDelete} loading={loading} />
        </div>
      );
    if (odpStatus !== false && editmode)
      return (
        <Form odpStatusedit={odpStatusedit} handleChange={handleChange} cancelEdit={onCancelEdit}
          save={save} odpStatuses={odpStatuses} loading={loading} />
      );
    return;
  };

  if (isDeleted && match.params.id === odpStatus.id.toString()) {
    return <Redirect to={"/admin/odpStatuses/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/admin/odpStatuses/" + odpStatus.id} />;
  }

  return <Box>{renderodpStatus()}</Box>;
};

export default OdpStatusPage;
