import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../../constants";
import { showError, showSuccess } from "../../notifications/slice";
import { logout, refreshAPI } from "../../main/slice";
import { currentList, customFilter } from "./utility";
import { customLabel } from "../../utility/customLabel";

export const initialState = {
  loading: false,
  hasErrors: false,
  odps: false,
  total: 0,
  reload: false,
  toProcess: [],
  loadingStarted: false,
  errorsStarted: false,
  loadingPaused: false,
  errorsPaused: false,
  loadingNotStarted: false,
  errorsNotStarted: false,
  loadingNotScheduled: false,
  errorsNotScheduled: false,
  loadingExternal: false,
  errorsExternal: false,
  loadingClosed: false,
  errorsClosed: false,
  odpsStarted: false,
  odpsPaused: false,
  odpsNotStarted: false,
  odpsNotScheduled: false,
  odpsExternal: false,
  odpsClosed: false,
  startedFilter: false,
  pausedFilter: false,
  notStartedFilter: false,
  notScheduledFilter: false,
  externalFilter: false,
  closedFilter: false,
  customReload: false,
  totalStarted: false,
  totalPaused: false,
  totalNotScheduled: false,
  totalNotStarted: false,
  totalExternal: false,
  totalClosed: false,
  tabIndex: 0,//usato per tenere traccia del tab
};

const odpCurrentStatusSlice = createSlice({
  name: "odpCurrentStatus",
  initialState,
  reducers: {
    enableErrors: (state) => {
      state.hasErrors = true;
      state.loading = false;
    },
    loadingOdp: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.reload = false;
    },
    setReload: (state) => {
      state.reload = true;
    },
    changeCustomFilter: (state, { payload }) => {
      switch (payload.typeStatus) {
        case currentList.started.url: state.startedFilter[payload.name] = payload.value;
          break;
        case currentList.paused.url: state.pausedFilter[payload.name] = payload.value;
          break;
        case currentList.notStarted.url: state.notStartedFilter[payload.name] = payload.value;
          break;
        case currentList.notScheduled.url: state.notScheduledFilter[payload.name] = payload.value;
          break;
        case currentList.external.url: state.externalFilter[payload.name] = payload.value;
          break;
        case currentList.closed.url: state.closedFilter[payload.name] = payload.value;
          break;
        default: return;
      }
    },
    addToProcess: (state, { payload }) => {
      state.toProcess.push(payload)
    },
    removeToProcess: (state, { payload }) => {
      let index = state.toProcess.indexOf(payload);
      if (index > -1) {
        state.toProcess.splice(index, 1);
      }
    },
    singleOdpToWorkSuccess: (state) => {
      state.toProcess = []
      state.loading = false;
      state.hasErrors = false;
    },
    resetToProcess: (state) => {
      state.toProcess = [];
    },
    sendToProcessSuccess: (state, { payload }) => {
      state.toProcess = []
      for (let x in payload) {
        let index = state.odps.findIndex((o) => {
          return o.id === payload[x].id
        });
        state.odps[index] = payload[x]
      }
    },
    /**
     * 
     * @param {int} payload il tabIndex da impostare su ogni click del tab 
     */
    resetOdps: (state, { payload }) => {
      for (let type in currentList) {
        let odpsString = currentList[type]["odps"];
        let totalString = currentList[type]["total"];
        let errorsString = currentList[type]["errors"];
        state[odpsString] = false;
        state[totalString] = false;
        state[errorsString] = false;
        state.tabIndex = payload;
        state.customReload = true;
      }
    },
    enableCustomLoading: (state, { payload }) => {
      state[payload.loading] = true;
      state[payload.errors] = false;
    },
    enableCustomErrors: (state, { payload }) => {
      state[payload.loading] = false;
      state[payload.errors] = true;
      state.customReload = false;
    },
    fillCustomOdps: (state, { payload }) => {
      state[payload.loading] = false;
      state.customReload = false;
      state[payload.total] = payload.data[0].total_entries;
      switch (payload.typeStatus) {
        case currentList.started.url: state.odpsStarted = payload.data[1];
          break;
        case currentList.paused.url: state.odpsPaused = payload.data[1];
          break;
        case currentList.notStarted.url: state.odpsNotStarted = payload.data[1];
          break;
        case currentList.notScheduled.url: state.odpsNotScheduled = payload.data[1];
          break;
        case currentList.external.url: state.odpsExternal = payload.data[1];
          break;
        case currentList.closed.url: state.odpsClosed = payload.data[1];
          break;
        default: return;
      }
    },
    enableCustomReload: (state) => {
      state.customReload = true;
    },
    initCustomFilter: (state, { payload }) => {
      let tempFilter = { ...customFilter };
      tempFilter["showFilter"] = payload.showFilter;
      state[payload.filter] = { ...tempFilter };
    }
  },
});

export const { enableErrors, loadingOdp, setReload, addToProcess, removeToProcess, sendToProcessSuccess,
  fillCustomOdps, resetOdps, enableCustomLoading, enableCustomErrors, resetToProcess, singleOdpToWorkSuccess,
  changeCustomFilter, initCustomFilter, enableCustomReload } = odpCurrentStatusSlice.actions;

export const selector = (state) => state.odpCurrentStatus;

export default odpCurrentStatusSlice.reducer;

export function sendToProcess(odps, currentType, notScheduledFilter) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(loadingOdp());
    let url = SERVER_URL + "/api/odp/toProcess"
    let params = { odps: odps };
    const response = await fetch(url, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(singleOdpToWorkSuccess());
        dispatch(loadCustomOdpsAPI(currentType, notScheduledFilter))
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

/**
 * 
 * @param {object} currentType oggetto contenente url da chiamare, loading e errors per le commesse dello stato desiderato
 * @returns 
 */
export function loadCustomOdpsAPI(currentType, filter) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(enableCustomLoading({ loading: currentType.loading, errors: currentType.errors }));
    let url = SERVER_URL + "/api/odp/current/" + currentType.url + "?page=" + filter.page + "&per_page=" + filter.rowsPerPage;
    if (filter.code) {
      url = url + "&code=" + filter.code
    }
    if (filter.customerOrder) {
      url = url + "&customerOrder=" + filter.customerOrder
    }
    if (filter.customer) {
      url = url + "&customer=" + filter.customer.id
    }
    if (filter.article) {
      url = url + "&article=" + filter.article.id
    }
    if (filter.supplier) {
      url = url + "&supplier=" + filter.supplier.id
    }
    if (filter.deliveryDateFrom) {
      url = url + "&deliveryDateFrom=" + filter.deliveryDateFrom
    }
    if (filter.deliveryDateTo) {
      url = url + "&deliveryDateTo=" + filter.deliveryDateTo
    }
    if (filter.startDateFrom) {
      url = url + "&startDateFrom=" + filter.startDateFrom
    }
    if (filter.startDateTo) {
      url = url + "&startDateTo=" + filter.startDateTo
    }
    if (filter.endDateFrom) {
      url = url + "&endDateFrom=" + filter.endDateFrom
    }
    if (filter.endDateTo) {
      url = url + "&endDateTo=" + filter.endDateTo
    }
    if (filter.workcentre) {
      url = url + "&workcentre=" + filter.workcentre.id
    }
    if (filter.machine) {
      url = url + "&machine=" + filter.machine.id
    }
    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(fillCustomOdps({ data: data, loading: currentType.loading, typeStatus: currentType.url, total: currentType.total }));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableCustomErrors({ loading: currentType.loading, errors: currentType.errors }));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: currentType.loading, errors: currentType.errors }));
    }
  };
}