import React from "react";
import { Link } from "react-router-dom";
import {
  FormControl, InputLabel, Input, Divider, Button, Typography, Grid
} from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import { ChromePicker } from "react-color";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";

export const Form = ({ odpStatusedit, handleChange, cancelEdit, save, loading }) => {
  const myClasses = mesStyles();

  const handleChangeComplete = (color, type) => {
    let c = "rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")";
    let e = { preventDefault: function () { }, persist: function () { } };
    let t = { name: type, value: c };
    e["target"] = t;
    handleChange(e);
  };

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">
          {customLabel("odpStatus.odpStatus")}
        </Typography>
        <LockOpenIcon />
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
          <form className={myClasses.formContainer} noValidate autoComplete="off">
            <FormControl className={myClasses.form50} disabled={odpStatusedit.systemStatus}>
              <InputLabel htmlFor="name">
                {customLabel("odpStatus.name")}
              </InputLabel>
              <Input autoFocus name="name" error={odpStatusedit.name === ""} value={odpStatusedit.name}
                onChange={handleChange} />
            </FormControl>
          </form>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
          <div className={myClasses.formContainer}>
            <FormControl margin="normal" className={myClasses.form50}>
              <InputLabel htmlFor="backgroundColor">
                {customLabel("odpStatus.backgroundColor")}
              </InputLabel>
              <Input name="backgroundColor" value={odpStatusedit.backgroundColor !== false ? odpStatusedit.backgroundColor : ""}
                error={odpStatusedit.backgroundColor === "" || odpStatusedit.backgroundColor === false} onChange={handleChange} />
            </FormControl>
            <ChromePicker color={odpStatusedit.backgroundColor !== false ? odpStatusedit.backgroundColor : "rgba(0,0,0)"}
              onChangeComplete={(color) => handleChangeComplete(color, "backgroundColor")} />
          </div>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: "22px" }} />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple onClick={save} disabled={odpStatusedit.name === "" || odpStatusedit.backgroundColor === "" || !odpStatusedit.backgroundColor || loading} color="primary">
          {customLabel("button.save")}
        </Button>
        {odpStatusedit.id !== "new" ? (
          <Button focusRipple onClick={cancelEdit} disabled={loading}>
            {customLabel("button.cancel")}
          </Button>
        ) : (
          <Button component={Link} to={`/admin/odpStatuses`} disabled={loading}>
            {customLabel("button.back")}
          </Button>
        )}
      </div>
    </PaperForm>
  );
};
