import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../../utility/customLabel";
import MaintenanceStatusPage from './MaintenanceStatusPage';
import MaintenancesTimeline from "./MaintenancesTimeline";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { selector, changeTabIndex } from './slice'
import { TAB_INDEX_STATUS, TAB_INDEX_TIMELINE } from "./utility";

const MaintenancesPage = () => {
    const dispatch = useDispatch();
    const { tabIndex } = useSelector(selector);

    const handleChange = (event, newValue) => {
        dispatch(changeTabIndex(newValue));
    };

    return (
        <React.Fragment>
            <AppBar position="static">
                <Tabs value={tabIndex} onChange={handleChange} style={{ backgroundColor: "white", color: "black" }} variant="scrollable" scrollButtons="auto">
                    <Tab label={customLabel("timeline.timeline") + " " + customLabel("maintenance.maintenances")} value={TAB_INDEX_TIMELINE} />
                    <Tab label={customLabel("bishop.planning") + " " + customLabel("maintenance.maintenances")} value={TAB_INDEX_STATUS} />
                </Tabs>
            </AppBar>
            {tabIndex === TAB_INDEX_TIMELINE ? (
                <MaintenancesTimeline />
            ) : tabIndex === TAB_INDEX_STATUS ?
                <MaintenanceStatusPage />
                : null}
        </React.Fragment >
    );
};

export default MaintenancesPage;
