import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import CheckIcon from "@material-ui/icons/Check";
import LockIcon from '@material-ui/icons/Lock';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";

export const Details = ({ machineEvent, edit, toggleDelete, deleting, doDelete, loading }) => {
  const myClasses = mesStyles();

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">
          {customLabel("machineEvent.machineEvent")}
        </Typography>
        <LockIcon />
      </div>
      <div className={myClasses.tableContainer}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell> {customLabel("machineEvent.name")}</TableCell>
              <TableCell>{machineEvent.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell> {customLabel("odpStatus.odpStatus")}</TableCell>
              <TableCell>{machineEvent.odpStatus.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell> {customLabel("processStatus.processStatus")}</TableCell>
              <TableCell>{machineEvent.processStatus.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell> {customLabel("machineEvent.systemEvent")}</TableCell>
              <TableCell>
                {machineEvent.systemEvent ? <CheckIcon /> : null}
              </TableCell>
            </TableRow>
            <React.Fragment>
              <TableRow>
                <TableCell> {customLabel("machineEvent.excludeFromKPI")}</TableCell>
                <TableCell>
                  {machineEvent.excludeFromKPI ? <CheckIcon /> : null}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> {customLabel("machineEvent.productive")}</TableCell>
                <TableCell>
                  {machineEvent.productive ? <CheckIcon /> : null}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> {customLabel("machineEvent.setup")}</TableCell>
                <TableCell>
                  {machineEvent.setup ? <CheckIcon /> : null}
                </TableCell>
              </TableRow>
            </React.Fragment>
            <TableRow>
              <TableCell>{customLabel("machineEvent.backgroundColor")}</TableCell>
              <TableCell>
                {machineEvent.backgroundColor ? (
                  <div className={myClasses.backgroundColor}
                    style={{ backgroundColor: machineEvent.backgroundColor }}
                  ></div>
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {customLabel("machineEvent.disactiveBackgroundColor")}
              </TableCell>
              <TableCell>
                {machineEvent.disactiveBackgroundColor ? (
                  <div className={myClasses.backgroundColor}
                    style={{ backgroundColor: machineEvent.disactiveBackgroundColor }}
                  ></div>
                ) : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple key={machineEvent.id} onClick={edit} color="primary" disabled={loading}>
          {customLabel("button.edit")}
        </Button>
        <Button color="secondary" onClick={toggleDelete} disabled={machineEvent.systemEvent || loading}>
          {customLabel("button.delete")}
        </Button>
        <Button component={Link} to={`/admin/machineEvents`} disabled={loading}>
          {customLabel("button.back")}
        </Button>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={toggleDelete} disabled={loading} />
    </PaperForm>
  );
};
