import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
    buttonsSelector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete
} from "./slice";
import { Box } from "@material-ui/core";
import { Details } from "./Details";
import { Form } from "./Form";
import RolesPage from './RolesPage'
import ProcessTypesPage from './ProcessTypesPage'

const ButtonPage = ({ match }) => {
    const dispatch = useDispatch();
    const { button, buttonedit, editmode, deleting, isCreated, isDeleted, loading, reload } = useSelector(buttonsSelector);

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (event.target.type === "checkbox") {
            value = event.target.checked
        }
        dispatch(change({ name: name, value: value }));
    };

    const toggleDelete = (event) => {
        dispatch(confirmDelete());
    };

    const onCancelEdit = () => {
        dispatch(cancelEdit(buttonedit));
    };

    const onEdit = () => {
        dispatch(edit());
    };
    const doDelete = () => {
        dispatch(deleteAPI(button));
    };

    const save = () => {
        if (button.id === "new") {
            dispatch(createAPI(buttonedit));
        } else {
            dispatch(updateAPI(buttonedit));
        }
    };

    useEffect(() => {
        const { id } = match.params;
        if (id !== "new") {
            dispatch(getAPI(id));
        } else {
            dispatch(create());
        }
    }, [dispatch, match]);

    useEffect(() => {
        if (reload === true && loading === false) {
            dispatch(getAPI(button.id));
        }
    }, [dispatch, reload, loading, button])

    const renderButton = () => {
        if (button !== false && !editmode && match.params.id === button.id.toString()) {
            return (
                <div>
                    <Details button={button} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete} loading={loading} />
                    <ProcessTypesPage button={button} maintenance={false} loading={loading} />
                    <RolesPage button={button} loading={loading} />
                </div>
            );
        }
        if (button !== false && editmode) {
            return (
                <Form buttonedit={buttonedit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save} loading={loading} />
            );
        }
        return;
    };

    if (isDeleted && match.params.id === button.id.toString()) {
        return <Redirect to={"/admin/buttons/"} />;
    }

    if (isCreated) {
        return <Redirect to={"/admin/buttons/" + button.id} />;
    }

    return <Box>{renderButton()}</Box>;
};

export default ButtonPage;