import React from 'react';
import { useSelector } from "react-redux";
import { customLabel } from '../../utility/customLabel';
import { TableCell, TableRow } from "@material-ui/core";
import { odpsSelector } from '../slice';
import { mesStyles } from '../../utility/ultrafabStyles';

const MachineEvents = ({ odp, p, handleOpenPopover, handleClosePopover }) => {
    const myClasses = mesStyles();
    const { currentProcess, staplingMachineEvent } = useSelector(odpsSelector);

    /**
     * 
     * @param {object} machine oggetto macchina, false se non presente
     * @param {object} machineEvent oggetto evento macchina, false se non presente
     * @param {boolean} stapling booleano per indicare se tipo pinzatura o meno
     * @returns Restituisce una TableRow con macchina, evento macchina e colore legenda
     */
    const renderMachineEvent = (machine, machineEvent, stapling) => {
        return (
            <TableRow>
                <TableCell colSpan={stapling ? 2 : null} style={{ paddingLeft: !stapling ? '5px' : null }}>
                    {customLabel("machine.machine")}: {machine ? machine.name : customLabel("function.notAvailable").toUpperCase()}
                </TableCell>
                <TableCell colSpan={stapling ? 2 : null} style={{ paddingRight: !stapling ? '5px' : null }}>
                    <div className={machineEvent ? myClasses.tableCellEventPlusColor : null}>
                        {customLabel("machineEvent.event")}: {machineEvent && !stapling ? machineEvent.name : machineEvent && stapling ? machineEvent.type.name :
                            customLabel("function.notAvailable").toUpperCase()}
                        {machineEvent ? (
                            <div className={myClasses.legendRectangle} onMouseEnter={(e) => handleOpenPopover(e.currentTarget, false)} onMouseLeave={handleClosePopover}
                                style={{
                                    backgroundColor: machineEvent && !stapling ? machineEvent.backgroundColor :
                                        machineEvent && stapling ? machineEvent.type.backgroundColor : null
                                }}>
                            </div>
                        ) : null}
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    const renderStandardMachineEvents = () => {
        //mostro eventi di currentProcess oppure di eventuali lavorazioni in corso, anche se non current
        if (p && p.stapling.id === null && ((currentProcess !== null && p.id === currentProcess.id) || (p.startDate !== null && p.endDate === null))) {
            return renderMachineEvent(p.machineEvent.id !== null ? p.machineEvent.machine : p.machine.id !== null ? p.machine : false,
                p.machineEvent.id !== null ? p.machineEvent : false, false)
        } else {
            return null
        }
    }

    if (odp && !odp.stapling) {
        return renderStandardMachineEvents()
    } else {
        return renderMachineEvent(staplingMachineEvent && staplingMachineEvent.length !== 0 && staplingMachineEvent.machine.id !== null ?
            staplingMachineEvent.machine : false, staplingMachineEvent && staplingMachineEvent.length !== 0 ? staplingMachineEvent : false, true)
    }
};

export default MachineEvents;
