
import { Button, FormControl, IconButton, Input, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Autocomplete } from "@material-ui/lab";
import _ from 'lodash';
import moment from "moment";
import React from "react";
import { adminCode, bishopFormats, evolutionCode } from '../../constants';
import { renderComponents } from "../components/utility";
import PaperAutocomplete from "../utility/PaperAutocomplete";
import { customLabel } from "../utility/customLabel";

export const START_TRANSLATE = customLabel("processType.check.start").toUpperCase();
export const PAUSE_TRANSLATE = customLabel("processType.check.pause").toUpperCase();
export const RESTART_TRANSLATE = customLabel("processType.check.restart").toUpperCase();
export const CLOSE_TRANSLATE = customLabel("processType.check.close").toUpperCase();
export const ON_DEMAND_TRANSLATE = customLabel("processType.check.onDemand").toUpperCase();

/**
 * Restituisce un elenco di tablerow per mostrare lo storico di una variabile
 * @param {array} checkList 
 * @param {boolean} showOperator 
 * @param {int} decimals
 * @returns tablerows con colonne: data - operatore (parametrizzabile) - valore - note
 */
export const renderCheckDetails = (checkList, showOperator, decimals) => {
    if (checkList && checkList.length > 0) {
        return (
            checkList.map((c) => {
                return (
                    <TableRow key={c.id}>
                        <TableCell>{c.timestamp !== null ? moment(c.timestamp).format(bishopFormats.LTS) : null}</TableCell>
                        {showOperator ? (
                            <TableCell>{c.user.id !== null && c.user.code !== adminCode && c.user.code !== evolutionCode
                                ? c.user.firstName + " " + c.user.lastName : null}
                            </TableCell>
                        ) : null}
                        <TableCell>{(c.type === "boolean" && c.success === true) || (c.type === "component" && c.value === "true") ? <CheckIcon /> :
                            (c.type === "boolean" && c.success === false) || ((c.type === "component" || c.type === "var") && c.value === "false") ? <ClearIcon /> :
                                (c.type === "boolean" && c.success === null) || (c.type === "component" && c.value === null) ? customLabel("function.notAvailable").toUpperCase() :
                                    c.value !== null && c.type === "number" ? Number(c.value).toFixed(decimals) :
                                        c.value !== null ? c.value : customLabel("function.notAvailable").toUpperCase()}
                        </TableCell>
                        <TableCell>{c.notes && c.notes !== "false" && c.notes !== null ? c.notes.substring(0, 20) : null}</TableCell>
                    </TableRow>
                )
            })
        )
    } else {
        return (
            <TableRow>
                <TableCell colSpan={showOperator ? 4 : 3}>{customLabel("function.notAvailable").toUpperCase()}</TableCell>
            </TableRow>
        )
    }
};

/**
 * Restituisce una lista di TableRow restituendo solamente una riga (l'ultima) per ogni check
 * @param {object} odp 
 * @param {boolean} stapling 
 * @param {object} process 
 * @param {array} list lista checks
 * @param {string} when avvia/pausa/ferma/chiusura
 * @param {boolean} showHistoryIcon 
 * @param {boolean} showEditIcon 
 * @param {function} setCheckDetails riceve come parametri odpId, editProcessId, currentCheck, stapling
 * @param {function} onEditCheck 
 * @param {int} decimals
 * @returns elenco di TableRow con colonne: quando, nome, valore, data, operatore, note
 */
export const renderChecksByType = (odp, stapling, process, list, when, showHistoryIcon, showEditIcon, setChecksDetails, onEditCheck, decimals) => {
    const checks = [...list];
    //per ogni checks tengo solamente uno, che deve essere il primo
    let uniqueList = _.uniqBy(checks, 'name');
    if (!uniqueList || uniqueList.length === 0) {
        return (
            <TableRow>
                <TableCell>{when}</TableCell>
                <TableCell colSpan={6}>{customLabel("function.notAvailable").toUpperCase()}</TableCell>
            </TableRow>
        )
    } else {
        return (
            uniqueList.map((check) => {
                return (
                    <TableRow key={check.id}>
                        <TableCell>{when}</TableCell>
                        <TableCell>
                            {check.name}
                        </TableCell>
                        <TableCell>{(check.type === "boolean" && check.success === true) || (check.type === "component" && check.value === "true") ? <CheckIcon /> :
                            (check.type === "boolean" && check.success === false) || ((check.type === "component" || check.type === "var") && check.value === "false") ? <ClearIcon /> :
                                (check.type === "boolean" && check.success === null) || (check.type === "component" && check.value === null) ? customLabel("function.notAvailable").toUpperCase() :
                                    check.value !== null && check.type === "number" ? Number(check.value).toFixed(decimals) :
                                        check.value !== null ? check.value : customLabel("function.notAvailable").toUpperCase()}
                        </TableCell>
                        <TableCell>{check.timestamp !== null ? moment(check.timestamp).format(bishopFormats.LTS) : null}</TableCell>
                        <TableCell>{check.user.id !== null && check.user.code !== adminCode && check.user.code !== evolutionCode
                            ? check.user.firstName + " " + check.user.lastName : null}
                        </TableCell>
                        <TableCell>{check.notes && check.notes !== "false" && check.notes !== null ? check.notes.substring(0, 20) : null}</TableCell>
                        <TableCell>
                            <IconButton size="small" color="primary" disabled={(check.value === null && check.success === null) || !showHistoryIcon}
                                onClick={!stapling ? () => setChecksDetails(odp.id, process.id, check, false) : () => setChecksDetails(odp.id, false, check, true)}>
                                <VisibilityIcon />
                            </IconButton>
                            <IconButton size="small" color="primary" onClick={() => onEditCheck(check)} disabled={check.value !== null || check.success !== null || !showEditIcon}>
                                <EditIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )
            })
        )
    }
}
/**
 * Mostra la lista dei check a rischiesta disponibili per l'inserimento
 * @param {array} checks 
 * @param {function} handleChooseCheck 
 * @returns 
 */
export const renderExtChecksToInsert = (checks, handleChooseCheck) => {
    if (checks && checks.length > 0) {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ padding: "7px" }}>
                            {customLabel("function.name")}
                        </TableCell>
                        <TableCell style={{ padding: "7px" }} align="right">
                            {customLabel("function.arrivalData").toUpperCase()}
                        </TableCell>
                        <TableCell style={{ padding: "7px" }} align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {checks.map((c, i) => {
                        return (
                            <TableRow key={i + "_" + c.name + "_" + c.timeReceived}>
                                <TableCell style={{ padding: "7px" }}>
                                    {c.name}
                                </TableCell>
                                <TableCell style={{ padding: "7px" }} align="right">
                                    {moment(c.timeReceived).format(bishopFormats.LTS)}
                                </TableCell>
                                <TableCell style={{ padding: "7px" }} align="right">
                                    <Button style={{
                                        padding: "15px",
                                        fontSize: "15pt !important"
                                    }} variant={"contained"} color="primary" onClick={(e) => handleChooseCheck(c)}>
                                        {customLabel("button.insert")}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        )
    }
    else {
        return (
            <div>{customLabel("function.notAvailable").toUpperCase()}</div>
        )
    }
};
/**
 * Mostra la lista dei check a rischiesta disponibili per l'inserimento, per ora per manutenzione
 * @param {array} checks 
 * @param {function} handleChooseCheck 
 * @returns 
 */
export const renderListChecksToInsert = (checks, handleChooseCheck) => {
    if (checks && checks.length > 0) {
        return (
            checks.map((c, i) => {
                return (
                    <TableRow key={c.id + "_" + c.name}>
                        <TableCell>
                            {c.name}
                        </TableCell>
                        <TableCell>
                            {c.type === "boolean" && c.success === false ? <ClearIcon /> : c.type === "boolean" && c.success === true ? <CheckIcon /> :
                                c.value !== null && c.value !== "" ? c.value : customLabel("function.notInserted") + "!"}
                        </TableCell>
                        <TableCell>
                            {c.notes && c.notes !== "" && c.notes !== "false" ? c.notes.substr(0, 20) : null}
                        </TableCell>
                        <TableCell>
                            <Button color="primary" variant="contained" onClick={(e) => handleChooseCheck(e, c)}>
                                {customLabel("button.update")}
                            </Button>
                        </TableCell>
                    </TableRow>
                )
            })
        )
    }
    else {
        return (
            <TableRow>
                <TableCell colSpan={4}>
                    {customLabel("function.notAvailable").toUpperCase()}
                </TableCell>
            </TableRow>
        )
    }
};
export const renderTimeCheck = (timestamp) => {
    return (
        <h4 style={{ textAlign: "center" }}>{moment(timestamp).format(bishopFormats.LTS)}</h4>
    )
}
/**
 * Mostra il form di inserimento dei checks, sia normali, sia onDemand (bloccanti o no), a seconda dei parametri currentChecks e blockingCheck
 * @param {object} currentChecks valorizzato se controlli normali o bloccanti, false altrimenti
 * @param {boolean} onDemand per mostrare il timestamp del check on demand ricevuto
 * @param {object} blockingCheck false se controlli bloccanti, valorizzato se controllo scelto dalla lista
 * @param {object} currentValue 
 * @param {function} handleChangeBooleanCheck 
 * @param {function} handleChangeValueText 
 * @param {object} noteChecks 
 * @param {function} handleChangeNoteCheck 
 * @param {boolean} loading 
 * @param {array} vars lista variabili nel caso controllo associato a variabile
 * @param {object} articles se valorizzato verifica se il check collegato a pezzi scarti per mostrare autocomplete articolo scarti pinzatura, se false niente
 * @param {object} article
 * @param {function} onChangeArticle
 * @param {array} staplingList
 * @param {array} components
 * @param {function} declareNewComp
 * @param {function} declareEditComp
 * @param {function} finishDeclaration
 * @returns 
 */
export const renderFormInsertCheck = (currentChecks, onDemand, blockingCheck, currentValue, handleChangeBooleanCheck, handleChangeValueText,
    noteChecks, handleChangeNoteCheck, loading, vars, articles, article, onChangeArticle, staplingList, components, declareNewComp, declareEditComp,
    finishDeclaration, endDeclaration, configuration, processTypeVar, renderProgressBar) => {
    let checkToInsert = false;
    let varCheck = false;
    let showArticle = false;
    if (currentChecks && currentChecks.length > 0) {
        checkToInsert = currentChecks[0];
    }
    if (blockingCheck) {
        checkToInsert = blockingCheck;
    }
    if (vars && checkToInsert.var_name !== "" && checkToInsert.var_name !== null) {
        let indexVar = vars.findIndex((v) => {
            return v.name.toString() === checkToInsert.var_name;
        })
        if (indexVar !== -1) {
            varCheck = vars[indexVar];
        }
    }
    if (varCheck && articles && articles.length > 0 && varCheck.operatorEdit && varCheck.operatorView && staplingList && staplingList.length > 0 && varCheck.name === staplingList[0].type.rejectedPieces) {
        showArticle = true;
    }
    if (checkToInsert && checkToInsert.type !== "component") {
        return (
            <div key={checkToInsert.id}>
                <div style={varCheck.name === processTypeVar ? { display: 'flex', alignItems: 'center', justifyContent: 'space-around' } : null}>
                    <h3 style={{ textAlign: "center" }}>{!varCheck ? checkToInsert.name : checkToInsert.name + " - " + customLabel("operatorView.varInsertionCheck") + ": " + varCheck.name}</h3>
                    {onDemand ? renderTimeCheck(checkToInsert.timeReceived) : null}
                    {varCheck.name === processTypeVar ? renderProgressBar() : null}
                </div>
                <div style={(!varCheck && checkToInsert.type === "boolean") || (varCheck && varCheck.type === "boolean") ?
                    {
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-around"
                    }
                    : {
                        textAlign: "center",
                        marginTop: "20px"
                    }}>
                    {(!varCheck && checkToInsert.type === "boolean") || (varCheck && varCheck.type === "boolean") ? (
                        <React.Fragment>
                            <Button onClick={(e) => handleChangeBooleanCheck(e, true)} color="primary" disabled={loading}
                                variant={currentValue === true ? "contained" : "outlined"}>
                                    {customLabel("function.true")}
                            </Button>
                            <Button onClick={(e) => handleChangeBooleanCheck(e, false)} color="secondary" disabled={loading}
                                variant={currentValue === false ? "contained" : "outlined"}>
                                    {customLabel("function.false")}
                            </Button>
                        </React.Fragment>
                    ) : (!varCheck && checkToInsert.type === "number") || (varCheck && varCheck.type === "number") ? (
                        <Input value={currentValue ? currentValue : ""} error={currentValue === "" || currentValue === null} disabled={loading}
                                type="number" margin="dense" fullWidth name="operator" onChange={handleChangeValueText} autoComplete="off"
                                style={{ backgroundColor: checkToInsert.color ? 'white' : null }}>
                        </Input>
                    ) : <FormControl style={{ width: "100%", marginTop: "16px" }}>
                        <TextField
                            variant="outlined" name="operator" multiline rows={4} value={currentValue ? currentValue : ""}
                            onChange={handleChangeValueText} inputProps={{ maxLength: 250 }} style={{backgroundColor: checkToInsert.color ? 'white' : null}}
                        />
                    </FormControl>
                    }
                </div>
                {showArticle ? (
                    <React.Fragment>
                        <Autocomplete
                            PaperComponent={PaperAutocomplete}
                            disabled={loading}
                            style={{ display: "inline-block", width: "calc(100%)", marginTop: "20px" }}
                            value={article ? article : null}
                            getOptionSelected={(option, value) => article.id !== null && article.id !== '' ? option.id === value.id : null}
                            onChange={(event, value, reason) => onChangeArticle("article.id", value, reason)}
                            options={articles}
                            getOptionLabel={(option) => option.code + " - " + option.name}
                            id="articleAutocomplete"
                            renderInput={(params) => <TextField {...params} label={customLabel("article.article")} error={article ? false : true} />
                            }
                        />
                    </React.Fragment>
                ) : null}
                {(!varCheck && checkToInsert.type !== "string") || (varCheck && varCheck.type !== "string") ? (
                    <FormControl style={{ width: "100%", marginTop: "16px", backgroundColor: checkToInsert.color ? 'white' : null }}>
                        <TextField
                            variant="outlined" name="note" label={customLabel("function.note")} multiline rows={4} value={noteChecks ? noteChecks : ""}
                            onChange={handleChangeNoteCheck}
                        />
                    </FormControl>
                ) : null}
                {varCheck && finishDeclaration !== null ? (
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "16px" }}>
                        <Button color="primary" disabled={loading || endDeclaration || currentValue} variant="contained" onClick={() => finishDeclaration()}>
                            {customLabel("button.finishDeclaration")}
                        </Button>
                    </div>
                ) : null}
            </div>
        )
    }
    if (checkToInsert && checkToInsert.type === "component" && components) {
        return (
            renderComponents(components, declareNewComp, declareEditComp, finishDeclaration, endDeclaration, loading, currentValue, configuration)
        )
    }
};
/**
 * Restituisce la variabile corrispondente associata ad un controllo nel campo varName
 * @param {object} check 
 * @param {array} listVars 
 * @returns restituisce variabile se esiste, false altrimenti
 */
export const getVarCheck = (check, listVars) => {
    let varCheck = false;
    if (listVars && check) {
        let indexVar = listVars.findIndex((v) => {
            return v.name.toString() === check.var_name;
        })
        if (indexVar !== -1) {
            varCheck = listVars[indexVar];
        }
        return varCheck;
    }
}