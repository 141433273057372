import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { customLabel } from "../utility/customLabel";
import {
  articlesSelector, getAPI, updateCycleTimeAPI, selectCycleTime, changeCycleTime, updateOdpsCycleTimeAPI,
  enableFlagReloadCycleTime, disableFlagReloadCycleTime, changeCycleTimeAttr, addCycleTimeAttr, deleteCycleTimeAttr
} from './slice'
import { Typography, IconButton, Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, FormControl, Input } from "@material-ui/core";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from '../utility/DialogConfirmAction';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

const CycleTimesPage = ({ article }) => {
  const dispatch = useDispatch()
  const myClasses = mesStyles();
  const { loading, reload, cycleTime, flagReloadCycleTime } = useSelector(articlesSelector)

  useEffect(() => {
    if (reload === true && loading === false) {
      dispatch(getAPI(article.id));
    }
  }, [dispatch, reload, loading, article]);

  const onEdit = (cycleTime) => {
    dispatch(selectCycleTime(cycleTime))
  }
  const cancelEdit = () => {
    dispatch(selectCycleTime(false))
  }

  const save = () => {
    dispatch(updateCycleTimeAPI(article, cycleTime))
  }

  const onChange = (event, key, item) => {
    let name = event.target.name
    let value = event.target.value
    if (name === "jsonAttributes") {
      dispatch(changeCycleTimeAttr({ value: value, key: key, item: item }));
    } else {
      dispatch(changeCycleTime({ name: name, value: value }));
    }
  };

  const setReloadCycleTime = (c) => {
    dispatch(enableFlagReloadCycleTime(c));
  };

  const unsetReloadCycleTime = (c) => {
    dispatch(disableFlagReloadCycleTime());
  };

  const confirmReloadCycleTime = () => {
    dispatch(updateOdpsCycleTimeAPI(article, cycleTime));
  };

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit"> {customLabel("article.theoreticalCycleTimes")}</Typography>
      </div>
      <div className={myClasses.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{customLabel("process.process")}</TableCell>
              <TableCell>{customLabel("machine.machine")}</TableCell>
              <TableCell>{customLabel("article.recipe")}</TableCell>
              <TableCell>{customLabel("article.recipeNo")}</TableCell>
              <TableCell>{customLabel("article.jsonAttributes")}</TableCell>
              <TableCell align="right">{customLabel("article.theoreticalCycleTime")} (s/{article.measure})</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {article.cycleTimes.map(c => {
              let jsonAtt = JSON.parse(c.jsonAttributes)
              return (
                <TableRow key={c.id}>
                  <TableCell>{c.processType.name}</TableCell>
                  <TableCell>{c.machine.name}</TableCell>
                  <TableCell>{c.recipe !== null ? c.recipe : null}</TableCell>
                  <TableCell>{c.recipeNo !== null ? c.recipeNo : null}</TableCell>
                  <TableCell>{c.jsonAttributes !== null ? <> {
                    Object.keys(jsonAtt).map(j => { return <span>{j + ": " + jsonAtt[j]}<br /></span> })
                  } </> : null}</TableCell>
                  <TableCell align="right">{c.cycleTime} s</TableCell>
                  <TableCell className={myClasses.tableCell2Buttons}>
                    <Button onClick={() => { onEdit(c) }} color="primary" disabled={loading}>{customLabel("button.edit")}</Button>
                    <Button onClick={() => setReloadCycleTime(c)} color="default" disabled={loading}>{customLabel("button.reload")}</Button>
                  </TableCell>
                </TableRow>
              )
            })
            }
          </TableBody>
        </Table>
        {cycleTime !== false && flagReloadCycleTime === false ? (
          <Dialog open={cycleTime !== false && flagReloadCycleTime === false} keepMounted maxWidth={"md"} fullWidth={true}>
            <DialogTitle>{customLabel("article.theoreticalCycleTime")} - {cycleTime.processType.name} - {cycleTime.machine.name}</DialogTitle>
            <DialogContent>
              <form className={myClasses.dialogForm} noValidate autoComplete="off">
                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="cycleTime">{customLabel("article.theoreticalCycleTime")} ({article.measure}/s)</InputLabel>
                  <Input name="cycleTime" value={cycleTime && cycleTime.cycleTime !== null ? cycleTime.cycleTime : ""} autoFocus onChange={onChange} type="number"
                    error={cycleTime.cycleTime === "" || cycleTime.cycleTime < 0} />
                </FormControl>
                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="recipe">{customLabel("article.recipe")}</InputLabel>
                  <Input name="recipe" value={cycleTime.recipe !== null ? cycleTime.recipe : ""} onChange={onChange}
                  />
                </FormControl>
                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="recipeNo">{customLabel("article.recipeNo")}</InputLabel>
                  <Input name="recipeNo" value={cycleTime.recipeNo !== null ? cycleTime.recipeNo : ""} onChange={onChange} type="number"
                  />
                </FormControl><br /><br />
                <InputLabel htmlFor="jsonAttributes">{customLabel("article.jsonAttributes")}</InputLabel>
                {cycleTime.jsonAtt !== null ?
                  <Table>
                    <TableRow>
                      <TableCell>{customLabel("function.name")}</TableCell>
                      <TableCell>{customLabel("function.value")}</TableCell>
                      <TableCell><IconButton onClick={() => dispatch(addCycleTimeAttr())}><AddIcon fontSize="small" /></IconButton></TableCell>
                    </TableRow>
                    {Object.keys(cycleTime.jsonAtt).map((j, i) => {
                      return <TableRow>
                        <TableCell>
                          <Input name="jsonAttributes" value={j} onChange={(e) => onChange(e, i, 'key')} type="string" />
                        </TableCell>
                        <TableCell>
                          <Input name="jsonAttributes" value={cycleTime.jsonAtt[j]} onChange={(e) => onChange(e, i, 'val')} type="numeric" />
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={(e) => dispatch(deleteCycleTimeAttr(i))}><ClearIcon fontSize="small" /></IconButton>
                        </TableCell>
                      </TableRow>
                    })}
                  </Table> : null}
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={save} color="primary" disabled={cycleTime.cycleTime === null || cycleTime.cycleTime < 0 || loading} >{customLabel("button.save")}</Button>
              <Button onClick={cancelEdit} color="default" disabled={loading}>{customLabel("button.close")}</Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </div>
      <DialogConfirmAction flag={flagReloadCycleTime && cycleTime} text={customLabel("article.confirmUpdatingOdpsCycleTime")} confirm={confirmReloadCycleTime} cancel={unsetReloadCycleTime} disabled={loading} />
    </PaperForm >
  )
}

export default CycleTimesPage
