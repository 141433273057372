import React from "react";
import {
    Typography, FormControl, InputLabel, Input, TextField, Card, CardMedia,
    CardActionArea, Grid, CardContent, Box
} from "@material-ui/core";
import { customLabel } from "../../utility/customLabel";
import { makeStyles } from "@material-ui/core/styles";
import { SERVER_URL } from "../../../constants";
import { mesStyles } from "../../utility/ultrafabStyles";

const useStyles = makeStyles((theme) => ({
    logo: {
        width: "300px",
        height: "200px"
    },
    card: {
        maxWidth: "345px",
        minHeight: "350px",
        margin: theme.spacing(2),
    },
    buttonImage: {
        display: "flex",
        justifyContent: "space-between"
    },
    buttonTopMargin: {
        marginTop: theme.spacing(1),
    }
}));

const Company = ({ configuration }) => {
    const classes = useStyles();
    const myClasses = mesStyles();

    return (
        <Box>
            <Grid container size="small" className={myClasses.confDetailsBox}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <form className={myClasses.formContainer} noValidate autoComplete="off">
                        <FormControl disabled className={myClasses.form100}>
                            <InputLabel htmlFor="name">{customLabel("configuration.nameCompany")}</InputLabel>
                            <Input value={configuration.name} />
                        </FormControl>
                        <FormControl disabled className={myClasses.form100}>
                            <TextField
                                disabled variant="outlined" name="note" multiline label={customLabel("function.note")} rows={4}
                                value={configuration.note}
                            />
                        </FormControl>
                    </form>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} align="center">
                    <Card className={classes.card}>
                        <CardActionArea>
                            <CardMedia
                                className={classes.logo}
                                image={SERVER_URL + "/configuration/logo?" + new Date().getTime()}
                                title="logo"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {customLabel("configuration.logo")}
                                </Typography>
                                <InputLabel>
                                    {configuration.logo !== null ? configuration.logo : customLabel("document.noFileSelected").toUpperCase()}
                                </InputLabel>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};
export default Company;