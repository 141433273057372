import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { Checkbox, FormControlLabel, Typography, FormControl, InputLabel, Input, Divider, Button } from '@material-ui/core'
import { customLabel } from "../utility/customLabel";
import LockIcon from '@material-ui/icons/Lock';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";
import { configurationSelector } from '../configuration/slice';

export const Details = ({ processType, edit, toggleDelete, deleting, doDelete, maintenance, loading }) => {

  const myClasses = mesStyles();
  const { configuration } = useSelector(configurationSelector);

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit"> {customLabel(maintenance ? "maintenancePhase.maintenancePhase" : "processType.processType")}</Typography>
        <LockIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl disabled className={myClasses.form50}>
          <InputLabel htmlFor="code">{customLabel("processType.code")}</InputLabel>
          <Input value={processType.code} />
        </FormControl>
        <FormControl disabled className={myClasses.form50}>
          <InputLabel htmlFor="name">{customLabel("processType.name")}</InputLabel>
          <Input value={processType.name} />
        </FormControl>
        {!maintenance ? (
          <React.Fragment>
            <FormControl disabled className={myClasses.form50}>
              <InputLabel htmlFor="validPieces">{customLabel("processType.validPieces")}</InputLabel>
              <Input value={processType.validPieces && processType.validPieces !== null ? processType.validPieces : ""} />
            </FormControl>
            <FormControl disabled className={myClasses.form50}>
              <InputLabel htmlFor="rejectedPieces">{customLabel("processType.rejectedPieces")}</InputLabel>
              <Input value={processType.rejectedPieces && processType.rejectedPieces !== null ? processType.rejectedPieces : ""} />
            </FormControl>
            <FormControl disabled className={myClasses.form50}>
              <InputLabel htmlFor="totalPieces">{customLabel("processType.totalPieces")}</InputLabel>
              <Input value={processType.totalPieces && process.totalPieces !== null ? processType.totalPieces : ""} />
            </FormControl>
            <br />
            <FormControlLabel disabled control={<Checkbox checked={processType.multiple} color="default" />} label={customLabel("processType.multiple")} />
            <FormControlLabel disabled control={<Checkbox checked={processType.autostart} color="default" />} label={customLabel("processType.autostart")} />
            <FormControlLabel disabled control={<Checkbox checked={processType.external} color="default" />} label={customLabel("processType.external")} />
            {configuration.enableComponents ? (
              <FormControlLabel disabled control={<Checkbox checked={processType.autoComponentConsumption} color="default" />} label={customLabel("processType.autoComponentConsumption")} />
            ) : null}
          </React.Fragment>
        ) : null}
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple key={processType.id} onClick={edit} color="primary" disabled={loading}>{customLabel("button.edit")}</Button>
        <Button color="secondary" onClick={toggleDelete} disabled={loading}>{customLabel("button.delete")}</Button>
        <Button component={Link} to={maintenance ? `/admin/maintenancePhases` : `/admin/processTypes`} disabled={loading}>{customLabel("button.back")}</Button>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={toggleDelete} disabled={loading} />
    </PaperForm>
  )
}
