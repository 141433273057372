import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Paper, Table, TableCell, TableRow,
    TextField
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { bishopFormats } from '../../../constants';
import { configurationSelector } from '../../configuration/slice';
import { mainSelector } from "../../main/slice";
import { processTypesSelector } from '../../processTypes/slice';
import { suppliersSelector } from '../../suppliers/slice';
import ButtonProcessStapling from '../../utility/ButtonProcessStapling';
import DialogConfirmAction from "../../utility/DialogConfirmAction";
import PaperAutocomplete from '../../utility/PaperAutocomplete';
import { customLabel } from '../../utility/customLabel';
import { mesStyles } from '../../utility/ultrafabStyles';
import { calcRemainingTime, secondsToHours } from '../../utility/vari';
import { workcentresSelector } from "../../workcentres/slice";
import {
    odpsSelector,
    onChangeProcess,
    selectProcess,
    setSupplierFlag,
    startProcessAPI,
    unSetSupplierFlag,
    updateProcessAPI
} from "../slice";
import ProcessForm from "./ProcessForm";

const Details = ({ loading, odp, editProcess, process, restoreProcessAPI, reopenProcessAPI }) => {
    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { supplierFlag } = useSelector(odpsSelector);
    const { workcentres } = useSelector(workcentresSelector);
    const { suppliers } = useSelector(suppliersSelector);
    const { currentUser } = useSelector(mainSelector);
    const [currentSupplier, setCurrentSupplier] = React.useState(false);
    const [updateDialog, setUpdateDialog] = React.useState(false);
    const [currentMachineId, setCurrentMachineId] = React.useState(false);
    const [reopenFlag, setReopenFlag] = React.useState(false);
    const [restoreFlag, setRestoreFlag] = React.useState(false);
    const [restartFlag, setRestartFlag] = React.useState(false);
    const { configuration } = useSelector(configurationSelector);
    const [isSerial, setIsSerial] = React.useState(false)
    const [isProcessTypeSerial, setIsProcessTypeSerial] = React.useState(false)
    const [quantityAlert, setQuantityAlert] = React.useState(false)
    const [bigQuantityAlert, setBigQuantityAlert] = React.useState(false)
    const [processTypeVars, setProcessTypeVars] = React.useState({})
    const { processTypes } = useSelector(processTypesSelector)

    useEffect(() => {
        if (processTypes) {
            for (let i = 0; i < processTypes.length; i++) {
                if (processTypes[i].code === editProcess.type.code) {
                    setProcessTypeVars({
                        totalPieces: processTypes[i].totalPieces,
                        validPieces: processTypes[i].validPieces,
                        rejectedPieces: processTypes[i].rejectedPieces
                    })
                }
            }
        }
    }, [processTypes, editProcess])

    //dialog modifica del process
    const openUpdateDialog = (process) => {
        setUpdateDialog(true);
        dispatch(selectProcess(process))
        if (processTypes) {
            for (let i = 0; i < processTypes.length; i++) {
                if (processTypes[i].vars && (process.type.id === processTypes[i].id)) {
                    for (let j = 0; j < processTypes[i].vars.length; j++) {
                        if (processTypes[i].vars[j].serial === true) {
                            setIsProcessTypeSerial(true)
                        }
                    }
                }
            }
        }
        for (let i = 0; i < process.vars.length; i++) {
            if (process.vars[i].serial === true) {
                setIsSerial(true)
            }
        }
    };

    const handleChange = (event) => {
        let name = event.target.name;
        let value;
        if ((name === 'quantity') && (event.target.value !== editProcess.quantity.toString()) && isSerial) {
            setQuantityAlert(true)
        } else {
            setQuantityAlert(false)
        }
        if (name === 'quantity' && event.target.value > 99 && isProcessTypeSerial) {
            setBigQuantityAlert(true)
        } else {
            setBigQuantityAlert(false)
        }
        if (event.target.type === "checkbox") {
            value = event.target.checked;
        } else if ((name === "quantity" || name === "expectedSetupTime") && event.target.value !== "") {
            value = event.target.valueAsNumber;
        }
        else if (name === "expectedSetupTime" && event.target.value === "") {
            value = null;
        } else {
            value = event.target.value;
        }
        dispatch(onChangeProcess({ name: name, value: value }));
    };

    const closeUpdateDialog = () => {
        setUpdateDialog(false);
        dispatch(selectProcess(false))
        setQuantityAlert(false)
        setIsProcessTypeSerial(false)
        setBigQuantityAlert(false)
    };
    //salva il process con le modifiche effettuate
    const saveProcess = () => {
        dispatch(updateProcessAPI(odp, process));
    };
    //dialog avvia esternamente con fornitore
    const openSupplierDialog = () => {
        dispatch(setSupplierFlag());
    };
    const closeSupplierDialog = () => {
        dispatch(unSetSupplierFlag());
        setCurrentSupplier(false);
    };
    const settingRestart = (machineId) => {
        setRestartFlag(true);
        setCurrentMachineId(machineId);
    };
    const confirmRestart = () => {
        dispatch(startProcessAPI(odp.id, editProcess.id, currentUser.username, currentMachineId));
        setRestartFlag(false);
        setCurrentMachineId(false);
    };
    const onChangeFilterAutocomplete = (name, value, reason) => {
        if (reason === "clear") {
            setCurrentSupplier(false)
        } else {
            setCurrentSupplier(value)
        }
    };
    const renderSuppliers = () => {
        if (suppliers && suppliers.length > 0) {
            return (
                <Autocomplete
                    PaperComponent={PaperAutocomplete}
                    disabled={!suppliers}
                    fullWidth
                    style={{ display: "inline-block" }}
                    value={currentSupplier ? currentSupplier : null}
                    getOptionSelected={(option, value) => currentSupplier ? option.id === value.id : null}
                    onChange={(event, value, reason) => onChangeFilterAutocomplete("supplier", value, reason)}
                    options={suppliers ? suppliers : []}
                    getOptionLabel={(option) => option.name}
                    id="supplierAutocomplete"
                    renderInput={(params) => <TextField error={!currentSupplier}
                        {...params} label={customLabel("supplier.supplier")} />
                    }
                />
            )
        } else {
            return (
                <React.Fragment>
                    {customLabel("function.notAvailable").toUpperCase()}
                </React.Fragment>
            )
        }
    };
    //metodi di conferma per compiere azioni sulle lavorazioni
    const settingReopen = () => {
        setReopenFlag(true);
    };
    const confirmReopen = () => {
        dispatch(reopenProcessAPI(odp.id, editProcess.id));
        setReopenFlag(false);
        setCurrentMachineId(false);
    };
    const settingRestore = () => {
        setRestoreFlag(true);
    };
    const confirmRestore = () => {
        dispatch(restoreProcessAPI(odp.id, editProcess.id));
        setRestoreFlag(false);
        setCurrentMachineId(false);
    };
    const existNextProcessOpen = () => {
        let nextOpen = false;
        if (odp && odp.processes && odp.processes.length > 0) {
            let processIndex = odp.processes.findIndex((o) => {
                return o.id === editProcess.id
            });
            odp.processes.forEach((p, currentIndex) => {
                if ((currentIndex > processIndex) && p.startDate !== null) {
                    nextOpen = true;
                }
            })
        }
        return nextOpen;
    };

    const cancelActions = () => {
        setReopenFlag(false);
        setRestoreFlag(false);
        setRestartFlag(false);
        setCurrentMachineId(false);
    };

    return (
        <React.Fragment>
            <Paper className={myClasses.processDetailsPaper}>
                {editProcess.stapling.id !== null ? (
                    <React.Fragment>
                        <ButtonProcessStapling message={customLabel("process.processInStapling").toUpperCase()} stapling={editProcess.stapling} />
                        <Divider />
                    </React.Fragment>
                ) : null}
                <div className={myClasses.buttonsFlexRight}>
                    {!odp.maintenance ? (
                        <Button onClick={() => openUpdateDialog(editProcess)} color="primary" disabled={loading || editProcess.stapling.id !== null || editProcess.status.id === 4}>
                            {customLabel("button.edit")}
                        </Button>
                    ) : null}
                    {editProcess.external && editProcess.startDate === null ? (
                        <Button color="primary" disabled={loading || editProcess.status.id !== 5 || !editProcess.available || editProcess.stapling.id !== null}
                            onClick={() => openSupplierDialog()}>
                            {customLabel("button.externalStart")}
                        </Button>
                    ) : editProcess.external && editProcess.startDate !== null ? (
                        <Button color="primary" disabled={loading || editProcess.status.id === 4 || editProcess.status.id === 1 || editProcess.stapling.id !== null}
                            onClick={() => settingRestart(editProcess.machineEvent.machine.id)}>
                            {customLabel("button.start")}
                        </Button>
                    ) : null}
                    <Button color="primary" disabled={loading || editProcess.startDate === null || editProcess.endDate === null || (odp.lockProcesses && existNextProcessOpen()) || editProcess.stapling.id !== null}
                        onClick={() => settingReopen()}>
                        {customLabel("button.reopen")}
                    </Button>
                    <Button color="primary" disabled={loading || editProcess.startDate === null || (odp.lockProcesses && existNextProcessOpen()) || editProcess.stapling.id !== null}
                        onClick={() => settingRestore()}>
                        {customLabel("button.restore")}
                    </Button>
                </div>
                <Divider />
                <Table size="small">
                    <tbody>
                        {!odp.maintenance ? (
                            <TableRow>
                                <TableCell>{customLabel("processType.processType")}</TableCell>
                                <TableCell colSpan={2}>
                                    {!editProcess.external ? customLabel("processType.internal") : customLabel("processType.external")}
                                </TableCell>
                            </TableRow>
                        ) : null}
                        {editProcess.external ? (
                            <TableRow>
                                <TableCell>{customLabel("supplier.supplier")}</TableCell>
                                <TableCell colSpan={2}>
                                    {editProcess.machineEvent.machine.supplier.id !== null ? editProcess.machineEvent.machine.supplier.name
                                        : editProcess.machine.name}
                                </TableCell>
                            </TableRow>
                        ) : null}
                        <TableRow>
                            <TableCell>{customLabel("odp.startDate")}</TableCell>
                            <TableCell colSpan={2}>{editProcess.startDate !== null ? moment(editProcess.startDate).format(bishopFormats.LTS) : null}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{customLabel("odp.endDate")}</TableCell>
                            <TableCell colSpan={2}>{editProcess.endDate !== null && editProcess.endDate !== null ? moment(editProcess.endDate).format(bishopFormats.LTS) : null}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{customLabel(odp.maintenance ? "maintenance.status" : "processStatus.processStatus")}</TableCell>
                            <TableCell colSpan={2}>{editProcess.status.id !== null ? editProcess.status.name : null}</TableCell>
                        </TableRow>
                        {!editProcess.external && !odp.maintenance ? (
                            <React.Fragment>
                                <TableRow>
                                    <TableCell>{customLabel("workcentre.workcentre")}</TableCell>
                                    <TableCell colSpan={2}>
                                        {editProcess.workcentre.id !== null ? editProcess.workcentre.code + "-" + editProcess.workcentre.name : null}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{customLabel("machine.machine")}</TableCell>
                                    <TableCell colSpan={2}>
                                        {editProcess.machine.id !== null ? editProcess.machine.code + "-" + editProcess.machine.name : null}
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ) : null}
                        {!odp.maintenance ? (
                            <React.Fragment>
                                <TableRow>
                                    <TableCell>{customLabel("process.quantity")}</TableCell>
                                    <TableCell colSpan={2}>{editProcess.quantity !== null ? editProcess.quantity.toFixed(configuration.decimals) : null}</TableCell>
                                </TableRow>
                                {
                                    processTypeVars?.totalPieces && (
                                        <TableRow>
                                            <TableCell>{customLabel("processType.totalPieces")}</TableCell>
                                            <TableCell colSpan={2}>{editProcess.totalPieces !== null ? Number(editProcess.totalPieces).toFixed(configuration.decimals) : (0).toFixed(configuration.decimals)}</TableCell>
                                        </TableRow>
                                    )
                                }
                                {
                                    processTypeVars?.validPieces && (
                                        <TableRow>
                                            <TableCell>{customLabel("processType.validPieces")}</TableCell>
                                            <TableCell colSpan={2}>{editProcess.validPieces !== null ? Number(editProcess.validPieces).toFixed(configuration.decimals) : (0).toFixed(configuration.decimals)}</TableCell>
                                        </TableRow>
                                    )
                                }
                                {
                                    processTypeVars?.rejectedPieces && (
                                        <TableRow>
                                            <TableCell>{customLabel("processType.rejectedPieces")}</TableCell>
                                            <TableCell colSpan={2}>{editProcess.rejectedPieces !== null ? Number(editProcess.rejectedPieces).toFixed(configuration.decimals) : (0).toFixed(configuration.decimals)}</TableCell>
                                        </TableRow>
                                    )
                                }
                                <TableRow>
                                    <TableCell>{customLabel("process.theoreticalCycleTime")} s/{editProcess.article !== null ? editProcess.article.measure : null}</TableCell>
                                    <TableCell colSpan={2}>{editProcess.cycleTime !== null ? editProcess.cycleTime.cycleTime : null}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{customLabel("process.expectedRunTime")}</TableCell>
                                    <TableCell colSpan={2}>{editProcess.expectedRunTime !== null ? secondsToHours(editProcess.expectedRunTime) : null}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{customLabel("process.expectedSetupTime")}</TableCell>
                                    <TableCell colSpan={2}>{editProcess.expectedSetupTime !== null ? secondsToHours(editProcess.expectedSetupTime) : null}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{customLabel("process.remainingTime")}</TableCell>
                                    <TableCell colSpan={2}>{calcRemainingTime(editProcess.cycleTime ? editProcess.cycleTime.cycleTime : null, editProcess.validPieces, editProcess.totalPieces, editProcess.quantity, true)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{customLabel("process.priority")}</TableCell>
                                    <TableCell colSpan={2}>{editProcess.priority !== null && editProcess.priority < 10 ? editProcess.priority + 1 : customLabel("process.priorityNotSet").toUpperCase()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {customLabel("function.note")}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined" name="note" disabled multiline rows={2} value={editProcess.note ? editProcess.note : ""}
                                            />
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {customLabel("function.operatorViewNote")}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined" name="note2" disabled multiline rows={2} value={editProcess.note2 ? editProcess.note2 : ""}
                                            />
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {customLabel("function.odpListNote")}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined" name="note3" disabled multiline rows={2} value={editProcess.note3 ? editProcess.note3 : ""}
                                            />
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ) : null}
                    </tbody>
                </Table>
            </Paper>
            {process && updateDialog ? (
                <Dialog open={true} keepMounted maxWidth={"lg"} fullWidth>
                    <DialogTitle>{odp.code} - {customLabel("process.process")}: {process.name}</DialogTitle>
                    <ProcessForm process={process} handleChange={handleChange} workcentres={workcentres}
                        onChangeProcess={onChangeProcess} quantityAlert={quantityAlert} bigQuantityAlert={bigQuantityAlert} lockProcesses={odp.bom.id !== null ? odp.bom.lockProcesses : true} />
                    <DialogActions>
                        <Button onClick={saveProcess} color="primary" disabled={loading || process.quantity < 0 || process.quantity === "" || process.cycleTimeEdit < 0 || process.cycleTimeEdit === ""}>
                            {customLabel("button.save")}
                        </Button>
                        <Button onClick={() => closeUpdateDialog()} color="default" disabled={loading}>{customLabel("button.close")}</Button>
                    </DialogActions>
                </Dialog>
            ) : null}

            {/*mostrare elenco fornitori con il quale avviare la lavorazione*/}
            {
                supplierFlag ? (
                    <Dialog open={supplierFlag} keepMounted maxWidth={"sm"} fullWidth={true}>
                        <DialogTitle>{customLabel("processType.chooseSupplier")}</DialogTitle>
                        <DialogContent>
                            {renderSuppliers()}
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={!currentSupplier || loading}
                                onClick={() => dispatch(startProcessAPI(odp.id, editProcess.id, currentUser.username, currentSupplier.machine.id))} color="primary">
                                {customLabel("button.startProcess")}
                            </Button>
                            <Button onClick={() => closeSupplierDialog()} color="default" disabled={loading}>
                                {customLabel("button.cancel")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                ) : null
            }
            <DialogConfirmAction text={customLabel("odp.confirmRestart")} flag={restartFlag} confirm={confirmRestart}
                cancel={cancelActions} disabled={loading} />
            <DialogConfirmAction text={customLabel(!odp.maintenance ? "odp.confirmRestore" : "maintenance.confirmRestore")} flag={restoreFlag} confirm={confirmRestore}
                cancel={cancelActions} disabled={loading} />
            <DialogConfirmAction text={customLabel(!odp.maintenance ? "odp.confirmReopen" : "maintenance.confirmReopen")} flag={reopenFlag} confirm={confirmReopen}
                cancel={cancelActions} disabled={loading} />
        </React.Fragment >
    )
};

export default Details;
