import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockIcon from '@material-ui/icons/Lock';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";

export const Details = ({ userEvent, edit, toggleDelete, deleting, doDelete, loading }) => {
  const myClasses = mesStyles();

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">{customLabel("userEvent.userEvent")}</Typography>
        <LockIcon />
      </div>
      <div className={myClasses.tableContainer}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell> {customLabel("userEvent.name")}</TableCell>
              <TableCell>{userEvent.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell> {customLabel("role.roles")}</TableCell>
              <TableCell>
                {userEvent.roles !== false ? (
                  <span>
                    {userEvent.roles.map((role, index) => {
                      return (<span key={role.id}>{role.authority} {index === userEvent.roles.length - 1 ? "" : ", "}</span>);
                    })}
                  </span>
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{customLabel("userEvent.backgroundColor")}</TableCell>
              <TableCell>
                {userEvent.backgroundColor !== null && userEvent.backgroundColor !== false ? (
                  <div className={myClasses.backgroundColor} style={{ backgroundColor: userEvent.backgroundColor }}></div>
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{customLabel("userEvent.disactiveBackgroundColor")}</TableCell>
              <TableCell>
                {userEvent.disactiveBackgroundColor !== null && userEvent.disactiveBackgroundColor !== false ? (
                  <div className={myClasses.backgroundColor} style={{ backgroundColor: userEvent.disactiveBackgroundColor }}></div>
                ) : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple key={userEvent.id} onClick={edit} color="primary" disabled={loading}>{customLabel("button.edit")}</Button>
        <Button color="secondary" onClick={toggleDelete} disabled={loading}>{customLabel("button.delete")}</Button>
        <Button component={Link} to={`/admin/userEvents`} disabled={loading}>{customLabel("button.back")}</Button>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={toggleDelete} disabled={loading} />
    </PaperForm>
  );
};
