import {
    Button, Table,
    TableBody, TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from "classnames";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bishopFormats } from '../../../constants';
import { customLabel } from '../../utility/customLabel';
import { secondsToDhms } from "../../utility/vari";
import TableVarsDetails from "../../vars/TableVarsDetails";
import {
    changeFilter as changeVarsFilter,
    getListMachineVarsByNameAPI,
    getListVarsByNameAPI,
    getListVarsByTypeAPI,
    resetState as resetStateVars,
    setVarToSee, setReload as setVarsReload,
    varsSelector
} from '../../vars/slice';
import { renderAlertCausalize } from "../../vars/utility";
import { getProcessVarsAPI, getStaplingVarsAPI, operatorViewSelector } from "../slice";
import DialogOperator from "../utility/DialogOperator";
import { opViewStyles } from "../utility/customStyles";

const RealTimeDetails = ({ configuration }) => {
    const customClasses = opViewStyles();
    const dispatch = useDispatch();
    const { varToSee, varList, results: varsResults, filter: varsFilter, reload: varsReload, loading: varsLoading } = useSelector(varsSelector);
    const { vars, process, oee_efficiency, machineVars, modeStapling, odp, machine } = useSelector(operatorViewSelector);

    const settingVarToSee = (odpId, editProcessId, cv, machine, stapling, machineId) => {
        let serialNo = false;
        if (cv.serial) {
            serialNo = cv.serialNo
        }
        if (!stapling) {
            dispatch(getProcessVarsAPI(process));
        }
        if (stapling && odp) {
            dispatch(getStaplingVarsAPI(odp))
        }
        dispatch(setVarToSee({ var: cv, odpId: odpId, processId: editProcessId, serialNo: serialNo, machine: machine, stapling: stapling, machineId: machineId }));
        dispatch(setVarsReload());
    };

    const unSetVarsDetails = () => {
        dispatch(resetStateVars());
    };
    const handleChangeRowsPerPage = (event) => {
        dispatch(changeVarsFilter({ name: "rowsPerPage", value: parseInt(event.target.value, 10) }))
        dispatch(changeVarsFilter({ name: "page", value: 0 }))
        dispatch(setVarsReload());
    };
    const handleChangePage = (event, newPage, doReload) => {
        dispatch(changeVarsFilter({ name: "page", value: newPage }))
        if (doReload) {
            dispatch(setVarsReload());
        }
    };
    useEffect(() => {
        if (varsReload && !varsFilter.machine) {
            if (varToSee && varToSee.varDef && varToSee.varDef.id !== null) {
                dispatch(getListVarsByTypeAPI(varsFilter));
            } else {
                dispatch(getListVarsByNameAPI(varsFilter));
            }
        } else if (varsReload && varsFilter.machine) {
            dispatch(getListMachineVarsByNameAPI(varsFilter));
        }
    }, [dispatch, varsReload, varsFilter, varToSee]);

    const renderVars = () => {
        if (vars && vars.length > 0) {
            return (
                vars.map((v) => {
                    return (
                        <TableRow key={v.name + "_" + v.id} style={{ cursor: "pointer" }}
                            onClick={modeStapling ? () => settingVarToSee(odp.id, false, v, false, true) : () => settingVarToSee(odp.id, process.id, v, false, false)}>
                            <TableCell className={customClasses.tableCellBreak140}>
                                {v.serial ? v.name + " (" + v.serialNo + ")" : v.name}
                            </TableCell>
                            <TableCell className={customClasses.tableCellBreak100}>
                                {v.type === "boolean" && v.value === "false" ? <ClearIcon /> : v.type === "boolean" && v.value === "true" ? <CheckIcon /> :
                                    v.value !== null && v.type === "string" ? v.value : v.value !== null && v.type === "number" ? <div style={{display: 'flex', alignItems: 'center'}}>{Number(v.value.toFixed(configuration.decimals))}{renderAlertCausalize(v,vars)?<WarningIcon color={"secondary"} style={{marginLeft: '5px', marginBottom: '7px'}} /> :null}</div> :
                                        v.value !== null && v.type === "timer" ? secondsToDhms(v.value) :
                                            "-"}
                            </TableCell>
                            <TableCell align="right" className={customClasses.tableCellBreak110}>
                                {v.timestamp !== null ? moment(v.timestamp).format(bishopFormats.L) : null}
                                <br />
                                {v.timestamp !== null ? moment(v.timestamp).format(bishopFormats.H) : null}
                            </TableCell>
                        </TableRow>
                    )
                })
            )
        }
        else return (
            <TableRow >
                <TableCell colSpan={5}>
                    {customLabel("function.notAvailable").toUpperCase()}
                </TableCell>
            </TableRow>
        );
    };
    const renderMachineVars = () => {
        if (machineVars && machineVars.length > 0) {
            return (
                machineVars.map((v) => {
                    if (v.operatorView) {
                        return (
                            <TableRow key={v.name + "_" + v.id} style={{ cursor: "pointer" }}
                                onClick={modeStapling ? () => settingVarToSee(odp.id, false, v, true, true, machine.id) : () => settingVarToSee(odp.id, process.id, v, true, false, machine.id)} >
                                <TableCell className={customClasses.tableCellBreak140}>
                                    {v.serial ? v.name + " (" + v.serialNo + ")" : v.name}
                                </TableCell>
                                <TableCell className={customClasses.tableCellBreak100}>
                                    {v.type === "boolean" && v.value === "false" ? <ClearIcon /> : v.type === "boolean" && v.value === "true" ? <CheckIcon /> :
                                        v.value !== null && v.type === "string" ? v.value : v.value !== null && v.type === "number" ? Number(v.value).toFixed(configuration.decimals) :
                                            v.value !== null && v.type === "timer" ? secondsToDhms(v.value) : "-"}
                                </TableCell>
                                <TableCell align="right" className={customClasses.tableCellBreak110}>
                                    {v.timestamp !== null ? moment(v.timestamp).format(bishopFormats.L) : null}
                                    <br />
                                    {v.timestamp !== null ? moment(v.timestamp).format(bishopFormats.H) : null}
                                </TableCell>
                            </TableRow>
                        )
                    } else {
                        return null;
                    }
                })
            )
        }
        else return (
            <TableRow >
                <TableCell colSpan={5}>
                    {customLabel("function.notAvailable").toUpperCase()}
                </TableCell>
            </TableRow>
        );
    };

    const renderHistoricalVarsContent = () => {
        return (
            <TableVarsDetails varList={varList} varToSee={varToSee} page={varsFilter.page}
                setPage={handleChangePage} rowsPerPage={varsFilter.rowsPerPage} setRowsPerPage={handleChangeRowsPerPage} results={varsResults}
                showOperator={false} decimals={configuration.decimals} loading={varsLoading} />
        )
    }
    const renderHistoricalVarsActions = () => {
        return (
            <Button disabled={varsLoading} className={customClasses.dialogButton} onClick={() => unSetVarsDetails()} variant="contained" color="secondary">{customLabel("button.close")}</Button>
        )
    }

    return (
        <React.Fragment>
            <div>
                {process.cycleTime ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={customClasses.tableCellBreak140}>
                                    {customLabel("operatorView.realCycleTime").toUpperCase()} S/{process.article.measure.toUpperCase()}
                                </TableCell>
                                <TableCell className={customClasses.tableCellBreak210}>
                                    {customLabel("operatorView.theoreticalCycleTime").toUpperCase()} S/{process.article.measure.toUpperCase()}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ borderWidth: "3px" }}
                                    className={classNames({ [customClasses.okCTColor]: oee_efficiency && (oee_efficiency.real_cycle_time) < process.cycleTime.cycleTime, [customClasses.koCTColor]: oee_efficiency && oee_efficiency.real_cycle_time >= process.cycleTime.cycleTime })}>
                                    {oee_efficiency ? oee_efficiency.real_cycle_time.toFixed(4) : null}
                                </TableCell>
                                <TableCell style={{ borderWidth: "3px" }} className={customClasses.tableCellBreak210}>{process.cycleTime.cycleTime}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    : null}
                <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={customClasses.tableCellBreak140}>
                                    {customLabel("processType.var.processTypeVar").toUpperCase()}
                                </TableCell>
                                <TableCell className={customClasses.tableCellBreak100}>
                                    {customLabel("function.value")}
                                </TableCell>
                                <TableCell align="right" className={customClasses.tableCellBreak110}>
                                    {customLabel("function.time")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderVars()}
                        </TableBody>
                    </Table>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={customClasses.tableCellBreak140}>
                                    {customLabel("machine.machineVar").toUpperCase()}
                                </TableCell>
                                <TableCell className={customClasses.tableCellBreak100}>
                                    {customLabel("function.value")}
                                </TableCell>
                                <TableCell align="right" className={customClasses.tableCellBreak110}>
                                    {customLabel("function.time")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderMachineVars()}
                        </TableBody>
                    </Table>
                </div>
            </div>
            {/*Dialog per mostrare lo storico variabili */}
            <DialogOperator flag={varToSee ? true : false}
                title={`${customLabel("function.historicalVariable")}: ${varToSee ? varToSee.name : ""} ${varToSee && varToSee.serial ? " - " + varToSee.serialNo : ""}  ${varToSee && varToSee.measure !== "" && varToSee.measure !== null ? " (" + varToSee.measure + ")" : ""}`}
                form={() => renderHistoricalVarsContent()}
                actions={() => renderHistoricalVarsActions()} transparentBackround={false} />
        </React.Fragment>
    );
};
export default RealTimeDetails;