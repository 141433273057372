import React from 'react'
import { FormControl, InputLabel, Input, FormControlLabel, Checkbox, MenuItem, Select } from '@material-ui/core'
import { customLabel } from "../utility/customLabel";
import { mesStyles } from '../utility/ultrafabStyles';

export const Form = ({ editmachine, handleChange, workcentres }) => {
  const myClasses = mesStyles();

  if (editmachine) {
    return (
      <form className={myClasses.dialogFormCascadea} noValidate autoComplete="off">
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="code">{customLabel("machine.code")}</InputLabel>
          <Input autoFocus name="code" error={editmachine.code === ""} value={editmachine.code} onChange={handleChange} />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="name">{customLabel("machine.name")}</InputLabel>
          <Input name="name" error={editmachine.name === ""} value={editmachine.name} onChange={handleChange} />
        </FormControl>
        {editmachine && editmachine.id !== "new" ? (
          <FormControl margin="normal" fullWidth>
            <InputLabel>{customLabel("workcentre.workcentre")}</InputLabel>
            <Select name="workcentre" value={editmachine && editmachine.workcentre && editmachine.workcentre.id !== null ? editmachine.workcentre.id : ""}
              disabled={!workcentres}
              onChange={handleChange}>
              {workcentres ?
                workcentres.map(w => {
                  return <MenuItem key={w.id} value={w.id}>{w.code}-{w.name}</MenuItem>
                }) : null}
            </Select>
          </FormControl>
        ) : null}
        <FormControl margin="normal">
          <FormControlLabel labelPlacement="end"
            control={
              <Checkbox checked={editmachine.external} onChange={handleChange} name="external" color="default" />
            }
            label={customLabel("machine.external")}
          />
        </FormControl>
        <FormControl margin="normal">
          <FormControlLabel labelPlacement="end"
            control={
              <Checkbox checked={editmachine.closeUserEvent} onChange={handleChange} name="closeUserEvent" color="default" />
            }
            label={customLabel("machine.closeUserEventOnPause")}
          />
        </FormControl>
        <FormControl margin="normal">
          <FormControlLabel labelPlacement="end"
            control={
              <Checkbox checked={editmachine.stapling} onChange={handleChange} name="stapling" color="default" />
            }
            label={customLabel("machine.stapling")}
          />
        </FormControl>
      </form>
    )
  }
  return null
}
