import React from "react";
import { Autocomplete } from "@material-ui/lab"
import { customLabel } from "../utility/customLabel";
import { Button, Divider, TextField, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PaperAutocomplete from "../utility/PaperAutocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  paper: {
    marginTop: theme.spacing(),
    padding: theme.spacing()
  },
  buttonContainer: {
    textAlign: "right",
    marginTop: theme.spacing(2)
  },
  form: {
    width: "calc(25% - 32px)",
  },
  note: {
    width: "calc(100% - 32px)",
  },
  button: {
    padding: theme.spacing(),
    margin: theme.spacing(),
    fontSize: "15pt !important"
  },
  buttonNM: {
    padding: theme.spacing(),
    fontSize: "15pt !important"
  }
}));
export const ExtraordinaryForm = ({ onChange, onChangeFilterAutocomplete, onCancelCreate, save, machines, odp, selectCheck, onAddCheck }) => {

  const classes = useStyles();

  return (
    <div>
      <form className={classes.root} noValidate autoComplete="off">
        {machines ? (
          <Autocomplete
            PaperComponent={PaperAutocomplete}
            style={{ display: "inline-block" }}
            className={classes.form}
            value={odp.machine.id !== "" ? odp.machine : null}
            getOptionSelected={(option, value) => odp.machine.id !== '' ? option.id === value.id : null}
            onChange={(event, value, reason) => onChangeFilterAutocomplete("machine.id", value, reason)}
            options={machines}
            getOptionLabel={(option) => option.code + " - " + option.name}
            id="machineAutocomplete"
            renderInput={(params) => <TextField {...params} label={customLabel("maintenance.machine")} error={odp.machine.id === ''} />
            }
          />)
          : null}
        <FormControl className={classes.note}>
          <TextField
            variant="outlined" name="note" label={customLabel("function.note")} multiline rows={4} value={odp.note === null ? "" : odp.note}
            onChange={onChange}
          />
        </FormControl>
      </form>
      <Divider />
      <div className={classes.buttonContainer}>
        <Button className={classes.button} focusRipple variant="contained" onClick={save} color="primary" disabled={odp.machine.id === ""}>{customLabel("button.save")}</Button>
        <Button className={classes.button} focusRipple variant="contained" onClick={onCancelCreate}>{customLabel("button.back")}</Button>
      </div>
    </div>
  )
}