import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { usersSelector, getListAPI, setReload, changeFilter, initFilter } from "./slice";
import TableList from "../utility/TableList";
import { Box, Divider, Typography } from "@material-ui/core";
import { rolesSelector, getListAPI as getRolesAPI } from "../roles/slice";
import { configurationSelector } from "../configuration/slice";
import { useBoolean } from "../customHooks/useBoolean";
import HeaderTableList from "../utility/HeaderTableList";
import { headers } from "./utility";

const UsersPage = () => {
  const dispatch = useDispatch();
  const { users, loading, results, reload, filter } = useSelector(usersSelector);
  const { roles } = useSelector(rolesSelector);
  const { configuration } = useSelector(configurationSelector);
  const [firstFetchDone, { setTrue: setFirstFetchDoneTrue }] = useBoolean(false);

  useEffect(() => {
    if (!filter && configuration) {
      dispatch(initFilter(configuration.showFilters))
    }
  }, [configuration, filter, dispatch])

  useEffect(() => {
    if (filter && !firstFetchDone) {
      dispatch(getListAPI(filter.page, filter.rowsPerPage, filter.orderBy, filter.order, filter.username, filter.code, filter.roles));
      dispatch(getRolesAPI(0, 100000, "authority", "asc", false));
      window.scrollTo(0, 0);
      setFirstFetchDoneTrue();
    }
  }, [dispatch, filter, firstFetchDone, setFirstFetchDoneTrue]);

  if (reload && !loading) {
    dispatch(getListAPI(filter.page, filter.rowsPerPage, filter.orderBy, filter.order, filter.username, filter.code, filter.roles));
  }

  const handleChangePage = (event, newPage, doReload) => {
    dispatch(changeFilter({ name: "page", value: newPage }))
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changeFilter({ name: "rowsPerPage", value: parseInt(event.target.value, 10) }))
    dispatch(changeFilter({ name: "page", value: 0 }))
  };

  const onRequestSort = (property) => {
    const isAsc = filter.orderBy === property && filter.order === "asc"
    let newOrder = isAsc ? "desc" : "asc";
    dispatch(changeFilter({ name: "order", value: newOrder }))
    dispatch(changeFilter({ name: "orderBy", value: property }))
  };

  const changeFilters = (name, value) => {
    dispatch(changeFilter({ name: name, value: value }))
  };

  const resetFilter = () => {
    dispatch(initFilter(true));
  };

  const toggleFilter = () => {
    if (!filter.showFilter) {
      dispatch(initFilter(true));
    } else {
      dispatch(initFilter(false));
    }
  }

  const applyReload = useCallback(() => {
    dispatch(setReload());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Typography component="h1" variant="h6" color="inherit">
        {customLabel("user.users")}
      </Typography>
      <HeaderTableList filter={filter} toggleFilter={toggleFilter} showAdd={true} businessObject={"admin/users"} />
      <Divider />
      <Box>
        {users && filter ? (
          <TableList items={users} headers={headers} mainObject={"user."} redirectObject={"admin/users"}
            setPage={handleChangePage} setRowsPerPage={handleChangeRowsPerPage} results={results}
            onRequestSort={onRequestSort} usernameList={false} codeList={false}
            changeFilters={changeFilters} resetFilter={resetFilter} rolesList={roles} filter={filter} applyReload={applyReload} />
        ) : null}
        {loading ? <div>{customLabel("function.loading").toUpperCase()}</div> : null}
      </Box>
    </React.Fragment>
  );
};

export default UsersPage;
