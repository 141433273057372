import React from "react";
import { Link } from "react-router-dom";
import {
  FormControl, InputLabel, Input, Divider, Button, TextField, Grid, FormControlLabel, Checkbox, Typography, LinearProgress, Select, MenuItem
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab"
import { customLabel } from "../utility/customLabel";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { bishopFormats } from '../../constants';
import FormProcessVars from "./FormProcessVars";
import { mesStyles } from "../utility/ultrafabStyles";
import PaperAutocomplete from "../utility/PaperAutocomplete";

const FormOdp = ({ odpedit, handleChange, handleChangeDate, handleChangeObject, cancelEdit, save, boms,
  fromCustomerOrder, articles, loading, adminVars, resetOdpsState, saveFromOrder, waitForLoadingObjects, quantityAlert, bigQuantityAlert }) => {

  const myClasses = mesStyles();
  const onChangeFilterAutocomplete = (name, value, reason) => {
    if (reason === "clear") {
      handleChangeObject(name, false)
    } else {
      handleChangeObject(name, value.id)
    }
  }

  const renderProcessVars = () => {
    if (odpedit && odpedit.id === "new" && odpedit.bom.id !== "new" && adminVars && adminVars.length > 0) {
      return (
        <React.Fragment>
          <FormProcessVars adminVars={adminVars} loading={loading} waitForLoadingObjects={waitForLoadingObjects} />
        </React.Fragment>
      )
    }
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography component="h1" variant="h6" color="inherit">
            {customLabel("odp.odp")}
          </Typography>
          <form className={myClasses.dialogFormCascade} noValidate autoComplete="off">
            <FormControl className={myClasses.form25} margin="normal" disabled={odpedit.startDate !== null || loading || waitForLoadingObjects}>
              <InputLabel htmlFor="code">{customLabel("odp.code")}</InputLabel>
              <Input autoFocus name="code" error={odpedit.code === ""} value={odpedit.code} onChange={handleChange} />
            </FormControl>
            <FormControl className={myClasses.form25} margin="normal" disabled={loading || waitForLoadingObjects}>
              <InputLabel htmlFor="extcode">{customLabel("odp.extCode")}</InputLabel>
              <Input name="extCode" value={odpedit.extCode} onChange={handleChange} />
            </FormControl>
            <FormControl disabled className={myClasses.form25}>
              <InputLabel shrink htmlFor="customerOrder">{customLabel("odp.customerOrder")}</InputLabel>
              <Input value={odpedit.customerOrder.code} error={odpedit.customerOrder.id === ''} />
            </FormControl>
            <FormControl className={myClasses.form25} disabled>
              <InputLabel shrink htmlFor="customer">{customLabel("odp.customer.customer")}</InputLabel>
              <Input name="customer.id" value={odpedit && odpedit.customer.name ? odpedit.customer.code + " - " + odpedit.customer.name : ""} error={odpedit && odpedit.customer.id === ""} />
            </FormControl>
            {!odpedit.coArticle ? (
              <Autocomplete
                PaperComponent={PaperAutocomplete}
                disabled={odpedit.startDate !== null || waitForLoadingObjects || loading}
                style={{ display: "inline-block" }}
                className={myClasses.form25}
                value={odpedit.article.id !== "" ? odpedit.article : null}
                getOptionSelected={(option, value) => odpedit.article.id !== '' ? option.id === value.id : null}
                onChange={(event, value, reason) => onChangeFilterAutocomplete("article.id", value, reason)}
                options={articles ? articles : []}
                getOptionLabel={(option) => option.code + " - " + option.name}
                id="articleAutocomplete"
                renderInput={(params) => <TextField {...params} label={customLabel("article.article")} error={odpedit.article.id === ''} />
                }
              />)
              : (
                <FormControl disabled className={myClasses.form25}>
                  <InputLabel shrink htmlFor="article">{customLabel("article.article")}</InputLabel>
                  <Input value={odpedit.article.code} error={odpedit.article.id === ''} />
                </FormControl>
              )}
            <Autocomplete
              PaperComponent={PaperAutocomplete}
              disabled={odpedit.startDate !== null || waitForLoadingObjects || loading}
              style={{ display: "inline-block" }}
              className={myClasses.form25}
              value={odpedit.bom.id !== "" && odpedit.bom.id !== null ? odpedit.bom : null}
              getOptionSelected={(option, value) => odpedit.bom.id !== '' ? option.id === value.id : null}
              onChange={(event, value, reason) => onChangeFilterAutocomplete("bom.id", value, reason)}
              options={boms ? boms : []}
              getOptionLabel={(option) => option.code + " - " + option.name}
              id="bomsAutocomplete"
              renderInput={(params) => <TextField {...params} label={customLabel("odp.bom")} />
              }
            />
            <FormControl className={myClasses.form25} disabled={odpedit.startDate !== null || loading}>
              <InputLabel htmlFor="quantity">{customLabel("odp.quantity")}</InputLabel>
              <Input name="quantity" error={odpedit.quantity < 0 || odpedit.quantity === ""} value={odpedit.quantity} disabled={waitForLoadingObjects || loading || odpedit.startDate !== null}
                onChange={handleChange} type="number" />
            </FormControl>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <DatePicker className={myClasses.form25}
                disableToolbar
                allowKeyboardControl={false}
                variant="inline"
                format={bishopFormats.L}
                autoOk
                onChange={(date) => handleChangeDate("deliveryDate", date)}
                label={customLabel("odp.deliveryDate")}
                value={odpedit.deliveryDate}
                disabled={loading || waitForLoadingObjects || odpedit.startDate !== null}
              />
            </MuiPickersUtilsProvider>
            <FormControl className={myClasses.form25} disabled={loading || odpedit.endDate !== null}>
              <InputLabel htmlFor="priority">{customLabel("odp.priority")}</InputLabel>
              <Select name="priority" value={odpedit.priority !== null ? odpedit.priority : 9999999} onChange={handleChange}>
                <MenuItem value={9999999}>{customLabel("odp.priorityNotSet")}</MenuItem>
                <MenuItem value={0}>1</MenuItem>
                <MenuItem value={1}>2</MenuItem>
                <MenuItem value={2}>3</MenuItem>
                <MenuItem value={3}>4</MenuItem>
                <MenuItem value={4}>5</MenuItem>
                <MenuItem value={5}>6</MenuItem>
                <MenuItem value={6}>7</MenuItem>
                <MenuItem value={7}>8</MenuItem>
                <MenuItem value={8}>9</MenuItem>
                <MenuItem value={9}>10</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel labelPlacement="end" className={myClasses.form25} margin="normal" disabled={odpedit.startDate !== null || loading || waitForLoadingObjects}
              control={
                <Checkbox checked={odpedit.toBeProcessed} onChange={handleChange} name="toBeProcessed" color="default" />
              }
              label={customLabel("odp.toBeProcessed")}
            />
            <FormControlLabel labelPlacement="end" className={myClasses.form25} margin="normal" disabled={odpedit.endDate !== null || odpedit.bom.id === "" || odpedit.bom.id === null || loading || waitForLoadingObjects || odpedit.startDate !== null}
              control={
                <Checkbox checked={odpedit.lockProcesses} onChange={handleChange} name="lockProcesses" color="default" />
              }
              label={customLabel("odp.lockProcesses")}
            />
            <FormControl className={myClasses.form100}>
              <TextField disabled={loading || waitForLoadingObjects}
                variant="outlined" name="note" label={customLabel("function.note")} multiline rows={4} value={odpedit.note === null ? "" : odpedit.note}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={myClasses.form100}>
              <TextField disabled={loading || waitForLoadingObjects}
                variant="outlined" name="note2" label={customLabel("function.operatorViewNote")} multiline rows={4} value={odpedit.note2 === null ? "" : odpedit.note2}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={myClasses.form100}>
              <TextField disabled={loading || waitForLoadingObjects}
                variant="outlined" name="note3" label={customLabel("function.odpListNote")} multiline rows={4} value={odpedit.note3 === null ? "" : odpedit.note3}
                onChange={handleChange}
              />
            </FormControl>
          </form>
          <div className={myClasses.placeholderLinearProgress}>
            {waitForLoadingObjects || loading ? (
              <LinearProgress color="primary" />
            ) : null}
          </div>
          <Divider />
          <div className={myClasses.buttonsFormUpdate}>
            <Button focusRipple onClick={save} color="primary"
              disabled={waitForLoadingObjects || odpedit.customerOrder.id === "" || odpedit.code === "" ||
                odpedit.article.id === "" || odpedit.quantity < 0 || odpedit.quantity === "" || loading}>
              {fromCustomerOrder ? customLabel("button.saveAndRedirectOdp") : customLabel("button.save")}
            </Button>
            {fromCustomerOrder ? (
              <Button focusRipple onClick={saveFromOrder} color="primary"
                disabled={waitForLoadingObjects || odpedit.customerOrder.id === "" || odpedit.code === "" ||
                  odpedit.article.id === "" || odpedit.quantity < 0 || odpedit.quantity === "" || loading}
                component={Link} to={`/customerOrders/` + fromCustomerOrder}>
                {customLabel("button.saveAndRedirectOrder")}
              </Button>
            ) : null}
            {odpedit.id !== "new" ? (
              <Button focusRipple onClick={cancelEdit} disabled={loading || waitForLoadingObjects}>
                {customLabel("button.cancel")}
              </Button>
            ) : (
              <Button component={Link} to={!fromCustomerOrder ? `/odps` : `/customerOrders/` + fromCustomerOrder} disabled={loading || waitForLoadingObjects}
                onClick={resetOdpsState}>
                {customLabel("button.back")}
              </Button>
            )}
          </div>
        </Grid>
        {odpedit && odpedit.id === "new" ? (
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography component="h1" variant="h6" color="inherit"> {customLabel("processType.var.vars")}</Typography>
            {bigQuantityAlert && odpedit.bom.id !== "" ?
              <Typography component="h3" variant="h6" className={myClasses.alertMessage}>Attenzione, quantità elevata con variabili seriali!</Typography> : null
            }
            {odpedit && odpedit.id === "new" && odpedit.bom.id !== "" ? (
              renderProcessVars()
            ) : null}
          </Grid>)
          : (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography component="h1" variant="h6" color="inherit">{customLabel("processType.var.vars")}</Typography>
              <Divider component="br" />
              <Typography component="h3" variant="body1" color="inherit">Per modificare le variabili andare nella sezione "VARIABILI" della singola lavorazione.</Typography>
              <Divider component="br" />
              {bigQuantityAlert ?
                <Typography component="h3" variant="h6" className={myClasses.alertMessage}>Attenzione, quantità elevata con variabili seriali!</Typography> : null
              }
              {(quantityAlert) ?
                <Typography component="h3" variant="h6" className={myClasses.alertMessage}>Modificando la quantità verranno ripristinate tutte le variabili seriali e quelle inserite manualmente verranno eliminate!</Typography> : null
              }
            </Grid>
          )
        }
      </Grid>
    </React.Fragment>
  );
};
export default FormOdp;
