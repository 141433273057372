import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { customLabel } from "../utility/customLabel";
import { machinesSelector, createVarAPI, deleteVarAPI, updateVarAPI, newVar, selectVar, changeVar } from './slice'
import moment from "moment";
import { bishopFormats } from '../../constants';
import { Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, Select, MenuItem, FormControl, FormControlLabel, Checkbox, Input } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from '../utility/DialogConfirmAction';

const MachineVarsPage = ({ machine, loading }) => {
  const dispatch = useDispatch()
  const myClasses = mesStyles();
  const { machineVar, machineVars } = useSelector(machinesSelector)

  const [deleting, setDeleting] = React.useState(false);

  const onAdd = () => {
    dispatch(newVar())
  }

  const onDelete = (machineVar) => {
    setDeleting(machineVar)
  }
  const cancelDelete = () => {
    setDeleting(false)
  }

  const doDelete = () => {
    setDeleting(false)
    dispatch(deleteVarAPI(machine, deleting));
  }

  const onEdit = (machineVar) => {
    dispatch(selectVar(machineVar))
  }
  const cancelEdit = () => {
    dispatch(selectVar(false))
  }

  const save = () => {
    if (machineVar.id === "new") {
      dispatch(createVarAPI(machine, machineVar))
    } else {
      dispatch(updateVarAPI(machine, machineVar))

    }
  }

  const onChange = event => {
    let name = event.target.name
    let value = event.target.value
    if (event.target.type === "checkbox") {
      value = event.target.checked
    }
    dispatch(changeVar({ name: name, value: value }));
  };

  return (
    <React.Fragment>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit"> {customLabel("machine.vars")}</Typography>
      </div>
      <div className={myClasses.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{customLabel("machine.name")}</TableCell>
              <TableCell>{customLabel("machine.type")}</TableCell>
              <TableCell>{customLabel("machine.multiple")}</TableCell>
              <TableCell>{customLabel("machine.incremental")}</TableCell>
              <TableCell>{customLabel("machine.operatorEdit")}</TableCell>
              <TableCell>{customLabel("operatorView.operatorView")}</TableCell>
              <TableCell>{customLabel("machine.value")}</TableCell>
              <TableCell>{customLabel("machine.timestamp")}</TableCell>
              <TableCell className={myClasses.tableCell1Button} align="right">
                <Button onClick={onAdd} color="primary" disabled={loading}>{customLabel("button.add")}</Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {machineVars.map(c => {
              return (
                <TableRow key={c.id}>
                  <TableCell>{c.measure !== "" && c.measure !== null ? c.name + " (" + c.measure + ")" : c.name}</TableCell>
                  <TableCell>{c.type === "boolean" ? customLabel("function.boolean") : c.type === "number" ? customLabel("function.number") : customLabel("function.string")}</TableCell>
                  <TableCell>{c.multiple ? <CheckIcon /> : null}</TableCell>
                  <TableCell>{c.incremental ? <CheckIcon /> : null}</TableCell>
                  <TableCell>{c.operatorEdit ? <CheckIcon /> : null}</TableCell>
                  <TableCell>{c.operatorView ? <CheckIcon /> : null}</TableCell>
                  <TableCell>
                    {c.type === "boolean" && c.value === "false" ? <ClearIcon /> : c.type === "boolean" && c.value === "true" ? <CheckIcon /> :
                      c.value !== null && c.type !== "number" ? c.value : c.value !== null && c.type === "number" ? c.value :
                        null}
                  </TableCell>
                  <TableCell>{c.timestamp !== null ? moment(c.timestamp).format(bishopFormats.LTS) : null}</TableCell>
                  <TableCell className={myClasses.tableCell2Buttons} align="right">
                    <Button onClick={() => { onEdit(c) }} color="primary" disabled={loading}>{customLabel("button.edit")}</Button>
                    <Button onClick={() => { onDelete(c) }} color="secondary" disabled={loading}>{customLabel("button.delete")}</Button>
                  </TableCell>
                </TableRow>
              )
            })
            }
          </TableBody>
        </Table>
      </div>
      {
        machineVar !== false ? (
          <Dialog open={machineVar !== false} keepMounted maxWidth={"sm"} fullWidth={true}>
            <DialogTitle>{customLabel("machine.var")}</DialogTitle>
            <DialogContent>
              <form className={myClasses.dialogForm} noValidate autoComplete="off">
                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="name">{customLabel("machine.name")}</InputLabel>
                  <Input name="name" value={machineVar.name} autoFocus onChange={onChange} error={machineVar.name === ""} />
                </FormControl>
                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="type">{customLabel("machine.type")}</InputLabel>
                  <Select name="type" value={machineVar.type} onChange={onChange} error={machineVar.type === ""}>
                    <MenuItem value="boolean">{customLabel("function.boolean")}</MenuItem>
                    <MenuItem value="string">{customLabel("function.string")}</MenuItem>
                    <MenuItem value="number">{customLabel("function.number")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="measure">{customLabel("machine.measure")}</InputLabel>
                  <Input name="measure" value={machineVar.measure} onChange={onChange} />
                </FormControl>
                <FormControl margin="normal">
                  <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="multiple" onChange={onChange} checked={machineVar.multiple} />}
                    label={customLabel("machine.multiple")} />
                </FormControl>
                <FormControl margin="normal">
                  <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="incremental" onChange={onChange} checked={machineVar.incremental} />}
                    label={customLabel("machine.incremental")} />
                </FormControl>
                <FormControl margin="normal">
                  <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="operatorEdit" onChange={onChange} checked={machineVar.operatorEdit} />}
                    label={customLabel("machine.operatorEdit")} />
                </FormControl>
                <FormControl margin="normal">
                  <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="operatorView" onChange={onChange} checked={machineVar.operatorView}
                    disabled={machineVar.operatorEdit} />}
                    label={customLabel("operatorView.operatorView")} />
                </FormControl>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={save} color="primary" disabled={machineVar.name === "" || machineVar.type === "" || loading} >{customLabel("button.save")}</Button>
              <Button onClick={cancelEdit} color="default" disabled={loading}>{customLabel("button.close")}</Button>
            </DialogActions>
          </Dialog>
        ) : null
      }
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={cancelDelete} disabled={loading} />
    </React.Fragment >
  )
}

export default MachineVarsPage
