import moment from "moment";

export const bishopFormats = {
  LTS: "DD/MM/YYYY HH:mm:ss",
  LT: "DD/MM/YY HH:mm",
  L: "DD/MM/YYYY",
  H: " HH:mm:ss",

};

export const bishopMoment = moment;
console.log(process.env.REACT_APP_LOCAL_ENV);
export const EXTRAORDINARY = "EXTRAORDINARY";
export const adminCode = "admin";
export const evolutionCode = "EVO";
export const adminFirstName = "admin";
export const evolutionFirstName = "Bishop";
export const SERVER_NOT_RESPONDING_MESSAGE = "INTERFACCIA DISCONNESSA!";

/*export const SERVER_URL =
  process.env.REACT_APP_LOCAL_ENV === "docker"
    ? `http://${window.location.hostname}:8080`
    : process.env.NODE_ENV === "production"
      ? `http://${window.location.hostname}:8080`
      : `http://${window.location.hostname}:8080`;*/

export const SERVER_URL = `${window.BISHOP_API_URL}:${window.BISHOP_API_PORT}`;
console.log(SERVER_URL)
export const SERIAL_NUMBER = window.BISHOP_SERIAL_NUMBER ? 'Ultrafab nr. ' + window.BISHOP_SERIAL_NUMBER : 'Ultrafab nr. xxxxx'
console.log(SERIAL_NUMBER)

export const WEBSOCKET_URL_ERROR =
  process.env.REACT_APP_LOCAL_ENV === "docker"
    ? `ws://${window.location.hostname}:9292/error`
    : process.env.NODE_ENV === "production"
      ? `ws://${window.location.hostname}:9292/error`
      : `ws://${window.location.hostname}:9292/error`;

export const WEBSOCKET_URL_CHECKS =
  process.env.REACT_APP_LOCAL_ENV === "docker"
    ? `ws://${window.location.hostname}:9292/checks`
    : process.env.NODE_ENV === "production"
      ? `ws://${window.location.hostname}:9292/checks`
      : `ws://${window.location.hostname}:9292/checks`;

export const WEBSOCKET_URL =
  process.env.NODE_ENV === "production"
    ? `ws://${window.location.hostname}:9292/`
    : `ws://${window.location.hostname}:9292`;


/*moment.locale('it');
moment.updateLocale('it', {
  longDateFormat: {
    LTS: "DD/MM/YYYY HH:mm:ss",
    L: "DD/MM/YYYY",
  }
});
moment.updateLocale('en', {
  longDateFormat: {
    LTS: "DD/MM/YYYY HH:mm:ss",
    LT: "DD/MM/YY HH:mm",
    L: "DD/MM/YYYY",
  }
});*/
