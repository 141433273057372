import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete, measureToolsSelector
} from "./slice";
import { reset as resetMachines } from "../machines/slice";
import { Box } from "@material-ui/core";
import { Form } from "./Form";
import { Details } from "./Details";
import DocumentsPage from "../documents/DocumentsPage";
import { PaperForm } from "../utility/ultrafabStyles";

const MeasureToolPage = ({ match }) => {
  const dispatch = useDispatch();
  const { measureTool, measureTooledit, editmode, deleting, isCreated, isDeleted, measureTools, loading } = useSelector(measureToolsSelector);

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    dispatch(change({ name: name, value: value }));
  };

  const toggleDelete = (event) => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(measureTooledit));
    dispatch(resetMachines())
  };

  const onEdit = () => {
    dispatch(edit());
  };
  const doDelete = () => {
    dispatch(deleteAPI(measureTool));
    dispatch(resetMachines())
  };

  const save = () => {
    if (measureTool.id === "new") {
      dispatch(createAPI(measureTooledit));
    } else {
      dispatch(updateAPI(measureTooledit));
    }
  };

  useEffect(() => {
    const { id } = match.params;
    if (id !== "new") {
      dispatch(getAPI(id));
    } else {
      dispatch(create());
    }
  }, [dispatch, match]);

  const renderMeasureTool = () => {
    if (measureTool !== false && !editmode && match.params.id === measureTool.id.toString()) {
      return (
        <div>
          <Details measureTool={measureTool} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete} loading={loading} />
          <PaperForm>
            <DocumentsPage parentId={measureTool.id} parentType="measure_tools" extLoading={loading} title={true} size="small" />
          </PaperForm>
        </div>
      );
    }
    if (measureTool !== false && editmode) {
      return (
        <Form measureTooledit={measureTooledit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save} measureTools={measureTools} loading={loading} />
      );
    }
    return null;
  };

  if (isDeleted && match.params.id === measureTool.id.toString()) {
    return <Redirect to={"/measureTools/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/measureTools/" + measureTool.id} />;
  }

  return <Box>{renderMeasureTool()}</Box>;
};

export default MeasureToolPage;
