import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { StyledTableRow } from "../../../utility/TableList";
import { TableContainer, Table, TableCell, TableRow, TableBody, TableHead, CircularProgress } from '@material-ui/core';
import { customLabel } from '../../../utility/customLabel';
import { selector, loadCustomOdpsAPI, initCustomFilter } from "../slice";
import { currentList } from "../utility";
import moment from "moment";
import { bishopFormats } from '../../../../constants';
import { mesStyles } from '../../../utility/ultrafabStyles';
import { customersSelector, getListAPI as getCustomersAPI } from "../../../customers/slice";
import { articlesSelector, getListAPI as getArticles } from "../../../articles/slice";
import FilterRow from "./tableUtility/FilterRow";
import ButtonsFragment from './tableUtility/ButtonsFragment';
import PaginationFragment from './tableUtility/PaginationFragment';

const Closed = ({ handleClickRedirect, onChangeFilter, onChangeFilterAutocomplete, handleChangeDate, handleResetDate,
    handleChangePage, handleChangeRowsPerPage, configuration, enableReload }) => {

    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { odpsClosed, loadingClosed, errorsClosed, closedFilter, totalClosed, customReload } = useSelector(selector);
    const { customers } = useSelector(customersSelector);
    const { articles } = useSelector(articlesSelector);

    useEffect(() => {
        dispatch(getCustomersAPI(0, 100000, "code", "asc", false, false));
        dispatch(getArticles(0, 100000, "code", "asc", false, false, false, false, false));
    }, [dispatch])

    //inizializzo filtro
    useEffect(() => {
        if (!closedFilter && configuration) {
            dispatch(initCustomFilter({ filter: currentList.closed.filter, showFilter: configuration.showFilters }))
        }
    }, [configuration, closedFilter, dispatch])

    //reload dopo cambio filtro
    useEffect(() => {
        if (closedFilter && customReload && !errorsClosed && !loadingClosed) {
            dispatch(loadCustomOdpsAPI(currentList.closed, closedFilter));
        }
    }, [dispatch, customReload, errorsClosed, closedFilter, loadingClosed])

    const toggleFilter = () => {
        if (!closedFilter.showFilter) {
            dispatch(initCustomFilter({ filter: currentList.closed.filter, showFilter: true }))
        } else {
            dispatch(initCustomFilter({ filter: currentList.closed.filter, showFilter: false }))
        }
    };

    const emptyFilter = () => {
        dispatch(initCustomFilter({ filter: currentList.closed.filter, showFilter: true }))
    };

    return (
        <React.Fragment>
            <ButtonsFragment filter={closedFilter} toggleFilter={toggleFilter} emptyFilter={emptyFilter} />
            <TableContainer style={{ maxHeight: "90%" }}>
                <Table stickyHeader
                    aria-labelledby="tableTitle"
                    size={'small'}
                    aria-label="enhanced table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell><b>{customLabel("odp.odp")}</b></TableCell>
                            <TableCell><b>{customLabel("customerOrder.customerOrder")}</b></TableCell>
                            <TableCell><b>{customLabel("customer.customer")}</b></TableCell>
                            <TableCell><b>{customLabel("article.article")}</b></TableCell>
                            <TableCell><b>{customLabel("odp.deliveryDate")}</b></TableCell>
                            <TableCell><b>{customLabel("odp.startDate")}</b></TableCell>
                            <TableCell><b>{customLabel("odp.endDate")}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/*7 colonne*/}
                        {closedFilter && odpsClosed && articles && customers && !loadingClosed ? (
                            <FilterRow customFilter={closedFilter} customers={customers} articles={articles} suppliers={false} onChangeFilter={onChangeFilter}
                                onChangeFilterAutocomplete={onChangeFilterAutocomplete} handleChangeDate={handleChangeDate} handleResetDate={handleResetDate}
                                colSpan={[1, 1, 1, 1, 1, 1, 1, 0, 0]} typeStatus={currentList.closed.url} showDeliveryFilter={true} showStartDateFilter={true} showEndDateFilter={true}
                                showSuppliersFilter={false} showMachinesFilter={false} showWorkcentresFilter={false} machines={false}
                                workcentres={false} enableReload={enableReload} />
                        ) : null}
                        {odpsClosed && odpsClosed.length > 0 && articles && customers && !loadingClosed ? (
                            odpsClosed.map((o) => {
                                return (
                                    <StyledTableRow key={o.id} onClick={() => handleClickRedirect(o.id)} className={myClasses.cursorPointer}>
                                        <TableCell>{o.code}</TableCell>
                                        <TableCell>{o.customerOrder?.code}</TableCell>
                                        <TableCell>{o.customer?.code} {o.customer?.name}</TableCell>
                                        <TableCell>{o.article?.code} {o.article?.name}</TableCell>
                                        <TableCell>{o.deliveryDate ? moment(o.deliveryDate).format(configuration && configuration.showOdpDeliveryTime ? bishopFormats.LT : bishopFormats.L) : null}</TableCell>
                                        <TableCell>{o.startDate ? moment(o.startDate).format(bishopFormats.LT) : null}</TableCell>
                                        <TableCell>{o.endDate ? moment(o.endDate).format(bishopFormats.LT) : null}</TableCell>
                                    </StyledTableRow>
                                )
                            })
                        ) : odpsClosed && odpsClosed.length === 0 && !loadingClosed ?
                            (
                                <TableRow>
                                    <TableCell colSpan={7}>{customLabel("odp.noOdpsAvailable").toUpperCase()}</TableCell>
                                </TableRow>
                            )
                            : loadingClosed || !customers || !articles ?
                                (<TableRow>
                                    <TableCell align="center" colSpan={7}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>)
                                :
                                null}
                    </TableBody>
                </Table>
            </TableContainer>
            {totalClosed !== false && !loadingClosed ? (
                <PaginationFragment total={totalClosed} customFilter={closedFilter} handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} typeStatus={currentList.closed.url} disabledChange={loadingClosed} />
            ) : null}
        </React.Fragment>
    )
}

export default Closed
