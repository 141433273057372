import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { buttonsSelector, getListAPI, setReload, changeFilter, initFilter } from './slice'
import TableList from "../utility/TableList";
import { Box, Divider, Typography } from "@material-ui/core";
import { buttonType } from "./utility";
import { configurationSelector } from "../configuration/slice";
import { useBoolean } from "../customHooks/useBoolean";
import HeaderTableList from "../utility/HeaderTableList";
import { headers } from "./utility";

const ButtonsPage = () => {
    const dispatch = useDispatch();
    const { buttons, loading, results, reload, filter } = useSelector(buttonsSelector);
    const { configuration } = useSelector(configurationSelector);
    const [firstFetchDone, { setTrue: setFirstFetchDoneTrue }] = useBoolean(false);

    useEffect(() => {
        if (!filter && configuration) {
            dispatch(initFilter(configuration.showFilters))
        }
    }, [configuration, filter, dispatch])

    useEffect(() => {
        if (filter && !firstFetchDone) {
            dispatch(getListAPI(filter.page, filter.rowsPerPage, filter.orderBy, filter.order, filter.name, filter.type, filter.api));
            window.scrollTo(0, 0);
            setFirstFetchDoneTrue();
        }
    }, [dispatch, filter, firstFetchDone, setFirstFetchDoneTrue]);

    if (reload && !loading) {
        dispatch(getListAPI(filter.page, filter.rowsPerPage, filter.orderBy, filter.order, filter.name, filter.type, filter.api));
    }

    const handleChangePage = (event, newPage, doReload) => {
        dispatch(changeFilter({ name: "page", value: newPage }))
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(changeFilter({ name: "rowsPerPage", value: parseInt(event.target.value, 10) }))
        dispatch(changeFilter({ name: "page", value: 0 }))
    };

    const onRequestSort = (property) => {
        const isAsc = filter.orderBy === property && filter.order === "asc"
        let newOrder = isAsc ? "desc" : "asc";
        dispatch(changeFilter({ name: "order", value: newOrder }))
        dispatch(changeFilter({ name: "orderBy", value: property }))
    };

    const changeFilters = (name, value) => {
        dispatch(changeFilter({ name: name, value: value }))
    };

    const resetFilter = () => {
        dispatch(initFilter(true));
    };

    const toggleFilter = () => {
        if (!filter.showFilter) {
            dispatch(initFilter(true));
        } else {
            dispatch(initFilter(false));
        }
    }

    const applyReload = useCallback(() => {
        dispatch(setReload());
    }, [dispatch]);

    return (
        <React.Fragment>
            <Typography component="h1" variant="h6" color="inherit">
                {customLabel("buttons.buttons")}
            </Typography>
            <HeaderTableList filter={filter} toggleFilter={toggleFilter} showAdd={true} businessObject={"admin/buttons"} />
            <Divider />
            <Box>
                {buttons && filter ? (
                    <TableList items={buttons} headers={headers} mainObject={"buttons."} redirectObject={"admin/buttons"}
                        setPage={handleChangePage} setRowsPerPage={handleChangeRowsPerPage} results={results}
                        onRequestSort={onRequestSort} changeFilters={changeFilters}
                        resetFilter={resetFilter} typeList={Object.keys(buttonType)} nameList={false} apiList={false}
                        filter={filter} applyReload={applyReload} />
                ) : null}
                {loading ? <div>{customLabel("function.loading").toUpperCase()}</div> : null}
            </Box>
        </React.Fragment>
    );
};

export default ButtonsPage;