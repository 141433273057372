import React from "react";
import { useSelector, useDispatch } from "react-redux";
import MyAlert from './MyAlert';
import { alertsSelector, closeAlert } from "./slice";

/**
 * Componente che mostra gli Alert salvati all interno dello slice Alerts
 * @returns 
 */
const Alerts = () => {
    const dispatch = useDispatch();
    const { alerts } = useSelector(alertsSelector);

    const handleCloseAlert = (name) => {
        dispatch(closeAlert(name));
    };

    const renderAlerts = () => {
        if (alerts && alerts.length > 0) {
            return (
                alerts.map((a) => {
                    return (
                        <MyAlert key={a.name} name={a.name} message={a.message} type={a.type} interaction={false} onCloseAlert={handleCloseAlert}
                            onClickAlert={null} buttonText={null} />
                    )
                })
            )
        } else {
            return null;
        }
    }

    return (
        <React.Fragment>
            {renderAlerts()}
        </React.Fragment>
    )
}

export default Alerts;
