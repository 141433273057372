import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table, TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import { customLabel } from "../utility/customLabel";
import moment from "moment";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SERVER_URL, bishopFormats } from '../../constants';
import { configurationSelector } from "../configuration/slice";
import { useBoolean } from "../customHooks/useBoolean";
import DialogConfirmAction from "../utility/DialogConfirmAction";
import { mesStyles } from '../utility/ultrafabStyles';
import { getExtendedOdpAPI } from './slice';

const DetailsOdp = ({ odp, onEdit, toggleDelete, deleting, doDelete, loading }) => {
    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { configuration } = useSelector(configurationSelector);
    const [reporting, { setTrue: setReporting, setFalse: unsetReporting }] = useBoolean(false);
    const [template, setTemplate] = React.useState("")

    const report = () => {
        unsetReporting()
        dispatch(getExtendedOdpAPI(odp, configuration.reportUrl, template))
        setTemplate(false)
    }

    const print = () => {
        window.open(SERVER_URL + '/maintenance/' + odp.id + '/print');
    }

    return (
        <React.Fragment>
            <Paper className={myClasses.odpDetailsPaperMD}>
                <TableContainer className={myClasses.odpDetailsContainerMD}>
                    <Table size="small" style={odp && !odp.stapling ? { overflow: "auto", maxHeight: "300px", minHeight: "300px" } : null}>
                        <tbody>
                            <TableRow>
                                <TableCell align="center" colSpan={3}>
                                    <b>{customLabel("odp.details")}</b>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{customLabel("odp.code")}</TableCell>
                                <TableCell align="right" style={{ wordBreak: "break-all" }} colSpan={2}>{odp ? odp.code : null}</TableCell>
                            </TableRow>
                            {odp.maintenance ? (
                                <React.Fragment>
                                    <TableRow>
                                        <TableCell>{customLabel("maintenance.maintenanceType")}</TableCell>
                                        <TableCell align="right" colSpan={2}>{odp ? odp.maintenanceType.name : null}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("machine.machine")}</TableCell>
                                        <TableCell align="right" colSpan={2}>{odp ? odp.machine.code : null}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("maintenance.toBeProcessed")}</TableCell>
                                        <TableCell align="right" colSpan={2}>{customLabel(odp && odp.toBeProcessed ? "maintenance.maintenance" : "maintenance.deadline")}</TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ) : null}
                            {odp && !odp.stapling && !odp.maintenance ? (
                                <React.Fragment>
                                    <TableRow>
                                        <TableCell>{customLabel("odp.extCode")}</TableCell>
                                        <TableCell colSpan={2}>{odp ? odp.extCode : null}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("customerOrder.customerOrder")}</TableCell>
                                        <TableCell align="right" colSpan={2}>{odp && odp.customerOrder ? odp.customerOrder.code : null}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("customer.customer")}</TableCell>
                                        <TableCell align="right" colSpan={2}>{odp && odp.customer ? odp.customer.code + " - " + odp.customer.name : null}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("article.article")}</TableCell>
                                        <TableCell align="right" colSpan={2}>{odp && odp.article ? odp.article.code + " - " + odp.article.name : null}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("odp.bom")}</TableCell>
                                        <TableCell align="right" colSpan={2}>{odp && odp.bom && odp.bom.code ? odp.bom.code + " - " + odp.bom.name : null}</TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ) : null}
                            {!odp.maintenance ? (
                                <React.Fragment>
                                    <TableRow>
                                        <TableCell colSpan={2}>{odp && !odp.stapling ? customLabel("odp.quantity") : customLabel("stapling.calculatedQuantity")}</TableCell>
                                        <TableCell align="right" colSpan={2}>{odp ? odp.quantity.toFixed(configuration.decimals) : null}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("odp.creationDate")}</TableCell>
                                        <TableCell align="right" colSpan={2}>{odp && odp.dateCreated && odp.dateCreated !== null ? moment(odp.dateCreated).format(bishopFormats.L) : null}</TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ) : null}
                            {odp && !odp.stapling ? (
                                <React.Fragment>
                                    <TableRow>
                                        <TableCell>{customLabel(!odp.maintenance ? "odp.deliveryDate" : "maintenance.deliveryDate")}</TableCell>
                                        <TableCell align="right" colSpan={2}>{odp ? moment(odp.deliveryDate).format(bishopFormats.L) : null}</TableCell>
                                    </TableRow>
                                    {!odp.maintenance ? (
                                        <React.Fragment>
                                            <TableRow>
                                                <TableCell>{customLabel("odp.priority")}</TableCell>
                                                <TableCell align="right" colSpan={2}>{odp && odp.priority !== null && odp.priority < 10 ? odp.priority + 1 : customLabel("odp.priorityNotSet")}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>{customLabel("odp.toBeProcessed")}</TableCell>
                                                <TableCell align="right" colSpan={2}>{odp && odp.toBeProcessed ? (
                                                    <CheckIcon />
                                                ) : null}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>{customLabel("odp.lockProcesses")}</TableCell>
                                                <TableCell align="right" colSpan={2}>{odp && odp.lockProcesses ? (
                                                    <CheckIcon />
                                                ) : null}
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ) : null}
                                    <TableRow>
                                        <TableCell>{customLabel("function.note")}</TableCell>
                                        <TableCell align="right" colSpan={2}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    variant="outlined" name="note" disabled multiline rows={2} value={odp.note ? odp.note : ""}
                                                />
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("function.operatorViewNote")}</TableCell>
                                        <TableCell align="right" colSpan={2}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    variant="outlined" name="note2" disabled multiline rows={2} value={odp.note2 ? odp.note2 : ""}
                                                />
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("function.odpListNote")}</TableCell>
                                        <TableCell align="right" colSpan={2}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    variant="outlined" name="note3" disabled multiline rows={2} value={odp.note3 ? odp.note3 : ""}
                                                />
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ) : null}
                        </tbody>
                    </Table>
                </TableContainer>
                <div className={myClasses.buttonsDivBottom}>
                    <div className={myClasses.buttonsFlexRight}>
                        {odp && !odp.stapling && odp.endDate === null ? (
                            <React.Fragment>
                                <Button key={odp.id} onClick={onEdit} color="primary" disabled={loading}>
                                    {customLabel("button.edit")}
                                </Button>
                            </React.Fragment>
                        ) : null}
                        <Button color="secondary" onClick={toggleDelete} disabled={loading || !odp.deletable}>
                            {customLabel("button.delete")}
                        </Button>
                        {configuration && configuration.reportUrl && configuration.reportUrl !== "" && configuration.reportUrl !== "null" && configuration.reportUrl !== null && !odp.maintenance ?
                            (
                                <Button color="default" disabled={loading} onClick={setReporting}>{customLabel("function.report")} </Button>
                            ) : null}
                        {odp && !odp.maintenance ? (
                            <Button color="default" disabled={loading} component={Link} to={`/odpStatus/`}>
                                {customLabel("menuItem.odpStatuses")}
                            </Button>
                        ) : null}
                        {odp && odp.maintenance ? (
                            <Button onClick={print}>
                                {customLabel("button.print")}
                            </Button>
                        ) : null}
                        <Button color="default" component={Link} to={!odp.maintenance ? '/odps' : '/maintenances'} disabled={loading}>
                            {customLabel("button.list")}
                        </Button>
                    </div>
                </div>
            </Paper>
            <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
                confirm={doDelete} cancel={toggleDelete} disabled={loading} />

            <Dialog open={reporting} keepMounted maxWidth={"md"} fullWidth={true}>
                <DialogTitle>{customLabel("function.generateReport")}</DialogTitle>
                <DialogContent>
                    <form className={myClasses.dialogForm} noValidate autoComplete="off">
                        <FormControl margin="normal" fullWidth>
                            {configuration.reportTemplates !== "" && configuration.reportTemplates !== null ? (
                                <React.Fragment>
                                    <InputLabel htmlFor="template">{customLabel("function.template")}</InputLabel>
                                    <Select name="template" value={template ? template : ""} onChange={(e) => setTemplate(e.target.value)}>
                                        {configuration.reportTemplates.split(",").map(t => {
                                            return <MenuItem key={t} value={t}>{t}</MenuItem>
                                        })}
                                    </Select>
                                </React.Fragment>
                            ) :
                                <Typography component="p" variant="body1" className={myClasses.alertMessage}>
                                    {customLabel("function.notAvailable").toUpperCase()}
                                </Typography>
                            }
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={report} color="primary" disabled={!template || loading} >{customLabel("function.confirm")}</Button>
                    <Button onClick={unsetReporting} disabled={loading} color="default">{customLabel("button.close")}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
};

export default DetailsOdp;
