import { Grid, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { renderComponents, renderOdpComponents } from "../../components/utility";
import { mainSelector } from "../../main/slice";
import { odpsSelector } from '../../odps/slice';
import SimpleTableHeader from '../../utility/SimpleTableHeader';
import { customLabel } from '../../utility/customLabel';
import {
    loadLotListAPI, loadScrapListAPI,
    loadSubstituteListAPI,
    operatorViewSelector,
    setEditConsum,
    setNewConsum,
    toggleComponentDetails
} from "../slice";

const ComponentsPage = ({ configuration, operatorView }) => {
    const dispatch = useDispatch()
    const { components, process, odp: opViewOdp, loading, modeStapling, componentDetails, lotInfo: opViewLotInfo } = useSelector(operatorViewSelector);
    const { serverNotResponding } = useSelector(mainSelector);
    const { odp, components: odpComponents, staplingComponents, lotInfo } = useSelector(odpsSelector)

    let autoComponentConsumption = modeStapling && opViewOdp ? opViewOdp.staplingProcesses.every(obj => obj.autoComponentConsumption) : process.autoComponentConsumption
    /**
     * 
     * @param {int} compId id del componente da valorizzare
     * @param {boolean} waste false per dichiarazione consumo, true dichiarazione scarto
     */
    const declareNewComp = (compId, waste, substitute, reverseQuantity, lotToReverse, scrapReason) => {
        let consumedQuantity
        let index = components.findIndex(c => c.id === compId)
        if (index !== -1) {
            consumedQuantity = components[index].consumedQuantity
        }
        let totalPieces = 0;
        if (modeStapling) {
            if (opViewOdp.validPieces !== null) {
                totalPieces = opViewOdp.validPieces;
            } else if (opViewOdp.validPieces === null && opViewOdp.totalPieces !== null) {
                totalPieces = opViewOdp.totalPieces;
            }
        }
        if (!modeStapling) {
            if (process.validPieces !== null) {
                totalPieces = process.validPieces;
            } else if (process.validPieces === null && process.totalPieces !== null) {
                totalPieces = process.totalPieces;
            }
        }
        dispatch(loadLotListAPI(opViewOdp, process, compId, opViewOdp.stapling));
        if (configuration.scrapReasonComponents) {
            dispatch(loadScrapListAPI(opViewOdp, process, compId, opViewOdp.stapling));
        }
        if (configuration.changeComponent) {
            dispatch(loadSubstituteListAPI(opViewOdp, process, compId, opViewOdp.stapling));
        }
        dispatch(setNewConsum({ compId: compId, components: components, decimals: configuration.componentDecimals, waste: waste, substitute: substitute, totalPieces: totalPieces, autoComponentConsumption: autoComponentConsumption, consumedQuantity: consumedQuantity, useAlwaysFactor: configuration.componentUseAlwaysFactor, reverseQuantity: reverseQuantity, lotToReverse: lotToReverse, scrapReason }));
    }

    const declareEditComponent = (compId, consumId) => {
        dispatch(loadLotListAPI(opViewOdp, process, compId, opViewOdp.stapling));
        dispatch(setEditConsum({ compId: compId, consumId: consumId, components: components, autoComponentConsumption: autoComponentConsumption }))
        dispatch(toggleComponentDetails(false))
    }

    const handleToggleDetails = (compId) => {
        dispatch(toggleComponentDetails(componentDetails === compId ? null : compId))
    };

    return (
        <React.Fragment>
            <div style={{ maxHeight: "642px", overflow: "auto" }}>
                {!serverNotResponding && operatorView ? (
                    <Grid container justify="center">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {renderComponents(components, declareNewComp, declareEditComponent, null, null, loading, false, configuration, componentDetails, handleToggleDetails, operatorView, opViewLotInfo)}
                        </Grid>
                    </Grid>
                ) : !operatorView ? (
                    <Table size="small" stickyHeader>
                        <SimpleTableHeader list={[customLabel("component.code"), customLabel("component.name"), customLabel("component.lot"),
                        customLabel("component.consumption"), customLabel("component.wastes"), customLabel("function.details")]} />
                        <TableBody>
                            {renderOdpComponents(odp.stapling ? staplingComponents : odpComponents, loading, configuration, componentDetails, handleToggleDetails, operatorView, lotInfo)}
                        </TableBody>
                    </Table>
                ) : (
                    <Table>
                        <tbody>
                            <TableRow>
                                <TableCell>{customLabel("function.notAvailable").toUpperCase()}</TableCell>
                            </TableRow>
                        </tbody>
                    </Table>
                )}
            </div>
        </React.Fragment>
    )
}

export default ComponentsPage
