export const customFilter = {
    mesName: "articles",
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "code",
    showFilter: false,
    code: false,
    extCode: false,
    name: false,
    bom: false,
    type: false
}

export const headers = {
    code: { filter: true, type: "string" },
    extCode: { filter: true, type: "string" },
    name: { filter: true, type: "string" },
    type: { filter: true, type: "stringL" },
    bom: { filter: true, type: "object" },
};



