import { createSlice } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../notifications/slice";
import { logout, refreshAPI } from "../main/slice";
import { SERVER_URL } from "../../constants";
import { customLabel } from "../utility/customLabel";
import { customFilter } from "./utility";

export const initialState = {
  loading: false,
  hasErrors: false,
  editmode: false,
  boms: false,
  bom: false,
  bomedit: false,
  deleting: false,
  isCreated: false,
  isDeleted: false,
  reload: false,
  bomProcess: false,
  processType: false,
  filter: false
};

const bomsSlice = createSlice({
  name: "boms",
  initialState,
  reducers: {
    resetState: (state) => {
      if (!state.filter) {
        Object.assign(state, initialState);
      } else {
        let tempState = {};
        let currentFilter = { ...state.filter }
        Object.assign(tempState, initialState);
        tempState.filter = { ...currentFilter }
        Object.assign(state, tempState);
      }
    },
    setReload: (state) => {
      state.reload = true;
    },
    reset: (state) => {
      state.bom = false;
      state.bomedit = false;
      state.editmode = false;
      state.isDeleted = false;
      state.isCreated = false;
    },
    gettingList: (state) => {
      state.loading = true;
      state.reload = false;
      state.hasErrors = false;
    },
    getListSuccess: (state, { payload }) => {
      state.boms = payload[1];
      state.results = payload[0].total_entries;
      state.loading = false;
    },
    getting: (state) => {
      state.bom = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.loading = true;
      state.editmode = false;
      state.reload = false;
      state.hasErrors = false;
    },
    getSuccess: (state, { payload }) => {
      state.bom = payload;
      state.loading = false;
    },
    creating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    create: (state) => {
      let newbom = { id: "new", code: "", name: "", note: "", lockProcesses: true, autoClose: true };
      state.bom = newbom;
      state.bomedit = newbom;
      state.editmode = true;
    },
    createSuccess: (state, { payload }) => {
      state.bom = payload;
      state.bomedit = false;
      state.loading = false;
      state.isCreated = true;
    },
    resetEdit: (state) => {
      state.editmode = true;
      state.bomedit = state.bom;
      state.loading = false;
      state.hasErrors = true;
    },
    edit: (state) => {
      state.editmode = true;
      state.bomedit = state.bom;
    },
    cancelEdit: (state) => {
      state.editmode = false;
      state.bomedit = false;
    },
    change: (state, { payload }) => {
      let u = state.bomedit;
      u[payload.name] = payload.value;
      state.bomedit = u;
    },
    updating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    updateSuccess: (state, { payload }) => {
      state.bom = payload;
      state.loading = false;
      state.editmode = false;
    },
    confirmDelete: (state) => {
      state.deleting = !state.deleting;
    },
    deleting: (state) => {
      state.deleting = false;
      state.loading = true;
      state.hasErrors = false;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.isDeleted = true;
    },
    enableLoading: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    creatingBomProcess: (state) => {
      state.loading = true;
    },
    createBomProcessSuccess: (state) => {
      state.loading = false;
      state.bomProcess = false;
      state.reload = true;
    },
    updatingBomProcess: (state) => {
      state.loading = true;
    },
    updateBomProcessSuccess: (state) => {
      state.loading = false;
      state.bomProcess = false;
      state.reload = true;
    },
    deletingBomProcess: (state) => {
      state.loading = true;
    },
    deleteBomProcessSuccess: (state) => {
      state.loading = false;
      state.reload = true;
    },
    newBomProcess: (state) => {
      state.bomProcess = { id: "new", nextMode: '', repeatable: false, optional: false, processType: { id: "" } };
    },
    selectBomProcess: (state, action) => {
      state.bomProcess = action.payload;
    },
    changeBomProcess: (state, { payload }) => {
      let b = state.bomProcess;
      if (payload.name === "processType.id") {
        let lista = payload.processTypes.slice();
        let indexOfTypes = lista.findIndex((l) => {
          return l.id === payload.value
        });
        b["processType"] = lista[indexOfTypes]
      } else {
        b[payload.name] = payload.value;
      }
      state.bomProcess = b;
    },
    sortBomProcessesSuccess: (state) => {
      state.reload = true;
      state.loading = false;
    },
    changeBomProcessObject: (state, { payload }) => {
      let u = state.bomProcess;
      if (payload.name === "processType.id") {
        u["processType"] = { id: '' }
      }
      state.bomProcess = u;
    },
    enableErrors: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    addingBomProcessArticles: (state) => {
      state.loading = true;
    },
    addBomProcessArticlesSuccess: (state, { payload }) => {
      state.reload = true;
      state.loading = false;
    },
    removingBomProcessArticle: (state) => {
      state.loading = true;
    },
    removeArticleBomProcessSuccess: (state, { payload }) => {
      state.reload = true;
      state.loading = false;
    },
    changeFilter: (state, { payload }) => {
      state.filter[payload.name] = payload.value;
    },
    /**
     * 
     * @param {*} state 
     * @param {boolean} payload showFilter 
     */
    initFilter: (state, { payload }) => {
      let tempFilter = { ...customFilter };
      tempFilter["showFilter"] = payload;
      state.filter = { ...tempFilter };
    }
  },
});

export const { resetState, reset, setReload, gettingList, getListSuccess, getting, getSuccess, create, creating, createSuccess, edit, cancelEdit, change, updating,
  updateSuccess, confirmDelete, deleting, deleteSuccess, resetEdit, loadingBoms, loadingBomsSuccess, hasErrors, enableErrors,
  selectBomProcess, changeBomProcess, sortBomProcessesSuccess, newBomProcess, creatingBomProcess, createBomProcessSuccess,
  updateBomProcessSuccess, updatingBomProcess, deletingBomProcess, deleteBomProcessSuccess, changeBomProcessObject, enableLoading,
  addingBomProcessArticles, addBomProcessArticlesSuccess, removingBomProcessArticle, removeArticleBomProcessSuccess, changeFilter, initFilter } = bomsSlice.actions;

export const bomsSelector = (state) => state.boms;

export default bomsSlice.reducer;

export function getListAPI(page, rowsPerPage, orderBy, order, name, code) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(gettingList());
    dispatch(reset());

    let url = SERVER_URL + "/api/bom?page=" + page + "&per_page=" + rowsPerPage + "&orderBy=" + orderBy +
      "&order=" + order;

    if (name !== false) {
      url = url + "&name=" + name;
    }
    if (code !== false) {
      url = url + "&code=" + code;
    }

    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getListSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}


export function updateAPI(bom) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { bom: bom };
  return async (dispatch) => {
    dispatch(updating());
    const response = await fetch(SERVER_URL + "/api/bom/" + bom.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(resetEdit());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function createAPI(bom) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(creating());
    let params = { bom: bom };
    const response = await fetch(SERVER_URL + "/api/bom", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function getAPI(id) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(getting());
    const response = await fetch(SERVER_URL + "/api/bom/" + id, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function deleteAPI(bom) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(deleting());
    const response = await fetch(SERVER_URL + "/api/bom/" + bom.id, {
      mode: "cors",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(deleteSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function sortBomProcessesAPI(bom, bomProcesses) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(enableLoading());
    let params = { bomProcesses: bomProcesses };
    const response = await fetch(
      SERVER_URL + "/api/bom/" + bom.id + "/bomProcess/sort",
      {
        mode: "cors",
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(params),
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(sortBomProcessesSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function createBomProcessAPI(bom, bomProcess) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(creatingBomProcess())
    let params = { bomProcess: bomProcess }
    const response = await fetch(SERVER_URL + '/api/bom/' + bom.id + '/bomProcess', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(createBomProcessSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function updateBomProcessAPI(bom, bomProcess) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(updatingBomProcess())
    let params = { bomProcess: bomProcess }
    const response = await fetch(SERVER_URL + '/api/bom/' + bom.id + '/bomProcess/' + bomProcess.id, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updateBomProcessSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function deleteBomProcessAPI(bom, bomProcess) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(deletingBomProcess())
    const response = await fetch(SERVER_URL + '/api/bom/' + bom.id + '/bomProcess/' + bomProcess.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(deleteBomProcessSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function addArticlesAPI(bom, bomProcess, articles) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(addingBomProcessArticles())
    let params = { articles: articles }
    const response = await fetch(SERVER_URL + '/api/bom/' + bom.id + '/bomProcess/' + bomProcess.id + '/links', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(addBomProcessArticlesSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}
export function removeArticleAPI(bom, bomProcess, article) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(removingBomProcessArticle())
    const response = await fetch(SERVER_URL + '/api/bom/' + bom.id + '/bomProcess/' + bomProcess.id + '/links/' + article.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(removeArticleBomProcessSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}