import { createSlice } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../notifications/slice";
import { logout, refreshAPI } from "../main/slice";
import { SERVER_URL } from "../../constants";
import _ from 'lodash';
import { customLabel } from "../utility/customLabel";
import {
  LOADING_MACHINE_EVENTS, ERRORS_MACHINE_EVENTS, LOADING_ODP_EVENTS, ERRORS_ODP_EVENTS,
  LOADING_VARS, ERRORS_VARS, LOADING_PROCESS_MACHINE_VARS, ERRORS_PROCESS_MACHINE_VARS, LOADING_USER_EVENTS, ERRORS_USER_EVENTS,
  LOADING_CHECKS, ERRORS_CHECKS
} from "../odps/utility/loadings";
import { customFilter } from "./utility";

export const initialState = {
  loading: false,
  reload: false,
  reloading: false, ///usato per i tasti ferma pausa riavvia ripristina
  hasErrors: false,
  odps: false,
  articleList: false,
  results: 0,
  editmode: false,
  odp: false,
  odpedit: false,
  deleting: false,
  isCreated: false,
  isDeleted: false,
  process: false,
  events: false,
  colors: false,
  totEvents: false,
  varList: false,
  supplierFlag: false, //server per mostrare il dialog scegli fornitore per avviare la lavorazione
  currentProcess: false,
  currentVar: false, //valore della variabile scelta per l'inserimento
  choosenVar: false,
  listVarFlag: false, //fase 1 inserimento variaible
  insertVarFlag: false, //fase 2 inserimento variabile
  adminVars: false,
  bomProcesses: false,
  flagEditbom: false,
  editProcess: false,
  reloadMachineEvents: false,
  resultsMachineEvents: false,
  currentMachineEvents: false,
  currentUserEvents: false,
  loadingOdpEvents: false,
  errorsOdpEvents: false,
  loadingMachineEvents: false,
  errorsMachineEvents: false,
  loadingUserEvents: false,
  errorsUserEvents: false,
  loadingVars: false,
  errorsVars: false,
  loadingProcessMachineVars: false,
  errorsProcessMachineVars: false,
  processMachineVars: false,
  currentVars: false,
  reloadProcessEvents: false,
  tabIndex: 0,
  editVar: false,
  filter: false,
  loadingChecks: false,
  currentChecks: false,
  editCheck: false
};

const maintenanceSlice = createSlice({
  name: "maintenances",
  initialState,
  reducers: {
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    gettingList: (state) => {
      state.loading = true;
      state.reload = false;
      state.hasErrors = false;
    },
    getListSuccess: (state, { payload }) => {
      state.odps = payload[1];
      state.results = payload[0].total_entries;
      state.loading = false;
    },
    enableLoading: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    setReload: (state) => {
      state.reload = true;
    },
    disableLoading: (state) => {
      state.loading = false;
    },
    enableReloading: (state) => {
      state.reloading = true;
      state.errors = false;
    },
    disableReloading: (state) => {
      state.reloading = false;
    },
    reset: (state) => {
      state.odp = false;
      state.odpedit = false;
      state.editmode = false;
      state.isDeleted = false;
      state.isCreated = false;
    },
    getting: (state) => {
      state.odp = false;
      state.events = false;
      state.colors = false;
      state.totEvents = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.loading = true;
      state.editmode = false;
      state.hasErrors = false;
    },
    getSuccess: (state, { payload }) => {
      state.odp = payload;
      state.loading = false;
    },
    creating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    create: (state) => {
      let newodp = {
        id: "new", maintenanceType: { id: '', code: '' }, machine: { id: "", name: "", code: "" }, deliveryDate: new Date().getTime(), toBeProcessed: true, maintenance: true,
        note: "", startDate: null
      };
      state.odp = newodp;
      state.odpedit = newodp;
      state.editmode = true;
    },
    createSuccess: (state, { payload }) => {
      state.odp = payload;
      state.odpedit = false;
      state.loading = false;
      state.isCreated = true;
      state.editmode = false;
    },
    cancelIsCreated: (state) => {
      state.isCreated = false;
    },
    resetEdit: (state) => {
      state.editmode = true;
      state.odpedit = state.odp;
      state.loading = false;
      state.hasErrors = true;
    },
    edit: (state) => {
      state.editmode = true;
      state.odpedit = _.cloneDeep(state.odp);
      const date = new Date(state.odpedit.deliveryDate).getTime();
      state.odpedit.deliveryDate = date;
    },
    cancelEdit: (state) => {
      state.editmode = false;
      state.odpedit = false;
    },
    change: (state, { payload }) => {
      let u = state.odpedit;
      if (payload.name === "machine.id") {
        let lista = payload.machines.slice();
        let indexOfMachines = lista.findIndex((l) => {
          return l.id === payload.value
        });
        u["machine"] = lista[indexOfMachines]
        u["maintenanceType"] = { id: '' }
      } else if (payload.name === "maintenanceType.id") {
        let lista = payload.maintenanceTypes.slice();
        let indexOfMts = lista.findIndex((l) => {
          return l.id === payload.value
        });
        u["maintenanceType"] = lista[indexOfMts]
      } else {
        u[payload.name] = payload.value;
      }
      state.odpedit = u;
    },
    changeObject: (state, { payload }) => {
      let u = state.odpedit;
      if (payload.name === "maintenanceType.id") {
        u["maintenanceType"] = { id: "" }
      } else if (payload.name === "machine.id") {
        u["machine"] = { id: "" }
        u["maintenanceType"] = { id: "" }
      }
      state.odpedit = u;
    },
    updating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    updateSuccess: (state, { payload }) => {
      state.odp = payload;
      state.loading = false;
      state.hasErrors = false;
      state.editmode = false;
      //aggiorno current process e edit process
      const processes = payload.processes.slice();
      const currentProcessIndex = payload.currentProcess;
      if (currentProcessIndex !== null && currentProcessIndex >= 0) {
        state.currentProcess = processes[currentProcessIndex];
        state.editProcess = processes[currentProcessIndex];
      } else {
        state.currentProcess = false;
        state.editProcess = false;
      }
    },
    confirmDelete: (state) => {
      state.deleting = !state.deleting;
    },
    deleting: (state) => {
      state.deleting = false;
      state.loading = true;
      state.hasErrors = false;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.isDeleted = true;
    },
    //aggiorna le lavorazioni al interno della commessa periodicamente
    updateOdp: (state, { payload }) => {
      state.reloading = false;
      state.reload = false;
      state.odp = payload;
      if (state.currentProcess) {
        let oldId = state.currentProcess.id;
        const indexOfProcess = state.odp.processes.findIndex((i) => {
          return i.id.toString() === oldId.toString();
        });
        if (indexOfProcess !== -1) {
          state.currentProcess = state.odp.processes[indexOfProcess];
        }
      }
      if (state.editProcess) {
        let oldId = state.editProcess.id;
        const indexOfProcess = state.odp.processes.findIndex((i) => {
          return i.id.toString() === oldId.toString();
        });
        if (indexOfProcess !== -1) {
          state.editProcess = state.odp.processes[indexOfProcess];
        }
      }
    },
    selectProcess: (state, { payload }) => {
      state.process = payload;
    },
    onChangeProcess: (state, { payload }) => {
      let process = state.process
      if (payload.name === "machine") {
        process[payload.name] = { 'id': payload.value }
      }
      if (payload.name === "note") {
        process[payload.name] = payload.value
      }
      state.process = process
    },
    gettingProcess: (state) => {
      state.process = false;
      state.loading = true;
      state.hasErrors = false;
    },
    //aggiorna il process dopo inserimento macchina o note
    updateProcessSuccess: (state, { payload }) => {
      let processes = state.odp.processes
      let indexOfProcess = processes.findIndex((p) => {
        return p.id === payload.id
      });
      processes[indexOfProcess] = payload;
      state.odp.processes = [...processes];
      state.odp = payload
      state.loading = false;
    },
    //una volta aggiornato il process con i pulsanti (avvia ferma pausa riapri ripristina) imposta reload a true per ricaricare l'odp
    updateProcessOperatorSuccess: (state) => {
      state.reload = true;
      state.reloading = false;
    },
    //se uno dei tasti di azioni rapide da errore fa il reload 
    enableProcessErrors: (state) => {
      state.reloading = false;
      state.hasErrors = true;
      state.reload = true;
    },
    enableErrors: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.reloading = false;
    },
    loadOdpEventsSuccess: (state, { payload }) => {
      let events = []
      let kpiEvents = []
      let colors = {}
      for (let e in payload.me) {
        let mt = payload.me[e]
        colors[mt.name] = mt.backgroundColor
      }

      for (let x in payload.data["events"]) {
        let me = payload.data["events"][x]
        for (let e in payload.me) {
          let mt = payload.me[e]
          if (mt.name === me[1]) {
            let v = 0
            let vKPI = 0
            if (payload.data.tot[0] !== null) {
              v = me[0] / payload.data.tot[0] * 100
            }
            if (payload.data.totKPI[0] !== null) {
              vKPI = me[0] / payload.data.totKPI[0] * 100
            }
            events.push({ id: mt.name, label: mt.name, color: mt.backgroundColor, value: v.toFixed(2), svalue: me[0] })
            if (!mt.excludeFromKPI) {
              kpiEvents.push({ id: mt.name, label: mt.name, color: mt.backgroundColor, value: vKPI.toFixed(2), svalue: me[0] })
            }
          }
        }
      }
      state.totEvents = payload.data.tot[0]
      state.events = events
      state.kpiEvents = kpiEvents
      state.colors = colors
      state.loading = false;
    },
    setVarList: (state, { payload }) => {
      state.varList = [...payload];
      state.loading = false;
    },
    unSetVarList: (state) => {
      state.varList = false;
    },
    setSupplierFlag: (state) => {
      state.supplierFlag = true;
    },
    unSetSupplierFlag: (state) => {
      state.supplierFlag = false;
    },
    setCurrentProcess: (state, { payload }) => {
      state.currentProcess = state.odp.processes[payload];
    },
    unSetCurrentProcess: (state) => {
      state.currentProcess = false;
    },
    changeCurrentVar: (state, { payload }) => {
      state.currentVar = payload.value;
    },
    enableVarFlag: (state) => {
      state.listVarFlag = true;
    },
    disableVarFlag: (state) => {
      state.listVarFlag = false;
    },
    enableInsertVarFlag: (state, { payload }) => {
      state.insertVarFlag = true;
      state.listVarFlag = false;
      state.currentVar = null;
      state.choosenVar = payload;
    },
    disableInsertVarFlag: (state) => {
      state.insertVarFlag = false;
      state.listVarFlag = true;
      state.currentVar = false;
      state.choosenVar = false;
    },
    varInsertedSuccess: (state) => {
      state.loading = false;
      state.currentVar = false;
      state.insertVarFlag = false;
      state.listVarFlag = true;
      state.reload = true;
      state.choosenVar = false;
    },
    enableVarErrors: (state) => {
      state.hasErrors = true;
      state.loading = false;
      state.insertVarFlag = false;
      state.listVarFlag = true;
      state.currentVar = false;
      state.reload = true;
      state.choosenVar = false;
    },
    setBomProcesses: (state, { payload }) => {
      state.bomProcesses = payload;
    },
    enableFlagEditBom: (state) => {
      state.flagEditBom = true;
    },
    disableFlagEditBom: (state) => {
      state.flagEditBom = false;
    },
    onChangeSort: (state, { payload }) => {
      state.bomProcesses = [...payload];
    },
    addProcess: (state, { payload }) => {
      let item = _.cloneDeep(payload)
      item["sortOrder"] = state.bomProcesses.length
      state.bomProcesses.push(item);
    },
    removeProcess: (state, { payload }) => {
      let bomProcesses = _.cloneDeep(state.bomProcesses)
      let index = bomProcesses.findIndex(v => {
        return v.sortOrder === payload.sortOrder;
      });
      state.bomProcesses = [...bomProcesses.filter((_, i) => i !== index)];
    },
    setEditProcess: (state, { payload }) => {
      const processes = payload.processes.slice();
      const currentProcessId = payload.currentProcessId;
      let indexCurrentProcess = processes.findIndex((p) => {
        return p.id.toString() === currentProcessId.toString();
      });
      if (indexCurrentProcess !== -1) {
        state.editProcess = processes[indexCurrentProcess];
      }
    },
    unSetEditProcess: (state) => {
      state.editProcess = false;
    },
    setReloadMachineEvents: (state) => {
      state.reloadMachineEvents = true;
    },
    setReloadProcessEvents: (state) => {
      state.reloadProcessEvents = true
    },
    setCurrentMachineEvents: (state, { payload }) => {
      state.loadingMachineEvents = false;
      state.reloadMachineEvents = false;
      state.resultsMachineEvents = payload[0].total_entries;
      if (payload[1] && payload[1].length > 0) {
        state.currentMachineEvents = payload[1].slice();
      } else {
        state.currentMachineEvents = [];
      }
    },
    setCurrentUserEvents: (state, { payload }) => {
      state.loadingUserEvents = false;
      if (state.currentUserEvents === false) {
        state.currentUserEvents = {}
      }
      if (payload && payload.data.userEvents && payload.data.userEvents.length > 0) {
        state.currentUserEvents[payload.process.id] = [...payload.data.userEvents];
      } else {
        state.currentUserEvents[payload.process.id] = [];
      }
    },
    enableCustomLoading: (state, { payload }) => {
      state[payload.loading] = true;
      state[payload.errors] = false;
    },
    enableCustomErrors: (state, { payload }) => {
      state[payload.loading] = false;
      state[payload.errors] = true;
    },
    setCurrentVars: (state, { payload }) => {
      state.loadingVars = false;
      state.currentVars = payload;
    },
    fillProcessMachineVars: (state, { payload }) => {
      state.loadingProcessMachineVars = false;
      state.processMachineVars = payload;
    },
    changeTabIndex: (state, { payload }) => {
      state.tabIndex = payload;
      if (payload === 0 && state.odp && state.odp.id !== "new") {
        state.reload = true;
      }
    },
    resetVar: (state) => {
      state.editVar = false
    },
    setVar: (state, { payload }) => {
      state.editVar = payload;
    },
    addVar: (state) => {
      state.editVar = { "id": "new", "type": "", multiple: false, name: "", incremental: false, operatorEdit: false, adminEdit: false, editable: false, serial: false, operatorView: false, measure: "" }
    },
    changeVar: (state, { payload }) => {
      let c = state.editVar;
      c[payload.name] = payload.value;
      state.editVar = c;
    },
    updatedProcessVar: (state, { payload }) => {
      state.editVar = false
      state.loading = false;
      let currentVars = _.cloneDeep(state.currentVars)
      let index = currentVars.findIndex(v => {
        return v.id.toString() === payload.id.toString();
      });
      currentVars[index] = payload;
      state.currentVars = currentVars;
    },
    addedProcessVar: (state, { payload }) => {
      state.editVar = false
      state.loading = false;
      if (payload.serial === undefined) {
        state.currentVars = payload;
      } else {
        let currentVars = _.cloneDeep(state.currentVars)
        currentVars.push(payload);
        state.currentVars = currentVars;
      }
    },
    changeFilter: (state, { payload }) => {
      state.filter[payload.name] = payload.value;
    },
    /**
     * 
     * @param {*} state 
     * @param {boolean} payload showFilter 
     */
    initFilter: (state, { payload }) => {
      let tempFilter = { ...customFilter };
      tempFilter["showFilter"] = payload;
      state.filter = { ...tempFilter };
    },
    setCurrentChecks: (state, { payload }) => {
      state.loadingChecks = false;
      state.currentChecks = payload;
    },
    setCheck: (state, { payload }) => {
      state.editCheck = payload;
    },
    resetCheck: (state) => {
      state.editCheck = false;
    },
    addCheck: (state) => {
      state.editCheck = {
        "id": "new", "type": "", name: "", start: false, close: false, restart: false, pause: false, onDemand: false, varName: ""
      }
    },
    changeCheck: (state, { payload }) => {
      let c = state.editCheck;
      c[payload.name] = payload.value;
      state.editCheck = c;
    },
    updatedProcessCheck: (state, { payload }) => {
      state.editCheck = false
      state.loading = false;
      let currentChecks = _.cloneDeep(state.currentChecks)
      let index = currentChecks.findIndex(c => {
        return c.id.toString() === payload.id.toString();
      });
      currentChecks[index] = payload;
      state.currentChecks = currentChecks;
    },
    addedProcessCheck: (state, { payload }) => {
      state.editCheck = false
      state.loading = false;
      let currentChecks = _.cloneDeep(state.currentChecks)
      currentChecks.push(payload);
      state.currentChecks = currentChecks;
    },
  }
});

export const { gettingList, getListSuccess, setReload, reset, getting, getSuccess, create, creating, createSuccess, edit, cancelEdit, change,
  changeObject, updating, updateSuccess, confirmDelete, deleting, deleteSuccess, resetEdit, loadingodps, loadingodpsSuccess, updateOdp,
  selectProcess, onChangeProcess, updateProcessSuccess, gettingProcess, enableErrors, loadOdpEventsSuccess, cancelIsCreated,
  enableLoading, disableLoading, enableReloading, disableReloading, setVarList, unSetVarList, setSupplierFlag, unSetSupplierFlag, updateProcessOperatorSuccess,
  setCurrentProcess, unSetCurrentProcess, changeCurrentVar, enableInsertVarFlag, disableInsertVarFlag, enableVarFlag, disableVarFlag, varInsertedSuccess, enableVarErrors,
  enableProcessErrors, resetState, setBomProcesses, enableFlagEditBom, disableFlagEditBom, onChangeSort, addProcess, removeProcess, setEditProcess, unSetEditProcess,
  setReloadMachineEvents, setCurrentMachineEvents, setCurrentUserEvents, enableCustomLoading, enableCustomErrors, setCurrentVars, fillProcessMachineVars,
  setReloadProcessEvents, changeTabIndex, resetVar, setVar, addVar, changeVar, updatedProcessVar, addedProcessVar, changeFilter, initFilter, setCurrentChecks,
  setCheck, resetCheck, addCheck, changeCheck, updatedProcessCheck, addedProcessCheck } = maintenanceSlice.actions;

export const maintenancesSelector = (state) => state.maintenances;

export default maintenanceSlice.reducer;

/**
 * 
 * @param {*} page 
 * @param {*} rowsPerPage 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} extraordinary 
 * @param {*} toBeProcessed 
 * @param {*} maintenanceType 
 * @param {*} machine 
 * @param {*} deliveryDateFrom 
 * @param {*} deliveryDateTo 
 * @returns 
 */
export function getListAPI(page, rowsPerPage, orderBy, order, extraordinary, toBeProcessed, maintenanceType, machine, deliveryDateFrom, deliveryDateTo) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(gettingList());
    dispatch(reset());
    let url = SERVER_URL + "/api/maintenance?&page=" + page + "&per_page=" + rowsPerPage + "&orderBy=" + orderBy + "&order=" + order;
    if (machine) {
      url = url + "&machine=" + machine.id;
    }
    if (toBeProcessed) {
      url = url + "&toBeProcessed=" + toBeProcessed.id;
    }
    if (extraordinary) {
      url = url + "&extraordinary=" + extraordinary.id;
    }
    if (maintenanceType) {
      url = url + "&maintenanceType=" + maintenanceType.id;
    }
    if (deliveryDateFrom) {
      url = url + "&deliveryDateFrom=" + deliveryDateFrom
    }
    if (deliveryDateTo) {
      url = url + "&deliveryDateTo=" + deliveryDateTo
    }

    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getListSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function updateAPI(odp) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { odp: odp };
  return async (dispatch) => {
    dispatch(updating());
    const response = await fetch(SERVER_URL + "/api/maintenance/" + odp.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(resetEdit());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function createAPI(odp) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(creating());
    let params = { odp: odp };
    const response = await fetch(SERVER_URL + "/api/maintenance", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function getAPI(id) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(getting());
    const response = await fetch(SERVER_URL + "/odp/" + id, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function deleteAPI(odp) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(deleting());
    const response = await fetch(SERVER_URL + "/api/maintenance/" + odp.id, {
      mode: "cors",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(deleteSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function reloadingOdpAPI(odp, machineEvents, errorsOdpEvents) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  //let url = odp.maintenance ? "/maintenance/" : "/odp/";
  return async (dispatch) => {
    dispatch(enableReloading());
    const response = await fetch(SERVER_URL + "/odp/" + odp.id, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateOdp(data));
        if (machineEvents && !errorsOdpEvents) {
          dispatch(getOdpEventsAPI(odp, machineEvents))
        }
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}


//cambio struttura distinta
export function updateProcessesAPI(odp, processes) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { odp: odp, processes: processes };
  return async (dispatch) => {
    dispatch(gettingProcess());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/processes", {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateProcessSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};
export function getOdpEventsAPI(odp, machineEvents) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(enableCustomLoading({ loading: LOADING_ODP_EVENTS, errors: ERRORS_ODP_EVENTS }));
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/events", {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      }
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(loadOdpEventsSuccess({ data: data, me: machineEvents }));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableCustomErrors({ loading: LOADING_ODP_EVENTS, errors: ERRORS_ODP_EVENTS }));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_ODP_EVENTS, errors: ERRORS_ODP_EVENTS }));
    }
  }
};

export function updateProcessNoteAPI(process) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { process: process };
  return async (dispatch) => {
    dispatch(gettingProcess());
    const response = await fetch(SERVER_URL + "/process/" + process.id + "/note/", {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateProcessSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function pauseProcessAPI(odpId, processId, username, machineId) {
  return async dispatch => {
    dispatch(enableReloading());
    let params = { user: { username: username }, machine: { id: machineId } }
    const response = await fetch(SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/pause/", {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updateProcessOperatorSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableProcessErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};

export function stopProcessAPI(odpId, processId, username, machineId) {
  return async dispatch => {
    dispatch(enableReloading());
    let params = { user: { username: username }, machine: { id: machineId } }
    const response = await fetch(SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/stop/", {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updateProcessOperatorSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableProcessErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};
export function reopenProcessAPI(odpId, processId) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async dispatch => {
    dispatch(enableReloading());
    const response = await fetch(SERVER_URL + "/api/odp/" + odpId + "/process/" + processId + "/reopen/", {
      mode: 'cors',
      method: 'POST',
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updateProcessOperatorSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableProcessErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};
export function restoreProcessAPI(odpId, processId) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async dispatch => {
    dispatch(enableReloading());
    const response = await fetch(SERVER_URL + "/api/odp/" + odpId + "/process/" + processId + "/restore/", {
      mode: 'cors',
      method: 'POST',
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updateProcessOperatorSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableProcessErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};

export function startProcessAPI(odpId, processId, username, machineId) {
  return async dispatch => {
    dispatch(enableReloading());
    let params = { user: { username: username }, machine: { id: machineId } }
    const response = await fetch(SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/start/", {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(unSetSupplierFlag());
        dispatch(updateProcessOperatorSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableProcessErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};
export function getListVarsAPI(odpId, processId, varId) {
  return async dispatch => {
    dispatch(enableLoading());
    const response = await fetch(SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/var/type/" + varId, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    });

    const data = await response.json()
    try {
      if (response.status === 200) {
        dispatch(setVarList(data));
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};
export function insertVarsAPI(odpId, processId, varId, value, username, machineId) {
  return async dispatch => {
    dispatch(enableLoading());
    let params = { value: value, user: { username: username }, machine: { id: machineId } }
    const response = await fetch(SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/var/" + varId, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(varInsertedSuccess());
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableVarErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableVarErrors());
    }
  }
};

export function stopOdpAPI(odp) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  //let url = odp.maintenance ? "/maintenance/" : "/odp/";
  let url = SERVER_URL + "/api/odp/" + odp.id + "/stop";
  return async (dispatch) => {
    dispatch(enableReloading());
    const response = await fetch(url, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateOdp(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function getProcessMachineEventsAPI(odp, process, page, rowsPerPage, orderBy, order, type, machine, user, startDateFrom, endDateFrom, startDateTo, endDateTo) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_MACHINE_EVENTS, errors: ERRORS_MACHINE_EVENTS }))
    let url = SERVER_URL + '/api/odp/' + odp.id + '/process/' + process.id + '/machineEvent?page=' + page + "&per_page=" + rowsPerPage + "&orderBy=" +
      orderBy + "&order=" + order;

    if (type !== false) {
      url = url + "&type=" + type.id;
    }
    if (machine !== false) {
      url = url + "&machine=" + machine.id;
    }
    if (user !== false) {
      url = url + "&user=" + user.id;
    }
    if (startDateFrom !== false) {
      url = url + "&startDateFrom=" + startDateFrom;
    }
    if (endDateFrom !== false) {
      url = url + "&endDateFrom=" + endDateFrom;
    }
    if (startDateTo !== false) {
      url = url + "&startDateTo=" + startDateTo;
    }
    if (endDateTo !== false) {
      url = url + "&endDateTo=" + endDateTo;
    }

    const response = await fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(setCurrentMachineEvents(data))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableCustomErrors({ loading: LOADING_MACHINE_EVENTS, errors: ERRORS_MACHINE_EVENTS }))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_MACHINE_EVENTS, errors: ERRORS_MACHINE_EVENTS }))
    }
  }
}

export function getProcessUserEventsAPI(odp, process) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_USER_EVENTS, errors: ERRORS_USER_EVENTS }))
    const response = await fetch(SERVER_URL + '/api/odp/' + odp.id + '/process/' + process.id, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(setCurrentUserEvents({ data: data, process: process }))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableCustomErrors({ loading: LOADING_USER_EVENTS, errors: ERRORS_USER_EVENTS }))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_USER_EVENTS, errors: ERRORS_USER_EVENTS }))
    }
  }
};

export function getProcessVarsAPI(odp, process) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_VARS, errors: ERRORS_VARS }))
    const response = await fetch(SERVER_URL + '/api/odp/' + odp.id + '/process/' + process.id + '/var', {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(setCurrentVars(data))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableCustomErrors({ loading: LOADING_VARS, errors: ERRORS_VARS }))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_VARS, errors: ERRORS_VARS }));
    }
  }
}

export function getProcessMachineVarsAPI(odp, process) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_PROCESS_MACHINE_VARS, errors: ERRORS_PROCESS_MACHINE_VARS }))
    const response = await fetch(SERVER_URL + '/odp/' + odp.id + '/process/' + process.id + "/machineVar", {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(fillProcessMachineVars(data))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableCustomErrors({ loading: LOADING_PROCESS_MACHINE_VARS, errors: ERRORS_PROCESS_MACHINE_VARS }))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_PROCESS_MACHINE_VARS, errors: ERRORS_PROCESS_MACHINE_VARS }))
    }
  }
};

// crea variabile
export function createVar(odp, process, processVar) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { var: processVar };
  return async (dispatch) => {
    dispatch(gettingProcess());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/process/" + process.id + "/var", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(addedProcessVar(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};
// aggiorna variabile
export function updateVar(odp, process, processVar) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { var: processVar };
  return async (dispatch) => {
    dispatch(gettingProcess());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/process/" + process.id + "/var/" + processVar.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updatedProcessVar(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};

export function getProcessChecksAPI(odp, process) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_CHECKS, errors: ERRORS_CHECKS }))
    const response = await fetch(SERVER_URL + '/api/odp/' + odp.id + '/process/' + process.id + '/check', {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(setCurrentChecks(data))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableCustomErrors({ loading: LOADING_CHECKS, errors: ERRORS_CHECKS }))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_CHECKS, errors: ERRORS_CHECKS }))
    }
  }
}

//aggiorna check
export function updateCheckAPI(odp, process, processCheck) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { check: processCheck };
  return async (dispatch) => {
    dispatch(gettingProcess());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/process/" + process.id + "/check/" + processCheck.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updatedProcessCheck(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};
// creo check
export function createCheckAPI(odp, process, processCheck) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { check: processCheck };
  return async (dispatch) => {
    dispatch(gettingProcess());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/process/" + process.id + "/check", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(addedProcessCheck(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};