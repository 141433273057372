import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: false,
  socketError: false,
  success: false,
  translate: true,
  socketErrorType: false
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    showError: (state, { payload }) => {
      state.error = payload;
    },
    showSuccess: (state, { payload }) => {
      if (payload.noTranslate) {
        state.success = payload.message;
        state.translate = false;
      } else {
        state.success = payload;
        state.translate = true;
      }
    },
    resetErrors: (state) => {
      state.error = false;
    },
    showSocketError: (state, { payload }) => {
      state.socketError = payload
      if(typeof payload === 'string') {
        if(payload.includes('CorrelationId')) {
          state.socketErrorType = 'ERP'
        } else {
          state.socketErrorType = 'MES'
        }
      }
    },
  },
});

export const { showError, showSuccess, resetErrors, showSocketError } = notificationsSlice.actions;
export const notificationsSelector = (state) => state.notifications;
export default notificationsSlice.reducer;
