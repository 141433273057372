import { TablePagination } from '@material-ui/core';
import { customLabel } from '../../../../utility/customLabel';

/**
 * 
 * @param {object} total false se non valorizzato, numero altrimenti
 * @param {customFilter} customFilter
 * @param {function} handleChangePage
 * @param {function} handleChangeRowsPerPage
 * @param {string} typeStatus tipo degli odp, usare currentList.url
 * @param {boolean} disabledChange usato per disabilitare durante il loading
 * @returns 
 */
const PaginationFragment = ({ total, customFilter, handleChangePage, handleChangeRowsPerPage, typeStatus, disabledChange }) => {
  return (
    <TablePagination labelRowsPerPage={customLabel("function.rowsPerPage")} rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} component="div" count={total}
      rowsPerPage={customFilter.rowsPerPage} page={customFilter.page} onChangePage={(e, page) => handleChangePage(e, page, typeStatus)}
      onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e, typeStatus)}
      SelectProps={{
        disabled: disabledChange
      }}
      backIconButtonProps={
        disabledChange
          ? {
            disabled: disabledChange
          }
          : undefined
      }
      nextIconButtonProps={
        disabledChange
          ? {
            disabled: disabledChange
          }
          : undefined
      }
    />
  )
}

export default PaginationFragment
