import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../constants";
import { showError, showSuccess } from "../notifications/slice";
import { HTTP_HEADER } from "./utility/constants";
import {
    LOADING_MACHINE, ERRORS_MACHINE, LOADING_USER, ERRORS_USER,
    LOADING_MACHINES, ERRORS_MACHINES
} from "./utility/loadings";
import { editSplitPayload } from "./utility/constants";

export const initialState = {
    user: false,
    machine: false,
    machines: false,
    loadingUser: false,
    errorsUser: false,
    loadingMachine: false,
    errorsMachine: false,
    loadingProcess: false,
    errorsProcess: false,
    loadingMachines: false,
    errorsMachines: false,
    flagSplitProcess: false,
    flagDeleteProcess: false,
    newQuantity: false,
    newMachine: false,
    processes: false,
    loadingProcesses: false,
    currentProcess: false,
    newProcess: false,
    loading: false,
    initialQuantity: false,
    reload: false,
    splitDialog: false,
    thisProcess: false,
};

const splitSlice = createSlice({
    name: "split",
    initialState,
    reducers: {
        resetState: (state) => {
            Object.assign(state, initialState);
        },
        enableCustomLoading: (state, { payload }) => {
            state[payload.loading] = true;
            state[payload.errors] = false;
        },
        enableCustomErrors: (state, { payload }) => {
            state[payload.loading] = false;
            state[payload.errors] = true;
        },
        getMachineSuccess: (state, { payload }) => {
            state.loadingMachine = false;
            state.machine = payload;
        },
        getUserSuccess: (state, { payload }) => {
            state.loadingUser = false;
            state.user = payload;
        },
        getProcessSuccess: (state, { payload }) => {
            state.loadingProcess = false;
            state.process = payload.process;
        },
        getMachinesSuccess: (state, { payload }) => {
            state.loadingMachines = false;
            state.machines = payload.machines;
        },
        getProcessesSuccess: (state, { payload }) => {
            state.processes = payload
            state.loading = false
        },
        selectProcess: (state, { payload }) => {
            state.currentProcess = payload.process
            state.newMachine = payload.machine
            if (state.flagEditProcess) {
                state.newQuantity = state.currentProcess.quantity;
            }
        },
        /**
         * Riceve il nome del flag è il parametro open, per settare il flag a true o false di conseguenza
         * @param {string} payload.flag 
         * @param {boolean} payload.open 
         */
        handleCustomFlag: (state, { payload }) => {
            state[payload.flag] = payload.open;
        },
        restoreSplitFields: (state) => {
            state.newQuantity = false;
            state.newMachine = false;
            state.newProcess = false;
        },
        /**
         * 
         * @param {string} payload.name passare valori all'interno della costante editSplitPayload 
         * @param {*} payload.value 
         * @param {array} payload.machines
         */
        editSplitFields: (state, { payload }) => {
            let value = payload.value;
            let name = payload.name;
            if (name === editSplitPayload.newMachine && payload.value) {
                let lista = payload.machines.slice();
                let indexOfM = lista.findIndex((l) => {
                    return l.machine_id.toString() === payload.value.toString();
                });
                state.newMachine = lista[indexOfM];
            }
            if (name === editSplitPayload.newMachine && !payload.value) {
                state.newMachine = false;
            }
            if (name === editSplitPayload.newQuantity) {
                state.newQuantity = value;
            }
            if (name === editSplitPayload.newProcess && payload.value) {
                let lista = state.processes.slice();
                let indexOfM = lista.findIndex((l) => {
                    return l.id.toString() === payload.value.toString();
                });
                state.newProcess = lista[indexOfM];
            }
        },
        getting: (state, { payload }) => {
            state.thisProcess = payload
            state.loading = true
        },
        creating: state => {
            state.loading = true
        },
        deleting: state => {
            state.loading = true
        },
        createSplitSuccess: state => {
            state.loading = false
        },
        deleteSplitSuccess: state => {
            state.loading = false
        },
        enableErrors: state => {
            state.loading = false
        },
        enableSplitDialog: state => {
            state.splitDialog = true
        },
        disableSplitDialog: state => {
            state.splitDialog = false
            state.processes = false
        },
        editing: state => {
            state.loading = true
        },
        editSplitSuccess: state => {
            state.loading = false
        },
    },
});

export const { resetState, enableCustomLoading, enableCustomErrors, getMachineSuccess, getUserSuccess, getProcessSuccess, getMachinesSuccess,
    handleCustomFlag, restoreSplitFields, editSplitFields, getProcessesSuccess, selectProcess, createSplitSuccess, deleteSplitSuccess,
    getting, creating, deleting, enableErrors, enableSplitDialog, disableSplitDialog, editing, editSplitSuccess,
} = splitSlice.actions;
export const splitSelector = (state) => state.split;
export default splitSlice.reducer;

/**
 * Chiamata con qui viene valorizzata la macchina
 * @param {int} machineId 
 * @returns 
 */
export function getMachineAPI(machineId) {
    return async (dispatch) => {
        dispatch(enableCustomLoading({ loading: LOADING_MACHINE, errors: ERRORS_MACHINE }))
        const response = await fetch(SERVER_URL + "/machine/" + machineId, HTTP_HEADER);
        const data = await response.json();
        if (response.status === 200) {
            dispatch(getMachineSuccess(data));
        } else {
            dispatch(showError(data.message));
            dispatch(enableCustomErrors({ loading: LOADING_MACHINE, errors: ERRORS_MACHINE }))
        }
    };
}

/**
 * Chiamata con qui viene valorizzto l'utente
 * @param {string} userCode 
 * @returns 
 */
export function getUserAPI(userCode) {
    return async (dispatch) => {
        dispatch(enableCustomLoading({ loading: LOADING_USER, errors: ERRORS_USER }))
        const response = await fetch(SERVER_URL + "/user/code/" + userCode, HTTP_HEADER);
        const data = await response.json();
        if (response.status === 200) {
            dispatch(getUserSuccess(data));
        } else {
            dispatch(showError(data.message));
            dispatch(enableCustomErrors({ loading: LOADING_USER, errors: ERRORS_USER }))
        }
    };
};
/** 
* Chiamata per valorizzare le macchine disponibili per il tipo lavorazione in questione
* @param {int} processTypeId 
* @returns 
*/
export function getProcessTypeAPI(processTypeId) {
    return async (dispatch) => {
        dispatch(enableCustomLoading({ loading: LOADING_MACHINES, errors: ERRORS_MACHINES }))
        const response = await fetch(SERVER_URL + '/processType/' + processTypeId, HTTP_HEADER);
        const data = await response.json();
        if (response.status === 200) {
            dispatch(getMachinesSuccess(data));
        } else {
            dispatch(showError(data.message));
            dispatch(enableCustomErrors({ loading: LOADING_MACHINES, errors: ERRORS_MACHINES }))
        }
    };
};

export function getSplitListAPI(process) {
    return async (dispatch) => {
        dispatch(getting(process))
        let url = SERVER_URL + "/split/odp/" + process.odp.id + "/processes/" + process.type.id;
        const response = await fetch(url, {
            mode: "cors",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        try {
            const data = await response.json();
            if (response.status === 200) {
                dispatch(getProcessesSuccess(data));
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors())
            }
        } catch (e) {
            dispatch(showError(e.message));
            dispatch(enableErrors())
        }
    };
}

export function createSplitAPI(process, odpId, processId, newMachine, newQuantity, user, machine) {
    return async dispatch => {
        dispatch(creating())
        let params = { "quantity": newQuantity, "currentMachine": { "id": machine.id }, "newMachine": { "id": newMachine.machine_id ? newMachine.machine_id : newMachine.id }, "user": { "username": user.username } }
        const response = await fetch(SERVER_URL + '/split/odp/' + odpId + '/process/' + processId, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(params)
        });
        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(createSplitSuccess());
                dispatch(showSuccess("function.operationSuccess"))
            } else {
                dispatch(showError(data.message))
                dispatch(enableErrors())
            }
        } catch (e) {
            dispatch(showError(e.message));
            dispatch(enableErrors())
        }
        dispatch(getSplitListAPI(process))
    }
}

export function deleteSplitAPI(process, odpId, processId, newProcessId, user, machine, processTypeId) {
    return async dispatch => {
        dispatch(deleting())
        let params = { "newProcess": { "id": newProcessId }, "user": { "username": user.username }, "machine": { "id": machine.id } }
        const response = await fetch(SERVER_URL + '/split/odp/' + odpId + '/process/' + processId, {
            mode: 'cors',
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(params)
        });
        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(deleteSplitSuccess());
                dispatch(showSuccess("function.operationSuccess"))
            } else {
                dispatch(showError(data.message))
                dispatch(enableErrors())
            }
        } catch (e) {
            dispatch(showError(e.message));
            dispatch(enableErrors())
        }
        dispatch(getSplitListAPI(process))
    }
}

export function editSplitAPI(process, odpId, processId, newMachine, newQuantity, user, newProcessId) {
    return async dispatch => {
        dispatch(editing())
        let nm = {}
        if (newMachine) {
            nm = { "id": newMachine.machine_id ? newMachine.machine_id : newMachine.id }
        }
        let params = { "quantity": newQuantity, "newProcess": { "id": newProcessId }, "newMachine": nm, "user": { "username": user.username } }
        const response = await fetch(SERVER_URL + '/split/odp/' + odpId + '/process/' + processId, {
            mode: 'cors',
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(params)
        });
        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(editSplitSuccess());
                dispatch(showSuccess("function.operationSuccess"))
            } else {
                dispatch(showError(data.message))
                dispatch(enableErrors())
            }
        } catch (e) {
            dispatch(showError(e.message));
            dispatch(enableErrors())
        }
        dispatch(getSplitListAPI(process))
    }
}