import React from "react";
import { Link } from "react-router-dom";
import { FormControl, InputLabel, Input, Divider, Button, Typography, TextField } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";

export const Form = ({ customeredit, handleChange, cancelEdit, save, loading }) => {
  const myClasses = mesStyles();

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h3" variant="h6" color="inherit">
          {customLabel("customer.customer")}
        </Typography>
        <LockOpenIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="code">{customLabel("customer.code")}</InputLabel>
          <Input autoFocus name="code" error={customeredit.code === ""} value={customeredit.code} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="name">{customLabel("customer.name")}</InputLabel>
          <Input name="name" error={customeredit.name === ""} value={customeredit.name} onChange={handleChange}
          />
        </FormControl>
        <FormControl className={myClasses.form100}>
          <TextField
            variant="outlined" name="note" label={customLabel("function.note")} multiline rows={4} value={customeredit.note}
            onChange={handleChange}
          />
        </FormControl>
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple onClick={save} disabled={customeredit.name === "" || customeredit.code === "" || loading}
          color="primary"
        >
          {customLabel("button.save")}
        </Button>
        {customeredit.id !== "new" ? (
          <Button focusRipple onClick={cancelEdit} disabled={loading}>
            {customLabel("button.cancel")}
          </Button>
        ) : (
          <Button component={Link} to={`/customers`} disabled={loading}>
            {customLabel("button.back")}
          </Button>
        )}
      </div>
    </PaperForm>
  );
};
