import React from "react";
import { useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { Paper, AppBar, Tabs, Tab, Table, TableRow, TableCell, Divider, Grid, Typography } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { mesStyles } from "../utility/ultrafabStyles";
import { makeStyles } from '@material-ui/core/styles';
import { MyPie } from "../machines/MyPie";
import ProgressOdp from "../utility/ProgressBarTwoLines";
import { machineEventsSelector } from '../machineEvents/slice';
import { setColor } from "../utility/vari";
import { machineViewSelector } from "./slice";
import PopoverLegend from "../utility/PopoverLegend";
import moment from "moment";
import { bishopFormats } from "../../constants";
import { secondsToHours, calcRemainingTime } from "../utility/vari";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import MomentUtils from "@date-io/moment";
import { useAnchor } from "../customHooks/useAnchor";

const useStyles = makeStyles((theme) => ({
    left: {
        margin: "5px",
        minHeight: "710px",
        maxHeight: "710px",
        overflowY: "auto",
        overflowX: "auto"
    },
    actionButton: {
        margin: "5px",
        width: '100%',
        padding: "15px",
    },
    alarm: {
        color: theme.palette.secondary.main,
        marginLeft: theme.spacing() / 2,
    },
    grafici: {
        display: "flex",
        justifyContent: "space-around",
    },
    divTitle: {
        marginLeft: "10px",
    },
    legend: {
        border: "1px solid rgba(0, 0, 0, 0.3)",
        padding: theme.spacing(1),
        borderRadius: "4px",
        marginLeft: theme.spacing() / 2,
        marginBottom: theme.spacing() / 2,
        textAlign: "center",
        minWidth: "160px"
    },
    processDetails: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
    },
    progressBar: {
        marginLeft: theme.spacing() / 2,
    },
    loadingCircle: {
        marginTop: theme.spacing(3),
        textAlign: "center"
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    text: {
        display: "inline-block",
        maxWidth: "90px",
        minWidth: "90px",
        height: '15px',
        whiteSpace: "nowrap",
        overflow: "hidden !important",
        textOverflow: "ellipsis",
    },
    list: {
        backgroundColor: theme.palette.background.paper,
        padding: '3px 0px',
        margin: '0px 0px'
    },
    listItem: {
        padding: '1px 8px',
        margin: '0px 0px'
    },
    title: {
        padding: '1px 8px 10px 8px',
        margin: '0px 0px'
    },
    legendLine: {
        display: "flex",
        justifyContent: "space-between"
    },
    legendSquare: {
        height: "15px",
        width: "15px",
        border: "solid 1px black",
        marginTop: "auto",
        marginLeft: "10px"
    },
    popoverContent: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}));

const CurrentMachineData = ({ process, machine, configuration, startDate, endDate, handleChangeDate, handlePopoverOpenText, handlePopoverCloseText }) => {
    const classes = useStyles();
    const myClasses = mesStyles()
    const { machineEvents } = useSelector(machineEventsSelector);
    const { machineEvent, processVars, machineEventStapling, stapling, staplingVars, customEvents, colors } = useSelector(machineViewSelector);
    const [anchorPopover, { handleOpen: handleOpenPopover, handleClose: handleClosePopover }, openPopover] = useAnchor();

    const getColor = bar => {
        return colors[bar.id];
    }

    return (
        <React.Fragment>
            <Paper className={classes.left}>
                <AppBar position="static">
                    <Tabs value={0} variant="scrollable" scrollButtons="auto">
                        <Tab label={customLabel("machineView.machineDetails")} key={"summary"} value={0} />
                    </Tabs>
                </AppBar>
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Table>
                                <tbody>
                                    <TableRow>
                                        <TableCell>
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                <DatePicker className={myClasses.datePickerTable}
                                                    label={customLabel("machineEvent.startDate")}
                                                    value={startDate}
                                                    onChange={(date) => handleChangeDate("startDate", date)}
                                                    format={bishopFormats.L}
                                                    cancelLabel={customLabel("function.cancel")}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </TableCell>
                                        <TableCell align="right">
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                <DatePicker className={myClasses.datePickerTable}
                                                    label={customLabel("machineEvent.endDate")}
                                                    value={endDate}
                                                    onChange={(date) => handleChangeDate("endDate", date)}
                                                    format={bishopFormats.L}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("machineEvent.machineEvent")}:</TableCell>
                                        <TableCell>
                                            {(machineEvent && machineEvent.type) || (machineEventStapling && machineEventStapling.type) ?
                                                <div
                                                    className={classes.legend}
                                                    style={{
                                                        backgroundColor: machineEvent ? machineEvent.type.backgroundColor : machineEventStapling.type.backgroundColor,
                                                        color: setColor(machineEvent ? machineEvent.type.backgroundColor : machineEventStapling.type.backgroundColor)
                                                    }}
                                                >
                                                    <b>{machineEvent ? machineEvent.type.name : machineEventStapling.type.name}</b>
                                                </div>
                                                : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            {customLabel(stapling && stapling.status ? "stapling.staplingStatus" : "processStatus.processStatus")}:
                                        </TableCell>
                                        <TableCell>
                                            {(process && process.status) || (stapling && stapling.status) ?
                                                <div
                                                    className={classes.legend}
                                                    style={{
                                                        backgroundColor: process ? process.status.backgroundColor : stapling.status.backgroundColor,
                                                        color: setColor(process ? process.status.backgroundColor : stapling.status.backgroundColor)
                                                    }}
                                                >
                                                    {stapling ?
                                                        <b>{stapling.status.name}</b>
                                                        : <b>{customLabel("process.process") + " " + process.status.name}</b>
                                                    }
                                                </div>
                                                : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                        </TableCell>
                                    </TableRow>
                                    {!stapling ?
                                        <TableRow>
                                            <TableCell>{customLabel("odp.progressOdp")}:</TableCell>
                                            <TableCell>
                                                {process ?
                                                    <div className={classes.progressBar}>
                                                        <ProgressOdp
                                                            total={process.quantity} current={process.validPieces !== null ? process.validPieces : process.totalPieces !== null ? process.totalPieces - process.rejectedPieces : 0}
                                                            measure={process.article.measure}
                                                        />
                                                    </div>
                                                    : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                            </TableCell>
                                        </TableRow> : null}
                                </tbody>
                            </Table>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div style={{ textAlign: "right", marginRight: "8px" }} onMouseEnter={(e) => handleOpenPopover(e.currentTarget, false)} onMouseLeave={handleClosePopover}>
                                <Typography
                                    component="h1" variant="h6" className={myClasses.dashedUnderline}>{customLabel("legend.legend")}
                                </Typography>
                            </div>
                            <div className={classes.grafici}>
                                <div style={{ margin: 0, height: 250, width: 250 }}>
                                    {customEvents && customEvents.length > 0 ?
                                        <MyPie data={customEvents} getColor={getColor} showArcLinkLabel={false} />
                                        : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Table size="small">
                                <tbody>
                                    {!stapling ?
                                        <TableRow>
                                            <TableCell>{customLabel("odp.customerOrder")}:</TableCell>
                                            <TableCell padding="none" onMouseLeave={handlePopoverCloseText}
                                                onMouseEnter={(e) => handlePopoverOpenText(e.currentTarget, false, process && process.odp ? process.odp.customerOrder.code
                                                    : customLabel("function.notAvailable").toUpperCase(), false, false)}>
                                                <b>{process && process.odp ?
                                                    process.odp.customerOrder.code
                                                    : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                                </b>
                                            </TableCell>
                                        </TableRow> : null
                                    }
                                    <TableRow>
                                        <TableCell>{customLabel(stapling && stapling.code ? "stapling.stapling" : "odp.odp")}:</TableCell>
                                        <TableCell padding="none" onMouseLeave={handlePopoverCloseText}
                                            onMouseEnter={(e) => handlePopoverOpenText(e.currentTarget, false, stapling && stapling.code ? stapling.code :
                                                process && process.odp ? process.odp.code
                                                    : customLabel("function.notAvailable").toUpperCase(), false, false)}>
                                            <b className={classes.text}>
                                                {stapling && stapling.code ? stapling.code
                                                    : process && process.odp ? process.odp.code
                                                        : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                            </b>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("processType.process")}:</TableCell>
                                        <TableCell padding="none" onMouseLeave={handlePopoverCloseText}
                                            onMouseEnter={(e) => handlePopoverOpenText(e.currentTarget, false, stapling && stapling.staplingProcesses.length > 0 ? stapling.staplingProcesses[0].name
                                                : process && process.type ? process.type.name
                                                    : customLabel("function.notAvailable").toUpperCase(), false, false)}>
                                            <b>
                                                {stapling && stapling.staplingProcesses.length > 0 ? stapling.staplingProcesses[0].name
                                                    : process && process.type ? process.type.name
                                                        : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                            </b>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("article.article")}:</TableCell>
                                        <TableCell padding="none" onMouseLeave={handlePopoverCloseText}
                                            onMouseEnter={(e) => handlePopoverOpenText(e.currentTarget, false, stapling && stapling.staplingProcesses.length > 0 ?
                                                stapling.staplingProcesses.map((sp, i) => `${sp.article.code}${stapling.staplingProcesses[i + 1] ? ', ' : ''}`)
                                                : process && process.article ? process.article.code
                                                    : customLabel("function.notAvailable").toUpperCase(), false, false)}>
                                            <b>
                                                {stapling && stapling.staplingProcesses.length > 0 ?
                                                    stapling.staplingProcesses.map((sp, i) => `${sp.article.code}${stapling.staplingProcesses[i + 1] ? ', ' : ''}`)
                                                    : process && process.article ? process.article.code
                                                        : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                            </b>
                                        </TableCell>
                                    </TableRow>
                                    {!stapling ?
                                        <>
                                            <TableRow>
                                                <TableCell>{customLabel("process.remainingTime")}:</TableCell>
                                                <TableCell padding="none">
                                                    <b>{process && process.cycleTime && process.cycleTime.cycleTime ?
                                                        calcRemainingTime(process.cycleTime ? process.cycleTime.cycleTime : null,
                                                            process.validPieces, process.totalPieces, process.quantity, true)
                                                        : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                                    </b>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>{customLabel("process.expectedSetupTime")}:</TableCell>
                                                <TableCell padding="none">
                                                    <b>{process && process.odp && process.odp.expectedSetupTime ?
                                                        secondsToHours(process.odp.expectedSetupTime)
                                                        : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                                    </b>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>{customLabel("process.expectedRunTime")}:</TableCell>
                                                <TableCell padding="none">
                                                    <b>{process && process.odp && process.odp.expectedRunTime ?
                                                        secondsToHours(process.odp.expectedRunTime)
                                                        : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                                    </b>
                                                </TableCell>
                                            </TableRow>
                                        </> :
                                        null
                                    }
                                </tbody>
                            </Table>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Table size="small">
                                <tbody>
                                    <TableRow>
                                        <TableCell>{customLabel("process.quantity")}:</TableCell>
                                        <TableCell padding="none">
                                            <b>{process && process.quantity ? process.quantity
                                                : stapling && stapling.quantity ? stapling.quantity
                                                    : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                            </b>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("process.deliveryDate")}:</TableCell>
                                        <TableCell padding="none">
                                            <b>{process && process.deliveryDate ? moment(process.deliveryDate).format(bishopFormats.LTS)
                                                : stapling && stapling.deliveryDate ? moment(stapling.deliveryDate).format(bishopFormats.LTS)
                                                    : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                            </b>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("process.startDate")}:</TableCell>
                                        <TableCell padding="none">
                                            <b>{process && process.startDate ? moment(process.startDate).format(bishopFormats.LTS)
                                                : stapling && stapling.startDate ? moment(stapling.startDate).format(bishopFormats.LTS)
                                                    : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                            </b>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("process.endDate")}:</TableCell>
                                        <TableCell padding="none">
                                            <b>{process && process.endDate ? moment(process.endDate).format(bishopFormats.LTS)
                                                : stapling && stapling.endDate ? moment(stapling.endDate).format(bishopFormats.LTS)
                                                    : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}
                                            </b>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}><b>{customLabel("process.processVariables")}:</b></TableCell>
                                    </TableRow>
                                    {processVars && processVars.length > 0 ? processVars.map((pv) =>
                                        <TableRow key={pv.id}>
                                            <TableCell>{pv.name}:</TableCell>
                                            <TableCell>
                                                {pv.type === "boolean" && pv.value === "false" ? <ClearIcon />
                                                    : pv.type === "boolean" && pv.value === "true" ? <CheckIcon />
                                                        : pv.value !== null && pv.type !== "number" ? <b>{pv.value}</b>
                                                            : pv.value !== null && pv.type === "number" ? <b>{Number(pv.value).toFixed(configuration.decimals)}</b>
                                                                : "-"}
                                            </TableCell>
                                        </TableRow>
                                    ) : staplingVars && staplingVars.length > 0 ? staplingVars.map((sv) =>
                                        <TableRow key={sv.id}>
                                            <TableCell>{sv.name}:</TableCell>
                                            <TableCell>
                                                {sv.type === "boolean" && sv.value === "false" ? <ClearIcon />
                                                    : sv.type === "boolean" && sv.value === "true" ? <CheckIcon />
                                                        : sv.value !== null && sv.type !== "number" ? <b>{sv.value}</b>
                                                            : sv.value !== null && sv.type === "number" ? <b>{Number(sv.value).toFixed(configuration.decimals)}</b>
                                                                : "-"}
                                            </TableCell>
                                        </TableRow>
                                    ) : <TableRow>
                                        <TableCell style={{ borderBottom: "none" }}>
                                            <b>{customLabel("function.notAvailable").toUpperCase()}</b>
                                        </TableCell>
                                    </TableRow>}
                                </tbody>
                            </Table>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </Paper>
            {anchorPopover ? (
                <PopoverLegend name={customLabel("legend.legend").toUpperCase()} list={machineEvents} open={openPopover} anchorEl={anchorPopover}
                    openPopover={handleOpenPopover} closePopover={handleClosePopover}
                    anchorVertical={"bottom"} anchorHorizontal={"right"} transformVertical={"center"} transormHorizontal={"left"} />
            ) : null}
        </React.Fragment >
    );
}

export default CurrentMachineData;