import React from "react";
import {
    Box, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Checkbox
} from "@material-ui/core";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";
import { labelTimeline, labelTimelineMachines, labelTimelineWorkcentres } from "../utility";

const Timeline = ({ configuration }) => {
    const myClasses = mesStyles();

    return (
        <Box className={myClasses.confDetailsBox}>
            <form className={myClasses.formContainer} noValidate autoComplete="off">
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="labelTimeline">{customLabel("configuration.labelTimeline.labelTimeline")}</InputLabel>
                    <Select name="labelTimeline" value={configuration.labelTimeline}>
                        <MenuItem value={labelTimeline["odpCode"]}>{customLabel("configuration.labelTimeline.odpCode")}</MenuItem>
                        <MenuItem value={labelTimeline["odpProcessTypeCode"]}>{customLabel("configuration.labelTimeline.odpProcessTypeCode")}</MenuItem>
                        <MenuItem value={labelTimeline["odpProcessTypeName"]}>{customLabel("configuration.labelTimeline.odpProcessTypeName")}</MenuItem>
                        <MenuItem value={labelTimeline["odpCustomerCode"]}>{customLabel("configuration.labelTimeline.odpCustomerCode")}</MenuItem>
                        <MenuItem value={labelTimeline["odpCustomerName"]}>{customLabel("configuration.labelTimeline.odpCustomerName")}</MenuItem>
                        <MenuItem value={labelTimeline["odpArticleCode"]}>{customLabel("configuration.labelTimeline.odpArticleCode")}</MenuItem>
                        <MenuItem value={labelTimeline["odpArticleName"]}>{customLabel("configuration.labelTimeline.odpArticleName")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="labelTimelineMachines">{customLabel("configuration.labelTimelineMachines.labelTimelineMachines")}</InputLabel>
                    <Select name="labelTimelineMachines" value={configuration.labelTimelineMachines}>
                        <MenuItem value={labelTimelineMachines["name"]}>{customLabel("configuration.labelTimelineMachines.name")}</MenuItem>
                        <MenuItem value={labelTimelineMachines["code"]}>{customLabel("configuration.labelTimelineMachines.code")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="labelTimelineWorkcentres">{customLabel("configuration.labelTimelineWorkcentres.labelTimelineWorkcentres")}</InputLabel>
                    <Select name="labelTimelineWorkcentres" value={configuration.labelTimelineWorkcentres}>
                        <MenuItem value={labelTimelineWorkcentres["name"]}>{customLabel("configuration.labelTimelineWorkcentres.name")}</MenuItem>
                        <MenuItem value={labelTimelineWorkcentres["code"]}>{customLabel("configuration.labelTimelineWorkcentres.code")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.showExtTimeline} name="showExtTimeline" color="default" />
                    }
                    label={customLabel("configuration.showExtTimeline")}
                />
            </form>
        </Box>
    );
};
export default Timeline;