import React from 'react'
import { Button } from '@material-ui/core';
import { customLabel } from "../../utility/customLabel";

/**
 * Restituisce due bottoni: Mostra/nascondi filtri e Svuota filtri
 * @param {object} filter 
 * @param {function} toggleFilter 
 * @param {function} emptyFilter 
 * @param {function} handleConfirm
 * @param {boolean} disabledConfirm
 * @returns 
 */
const ButtonsFragment = ({ filter, toggleFilter, emptyFilter, handleConfirm, disabledConfirm }) => {
    return (
        <React.Fragment>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <Button color="default" onClick={() => toggleFilter()} >
                        {filter.showFilter ? customLabel("button.hideFilters") : customLabel("button.showFilters")}
                    </Button>
                    {filter.showFilter ? (
                        <Button color="secondary" onClick={() => emptyFilter()} >
                            {customLabel("button.emptyFilters")}
                        </Button>
                    ) : null}
                </div>
                <Button onClick={() => handleConfirm()} color="primary" disabled={disabledConfirm}>
                    {customLabel("button.confirm")}
                </Button>
            </div>
        </React.Fragment>
    )
}

export default ButtonsFragment
