import React from 'react';
import { useSelector } from "react-redux";
import { customLabel } from '../../utility/customLabel';
import { TableCell, TableRow } from "@material-ui/core";
import { odpsSelector } from '../slice';
import { mesStyles } from '../../utility/ultrafabStyles';

const UserEvents = ({ odp, p, handleOpenPopover, handleClosePopover }) => {
    const myClasses = mesStyles();
    const { currentProcess, currentUserEvents, staplingUserEvents } = useSelector(odpsSelector);

    const renderUserEvents = (currentUserEvents, available, stapling) => {
        if (available) {
            return (
                currentUserEvents.map((u) => {
                    return (
                        <TableRow key={u.id}>
                            <TableCell colSpan={stapling ? 2 : null} style={{ paddingLeft: !stapling ? '5px' : null }}>
                                {customLabel("function.operator")}: {u.user.username}
                            </TableCell>
                            <TableCell colSpan={stapling ? 2 : null} style={{ paddingRight: !stapling ? '5px' : null }}>
                                <div className={myClasses.tableCellEventPlusColor}>
                                    {customLabel("userEvent.event")}: {u.type.name}
                                    <div className={myClasses.legendRectangle} onMouseEnter={(e) => handleOpenPopover(e.currentTarget, false)} onMouseLeave={handleClosePopover}
                                        style={{ backgroundColor: u.type.backgroundColor }}>
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow >
                    )
                })
            )
        } else {
            return (
                <TableRow>
                    <TableCell colSpan={stapling ? 2 : null} style={{ paddingLeft: !stapling ? '5px' : null }}>{customLabel("function.operator")}: {customLabel("function.notAvailable").toUpperCase()}</TableCell>
                    <TableCell colSpan={stapling ? 2 : null} style={{ paddingRight: !stapling ? '5px' : null }}>{customLabel("userEvent.event")}: {customLabel("function.notAvailable").toUpperCase()}</TableCell>
                </TableRow>
            )
        }
    }

    const renderStandardUserEvents = () => {
        if (p && p.stapling.id === null && ((currentProcess !== null && p.id === currentProcess.id) || (p.startDate !== null && p.endDate === null))) {
            return renderUserEvents(currentUserEvents[p.id], currentUserEvents && currentUserEvents[p.id] !== undefined && currentUserEvents[p.id].length > 0, false)
        } else {
            return null
        }
    }

    if (odp && !odp.stapling) {
        return renderStandardUserEvents()
    } else {
        return renderUserEvents(staplingUserEvents, staplingUserEvents.length > 0, true)
    }

};

export default UserEvents;
