import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket from 'react-use-websocket';
import useInterval from 'react-useinterval';
import { WEBSOCKET_URL_CHECKS, WEBSOCKET_URL_ERROR } from '../../../constants';
import { ALERT_PRODUCED_QUANTITY, ALERT_TYPE_WARNING } from '../../alerts/constants';
import { addAlert, alertsSelector, resetState as resetAlertsState } from "../../alerts/slice";
import { checksSelector } from "../../checks/slice";
import { useBoolean } from "../../customHooks/useBoolean";
import { showSocketError } from "../../notifications/slice";
import { getAPI as getProcessTypeAPI } from "../../processTypes/slice";
import { splitSelector } from "../../split/slice";
import { customLabel } from '../../utility/customLabel';
import { varsSelector } from '../../vars/slice';
import { useSocketOnce } from "../customHooks/useSocketOnce";
import {
    addExtCheck, enableFlagOnDemand, getMachineEventTypesAPI, getMachineVarsAPI, getStaplingChecksAPI,
    getStaplingComponentsAPI, getStaplingOeeAPI,
    getStaplingUserEventAPI,
    getStaplingVarsAPI, getUserEventTypesAPI,
    operatorViewSelector, pauseStaplingAPI, reloadingInterfaceStaplingModeAPI, startStaplingAPI, stopStaplingAPI
} from "../slice";
import { socketOptions } from "../utility/constants";

const ReloadingAPI = ({ configuration, presetVarCheckValue }) => {
    const dispatch = useDispatch();
    const { staplingList, machine, operator, odp,
        startChecks, pauseChecks, restartChecks, closeChecks, errors, flagDialog,
        interfaceNeedUpdate, loadingStaplingOdp,
        extChecks, unmountProcess } = useSelector(operatorViewSelector);
    const { splitDialog } = useSelector(splitSelector);
    const { varToSee } = useSelector(varsSelector);
    const { checkToSee } = useSelector(checksSelector);
    const { closedAlerts } = useSelector(alertsSelector);
    const { lastMessage } = useWebSocket(WEBSOCKET_URL_ERROR, socketOptions);
    const { lastMessage: lastCheck } = useWebSocket(WEBSOCKET_URL_CHECKS, socketOptions);
    const [machineEventsCall, { setTrue: setMacEventsTrue }] = useBoolean(false);
    const [userEventsCall, { setTrue: setUsEventsTrue }] = useBoolean(false);
    const [varsCall, { setTrue: setVarsTrue }] = useBoolean(false);
    const [checksCall, { setTrue: setChecksTrue }] = useBoolean(false);
    const [machineVarsCall, { setTrue: setMacVarsTrue }] = useBoolean(false);
    const [oeeCall, { setTrue: setOeeTrue }] = useBoolean(false);
    const [componetsCall, { setTrue: setComponentsTrue }] = useBoolean(false);
    const [processTypeCall, { setTrue: setProcessTypeTrue }] = useBoolean(false);

    useInterval(() => updatingInterface(), 10000);

    //WS errori
    const socketErrorCallback = (msg) => {
        let j = JSON.parse(msg.data);
        if (j.odp !== null && odp.id !== null && j.odp.toString() === odp.id.toString()) {
            dispatch(showSocketError(j.error));
        }
    }
    useSocketOnce(lastMessage, odp, socketErrorCallback);

    //WS controlli su richiesta
    useEffect(() => {
        if (lastCheck != null) {
            let j = JSON.parse(lastCheck.data);
            if (j.MAINWRAPPERNAME === odp.code) {
                dispatch(addExtCheck({ name: j.MESSAGENAME, timestamp: j.MESSAGETIMESTAMP }))
            }
        }
    }, [dispatch, lastCheck, odp.code])

    useEffect(() => {
        if (!Object.values(flagDialog).includes(true) && extChecks.length > 0 && !splitDialog && !checkToSee && !varToSee && configuration.blockingChecks) {
            dispatch(enableFlagOnDemand())
            //se necessario check di tipo variabile e quest'ultima è modifcabile, prevalorizza currentValue col valore della variabile
            presetVarCheckValue(extChecks[0]);
        }
    }, [dispatch, extChecks, flagDialog, splitDialog, varToSee, checkToSee, configuration.blockingChecks, presetVarCheckValue])

    //faccio il reset degli alerts
    useEffect(() => {
        dispatch(resetAlertsState());
    }, [dispatch])

    //creo alert per quantità prodotta
    useEffect(() => {
        if ((odp && odp.quantity !== null && odp.validPieces !== null && odp.quantity <= odp.validPieces) ||
            (odp && odp.quantity !== null && odp.totalPieces !== null && odp.quantity <= (odp.totalPieces - odp.rejectedPieces))) {
            if (!closedAlerts.includes("producedQuantity")) {
                dispatch(addAlert({ name: ALERT_PRODUCED_QUANTITY, type: ALERT_TYPE_WARNING, message: customLabel("alert.producedQuantity") }));
            }
        }
    }, [dispatch, odp, closedAlerts]);

    //API per start close pause process ogni qualvolta i relativi checks vengono azzerati
    useEffect(() => {
        if (flagDialog.flagStart && startChecks && startChecks.length === 0 && !errors) {
            dispatch(startStaplingAPI(odp, machine, operator));
        }
        if (flagDialog.flagPause && pauseChecks && pauseChecks.length === 0 && !errors) {
            dispatch(pauseStaplingAPI(odp, machine, operator, unmountProcess));
        }
        if (flagDialog.flagRestart && restartChecks && restartChecks.length === 0 && !errors) {
            dispatch(startStaplingAPI(odp, machine, operator));
        }
        if (flagDialog.flagClose && closeChecks && closeChecks.length === 0 && !errors) {
            dispatch(stopStaplingAPI(odp, machine, operator));
        }
    }, [dispatch, pauseChecks, odp, errors, machine, operator, restartChecks, staplingList, closeChecks,
        flagDialog, startChecks, unmountProcess]);


    useEffect(() => {
        if (!machineEventsCall && odp) {
            dispatch(getMachineEventTypesAPI(machine));
            setMacEventsTrue()
        }
    }, [dispatch, machineEventsCall, odp, machine, setMacEventsTrue])

    useEffect(() => {
        if (!userEventsCall && odp && staplingList && staplingList.length > 0) {
            dispatch(getUserEventTypesAPI(operator, staplingList[0], false, false, false));
            setUsEventsTrue()
        }
    }, [dispatch, userEventsCall, odp, staplingList, operator, setUsEventsTrue])

    useEffect(() => {
        if (!checksCall && odp && !configuration.enableComponents) {
            dispatch(getStaplingChecksAPI(odp));
            setChecksTrue()
        }
    }, [dispatch, checksCall, odp, setChecksTrue,configuration.enableComponents])

    useEffect(() => {
        if (!varsCall && odp) {
            dispatch(getStaplingVarsAPI(odp));
            setVarsTrue()
        }
    }, [dispatch, varsCall, odp, setVarsTrue])

    useEffect(() => {
        if (!machineVarsCall && odp) {
            dispatch(getMachineVarsAPI(machine));
            setMacVarsTrue()
        }
    }, [dispatch, machineVarsCall, odp, machine, setMacVarsTrue])

    useEffect(() => {
        if (!oeeCall && odp) {
            dispatch(getStaplingOeeAPI(odp));
            setOeeTrue()
        }
    }, [dispatch, oeeCall, odp, setOeeTrue])

    useEffect(() => {
        if (!componetsCall && odp && configuration.enableComponents) {
            dispatch(getStaplingComponentsAPI(odp, configuration.retrieveLotInfo,true));
            setComponentsTrue()
        }
    }, [dispatch, componetsCall, odp, configuration.enableComponents, setComponentsTrue, configuration.retrieveLotInfo])

    useEffect(() => {
        if (!processTypeCall&&odp) {
            dispatch(getProcessTypeAPI(odp.staplingProcesses[0].processTypeId));
            setProcessTypeTrue()
            dispatch(getStaplingUserEventAPI(odp, operator))
        }
    }, [dispatch, processTypeCall, odp, setProcessTypeTrue, operator])

    //ricarica quando si interrompe l inserimento dei checks oppure sull'aggiornamento
    useEffect(() => {
        if (interfaceNeedUpdate && odp && odp.startDate !== null && !loadingStaplingOdp) {
            const timeout = setTimeout(() => {
                dispatch(reloadingInterfaceStaplingModeAPI(odp, machine, operator, configuration.enableComponents, false, configuration.retrieveLotInfo,true));
            }, 2000)
            return () => clearTimeout(timeout)
        }
    }, [dispatch, odp, machine, operator, interfaceNeedUpdate, loadingStaplingOdp, configuration.enableComponents, configuration.retrieveLotInfo])

    //aggiorna vars e machineVars ogni 10 secondi
    const updatingInterface = () => {
        if (odp && odp.startDate !== null &&
            !flagDialog.flagStart &&
            !flagDialog.flagPause &&
            !flagDialog.flagClose &&
            !flagDialog.flagRestart &&
            !flagDialog.flagOnDemand &&
            !flagDialog.flagUserEventType &&
            !flagDialog.flagMachineEventType &&
            //!flagDialog.flagMachineEventReason &&
            !flagDialog.flagButtons &&
            !flagDialog.flagInsertButton &&
            !flagDialog.flagStopProcess &&
            !flagDialog.flagChoosePause &&
            !flagDialog.flagCompConsum &&
            !flagDialog.flagCompWaste) {
            dispatch(reloadingInterfaceStaplingModeAPI(odp, machine, operator, configuration.enableComponents, true, configuration.retrieveLotInfo,true));
        }
        //reloading solo di machinevars e components se pinzatura non avviata
        if (odp && odp.startDate === null) {
            dispatch(getMachineVarsAPI(machine));
            if (configuration.enableComponents) {
                dispatch(getStaplingComponentsAPI(odp, configuration.retrieveLotInfo,true));
            }else{
                dispatch(getStaplingChecksAPI(odp))
            }
        }
    }

    return (
        null
    );
}

export default ReloadingAPI;