import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../constants";
import { showError, showSuccess } from "../notifications/slice";
import { logout, refreshAPI } from "../main/slice";
import _ from 'lodash';
import { customLabel } from "../utility/customLabel";

export const initialState = {
  loading: false,
  hasErrors: false,
  reload: false,
  deleting: false,
  isCreated: false,
  isDeleted: false,
  check: false,
  document: false,
  documents: {},
  selectedFile: false,
  fileInfos: [],
  download: false, //opzione per scaricare il file
  currentDocument: false //documento selezionato per la visualizzazione
};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    getting: (state, { payload }) => {
      state.reload = false
      state.loading = true
      state.hasErrors = false
      let docs = _.cloneDeep(state.documents)
      if (payload !== "processes" && docs !== false) {
        let keys = Object.keys(docs);
        for (let i = 0; i < keys.length; i++) {
          let k = keys[i]
          if (k !== undefined && (k.indexOf(payload) > -1 || (payload === "odps" && k.indexOf("processes") > -1))) {
            delete docs[k];
          }
        }
      }
      state.documents = docs
    },
    getSuccess: (state, { payload }) => {
      state.loading = false
      let k = payload.parentType + "_" + payload.parentId
      state.documents[k] = [...payload.data]
      for (let i = 0; i < state.documents[k].length; i++) {
        let m = state.documents[k][i].mime !== null ? state.documents[k][i].mime : state.documents[k][i].url.split(".")[state.documents[k][i].url.split(".").length - 1]
        if (m.substring(0, 5) === "image" || m.toLowerCase().match(/.(jpg|jpeg|png|gif)$/i)) {
          state.documents[k][i].format = "image";
        }
        else if (m.split('/').pop().toLowerCase() === "pdf") {
          state.documents[k][i].format = "pdf";
        }
        else {
          state.documents[k][i].format = "other";
        }
      }
    },
    creating: state => {
      state.loading = true
      state.hasErrors = false
    },
    createSuccess: (state, { payload }) => {
      state.loading = false
      state.document = false
      state.reload = payload
    },
    updating: state => {
      state.loading = true
      state.hasErrors = false
    },
    updateSuccess: (state, { payload }) => {
      state.loading = false
      state.document = false
      state.reload = payload
    },
    deleting: state => {
      state.loading = true
      state.hasErrors = false
    },
    deleteSuccess: (state, { payload }) => {
      state.loading = false
      state.reload = payload
    },
    newDocument: (state, { payload }) => {
      state.document = { id: "new", name: "", parentId: payload.parentId, parentType: payload.parentType }
    },
    select: (state, action) => {
      state.document = _.cloneDeep(action.payload.document)
      if (state.document) {
        state.document["parentId"] = action.payload.parentId
        state.document["parentType"] = action.payload.parentType
      }
    },
    change: (state, { payload }) => {
      let c = state.document
      c[payload.name] = payload.value
      state.document = c
    },
    selectFile: (state, { payload }) => {
      state.selectedFile = payload;
    },
    setDownload: (state, { payload }) => {
      state.download = true;
      state.currentDocument = _.cloneDeep(payload.doc);
      if (state.currentDocument) {
        state.currentDocument["parentId"] = payload.parentId
        state.currentDocument["parentType"] = payload.parentType
      }
    },
    setCurrentDocument: (state, { payload }) => {
      state.currentDocument = _.cloneDeep(payload.doc);
      if (state.currentDocument) {
        state.currentDocument["parentId"] = payload.parentId
        state.currentDocument["parentType"] = payload.parentType
      }
    },
    resetCurrentDocument: (state) => {
      state.currentDocument = false;
      state.download = false;
    },
    enableErrors: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.document = false;
    }
  },
});

export const { getting, getSuccess, creating, createSuccess, updating, updateSuccess, deleting, deleteSuccess, enableErrors,
  newDocument, select, change, selectFile, setDownload, setCurrentDocument, resetCurrentDocument, enableReloading } = documentsSlice.actions;

export const documentsSelector = (state) => state.documents;
export default documentsSlice.reducer;

export function getListApi(parentId, parentType) {
  return async dispatch => {
    dispatch(getting(parentType));
    const response = await fetch(SERVER_URL + '/document?parentId=' + parentId + '&parentType=' + parentType, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(getSuccess({ data: data, parentId: parentId, parentType: parentType }))
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};
export function updateAPI(document) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  let params = { document: document }
  return async dispatch => {
    dispatch(updating())
    const response = await fetch(SERVER_URL + '/api/document/' + document.id, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updateSuccess({ parentId: document.parentId, parentType: document.parentType }))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }

}

export function createAPI(document, selectedFile, parentId, parentType) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  const formData = new FormData()
  formData.append('file', selectedFile[0])
  formData.append('name', document.name)
  formData.append('parentId', parentId)
  formData.append('parentType', parentType)
  return async dispatch => {
    dispatch(creating())
    const response = await fetch(SERVER_URL + '/api/document', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + access_token
      },
      body: formData,
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(createSuccess({ parentId: parentId, parentType: parentType }))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function deleteAPI(document, parentId, parentType) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(deleting())
    const response = await fetch(SERVER_URL + '/api/document/' + document.id + '?parentId=' + parentId + '&parentType=' + parentType, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(deleteSuccess({ parentId: parentId, parentType: parentType }))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}
