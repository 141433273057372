import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from '../../utility/customLabel';
import moment from "moment";
import { bishopFormats } from '../../../constants';
import {
    odpsSelector, setNewMachineEvent, unsetNewMachineEvent, changeNewMachineEvent, resetObject, updateMachineEventAPI, getProcessMachineEventsAPI,
    pauseProcessAPI, stopProcessAPI, getStaplingMachineEventsAPI, updateStaplingMachineEventAPI, pauseStaplingAPI, stopStaplingAPI
} from "../slice";
import {
    getProcessMachineEventsAPI as getMaintenanceMachineEventsAPI, stopProcessAPI as stopMaintenanceAPI,
    pauseProcessAPI as pauseMaintenanceAPI
} from "../../maintenances/slice";
import {
    Paper, Button, Dialog, DialogTitle, DialogContent,
    DialogActions, TextField, Divider, Box
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import MomentUtils from "@date-io/moment";
import { Autocomplete } from "@material-ui/lab"
import DialogConfirmAction from "../../utility/DialogConfirmAction";
import { mesStyles } from '../../utility/ultrafabStyles';
import TableListOneButton from "../../utility/TableListOneButton";
import { machineEventsSelector, getListAPI as getMachineEventsAPI } from "../../machineEvents/slice";
import { machinesSelector, getFullListAPI as getMachinesAPI } from "../../machines/slice";
import { usersSelector, getListAPI as getUsersAPI } from "../../users/slice";
import { mainSelector } from "../../main/slice";
import ButtonProcessStapling from "../../utility/ButtonProcessStapling";
import PaperAutocomplete from "../../utility/PaperAutocomplete";

const ProcessMachineEvents = ({ odp, loading, currentMachineEvents, loadingMachineEvents, reloadMachineEvents, resultsMachineEvents,
    setReloadMachineEvents, editProcess, loadingStaplingMachineEvents, resultsStaplingMachineEvents, reloadStaplingMachineEvents,
    currentStaplingMachineEvents, setReloadStaplingMachineEvents }) => {
    const dispatch = useDispatch();
    const myClasses = mesStyles();
    const { machineEvents } = useSelector(machineEventsSelector);
    const { users, loading: usersLoading } = useSelector(usersSelector);
    const { machines, loading: machinesLoading } = useSelector(machinesSelector);
    const { newMachineEvent, currentProcess, staplingMachineEvent } = useSelector(odpsSelector);
    const [currentMachineId, setCurrentMachineId] = React.useState(false);
    const [pauseFlag, setPauseFlag] = React.useState(false);
    const [stopFlag, setStopFlag] = React.useState(false);
    const { currentUser } = useSelector(mainSelector);
    //oggetti per parametri chiamata list
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("startDate");
    const [type, setType] = React.useState(false);
    const [machine, setMachine] = React.useState(false);
    const [user, setUser] = React.useState(false);
    const [startDateFrom, setStartDateFrom] = React.useState(false);
    const [startDateTo, setStartDateTo] = React.useState(false);
    const [endDateFrom, setEndDateFrom] = React.useState(false);
    const [endDateTo, setEndDateTo] = React.useState(false);

    useEffect(() => {
        if (!machines && !machinesLoading) {
            dispatch(getMachinesAPI());
        }
        if (!machineEvents && machines) {
            dispatch(getMachineEventsAPI(0, 10000, "name", "asc", false));
        }
        if (!users && machineEvents && !usersLoading) {
            dispatch(getUsersAPI(0, 10, "code", "asc", false, false, false));
        }
    }, [dispatch, machines, users, machineEvents, usersLoading, machinesLoading])

    useEffect(() => {
        setType(false);
        setMachine(false);
        setUser(false);
        setStartDateFrom(false);
        setEndDateFrom(false);
        setStartDateTo(false);
        setEndDateTo(false);
        setPage(0)
        setRowsPerPage(10)
    }, [editProcess.id])

    useEffect(() => {
        if (reloadMachineEvents && !loadingMachineEvents && !odp.maintenance && !odp.stapling) {
            dispatch(getProcessMachineEventsAPI(odp, editProcess, page, rowsPerPage, orderBy, order, type, machine, user, startDateFrom, endDateFrom, startDateTo, endDateTo));
        } else if (reloadMachineEvents && !loadingMachineEvents && odp.maintenance && !odp.stapling) {
            dispatch(getMaintenanceMachineEventsAPI(odp, editProcess, page, rowsPerPage, orderBy, order, type, machine, user, startDateFrom, endDateFrom, startDateTo, endDateTo));
        } else if (reloadStaplingMachineEvents && !loadingStaplingMachineEvents && !odp.maintenance && odp.stapling) {
            dispatch(getStaplingMachineEventsAPI(odp, page, rowsPerPage, orderBy, order, type, machine, user, startDateFrom, endDateFrom, startDateTo, endDateTo))
        }
    }, [dispatch, odp, editProcess, page, rowsPerPage, orderBy, order, type, machine, user, startDateFrom, endDateFrom, startDateTo, endDateTo, reloadMachineEvents, loadingMachineEvents, reloadStaplingMachineEvents, loadingStaplingMachineEvents]);

    const settingPause = (machineId) => {
        setPauseFlag(true);
        setCurrentMachineId(machineId);
    };
    const confirmPause = () => {
        if (!odp.maintenance && !odp.stapling) {
            dispatch(pauseProcessAPI(odp.id, editProcess.id, currentUser.username, currentMachineId));
        } else if (odp.maintenance && !odp.stapling) {
            dispatch(pauseMaintenanceAPI(odp.id, editProcess.id, currentUser.username, currentMachineId));
        } else if (!odp.maintenance && odp.stapling) {
            dispatch(pauseStaplingAPI(odp, currentMachineId, currentUser.id))
        }
        setPauseFlag(false);
        setCurrentMachineId(false);
    };
    const settingStop = (machineId) => {
        setStopFlag(true);
        setCurrentMachineId(machineId);
    };
    const confirmStop = () => {
        if (!odp.maintenance && !odp.stapling) {
            dispatch(stopProcessAPI(odp.id, editProcess.id, currentUser.username, currentMachineId));
        } else if (odp.maintenance && !odp.stapling) {
            dispatch(stopMaintenanceAPI(odp.id, editProcess.id, currentUser.username, currentMachineId));
        } else if (!odp.maintenance && odp.stapling) {
            dispatch(stopStaplingAPI(odp, currentMachineId, currentUser.id))
        }
        setStopFlag(false);
        setCurrentMachineId(false);
    };
    const cancelActions = () => {
        setPauseFlag(false);
        setStopFlag(false);
        setCurrentMachineId(false);
    };

    //cambiare startDate e endDate
    const handleChangeDateEvent = (name, value) => {
        let nameDate = name;
        let formatValue;
        if (value !== null) {
            formatValue = new Date(value.format()).getTime()
        } else {
            formatValue = "";
        }
        dispatch(changeNewMachineEvent({ name: nameDate, value: formatValue }));
    };
    //decide se cambiare machineEventType o svuotarlo
    const onChangeFilterAutocomplete = (name, value, reason) => {
        if (reason === "clear") {
            handleChangeObject(name, false)
        } else {
            handleChangeObject(name, value.id)
        }
    };
    //cambiare machineEventType
    const handleChangeObject = (name, value) => {
        if (value !== false) {
            dispatch(changeNewMachineEvent({ name: name, value: value, machineEvents: machineEvents }))
        }
        else {
            dispatch(resetObject({ name: name, value: false }))
        }
    };
    //metodi tablelist
    const handleChangePage = (event, newPage, doReload) => {
        setPage(newPage);
        if (doReload) {
            if (!odp.stapling && !odp.maintenance) {
                dispatch(setReloadMachineEvents());
            } else if (odp.stapling && !odp.maintenance) {
                dispatch(setReloadStaplingMachineEvents());
            }
        }
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        if (!odp.maintenance && !odp.stapling) {
            dispatch(getProcessMachineEventsAPI(odp, editProcess, 0, event.target.value, orderBy, order, type, machine, user, startDateFrom, endDateFrom, startDateTo, endDateTo));
            dispatch(setReloadMachineEvents());
        } else if (odp.maintenance && !odp.stapling) {
            dispatch(getMaintenanceMachineEventsAPI(odp, editProcess, 0, event.target.value, orderBy, order, type, machine, user, startDateFrom, endDateFrom, startDateTo, endDateTo));
        } else if (!odp.maintenance && odp.stapling) {
            dispatch(getStaplingMachineEventsAPI(odp, 0, event.target.value, orderBy, order, type, machine, user, startDateFrom, endDateFrom, startDateTo, endDateTo))
            dispatch(setReloadStaplingMachineEvents())
        }
    };
    const onRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        let newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        if (!odp.stapling) {
            dispatch(setReloadMachineEvents());
        } else {
            dispatch(setReloadStaplingMachineEvents());
        }
    };
    const changeFilters = (name, value) => {
        const f =
            name === "type" ? setType : name === "machine" ? setMachine : name === "user"
                ? setUser : name === "startDateFrom" ? setStartDateFrom : name === "endDateFrom" ? setEndDateFrom : name === "startDateTo" ? setStartDateTo :
                    name === "endDateTo" ? setEndDateTo : null;
        f(value);
        if (!odp.stapling && !odp.maintenance) {
            dispatch(setReloadMachineEvents());
        } else if (odp.stapling && !odp.maintenance) {
            dispatch(setReloadStaplingMachineEvents());
        }
    };
    const resetFilter = () => {
        setType(false);
        setMachine(false);
        setUser(false);
        setStartDateFrom(false);
        setEndDateFrom(false);
        setStartDateTo(false);
        setEndDateTo(false);
        if (!odp.stapling && !odp.maintenance) {
            dispatch(setReloadMachineEvents());
        } else if (odp.stapling && !odp.maintenance) {
            dispatch(setReloadStaplingMachineEvents());
        }
    };
    const headers = {
        type: { filter: true, type: "object" },
        machine: { filter: true, type: "object" },
        user: { filter: true, type: "custom", fields: ["firstName", "lastName"], autocomplete: "username" },
        startDate: { filter: true, type: "datetime", when: "startDate" },
        endDate: { filter: true, type: "datetime", when: "endDate" },
    };
    const onClickEditButton = (m) => {
        dispatch(setNewMachineEvent(m))
    };

    const updateMachineEvent = () => {
        if (!odp.stapling) {
            dispatch(updateMachineEventAPI(odp, currentProcess, newMachineEvent))
        } else {
            dispatch(updateStaplingMachineEventAPI(odp, newMachineEvent))
        }
    }

    return (
        <React.Fragment>
            <Paper className={myClasses.processDetailsPaper}>
                {!odp.stapling && editProcess && editProcess.stapling.id !== null ? (
                    <React.Fragment>
                        <ButtonProcessStapling message={customLabel("process.processInStapling").toUpperCase()} stapling={editProcess.stapling} />
                        <Divider />
                    </React.Fragment>
                ) : null}
                {!odp.stapling && editProcess ? (
                    <div className={myClasses.buttonsFlexRight}>
                        <Button color="secondary"
                            disabled={loading || editProcess.status.id >= 2 || (editProcess.machineEvent && editProcess.machineEvent.id !== null && !editProcess.machineEvent.systemEvent) || editProcess.stapling.id !== null}
                            onClick={() => settingPause(editProcess.machineEvent.machine.id)}>
                            {customLabel("button.pause")}
                        </Button>
                        <Button color="default" disabled={loading || editProcess.status.id >= 4 || (editProcess.machineEvent && editProcess.machineEvent.id !== null && !editProcess.machineEvent.systemEvent) || editProcess.stapling.id !== null}
                            onClick={() => settingStop(editProcess.machineEvent.machine.id)}>
                            {customLabel("button.stop")}
                        </Button>
                    </div>
                ) : odp.stapling ? (
                    <div className={myClasses.buttonsFlexRight}>
                        <Button color="secondary"
                            disabled={loading || odp.status.id >= 2 || (staplingMachineEvent && staplingMachineEvent.id !== null && !staplingMachineEvent?.type?.systemEvent)}
                            onClick={() => settingPause(staplingMachineEvent.machine.id)}>
                            {customLabel("button.pause")}
                        </Button>
                        <Button color="default"
                            disabled={loading || odp.status.id >= 4 || (staplingMachineEvent && staplingMachineEvent.id !== null && !staplingMachineEvent?.type?.systemEvent)}
                            onClick={() => settingStop(staplingMachineEvent.machine.id)}>
                            {customLabel("button.stop")}
                        </Button>
                    </div>
                ) : null}
                <Divider />
                <Box>
                    {!odp.stapling && currentMachineEvents !== false ? (
                        <TableListOneButton
                            items={currentMachineEvents} headers={headers} mainObject={"machineEvent."} page={page}
                            setPage={handleChangePage} rowsPerPage={rowsPerPage} setRowsPerPage={handleChangeRowsPerPage}
                            results={resultsMachineEvents} onRequestSort={onRequestSort} order={order} orderBy={orderBy}
                            changeFilters={changeFilters} resetFilter={resetFilter}
                            userList={users} typeList={machineEvents} machineList={machines}
                            buttonAction={onClickEditButton} buttonText={customLabel("button.edit")} disabledButton={loading}
                            startDateFrom={startDateFrom} startDateTo={startDateTo} endDateFrom={endDateFrom} endDateTo={endDateTo}
                            user={user} type={type} machine={machine} odp={odp} />
                    ) : odp.stapling && currentStaplingMachineEvents !== false ? (
                        <TableListOneButton
                            items={currentStaplingMachineEvents} headers={headers} mainObject={"machineEvent."} page={page}
                            setPage={handleChangePage} rowsPerPage={rowsPerPage} setRowsPerPage={handleChangeRowsPerPage}
                            results={resultsStaplingMachineEvents} onRequestSort={onRequestSort} order={order} orderBy={orderBy}
                            changeFilters={changeFilters} resetFilter={resetFilter}
                            userList={users} typeList={machineEvents} machineList={machines}
                            buttonAction={onClickEditButton} buttonText={customLabel("button.edit")} disabledButton={loading}
                            startDateFrom={startDateFrom} startDateTo={startDateTo} endDateFrom={endDateFrom} endDateTo={endDateTo}
                            user={user} type={type} machine={machine} odp={odp} />
                    ) : null}
                </Box>
            </Paper>
            <Dialog open={newMachineEvent ? true : false} keepMounted fullWidth maxWidth={"sm"} >
                <DialogTitle>{customLabel("odp.updateMachineEvent")}</DialogTitle>
                <DialogContent>
                    <form noValidate autoComplete="off" className={myClasses.dialogForm}>
                        <Autocomplete
                            PaperComponent={PaperAutocomplete}
                            value={newMachineEvent && newMachineEvent.type.id !== "" && newMachineEvent.type.id !== null ? newMachineEvent.type : null}
                            getOptionSelected={(option, value) => newMachineEvent.type.id !== null && newMachineEvent.type.id !== '' ? option.id === value.id : null}
                            onChange={(event, value, reason) => onChangeFilterAutocomplete("type.id", value, reason)}
                            options={machineEvents}
                            getOptionLabel={(option) => option.name}
                            id="machineEventTypeAutocomplete"
                            renderInput={(params) => <TextField {...params} label={customLabel("machineEvent.machineEvents")} />
                            }
                        />
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <DateTimePicker className={myClasses.datePickerTop2}
                                ampm={false}
                                label={customLabel("machineEvent.startDate")}
                                value={newMachineEvent.startDate !== null ? newMachineEvent.startDate : ""}
                                onChange={(date) => handleChangeDateEvent("startDate", date)}
                                format={bishopFormats.LT}
                            />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <DateTimePicker className={myClasses.datePickerTop2}
                                ampm={false}
                                label={customLabel("machineEvent.endDate")}
                                value={newMachineEvent.endDate !== null ? newMachineEvent.endDate : null}
                                onChange={(date) => handleChangeDateEvent("endDate", date)}
                                format={bishopFormats.LT}
                            />
                        </MuiPickersUtilsProvider>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => updateMachineEvent()}
                        disabled={(newMachineEvent && newMachineEvent.type.id === "") || loading || newMachineEvent.endDate <= newMachineEvent.startDate}
                        color="primary">{customLabel("button.confirm")}</Button>
                    <Button onClick={() => dispatch(unsetNewMachineEvent())} disabled={loading} color="default">{customLabel("button.close")}</Button>
                </DialogActions>
            </Dialog>
            <DialogConfirmAction text={customLabel(!odp.maintenance && !odp.stapling ? "odp.confirmPause" : odp.maintenance ? "maintenance.confirmPause" : "stapling.confirmPauseME")}
                flag={pauseFlag} confirm={confirmPause}
                cancel={cancelActions} disabled={loading} />
            <DialogConfirmAction text={customLabel(!odp.maintenance && !odp.stapling ? "odp.confirmStop" : odp.maintenance ? "maintenance.confirmStop" : "stapling.confirmStopME")} flag={stopFlag} confirm={confirmStop}
                cancel={cancelActions} disabled={loading} />
        </React.Fragment >
    );
};

export default ProcessMachineEvents;
