import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { processStatusesSelector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete } from "./slice";

import { Box } from "@material-ui/core";

import { Form } from "./Form";
import { Details } from "./Details";

const ProcessStatePage = ({ match }) => {
  const dispatch = useDispatch();
  const { processStatus, processStatusedit, editmode, deleting, isCreated, isDeleted, processStatuses, loading } = useSelector(processStatusesSelector);

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    dispatch(change({ name: name, value: value }));
  };

  const toggleDelete = (event) => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(processStatusedit));
  };

  const onEdit = () => {
    dispatch(edit());
  };
  const doDelete = () => {
    dispatch(deleteAPI(processStatus));
  };

  const save = () => {
    if (processStatus.id === "new") {
      dispatch(createAPI(processStatusedit));
    } else {
      dispatch(updateAPI(processStatusedit));
    }
  };

  useEffect(() => {
    const { id } = match.params;
    if (id !== "new") {
      dispatch(getAPI(id));
    } else {
      dispatch(create());
    }
  }, [dispatch, match]);

  const renderprocessStatus = () => {
    if (processStatus !== false && !editmode && match.params.id === processStatus.id.toString()) {
      return <Details processStatus={processStatus} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete} loading={loading} />
    }
    if (processStatus !== false && editmode) {
      return <Form processStatusedit={processStatusedit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save} processStatuses={processStatuses} loading={loading} />
    }
    return;
  };

  if (isDeleted && match.params.id === processStatus.id.toString()) {
    return <Redirect to={"/admin/processStatuses/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/admin/processStatuses/" + processStatus.id} />;
  }

  return <Box>{renderprocessStatus()}</Box>;
};

export default ProcessStatePage;
