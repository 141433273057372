import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../../constants";
import { showError } from "../../notifications/slice";
import { logout, refreshAPI } from "../../main/slice";
import { customLabel } from "../../utility/customLabel";

export const initialState = {
  loading: false,
  hasErrors: false,
  processes: false,
  machines: false
};

const workcentreViewSlice = createSlice({
  name: "workcentreView",
  initialState,
  reducers: {
    enableErrors: (state) => {
      state.hasErrors = true;
      state.loading = false;
    },
    gettingProcesses: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.reload = false;
    },
    getProcessesSuccess: (state, { payload }) => {
      state.loading = false;
      let processes = {}
      for (let p in payload) {
        let process = payload[p]
        let wc = process.w_code
        if (processes[wc] === undefined) {
          processes[wc] = []
        }
        processes[wc].push(process)
      }
      state.processes = processes;
    },
  },
});

export const { enableErrors, gettingProcesses, getProcessesSuccess } = workcentreViewSlice.actions;

export const selector = (state) => state.workcentreView;

export default workcentreViewSlice.reducer;

export function getProcesses(workcentreId) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(gettingProcesses());
    let url = SERVER_URL + "/api/workcentre/" + workcentreId + "/currentActivities"
    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getProcessesSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
