import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../constants";
import { showError, showSuccess } from "../notifications/slice";
import { logout, refreshAPI } from "../main/slice";
import { customLabel } from "../utility/customLabel";
import { customFilter } from "./utility";
import _ from 'lodash';

export const initialState = {
  loading: false,
  hasErrors: false,
  articles: false,
  reload: false,
  results: 0,
  editmode: false,
  article: false,
  articleedit: false,
  deleting: false,
  isCreated: false,
  isDeleted: false,
  cycleTime: false,
  tree: false,
  expanded: [],
  flagReloadCycleTime: false,
  filter: false
};

const getExpanded = (id, bom, expanded) => {
  expanded.push(id + "_process_" + bom.process.name)
  for (let b in bom.articles) {
    let p = bom.articles[b]
    let thisid = id + "_article_" + p.code
    expanded.push(thisid)
    for (let a in p.bomProcesses) {
      let ba = p.bomProcesses[a]
      expanded = [...getExpanded(thisid, ba, expanded)]
    }
  }
  return expanded
}

const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    resetState: (state) => {
      if (!state.filter) {
        Object.assign(state, initialState);
      } else {
        let tempState = {};
        let currentFilter = { ...state.filter }
        Object.assign(tempState, initialState);
        tempState.filter = { ...currentFilter }
        Object.assign(state, tempState);
      }
    },
    gettingList: (state) => {
      state.loading = true;
      state.reload = false;
      state.hasErrors = false;
    },
    getListSuccess: (state, { payload }) => {
      state.articles = payload[1];
      state.results = payload[0].total_entries;
      state.loading = false;
    },
    setReload: (state) => {
      state.reload = true;
    },
    reset: (state) => {
      state.article = false;
      state.articleedit = false;
      state.editmode = false;
      state.isDeleted = false;
      state.isCreated = false;
      state.cycleTime = false;
    },
    getting: (state) => {
      state.reload = false
      state.tree = false
      state.article = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.loading = true;
      state.editmode = false;
      state.hasErrors = false;
      state.cycleTime = false;
    },
    getSuccess: (state, { payload }) => {
      state.article = payload;
      state.loading = false;
    },
    creating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    create: (state) => {
      let newarticle = { id: "new", name: "", code: "", extCode: "", type: "", note: "", measure: "pezzo", bom: { id: '', name: "", code: "" } };
      state.article = newarticle;
      state.articleedit = newarticle;
      state.editmode = true;
    },
    createSuccess: (state, { payload }) => {
      state.article = payload;
      state.articleedit = false;
      state.loading = false;
      state.isCreated = true;
      state.editmode = false;
    },
    resetEdit: (state) => {
      state.editmode = true;
      state.articleedit = state.article;
      state.loading = false;
      state.hasErrors = true;
    },
    edit: (state) => {
      state.editmode = true;
      state.articleedit = state.article;
    },
    cancelEdit: (state) => {
      state.editmode = false;
      state.articleedit = false;
    },
    change: (state, { payload }) => {
      let u = state.articleedit;
      if (payload.name === "bom.id") {
        let lista = payload.boms.slice();
        let indexOfBoms = lista.findIndex((l) => {
          return l.id === payload.value
        });
        u["bom"] = lista[indexOfBoms]
      } else {
        u[payload.name] = payload.value;
      }
      state.articleedit = u;
      //metto a false per fare il reload di true attraverso lo useEffect
      //state.tree = false;
      //state.expanded = [];
    },
    changeObject: (state, { payload }) => {
      let u = state.articleedit;
      if (payload.name === "bom.id") {
        u["bom"] = { id: '' }
        state.tree = false;
        state.expanded = [];
      }
      state.articleedit = u;
    },
    updating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    updateSuccess: (state, { payload }) => {
      state.article = payload;
      state.loading = false;
      state.hasErrors = false;
      state.editmode = false;
    },
    confirmDelete: (state) => {
      state.deleting = !state.deleting;
    },
    deleting: (state) => {
      state.deleting = false;
      state.loading = true;
      state.hasErrors = false;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.isDeleted = true;
    },
    enableErrors: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    changeCycleTime: (state, { payload }) => {
      let cycleTime = state.cycleTime
      if (payload.value !== "") {
        cycleTime[payload.name] = payload.value
      } else {
        cycleTime[payload.name] = null
      }
      state.cycleTime = cycleTime
    },
    changeCycleTimeAttr: (state, { payload }) => {
      let cycleTime = state.cycleTime
      let jsonAttr = cycleTime["jsonAtt"]
      let k = Object.keys(cycleTime.jsonAtt)[payload.key]
      if (payload.item === "key") {
        let numbers = /\d/.test(payload.value)
        if (!numbers) {
          let nja = _.mapKeys(jsonAttr, function (value, key) {
            if (key === k) {
              return payload.value
            } else {
              return key
            }
          });
          jsonAttr = nja
        }
      } else if (!isNaN(payload.value)) {
        jsonAttr[k] = payload.value
      }
      state.cycleTime["jsonAtt"] = jsonAttr
      state.cycleTime["jsonAttributes"] = JSON.stringify(jsonAttr)
      state.cycleTime = cycleTime
    },
    addCycleTimeAttr: (state) => {
      let cycleTime = state.cycleTime
      let jsonAttr = cycleTime["jsonAtt"]
      if (jsonAttr[""] === undefined) {
        jsonAttr[""] = ""
        state.cycleTime["jsonAtt"] = jsonAttr
        state.cycleTime = cycleTime
      }
    },
    deleteCycleTimeAttr: (state, { payload }) => {
      let cycleTime = state.cycleTime
      let jsonAttr = cycleTime["jsonAtt"]
      let k = Object.keys(cycleTime.jsonAtt)[payload]
      delete jsonAttr[k]
      state.cycleTime["jsonAtt"] = jsonAttr
      state.cycleTime["jsonAttributes"] = JSON.stringify(jsonAttr)
      state.cycleTime = cycleTime
    },
    selectCycleTime: (state, { payload }) => {
      state.cycleTime = _.cloneDeep(payload)
      if (payload) {
        if (payload.jsonAttributes !== null) {
          state.cycleTime["jsonAtt"] = JSON.parse(payload.jsonAttributes)
        } else {
          state.cycleTime["jsonAtt"] = {}
        }
      }
    },
    updateCycleTimeSuccess: (state, { payload }) => {
      state.reload = true
      state.cycleTime = false
      state.loading = false
    },
    resetEditCycle: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.cycleTime = false;
    },
    getTreeSuccess: (state, { payload }) => {
      state.loading = false;
      state.tree = payload
      let id = "distinta_" + payload.bom.code
      let expanded = [id]
      for (let b in payload.bom.bomProcesses) {
        let bi = payload.bom.bomProcesses[b]
        expanded = [...getExpanded(id, bi, expanded)]
      }
      state.expanded = expanded
    },
    gettingTree: (state) => {
      state.deleting = false;
      state.loading = true;
      state.hasErrors = false;
    },
    cancelIsCreated: (state) => {
      state.isCreated = false;
    },
    resetTree: (state) => {
      state.tree = false;
      state.expanded = [];
    },
    enableFlagReloadCycleTime: (state, { payload }) => {
      state.flagReloadCycleTime = true;
      state.cycleTime = payload;
    },
    disableFlagReloadCycleTime: (state) => {
      state.flagReloadCycleTime = false;
      state.cycleTime = false;
    },
    //success del API updateOdpsCycleTimeAPI()
    updateOdpsCycleTime: (state) => {
      state.loading = false;
      state.flagReloadCycleTime = false;
      state.cycleTime = false;
    },
    changeFilter: (state, { payload }) => {
      state.filter[payload.name] = payload.value;
    },
    /**
     * 
     * @param {*} state 
     * @param {boolean} payload showFilter 
     */
    initFilter: (state, { payload }) => {
      let tempFilter = { ...customFilter };
      tempFilter["showFilter"] = payload;
      state.filter = { ...tempFilter };
    }
  },
});

export const { resetState, gettingList, getListSuccess, setReload, reset, getting, getSuccess, create, creating, createSuccess, edit, cancelEdit,
  change, updating, enableErrors, updateSuccess, confirmDelete, deleting, deleteSuccess, resetEdit, loadingarticles, loadingarticlesSuccess,
  changeObject, changeCycleTime, selectCycleTime, updateCycleTimeSuccess, resetEditCycle, getTreeSuccess, gettingTree, cancelIsCreated,
  resetTree, enableFlagReloadCycleTime, disableFlagReloadCycleTime, updateOdpsCycleTime, changeFilter, initFilter,
  changeCycleTimeAttr, addCycleTimeAttr, deleteCycleTimeAttr } = articlesSlice.actions;
export const articlesSelector = (state) => state.articles;
export default articlesSlice.reducer;

export function getListAPI(page, rowsPerPage, orderBy, order, name, code, extCode, bom, type) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(gettingList());
    dispatch(reset());
    let url = SERVER_URL + "/api/article?page=" + page + "&per_page=" + rowsPerPage + "&orderBy=" +
      orderBy + "&order=" + order;

    if (name !== false) {
      url = url + "&name=" + name;
    }

    if (code !== false) {
      url = url + "&code=" + code;
    }
    if (extCode !== false) {
      url = url + "&extCode=" + extCode;
    }
    if (type !== false) {
      url = url + "&type=" + type;
    }

    if (bom !== false) {
      url = url + "&bom=" + bom.id;
    }

    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getListSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function updateAPI(article) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { article: article };
  return async (dispatch) => {
    dispatch(updating());
    const response = await fetch(SERVER_URL + "/api/article/" + article.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateSuccess(data));
        if (data.bom.id !== "" && data.bom.id !== null) {
          dispatch(getTreeAPI(data));
        } else {
          dispatch(resetTree());
        }
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(resetEdit());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function createAPI(article) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(creating());
    let params = { article: article };
    const response = await fetch(SERVER_URL + "/api/article", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function getAPI(id) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(getting());
    const response = await fetch(SERVER_URL + "/api/article/" + id, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function deleteAPI(article) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(deleting());
    const response = await fetch(SERVER_URL + "/api/article/" + article.id, {
      mode: "cors",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(deleteSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function updateCycleTimeAPI(article, cycleTime) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { cycleTime: cycleTime };
  return async (dispatch) => {
    dispatch(updating());
    const response = await fetch(SERVER_URL + "/api/article/" + article.id + "/cycleTime/" + cycleTime.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateCycleTimeSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(resetEditCycle());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function getTreeAPI(article) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(gettingTree());
    const response = await fetch(SERVER_URL + "/api/article/" + article.id + "/bom", {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getTreeSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
//sistemare success e verificare loading
export function updateOdpsCycleTimeAPI(article, cycleTime) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { cycleTime: cycleTime };
  return async (dispatch) => {
    dispatch(updating());
    const response = await fetch(SERVER_URL + "/api/article/" + article.id + "/cycleTime/" + cycleTime.id + "/updateOdps", {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateOdpsCycleTime());
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(disableFlagReloadCycleTime());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}