import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import {
    Button, Paper, Table, TableHead, TableRow, TableCell, TableBody, TextField, IconButton
} from "@material-ui/core";
import {
    maintenerViewSelector, getUserOdpsAPI, handleChange, cancelCreate, createAPI, create, select, updateAPI,
    selectCheck, addCheck, cancelAddCheck, handleChangeCheck, createCheckAPI, updateCheckAPI, getCheckDefsAPI, startAPI, changeFilter, setReload, initFilter
} from "./slice";
import { machinesSelector, getFullListAPI as getMachinesAPI } from "../machines/slice";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { bishopFormats } from '../../constants';
import { ExtraordinaryForm } from "./ExtraordinaryForm";
import { Checks } from "./Checks";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from "@material-ui/lab";
import PaperAutocomplete from "../utility/PaperAutocomplete";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(2),
        },
    },
    paper: {
        marginTop: theme.spacing(),
        padding: theme.spacing()
    },
    buttonContainer: {
        textAlign: "right",
        marginTop: theme.spacing(2)
    },
    form: {
        width: "calc(25% - 32px)",
    },
    note: {
        width: "calc(100% - 32px)",
    },
    button: {
        padding: theme.spacing(),
        margin: theme.spacing(),
        fontSize: "15pt !important"
    },
    buttonNM: {
        marginLeft: theme.spacing(),
        padding: theme.spacing(),
        fontSize: "15pt !important"
    }
}));

const Extraordinary = () => {

    const dispatch = useDispatch();
    const classes = useStyles();

    const { maintener, odp, extOdps, check, checkDefs, filter, reload } = useSelector(maintenerViewSelector);
    const { machines } = useSelector(machinesSelector);

    useEffect(() => {
        dispatch(getMachinesAPI())
        dispatch(getUserOdpsAPI(maintener, true, true));
        dispatch(getCheckDefsAPI());
    }, [dispatch, maintener]);

    const onChangeFilterAutocomplete = (name, value, reason) => {
        if (reason === "clear") {
            dispatch(handleChange({ name: name, value: false }))
        } else {
            dispatch(handleChange({ name: name, value: value.id, machines: machines }))
        }
    };

    const onChange = (event) => {
        dispatch(handleChange({ name: event.target.name, value: event.target.value }))
    }

    const onChangeCheck = (event) => {
        dispatch(handleChangeCheck({ name: event.target.name, value: event.target.value }))
    }
    const onChangeInputCheck = (name, value, r) => {
        dispatch(handleChangeCheck({ name: "name", value: value }))
    }

    const onCancelCreate = () => {
        dispatch(cancelCreate());
    };

    const save = () => {
        if (odp.id !== '') {
            dispatch(updateAPI(odp));
        } else {
            dispatch(createAPI(odp));
        }
    }
    const onCreate = () => {
        dispatch(create());
    }

    const onAddCheck = () => {
        dispatch(addCheck())
    }
    const onCancelAddCheck = () => {
        dispatch(cancelAddCheck())
    }
    const onSelectCheck = (c) => {
        dispatch(selectCheck(c))
    }

    const saveCheck = () => {
        if (check.id === '') {
            dispatch(createCheckAPI(odp, check))
        } else {
            dispatch(updateCheckAPI(odp, check))
        }
    }

    const onChangeAutocompleteCheck = (e, value, reason) => {
        dispatch(handleChangeCheck({ name: "name", value: value }))
    }

    const machine = extOdps ? Object.values(extOdps.reduce((acc, obj) => {
        if (!acc[obj.machine.code]) {
            acc[obj.machine.code] = obj.machine
        }
        return acc;
    }, {})) : []

    useEffect(() => {
        dispatch(setReload());
    }, [dispatch, filter]);

    useEffect(() => {
        if (filter && reload) {
            dispatch(getUserOdpsAPI(maintener, true, true, filter));
        }
    }, [dispatch, maintener, filter, reload])

    useEffect(() => {
        dispatch(initFilter(true))
    }, [dispatch])

    const onChangeAutocomplete = (event, value, reason, name) => {
        if (reason === "clear") {
            changeFilters(name, false)
        } else {
            changeFilters(name, value)
        }
    }

    const handleChangeDate = (name, value) => {
        if (name.endsWith("From")) {
            const newValue = value.startOf('day')
            dispatch(changeFilter({ name: name, value: new Date(newValue.format()).getTime() }))
        }
        else if (name.endsWith("To")) {
            const newValue = value.endOf('day')
            dispatch(changeFilter({ name: name, value: new Date(newValue.format()).getTime() }))
        }
    }

    const handleResetDate = (e, name) => {
        e.stopPropagation();
        dispatch(changeFilter({ name: name, value: false }))
    }

    const changeFilters = (name, value) => {
        dispatch(changeFilter({ name: name, value: value }))
    }

    return (
        <Paper className={classes.paper}>
            {odp ? (
                <div>
                    <ExtraordinaryForm odp={odp} onChange={onChange} onCancelCreate={onCancelCreate} onChangeFilterAutocomplete={onChangeFilterAutocomplete} save={save} machines={machines} />
                    {odp.id !== '' ?
                        <Checks check={check} odp={odp} selectCheck={onSelectCheck} onAddCheck={onAddCheck} cancelAddCheck={onCancelAddCheck} save={saveCheck} onChange={onChangeCheck} onChangeAutocompleteCheck={onChangeAutocompleteCheck} onChangeInputCheck={onChangeInputCheck} checkDefs={checkDefs} />
                        : null}
                </div>
            ) :
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{customLabel("maintenance.machine")}</TableCell>
                            <TableCell>{customLabel("odp.creationDate")}</TableCell>
                            <TableCell>{customLabel("process.note")}</TableCell>
                            <TableCell align={"right"}><Button onClick={onCreate} className={classes.buttonNM} variant="contained" color="primary">{customLabel("button.add")}</Button></TableCell>
                        </TableRow>
                        {filter.showFilter && extOdps ? (
                            <TableRow>
                                <TableCell>
                                    <Autocomplete
                                        PaperComponent={PaperAutocomplete}
                                        options={machine} filterSelectedOptions size="small"
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        onChange={(event, value, reason) => onChangeAutocomplete(event, value, reason, "machine")}
                                        getOptionLabel={(option) => option.code}
                                        renderInput={(params) => (<TextField {...params} placeholder={customLabel("machine.machine")} />)}
                                        value={filter.machine ? filter.machine : null} />
                                </TableCell>
                                <TableCell>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <DatePicker
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton size="small" onClick={(e) => handleResetDate(e, "dateCreatedFrom")}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                )
                                            }}
                                            disableToolbar
                                            allowKeyboardControl={false}
                                            variant="inline"
                                            format={bishopFormats.L}
                                            autoOk
                                            onChange={(date) => handleChangeDate("dateCreatedFrom", date)}
                                            label={customLabel("function.from")}
                                            value={filter.dateCreatedFrom ? filter.dateCreatedFrom : null}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <br />
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <DatePicker
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton size="small" onClick={(e) => handleResetDate(e, "dateCreatedTo")}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                )
                                            }}
                                            disableToolbar
                                            allowKeyboardControl={false}
                                            variant="inline"
                                            format={bishopFormats.L}
                                            autoOk
                                            onChange={(date) => handleChangeDate("dateCreatedTo", date)}
                                            label={customLabel("function.to")}
                                            value={filter.dateCreatedTo ? filter.dateCreatedTo : null}
                                        />
                                    </MuiPickersUtilsProvider>
                                </TableCell>
                            </TableRow>
                        ) : null}
                    </TableHead>
                    {extOdps ? (
                        <TableBody>
                            {extOdps.map((o) => {
                                if (o.endDate == null) {
                                    return (
                                        <TableRow key={o.id}>
                                            <TableCell>{o.machine.code} - {o.machine.name}</TableCell>
                                            <TableCell>{moment(o.deliveryDate).format(bishopFormats.LTS)}</TableCell>
                                            <TableCell>{o.note}</TableCell>
                                            <TableCell align={"right"}>
                                                <Button onClick={() => dispatch(startAPI(o, maintener, true))} className={classes.buttonNM} variant="contained" color="primary">{customLabel("button.start")}</Button>
                                                <Button onClick={() => dispatch(select(o))} className={classes.buttonNM} variant="contained" color="default">{customLabel("button.details")}</Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                return null;
                            })}
                        </TableBody>
                    ) : null}
                </Table>
            }

        </Paper>
    )
}

export default Extraordinary;