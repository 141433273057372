import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { articlesSelector, getListAPI as getArticlesAPI } from "../articles/slice";
import { bomsSelector, getAPI as getBomAPI, getListAPI as getBomsAPI } from "../boms/slice";
import { configurationSelector } from "../configuration/slice";
import { selector as customerOrdersSelector, getAPI as getCustomerOrderAPI } from "../customerOrders/slice";
import { customersSelector, getListAPI as getCustomersAPI } from "../customers/slice";
import { getListAPI as getMachineEventsAPI, machineEventsSelector } from "../machineEvents/slice";
import { getListAPI as getProcessStatusesAPI } from "../processStatuses/slice";
import { getListAPI as getProcessTypesAPI, processTypesSelector } from '../processTypes/slice';
import { getListAPI as getSuppliersAPI } from "../suppliers/slice";
import { getListAPI as getUserEventsAPI } from "../userEvents/slice";
import { getListAPI as getWorkcentresAPI } from "../workcentres/slice";
import DetailsBom from "./DetailsBom";
import DetailsOdp from "./DetailsOdp";
import FormOdp from "./FormOdp";
import NewDetailsBom from "./NewDetailsBom";
import NewProductionData from "./NewProductionData";
import OdpHome from "./OdpHome";
import Home from "./editProcess/Home";
import MachineEvents from "./editProcess/MachineEvents";
import {
  addCheck, addProcess, addVar, cancelEdit, cancelIsCreated, change, changeCheck,
  changeCurrentVar, changeObject, changeTabIndex, changeVar, confirmDelete, create,
  createAPI, createCheckAPI, createVar, createVarsOdp, deleteAPI, deleteStaplingAPI,
  disableFlagEditBom, disableInsertVarFlag, disableVarFlag, edit, enableFlagEditBom,
  enableInsertVarFlag, enableVarFlag, getAPI, insertVarsAPI, odpsSelector,
  onChangeSort, presetOdp, removeProcess, reopenProcessAPI, resetCheck, resetState,
  resetVar, restoreProcessAPI, selectProcess, setBomProcesses, setCheck, setEditProcess,
  setReload, setReloadDrProcessEvents, setReloadMachineEvents, setReloadStaplingMachineEvents,
  setVar, stopOdpAPI, updateAPI, updateCheckAPI, updateOdpProcessesAPI, updateVar
} from "./slice";

const OdpPage = ({ match }) => {
  const dispatch = useDispatch();
  const { odp, odpedit, isCreated, isDeleted, loading, hasErrors, deleting, editmode,
    adminVars, bomProcesses, flagEditBom, events, colors, currentProcess, editProcess,
    totEvents, kpiEvents, totEventsKpi, barEvents, barKpiEvents, process, currentMachineEvents,
    loadingMachineEvents, reloadMachineEvents, resultsMachineEvents, tabIndex,
    choosenVar, insertVarFlag, listVarFlag, currentVar, editVar, currentVars, processMachineVars,
    drProcessEvents, loadingDrProcessEvents, reloadDrProcessEvents, resultsDrProcessEvents,
    loadingStaplingMachineEvents, resultsStaplingMachineEvents, reloadStaplingMachineEvents, currentStaplingMachineEvents,
    editCheck, currentChecks, crossingTime } = useSelector(odpsSelector);
  const { customerOrder } = useSelector(customerOrdersSelector)
  const { customers } = useSelector(customersSelector)
  const { boms, bom, loading: bomLoading } = useSelector(bomsSelector)
  const { articles } = useSelector(articlesSelector)
  const { machineEvents } = useSelector(machineEventsSelector);
  const { processTypes } = useSelector(processTypesSelector)
  const { configuration } = useSelector(configurationSelector)

  const [currentBom, setCurrentBom] = useState(false)
  const [quantityAlert, setQuantityAlert] = useState(false)
  const [bigQuantityAlert, setBigQuantityAlert] = useState(false)
  const [isOdpSerial, setIsOdpSerial] = useState(false)
  const [isProcessTypeSerial, setIsProcessTypeSerial] = useState(false)

  const [closeOdpFlag, setCloseOdpFlag] = React.useState(false);

  useEffect(() => {
    const { id, customerOrder } = match.params;
    //se l'odp è stato creato ed è già avvenuto il redirect in fondo alla pagina, rimuovo isCreated
    if (isCreated && odp.id.toString() === id) {
      dispatch(cancelIsCreated())
    }
    // se l'odp è appena stato selezionato dalla table list o è cambiato l'id nell'url:
    if (!isCreated && (odp === false || odp.id.toString() !== id) && !loading && !hasErrors) {
      //se si arriva da ordina clienti per la creazione della commessa si inizializza la creazione
      if (id === "new" && customerOrder !== undefined) {
        dispatch(create());
      } else {
        dispatch(getAPI(id));
      }
    }
  }, [dispatch, match, odp, loading, isCreated, hasErrors]);

  useEffect(() => {
    //usati per FormOdp
    dispatch(getBomsAPI(0, 100000, "name", "asc", false, false));
    dispatch(getCustomersAPI(0, 100000, "code", "asc", false, false));
    dispatch(getArticlesAPI(0, 100000, "code", "asc", false, false, false, false, false));
    //usati per altri file
    dispatch(getProcessStatusesAPI(0, 10000, "name", "asc", false));
    dispatch(getSuppliersAPI(0, 10000, "name", "asc", false, false));
    dispatch(getWorkcentresAPI(0, 10000, "name", "asc", false, false));
    dispatch(getUserEventsAPI(0, 10000, "name", "asc", false));
    dispatch(getMachineEventsAPI(0, 10000, "name", "asc", false));
    dispatch(getProcessTypesAPI(0, 100000, "code", "asc", false, false));
  }, [dispatch]);

  useEffect(() => {
    // se nell'url c'è l'id dell'ordine cliente, richiamo l'ordine cliente solo dopo aver inizializzato l'odp
    if (match.params.customerOrder && odp !== false && customerOrder === false) {
      dispatch(getCustomerOrderAPI(match.params.customerOrder))
    };
  }, [dispatch, match, odp, customerOrder])

  useEffect(() => {
    if (customerOrder && customerOrder.article.id !== null && customerOrder.article.bom.id !== null && !bom && !bomLoading) {
      dispatch(getBomAPI(customerOrder.article.bom.id))
    }
  }, [dispatch, customerOrder, bom, bomLoading])

  useEffect(() => {
    if (odp && odp.id === "new" && !isCreated && odpedit.customerOrder.id === "" && customerOrder && boms && articles
      && (!(customerOrder.article.id !== null && customerOrder.article.bom.id !== null) || (customerOrder.article.id !== null && customerOrder.article.bom.id !== null && bom))) {
      dispatch(presetOdp({ customerOrder: customerOrder, boms: boms, articles: articles, bom: bom ? bom : false }))
    }
  }, [dispatch, match, boms, articles, customerOrder, isCreated, odp, odpedit, bom]);

  //SPIEGAZIONE: la schermata OdpHome viene mostrata una volta caricato odp e machineEvents;
  //Se si arriva da customerOrder per la creazione dell'ordine, presetOrder verrà chiamata una volta caricato customerOrder, boms 
  //ed eventualmente bom

  //viene chiamato solo la prima volta, quando editProcess è false, ed inizializzato con il currentProcess
  useEffect(() => {
    if (odp && currentProcess !== false && odp.processes.length > 0 && (!editProcess || editProcess.odp.id !== odp.id)) {
      dispatch(setEditProcess({ currentProcessId: currentProcess.id, processes: odp.processes }))
    }
  }, [dispatch, odp, editProcess, currentProcess])

  const changeEditProcess = (processId) => {
    dispatch(setEditProcess({ currentProcessId: processId, processes: odp.processes }))
  };

  useEffect(() => {
    //aggiorno le variabili ogni volta che il currentBom è valorizzato
    if (currentBom && bom && bom.id === currentBom && odp.id === "new") {
      setCurrentBom(false)
      dispatch(createVarsOdp(bom))
    }
  }, [dispatch, bom, currentBom, odp])

  useEffect(() => {
    if (odp && odp.id !== 'new') {
      for (let i = 0; i < odp.processes.length; i++) {
        if (odp.processes[i].vars.length > 0) {
          for (let j = 0; j < odp.processes[i].vars.length; j++) {
            if (odp.processes[i].vars[j].serial === true) {
              setIsOdpSerial(true)
            }
          }
        }
      }
    }
    for (let i = 0; i < processTypes.length; i++) {
      if (processTypes[i].vars.length > 0) {
        for (let j = 0; j < processTypes[i].vars.length; j++) {
          if (processTypes[i].vars[j].serial === true) {
            setIsProcessTypeSerial(true)
          }
        }
      }
    }

  }, [odp, processTypes])

  const onEdit = () => {
    dispatch(edit());
  };

  const toggleDelete = () => {
    dispatch(confirmDelete());
  };

  const doDelete = () => {
    if (!odp.stapling) {
      dispatch(deleteAPI(odp));
    } else {
      dispatch(deleteStaplingAPI(odp));
    }
  };

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === 'quantity' && value !== odp.quantity.toString() && odp.id !== 'new' && isOdpSerial) {
      setQuantityAlert(true)
    } else {
      setQuantityAlert(false)
    }
    if (name === 'quantity' && value > 99 && isProcessTypeSerial) {
      setBigQuantityAlert(true)
    } else {
      setBigQuantityAlert(false)
    }
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    if (name === "quantity" && odpedit.bom.id !== "" && odp.id === "new") {
      setCurrentBom(odpedit.bom.id)
      //dispatch(getBomAPI(odpedit.bom.id))
    }
    //prima che i rispettivi array siano caricati gli input sono disabilitati
    dispatch(change({ name: name, value: value, customers: customers, articles: articles, boms: boms }));
  };

  const handleChangeDate = (name, value) => {
    dispatch(change({ name: name, value: new Date(value.format()).getTime() }));
  };

  const handleChangeObject = (name, value) => {
    //prima che i rispettivi array siano caricati gli input sono disabilitati
    if (value !== false) {
      let bomId = false;
      if (name === "bom.id") {
        bomId = value
      }
      if (name === "article.id") {
        const artIndex = articles.findIndex((a) => {
          return a.id.toString() === value.toString();
        })
        if (artIndex !== -1) {
          if (articles[artIndex].bom.id !== null) {
            bomId = articles[artIndex].bom.id
          }
        }
      }
      if (bomId && odp.id === "new") {
        setCurrentBom(bomId)
        //se cambio distinta o articolo con distinta, carico bom
        dispatch(getBomAPI(bomId))
      }
      dispatch(change({ name: name, value: value, customers: customers, articles: articles, boms: boms }))
    }
    else {
      dispatch(changeObject({ name: name, value: false }))
    }
  };

  //il parametro booleano è il customerOrder, serve per indicare salva e non rimanere al ordine
  const save = () => {
    if (odp.id === "new") {
      dispatch(createAPI(odpedit, adminVars, false));
    } else {
      dispatch(updateAPI(odpedit));
    }
  };

  //il parametro booleano è il customerOrder, serve per indicare salva e rimani nel ordine
  const saveFromOrder = () => {
    if (odp.id === "new") {
      dispatch(createAPI(odpedit, adminVars, customerOrder));
    }
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(odpedit));
    setQuantityAlert(false)
    setBigQuantityAlert(false)
  };

  const resetOdpsState = () => {
    dispatch(resetState());
  }

  const editBom = () => {
    dispatch(setBomProcesses(odp.processes))
    dispatch(enableFlagEditBom())
  };

  const saveBomProcesses = () => {
    dispatch(disableFlagEditBom())
    dispatch(updateOdpProcessesAPI(odp, bomProcesses))
  };

  const enableCloseOdpFlag = () => {
    setCloseOdpFlag(true);
  };

  const confirmCloseOdp = () => {
    dispatch(stopOdpAPI(odp));
    setCloseOdpFlag(false);
  };

  const cancelCloseOdp = () => {
    setCloseOdpFlag(false);
  };

  const renderDetailsOdp = () => {
    return (
      <DetailsOdp odp={odp} onEdit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete} loading={loading} />
    )
  }

  const renderFormOdp = () => {
    return (
      <FormOdp odpedit={odpedit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save} boms={boms} articles={articles}
        handleChangeDate={handleChangeDate} handleChangeObject={handleChangeObject} fromCustomerOrder={match.params.customerOrder ? match.params.customerOrder : false}
        loading={loading} adminVars={adminVars} resetOdpsState={resetOdpsState} saveFromOrder={saveFromOrder} quantityAlert={quantityAlert} bigQuantityAlert={bigQuantityAlert}
        waitForLoadingObjects={(match.params.customerOrder && !customerOrder) || !customers || !articles || !boms || (customerOrder && customerOrder.article.id !== null && customerOrder.article.bom.id !== null && !bom)}
      />
    )
  }

  const renderNewDetailsBom = () => {
    return (
      <NewDetailsBom odp={odp} loading={loading} bomProcesses={bomProcesses} flagEditBom={flagEditBom}
        editBom={editBom} enableCloseOdpFlag={enableCloseOdpFlag} saveBomProcesses={saveBomProcesses} closeOdpFlag={closeOdpFlag} confirmCloseOdp={confirmCloseOdp}
        cancelCloseOdp={cancelCloseOdp} addProcess={addProcess} removeProcess={removeProcess} onChangeSort={onChangeSort}
        disableFlagEditBom={disableFlagEditBom} />
    )
  }

  const renderProductionData = () => {
    return (
      <NewProductionData events={events} colors={colors} totEvents={totEvents} kpiEvents={kpiEvents} totEventsKpi={totEventsKpi}
        startDate={odp.startDate} endDate={odp.endDate} status={odp.status} stapling={odp.stapling} barEvents={barEvents} barKpiEvents={barKpiEvents} maintenance={odp.maintenance}
        crossingTime={crossingTime} />
    )
  }

  const renderDetailsBom = () => {
    return (
      <DetailsBom odp={odp} loading={loading} bomProcesses={bomProcesses} flagEditBom={flagEditBom}
        editBom={editBom} enableCloseOdpFlag={enableCloseOdpFlag} saveBomProcesses={saveBomProcesses} closeOdpFlag={closeOdpFlag} confirmCloseOdp={confirmCloseOdp}
        cancelCloseOdp={cancelCloseOdp} addProcess={addProcess} removeProcess={removeProcess} onChangeSort={onChangeSort}
        disableFlagEditBom={disableFlagEditBom} changeEditProcess={changeEditProcess} editProcess={editProcess} />
    )
  }

  const renderHome = () => {
    return (
      <Home odp={odp} loading={loading} editProcess={editProcess} setReload={setReload} process={process} selectProcess={selectProcess}
        restoreProcessAPI={restoreProcessAPI} reopenProcessAPI={reopenProcessAPI} currentMachineEvents={currentMachineEvents} loadingMachineEvents={loadingMachineEvents}
        reloadMachineEvents={reloadMachineEvents} resultsMachineEvents={resultsMachineEvents} setReloadMachineEvents={setReloadMachineEvents}
        choosenVar={choosenVar} insertVarFlag={insertVarFlag} listVarFlag={listVarFlag}
        currentVar={currentVar} editVar={editVar} currentVars={currentVars} processMachineVars={processMachineVars}
        insertVarsAPI={insertVarsAPI} changeCurrentVar={changeCurrentVar} enableInsertVarFlag={enableInsertVarFlag} enableVarFlag={enableVarFlag}
        disableVarFlag={disableVarFlag} disableInsertVarFlag={disableInsertVarFlag} setVar={setVar} resetVar={resetVar} addVar={addVar}
        createVar={createVar} updateVar={updateVar} changeVar={changeVar} drProcessEvents={drProcessEvents} loadingDrProcessEvents={loadingDrProcessEvents}
        reloadDrProcessEvents={reloadDrProcessEvents} resultsDrProcessEvents={resultsDrProcessEvents}
        setReloadDrProcessEvents={setReloadDrProcessEvents} editCheck={editCheck} currentChecks={currentChecks} changeCheck={changeCheck} resetCheck={resetCheck}
        addCheck={addCheck} updateCheckAPI={updateCheckAPI} createCheckAPI={createCheckAPI} setCheck={setCheck} configuration={configuration} />
    )
  }

  const renderMachineEvents = () => {
    return (
      <MachineEvents odp={odp} loading={loading} currentMachineEvents={currentMachineEvents}
        loadingMachineEvents={loadingMachineEvents} reloadMachineEvents={reloadMachineEvents} resultsMachineEvents={resultsMachineEvents}
        setReloadMachineEvents={setReloadMachineEvents} editProcess={editProcess} loadingStaplingMachineEvents={loadingStaplingMachineEvents}
        resultsStaplingMachineEvents={resultsStaplingMachineEvents} reloadStaplingMachineEvents={reloadStaplingMachineEvents}
        currentStaplingMachineEvents={currentStaplingMachineEvents} setReloadStaplingMachineEvents={setReloadStaplingMachineEvents} />
    )
  }

  const renderodp = () => {
    // if (odp !== false && match.params.id === odp.id.toString() && processStatuses) {
    if (odp !== false && match.params.id === odp.id.toString() && machineEvents) {
      return (
        <React.Fragment>
          <OdpHome match={match} loading={loading} odp={odp} renderDetailsOdp={renderDetailsOdp} renderFormOdp={renderFormOdp}
            renderNewDetailsBom={renderNewDetailsBom} renderProductionData={renderProductionData} renderDetailsBom={renderDetailsBom} configuration={configuration}
            renderHome={renderHome} renderMachineEvents={renderMachineEvents} editmode={editmode} odpedit={odpedit} changeTabIndex={changeTabIndex} tabIndex={tabIndex}/>
        </React.Fragment>
      );
    }
    return;
  };

  if (isDeleted && match.params.id === odp.id.toString()) {
    return <Redirect to={"/odps/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/odps/" + odp.id} />;
  }

  return <Box>{renderodp()}</Box>;
};

export default OdpPage;
