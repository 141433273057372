import _ from 'lodash';

export const createVarsCreationOdp = (bom, qta) => {
    let vars = [];
    let tempId = 0;
    let quantity = 0;
    if (qta !== 0 && qta !== "") {
        quantity = qta;
    }
    let bomProcesses = _.cloneDeep(bom.bomProcesses);
    bomProcesses.forEach(p => {
        p.vars.forEach(v => {
            let newVar = {};
            if (v.adminEdit && !v.serial) {
                newVar["process"] = p.name;
                newVar["tempId"] = tempId;
                newVar["sortOrderProcess"] = p.sortOrder;
                newVar["value"] = null;
                newVar["type"] = v.type;
                newVar["editable"] = v.editable;
                newVar["name"] = v.name;
                newVar["serial"] = false;
                newVar["serialNo"] = null;
                newVar["defId"] = v.id;
            } else if (v.adminEdit && v.serial) {
                for (let i = 1; i <= quantity; i++) {
                    let serialVar = {};
                    serialVar["process"] = p.name;
                    serialVar["tempId"] = tempId;
                    serialVar["sortOrderProcess"] = p.sortOrder;
                    serialVar["value"] = null;
                    serialVar["type"] = v.type;
                    serialVar["editable"] = v.editable;
                    serialVar["name"] = v.name;
                    serialVar["serial"] = true;
                    serialVar["serialNo"] = i;
                    serialVar["defId"] = v.id;
                    vars.push(serialVar);
                    tempId++;
                }
            }
            if (Object.keys(newVar).length > 0) {
                vars.push(newVar);
                tempId++;
            }
        })
    });
    return vars;
}

export const customFilter = {
    mesName: "odps",
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "code",
    showFilter: false,
    code: false,
    extCode: false,
    priority: false,
    toBeProcessed: false,
    customerOrder: false,
    customer: false,
    bom: false,
    article: false,
    status: false,
    deliveryDateFrom: false,
    deliveryDateTo: false,
    startDateFrom: false,
    startDateTo: false,
    endDateFrom: false,
    endDateTo: false,
    dateCreatedFrom: false,
    dateCreatedTo: false
}