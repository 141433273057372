import { Button } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from '../../utility/customLabel';
import {
  disableFlagUserEvents,
  operatorViewSelector,
  stopUserEventAPI
} from "../slice";
import DialogOperator from "../utility/DialogOperator";
import { opViewStyles } from "../utility/customStyles";

/**
 * 
 * @param {boolean} existChecks di avvio o ripresa a seconda dello stato del process
 * @param {function} onConfirm funzione da chiamare sul conferma
 * @returns 
 */
const UserEventsDialog = ({ existChecks, onConfirm }) => {
  const customClasses = opViewStyles();
  const dispatch = useDispatch();
  const { loading, userEvents, userEvent,
    flagDialog, errorsUserEventTypes } = useSelector(operatorViewSelector);

  const stopUserEvent = (userEvent) => {
    dispatch(stopUserEventAPI(userEvent, false, true, false)); 
  };

  const renderDialogContent = () => {
    if (errorsUserEventTypes) {
      return (
        <React.Fragment>
          {customLabel("function.loadingError").toUpperCase()}
        </React.Fragment>
      )
    }
    if (userEvents && userEvents.length > 0) {
      return (
          <div className={customClasses.listContainerList}>
            {userEvents !== false ? (userEvents.map((ue) => (
              <div key={ue.id}>
                {userEvent===false || userEvent.id!==ue.id ? (
                <><Button value={ue.id} className={customClasses.innerButton83} color={"default"}
                variant={"outlined"} disabled={loading}>
                  {ue.machine.name}: {ue.process.id !== null? ue.process.code: ue.stapling.code} ({ue.type.name})
                </Button>
                <Button className={customClasses.innerButton15} color={"secondary"} variant={"outlined"} onClick={()=> stopUserEvent(ue) }>{customLabel("button.close")}</Button>
                </>):null}
              </div>
            ))
            ) : null}
          </div>
      )
    } else return (
      <React.Fragment>
        {customLabel("function.notAvailable").toUpperCase()}
      </React.Fragment>
    )
  }

  const renderDialogActions = () => {
    return (
      <React.Fragment>
        <Button className={customClasses.dialogButton} variant="contained" onClick={() => handleCloseDialog()} disabled={loading} color="secondary">{customLabel("button.close")}</Button>
      </React.Fragment>
    )
  }
  //chiude dialog di aggiunta del userEventType
  const handleCloseDialog = () => {
    dispatch(disableFlagUserEvents());
  };

  return (
    <DialogOperator flag={flagDialog.flagUserEvents} title={customLabel("operatorView.userEventsOpen")}
      form={() => renderDialogContent()} actions={() => renderDialogActions()} transparentBackround={false} />
  );
}

export default UserEventsDialog;