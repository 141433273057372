import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormControl, InputLabel, Input, Divider, Button, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { configurationSelector } from "../configuration/slice";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";

export const Form = ({ roleedit, handleChange, cancelEdit, save, loading }) => {
  const myClasses = mesStyles();
  const { configuration } = useSelector(configurationSelector);

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">{customLabel("role.role")}</Typography>
        <LockOpenIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="authority">{customLabel("role.name")}</InputLabel>
          <Input autoFocus name="authority" error={roleedit.authority === ""} value={roleedit.authority} onChange={handleChange} />
        </FormControl>
        {configuration && configuration.enableMaintenance ? (
          <FormControlLabel control={<Checkbox checked={roleedit.maintenance} onChange={handleChange} name="maintenance" color="default" />}
            label={customLabel("maintenance.maintenance")} />
        ) : null}
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple onClick={save} disabled={roleedit.authority === "" || loading} color="primary">{customLabel("button.save")}</Button>
        {roleedit.id !== "new" ? (
          <Button focusRipple onClick={cancelEdit} disabled={loading}>{customLabel("button.cancel")}</Button>
        ) : (
          <Button component={Link} to={`/admin/roles`} disabled={loading}>{customLabel("button.back")}</Button>
        )}
      </div>
    </PaperForm>
  );
};
