import {
    Checkbox, FormControl,
    FormControlLabel,
    Grid, Input,
    InputLabel, MenuItem,
    Select
} from "@material-ui/core";
import React from "react";
import { SwatchesPicker } from "react-color";
import { useDispatch } from "react-redux";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";
import DragDrop from "../DragDrop";
import { change } from "../slice";
import { labelArticleOpView, labelMachineOpView, labelOperatorOpView, labelWorkcentreOpView, odpDetailsOpViewLabels, odpOpViewLabels } from "../utility";

const Operator = ({ configurationedit, handleChange }) => {
    const myClasses = mesStyles();
    const dispatch = useDispatch()

    const onChange = (data) => {
        dispatch(change(data))
    }

    const handleChangeComplete = (color) => {
        //active color
        let c = "rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + ",1)";
        let e = { preventDefault: function () { }, persist: function () { } };
        let t = { name: "justifyDialogColor", value: c };
        e["target"] = t;
        handleChange(e);
    };

    return (
        <div className={myClasses.dialogForm500}>
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.showUsers} onChange={handleChange} name="showUsers" color="default" />
                }
                label={customLabel("configuration.showUsers")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.askUserEvent} onChange={handleChange} name="askUserEvent" color="default" />
                }
                label={customLabel("configuration.askUserEvent")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.showCustomerInterface} onChange={handleChange} name="showCustomerInterface" color="default" />
                }
                label={customLabel("configuration.showCustomerInterface")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.showAlerts} onChange={handleChange} name="showAlerts" color="default" />
                }
                label={customLabel("configuration.showAlerts")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.enableInputOperator} onChange={handleChange} name="enableInputOperator" color="default" />
                }
                label={customLabel("configuration.enableInputOperator")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.opViewEnableOdpSearch} onChange={handleChange} name="opViewEnableOdpSearch" color="default" />
                }
                label={customLabel("configuration.opViewEnableOdpSearch")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.showExtMachines} onChange={handleChange} name="showExtMachines" color="default" />
                }
                label={customLabel("configuration.showExtMachines")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.enableInsertExtCode} onChange={handleChange} name="enableInsertExtCode" color="default" />
                }
                label={customLabel("configuration.enableInsertExtCode")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.blockingChecks} onChange={handleChange} name="blockingChecks" color="default" />
                }
                label={customLabel("configuration.blockingChecks")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.showOdpQtyPreview} onChange={handleChange} name="showOdpQtyPreview" color="default" />
                }
                label={customLabel("configuration.showOdpQtyPreview")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.showOdpRemainingQuantity} onChange={handleChange} name="showOdpRemainingQuantity" color="default" />
                }
                label={customLabel("configuration.showOdpRemainingQuantity")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.autoPopupJustify} onChange={handleChange} name="autoPopupJustify" color="default" />
                }
                label={customLabel("configuration.autoPopupJustify")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.enableJustifyDialogColor} onChange={handleChange} name="enableJustifyDialogColor" color="default" />
                }
                label={customLabel("configuration.enableJustifyDialogColor")}
            />
            {configurationedit.enableJustifyDialogColor ? (
                <>
                    <InputLabel style={{ marginTop: '10px', marginBottom: '10px' }} htmlFor="justifyDialogColor">{customLabel("function.color")}</InputLabel>
                    <div>
                        <SwatchesPicker color={configurationedit.justifyDialogColor !== false && configurationedit.justifyDialogColor !== null && configurationedit.justifyDialogColor !== "" ? configurationedit.justifyDialogColor : "rgba(0,0,0)"}
                            onChange={(color) => handleChangeComplete(color)} />
                    </div>
                </>
            ) : null}
            <br />
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="opViewOdpListSize">{customLabel("configuration.opViewOdpListSize")}</InputLabel>
                <Input name="opViewOdpListSize" type="number"
                    error={configurationedit.opViewOdpListSize === "" || configurationedit.opViewOdpListSize < 0} value={configurationedit.opViewOdpListSize !== false ? configurationedit.opViewOdpListSize : ""}
                    onChange={handleChange} />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="odpSortOrder">{customLabel("configuration.odpSortOrder")}</InputLabel>
                <Select name="odpSortOrder" value={configurationedit.odpSortOrder} onChange={handleChange}>
                    <MenuItem value="priority">{customLabel("process.priority")}</MenuItem>
                    <MenuItem value="deliveryDate">{customLabel("odp.deliveryDate")}</MenuItem>
                    <MenuItem value="code">{customLabel("odp.code")}</MenuItem>
                </Select>
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="labelArticleOpView">{customLabel("configuration.labelArticleOpView.labelArticleOpView")}</InputLabel>
                <Select name="labelArticleOpView" value={configurationedit.labelArticleOpView} onChange={handleChange}>
                    <MenuItem value={labelArticleOpView["code"]}>{customLabel("configuration.labelArticleOpView.code")}</MenuItem>
                    <MenuItem value={labelArticleOpView["codeName"]}>{customLabel("configuration.labelArticleOpView.codeName")}</MenuItem>
                    <MenuItem value={labelArticleOpView["name"]}>{customLabel("configuration.labelArticleOpView.name")}</MenuItem>
                </Select>
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="labelWorkcentreOpView">{customLabel("configuration.labelWorkcentreOpView.labelWorkcentreOpView")}</InputLabel>
                <Select name="labelWorkcentreOpView" value={configurationedit.labelWorkcentreOpView} onChange={handleChange}>
                    <MenuItem value={labelWorkcentreOpView["code"]}>{customLabel("configuration.labelWorkcentreOpView.code")}</MenuItem>
                    <MenuItem value={labelWorkcentreOpView["codeName"]}>{customLabel("configuration.labelWorkcentreOpView.codeName")}</MenuItem>
                    <MenuItem value={labelWorkcentreOpView["name"]}>{customLabel("configuration.labelWorkcentreOpView.name")}</MenuItem>
                </Select>
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="labelMachineOpView">{customLabel("configuration.labelMachineOpView.labelMachineOpView")}</InputLabel>
                <Select name="labelMachineOpView" value={configurationedit.labelMachineOpView} onChange={handleChange}>
                    <MenuItem value={labelMachineOpView["code"]}>{customLabel("configuration.labelMachineOpView.code")}</MenuItem>
                    <MenuItem value={labelMachineOpView["codeName"]}>{customLabel("configuration.labelMachineOpView.codeName")}</MenuItem>
                    <MenuItem value={labelMachineOpView["name"]}>{customLabel("configuration.labelMachineOpView.name")}</MenuItem>
                </Select>
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="labelOperatorOpView">{customLabel("configuration.labelOperatorOpView.labelOperatorOpView")}</InputLabel>
                <Select name="labelOperatorOpView" value={configurationedit.labelOperatorOpView} onChange={handleChange}>
                    <MenuItem value={labelOperatorOpView["code"]}>{customLabel("configuration.labelOperatorOpView.code")}</MenuItem>
                    <MenuItem value={labelOperatorOpView["codeUsername"]}>{customLabel("configuration.labelOperatorOpView.codeUsername")}</MenuItem>
                    <MenuItem value={labelOperatorOpView["name"]}>{customLabel("configuration.labelOperatorOpView.name")}</MenuItem>
                </Select>
            </FormControl>
            <Grid container size="small" className={myClasses.confDetailsBox}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <DragDrop labelsSaved={configurationedit.labelOdpOpView} labelsList={odpOpViewLabels}
                        name="labelOdpOpView" labelType={customLabel("configuration.labelOdpOpView.labelOdpOpView")} change={onChange} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <DragDrop labelsSaved={configurationedit.labelOdpDetailsOpView} labelsList={odpDetailsOpViewLabels}
                        name="labelOdpDetailsOpView" labelType={customLabel("configuration.labelOdpDetailsOpView.labelOdpDetailsOpView")} change={onChange} />
                </Grid>
            </Grid>
        </div>
    );
};
export default Operator;