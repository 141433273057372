import React from "react";
import { Link } from "react-router-dom";
import { FormControl, InputLabel, Input, Divider, Button, Typography, TextField } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";

export const Form = ({ workcentreedit, handleChange, cancelEdit, save, loading, loadingMachines }) => {
  const myClasses = mesStyles();

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">{customLabel("workcentre.workcentre")}</Typography>
        <LockOpenIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="code">{customLabel("workcentre.code")}</InputLabel>
          <Input autoFocus name="code" error={workcentreedit.code === ""} value={workcentreedit.code} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="name">{customLabel("workcentre.name")}</InputLabel>
          <Input name="name" error={workcentreedit.name === ""} value={workcentreedit.name} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form100}>
          <TextField
            variant="outlined" name="note" label={customLabel("function.note")} multiline rows={4} value={workcentreedit.note}
            onChange={handleChange}
          />
        </FormControl>
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple onClick={save} disabled={workcentreedit.name === "" || workcentreedit.code === "" || loading || loadingMachines} color="primary">
          {customLabel("button.save")}
        </Button>
        {workcentreedit.id !== "new" ? (
          <Button focusRipple onClick={cancelEdit} disabled={loading || loadingMachines}>{customLabel("button.cancel")}</Button>
        ) : (
          <Button disabled={loading || loadingMachines} component={Link} to={`/workcentres`}>{customLabel("button.back")}</Button>
        )}
      </div>
    </PaperForm>
  );
};
