import {
  Button,
  Dialog,
  DialogActions, DialogContent, DialogTitle,
  IconButton,
  Input,
  Typography
} from "@material-ui/core";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bishopFormats, evolutionCode } from '../../../constants';
import { getFullListAPI as getMachinesAPI, machinesSelector, resetState as resetStateMachines } from "../../machines/slice";
import { showError } from "../../notifications/slice";
import { getListWithoutAPI as getRolesAPI, resetState as resetStateRoles, rolesSelector } from "../../roles/slice";
import { getListByRolesAPI as getUsersAPI, resetState as resetStateUsers, usersSelector } from "../../users/slice";
import { customLabel } from '../../utility/customLabel';
import { toggleFullScreen } from "../../utility/vari";
import { getListAPI as getWorkcentresAPI, resetState as resetStateWorkcentres, workcentresSelector } from "../../workcentres/slice";
import {
  changeCurrentValue,
  changeMachine,
  changeOdp,
  changeProcess,
  changeStaplingProcess,
  changeUserEvent,
  changeWorkcentre,
  getAllProcessesAPI,
  getMachineEventAPI,
  getMachineOdpsAPI,
  getOdpProcessesAPI,
  getOperatorAPI,
  getStaplingListAPI,
  getUserEventAPI,
  integrateEventAPI,
  integrateOdpStaplingAPI,
  noUserEvent,
  operatorViewSelector,
  resetMachine, resetOdp,
  resetOperator,
  resetStaplingProcess,
  resetUserEvents,
  resetWorkcentre,
  setMachines,
  setRolesSaved,
  setWorkcentres,
  startStapling,
  stopUserEventAPI
} from "../slice";
import { INDEX_INTERFACE, INDEX_MACHINE, INDEX_ODP, INDEX_OPERATOR, INDEX_PROCESS, INDEX_STAPLING, INDEX_USER_EVENT, INDEX_WORKCENTRE } from "../utility/constants";
import { opViewStyles } from "../utility/customStyles";
import { orderDetailsOdp, renderMachineLabel, renderOdpLabelInput, renderOperatorLabel } from "../utility/utility";
import FormStaplingProcesses from "./FormStaplingProcesses";

const InputOperator = ({ configuration }) => {
  const customClasses = opViewStyles();
  const dispatch = useDispatch();
  const { index, machinesAvailable, workcentre, machine, loading, odp, operator, newProcesses, oldProcesses, userEventChecked,
    odpsAvailable, currentValue, machineEvent, machineEventChecked, userEvent, errors, rodpsAvailable, listWorkcentres,
    listMachines, machineEventTypes, modeMaintenance, rolesSaved, process, machineEventAdded, errorSettings, modeStapling, staplingList,
    allProcesses, sodpsAvailable, currentOdp, userEvents
  } = useSelector(operatorViewSelector);
  const { workcentres } = useSelector(workcentresSelector);
  const { machines } = useSelector(machinesSelector);
  const { users } = useSelector(usersSelector);
  const { roles } = useSelector(rolesSelector);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (index === INDEX_ODP) {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        let reloadTimeout = setTimeout(() => {
          if (configuration.opViewEnableOdpSearch) {
            dispatch(getMachineOdpsAPI(machine.id, operator.id, modeMaintenance, currentValue, 0, configuration.opViewOdpListSize, false, configuration.enableInsertExtCode));
          }
        }, 750)
        return () => {
          clearTimeout(reloadTimeout)
        }
      }
    } else {
      isInitialMount.current = true
    }
  }, [dispatch, index, currentValue, configuration.opViewEnableOdpSearch, machine, operator, modeMaintenance, configuration.opViewOdpListSize, configuration.enableInsertExtCode])

  useEffect(() => {
    if (errorSettings) {
      dispatch(showError(customLabel("settings.error") + " (" + errorSettings.type + " - " + errorSettings.localCode + ")"))
    }
  }, [dispatch, errorSettings]);

  //carico ruoli e utenti collegati
  useEffect(() => {
    if (!rolesSaved) {
      dispatch(getRolesAPI(0, 10000, "authority", "asc", false));
    }
  }, [dispatch, rolesSaved]);
  useEffect(() => {
    if (!rolesSaved && roles) {
      dispatch(setRolesSaved({ localRoles: JSON.parse(localStorage.getItem("roles")), dbRoles: roles }));
    }
  }, [dispatch, roles, rolesSaved]);
  useEffect(() => {
    if (!users && rolesSaved) {
      dispatch(getUsersAPI(rolesSaved));
    }
  }, [dispatch, users, rolesSaved]);

  //ricarico workcentres ogni qualvolta necessito di fare il controllo con quelli salvati nel local storage
  useEffect(() => {
    if (!listWorkcentres) {
      dispatch(getWorkcentresAPI(0, 10000, "code", "asc", false, false));
    }
  }, [dispatch, listWorkcentres]);

  //ricarico machines ogni qualvolta necessito di fare il controllo con quelli salvati nel local storage
  useEffect(() => {
    if (!listMachines) {
      dispatch(getMachinesAPI());
    }
  }, [dispatch, listMachines]);

  //salva i centri di lavoro e le macchine presenti nel localStorage, confrontando con quelle nel db
  useEffect(() => {
    if (!listWorkcentres && workcentres) {
      dispatch(setWorkcentres({ localWorkcentres: JSON.parse(localStorage.getItem("workcentres")), dbWorkcentres: workcentres }))
    };
    if (!listMachines && machines) {
      dispatch(setMachines({ localMachines: JSON.parse(localStorage.getItem("machines")), dbMachines: machines }))
    };
  }, [dispatch, workcentres, machines, listMachines, listWorkcentres]);

  //API automatiche che vengono chiamate durante l'inserimento
  useEffect(() => {
    //carica eventuali userEvent in corso associati al utente selezionato, se esistono salta l'inserimento restante
    if (!loading && !errors && !userEventChecked && operator && !workcentre) {
      dispatch(getUserEventAPI(operator, configuration.singleUserEvent, true));
    };
    //carica eventuali machineEvent in corso associati alla macchina selezionata, se esistono salta l'inserimento restante
    //a questo punto getMachineEventAPI farà un chiamata callback o su details o su stapling
    if (!loading && !errors && index < INDEX_INTERFACE && !machineEventChecked && machine && !userEvent && userEventChecked) {
      dispatch(getMachineEventAPI(machine, modeMaintenance, operator));
    };
    //carica gli odp disponibili una volta selezionata la macchina
    if (!loading && !errors && index < INDEX_INTERFACE && !odpsAvailable && machine && machineEventChecked && !machineEvent) {
      dispatch(getMachineOdpsAPI(machine.id, operator.id, modeMaintenance, '', 0, configuration.opViewOdpListSize, false, configuration.enableInsertExtCode));
    };
    //carica le lavorazioni disponibili una volta selezionato l odp, nel caso click su pinza viene fatta chiamata callback getAllProcessAPI()???
    if (!loading && !errors && index < INDEX_INTERFACE && !newProcesses && !staplingList && odp && !odp.stapling && machineEventChecked && !machineEvent) {
      dispatch(getOdpProcessesAPI(odp, machine, operator, modeStapling));
    };
    //carica le lavorazioni della pinzatura in pausa una volta selezionato l'odp e rimanda all' interfaccia
    if (!loading && !errors && index < INDEX_INTERFACE && !newProcesses && !staplingList && odp && odp.stapling && machineEventChecked && !machineEvent) {
      dispatch(getStaplingListAPI(odp));
    };
    //carica le lavorazioni disponibili per la pinzatura per il dialog finale
    if (INDEX_STAPLING && !loading && !errors && !allProcesses && staplingList && staplingList.length === 1) {
      dispatch(getAllProcessesAPI(machine, operator, staplingList[0], '', 0, configuration.opViewOdpListSize));
    }

  }, [dispatch, errors, index, loading, machine, machineEvent, machineEventChecked, newProcesses, odp, odpsAvailable, operator, configuration.enableInsertExtCode, configuration.opViewOdpListSize,
    userEvent, userEventChecked, workcentre, machineEventTypes, modeMaintenance, process, machineEventAdded, modeStapling, allProcesses, staplingList, configuration.singleUserEvent]);

  //Aggiornamento della proprietà current value tramite Input field
  const handleChangeValueText = (event) => {
    dispatch(changeCurrentValue({ value: event.target.value }));
  };
  //Aggiornamento della proprietà current value solamente del odp, in modo da passare configuration
  const handleChangeValueTextOdp = (event) => {
    dispatch(changeCurrentValue({ value: event.target.value, extCode: configuration.enableInsertExtCode }));
  };
  //Aggiornamento dello proprietà current value tramite click su Button nella lista
  //btn può essere o code o id (per il process)
  const handleChangeValueBtn = (btn) => {
    dispatch(changeCurrentValue({ value: btn }));
  };
  const handleClickEnter = (event, currentFunction) => {
    if (event.key === "Enter") {
      if (index === INDEX_ODP) {
        currentFunction(true);
      } else {
        currentFunction()
      }
    } else {
      return;
    }
  }
  //click prosegui utente
  const handleAddOperator = () => {
    if (currentValue !== null && currentValue !== false && !errors) {
      dispatch(getOperatorAPI(currentValue, rolesSaved));
    }
  };
  //click prosegui centro lavoro
  const handleAddWorkcentre = () => {
    if (currentValue !== null && currentValue !== false) {
      dispatch(changeWorkcentre());
    }
  };
  //click indietro evento utente
  const handleRemoveUserEvent = () => {
    dispatch(resetOperator({ value: false }))
  }
  //click indietro centro lavoro
  const handleRemoveWorkCentre = () => {
    if(!userEvents||configuration.singleUserEvent){
      dispatch(resetOperator({ value: false }))
    } else if(userEvents.length>0){
      dispatch(resetUserEvents())
    } else{
      dispatch(resetOperator({ value: false }))
    }
  }
  //click prosegui macchina
  const handleAddMachine = () => {
    if (currentValue !== null && currentValue !== false) {
      dispatch(changeMachine({ machines: machinesAvailable }));
    }
  };
  //click indietro macchina
  const handleRemoveMachine = () => {
    if (listWorkcentres && listWorkcentres.length === 1) {
      if(!userEvents||configuration.singleUserEvent){
        dispatch(resetOperator({ value: false }))
      } else if(userEvents.length>0){
        dispatch(resetUserEvents())
      } else{
        dispatch(resetOperator({ value: false }))
      }
    } else {
      dispatch(resetWorkcentre());
    }
  }
  //click prosegui odp
  const handleAddOdp = (enter) => {
    if (currentValue !== null && currentValue !== false) {
      dispatch(changeOdp({ odps: odpsAvailable, rodps: rodpsAvailable, sodps: sodpsAvailable, stapling: false, extCode: configuration.enableInsertExtCode, enter: enter }))
    }
  };
  //click pinza odp
  const handleSetStapling = () => {
    if (currentValue !== null && currentValue !== false) {
      dispatch(changeOdp({ odps: odpsAvailable, stapling: true, extCode: configuration.enableInsertExtCode }))
    }
  };
  //click indietro odp
  const handleRemoveOdp = () => {
    if (machinesAvailable.length === 1 && listWorkcentres.length === 1) {
      if(!userEvents||configuration.singleUserEvent){
        dispatch(resetOperator({ value: false }))
      } else if(userEvents.length>0){
        dispatch(resetUserEvents())
      } else{
        dispatch(resetOperator({ value: false }))
      }
    } else if (machinesAvailable.length === 1 && listWorkcentres.length !== 1) {
      dispatch(resetWorkcentre())
    } else {
      dispatch(resetMachine())
    }
  };
  //click prosegui lavorazione normale
  const handleAddProcess = () => {
    if (currentValue !== null && currentValue !== false) {
      dispatch(changeProcess({ newP: newProcesses, oldP: oldProcesses }));
    }
  };
  //click prosegui lavorazione pinzatura
  const handleAddStaplingProcess = () => {
    dispatch(changeStaplingProcess({ newP: newProcesses }))
  }
  //click indietro lavorazione
  const handleRemoveProcess = () => {
    dispatch(resetOdp());
  };
  //click prosegui menu lavorazioni pinzatura
  const handleAddStaplingList = () => {
    dispatch(startStapling());
  };
  const handleRemoveStaplingList = () => {
    if (!newProcesses) {
      dispatch(resetOdp());
    } else {
      dispatch(resetStaplingProcess());
    }
  }
  //tasti reset/esci
  const handleChangeResetOperator = (exit) => {
    dispatch(resetOperator({ value: exit }));
    dispatch(resetStateWorkcentres());
    dispatch(resetStateMachines());
    dispatch(resetStateUsers());
    dispatch(resetStateRoles());
  };

  //mette focus sull'input
  const handleFocus = (event) => {
    event.preventDefault();
    return false;
  };

  const renderSelection = (list, isOdp = false, isProcess = false, caseInsensitive = false, isUserEvent = false) => {
    if (list && currentValue) {
      let element
      let index = list.findIndex((e) => {
        if (isProcess||isUserEvent) {
          return e.id.toString() === currentValue
        } else if (isOdp) {
          return configuration.enableInsertExtCode && e.extCode && e.extCode !== "" ? e.code.toString() === currentValue || e.extCode.toString() === currentValue : e.code.toString() === currentValue
        } else if (caseInsensitive) {
          return e.code.toString().toLowerCase() === currentValue.toString().toLowerCase()
        } else {
          return e.code.toString() === currentValue.toString()
        }
      })
      if (index !== -1) {
        element = list[index]
      }
      if (element) {
        return (
          <Button className={customClasses.selectedButtonInput} value={element.code}
            color={((isProcess || isUserEvent) && currentValue === element.id.toString()) || (isOdp && configuration.enableInsertExtCode && element.extCode && element.extCode !== "" && element.extCode.toString() === currentValue) || (!caseInsensitive && currentValue === element.code.toString()) || ((caseInsensitive && currentValue.toString().toLowerCase() === element.code.toString().toLowerCase())) ? "primary" : "default"}
            variant={(element.code && currentValue === element.code.toString()) || element.id.toString() ? "contained" : "outlined"}>
            {isOdp ? renderOdpLabelInput(element, configuration)
              : isProcess ? `${element.type.code} - ${element.type.name} - ${element.quantity} - ${element.article.measure} ${element.machine.id ? ` - (${element.machine.code})` : ""}`
              : isUserEvent ? `${element.machine.name} - ${element.machine.code} ${element.process.id !== null? `${element.process.code}` : `${element.stapling.code}`} (${element.type.name})`
              : `${element.name} - ${element.code}`}
          </Button>
        )
      } else {
        return <Button className={customClasses.selectedButtonInput} variant="outlined" disabled style={{ padding: '32px' }}></Button>
      }
    } else if (list && !currentValue) {
      return <Button className={customClasses.selectedButtonInput} variant="outlined" disabled style={{ padding: '32px' }}></Button>
    } else {
      return null
    }
  }

  const renderOdpDetails = () => {
    if (currentOdp && !currentOdp.stapling && !currentOdp.maintenance && configuration.labelOdpDetailsOpView && configuration.labelOdpDetailsOpView !== "") {
      return (
        orderDetailsOdp(configuration, currentOdp)
      )
    }
  }

  //Metodi che restituiscono lo step attuale per la creazione della lavorazione
  const insertOperator = () => {
    return (
      <div>
        <DialogTitle>
          {renderStepTitleUser()}
        </DialogTitle>
        <DialogContent>
          <div className={customClasses.formInputSection}>
            <Input onFocus={handleFocus} disabled={loading}
              inputRef={(input) => {
                if (input !== null) {
                  input.focus();
                }
              }}
              onKeyPress={(e) => handleClickEnter(e, handleAddOperator)}
              readOnly={configuration.enableInputOperator ? false : true}
              value={currentValue ? currentValue : ""} fullWidth name="operator" onChange={handleChangeValueText}></Input>
            {renderSelection(users)}
          </div>
          <div className={customClasses.listContainerList}>
            {configuration && configuration !== null && !configuration.showUsers ? (
              <React.Fragment>
                {(!users) && !loading ? (
                  <div className={customClasses.formInputSection}>
                    <Typography component="h1" variant="h6" color="inherit">
                      {customLabel("operatorView.noOperatorsAvailable")}
                    </Typography>
                  </div>
                ) : loading ? null : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {users !== false ?
                  (users.map((u) => (
                    u.code !== evolutionCode ? (
                      <div key={u.id} >
                        <Button value={u.code} className={customClasses.innerButton} color={"default"}
                          variant={currentValue === u.code.toString() ? "contained" : "outlined"} onClick={() => handleChangeValueBtn(u.code.toString())}
                          disabled={loading}>
                          {renderOperatorLabel(configuration, u)}
                        </Button>
                      </div>
                    ) : null
                  ))
                  ) : !loading ? (
                    <div className={customClasses.formInputSection}>
                      <Typography component="h1" variant="h6" color="inherit">
                        {customLabel("operatorView.noOperatorsAvailable")}
                      </Typography>
                    </div>
                  ) : null}
              </React.Fragment>
            )}
          </div>
        </DialogContent >
        <DialogActions style={{ padding: "16px 24px" }}>
          <div style={{ flex: "1 0 0" }} />
          <Button className={customClasses.dialogButton} onClick={handleAddOperator} variant="contained"
            disabled={loading || !currentValue || currentValue === "" || !users || errorSettings || !listMachines} color="primary">{customLabel("button.moveOn")}
          </Button>
        </DialogActions>
      </div >
    )
  };
  const insertWorkcentre = () => {
    return (
      <div>
        <DialogTitle>
          {renderStepTitle(customLabel("operatorView.insertWorkcentre"))}
        </DialogTitle>
        <DialogContent>
          <div className={customClasses.formInputSection}>
            <Input onFocus={handleFocus}
              inputRef={(input) => {
                if (input !== null) {
                  input.focus();
                }
              }}
              onKeyPress={(e) => handleClickEnter(e, handleAddWorkcentre)}
              readOnly={configuration.enableInputOperator ? false : true}
              value={currentValue ? currentValue : ""} fullWidth name="workcentre" onChange={handleChangeValueText}></Input>
            {renderSelection(listWorkcentres, false, false, true)}
          </div>
          <div className={customClasses.listContainerList}>
            {listWorkcentres !== false && listWorkcentres.length > 0 ? (listWorkcentres.map((w) => (
              <div key={w.id} >
                <Button value={w.code} className={customClasses.innerButton} color={"default"}
                  variant={currentValue && currentValue.toString().toLowerCase() === w.code.toString().toLowerCase() ? "contained" : "outlined"} onClick={() => handleChangeValueBtn(w.code.toString())}>
                  {w.name} - {w.code} {configuration.showOdpQtyPreview ? w.availableOdps !== null ? `(${w.availableOdps})` : '(0)' : null}
                </Button>
              </div>
            ))
            ) : !loading ? (
              <div className={customClasses.formInputSection}>
                <Typography component="h1" variant="h6" color="inherit">
                  {customLabel("operatorView.noWorkcentresAvailable")}
                </Typography>
              </div>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          {renderExitButton()}
          <div style={{ flex: "1 0 0" }} />
          <Button className={customClasses.dialogButton} onClick={() => handleRemoveWorkCentre()} color="secondary" variant="contained" disabled={loading}>{customLabel("button.back")}</Button>
          <Button className={customClasses.dialogButton} onClick={() => handleAddWorkcentre()} variant="contained" color="primary"
            disabled={loading || !currentValue || currentValue === "" || !workcentres || (workcentres && workcentres.length === 0) || errorSettings || !listMachines}>
            {customLabel("button.moveOn")}
          </Button>
        </DialogActions>
      </div>
    );
  };
  const insertMachine = () => {
    return (
      <div>
        <DialogTitle>
          {renderStepTitle(customLabel("operatorView.insertMachine"))}
        </DialogTitle>
        <DialogContent>
          <div className={customClasses.formInputSection}>
            <Input value={currentValue ? currentValue : ""} onFocus={handleFocus} disabled={loading}
              inputRef={(input) => {
                if (input !== null) {
                  input.focus();
                }
              }}
              onKeyPress={(e) => handleClickEnter(e, handleAddMachine)}
              readOnly={configuration.enableInputOperator ? false : true}
              fullWidth name="machine" onChange={handleChangeValueText}></Input>
            {renderSelection(machinesAvailable, false, false, true)}
          </div>
          <div className={customClasses.listContainerList}>
            {machinesAvailable !== false && machinesAvailable.length > 0 ? (machinesAvailable.map((m) => (
              <div key={m.id}>
                <Button value={m.code} className={customClasses.innerButton} color={"default"}
                  variant={currentValue && currentValue.toString().toLowerCase() === m.code.toString().toLowerCase() ? "contained" : "outlined"} onClick={() => handleChangeValueBtn(m.code.toString())}
                  disabled={loading}>
                  {m.name} - {m.code} {configuration.showOdpQtyPreview ? m.availableOdps !== null ? `(${m.availableOdps})` : '(0)' : null}
                </Button>
              </div>
            ))
            ) : !loading ? (
              <div className={customClasses.formInputSection}>
                <Typography component="h1" variant="h6" color="inherit">
                  {customLabel("operatorView.noMachinesAvailable")}
                </Typography>
              </div>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          {renderExitButton()}
          <div style={{ flex: "1 0 0" }} />
          <Button className={customClasses.dialogButton}
            onClick={() => handleRemoveMachine()} color="secondary" variant="contained" disabled={loading}>{customLabel("button.back")}</Button>
          <Button className={customClasses.dialogButton} onClick={() => handleAddMachine()} variant="contained" color="primary"
            disabled={loading || !currentValue || currentValue === "" || !machinesAvailable || (machinesAvailable && machinesAvailable.length === 0) || errorSettings}>
            {customLabel("button.moveOn")}
          </Button>
        </DialogActions>
      </div>
    );
  };

  const handleAddUserEvent = () => {
    const indexOfUe = userEvents.findIndex((u) => {
        return u.id.toString().toLowerCase() === currentValue.toString().toLowerCase();
    });
    if(indexOfUe<0||currentValue===""){
      dispatch(noUserEvent())
    }else{
      let ue = userEvents[indexOfUe]
      dispatch(changeUserEvent({userEvent:ue,machineEvent:null}));
      dispatch(getMachineEventAPI(ue.machine,ue.process.maintenance===true,operator))
      if (ue.process.id !== null) {
          dispatch(integrateEventAPI(ue.odp, ue.process, ue.machine, ue.user, true, false));
      } else if (ue.stapling.id !== null) {
          dispatch(integrateOdpStaplingAPI(ue.stapling));
      }
    }
  }

  const stopUserEvent = (userEvent) => {
    dispatch(stopUserEventAPI(userEvent, false, true, true));
  };

  const insertUserEvent = () => {
    return (
      <div>
        <DialogTitle>
          {renderStepTitle(customLabel("operatorView.insertUserEvent"))}
        </DialogTitle>
        <DialogContent>
          {renderSelection(userEvents, false, false, false, true)}
          <div className={customClasses.listContainerList}>
            {userEvents !== false ? (userEvents.map((ue) => (
              <div key={ue.id}>
                <Button value={ue.id} className={customClasses.innerButton83} color={"default"}
                variant={currentValue && currentValue.toString().toLowerCase() === ue.id.toString().toLowerCase() ? "contained" : "outlined"} onClick={() => handleChangeValueBtn(ue.id.toString())}
                  disabled={loading}>
                  {ue.machine.name}: {ue.process.id !== null? ue.process.code: ue.stapling.code} ({ue.type.name})
                </Button>
                <Button className={customClasses.innerButton15} color={"secondary"} variant={"outlined"} onClick={()=> stopUserEvent(ue) }>{customLabel("button.close")}</Button>
              </div>
            ))
            ) : !loading ? (
              <div className={customClasses.formInputSection}>
                <Typography component="h1" variant="h6" color="inherit">
                  {customLabel("operatorView.noUserEventsAvailable")}
                </Typography>
              </div>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          {renderExitButton()}
          <div style={{ flex: "1 0 0" }} />
          <Button className={customClasses.dialogButton}
            onClick={() => handleRemoveUserEvent()} color="secondary" variant="contained" disabled={loading}>{customLabel("button.back")}</Button>
          <Button className={customClasses.dialogButton} onClick={() => handleAddUserEvent()} variant="contained" color="primary"
            disabled={loading || currentValue === false || errorSettings}>
            {customLabel("button.moveOn")}
          </Button>
        </DialogActions>
      </div>
    );
  };

  const insertOdp = () => {
    return (
      <div>
        <DialogTitle>
          {renderStepTitle(modeMaintenance ? customLabel("operatorView.insertMaintenance") : customLabel("operatorView.insertOdp"))}
        </DialogTitle>
        <DialogContent>
          {!modeMaintenance ? (
            <div className={customClasses.formInputSection}>
              <Input value={currentValue ? currentValue : ""} onFocus={handleFocus} disabled={!configuration.opViewEnableOdpSearch && loading}
                inputRef={(input) => {
                  if (input !== null) {
                    input.focus();
                  }
                }}
                onKeyPress={machine && !machine.stapling ? (e) => handleClickEnter(e, handleAddOdp) : null}
                readOnly={configuration.enableInputOperator ? false : true}
                fullWidth name="odp" onChange={handleChangeValueTextOdp}></Input>
              {renderSelection(odpsAvailable && rodpsAvailable && sodpsAvailable ? odpsAvailable.concat(rodpsAvailable, sodpsAvailable) : [], true)}
              {renderOdpDetails()}
            </div>
          ) : null}
          <div className={customClasses.listContainerList}>
            {odpsAvailable !== false && odpsAvailable.length > 0 ? (
              odpsAvailable.map((o) => (
                <div key={o.id} >
                  <Button value={o.code} className={customClasses.innerButton}
                    color={"default"}
                    variant={currentValue === o.code.toString() || (configuration.enableInsertExtCode && o.extCode !== null && o.extCode !== "" && o.extCode.toString() === currentValue) ? "contained" : "outlined"}
                    onClick={() => handleChangeValueBtn(o.code.toString())}
                    disabled={loading}>
                    {!modeMaintenance ? renderOdpLabelInput(o, configuration) :
                      o.maintenanceType.name + " - " + moment(o.deliveryDate).format(bishopFormats.L)}
                  </Button>
                </div>
              ))
            ) : null}
            {rodpsAvailable !== false && rodpsAvailable.length > 0 ? (
              <div className={customClasses.extraOdpsContainer}>
                <Typography component="h6" variant="h6" color="inherit">
                  {customLabel("operatorView.closedOdps")}
                </Typography>
              </div>
            ) : null}
            {rodpsAvailable !== false && rodpsAvailable.length > 0 ?
              (rodpsAvailable.map((o) => (
                <div key={o.id}>
                  <Button value={o.code} className={customClasses.innerButton} color={"default"}
                    variant={currentValue === o.code.toString() ? "contained" : "outlined"} onClick={() => handleChangeValueBtn(o.code.toString())}
                    disabled={loading}>
                    {renderOdpLabelInput(o, configuration)}
                  </Button>
                </div>))
              ) : null}
            {sodpsAvailable !== false && sodpsAvailable.length > 0 ? (
              <div className={customClasses.extraOdpsContainer}>
                <Typography component="h6" variant="h6" color="inherit">
                  {customLabel("operatorView.stalplingOdps")}
                </Typography>
              </div>
            ) : null}
            {sodpsAvailable !== false && sodpsAvailable.length > 0 ?
              (sodpsAvailable.map((o) => (
                <div key={o.id}>
                  <Button value={o.code} className={customClasses.innerButton} color={"default"}
                    variant={currentValue === o.code.toString() ? "contained" : "outlined"} onClick={() => handleChangeValueBtn(o.code.toString())}
                    disabled={loading}>
                    {o.code}
                  </Button>
                </div>))
              ) : null}
            {(!odpsAvailable || odpsAvailable.length === 0) && (!rodpsAvailable || rodpsAvailable.length === 0) && (!sodpsAvailable || sodpsAvailable.length === 0) && !loading ? (
              <div className={customClasses.formInputSection}>
                <Typography component="h1" variant="h6" color="inherit">
                  {!modeMaintenance ? customLabel("operatorView.noOdpsAvailable") : customLabel("operatorView.noMaintenancesAvailable")}
                </Typography>
              </div>
            ) : (
              null
            )}
          </div>
        </DialogContent >
        <DialogActions style={{ padding: "16px 24px" }}>
          {renderExitButton()}
          <div style={{ flex: "1 0 0" }} />
          <Button className={customClasses.dialogButton} variant="contained"
            onClick={() => handleRemoveOdp()}
            color="secondary" disabled={loading}>{customLabel("button.back")}
          </Button>
          <Button className={customClasses.dialogButton} variant="contained" onClick={() => handleAddOdp(false)} color="primary"
            disabled={loading || !currentValue || currentValue === "" || (odpsAvailable && rodpsAvailable && sodpsAvailable && odpsAvailable.length === 0 && rodpsAvailable.length === 0 && sodpsAvailable.length === 0)}>
            {customLabel("button.moveOn")}
          </Button>
          {machine && machine.stapling && !modeMaintenance ? (
            <Button className={customClasses.dialogButton} variant="contained" onClick={() => handleSetStapling()} color="primary"
              disabled={loading || !currentValue || currentValue === "" || (odpsAvailable && rodpsAvailable && sodpsAvailable && odpsAvailable.length === 0 && rodpsAvailable.length === 0 && sodpsAvailable.length === 0)
                || (currentOdp && currentOdp.stapling)}>
              {customLabel("button.stapling")}
            </Button>
          ) : null}
        </DialogActions>
      </div >
    );
  };
  const insertProcess = () => {
    if (!modeStapling) {
      return (
        <div>
          <DialogTitle>
            {renderStepTitle(modeMaintenance ? customLabel("operatorView.insertPhase") : customLabel("operatorView.insertProcess"))}
          </DialogTitle>
          <DialogContent>
            {!modeMaintenance ? (
              <div className={customClasses.formInputSection}>
                <Input value={currentValue ? currentValue : ""} onFocus={handleFocus} disabled={loading}
                  inputRef={(input) => {
                    if (input !== null) {
                      input.focus();
                    }
                  }}
                  onKeyPress={(e) => handleClickEnter(e, handleAddProcess)}
                  readOnly={configuration.enableInputOperator ? false : true}
                  fullWidth name="process" onChange={handleChangeValueText}></Input>
                {renderSelection(newProcesses && oldProcesses ? newProcesses.concat(oldProcesses) : [], false, true)}
              </div>
            ) : null}
            <div className={customClasses.listContainerList}>
              {newProcesses !== false && newProcesses.length > 0 ?
                (newProcesses.map((p) => (
                  <div key={p.id}>
                    <Button value={p.id} className={customClasses.innerButton} color={"default"}
                      variant={currentValue === p.id.toString() ? "contained" : "outlined"} onClick={() => handleChangeValueBtn(p.id.toString())}
                      disabled={loading}>
                      {p && p.type && p.type.code ? p.type.code : null} - {p.name} - {p.quantity} {p.article.measure}
                      {p.machine.id ? " (" + p.machine.code + ")" : p.lastMachineEvent.id !== null ? " (" + renderMachineLabel(configuration, p.lastMachineEvent.machine) + ")" : null}
                      {p.lastUserEvent.id !== null ? " (" + renderOperatorLabel(configuration, p.lastUserEvent.user) + ")" : null}
                    </Button>
                  </div>))
                ) : null}
              {oldProcesses !== false && oldProcesses.length > 0 ? (
                <div className={customClasses.extraOdpsContainer}>
                  <Typography component="h6" variant="h6" color="inherit">
                    {customLabel("operatorView.rework")}
                  </Typography>
                </div>
              ) : null}
              {oldProcesses !== false && oldProcesses.length > 0 ?
                (oldProcesses.map((p) => (
                  <div key={p.id}>
                    <Button value={p.id} className={customClasses.innerButton} color={"default"}
                      variant={currentValue === p.id.toString() ? "contained" : "outlined"} onClick={() => handleChangeValueBtn(p.id.toString())}
                      disabled={loading}>
                      {p && p.type && p.type.code ? p.type.code : null} - {p.name} - {p.quantity} {p.article.measure} {p.machine.id ? "(" + p.machine.code + ")" : null}
                    </Button>
                  </div>))
                ) : null}
              {(!newProcesses || newProcesses.length === 0) && (!oldProcesses || oldProcesses.length === 0) && !loading ? (
                <div className={customClasses.formInputSection}>
                  <Typography component="h1" variant="h6" color="inherit">
                    {!modeMaintenance ? customLabel("operatorView.noProcessesAvailable") : customLabel("operatorView.noPhasesAvailable")}
                  </Typography>
                </div>
              ) : (
                null
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "16px 24px" }}>
            {renderExitButton()}
            <div style={{ flex: "1 0 0" }} />
            <Button className={customClasses.dialogButton} variant="contained" onClick={() => handleRemoveProcess()} disabled={loading} color="secondary">{customLabel("button.back")}</Button>
            <Button className={customClasses.dialogButton} variant="contained" onClick={() => handleAddProcess()}
              disabled={loading || !currentValue || currentValue === "" || (!oldProcesses && !newProcesses) || (oldProcesses && newProcesses && oldProcesses.length === 0 && newProcesses.length === 0) || (!oldProcesses && newProcesses && newProcesses.length === 0) || (!newProcesses && oldProcesses && oldProcesses.length === 0)} color="primary">
              {customLabel("button.moveOn")}
            </Button>
          </DialogActions>
        </div>
      );
    } else {
      return (
        <div>
          <DialogTitle>
            {renderStepTitle(customLabel("operatorView.insertProcessForStapling"))}
          </DialogTitle>
          <DialogContent>
            <div className={customClasses.formInputSection}>
              <Input value={currentValue ? currentValue : ""} onFocus={handleFocus} disabled={loading}
                inputRef={(input) => {
                  if (input !== null) {
                    input.focus();
                  }
                }}
                readOnly={configuration.enableInputOperator ? false : true}
                fullWidth name="process" onChange={handleChangeValueText}></Input>
              {renderSelection(newProcesses, false, true)}
            </div>
            <div className={customClasses.listContainerList}>
              {newProcesses !== false && newProcesses.length > 0 ?
                (newProcesses.map((p) => (
                  <div key={p.id}>
                    <Button value={p.id} className={customClasses.innerButton} color={"default"}
                      variant={currentValue === p.id.toString() ? "contained" : "outlined"} onClick={() => handleChangeValueBtn(p.id.toString())}
                      disabled={loading}>
                      {p && p.type && p.type.code ? p.type.code : null} - {p.name} - {p.quantity} {p.article.measure}
                    </Button>
                  </div>))
                ) : null}
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "16px 24px" }}>
            {renderExitButton()}
            <div style={{ flex: "1 0 0" }} />
            <Button className={customClasses.dialogButton} variant="contained" onClick={() => handleRemoveProcess()} disabled={loading} color="secondary">
              {customLabel("button.back")}
            </Button>
            <Button className={customClasses.dialogButton} variant="contained" onClick={() => handleAddStaplingProcess()}
              disabled={loading || !currentValue || currentValue === "" || !newProcesses || (newProcesses && newProcesses.length === 0)} color="primary">
              {customLabel("button.moveOn")}
            </Button>
          </DialogActions>
        </div >
      )
    }
  };
  const insertStapling = () => {
    return (
      <div>
        <DialogTitle>
          {renderStepTitle(customLabel("operatorView.addProcessesToStapling"))}
        </DialogTitle>
        <DialogContent>
          <FormStaplingProcesses configuration={configuration} />
        </DialogContent >
        <DialogActions style={{ padding: "16px 24px" }}>
          {renderExitButton()}
          <div style={{ flex: "1 0 0" }} />
          <Button className={customClasses.dialogButton} variant="contained" onClick={() => handleRemoveStaplingList()} disabled={loading} color="secondary">
            {customLabel("button.back")}
          </Button>
          <Button className={customClasses.dialogButton} variant="contained" onClick={() => handleAddStaplingList()}
            disabled={loading || !staplingList || (staplingList && staplingList.length === 0)} color="primary">
            {customLabel("button.moveOn")}
          </Button>
        </DialogActions>
      </div>
    )
  };
  //Usa la variabile step per andare avanti con l'inserimento
  const renderStep = () => {
    switch (index) {
      case INDEX_OPERATOR: return insertOperator();
      case INDEX_WORKCENTRE: return insertWorkcentre();
      case INDEX_MACHINE: return insertMachine();
      case INDEX_USER_EVENT: return insertUserEvent();
      case INDEX_ODP: return insertOdp();
      case INDEX_PROCESS: return insertProcess();
      case INDEX_STAPLING: return insertStapling();
      default: return null;
    }
  };

  const renderStepTitle = (stepTitle) => {
    return (
      <div className={customClasses.titleInputSection}>
        <div>
          {stepTitle}
        </div>
        {configuration && configuration.enableMaintenance ? (
          <div className={modeMaintenance ? customClasses.modeMaintenanceColor : customClasses.modeProductionColor}>
            {modeMaintenance ? customLabel("operatorView.maintenanceFlag") : customLabel("operatorView.productionFlag")}
          </div>
        ) : null}
      </div>
    )
  }

  const renderStepTitleUser = () => {
    return (
      <div className={customClasses.titleInputSection}>
        <div>
          {configuration && configuration !== null && !configuration.showUsers ? customLabel("operatorView.insertOperatorCode") : customLabel("operatorView.insertOperator")}
        </div>
        <div>
          <IconButton onClick={() => toggleFullScreen(document.body)}>
            <FullscreenIcon style={{ color: "black" }} fontSize="large" />
          </IconButton>
        </div>
      </div>
    )
  }

  const renderExitButton = () => {
    return (
      <React.Fragment>
        <Button className={customClasses.dialogButton} color="default" variant="contained" onClick={() => handleChangeResetOperator(true)} disabled={loading}>
          {customLabel("button.exit")}
        </Button>
      </React.Fragment>
    )
  }

  /*const renderResetButton = () => {
    return (
      <React.Fragment>
        <Button className={customClasses.dialogButton} color="secondary" variant="contained" onClick={() => handleChangeResetOperator(false)} disabled={loading}>
          {customLabel("button.reset")}
        </Button>
      </React.Fragment>
    )
  }*/

  return (
    <Dialog fullWidth maxWidth="md" open={index < INDEX_INTERFACE}>
      {renderStep()}
    </Dialog>
  );
}

export default InputOperator;