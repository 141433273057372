import React from "react";
import { Link } from "react-router-dom";
import { Typography, FormControl, InputLabel, Input, Divider, Button, FormControlLabel, Checkbox, TextField } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockIcon from '@material-ui/icons/Lock';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";

export const Details = ({ button, edit, toggleDelete, deleting, doDelete, loading }) => {
    const myClasses = mesStyles();

    return (
        <PaperForm>
            <div className={myClasses.titleFormSpaceBetween}>
                <Typography component="h1" variant="h6" color="inherit">
                    {customLabel("buttons.button")}
                </Typography>
                <LockIcon />
            </div>
            <form className={myClasses.formContainer} noValidate autoComplete="off">
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="name">{customLabel("buttons.name")}</InputLabel>
                    <Input id="name" value={button.name} />
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="type">{customLabel("buttons.type.type")}</InputLabel>
                    <Input id="type" value={customLabel(button.type === "rest" ? "buttons.type.rest" : "buttons.type.newWindow")} />
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="api">{customLabel("buttons.api")}</InputLabel>
                    <Input id="api" value={button.api} />
                </FormControl>
                <Divider component="br" />
                <FormControl disabled margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="sendProcess" checked={button.sendProcess} />}
                        label={customLabel("configButton.sendProcess")} />
                </FormControl>
                <FormControl disabled margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="sendUser" checked={button.sendUser} />}
                        label={customLabel("configButton.sendUser")} />
                </FormControl>
                <FormControl disabled margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="sendOdp" checked={button.sendOdp} />}
                        label={customLabel("configButton.sendOdp")} />
                </FormControl>
                <FormControl disabled margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="sendCustomerOrder" checked={button.sendCustomerOrder} />}
                        label={customLabel("configButton.sendCustomerOrder")} />
                </FormControl>
                <FormControl disabled margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="sendMachineEvent" checked={button.sendMachineEvent} />}
                        label={customLabel("configButton.sendMachineEvent")} />
                </FormControl>
                <FormControl disabled margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="sendMachine" checked={button.sendMachine} />}
                        label={customLabel("configButton.sendMachine")} />
                </FormControl>
                <FormControl disabled margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="sendUserEvent" checked={button.sendUserEvent} />}
                        label={customLabel("configButton.sendUserEvent")} />
                </FormControl>
                <br />
                <FormControl disabled margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="showInOdp" checked={button.showInOdp} />}
                        label={customLabel("configButton.showInOdp")} />
                </FormControl>
                <FormControl disabled margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="showInStapling" checked={button.showInStapling} />}
                        label={customLabel("configButton.showInStapling")} />
                </FormControl>
                <FormControl disabled margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="showInMaintenance" checked={button.showInMaintenance} />}
                        label={customLabel("configButton.showInMaintenance")} />
                </FormControl>
                <br />
                <FormControl disabled margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="askConfirmation" checked={button.askConfirmation} />}
                        label={customLabel("configButton.askConfirmation")} />
                </FormControl>
                <FormControl disabled margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="askValue" checked={button.askValue} />}
                        label={customLabel("configButton.askValue")} />
                </FormControl>
                <FormControl disabled margin="normal">
                    <InputLabel htmlFor="name">{customLabel("configButton.nameValue")}</InputLabel>
                    <Input name="nameValue" value={button.nameValue} error={button.askValue && button.nameValue === ""} />
                </FormControl>
                <Divider component="br" />
                <FormControl disabled className={myClasses.form100}>
                    <TextField
                        disabled variant="outlined" name="note" multiline label={customLabel("function.note")} rows={4}
                        value={button.note !== null ? button.note : ""}
                    />
                </FormControl>
            </form>
            <Divider />
            <div className={myClasses.buttonsFormUpdate}>
                <Button focusRipple key={button.id} onClick={edit} color="primary" disabled={loading}>
                    {customLabel("button.edit")}
                </Button>
                <Button color="secondary" onClick={toggleDelete} disabled={loading}>{customLabel("button.delete")}</Button>
                <Button component={Link} to={`/admin/buttons`} disabled={loading}>{customLabel("button.back")}</Button>
            </div>
            <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
                confirm={doDelete} cancel={toggleDelete} disabled={loading} />
        </PaperForm >
    );
};