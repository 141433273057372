import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress, Typography, Box } from '@material-ui/core';
import { configurationSelector } from '../configuration/slice';

const useStyles = makeStyles((theme) => ({
    pieces: {
        marginTop: theme.spacing(2),
        textAlign: "center"
    },
    progress: {
        height: 10,
        borderRadius: 2
    }
}));


const ProgressBar = ({ total, current, measure }) => {
    const classes = useStyles();
    const { configuration } = useSelector(configurationSelector)

    return (
        <React.Fragment>
            <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                    <LinearProgress className={classes.progress} variant="determinate" value={total > 0 ? (current / total * 100) < 100 ? (current / total * 100) : 100 : 0} />
                </Box>
                <Box minWidth={35} style={{ textAlign: "right" }}>
                    <Typography variant="body2">{total > 0 ? (current / total * 100).toFixed() : 0}%</Typography>
                </Box>
            </Box>
            <div className={classes.pieces}>
                <Typography variant="body2">
                    <b>{current ? current.toFixed(configuration.decimals) : 0} / {total ? total.toFixed(configuration.decimals) : 0} {measure}</b>
                </Typography>
            </div>
        </React.Fragment>
    )
}

export default ProgressBar
