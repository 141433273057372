import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { GreyRow } from "../../../utility/TableList";
import { TableContainer, Table, TableCell, TableRow, TableBody, CircularProgress } from '@material-ui/core';
import { loadCustomOdpsAPI, selector, initCustomFilter } from "../slice";
import { currentList } from "../utility";
import moment from "moment";
import { bishopFormats } from '../../../../constants';
import { customLabel } from '../../../utility/customLabel';
import { mesStyles } from '../../../utility/ultrafabStyles';
import classNames from "classnames";
import PopoverLegend from '../../../utility/PopoverLegend';
import { machineEventsSelector, getListAPI as getMachineEventsAPI } from "../../../machineEvents/slice";
import { customersSelector, getListAPI as getCustomersAPI } from "../../../customers/slice";
import { articlesSelector, getListAPI as getArticles } from "../../../articles/slice";
import ButtonsFragment from './tableUtility/ButtonsFragment';
import FilterRow from "./tableUtility/FilterRow";
import PaginationFragment from './tableUtility/PaginationFragment';
import { machinesSelector, getFullListAPI as getMachinesAPI } from "../../../machines/slice";
import { workcentresSelector, getListAPI as getWorkcentresAPI } from "../../../workcentres/slice";
import { useAnchor } from '../../../customHooks/useAnchor';

const Started = ({ handleClickRedirect, onChangeFilter, onChangeFilterAutocomplete, handleChangeDate, handleResetDate,
    handleChangePage, handleChangeRowsPerPage, configuration, enableReload }) => {

    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { odpsStarted, loadingStarted, errorsStarted, startedFilter, customReload, totalStarted } = useSelector(selector);
    const { machineEvents } = useSelector(machineEventsSelector);
    const { customers } = useSelector(customersSelector);
    const { articles } = useSelector(articlesSelector);
    const { machines } = useSelector(machinesSelector);
    const { workcentres } = useSelector(workcentresSelector);
    const [anchorPopover, { handleOpen: handleOpenPopover, handleClose: handleClosePopover }, openPopover] = useAnchor();

    useEffect(() => {
        dispatch(getMachineEventsAPI(0, 10000, "name", "asc", false));
        dispatch(getCustomersAPI(0, 100000, "code", "asc", false, false));
        dispatch(getArticles(0, 100000, "code", "asc", false, false, false, false, false));
        dispatch(getMachinesAPI());
        dispatch(getWorkcentresAPI(0, 10000, "code", "asc", false, false));
    }, [dispatch]);

    //inizializzo filtro
    useEffect(() => {
        if (!startedFilter && configuration) {
            dispatch(initCustomFilter({ filter: currentList.started.filter, showFilter: configuration.showFilters }))
        }
    }, [configuration, startedFilter, dispatch])

    //reload dopo cambio filtro
    useEffect(() => {
        if (startedFilter && customReload && !errorsStarted && !loadingStarted) {
            dispatch(loadCustomOdpsAPI(currentList.started, startedFilter));
        }
    }, [dispatch, customReload, errorsStarted, startedFilter, loadingStarted])

    /**
    * Restituisce il nome della prossima lavorazione
    * @param {integer} o process
    * @returns ritorna il nome della prossima lavorazione
    */
    const renderNextProcess = (o) => {
        if (o.next.id === null) return null;
        return o.next.name;
    }

    const toggleFilter = () => {
        if (!startedFilter.showFilter) {
            dispatch(initCustomFilter({ filter: currentList.started.filter, showFilter: true }))
        } else {
            dispatch(initCustomFilter({ filter: currentList.started.filter, showFilter: false }))
        }
    }

    const emptyFilter = () => {
        dispatch(initCustomFilter({ filter: currentList.started.filter, showFilter: true }))
    };

    return (
        <React.Fragment>
            <ButtonsFragment filter={startedFilter} emptyFilter={emptyFilter} toggleFilter={toggleFilter} />
            <TableContainer style={{ maxHeight: "90%" }}>
                <Table stickyHeader
                    aria-labelledby="tableTitle"
                    size={'small'}
                    aria-label="enhanced table"
                >
                    <TableBody>
                        {/*8 colonne*/}
                        {startedFilter && odpsStarted && articles && customers && machineEvents && machines && workcentres && !loadingStarted ? (
                            <FilterRow customFilter={startedFilter} customers={customers} articles={articles} suppliers={false} onChangeFilter={onChangeFilter}
                                onChangeFilterAutocomplete={onChangeFilterAutocomplete} handleChangeDate={handleChangeDate} handleResetDate={handleResetDate}
                                colSpan={[2, 2, 1, 1, 1, 1, 0, 0, 0]} typeStatus={currentList.started.url} showDeliveryFilter={true} showStartDateFilter={true}
                                showEndDateFilter={false} showSuppliersFilter={false} showMachinesFilter={true} showWorkcentresFilter={true} machines={machines}
                                workcentres={workcentres} enableReload={enableReload} />
                        ) : null}
                        {odpsStarted && odpsStarted.length > 0 && articles && customers && machineEvents && machines && workcentres && !loadingStarted ? (
                            odpsStarted.map((o) => {
                                return (
                                    <React.Fragment key={o.id}>
                                        <GreyRow onClick={() => handleClickRedirect(o.odp.id)} className={myClasses.cursorPointer}>
                                            <TableCell className={myClasses.tabelCellBotMargin}>
                                                <b>{customLabel("workcentre.workcentre")}:</b>
                                            </TableCell>
                                            <TableCell className={myClasses.tabelCellBotMargin}>
                                                {o.stapling.id === null ? o.machineEvent?.machine?.workcentre : o.staplingMachineEvent?.machine?.workcentre}
                                            </TableCell>
                                            <TableCell className={myClasses.tabelCellBotMargin}>
                                                <b>{customLabel("machine.machine")}:</b>
                                            </TableCell>
                                            <TableCell className={myClasses.tabelCellBotMargin}>
                                                {o.stapling.id === null ? o.machineEvent?.machine?.code : o.staplingMachineEvent?.machine?.code}
                                                {o.stapling.id !== null ? (
                                                    <React.Fragment>
                                                        {o.machineEvent?.machine?.code || o.staplingMachineEvent?.machine?.code ? <br /> : null}
                                                        {"(" + customLabel("stapling.stapling") + ")"}
                                                    </React.Fragment>
                                                ) : null}
                                            </TableCell>
                                            <TableCell className={myClasses.tabelCellBotMargin}>
                                                <b>{customLabel("odp.currentProcess")}:</b>
                                            </TableCell>
                                            <TableCell className={myClasses.tabelCellBotMargin}>
                                                {o.name}
                                            </TableCell>
                                            <TableCell className={myClasses.tabelCellBotMargin}>
                                                <b>{customLabel("machineEvent.machineEvent")}:</b>
                                            </TableCell>
                                            <TableCell
                                                className={o.machineEvent.id !== null || o.staplingMachineEvent.id !== null ? classNames(myClasses.tabelCellBotMargin, myClasses.tableCellColorBorder, myClasses.dashedUnderline) :
                                                    classNames(myClasses.tabelCellBotMargin)}
                                                style={o.machineEvent.id !== null ? { borderLeftColor: o.machineEvent.backgroundColor } : o.staplingMachineEvent.id !== null ? { borderLeftColor: o.staplingMachineEvent.backgroundColor } : null}
                                                onMouseEnter={o.machineEvent.id !== null || o.staplingMachineEvent.id !== null ? (e) => handleOpenPopover(e.currentTarget, false) : null}
                                                onMouseLeave={o.machineEvent.id !== null || o.staplingMachineEvent.id !== null ? handleClosePopover : null}>
                                                {o.stapling.id === null && o.machineEvent && o.machineEvent.id !== null ? o.machineEvent.name : o.stapling.id !== null &&
                                                    o.staplingMachineEvent && o.staplingMachineEvent.id !== null ? o.staplingMachineEvent.name : null}
                                            </TableCell>
                                        </GreyRow>
                                        <TableRow onClick={() => handleClickRedirect(o.odp.id)} className={myClasses.cursorPointer}>
                                            <TableCell><b>{customLabel("odp.odp")}:</b></TableCell>
                                            <TableCell>{o.code}</TableCell>
                                            <TableCell><b>{customLabel("customer.customer")}:</b></TableCell>
                                            <TableCell>{o.odp.customer?.code} {o.customer?.name}</TableCell>
                                            <TableCell><b>{customLabel("odp.deliveryDate")}:</b></TableCell>
                                            <TableCell>{o.deliveryDate ? moment(o.deliveryDate).format(configuration && configuration.showOdpDeliveryTime ? bishopFormats.LT : bishopFormats.L) : null}</TableCell>
                                            <TableCell><b>{customLabel("odpStatus.odpStatus")}:</b></TableCell>
                                            <TableCell>{o.status?.name}</TableCell>
                                        </TableRow>
                                        <TableRow onClick={() => handleClickRedirect(o.odp.id)} className={myClasses.cursorPointer}>
                                            <TableCell><b>{customLabel("customerOrder.customerOrder")}:</b></TableCell>
                                            <TableCell>{o.odp.customerOrder?.code}</TableCell>
                                            <TableCell><b>{customLabel("article.article")}:</b></TableCell>
                                            <TableCell>{o.article?.code} {o.article?.name}</TableCell>
                                            <TableCell><b>{customLabel("odp.startDate")}:</b></TableCell>
                                            <TableCell>{o.startDate ? moment(o.startDate).format(bishopFormats.LT) : null}</TableCell>
                                            <TableCell><b>{customLabel("odp.nextProcess")}:</b></TableCell>
                                            <TableCell>{renderNextProcess(o)}</TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )
                            })

                        ) : odpsStarted && odpsStarted.length === 0 && !loadingStarted ?
                            (
                                <TableRow>
                                    <TableCell colSpan={8}>{customLabel("odp.noOdpsAvailable").toUpperCase()}</TableCell>
                                </TableRow>
                            )
                            : loadingStarted || !customers || !articles || !machineEvents || !machines || !workcentres ?
                                (<TableRow>
                                    <TableCell align="center" colSpan={8}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>)
                                :
                                null}
                    </TableBody>
                </Table>

            </TableContainer>
            {startedFilter && totalStarted !== false && !loadingStarted ? (
                <PaginationFragment total={totalStarted} customFilter={startedFilter} handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} typeStatus={currentList.started.url} disabledChange={loadingStarted} />
            ) : null
            }
            {anchorPopover ? (
                <PopoverLegend name={customLabel("legend.legend").toUpperCase()} list={machineEvents} open={openPopover} anchorEl={anchorPopover}
                    openPopover={handleOpenPopover} closePopover={handleClosePopover}
                    anchorVertical={"center"} anchorHorizontal={"left"} transformVertical={"center"} transormHorizontal={"right"} />
            ) : null}
        </React.Fragment >
    )
}

export default Started
