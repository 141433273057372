import React from 'react'
import { customLabel } from '../../utility/customLabel';
import { Divider, Table, TableCell, TableRow, TableBody, TableHead } from "@material-ui/core";
import DocumentRow from './DocumentRow';
import DocumentDialog from '../../documents/DocumentDialog';

/**
 * 
 * @param {string} title il titolo del parent dei documenti 
 * @param {array} documents i documenti da mostrare
 * @returns restituisce una tabella con l elenco dei documenti da mostrare e i rispettivi DocumentDialog.js
 */
const DocumentsTable = ({ title, documents }) => {

    //tabella con elenco documenti passati da parametro
    const renderCurrentDocs = () => {
        if (documents && documents.length > 0) {
            return (
                documents.map((d) => {
                    return (
                        <DocumentRow key={d.parentId + "_" + d.parentType} document={d} parentId={d.parentId} parentType={d.parentType} />
                    )
                })
            )
        } else {
            return (
                <TableRow>
                    <TableCell colSpan={3}>{customLabel("function.notAvailable").toUpperCase()}</TableCell>
                </TableRow>
            )
        }
    };

    const renderCurrentDialogs = () => {
        if (documents && documents.length > 0) {
            return (
                documents.map((d) => {
                    return (
                        <DocumentDialog key={d.parentId + "_" + d.parentType} parentId={d.parentId} parentType={d.parentType} />
                    )
                })
            )
        } else {
            return null;
        }
    }

    return (
        <React.Fragment>
            <h3 style={{ textAlign: "center" }}>{title}</h3>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{customLabel("document.name")}</TableCell>
                        <TableCell align="center" style={{ width: "180px" }}>{customLabel("document.format")}</TableCell>
                        <TableCell align="center" style={{ width: "180px" }}>{customLabel("document.action")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderCurrentDocs()}
                </TableBody>
            </Table>
            {renderCurrentDialogs()}
        </React.Fragment>
    )
}
export default DocumentsTable;
