import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setDownload, setCurrentDocument } from "../../documents/slice";
import { customLabel } from '../../utility/customLabel';
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { mainSelector } from '../../main/slice';

/**
 * 
 * @param {array} document il documento da mostrare
 * @param {object} parentId id dell'oggetto parent
 * @param {string} parentType la tipologia del parent
 * @returns restituisce un div con titolo parentType e una tabella con l'elenco dei documenti
 */
const DocumentRow = ({ document, parentId, parentType }) => {
    const dispatch = useDispatch();
    const { serverNotResponding } = useSelector(mainSelector);

    //seleziona il documento per visualizzarlo, abilitati per formati solo pdf e image
    const handleViewDocument = (doc, id, type) => {
        dispatch(setCurrentDocument({ doc: doc, parentId: id, parentType: type }))
    }
    //seleziona il documento per il download, abilitato per tutti i formati
    const handleGetDocument = (doc, id, type) => {
        dispatch(setDownload({ doc: doc, parentId: id, parentType: type }));
    }

    //tabella con elenco documenti passati da parametro
    const renderDocument = (d, id, type) => {
        return (
            <TableRow key={d.id + "_" + d.name + "_" + d.format}>
                <TableCell>
                    {d.name}
                </TableCell>
                <TableCell align="center">{d.format === "image" ? customLabel("document.image").toUpperCase() :
                    d.format === "pdf" ? customLabel("document.pdf").toUpperCase() :
                        customLabel("document.other").toUpperCase()}
                </TableCell>
                <TableCell align="center">
                    <IconButton color="primary" variant="contained" onClick={() => handleViewDocument(d, id, type)}
                        disabled={(d.format === "image" || d.format === "pdf") && !serverNotResponding ? false : true}>
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton color="primary" variant="contained" onClick={() => handleGetDocument(d, id, type)}
                        disabled={serverNotResponding} >
                        <GetAppIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    };

    return (
        <React.Fragment>
            {renderDocument(document, parentId, parentType)}
        </React.Fragment>
    )
}
export default DocumentRow;
