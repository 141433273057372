import React from 'react';
import {
    Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TableContainer,
} from '@material-ui/core';
import { customLabel } from "../utility/customLabel";
import { renderVarsDetails } from "./utility";

/**
 * Restituisce una tabella per la visualizzazione paginata delle variabili con le colonne: timestamp, operatore(facoltativo) e valore
 * @param {array} varList 
 * @param {object} varToSee 
 * @param {int} page 
 * @param {int} rowsPerPage 
 * @param {function} setPage 
 * @param {function} setRowsPerPage 
 * @param {int} results
 * @param {boolean} showOperator 
 * @param {int} decimals
 * @param {params} loading
 * @returns Tabella Material UI
 */
const TableVarsDetails = ({ varList, varToSee, page, rowsPerPage, setPage, setRowsPerPage, results, showOperator, decimals, loading }) => {
    return (
        <React.Fragment>
            <TableContainer style={{ maxHeight: "400px" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell size="small">{customLabel("function.time")}</TableCell>
                            {showOperator ? (
                                <TableCell size="small">{customLabel("function.operator").toUpperCase()}</TableCell>
                            ) : null}
                            <TableCell size="small">{customLabel("function.value")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderVarsDetails(varList, varToSee, showOperator, decimals)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                SelectProps={{
                    disabled: loading
                }}
                backIconButtonProps={
                    loading
                        ? {
                            disabled: loading
                        }
                        : undefined
                }
                nextIconButtonProps={
                    loading
                        ? {
                            disabled: loading
                        }
                        : undefined
                }
                labelRowsPerPage={customLabel("function.rowsPerPage")} rowsPerPageOptions={[5, 10, 20, 50, 100, 500]} component="div" count={results}
                rowsPerPage={rowsPerPage} page={page} onChangePage={(event, page) => setPage(event, page, true)} onChangeRowsPerPage={setRowsPerPage}
                disabled={true} />
        </React.Fragment>
    )
}

export default TableVarsDetails;
