import { Button, Typography } from "@material-ui/core";
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from '../../utility/customLabel';
import {
    changeMachineEventReason,
    enableFlagMachineEventReason,
    justifyMachineEventAPI,
    operatorViewSelector, resetMachineEventReason
} from "../slice";
import DialogOperator from "../utility/DialogOperator";
import { opViewStyles } from "../utility/customStyles";

const MachineEventReasonDialog = ({configuration}) => {
    const customClasses = opViewStyles();
    const dispatch = useDispatch();
    const { machine, operator, odp, loading, machineEvent, machineEventReason, machineEventTypeReasons,
        flagDialog, process, modeStapling, errorsMachineEventTypes } = useSelector(operatorViewSelector);
    const [seconds, setSeconds] = useState(null);

    useEffect(() => {
        if (seconds === 0) {
            dispatch(resetMachineEventReason())
            setSeconds(null)
        } else if (seconds !== null && flagDialog.flagMachineEventReason && machineEventTypeReasons.length === 0) {
            const timer = setTimeout(() => {
                setSeconds(prevSeconds => prevSeconds - 1)
            }, 1000)

            return () => clearTimeout(timer)
        }
    }, [dispatch, seconds, configuration.autoPopupJustify, flagDialog.flagMachineEventReason, machineEventTypeReasons])

    useEffect(() => {
        if (!flagDialog.flagMachineEventReason && machineEventTypeReasons.length > 0) {
            setSeconds(5)
            if (configuration.autoPopupJustify){
                dispatch(enableFlagMachineEventReason())
            }
        }
    }, [dispatch, configuration.autoPopupJustify, flagDialog.flagMachineEventReason, machineEventTypeReasons])

    //API che crea Evento macchina
    const addMachineEventType = () => {
        if (!modeStapling) {
            dispatch(justifyMachineEventAPI(process, false, operator, machineEventReason, machine, machineEvent));
        } else {
            dispatch(justifyMachineEventAPI(false, odp, operator, machineEventReason, machine, machineEvent));
        }
    }

    const renderDialogActions = () => {
        return (
            <React.Fragment>
                <Button className={customClasses.dialogButton} onClick={() => addMachineEventType()} variant="contained" disabled={machineEventReason === false || loading} color="primary">{customLabel("button.confirm")}</Button>
                <Button className={customClasses.dialogButton} onClick={() => dispatch(resetMachineEventReason())} variant="contained" color="secondary" disabled={loading}>{customLabel("button.close")}</Button>
            </React.Fragment>
        )
    }

    //restituisce gli eventi macchina disonibili, non quelli di sistema
    const renderCountMachineEventTypes = () => {
        let count = 0;
        if (machineEventTypeReasons) {
            for (let i = 0; i < machineEventTypeReasons.length; i++) {
                if (!machineEventTypeReasons[i].systemEvent) {
                    count++;
                }
            }
        }
        return count
    };

    //pulsanti che contengono i machineEventReason, sul click valorizza lo stato machineEventReason
    const handleChangeMachineEventType = (id) => {
        dispatch(changeMachineEventReason({ value: id, events: machineEventTypeReasons }));
    };

    const renderDialogForm = () => {
        if (errorsMachineEventTypes) {
            return (
                <React.Fragment>
                    {customLabel("function.loadingError").toUpperCase()}
                </React.Fragment>
            )
        }
        if (renderCountMachineEventTypes() > 0) {
            return (
                machineEventTypeReasons.map((m) => {
                    return (
                        <div key={m.id}>
                            <Button value={m.reason.name} className={customClasses.innerButton} color={machineEventReason.id === m.reason.id ? "primary" : "default"}
                                variant={machineEventReason.id === m.reason.id ? "contained" : "outlined"} onClick={() => handleChangeMachineEventType(m.reason.id)}
                                disabled={loading} style={{ backgroundColor: configuration.justifyDialogColor && (machineEventReason.id !== m.reason.id) ? 'white' : null }}>
                                {m.reason.name}
                            </Button>
                        </div>
                    )
                })
            )
        } else {
            return (
                <React.Fragment>
                    <Typography component="h1" variant="h5" className={customClasses.alarmColor}>
                        {seconds !== null && `Evento macchina cambiato in: ${machineEvent.type.name}, il popup si chiuderà automaticamente in ${seconds}`}
                    </Typography>
                </React.Fragment>
            )
        }
    }
    if(machineEvent){
        return (
            <DialogOperator flag={flagDialog.flagMachineEventReason} 
                title={customLabel("operatorView.justifyMachineEvent") + " " + machineEvent.type.name}
                form={() => renderDialogForm()} actions={() => renderDialogActions()} transparentBackround={false} justifyColor={configuration.justifyDialogColor} />
        );
    }else{
        return null;
    }
}

export default MachineEventReasonDialog;