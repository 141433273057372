import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../../constants";
import { logout, refreshAPI } from "../../main/slice";
import { showError } from "../../notifications/slice";
import { customLabel } from "../../utility/customLabel";

export const initialState = {
  loading: false,
  hasErrors: false,
  macEvents: [],
  usEvents: [],
  resetMachines: false,
  resetUsers: false,
};

const timelineSlice = createSlice({
  name: "timeline",
  initialState,
  reducers: {
    loading: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    getMachineEventsSuccess: (state, { payload }) => {
      state.loading = false;
      state.macEvents = payload.events;
      state.procEvents = payload.processEvents;
    },
    getUserEventsSuccess: (state, { payload }) => {
      state.loading = false;
      state.usEvents = payload;
    },
    enableErrors: (state) => {
      state.hasErrors = true;
      state.loading = false;
    }
  },
});

export const { loading, getMachineEventsSuccess, getUserEventsSuccess, enableErrors } = timelineSlice.actions;
export const timelineSelector = (state) => state.timeline;
export default timelineSlice.reducer;

export function loadMachineEvents(start, end, duration) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(loading());
    let url = SERVER_URL + "/api/machineEvent?start=" + start + "&end=" + end + "&duration=" + duration;
    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getMachineEventsSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }

  };
}

export function loadUserEvents(start, end, duration) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(loading());
    let url = SERVER_URL + "/api/userEvent?start=" + start + "&end=" + end + "&duration=" + duration;
    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getUserEventsSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

