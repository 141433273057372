import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { customLabel } from "../utility/customLabel";
import { processTypesSelector, createCheckAPI, deleteCheckAPI, updateCheckAPI, newCheck, selectCheck, changeCheck } from './slice'
import { Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import CheckForm from "./CheckForm";
import DialogConfirmAction from '../utility/DialogConfirmAction';

const ChecksPage = ({ processType, maintenance, loading, configuration }) => {
  const dispatch = useDispatch()
  const myClasses = mesStyles();
  const { check } = useSelector(processTypesSelector)
  const [deleting, setDeleting] = React.useState(false);

  const onAdd = () => {
    dispatch(newCheck())
  }

  const onDelete = (check) => {
    setDeleting(check)
  }
  const cancelDelete = () => {
    setDeleting(false)
  }

  const doDelete = () => {
    dispatch(deleteCheckAPI(processType, deleting));
  }

  const onEdit = (check) => {
    dispatch(selectCheck(check))
  }
  const cancelEdit = () => {
    dispatch(selectCheck(false))
  }

  const save = () => {
    if (check.id === "new") {
      dispatch(createCheckAPI(processType, check))
    } else {
      dispatch(updateCheckAPI(processType, check))
    }
  }

  const onChange = event => {
    let name = event.target.name
    let value = event.target.value
    if (event.target.type === "checkbox") {
      value = event.target.checked
    }
    dispatch(changeCheck({ name: name, value: value }));
  };

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit"> {customLabel(maintenance ? "maintenancePhase.checks" : "processType.check.checks")}</Typography>
      </div>
      <div className={myClasses.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{customLabel("processType.name")}</TableCell>
              {!maintenance ? (
                <React.Fragment>
                  <TableCell>{customLabel("processType.check.type")}</TableCell>
                  <TableCell>{customLabel("processType.check.start")}</TableCell>
                  <TableCell>{customLabel("processType.check.pause")}</TableCell>
                  <TableCell>{customLabel("processType.check.restart")}</TableCell>
                </React.Fragment>
              ) : null}
              <TableCell>{customLabel("processType.check.close")}</TableCell>
              {!maintenance ? (
                <TableCell>{customLabel("processType.check.onDemand")}</TableCell>
              ) : null}
              <TableCell className={myClasses.tableCell2Buttons} align="right">
                <Button onClick={onAdd} color="primary" disabled={loading}>{customLabel("button.add")}</Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processType.checks.map(c => {
              return (
                <TableRow key={c.id}>
                  <TableCell>{c.name}</TableCell>
                  {!maintenance ? (
                    <React.Fragment>
                      <TableCell>
                        {c.type === "boolean" ? customLabel("function.boolean") : c.type === "number" ? customLabel("function.number") :
                          c.type === "var" ? customLabel("function.var").toLowerCase() : c.type === "component" ? customLabel("function.component") : customLabel("function.string")}
                      </TableCell>
                      <TableCell>{c.start ? <CheckIcon /> : null}</TableCell>
                      <TableCell>{c.pause ? <CheckIcon /> : null}</TableCell>
                      <TableCell>{c.restart ? <CheckIcon /> : null}</TableCell>
                    </React.Fragment>
                  ) : null}
                  <TableCell>{c.close ? <CheckIcon /> : null}</TableCell>
                  {!maintenance ? (
                    <TableCell>{c.onDemand ? <CheckIcon /> : null}</TableCell>
                  ) : null}
                  <TableCell className={myClasses.tableCell2Buttons} align="right">
                    <Button onClick={() => { onEdit(c) }} color="primary" disabled={loading}>{customLabel("button.edit")}</Button>
                    <Button onClick={() => { onDelete(c) }} color="secondary" disabled={loading}>{customLabel("button.delete")}</Button>
                  </TableCell>
                </TableRow>
              )
            })
            }
          </TableBody>
        </Table>
        {check !== false ? (
          <Dialog open={check ? true : false} keepMounted maxWidth={"sm"} fullWidth>
            <DialogTitle>{customLabel(maintenance ? "maintenancePhase.check" : "processType.check")}</DialogTitle>
            <DialogContent>
              <CheckForm maintenance={maintenance} processCheck={check} onChange={onChange}
                vars={processType.vars.filter((v) => v.operatorEdit && v.operatorView)} configuration={configuration} />
            </DialogContent>
            <DialogActions>
              <Button onClick={save} disabled={loading || check.name === "" || check.type === "" || (!check.start && !check.pause && !check.restart && !check.close && !check.onDemand)
                || (check.type === "var" && (check.varName === null || check.varName === ""))}
                color="primary">{customLabel("button.save")}</Button>
              <Button onClick={cancelEdit} disabled={loading} color="default">{customLabel("button.close")}</Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={cancelDelete} disabled={loading} />
    </PaperForm>
  )
}

export default ChecksPage;
