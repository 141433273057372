export const customFilter = {
    mesName: "customerOrders",
    page: 0,
    rowsPerPage: 10,
    order: "desc",
    orderBy: "date",
    showFilter: false,
    code: false,
    customer: false,
    endDateFrom: false,
    endDateTo: false,
    dateTo: false,
    dateFrom: false
}

export const headers = {
    code: { filter: true, type: "string" },
    customer: { filter: true, type: "object" },
    date: { filter: true, type: "date", name: "date" },
    endDate: { filter: true, type: "date", name: "endDate" },
};
