export const customProcessTypeFilter = {
    mesName: "processTypes",
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "code",
    showFilter: false,
    code: false,
    name: false,
    maintenance: false
}

export const headers = {
    code: { filter: true, type: "string" },
    name: { filter: true, type: "string" },
    multiple: { filter: false, type: "boolean" },
    autostart: { filter: false, type: "boolean" },
    external: { filter: false, type: "boolean" }
};