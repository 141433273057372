import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Table, TableCell, TableRow } from '@material-ui/core';
import { customLabel } from "../utility/customLabel";
import { SERVER_URL } from "../../constants";
import DialogConfirmAction from '../utility/DialogConfirmAction';
import { configurationSelector } from '../configuration/slice';

const ExportData = ({ odp, stapling, loading }) => {
    const [downMachEv, setDownMachEv] = React.useState(false);
    const [downUserEv, setDownUserEv] = React.useState(false);
    const [downChecks, setDownChecks] = React.useState(false);
    const [downVars, setDownVars] = React.useState(false);
    const [downMacVars, setDownMacVars] = React.useState(false);
    const { configuration } = useSelector(configurationSelector)

    const confirmDownMachEv = () => {
        if (!stapling) {
            window.open(SERVER_URL + "/odp/" + odp.id + "/machineEvents/csv");
        } else {
            window.open(SERVER_URL + "/odp/" + odp.id + "/stapling/machineEvents/csv");
        }
        setDownMachEv(false);
    };
    const confirmDownUserEv = () => {
        if (!stapling) {
            window.open(SERVER_URL + "/odp/" + odp.id + "/userEvents/csv");
        } else {
            window.open(SERVER_URL + "/odp/" + odp.id + "/stapling/userEvents/csv");
        }
        setDownUserEv(false);
    };
    const confirmDownChecks = () => {
        if (!stapling) {
            window.open(SERVER_URL + "/odp/" + odp.id + "/checks/csv");
        } else {
            window.open(SERVER_URL + "/odp/" + odp.id + "/stapling/checks/csv");
        }
        setDownChecks(false);
    };
    const confirmDownVars = () => {
        if (!stapling) {
            window.open(SERVER_URL + "/odp/" + odp.id + "/vars/csv");
        } else {
            window.open(SERVER_URL + "/odp/" + odp.id + "/stapling/vars/csv");
        }
        setDownVars(false);
    };
    const confirmDownMacVars = () => {
        if (!stapling) {
            window.open(SERVER_URL + "/odp/" + odp.id + "/machineVars/csv");
        } else {
            window.open(SERVER_URL + "/odp/" + odp.id + "/stapling/machineVars/csv");
        }
        setDownMacVars(false);
    };
    const cancelActions = () => {
        setDownMachEv(false);
        setDownUserEv(false);
        setDownChecks(false);
        setDownVars(false);
        setDownMacVars(false);
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <Table size="small">
                    <tbody>
                        <TableRow>
                            <TableCell>{customLabel("machineEvent.machineEvents").toUpperCase()}</TableCell>
                            <TableCell align="right">
                                <Button color="primary" onClick={() => setDownMachEv(true)} disabled={loading}>
                                    {customLabel("button.download")}
                                </Button>
                            </TableCell>
                        </TableRow>
                        {configuration.showUserEvents ? (
                            <TableRow>
                                <TableCell>{customLabel("userEvent.userEvents").toUpperCase()}</TableCell>
                                <TableCell align="right">
                                    <Button color="primary" onClick={() => setDownUserEv(true)} disabled={loading}>
                                        {customLabel("button.download")}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ) : null}
                        <TableRow>
                            <TableCell>{customLabel("processType.check.checks").toUpperCase()}</TableCell>
                            <TableCell align="right">
                                <Button color="primary" onClick={() => setDownChecks(true)} disabled={loading}>
                                    {customLabel("button.download")}
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{customLabel("processType.var.vars").toUpperCase()}</TableCell>
                            <TableCell align="right">
                                <Button color="primary" onClick={() => setDownVars(true)} disabled={loading}>
                                    {customLabel("button.download")}
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{customLabel("machine.machineVars").toUpperCase()}</TableCell>
                            <TableCell align="right">
                                <Button color="primary" onClick={() => setDownMacVars(true)} disabled={loading}>
                                    {customLabel("button.download")}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </tbody>
                </Table>
            </React.Fragment>
            {/*mostrare conferma per scaricare eventi macchina*/}
            <DialogConfirmAction flag={downMachEv} text={customLabel("odp.confirmDownloadMachineEvents")}
                confirm={confirmDownMachEv} cancel={cancelActions} disabled={false} />
            {/*mostrare conferma per scaricare eventi utente*/}
            <DialogConfirmAction flag={downUserEv} text={customLabel("odp.confirmDownloadUserEvents")}
                confirm={confirmDownUserEv} cancel={cancelActions} disabled={false} />
            {/*mostrare conferma per scaricare controlli*/}
            <DialogConfirmAction flag={downChecks} text={customLabel("odp.confirmDownloadChecks")}
                confirm={confirmDownChecks} cancel={cancelActions} disabled={false} />
            {/*mostrare conferma per scaricare variabili*/}
            <DialogConfirmAction flag={downVars} text={customLabel("odp.confirmDownloadVars")}
                confirm={confirmDownVars} cancel={cancelActions} disabled={false} />
            <DialogConfirmAction flag={downMacVars} text={customLabel("odp.confirmDownloadMacVars")}
                confirm={confirmDownMacVars} cancel={cancelActions} disabled={false} />
        </React.Fragment>
    )
}

export default ExportData
