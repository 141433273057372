import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { loadCustomOdpsAPI, selector, addToProcess, resetToProcess, sendToProcess, initCustomFilter } from "../slice";
import { StyledTableRow } from "../../../utility/TableList";
import { TableContainer, Table, TableCell, TableRow, TableBody, TableHead, Button, CircularProgress } from '@material-ui/core';
import { customLabel } from '../../../utility/customLabel';
import { currentList } from "../utility";
import moment from "moment";
import { bishopFormats } from '../../../../constants';
import DialogConfirmAction from '../../../utility/DialogConfirmAction';
import { mesStyles } from '../../../utility/ultrafabStyles';
import { customersSelector, getListAPI as getCustomersAPI } from "../../../customers/slice";
import { articlesSelector, getListAPI as getArticles } from "../../../articles/slice";
import ButtonsFragment from './tableUtility/ButtonsFragment';
import FilterRow from "./tableUtility/FilterRow";
import PaginationFragment from './tableUtility/PaginationFragment';

const NotScheduled = ({ handleClickRedirect, onChangeFilter, onChangeFilterAutocomplete, handleChangeDate, handleResetDate,
    handleChangePage, handleChangeRowsPerPage, configuration, enableReload }) => {

    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { odpsNotScheduled, loadingNotScheduled, errorsNotScheduled, toProcess, loading, notScheduledFilter, customReload, totalNotScheduled } = useSelector(selector);
    const { customers } = useSelector(customersSelector);
    const { articles } = useSelector(articlesSelector);

    useEffect(() => {
        dispatch(getCustomersAPI(0, 100000, "code", "asc", false, false));
        dispatch(getArticles(0, 100000, "code", "asc", false, false, false, false, false));
    }, [dispatch])

    //inizializzo filtro
    useEffect(() => {
        if (!notScheduledFilter && configuration) {
            dispatch(initCustomFilter({ filter: currentList.notScheduled.filter, showFilter: configuration.showFilters }))
        }
    }, [configuration, notScheduledFilter, dispatch])

    //reload dopo cambio filtro
    useEffect(() => {
        if (notScheduledFilter && customReload && !errorsNotScheduled && !loadingNotScheduled) {
            dispatch(loadCustomOdpsAPI(currentList.notScheduled, notScheduledFilter))
        }
    }, [dispatch, customReload, errorsNotScheduled, notScheduledFilter, loadingNotScheduled])

    const toggleFilter = () => {
        if (!notScheduledFilter.showFilter) {
            dispatch(initCustomFilter({ filter: currentList.notScheduled.filter, showFilter: true }))
        } else {
            dispatch(initCustomFilter({ filter: currentList.notScheduled.filter, showFilter: false }))
        }
    }

    const emptyFilter = () => {
        dispatch(initCustomFilter({ filter: currentList.notScheduled.filter, showFilter: true }))
    };

    const handleAddWorkOdp = (odpId) => {
        dispatch(addToProcess(odpId))
    }

    const handleRemoveWorkOdp = () => {
        dispatch(resetToProcess())
    }

    return (
        <React.Fragment>
            <ButtonsFragment filter={notScheduledFilter} toggleFilter={toggleFilter} emptyFilter={emptyFilter} />
            <TableContainer style={{ maxHeight: "90%" }}>
                <Table stickyHeader
                    aria-labelledby="tableTitle"
                    size={'small'}
                    aria-label="enhanced table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell><b>{customLabel("odp.odp")}</b></TableCell>
                            <TableCell><b>{customLabel("customerOrder.customerOrder")}</b></TableCell>
                            <TableCell><b>{customLabel("customer.customer")}</b></TableCell>
                            <TableCell><b>{customLabel("article.article")}</b></TableCell>
                            <TableCell><b>{customLabel("odp.deliveryDate")}</b></TableCell>
                            <TableCell><b>{customLabel("machine.machine")}</b></TableCell>
                            <TableCell><b>{customLabel("workcentre.workcentre")}</b></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/*8 colonne */}
                        {notScheduledFilter && odpsNotScheduled && articles && customers && !loadingNotScheduled ? (
                            <FilterRow customFilter={notScheduledFilter} customers={customers} articles={articles} suppliers={false} onChangeFilter={onChangeFilter}
                                onChangeFilterAutocomplete={onChangeFilterAutocomplete} handleChangeDate={handleChangeDate} handleResetDate={handleResetDate}
                                colSpan={[1, 1, 1, 1, 1, 0, 0, 0, 3]} typeStatus={currentList.notScheduled.url} showDeliveryFilter={true} showStartDateFilter={false}
                                showEndDateFilter={false} showSuppliers={false} showMachinesFilter={false} showWorkcentresFilter={false} machines={false}
                                workcentres={false} enableReload={enableReload} />
                        ) : null}
                        {odpsNotScheduled && odpsNotScheduled.length > 0 && articles && customers && !loadingNotScheduled ? (
                            odpsNotScheduled.map((o) => {
                                return (
                                    <StyledTableRow key={o.id}>
                                        <TableCell onClick={() => handleClickRedirect(o.id)} className={myClasses.cursorPointer}>{o.code}</TableCell>
                                        <TableCell onClick={() => handleClickRedirect(o.id)} className={myClasses.cursorPointer}>{o.customerOrder?.code}</TableCell>
                                        <TableCell onClick={() => handleClickRedirect(o.id)} className={myClasses.cursorPointer}>{o.customer?.code} {o.customer?.name}</TableCell>
                                        <TableCell onClick={() => handleClickRedirect(o.id)} className={myClasses.cursorPointer}>{o.article?.code} {o.article?.name}</TableCell>
                                        <TableCell onClick={() => handleClickRedirect(o.id)} className={myClasses.cursorPointer}>{o.deliveryDate ? moment(o.deliveryDate).format(configuration && configuration.showOdpDeliveryTime ? bishopFormats.LT : bishopFormats.L) : null}</TableCell>
                                        <TableCell onClick={() => handleClickRedirect(o.id)} className={myClasses.cursorPointer}>{o.machine?.code} {o.machine?.name}</TableCell>
                                        <TableCell onClick={() => handleClickRedirect(o.id)} className={myClasses.cursorPointer}>{o.machine?.workcentre?.code} {o.machine?.workcentre?.name}</TableCell>
                                        <TableCell>
                                            <Button color="primary" onClick={() => handleAddWorkOdp(o.id)}>
                                                {customLabel("button.work")}
                                            </Button>
                                        </TableCell>
                                    </StyledTableRow>
                                )
                            })
                        ) : odpsNotScheduled && odpsNotScheduled.length === 0 && !loadingNotScheduled ?
                            (
                                <TableRow>
                                    <TableCell colSpan={8}>{customLabel("odp.noOdpsAvailable").toUpperCase()}</TableCell>
                                </TableRow>
                            )
                            : loadingNotScheduled || !customers || !articles ?
                                (<TableRow>
                                    <TableCell align="center" colSpan={8}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>)
                                :
                                null}
                    </TableBody>
                </Table>
            </TableContainer>
            {totalNotScheduled !== false && !loadingNotScheduled ? (
                <PaginationFragment total={totalNotScheduled} customFilter={notScheduledFilter} handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} typeStatus={currentList.notScheduled.url} disabledChange={loadingNotScheduled} />
            ) : null}
            <DialogConfirmAction flag={toProcess && toProcess.length === 1 ? true : false} cancel={() => handleRemoveWorkOdp()} confirm={() => dispatch(sendToProcess(toProcess, currentList.notScheduled, notScheduledFilter))}
                disabled={loading} text={customLabel("odp.confirmToWorkOdp")} />
        </React.Fragment>
    )
}

export default NotScheduled
