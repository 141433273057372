import React from 'react'
import { customLabel } from "../utility/customLabel";
import { useDispatch, useSelector } from "react-redux"
import {
  Button, Typography, Table, TableRow, TableBody, TableCell, TableHead, Dialog, DialogTitle, InputLabel, DialogActions, DialogContent
} from "@material-ui/core";
import { mesStyles } from "../utility/ultrafabStyles";
import { machinesSelector, saveImageAPI, deleteImageAPI, disableInsertImage, enableInsertImage, enableDeletingImage, disableDeletingImage } from './slice';
import DialogConfirmAction from "../utility/DialogConfirmAction";

const Image = ({ workcentre, machine, loading }) => {
  const myClasses = mesStyles();
  const dispatch = useDispatch()
  const [imageFile, setImageFile] = React.useState(null);
  const { insertImage, deletingImage } = useSelector(machinesSelector);

  const openDialogImage = () => {
    dispatch(enableInsertImage());
  }

  const closeDialogImage = () => {
    setImageFile(null);
    dispatch(disableInsertImage());
  }

  const onSelectImage = event => {
    //per evitare che si rompi nel caso l'utente schiacci annulla su dialog di inserimento del file
    if (event.target.files && event.target.files.length > 0) {
      setImageFile(event.target.files)
    }
  };

  const confirmSave = () => {
    dispatch(saveImageAPI(workcentre, machine, imageFile));
    setImageFile(null);
  };
  const onDeleting = () => {
    dispatch(enableDeletingImage());
  };
  const confirmDelete = () => {
    dispatch(deleteImageAPI(workcentre, machine));
    setImageFile(null);
  };
  const cancelDelete = () => {
    dispatch(disableDeletingImage());
  };

  const onInputClick = (event) => {
    event.target.value = ''
  }

  return (
    <React.Fragment>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit"> {customLabel("machine.image.machineImage")}</Typography>
      </div>
      <div className={myClasses.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                {customLabel("machine.image.name")}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {machine.image != null ? machine.image : customLabel("function.notAvailable").toUpperCase()}
              </TableCell>
              <TableCell className={myClasses.tableCell1Button}>
                {machine.image !== null ? (
                  <Button color="secondary" onClick={onDeleting}>{customLabel("button.delete")}</Button>
                ) : (
                  <Button color="primary" component="label" onClick={openDialogImage}>
                    {customLabel("button.add")}
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <Dialog open={insertImage ? true : false} keepMounted maxWidth={"sm"} fullWidth>
        <DialogTitle>{customLabel("machine.image.image")}</DialogTitle>
        <DialogContent>
          <form className={myClasses.dialogForm} noValidate autoComplete="off">
            <div className={myClasses.insertFileDiv}>
              <Button
                component="label"
                color="primary"
                disabled={loading}
              >
                {customLabel("button.add")}
                <input
                  type="file"
                  hidden
                  onChange={onSelectImage}
                  onClick={onInputClick}
                />
              </Button>
              <InputLabel>{imageFile ? imageFile[0].name : customLabel("document.noFileSelected")}</InputLabel>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmSave} disabled={loading || imageFile === null} color="primary"  >{customLabel("button.save")}</Button>
          <Button onClick={closeDialogImage} disabled={loading} color="default">{customLabel("button.close")}</Button>
        </DialogActions>
      </Dialog>
      <DialogConfirmAction flag={deletingImage} text={customLabel("function.confirmDelete")}
        confirm={confirmDelete} cancel={cancelDelete} disabled={loading} />
    </React.Fragment >
  )
}

export default Image
