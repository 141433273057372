import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../../utility/customLabel";
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import { bishopFormats } from '../../../constants';
import { selector, getProcesses } from './slice';
import PopoverItem from "../../utility/PopoverItem";
import {
  Paper, Typography, Divider, FormControl, InputLabel, Select, MenuItem, Grid, List, ListItem
} from '@material-ui/core';
import useInterval from 'react-useinterval';
import { mesStyles } from "../../utility/ultrafabStyles";
import { machineEventsSelector, getListAPI as getMachineEventsAPI } from "../../machineEvents/slice";
import { workcentresSelector, getListAPI as getWorkcentresAPI } from "../../workcentres/slice";
import { doChange } from "../slice";
import { calcRemainingTime } from "../../utility/vari";
import ProgressBarOneLine from "../../utility/ProgressBarOneLine";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import EventIcon from '@material-ui/icons/Event';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import WorkIcon from '@material-ui/icons/Work';
import ExtensionIcon from '@material-ui/icons/Extension';
import LayersIcon from '@material-ui/icons/Layers';
import { TableRow, TableCell, Table } from '@material-ui/core';
import { label } from './utility'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat( auto-fill, minmax(300px, 1fr) );",
    gap: theme.spacing(1)
  },
  break: {
    "marginBottom": theme.spacing(1),
    "marginTop": theme.spacing(1),
  },
  box: {
    height: "auto",
    padding: theme.spacing(1),
    maxWidth: "300px",
    minWidth: "300px",
    minHeight: "190px",
  },
  machineBox: {
    height: "auto",
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: "100px",
    fontSize: "120%",
    backgroundColor: "#7a7a7a",
    color: "#fff"
  },
  icon: {
    fontSize: "0.95rem"
  },
  text: {
    display: "inline-block",
    maxWidth: "90px",
    minWidth: "90px",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis"
  },
  legendLine: {
    display: "flex",
    justifyContent: "space-between"
  },
  legendSquare: {
    height: "15px",
    width: "15px",
    border: "solid 1px black",
    marginTop: "auto",
    marginLeft: "10px"
  },
  popoverContent: {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  underline: {
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    padding: '3px 0px',
    margin: '0px 0px'
  },
  listItem: {
    padding: '1px 8px',
    margin: '0px 0px'
  },
  title: {
    padding: '1px 8px 10px 8px',
    margin: '0px 0px'
  }
}));

export default function WorkcentreViewPage() {
  const classes = useStyles();
  const myClasses = mesStyles();
  const dispatch = useDispatch();
  const { processes } = useSelector(selector);
  const { machineEvents } = useSelector(machineEventsSelector);
  const { workcentres } = useSelector(workcentresSelector);
  const [currentWorkcentre, setCurrentWorkcentre] = React.useState("all");
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [params, setParams] = React.useState({ process: {} })

  useInterval(() => reloadProcesses(), 10000);
  const reloadProcesses = () => {
    dispatch(getProcesses(currentWorkcentre));
  }

  useEffect(() => {
    dispatch(getProcesses(currentWorkcentre));
  }, [dispatch, currentWorkcentre]);

  useEffect(() => {
    dispatch(getMachineEventsAPI(0, 10000, "name", "asc", false));
    dispatch(getWorkcentresAPI(0, 10000, "code", "asc", false, false));
  }, [dispatch]);

  const handlePopoverOpen1 = (event, type, process) => {
    setParams({ type: type, process: process })
    setAnchorEl1(event.currentTarget);
  };
  const handlePopoverClose1 = () => {
    setParams({ process: {} })
    setAnchorEl1(null);
  };
  const open1 = Boolean(anchorEl1)
  const changeWorkcentre = (event) => {
    setCurrentWorkcentre(event.target.value)
    dispatch(getProcesses(event.target.value));
  }

  const change = (url) => {
    dispatch(doChange(url))
  }

  const returnOdpData = (process) => {
    let rt = calcRemainingTime(process.cycle_time ? process.cycle_time : null, process.valid_pieces, process.total_pieces, process.quantity, true);
    return (
      <Paper className={classes.box}
        style={{ borderLeft: "5px solid " + process.e_background_color }} key={process.process_id + "_" + process.w_code + "_" + process.m_code}>
        <Typography variant="h6" component="h6">{process.m_code} - {process.m_name}</Typography>
        <Grid container spacing={1}>
          {/*<Grid item xs={6}>
            <Grid container spacing={0}>*/}
          <Grid item xs={12}>{customLabel("machineEvent.machineEvent")}: <b className={process.e_name ? myClasses.dashedUnderline : null} onMouseEnter={process.e_name ? (event) => handlePopoverOpen1(event, label.legend, process) : null} onMouseLeave={handlePopoverClose1}>{process.e_name !== '' && process.e_name !== null ? process.e_name : "ND"}</b></Grid>
          {process.e_name ?
            <React.Fragment>
              <Grid item xs={6}><WorkIcon className={classes.icon} /> <span className={classes.text}><b className={myClasses.dashedUnderline} onMouseEnter={(event) => handlePopoverOpen1(event, process.odp_id ? label.odp : label.stapling, process)} onMouseLeave={handlePopoverClose1}>{process.p_code !== null ? process.p_code : process.stapling_code}</b></span></Grid>
              <Grid item xs={6}>{process.p_code !== null ? <span><SupervisedUserCircleIcon className={classes.icon} /> <span className={classes.text}><b className={myClasses.dashedUnderline} onMouseEnter={(event) => handlePopoverOpen1(event, label.customer, process)} onMouseLeave={handlePopoverClose1}>{process.cust_name}</b></span></span> : null}</Grid>
              <Grid item xs={6}><LayersIcon className={classes.icon} /> <span className={classes.text}>{process.p_name !== null ? process.p_name : process.stapling_type}</span><br /></Grid>
              <Grid item xs={6}>{process.p_code !== null ? <span><EventIcon className={classes.icon} /> {process.delivery_date !== null ? moment(process.delivery_date).format(bishopFormats.L) : null} </span> : null}</Grid>
              <Grid item xs={6}>
                {process.status_id !== null ?
                  <React.Fragment>
                    {process.status_id === 3 ? <React.Fragment><PauseCircleOutlineIcon className={classes.icon} /> {process.status}</React.Fragment> : process.status_id === 1 ? <React.Fragment><PlayCircleOutlineIcon className={classes.icon} /> {process.status}</React.Fragment> : <span>{process.status}</span>}
                  </React.Fragment> :
                  <React.Fragment>
                    {process.stapling_status_id === 3 ? <React.Fragment><PauseCircleOutlineIcon className={classes.icon} /> {process.stapling_status}</React.Fragment> : process.stapling_status_id === 1 ? <React.Fragment><PlayCircleOutlineIcon className={classes.icon} /> {process.stapling_status}</React.Fragment> : <span>{process.stapling_status}</span>}
                  </React.Fragment>}
              </Grid>
              <Grid item xs={6}>{process.p_code !== null ? <span><ExtensionIcon className={classes.icon} /><span className={classes.text}><b className={myClasses.dashedUnderline} onMouseEnter={(event) => handlePopoverOpen1(event, label.article, process)} onMouseLeave={handlePopoverClose1}>{process.art_code}</b></span></span> : null}</Grid>
              <Grid item xs={6}><AccessTimeIcon className={classes.icon} /> {rt}</Grid>
              <Grid item xs={6}>
                <ProgressBarOneLine total={process !== null ? process.quantity : null}
                  current={process !== null && process.valid_pieces !== null ? process.valid_pieces :
                    process !== null && process.total_pieces !== null ? process.total_pieces - process.rejected_pieces : 0}
                  measure={null} noMarginText={true} />
              </Grid>
            </React.Fragment> : null}
        </Grid>
        {/*
          </Grid>
          <Grid item xs={6}>
            {process.m_image !== null ? (
              <img src={SERVER_URL + "/machine/" + process.m_id + "/image?" + new Date().getTime()} width={160} alt="immagine-macchina" />
            ) : null}
            </Grid>
        </Grid>
          */}
      </Paper >
    )
  }

  const legendList = () => {
    return (
      machineEvents.map((l) => {
        return (
          <TableRow key={l.id}>
            <TableCell>
              <div className={classes.legendLine}>
                {l.name}
                <div className={classes.legendSquare} style={{ backgroundColor: l.backgroundColor }}></div>
              </div>
            </TableCell>
          </TableRow>
        )
      })
    )
  }

  const odpList = () => {
    return (
      <List
        className={classes.list}
      >
        <Typography className={classes.title} variant="subtitle2">{customLabel("odp.odp")}</Typography>
        <ListItem className={classes.listItem} button onClick={() => change(`/odps/${params.process.odp_id}`)} component={Link} to={`/odps/${params.process.odp_id}`}>
          <Typography variant="body1">
            <b>{customLabel("odp.code")}</b>: {params.process.p_code}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem} button onClick={() => change(`/customerOrders`)} component={Link} to={`/customerOrders/${params.process.cust_order_id}`}>
          <Typography variant="body1">
            <b>{customLabel("odp.customerOrder")}</b>: {params.process.cust_order_code}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem} button onClick={() => change(`/admin/boms/${params.process.bom_id}`)} component={Link} to={`/admin/boms/${params.process.bom_id}`}>
          <Typography variant="body1">
            <b>{customLabel("bom.bom")}</b>: {params.process.bom_name}
          </Typography>
        </ListItem>
      </List>
    )
  }

  const customerList = () => {
    return (
      <List
        className={classes.list}
      >
        <Typography className={classes.title} variant="subtitle2">{customLabel("customer.customer")}</Typography>
        <ListItem className={classes.listItem} button onClick={() => change(`/customers`)} component={Link} to={`/customers/${params.process.cust_id}`}>
          <Typography variant="body1">
            <b>{customLabel("customer.code")}</b>: {params.process.cust_code}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem} button onClick={() => change(`/customers`)} component={Link} to={`/customers/${params.process.cust_id}`}>
          <Typography variant="body1">
            <b>{customLabel("customer.name")}</b>: {params.process.cust_name}
          </Typography>
        </ListItem>
      </List>
    )
  }

  const articleList = () => {
    return (
      <List
        className={classes.list}
      >
        <Typography className={classes.title} variant="subtitle2">{customLabel("article.article")}</Typography>
        <ListItem className={classes.listItem} button onClick={() => change(`/articles/${params.process.art_id}`)} component={Link} to={`/articles/${params.process.art_id}`}>
          <Typography variant="body1">
            <b>{customLabel("article.code")}</b>: {params.process.art_code}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem} button onClick={() => change(`/articles/${params.process.art_id}`)} component={Link} to={`/articles/${params.process.art_id}`}>
          <Typography variant="body1">
            <b>{customLabel("article.name")}</b>: {params.process.art_name}
          </Typography>
        </ListItem>
        {params.process.cycle_time ?
          <ListItem className={classes.listItem} button onClick={() => change(`/articles/${params.process.art_id}`)} component={Link} to={`/articles/${params.process.art_id}`}>
            <Typography variant="body1">
              <b>{customLabel("process.theoreticalCycleTime")}</b>: {params.process.cycle_time + ' s'}
            </Typography>
          </ListItem>
          : null}
      </List>
    )
  }

  const staplingList = () => {
    return (
      <List
        className={classes.list}
      >
        <Typography className={classes.title} variant="subtitle2">{customLabel("stapling.stapling")}</Typography>
        <ListItem className={classes.listItem} button onClick={() => change(`/odps/${params.process.stapling_id}`)} component={Link} to={`/odps/${params.process.stapling_id}`}>
          <Typography variant="body1">
            <b>{customLabel("odp.code")}</b>: {params.process.stapling_code}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem} button onClick={() => change(`/odps/${params.process.stapling_id}`)} component={Link} to={`/odps/${params.process.stapling_id}`}>
          <Typography variant="body1">
            <b>{customLabel("stapling.stapledOdps")}</b>: {params.process.stapling_processes}
          </Typography>
        </ListItem>
      </List>
    )
  }

  const renderList = () => {
    return params.type === label.legend ? <Table size="small"><tbody>{legendList()}</tbody></Table>
      : params.type === label.odp ? odpList()
        : params.type === label.customer ? customerList()
          : params.type === label.article ? articleList()
            : params.type === label.stapling ? staplingList()
              : null
  }

  if (processes) {
    return (
      <div>
        <div className={myClasses.headerSpaceBetween}>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="h1">
            {customLabel("menuItem.workcentreView")}
          </Typography>
        </div>
        <Divider />
        {workcentres ? (
          <FormControl className={myClasses.form50} margin="normal">
            <InputLabel htmlFor="workcentres">{customLabel("workcentre.workcentre")}</InputLabel>
            <Select onChange={changeWorkcentre} value={currentWorkcentre}>
              <MenuItem value="all">
                <option>{customLabel("workcentre.all")}</option>
              </MenuItem>
              {workcentres.map((a) => {
                return (
                  <MenuItem key={a.id} value={a.id}>
                    {a.code}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : null}
        <Divider />
        <div>
          {Object.keys(processes).map((wc) => {
            return (
              <React.Fragment key={wc}>
                <Paper className={classes.machineBox}><b>{wc} - {processes[wc][0]["w_name"]}</b></Paper>
                <div className={classes.break}></div>
                <div key={wc} className={classes.machine}>
                  <div className={classes.content}>
                    {processes[wc].map((process) => {
                      return returnOdpData(process)
                    })}
                  </div>
                </div>
                <div className={classes.break}><hr /></div>
              </React.Fragment>
            )
          })}
        </div>
        <div aria-owns="mouse-over-popover" aria-haspopup="true">
          <PopoverItem content={renderList} open={open1} anchorEl={anchorEl1} params={params} openPopover={handlePopoverOpen1} closePopover={handlePopoverClose1}
            anchorVertical={"top"} anchorHorizontal={"left"} transformVertical={"top"} transormHorizontal={"left"} />
        </div>
      </div >
    );
  }
  return null;
}