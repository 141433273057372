import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from '../../utility/customLabel';

import {
  AppBar,
  Button,
  Divider,
  FormControl,
  Grid,
  Paper,
  Tab,
  Table,
  TableCell,
  TableRow,
  Tabs,
  TextField
} from "@material-ui/core";
import moment from "moment";
import { bishopFormats } from '../../../constants';
import Alerts from "../../alerts/Alerts";
import MyAlert from "../../alerts/MyAlert";
import { ALERT_TYPE_WARNING } from "../../alerts/constants";
import { getVarCheck } from "../../checks/utility";
import { mainSelector } from "../../main/slice";
import { processTypesSelector } from "../../processTypes/slice";
import ProgressBarOneLine from "../../utility/ProgressBarOneLine";
import { calcRemainingTime, secondsToHours } from "../../utility/vari";
import ButtonsDialog from "../buttons/ButtonsDialog";
import ChecksDialog from "../checks/ChecksDialog";
import ComponentDialog from "../components/ComponentDialog";
import ComponentsPage from "../components/ComponentsPage.js";
import MachineEventDialog from "../events/MachineEventDialog";
import MachineEventReasonDialog from "../events/MachineEventReasonDialog";
import UserEventDialog from "../events/UserEventDialog";
import UserEventsDialog from "../events/UserEventsDialog";
import {
  disableFlagStopProcess, enableFlagButtons, enableFlagCloseChecks, enableFlagExtChecks, enableFlagMachineEventReason, enableFlagMachineEventType,
  enableFlagPauseChecks, enableFlagRestartChecks, enableFlagStartChecks, enableFlagStopProcess, enableFlagUserEventType, enableFlagVars, operatorViewSelector,
  pauseProcessAPI, presetVarCurrentValue, startProcessAPI, stopMachineEventAPI, stopProcessAPI
} from "../slice";
import DialogOperator from "../utility/DialogOperator";
import ProgressLine from "../utility/ProgressLine";
import { MACHINE_EVENT_OFF } from "../utility/constants";
import { opViewStyles } from "../utility/customStyles";
import { renderArticleLabel } from "../utility/utility";
import VarsDialog from "../vars/VarsDialog";
import OperatorMeasurePage from "./OperatorMeasurePage";
import ReloadingAPI from "./ReloadingAPI";

const ManageProcess = ({ configuration }) => {
  const customClasses = opViewStyles();
  const dispatch = useDispatch();
  const { process, odp, machine, operator, userEventType, machineEvent,
    startChecks, closeChecks, restartChecks, pauseChecks, errors,
    vars, loading, modeMaintenance, flagDialog, extChecks,machineEventTypeReasons
  } = useSelector(operatorViewSelector);
  const { serverNotResponding } = useSelector(mainSelector);
  const [tabIndex, setTabIndex] = React.useState(0);
  const { processType } = useSelector(processTypesSelector)

  let processTypeVar = processType?.totalPieces !== "" ? processType.totalPieces : processType.validPieces

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };
  /**
   * Gestisce l'avvio o la ripresa della lavorazione: se non esistono controlli avvia subito la lavorazione viceversa l'avvia dopo l'inserimento
   * dell'ultimo controllo
   */
  const handleAddProcess = () => {
    if (process.startDate === null && startChecks && startChecks.length > 0) {
      dispatch(enableFlagStartChecks());
      presetVarCheckValue(startChecks[0]);
    } else if (process.startDate !== null && restartChecks && restartChecks.length > 0) {
      dispatch(enableFlagRestartChecks());
      presetVarCheckValue(restartChecks[0]);
    } else if (!errors) {
      dispatch(startProcessAPI(odp, process, operator, machine, false));
    }
  };

  /**
   * Gestisce l'apertura dell' evento utente dialog e se non ci sono controlli avvia contemporaneamente la lavorazione, altrimenti chiama
   * handleAddProcess che richiederà l'inserimento dei controlli
   */
  const handleOpenUserEvent = () => {
    dispatch(enableFlagUserEventType(true));
    let checks;
    if (process.startDate === null) {
      checks = [...startChecks];
    } else {
      checks = [...restartChecks];
    }
    //se non ci sono controlli avvio direttamente la lavorazione
    if (!(checks && checks.length > 0)) {
      dispatch(startProcessAPI(odp, process, operator, machine, false));
    }
  }
  /**
   * Gestisce il conferma chiudi lavorazione, se esistono check di chiusura li chiede altrimenti ferma la lavorazione
   */
  const handleCloseProcess = () => {
    if (!modeMaintenance && closeChecks && closeChecks.length > 0) {
      dispatch(enableFlagCloseChecks());
      presetVarCheckValue(closeChecks[0]);
    } else if (!errors) {
      dispatch(stopProcessAPI(odp, process, operator, machine));
    }
  };

  //mettere in pausa la lavorazione e controllo relativi checks
  const handlePauseProcess = () => {
    if (pauseChecks && pauseChecks.length > 0) {
      dispatch(enableFlagPauseChecks());
      presetVarCheckValue(pauseChecks[0]);
    }
    else if (!errors) {
      dispatch(pauseProcessAPI(odp, process, operator, machine));
    }
  };
  //API che cancella Evento macchina
  const stopMachineEvent = () => {
    dispatch(stopMachineEventAPI(machineEvent, machine));
  };

  const renderStopProcessContentDialog = () => {
    return (
      <React.Fragment>
        {customLabel("operatorView.confirmStopProcess")}
      </React.Fragment>
    )
  };

  const renderStopProcessActionsDialog = () => {
    return (
      <React.Fragment>
        <Button className={customClasses.dialogButton} variant="contained" disabled={loading} onClick={() => handleCloseProcess()} color="primary">{customLabel("button.confirm")}</Button>
        <Button className={customClasses.dialogButton} variant="contained" onClick={() => dispatch(disableFlagStopProcess())} disabled={loading} color="secondary">{customLabel("button.cancel")}</Button>
      </React.Fragment>
    )
  }

  const availableButtons = (buttons) => {
    let showButtons = []
    for (let button in buttons) {
      let b = buttons[button]
      if (b.roles && operator.authorities && b.showInOdp) {
        for (let a in operator.authorities) {
          let p = operator.authorities[a]
          if (b.roles.some((x) => p === x.authority) && !showButtons.some((y) => b.id === y.id)) {
            showButtons.push(b)
          }
        }
      }
    }
    return showButtons
  }

  /**
   * Prevalorizza il currentValue ogniqualvolta vengono inizializzati i check, altrimenti il value è null come sempre
   * @param {object} check 
   */
  const presetVarCheckValue = (check) => {
    if (check.var_name !== "" && check.var_name !== null && vars) {
      let varToInsert = getVarCheck(check, vars);
      if (varToInsert) {
        dispatch(presetVarCurrentValue({ var: varToInsert }))
      }
    };
  }

  const renderProgressBar = () => {
    return (
      <div style={{ width: '250px', marginTop: '20px' }}>
        <ProgressBarOneLine total={process !== null ? process.quantity : null}
          current={process !== null && process.validPieces !== null ? process.validPieces :
            process !== null && process.totalPieces !== null ? process.totalPieces - process.rejectedPieces : 0}
          measure={null} noMarginText={true} />
      </div>
    )
  }

  return (
    <Paper className={customClasses.middlePaper}>
      <AppBar position="static">
        <Tabs value={tabIndex} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
          <Tab label={customLabel("odp.odp")} key={"odp"} value={0} />
          {configuration.enableComponents ? (
            <Tab label={customLabel("component.components")} key={"components"} value={1} />
          ) : null}
          <Tab label={customLabel("operatorView.measure").toUpperCase()} key={"measureTools"} value={2} />
        </Tabs>
      </AppBar>
      {tabIndex === 0 && process ? (
        <React.Fragment>
          <Grid container alignItems="flex-start" justify="center">
            <Grid item xs={8}>
              <React.Fragment>
                <h3 className={customClasses.divTitleManagePages}>{customLabel("processType.process")}</h3>
                <Divider />
              </React.Fragment>
              <Table size={"small"}>
                <tbody>
                  {process.code ? (
                    <TableRow>
                      <TableCell>{customLabel("processType.name")}</TableCell>
                      <TableCell><b>{process.name}</b></TableCell>
                    </TableRow>
                  ) : null}
                  {process.type && !process.type.multiple ? (
                    <TableRow>
                      <TableCell>{customLabel("article.article")}</TableCell>
                      <TableCell>{process && process.article !== null ? (<b>{renderArticleLabel(configuration, process.article)}</b>) : null}</TableCell>
                    </TableRow>
                  ) : null}
                  {process.type && !process.type.multiple ? (
                    <TableRow>
                      <TableCell>{customLabel("operatorView.quantity")}</TableCell>
                      <TableCell>
                        <b>{process.validPieces !== null ? Number(process.validPieces.toFixed(configuration.decimals)) : process.totalPieces !== null ? Number((process.totalPieces - process.rejectedPieces).toFixed(configuration.decimals)) : 0}/{Number(process.quantity.toFixed(configuration.decimals))} {process.article.measure}</b>
                        <br />
                        {process.validPieces !== null && process.quantity !== null && process.quantity > 0 ?
                          <ProgressLine variant="determinate"
                            value={(process.validPieces / process.quantity * 100) < 100 ? (process.validPieces / process.quantity * 100) : 100} /> :
                          process.validPieces === null && process.totalPieces !== null && process.quantity !== null && process.quantity > 0 ?
                            <ProgressLine variant="determinate"
                              value={((process.totalPieces - process.rejectedPieces) / process.quantity * 100) < 100 ? ((process.totalPieces - process.rejectedPieces) / process.quantity * 100) : 100} />
                            : null}
                      </TableCell>
                    </TableRow>) : null}
                  {configuration.showOdpRemainingQuantity ? (
                    <TableRow>
                      <TableCell>{customLabel("operatorView.remainingQuantity")}</TableCell>
                      <TableCell><b>{process.validPieces !== null && process.quantity > process.validPieces ? Number((process.quantity - process.validPieces).toFixed(configuration.decimals))
                        : process.totalPieces !== null && process.quantity > (process.totalPieces - process.rejectedPieces) ? Number((process.quantity - (process.totalPieces - process.rejectedPieces)).toFixed(configuration.decimals))
                          : (process.validPieces !== null && process.validPieces >= process.quantity) || (process.totalPieces !== null && (process.totalPieces - process.rejectedPieces) >= process.quantity) ? 0
                            : Number(process.quantity.toFixed(configuration.decimals))} {process.article.measure}</b>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {
                    process.type && !process.type.multiple ? (
                      <TableRow>
                        <TableCell>
                          {customLabel("process.deliveryDate")}
                        </TableCell>
                        <TableCell><b>{process.deliveryDate ? moment(process.deliveryDate).format(configuration && configuration.showOdpDeliveryTime ? bishopFormats.LT : bishopFormats.L) : null}</b></TableCell>
                      </TableRow>
                    ) : null
                  }
                  <TableRow>
                    <TableCell>{customLabel("process.startDate")}</TableCell>
                    <TableCell><b>{process.startDate ? moment(process.startDate).format(bishopFormats.LTS) : null}</b></TableCell>
                  </TableRow>
                  {
                    process.type && !process.type.multiple ? (
                      <TableRow>
                        <TableCell>{customLabel("process.endDate")}</TableCell>
                        <TableCell><b>{process.endDate ? moment(process.endDate).format(bishopFormats.LTS) : null}</b></TableCell>
                      </TableRow>
                    ) : null
                  }
                  {
                    process.type && !process.type.multiple ? (
                      <TableRow>
                        <TableCell>
                          {customLabel("processStatus.processStatus")}
                        </TableCell>
                        <TableCell>{process.status.id !== null ? (<b>{process.status.name}</b>) : null}</TableCell>
                      </TableRow>
                    ) : null
                  }
                  {
                    process.type && !process.type.multiple ? (
                      <React.Fragment>
                        <TableRow>
                          <TableCell>{customLabel("operatorView.expectedRunTime")}</TableCell>
                          <TableCell><b>{process.expectedRunTime ? secondsToHours(process.expectedRunTime) : null}</b></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{customLabel("operatorView.expectedSetupTime")}</TableCell>
                          <TableCell><b>{process.expectedSetupTime ? secondsToHours(process.expectedSetupTime) : null}</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{customLabel("process.remainingTime")}</TableCell>
                          <TableCell><b>{process.cycleTime !== null && process.cycleTime.cycleTime !== null ? calcRemainingTime(process.cycleTime ? process.cycleTime.cycleTime : null, process.validPieces, process.totalPieces, process.quantity, true) : null}</b></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{customLabel("machineEvent.machineEvent")}</TableCell>
                          <TableCell>{machineEvent && (!machineEvent.type.systemEvent || machineEvent.type.name === MACHINE_EVENT_OFF) ? (<b>{machineEvent.type.name}</b>) : null}</TableCell>
                        </TableRow>
                      </React.Fragment>
                    ) : null
                  }
                  {
                    process.note ? (
                      <TableRow>
                        <TableCell colSpan={2}>
                          <FormControl className={customClasses.formNoteManagePages} fullWidth>
                            <TextField variant="outlined" name="note" multiline label="Note lavorazione" id="outlined-disabled"
                              InputProps={{
                                readOnly: true,
                              }}
                              value={process.note ? process.note : null} rows={8}>
                            </TextField>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ) : null
                  }
                </tbody>
              </Table>
            </Grid>
            <Grid item xs={4}>
              <Table size={"small"}>
                <tbody>
                  <TableRow>
                    <TableCell>
                      {/*Pulsante che viene mostrato nel caso si tratti di una rilavorazione */}
                      {
                        process.endDate !== null && process.repeatable ? (
                          <Button variant="contained" color="primary" fullWidth className={customClasses.actionButtonManagePages}
                            disabled={(machineEvent && !machineEvent.type.systemEvent) || loading || serverNotResponding ? true : false}
                            onClick={() => handleAddProcess()}>
                            {customLabel("button.restart")}
                          </Button>
                        ) : null
                      }
                      <Button variant="contained" color="primary" className={customClasses.actionButtonManagePages} fullWidth
                        disabled={loading || process.status.id === 1 || process.status.id === 4 || (machineEvent && (!machineEvent.type.systemEvent || machineEvent.type.name === MACHINE_EVENT_OFF)) || serverNotResponding ? true : false}
                        onClick={!userEventType && configuration.askUserEvent ? () => handleOpenUserEvent() : () => handleAddProcess()}>
                        {customLabel("button.start")}
                      </Button>
                      <Button variant="contained" color="secondary" className={customClasses.actionButtonManagePages} fullWidth
                        disabled={loading || process.status.id >= 2 || (machineEvent && !machineEvent.type.systemEvent) || (process.type.multiple) || serverNotResponding ? true : false}
                        onClick={() => handlePauseProcess()}>
                        {customLabel("button.pause")}
                      </Button>
                      <Button variant="contained" color="secondary" className={customClasses.actionButtonManagePages} fullWidth
                        onClick={() => dispatch(enableFlagStopProcess())}
                        disabled={loading || (process.status.id >= 4 && process.status.systemStatus) || (machineEvent && !machineEvent.type.systemEvent) || serverNotResponding ? true : false}>
                        {customLabel("button.stop")}
                      </Button>
                    </TableCell>
                  </TableRow>
                  <React.Fragment>
                    {process.type && !process.type.multiple ? (
                      <TableRow>
                        <TableCell>
                          <Button variant="contained" color="primary" fullWidth className={customClasses.actionButtonManagePages}
                            onClick={() => dispatch(enableFlagMachineEventType())}
                            disabled={loading || (machineEvent && !machineEvent.type.systemEvent) || process.status.id >= 4 || serverNotResponding}
                          >
                            {customLabel("button.startEvent")}
                          </Button>
                          <Button variant="contained" color="secondary" className={customClasses.actionButtonManagePages} fullWidth
                            onClick={() => stopMachineEvent()}
                            disabled={loading || !machineEvent || machineEvent.type.systemEvent || process.status.id >= 4 || serverNotResponding}
                          >
                            {customLabel("button.stopEvent")}
                          </Button>
                          <Button variant="contained" color="secondary" className={customClasses.actionButtonManagePages} fullWidth
                            onClick={() => dispatch(enableFlagMachineEventReason())}
                            disabled={loading || !machineEvent || !machineEventTypeReasons || machineEventTypeReasons.length===0 || process.status.id >= 4 || serverNotResponding}
                          >
                            {customLabel("button.justifyEvent")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {vars && vars.length > 0 ? (
                      <TableRow>
                        <TableCell>
                          <Button variant="contained" color="primary" fullWidth className={customClasses.actionButtonManagePages}
                            disabled={loading || process.startDate == null || process.endDate != null || serverNotResponding} onClick={() => dispatch(enableFlagVars())}
                          >
                            {customLabel("button.declare")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {availableButtons(process.buttons).length > 0 ? (
                      <TableRow>
                        <TableCell>
                          <Button variant="contained"
                            className={customClasses.actionButtonManagePages}
                            color="primary" fullWidth onClick={() => dispatch(enableFlagButtons())} disabled={loading || process.endDate != null || serverNotResponding}
                          >
                            {customLabel("button.run")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                </tbody>
              </Table>
            </Grid>
          </Grid >
          {configuration && !configuration.blockingChecks && extChecks && extChecks.length > 0 ?
            <MyAlert key={customLabel("button.insert") + "_" + extChecks[0].name} name={null} message={customLabel("operatorView.onDemandChecksWaitingForValue")}
              type={ALERT_TYPE_WARNING} interaction={true} onCloseAlert={null}
              onClickAlert={() => dispatch(enableFlagExtChecks())} buttonText={customLabel("button.insert")} /> : null}
          <Alerts />
        </React.Fragment>
      ) :
        tabIndex === 1 && process ? (
          <ComponentsPage configuration={configuration} operatorView={true} />
        ) :
          tabIndex === 2 && process ? (
            <OperatorMeasurePage />
          )
            : null
      }
      {process ? (
        <React.Fragment>
          <VarsDialog configuration={configuration} processTypeVar={processTypeVar} renderProgressBar={renderProgressBar} />
          <ChecksDialog presetVarCheckValue={presetVarCheckValue} configuration={configuration} processTypeVar={processTypeVar} renderProgressBar={renderProgressBar} />
          <ButtonsDialog buttons={availableButtons(process.buttons)} />
          <UserEventDialog onConfirm={handleAddProcess}
            existChecks={(process.startDate === null && startChecks && startChecks.length > 0) || (process.startDate !== null && restartChecks && restartChecks.length > 0)} />
          <MachineEventDialog />
          <UserEventsDialog />
          <MachineEventReasonDialog configuration={configuration} />
          <ReloadingAPI presetVarCheckValue={presetVarCheckValue} configuration={configuration} handleOpenUserEvent={handleOpenUserEvent} handleAddProcess={handleAddProcess} />
          {/*           <DialogOperator flag={flagDialog.flagChoosePause} title={customLabel("operatorView.choosePauseProcess")} form={renderPauseProcessContentDialog}
            actions={renderPauseProcessActionsDialog} transparentBackround={false} /> */}
          <DialogOperator flag={flagDialog.flagStopProcess} title={customLabel("function.confirm")} form={renderStopProcessContentDialog}
            actions={renderStopProcessActionsDialog} transparentBackround={false} />
          <ComponentDialog configuration={configuration} />
        </React.Fragment>
      ) : null}
    </Paper>
  );
}

export default ManageProcess;