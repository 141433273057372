import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline';
import { customLabel } from "../utility/customLabel";
import {
  Typography, Paper, Input, InputLabel, FormControl, Button, Container
} from '@material-ui/core';

import { Redirect } from 'react-router-dom'

import { loginSelector, change, loginAPI } from './loginSlice'
import NotificationsPage from "../notifications/NotificationsPage";

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: 'url(/background.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}));

export default function LoginPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(loginSelector);

  const handleChange = event => {
    dispatch(change({ name: event.target.name, value: event.target.value }));
  };
  const onSubmit = event => {
    dispatch(loginAPI(user));
  };

  if (localStorage.getItem('bishop_current_user')) {
    return (
      <Redirect to={"/"} from="/login" />
    )
  };

  return (
    <Container component="main" maxWidth="xs" >
      <CssBaseline />
      <Paper className={classes.paper}>
        <img height="50" alt="ultrafab_logo" src="./logobishop.png" />
        <Typography component="h1" variant="h5">{customLabel("button.logIn")}</Typography>
        <form className={classes.form}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="username">{customLabel("user.username")}</InputLabel>
            <Input name="username" value={user.username} autoFocus onChange={handleChange} />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">{customLabel("user.password")}</InputLabel>
            <Input name="password" value={user.password} type="password" id="password" onChange={handleChange} />
          </FormControl>
          <Button fullWidth type="button" onClick={onSubmit} variant="contained" color="primary" className={classes.submit}>{customLabel("button.signIn")}</Button>
        </form>
      </Paper>
      <NotificationsPage />
    </Container >
  )
}





