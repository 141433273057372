import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  usersSelector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete
} from "./slice";

import { rolesSelector, getListAPI as getRolesAPI } from "../roles/slice";

import { Box } from "@material-ui/core";

import { Details } from "./Details";
import { Form } from "./Form";

const UserPage = ({ match }) => {
  const dispatch = useDispatch();
  const { user, useredit, editmode, deleting, isCreated, isDeleted, loading } = useSelector(usersSelector);
  const { roles } = useSelector(rolesSelector);

  const handleChange = (event, checked) => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      dispatch(change({ name: name, value: value, checked: checked, roles: roles }));
    }
    else {
      dispatch(change({ name: name, value: value, roles: roles }));
    }
  };

  const toggleDelete = (event) => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(useredit));
  };

  const onEdit = () => {
    dispatch(edit());
  };
  const doDelete = () => {
    dispatch(deleteAPI(user));
  };

  const save = () => {
    if (user.id === "new") {
      dispatch(createAPI(useredit));
    } else {
      dispatch(updateAPI(useredit));
    }
  };

  useEffect(() => {
    dispatch(getRolesAPI(0, 100000, "authority", "asc", false));
    const { id } = match.params;
    if (id !== "new") {
      dispatch(getAPI(id));
    } else {
      dispatch(create());
    }
  }, [dispatch, match]);

  const renderUser = () => {
    if (user !== false && !editmode && match.params.id === user.id.toString()) {
      return (
        <Details user={user} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} roles={roles} doDelete={doDelete} loading={loading} />
      );
    }
    if (user !== false && editmode) {
      return (
        <Form useredit={useredit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save} roles={roles} loading={loading} />
      );
    }
    return;
  };

  if (isDeleted && match.params.id === user.id.toString()) {
    return <Redirect to={"/admin/users/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/admin/users/" + user.id} />;
  }

  return <Box>{renderUser()}</Box>;
};

export default UserPage;
