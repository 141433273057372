import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Input,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import React from "react";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";
import { labelStaplingSetupMode, staplingConfigLabels } from "../utility";

const Stapling = ({ configuration, incremental }) => {
    const myClasses = mesStyles();

    const configStapling = configuration.staplingConfig !== null ? configuration.staplingConfig.split(",") : ""
    const labelStaplingSaved = staplingConfigLabels.filter(l => configStapling.includes(l.id)).map(l => customLabel(l.translatedLabel));

    return (
        <Box className={myClasses.confDetailsBox}>
            <form className={myClasses.formContainer} noValidate autoComplete="off">
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="staplingSetupTimeMode">{customLabel("configuration.staplingSetupTimeMode.staplingSetupTimeMode")}</InputLabel>
                    <Select name="staplingSetupTimeMode" value={configuration.staplingSetupTimeMode}>
                        <MenuItem value={labelStaplingSetupMode["sum"]}>{customLabel("configuration.staplingSetupTimeMode.sum")}</MenuItem>
                        <MenuItem value={labelStaplingSetupMode["biggest"]}>{customLabel("configuration.staplingSetupTimeMode.biggest")}</MenuItem>
                    </Select>
                </FormControl><br/>
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={incremental} name="incremental" color="default" />
                    }
                    label={customLabel("configuration.staplingIncrement")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.enableUnstaple} name="enableUnstaple" color="default" />
                    }
                    label={customLabel("configuration.enableUnstaple")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.showSingleOdp} name="showSingleOdp" color="default" />
                    }
                    label={customLabel("configuration.showSingleOdp")}
                />
                <br />
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="labelOdpDetailsOpView">{customLabel("configuration.staplingCode")}</InputLabel>
                    <Input name="labelOdpDetailsOpView" value={labelStaplingSaved.join(", ")} />
                </FormControl>
            </form>
        </Box>
    );
};
export default Stapling;