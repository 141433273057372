import React from 'react'
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { customLabel } from "../utility/customLabel";

/**
 * Mostra il tasto toggleFilter e il tasto aggiungi, se configurato
 * @param {object} params.filter
 * @param {function} params.toggleFilter  
 * @param {boolean} params.showAdd 
 * @param {string} params.businessObject  
 * @returns 
 */
const HeaderTableList = ({ filter, toggleFilter, showAdd, businessObject }) => {
    if (filter) {
        return (
            <React.Fragment>
                <Button color="default" onClick={() => toggleFilter()} >
                    {filter.showFilter ? customLabel("button.hideFilters") : customLabel("button.showFilters")}
                </Button>
                {showAdd ? (
                    <Button component={Link} color="primary" to={`/${businessObject}/new`}>{customLabel("button.add")}</Button>
                ) : null}
            </React.Fragment>
        )
    }
    return null
}

export default HeaderTableList
