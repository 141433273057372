import React from 'react'
import { Typography } from "@material-ui/core";

const LegendPreview = ({ name }) => {
    return (
        <React.Fragment>
            <Typography component="h1" variant="h6" color="inherit">
                {name}
            </Typography>
        </React.Fragment>
    )
}

export default LegendPreview;
