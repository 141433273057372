import React from "react";
import Alert from '@material-ui/lab/Alert';
import { mesStyles } from "../utility/ultrafabStyles";
import Button from '@material-ui/core/Button';

/**
 * Mostra un alert, per l int operatore con due tipi: alert con messaggio (salvati nello slice), alert con azione (non salvati) in base a interaction
 * @param {string} name identificativo del alert nello store, solo se interaction false
 * @param {string} message messaggio del alert 
 * @param {string} type error warning info success
 * @param {boolean} interaction se true mostra un bottone per interagirci altrimenti la x per chiuderlo
 * @param {function} onCloseAlert esiste solo se interaction false
 * @param {function} onClickAlert esiste solo se interaction true
 * @param {string} buttonText testo del bottone, in caso interaction true
 * @returns 
 */
const MyAlert = ({ name, message, type, interaction, onCloseAlert, onClickAlert, buttonText }) => {
    const myClasses = mesStyles();

    return (
        <Alert onClose={!interaction ? () => onCloseAlert(name) : null} severity={type} className={myClasses.operatorAlert} variant="filled"
            action={
                interaction ?
                    <Button color="inherit" size="small" onClick={interaction ? () => onClickAlert() : null}
                        style={{ minWidth: "80px" }}>
                        {buttonText}
                    </Button>
                    : null
            }>
            {message}
        </Alert>
    )
}

export default MyAlert;
