import { createSlice } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../notifications/slice";
import { logout, refreshAPI } from "../main/slice";
import { SERVER_URL } from "../../constants";
import _ from 'lodash';
import { customLabel } from "../utility/customLabel";

export const initialState = {
  loading: false,
  reload: false,
  hasErrors: false,
  editmode: false,
  odp: false,
  odpedit: {
    id: "new", customerOrder: { id: '', code: '', article: { id: '' } }, customer: { id: "", name: "", code: "" }, code: "", extCode: "", article: { id: "", name: "", code: "" },
    bom: { id: "", name: "", code: "" }, quantity: "0", deliveryDate: new Date().getTime(), toBeProcessed: true, maintenance: false,
    note: "", startDate: null
  },
  customerOrderedit: {
    id: "new", name: "", code: "", customer: { id: '' }, quantity: "0", deliveryDate: new Date().getTime(), date: new Date().getTime(),
    note: "", article: { id: "", name: "", code: "" }, startDate: null
  },
  deleting: false,
  isCreated: false,
  isDeleted: false,
  processes: false,
  processVar: false,
  measureTools: false,
  currentProcess: false,
  availableTools: false,
  toolToUnlink: false,
  bomProcesses: false,
};

const smartOdpSlice = createSlice({
  name: "smartOdp",
  initialState,
  reducers: {
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    enableLoading: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    enableErrors: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    setReload: (state) => {
      state.reload = true;
    },
    disableLoading: (state) => {
      state.loading = false;
    },
    reset: (state) => {
      state.odp = false;
      state.odpedit = false;
      state.editmode = false;
      state.isDeleted = false;
      state.isCreated = false;
    },
    creating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    createSuccess: (state, { payload }) => {
      state.odp = false;
      state.odpedit = {
        id: "new", customerOrder: { id: '', code: '', article: { id: '' } }, customer: { id: "", name: "", code: "" }, code: "", extCode: "", article: { id: "", name: "", code: "" },
        bom: { id: "", name: "", code: "" }, quantity: "0", deliveryDate: new Date().getTime(), toBeProcessed: true, maintenance: false,
        note: "", startDate: null, customerOrderCode: ''
      };
      state.customerOrderedit = {
        id: "new", name: "", code: "", customer: { id: '' }, quantity: "0", deliveryDate: new Date().getTime(), date: new Date().getTime(),
        note: "", article: { id: "", name: "", code: "" }, startDate: null
      };
      state.loading = false;
      state.isCreated = payload;
      state.editmode = false;
      state.processes = false;
    },
    cancelIsCreated: (state) => {
      state.isCreated = false;
    },
    resetEdit: (state) => {
      state.editmode = true;
      state.odpedit = state.odp;
      state.loading = false;
      state.hasErrors = true;
    },
    edit: (state) => {
      state.editmode = true;
      state.odpedit = state.odp;
      const date = new Date(state.odpedit.deliveryDate).getTime()
      state.odpedit.deliveryDate = date
    },
    cancelEdit: (state) => {
      state.editmode = false;
      state.odpedit = false;
    },
    change: (state, { payload }) => {
      let u = state.odpedit;
      if (payload.name === "customerOrder.id") {
        let lista = payload.customerOrders.slice();
        let indexOfOrders = lista.findIndex((l) => {
          return l.id === payload.value
        });
        u["customerOrder"] = lista[indexOfOrders]
        let listaCustomers = payload.customers.slice();
        let customerID = u["customerOrder"].customer.id
        let indexOfCustomers = listaCustomers.findIndex((l) => {
          return l.id === customerID
        });
        u["customerOrderCode"] = ""
        u["customer"] = listaCustomers[indexOfCustomers]
        //se l'ordine cliente contiene un articolo lo auto compilo
        if (u["customerOrder"].article.id !== null) {
          let listaArticles = payload.articles.slice();
          let articleId = u["customerOrder"].article.id
          let indexOfArticle = listaArticles.findIndex((l) => {
            return l.id === articleId
          })
          u["article"] = listaArticles[indexOfArticle]
          u["coArticle"] = true
          //se esiste l'articolo e a quel ultimo è associato anche un tipo odp lo compilo
          if (u["customerOrder"].article.bom.id !== null) {
            let listaBoms = payload.boms.slice();
            let bomId = u["article"].bom.id
            let indexOfBoms = listaBoms.findIndex((l) => {
              return l.id === bomId
            });
            u["bom"] = listaBoms[indexOfBoms]
          } else {
            //se esiste articolo ma senza distinta il campo distinta viene azzerato
            u["bom"] = { id: "" }
          }
        }
      } else if (payload.name === "bom.id") {
        let lista = payload.boms.slice();
        let indexOfBoms = lista.findIndex((l) => {
          return l.id === payload.value
        });
        u["bom"] = lista[indexOfBoms]
      } else if (payload.name === "article.id") {
        let lista = payload.articles.slice();
        let indexOfArticles = lista.findIndex((l) => {
          return l.id === payload.value
        })
        u["article"] = lista[indexOfArticles]
        if (lista[indexOfArticles].bom.id != null) {
          let lista3 = payload.boms.slice();
          let indexOfBoms = lista3.findIndex((l) => {
            return l.id === lista[indexOfArticles].bom.id
          });
          u["bom"] = lista3[indexOfBoms]
        }
      } else {
        u[payload.name] = payload.value;
      }
      state.odpedit = u;
    },
    changeObject: (state, { payload }) => {
      let u = state.odpedit;
      if (payload.name === "customerOrder.id") {
        u["customerOrder"] = { id: "" }
        u["customer"] = { id: "" }
      } else if (payload.name === "bom.id") {
        u["bom"] = { id: "" }
      } else if (payload.name === "article.id") {
        u["article"] = { id: "", name: "" };
        u["bom"] = { id: "", name: "", code: "" };
      }
      state.odpedit = u;
    },
    changeCO: (state, { payload }) => {
      let u = state.customerOrderedit;
      if (payload.name === "customer.id") {
        let lista = payload.customers.slice();
        let indexOfCustomers = lista.findIndex((l) => {
          return l.id === payload.value
        });
        u["customer"] = lista[indexOfCustomers]
      } else {
        u[payload.name] = payload.value;
      }
      state.customerOrderedit = u;
    },
    changeObjectCO: (state, { payload }) => {
      let u = state.customerOrderedit;
      u["customer"] = { id: "" }
      state.customerOrderedit = u;
    },
    preAddProcesses: (state, { payload }) => {
      state.processes = payload.bomProcesses
      state.bomProcesses = payload.bomProcesses
    },
    onChangeSort: (state, { payload }) => {
      state.processes = [...payload];
    },
    addProcess: (state, { payload }) => {
      let item = _.cloneDeep(payload)
      item["sortOrder"] = state.processes.length
      state.processes.push(item);
    },
    removeProcess: (state, { payload }) => {
      let processes = _.cloneDeep(state.processes)
      let index = processes.findIndex(v => {
        return v.sortOrder === payload.sortOrder;
      });
      state.processes = [...processes.filter((_, i) => i !== index)];
    },
    addVar: (state, { payload }) => {
      state.processVar = {
        'name': '', 'type': '', process: payload, "multiple": false, "operatorEdit": true, "adminEdit": true, "editable": false, "incremental": false, "operatorView": true, "serial": false,
        measure: ""
      }
    },
    cancelAddVar: (state) => {
      state.processVar = false
    },
    removeVar: (state, { payload }) => {
      let p = _.cloneDeep(payload.process)
      let processes = _.cloneDeep(state.processes)
      let index = processes.findIndex(v => {
        return v.sortOrder === payload.process.sortOrder;
      });
      let vars = _.cloneDeep(p.vars)
      let indexVar = vars.findIndex(v => {
        return v.name === payload.var.name;
      });
      vars = [...vars.filter((_, i) => i !== indexVar)];
      p.vars = [...vars]
      processes[index] = p
      state.processes = [...processes]
    },
    saveVar: (state) => {
      let p = _.cloneDeep(state.processVar.process)
      let processes = _.cloneDeep(state.processes)
      let index = processes.findIndex(v => {
        return v.sortOrder === p.sortOrder;
      });
      let vars = _.cloneDeep(p.vars)
      vars.push(state.processVar)
      p.vars = [...vars]
      processes[index] = p
      state.processes = [...processes]
      state.processVar = false
    },
    changeVar: (state, { payload }) => {
      let v = state.processVar;
      v[payload.name] = payload.value;
      state.processVar = v
    },
    setOdp: (state) => {
      let odp = state.odpedit
      if (odp.customerOrder.id === '') {
        odp["customerOrder"] = state.customerOrderedit
        odp["customer"] = state.customerOrderedit.customer
      }
      odp["processes"] = [...state.processes]
      state.odp = odp
    },
    sortVars: (state, { payload }) => {
      let p = _.cloneDeep(payload.process)
      let processes = _.cloneDeep(state.processes)
      let index = processes.findIndex(v => {
        return v.sortOrder === p.sortOrder;
      });
      let vars = _.cloneDeep(payload.vars)
      p.vars = [...vars]
      processes[index] = p
      state.processes = [...processes]
    },
    /**
     * Viene usato per impostare il currentProccess quando si clicca su Collega, viene impostato anche l'oggetto availableTools
     * @param {object} payload.process oggetto process selezionato
     * @param {*} payload.measureTools lista degli strumenti di misura per selezionare quello disponibile
     */
    setCurrentProcessForLinking: (state, { payload }) => {
      state.currentProcess = payload.process;
      const availableMeasureTools = payload.measureTools.filter((w) =>
        payload.process.measureTools.every((pw) => w.id !== pw.measureTool_id)
      );
      state.availableTools = availableMeasureTools;
    },
    confirmLink: (state, { payload }) => {
      for (let i = 0; i < payload.length; i++) {
        const newTool = { code: payload[i].code, name: payload[i].name, serial: payload[i].serial, measureTool_id: payload[i].id }
        state.currentProcess.measureTools.push(newTool);
      }
      //aggiorno il process in processes
      let processes = _.cloneDeep(state.processes);
      const indexOfProcess = processes.findIndex((p) => {
        return p.sortOrder.toString() === state.currentProcess.sortOrder.toString();
      })
      if (indexOfProcess !== -1) {
        processes[indexOfProcess] = state.currentProcess;
      }
      state.processes = processes;
      //svuoto oggetti utilizzati per linking
      state.currentProcess = false;
      state.availableTools = false;
    },
    /**
     * Viene usato per impostare il currentProccess quando si clicca su Scollega, imposta il currentProcess e il measureTool da eliminare
     * @param {object} payload.process oggetto process selezionato
     * @param {*} payload.measureTool oggetto strumento di misura da scollegare
     */
    setCurrentProcessForUnlinking: (state, { payload }) => {
      state.currentProcess = payload.process;
      state.toolToUnlink = payload.measureTool;
    },
    confirmUnlink: (state, { payload }) => {
      const toolToUnlink = _.cloneDeep(state.toolToUnlink);
      let currentProcess = _.cloneDeep(state.currentProcess);

      const indexOfTool = currentProcess.measureTools.findIndex((i) => {
        return i.measureTool_id.toString() === toolToUnlink.measureTool_id.toString()
      });
      if (indexOfTool !== -1) {
        currentProcess.measureTools.splice(indexOfTool, 1);
      }
      state.currentProcess = currentProcess;
      //aggiorno il process in processes
      let processes = _.cloneDeep(state.processes);
      const indexOfProcess = processes.findIndex((p) => {
        return p.sortOrder.toString() === currentProcess.sortOrder.toString();
      })
      if (indexOfProcess !== -1) {
        processes[indexOfProcess] = currentProcess;
      }
      state.processes = processes;
      //svuoto oggetti utilizzati per linking
      state.currentProcess = false;
      state.toolToUnlink = false;
    },
    unsetCurrentProcess: (state, { payload }) => {
      state.currentProcess = false;
      state.availableTools = false;
      state.toolToUnlink = false;
    },
  }
});

export const { resetState, setReload, reset, getting, create, creating, createSuccess, edit, cancelEdit, change,
  changeObject, enableErrors, preAddProcesses, onChangeSort, addProcess, removeProcess, addVar, cancelAddVar,
  changeObjectCO, changeCO, removeVar, saveVar, changeVar, setOdp, cancelIsCreated, sortVars, setCurrentProcessForUnlinking,
  setCurrentProcessForLinking, unsetCurrentProcess, confirmLink, confirmUnlink } = smartOdpSlice.actions;

export const odpSmartSelector = (state) => state.smartOdp;

export default smartOdpSlice.reducer;

export function createAPI(odp) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(creating());
    let params = { odp: odp, createBom: false };
    const response = await fetch(SERVER_URL + "/api/odp/smart", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}