import {
    Box, Checkbox,
    FormControl,
    FormControlLabel,
    Input, InputLabel
} from "@material-ui/core";
import React from "react";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";

const Components = ({ configuration }) => {
    const myClasses = mesStyles();

    return (
        <Box className={myClasses.confDetailsBox}>
            <form className={myClasses.formContainer} noValidate autoComplete="off">
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.enableComponents} name="enableComponents" color="default" />
                    }
                    label={customLabel("configuration.enableComponents")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.verifyComponentLot} name="verifyComponentLot" color="default" />
                    }
                    label={customLabel("configuration.verifyComponentLot")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.enableFreeLotComponents} name="enableFreeLotComponents" color="default" />
                    }
                    label={customLabel("configuration.enableFreeLotComponents")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.scrapReasonComponents} name="scrapReasonComponents" color="default" />
                    }
                    label={customLabel("configuration.scrapReasonComponents")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.changeComponent} name="changeComponent" color="default" />
                    }
                    label={customLabel("configuration.changeComponent")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.forceComponent} name="forceComponent" color="default" />
                    }
                    label={customLabel("configuration.forceComponent")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.forceWithVerifyComponent} name="forceWithVerifyComponent" color="default" />
                    }
                    label={customLabel("configuration.forceWithVerifyComponent")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.editComponent} name="editComponent" color="default" />
                    }
                    label={customLabel("configuration.editComponent")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.hideComponentLot} name="hideComponentLot" color="default" />
                    }
                    label={customLabel("configuration.hideComponentLot")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.showLotRemainingQuantity} name="showLotRemainingQuantity" color="default" />
                    }
                    label={customLabel("configuration.showLotRemainingQuantity")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.showCloseLot} name="showCloseLot" color="default" />
                    }
                    label={customLabel("configuration.showCloseLot")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.componentUseAlwaysFactor} name="componentUseAlwaysFactor" color="default" />
                    }
                    label={customLabel("configuration.componentUseAlwaysFactor")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.retrieveLotInfo} name="retrieveLotInfo" color="default" />
                    }
                    label={customLabel("configuration.componentRetrieveLotInfo")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.insertArticleIfForcedLot} name="insertArticleIfForcedLot" color="default" />
                    }
                    label={customLabel("configuration.insertArticleIfForcedLot")}
                />
                <br />
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="componentUrl">{customLabel("configuration.componentUrl")}</InputLabel>
                    <Input value={configuration.componentUrl !== "null" && configuration.componentUrl !== null ? configuration.componentUrl : ""} />
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="componentDecimals">{customLabel("configuration.decimals")}</InputLabel>
                    <Input value={configuration.componentDecimals ? configuration.componentDecimals : ""} />
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="componentPercentualeOk">{customLabel("configuration.percentualeOk")}</InputLabel>
                    <Input value={configuration.componentPercentualeOk ? configuration.componentPercentualeOk : ""} />
                </FormControl>
            </form>
        </Box>
    );
};
export default Components;