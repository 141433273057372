import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaperAutocomplete from "../utility/PaperAutocomplete";
import { customLabel } from "../utility/customLabel";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import { addReasonsAPI, getAPI, machineEventsSelector, removeReasonAPI } from "./slice";

const ReasonsPage = ({ machineEvent, extLoading }) => {
    const dispatch = useDispatch();
    const myClasses = mesStyles();
    const { loading, reload, machineEvents } = useSelector(machineEventsSelector);
    const [removeReason, setRemoveReason] = React.useState(false);
    const [linking, setLinking] = React.useState(false);
    const [wl, setWl] = React.useState([]);

    useEffect(() => {
        if (reload === true && loading === false) {
            dispatch(getAPI(machineEvent.id));
        }
    }, [dispatch, reload, loading, machineEvent])

    const onUnlick = (w) => {
        setRemoveReason(w);
    };

    const cancelUnlink = () => {
        setRemoveReason(false);
    };

    const doUnlink = () => {
        dispatch(removeReasonAPI(machineEvent, removeReason));
        setRemoveReason(false);
    };

    const onLink = () => {
        setLinking(true);
    };

    const cancelLink = () => {
        setLinking(false);
        setWl([]);
    };

    const doLink = () => {
        dispatch(addReasonsAPI(machineEvent, wl));
        setLinking(false);
    };

    if (machineEvents !== false) {
        const availableReasons = machineEvents.filter((w) =>
            machineEvent.reasons.every((pw) => w.id !== pw.reason_id)
        );

        return (
            <PaperForm>
                <div className={myClasses.titleFormSpaceBetween}>
                    <Typography component="h1" variant="h6" color="inherit">{customLabel("machineEvent.reasons")}</Typography>
                </div>
                <div className={myClasses.tableContainer}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{customLabel("machineEvent.name")}</TableCell>
                                <TableCell align="right" style={{ width: '250px' }}>
                                    <Button color="primary" onClick={onLink} disabled={loading || extLoading}>{customLabel("button.link")}</Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {machineEvent.reasons.map((w) => {
                                return (
                                    <TableRow key={w.id}>
                                        <TableCell>{w.name}</TableCell>
                                        <TableCell align="right" className={myClasses.tableCell1Button}>
                                            <Button onClick={() => { onUnlick(w) }} color="secondary" disabled={loading || extLoading}>
                                                {customLabel("button.unlink")}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                    <Dialog open={removeReason !== false} keepMounted>
                        <DialogTitle>{customLabel("function.confirm").toUpperCase()}</DialogTitle>
                        <DialogContent><DialogContentText>{customLabel("function.confirmUnlink")}</DialogContentText></DialogContent>
                        <DialogActions>
                            <Button disabled={loading || extLoading} onClick={doUnlink} color="primary">{customLabel("button.confirm")}</Button>
                            <Button disabled={loading || extLoading} onClick={cancelUnlink} color="default">{customLabel("button.cancel")}</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={linking} keepMounted fullWidth>
                        <DialogTitle>{customLabel("machineEvent.linkReasons")}</DialogTitle>
                        <DialogContent>
                            <Autocomplete
                                PaperComponent={PaperAutocomplete}
                                value={wl} onChange={(event, newValue) => { setWl(newValue) }} multiple options={availableReasons}
                                getOptionLabel={(option) => option.name} filterSelectedOptions
                                renderInput={(params) => (<TextField {...params} label={customLabel("machineEvent.reason")} error={wl.length === 0} />)} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={doLink} color="primary" disabled={wl.length === 0 || loading || extLoading}>{customLabel("button.confirm")}</Button>
                            <Button disabled={loading || extLoading} onClick={cancelLink} color="default">{customLabel("button.close")}</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </PaperForm>
        );
    }
    return null;
};

export default ReasonsPage;
