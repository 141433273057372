import React from 'react';
import { Grid, } from "@material-ui/core";

const InfoProcesses = ({ odp, renderDetailsBom, renderHome }) => {

    return (
        <React.Fragment>
            {odp && odp.id !== "new" ? (
                <Grid container spacing={1} alignItems="flex-start" justify="center">
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        {renderDetailsBom()}
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        {renderHome()}
                    </Grid>
                </Grid>) : null}
        </React.Fragment>
    )
};

export default InfoProcesses;
