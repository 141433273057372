import React from "react";
import { Link } from "react-router-dom";
import {
  Checkbox, FormControlLabel, Typography, FormControl, InputLabel, Input, Divider, Button, TextField
} from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockIcon from '@material-ui/icons/Lock';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";

export const Details = ({ bom, edit, toggleDelete, deleting, doDelete, loading }) => {
  const myClasses = mesStyles();

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography className={myClasses.note} component="h1" variant="h6" color="inherit">
          {customLabel("bom.bom")}
        </Typography>
        <LockIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl disabled className={myClasses.form50}>
          <InputLabel margin="dense" htmlFor="code">{customLabel("bom.code")}</InputLabel>
          <Input value={bom.code} />
        </FormControl>
        <FormControl disabled className={myClasses.form50}>
          <InputLabel margin="dense" htmlFor="name">{customLabel("bom.name")}</InputLabel>
          <Input value={bom.name} />
        </FormControl>
        <FormControl disabled className={myClasses.form100}>
          <TextField
            disabled variant="outlined" name="note" multiline label={customLabel("function.note")} rows={4}
            value={bom.note}
          />
        </FormControl>
        <FormControlLabel disabled control={<Checkbox checked={bom.lockProcesses} color="default" />}
          label={customLabel("bom.lockProcesses")}
        />
        <FormControlLabel disabled control={<Checkbox checked={bom.autoClose} color="default" />}
          label={customLabel("bom.autoClose")}
        />
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple key={bom.id} onClick={edit} color="primary" disabled={loading}>
          {customLabel("button.edit")}
        </Button>
        <Button color="secondary" onClick={toggleDelete} disabled={loading}>
          {customLabel("button.delete")}
        </Button>
        <Button component={Link} to={`/admin/boms`} disabled={loading}>
          {customLabel("button.back")}
        </Button>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={toggleDelete} disabled={loading} />
    </PaperForm>
  );
};
