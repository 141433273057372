import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Input,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";
import DragDrop from "../DragDrop";
import { change } from "../slice";
import { labelStaplingSetupMode, renderLabelStaplingSetupMode, staplingConfigLabels } from "../utility";

const Stapling = ({ configurationedit, handleChange, incremental }) => {
    const myClasses = mesStyles();
    const dispatch = useDispatch()

    let labelsSaved = configurationedit.staplingConfig?.split(',').slice(1).join(',') ?? ""

    const onChange = (data) => {
        dispatch(change(data))
    }
    console.log(renderLabelStaplingSetupMode("um"))
    return (
        <div className={myClasses.dialogForm500}>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="staplingSetupTimeMode">{customLabel("configuration.staplingSetupTimeMode.staplingSetupTimeMode")}</InputLabel>
                <Select name="staplingSetupTimeMode" value={configurationedit.staplingSetupTimeMode} onChange={handleChange}>
                    <MenuItem value={labelStaplingSetupMode["sum"]}>{customLabel("configuration.staplingSetupTimeMode.sum")}</MenuItem>
                    <MenuItem value={labelStaplingSetupMode["biggest"]}>{customLabel("configuration.staplingSetupTimeMode.biggest")}</MenuItem>
                </Select>
            </FormControl><br/>
            <FormControlLabel disabled
                control={
                    <Checkbox checked={incremental} name="incremental" color="default" />
                }
                label={customLabel("configuration.staplingIncrement")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.enableUnstaple} onChange={handleChange} name="enableUnstaple" color="default" />
                }
                label={customLabel("configuration.enableUnstaple")}
            />
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.showSingleOdp} onChange={handleChange} name="showSingleOdp" color="default" />
                }
                label={customLabel("configuration.showSingleOdp")}
            />
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="staplingName">{customLabel("configuration.staplingName")}</InputLabel>
                <Input name="staplingName" type="text"
                    error={configurationedit.staplingName === ""} value={configurationedit.staplingConfig !== null ? configurationedit.staplingConfig.split(',')[0] : ""}
                    onChange={handleChange} />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="staplingCharSeparator">{customLabel("configuration.staplingCharSeparator")}</InputLabel>
                <Input name="staplingCharSeparator" type="text"
                    error={configurationedit.staplingCharSeparator === ""} value={configurationedit.staplingCharSeparator !== null ? configurationedit.staplingCharSeparator : ""}
                    onChange={handleChange} />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="staplingIncrPositions">{customLabel("configuration.staplingIncrPositions")}</InputLabel>
                <Input name="staplingIncrPositions" type="number"
                    error={configurationedit.staplingIncrPositions === "" || configurationedit.staplingIncrPositions < 0} value={configurationedit.staplingIncrPositions !== null ? configurationedit.staplingIncrPositions : ""}
                    onChange={handleChange} />
            </FormControl>
            <DragDrop labelsSaved={labelsSaved} labelsList={staplingConfigLabels}
                name="staplingConfig" labelType={customLabel("configuration.staplingCode")} change={onChange} />
        </div >
    );
};
export default Stapling;