import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { processTypesSelector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete } from './slice'
import { Box } from "@material-ui/core";
import { Form } from './Form'
import { Details } from './Details'
import MachinesPage from './MachinesPage'
import UserEventTypesPage from './UserEventTypesPage'
import RolesPage from './RolesPage'
import ChecksPage from './ChecksPage'
import VarsPage from './VarsPage'
import DocumentsPage from '../documents/DocumentsPage';
import MeasureToolsPage from './MeasureToolsPage';
import { PaperForm } from "../utility/ultrafabStyles";
import { configurationSelector } from '../configuration/slice';

const ProcessTypePage = ({ match }) => {
  const dispatch = useDispatch()
  const { processType, processTypeedit, editmode, deleting, isCreated, isDeleted, processTypes, loading, reload } = useSelector(processTypesSelector)
  const { configuration } = useSelector(configurationSelector)

  const handleChange = event => {
    let name = event.target.name
    let value = event.target.value
    if (event.target.type === "checkbox") {
      value = event.target.checked
    }
    dispatch(change({ name: name, value: value }));
  };
  const toggleDelete = event => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(processTypeedit))
  }

  const onEdit = () => {
    dispatch(edit())
  }
  const doDelete = () => {
    dispatch(deleteAPI(processType))
  }

  const save = () => {
    if (processType.id === "new") {
      dispatch(createAPI(processTypeedit));
    } else {
      dispatch(updateAPI(processTypeedit));
    }
  };

  useEffect(() => {
    const { id } = match.params
    if (id !== "new") {
      dispatch(getAPI(id))
    } else {
      dispatch(create())
    }
  }, [dispatch, match])

  useEffect(() => {
    if (reload === true && loading === false) {
      dispatch(getAPI(processType.id));
    }
  }, [dispatch, reload, loading, processType])


  const renderProcessType = () => {
    if (processType !== false && !editmode && match.params.id === processType.id.toString()) {
      return (
        <div>
          <Details processType={processType} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete} maintenance={false}
            loading={loading} />
          <VarsPage processType={processType} maintenance={false} loading={loading} />
          <MachinesPage processType={processType} loading={loading} />
          <ChecksPage processType={processType} maintenance={false} loading={loading} configuration={configuration} />
          {configuration.showUserEvents ? (
            <UserEventTypesPage processType={processType} loading={loading} />
          ) : null}
          <RolesPage processType={processType} loading={loading} />
          <MeasureToolsPage processType={processType} loading={loading} />
          <PaperForm>
            <DocumentsPage parentId={processType.id} parentType="process_types" extLoading={loading} title={true} size="small" />
          </PaperForm>
        </div>
      )
    }
    if (processType !== false && editmode) {
      return <Form processTypeedit={processTypeedit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save} processTypes={processTypes}
        maintenance={false} loading={loading} />
    }
    return
  }

  if (isDeleted && match.params.id === processType.id.toString()) {
    return (<Redirect to={"/admin/processTypes/"} />)
  }

  if (isCreated) {
    return (<Redirect to={"/admin/processTypes/" + processType.id} />)
  }

  return (
    <Box>
      {renderProcessType()}
    </Box>
  )
}

export default ProcessTypePage
