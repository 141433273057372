import React from 'react';
import { Grid, Dialog, DialogContent, Paper } from "@material-ui/core";
import ListProcesses from "./stapling/ListProcesses";
import { mesStyles } from "../utility/ultrafabStyles";

const InfoOdp = ({ odp, renderProductionData,
    editmode, odpedit, renderNewDetailsBom, renderDetailsOdp, renderFormOdp, renderFormMaintenance }) => {
    const myClasses = mesStyles()

    return (
        <React.Fragment>
            {(odp && odp.id !== "new") ? (
                <Grid container spacing={1} alignItems="flex-start" justify="center">
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        {renderDetailsOdp()}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        {odp && !odp.stapling ? renderNewDetailsBom() : <ListProcesses />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Paper className={myClasses.odpDetailsPaperMD}>
                            {renderProductionData()}
                        </Paper>
                    </Grid>
                </Grid>
            ) : null}
            <Dialog open={editmode} maxWidth={"xl"} fullWidth keepMounted>
                <DialogContent>
                    {odpedit && !odpedit.maintenance ? (
                        renderFormOdp()
                    ) : odpedit && odpedit.maintenance ? (
                        renderFormMaintenance()
                    ) : null}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
};

export default InfoOdp;
