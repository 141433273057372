import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { removeProcessTypeAPI, addProcessTypesAPI } from "./slice";
import { processTypesSelector, getListAPI as getProcessTypesAPI } from "../processTypes/slice";
import { Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import PaperAutocomplete from "../utility/PaperAutocomplete";

const ProcessTypesPage = ({ button, loading }) => {
    const dispatch = useDispatch();
    const myClasses = mesStyles();
    const { processTypes } = useSelector(processTypesSelector);
    const [removeProcessType, setRemoveProcessType] = React.useState(false);
    const [linking, setLinking] = React.useState(false);
    const [wl, setWl] = React.useState([]);

    useEffect(() => {
        dispatch(getProcessTypesAPI(0, 100000, "name", "asc", false, false));
    }, [dispatch]);

    const onUnlick = (w) => {
        setRemoveProcessType(w);
    };

    const cancelUnlink = () => {
        setRemoveProcessType(false);
    };

    const doUnlink = () => {
        dispatch(removeProcessTypeAPI(button, removeProcessType));
        setRemoveProcessType(false);
    };

    const onLink = () => {
        setLinking(true);
    };

    const cancelLink = () => {
        setLinking(false);
        setWl([]);
    };

    const doLink = () => {
        dispatch(addProcessTypesAPI(button, wl));
        setLinking(false);
    };

    if (processTypes !== false) {
        const availableProcessTypes = processTypes.filter((w) =>
            button.processTypes.every((pw) => w.id !== pw.processTypeId)
        );

        return (
            <PaperForm>
                <div className={myClasses.titleFormSpaceBetween}>
                    <Typography component="h1" variant="h6" color="inherit">{customLabel("processType.processTypes")}</Typography>
                </div>
                <div className={myClasses.tableContainer}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{customLabel("processType.code")}</TableCell>
                                <TableCell>{customLabel("processType.name")}</TableCell>
                                <TableCell>{customLabel("processType.multiple")}</TableCell>
                                <TableCell>{customLabel("processType.autostart")}</TableCell>
                                <TableCell>{customLabel("processType.external")}</TableCell>
                                <TableCell className={myClasses.tableCell1Button} align="right">
                                    <Button color="primary" onClick={onLink} disabled={loading}>{customLabel("button.link")}</Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {button.processTypes.map((w) => {
                                return (
                                    <TableRow key={w.id}>
                                        <TableCell>{w.code}</TableCell>
                                        <TableCell>{w.name}</TableCell>
                                        <TableCell>{w.multiple}</TableCell>
                                        <TableCell>{w.autostart}</TableCell>
                                        <TableCell>{w.external}</TableCell>
                                        <TableCell align="right" className={myClasses.tableCell1Button}>
                                            <Button onClick={() => { onUnlick(w) }} disabled={loading} color="secondary">
                                                {customLabel("button.unlink")}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                    <Dialog open={removeProcessType !== false} keepMounted>
                        <DialogTitle>{customLabel("function.confirm").toUpperCase()}</DialogTitle>
                        <DialogContent><DialogContentText>{customLabel("function.confirmUnlink")}</DialogContentText></DialogContent>
                        <DialogActions>
                            <Button onClick={doUnlink} disabled={loading} color="primary">{customLabel("button.confirm")}</Button>
                            <Button onClick={cancelUnlink} disabled={loading} color="default">{customLabel("button.cancel")}</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={linking} keepMounted fullWidth>
                        <DialogTitle>{customLabel("processType.linkProcessTypes")}</DialogTitle>
                        <DialogContent>
                            <Autocomplete
                                PaperComponent={PaperAutocomplete}
                                value={wl} onChange={(event, newValue) => { setWl(newValue) }} multiple options={availableProcessTypes}
                                getOptionLabel={(option) => option.code + " - " + option.name} filterSelectedOptions
                                renderInput={(params) => (<TextField {...params} label={customLabel("processType.process")} error={wl.length === 0} />)} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={doLink} color="primary" disabled={wl.length === 0 || loading}>{customLabel("button.confirm")}</Button>
                            <Button onClick={cancelLink} disabled={loading} color="default">{customLabel("button.close")}</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </PaperForm >
        );
    }
    return null;
};

export default ProcessTypesPage;