import { customLabel } from "../utility/customLabel";

export const labelTimeline = {
    odpCode: "odpCode",
    odpProcessTypeCode: "odpProcessTypeCode",
    odpProcessTypeName: "odpProcessTypeName",
    odpCustomerCode: "odpCustomerCode",
    odpCustomerName: "odpCustomerName",
    odpArticleCode: "odpArticleCode",
    odpArticleName: "odpArticleName"
}

export const labelStaplingSetupMode = {
    sum: "sum",
    biggest: "biggest"
}
export const labelTimelineMachines = {
    name: "name",
    code: "code"
}
export const labelTimelineWorkcentres = {
    name: "name",
    code: "code"
}

export const labelArticleOpView = {
    code: "code",
    codeName: "codeName",
    name: "name"
}

export const labelWorkcentreOpView = {
    code: "code",
    codeName: "codeName",
    name: "name"
}

export const labelMachineOpView = {
    code: "code",
    codeName: "codeName",
    name: "name"
}

export const labelOperatorOpView = {
    code: "code",
    codeUsername: "codeUsername",
    name: "name"
}

export const labelOdpOpView = {
    odpCode: "odpCode",
    odpCodeExtCode: "odpCodeExtCode",
    orderCodeOdpCode: "orderCodeOdpCode",
    orderCodeOdpCodeExtCode: "orderCodeOdpCodeExtCode",
    odpCodeCustomerName: "odpCodeCustomerName"
}

export const tabIndexConfig = {
    company: 0,
    evolution: 1,
    general: 2,
    operatorView: 3,
    components: 4,
    timeline: 5,
    planner: 6,
    report: 7,
    stapling: 8,
    labels: 9
}
/**
 * Restituisce il parametro da affiancare ad odpCode negli oggetti della timeline
 * @param {string} label etichetta 
 * @returns false se label e odpCode quindi nessun altra stringa, altrimenti ritorna la stringa corrispondente alla label
 */
export const renderLabelTimeline = (label) => {
    switch (label) {
        case labelTimeline["odpCode"]: return customLabel("configuration.labelTimeline.odpCode");
        case labelTimeline["odpProcessTypeCode"]: return customLabel("configuration.labelTimeline.odpProcessTypeCode")
        case labelTimeline["odpProcessTypeName"]: return customLabel("configuration.labelTimeline.odpProcessTypeName")
        case labelTimeline["odpCustomerCode"]: return customLabel("configuration.labelTimeline.odpCustomerCode")
        case labelTimeline["odpCustomerName"]: return customLabel("configuration.labelTimeline.odpCustomerName")
        case labelTimeline["odpArticleCode"]: return customLabel("configuration.labelTimeline.odpArticleCode")
        case labelTimeline["odpArticleName"]: return customLabel("configuration.labelTimeline.odpArticleName")
        default: return;
    }
}
/**
 * Restituisce il parametro da affiancare ad article nella vista operatore
 * @param {string} label etichetta 
 * @returns stringa corrispondente, nulla se non appartiene all oggetto
 */
export const renderLabelArticleOpView = (label) => {
    switch (label) {
        case labelArticleOpView["code"]: return customLabel("configuration.labelArticleOpView.code");
        case labelArticleOpView["codeName"]: return customLabel("configuration.labelArticleOpView.codeName")
        case labelArticleOpView["name"]: return customLabel("configuration.labelArticleOpView.name")
        default: return;
    }
}
export const renderLabelWorkcentreOpView = (label) => {
    switch (label) {
        case labelWorkcentreOpView["code"]: return customLabel("configuration.labelWorkcentreOpView.code");
        case labelWorkcentreOpView["codeName"]: return customLabel("configuration.labelWorkcentreOpView.codeName")
        case labelWorkcentreOpView["name"]: return customLabel("configuration.labelWorkcentreOpView.name")
        default: return;
    }
}
export const renderLabelMachineOpView = (label) => {
    switch (label) {
        case labelMachineOpView["code"]: return customLabel("configuration.labelMachineOpView.code");
        case labelMachineOpView["codeName"]: return customLabel("configuration.labelMachineOpView.codeName")
        case labelMachineOpView["name"]: return customLabel("configuration.labelMachineOpView.name")
        default: return;
    }
}
export const renderLabelOperatorOpView = (label) => {
    switch (label) {
        case labelOperatorOpView["code"]: return customLabel("configuration.labelOperatorOpView.code");
        case labelOperatorOpView["codeUsername"]: return customLabel("configuration.labelOperatorOpView.codeUsername")
        case labelOperatorOpView["name"]: return customLabel("configuration.labelOperatorOpView.name")
        default: return;
    }
}
/**
 * Restituisce il parametro che esce nella lista commesse, nell'interfaccia operatore
 * @param {string} label etichetta 
 * @returns stringa corrispondente, nulla se non appartiene all oggetto
 */
export const renderLabelOdpOpView = (label) => {
    switch (label) {
        case labelOdpOpView["odpCode"]: return customLabel("configuration.labelOdpOpView.odpCode");
        case labelOdpOpView["odpCodeExtCode"]: return customLabel("configuration.labelOdpOpView.odpCodeExtCode");
        case labelOdpOpView["orderCodeOdpCode"]: return customLabel("configuration.labelOdpOpView.orderCodeOdpCode");
        case labelOdpOpView["orderCodeOdpCodeExtCode"]: return customLabel("configuration.labelOdpOpView.orderCodeOdpCodeExtCode");
        case labelOdpOpView["odpCodeCustomerName"]: return customLabel("configuration.labelOdpView.odpCodeCustomerName");
        default: return;
    }
}
/**
 * Restituisce il parametro da usare per le macchine nella timeline
 * @param {string} label etichetta 
 * @returns stringa corrispondente, nulla se non appartiene all oggetto
 */
export const renderLabelTimelineMachines = (label) => {
    switch (label) {
        case labelTimelineMachines["code"]: return customLabel("configuration.labelTimelineMachines.code");
        case labelTimelineMachines["name"]: return customLabel("configuration.labelTimelineMachines.name");
        default: return;
    }
}
export const renderLabelStaplingSetupMode = (label) => {
    switch (label) {
        case labelStaplingSetupMode["sum"]: return customLabel("configuration.staplingSetupTimeMode.sum");
        case labelStaplingSetupMode["biggest"]: return customLabel("configuration.staplingSetupTimeMode.biggest");
        default: return;
    }
}

// labels per componente DragDrop
export const odpOpViewLabels = [
    {
        id: 'code',
        translatedLabel: "configuration.labelOdpOpView.odpCode"
    },
    {
        id: 'extCode',
        translatedLabel: "configuration.labelOdpOpView.extCode"
    },
    {
        id: 'customer.code',
        translatedLabel: "configuration.labelOdpOpView.customerCode"
    },
    {
        id: 'customer.name',
        translatedLabel: "configuration.labelOdpOpView.customerName"
    },
    {
        id: 'customerOrder.code',
        translatedLabel: 'configuration.labelOdpOpView.customerOrderCode'
    },
    {
        id: 'article.code',
        translatedLabel: "configuration.labelOdpOpView.articleCode"
    },
    {
        id: 'article.name',
        translatedLabel: "configuration.labelOdpOpView.articleName"
    },
    {
        id: 'priority',
        translatedLabel: "configuration.labelOdpOpView.priority"
    }
]
// labels per componente DragDrop
export const odpDetailsOpViewLabels = [
    {
        id: 'current.status.name',
        translatedLabel: "configuration.labelOdpDetailsOpView.status",
        date: false
    },
    {
        id: 'deliveryDate',
        translatedLabel: "configuration.labelOdpDetailsOpView.deliveryDate",
        date: true
    },
    {
        id: 'article.code',
        translatedLabel: "configuration.labelOdpDetailsOpView.articleCode",
        date: false

    },
    {
        id: 'article.extCode',
        translatedLabel: "configuration.labelOdpDetailsOpView.articleExtCode",
        date: false

    },
    {
        id: 'article.name',
        translatedLabel: "configuration.labelOdpDetailsOpView.articleName",
        date: false
    },
    {
        id: 'current.startDate',
        translatedLabel: "configuration.labelOdpDetailsOpView.startDate",
        date: true
    },
    {
        id: 'quantity',
        translatedLabel: "configuration.labelOdpDetailsOpView.quantity",
        date: false
    }
]

export const staplingConfigLabels = [
    {
        id: '$machine.code',
        translatedLabel: "configuration.machineCode"
    },
    {
        id: '$type.name',
        translatedLabel: "configuration.processType"
    },
    {
        id: 'YEAR',
        translatedLabel: "configuration.staplingYear"
    },
]