import React from "react";
import { useSelector } from "react-redux";
import { machineViewSelector } from "./slice";
import moment from "moment";
import { bishopFormats } from '../../constants';
import { customLabel } from "../utility/customLabel";
import { configurationSelector } from "../configuration/slice";
import { operatorViewSelector } from "../operatorView/slice";

import { Paper, Table, TableRow, TableCell, AppBar, Tabs, Tab, TableHead, TableBody } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import LoadingBar from "../utility/LoadingBar";

const useStyles = makeStyles((theme) => ({
    main: {
        margin: "5px",
        minHeight: "710px",
        maxHeight: "710px",
        overflow: "auto"
    },
    alarm: {
        color: theme.palette.secondary.main,
        fontWeight: "bold"
    }
}));

const Odps = ({ handlePopoverOpenText, handlePopoverCloseText }) => {
    const classes = useStyles();
    const { configuration } = useSelector(configurationSelector);
    const { lastOdps, nextOdps, nextMaintenances, loading, loadingNextOdps, loadingLastOdps } = useSelector(machineViewSelector);
    const { oee, oee_availability, oee_efficiency, oee_quality } = useSelector(operatorViewSelector)

    const renderLastOdps = () => {
        //qua ricevo le lavorazioni e non gli odps
        if (lastOdps) {
            return (
                lastOdps.map((o) => {
                    return (
                        <TableRow key={o.id + o.code}>
                            <TableCell onMouseLeave={handlePopoverCloseText} onMouseEnter={(e) => handlePopoverOpenText(e.currentTarget, false, o.odp.code, o.odp.id, "odps")}>
                                {o.code}
                            </TableCell>
                            <TableCell onMouseLeave={handlePopoverCloseText} onMouseEnter={(e) => handlePopoverOpenText(e.currentTarget, false, o.article.code, o.article.id, "articles")}>
                                {o.article.code}
                            </TableCell>
                            <TableCell>{moment(o.endDate).format(bishopFormats.LTS)}</TableCell>
                        </TableRow>
                    )
                })
            )
        } else {
            return (
                <TableRow>
                    <TableCell colSpan={3}>{customLabel("function.notAvailable").toUpperCase()}</TableCell>
                </TableRow>
            )
        }
    };

    const renderNextOdps = () => {
        if (nextOdps) {
            return (
                nextOdps.map((o) => {
                    return (
                        <TableRow key={o.id + o.code}>
                            <TableCell onMouseLeave={handlePopoverCloseText} onMouseEnter={(e) => handlePopoverOpenText(e.currentTarget, false, o.code, o.id, "odps")}>
                                {o.code}
                            </TableCell>
                            <TableCell onMouseLeave={handlePopoverCloseText} onMouseEnter={(e) => handlePopoverOpenText(e.currentTarget, false, o.article.code, o.article.id, "articles")}>
                                {o.article.code}
                            </TableCell>
                            <TableCell>{moment(o.deliveryDate).format(bishopFormats.L)}</TableCell>
                        </TableRow>
                    )
                })
            )
        } else {
            return (
                <TableRow>
                    <TableCell colSpan={3}>{customLabel("function.notAvailable").toUpperCase()}</TableCell>
                </TableRow>
            )
        }
    };

    const renderNextMaintenances = () => {
        if (nextMaintenances && nextMaintenances.length > 0) {
            return (
                nextMaintenances.map((m) => {
                    return (
                        <TableRow key={m.id + m.code}>
                            <TableCell>{m.maintenanceType.name}</TableCell>
                            <TableCell>{moment(m.deliveryDate).format(bishopFormats.L)}</TableCell>
                        </TableRow>
                    )
                })
            )
        } else {
            return (
                <TableRow>
                    <TableCell colSpan={2}>{customLabel("function.notAvailable").toUpperCase()}</TableCell>
                </TableRow>
            )
        }
    };

    /**
     * 
     * @param {integer} colspan lunghezza cella all'interno della tablerow
       * @returns restituisce una barra di caricamento all'interno di una tablecell di span parametrizzato
     */
    const renderLoadingBar = (colspan) => {
        return (
            <TableRow>
                <TableCell colSpan={colspan}>
                    <LoadingBar />
                </TableCell>
            </TableRow>
        )
    };

    return (
        <React.Fragment>
            <Paper className={classes.main}>
                <AppBar position="static">
                    <Tabs value={0} variant="scrollable" scrollButtons="auto">
                        <Tab label={customLabel("machineView.oee")} key={"oee"} value={0} />
                    </Tabs>
                </AppBar>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{customLabel("machineView.data")}</TableCell>
                            <TableCell>{customLabel("machineView.value")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{customLabel("machineView.availability")}</TableCell>
                            <TableCell>{oee_availability ? (oee_availability.availability * 100).toFixed(2) + "%" : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{customLabel("machineView.quality")}</TableCell>
                            <TableCell>{oee_quality ? (oee_quality.quality * 100).toFixed(2) + "%" : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{customLabel("machineView.efficiency")}</TableCell>
                            <TableCell>{oee_efficiency ? (oee_efficiency.efficiency * 100).toFixed(2) + "%" : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{customLabel("machineView.oee")}</TableCell>
                            <TableCell>{oee ? (oee.oee * 100).toFixed(2) + "%" : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <AppBar position="static">
                    <Tabs value={0} variant="scrollable" scrollButtons="auto">
                        <Tab label={customLabel("machineView.nextOdps")} key={"lastOdps"} value={0} />
                    </Tabs>
                </AppBar>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{customLabel("odp.code")}</TableCell>
                            <TableCell>{customLabel("article.article")}</TableCell>
                            <TableCell>{customLabel("odp.deliveryDate")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loadingNextOdps && !nextOdps ? renderLoadingBar(3) : renderNextOdps()}
                    </TableBody>
                </Table>
                <AppBar position="static">
                    <Tabs value={0} variant="scrollable" scrollButtons="auto">
                        <Tab label={customLabel("machineView.lastOdps")} key={"lastOdps"} value={0} />
                    </Tabs>
                </AppBar >
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{customLabel("odp.code")}</TableCell>
                            <TableCell>{customLabel("article.article")}</TableCell>
                            <TableCell>{customLabel("odp.endDate")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loadingLastOdps && !lastOdps ? renderLoadingBar(3) : renderLastOdps()}
                    </TableBody>
                </Table>
                {configuration && configuration.enableMaintenance ? (
                    <React.Fragment>
                        <AppBar position="static">
                            <Tabs value={0} variant="scrollable" scrollButtons="auto">
                                <Tab label={customLabel("machineView.nextMaintenances")} key={"lastOdps"} value={0} />
                            </Tabs>
                        </AppBar>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{customLabel("maintenance.code")}</TableCell>
                                    <TableCell>{customLabel("maintenance.deliveryDate")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading && !nextMaintenances ? renderLoadingBar(2) : renderNextMaintenances()}
                            </TableBody>
                        </Table>
                    </React.Fragment>
                ) : null}
            </Paper>
        </React.Fragment>
    );
}

export default Odps;