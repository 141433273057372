import React, { useEffect } from "react";
import { useBoolean } from "./useBoolean";

export const useAnchor = () => {
    const [anchor, setAnchor] = React.useState(null);
    const [open, { setTrue, setFalse }] = useBoolean(false);

    /**
     * Valorizzo anchor, se currentEvent non è false ha la priorità su event.currentEvent
     * @param {object} event
     * @param {object} currentEvent
     */
    const handleSetAnchor = (newTarget, currentTarget) => {
        if (typeof currentTarget === "object") {
            setAnchor(currentTarget);
        } else if (typeof newTarget === "object") {
            setAnchor(newTarget);
        } else {
            setAnchor(null);
        }
    }

    const handleUnsetAnchor = () => {
        setAnchor(null);
    }

    useEffect(() => {
        if (anchor) {
            setTrue();
        } else {
            setFalse();
        }
    }, [anchor, setTrue, setFalse])

    return (
        [
            anchor,
            {
                handleOpen: handleSetAnchor,
                handleClose: handleUnsetAnchor
            },
            open
        ]
    )
}