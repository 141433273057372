import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Clock from 'react-live-clock';
import clsx from "clsx";

import {
  CssBaseline, Container, AppBar, Toolbar, IconButton, Typography, Dialog, DialogTitle, DialogActions, DialogContent, Input, Button, Tabs, Tab,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import { customLabel } from "../utility/customLabel";
import T from "i18n-react"

import NotificationsPage from "../notifications/NotificationsPage";

import { maintenerViewSelector, resetErrors, changeCurrentInput, getOperatorAPI } from "./slice";
import { configurationSelector, getAPI as getConfigurationAPI } from "../configuration/slice";
import { notificationsSelector } from "../notifications/slice";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { toggleFullScreen } from "../utility/vari";
import Ordinary from './Ordinary'
import Extraordinary from './Extraordinary'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: "#d01a28",
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    width: `100%`,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  apptitle: {
    flexGrow: 1,
    textDecoration: "none",
    fontWeight: "normal",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  fullIcon: {
    marginLeft: theme.spacing(10)
  },
  dialogButton: {
    padding: "15px",
    fontSize: "15pt !important"
  },
  form: {
    textAlign: "center",
  },
  title: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

const handleFocus = (event) => {
  event.preventDefault();
  return false;
}

const MaintenerViewPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, errors, maintener, currentInput } = useSelector(maintenerViewSelector);
  const { configuration, loading: loadingConf, hasErrors: errorsConf } = useSelector(configurationSelector);
  const { error } = useSelector(notificationsSelector);
  const [tab, setTab] = React.useState(0);
  const logobishop = process.env.PUBLIC_URL + "/logobishop.png";

  if (configuration === false && !loadingConf && !errorsConf) {
    dispatch(getConfigurationAPI());
  }
  if (configuration && configuration.label !== null) {
    T.setTexts(JSON.parse(configuration.label), {
      notFound: (key) => `${key} <- missing!`,
    });
  }

  if (!error && errors) {
    dispatch(resetErrors())
  }

  useEffect(() => {
    dispatch(getConfigurationAPI());
  }, [dispatch]);

  const handleChangeInput = (event) => {
    dispatch(changeCurrentInput(event.target.value));
  };

  const handleAddOperator = () => {
    dispatch(getOperatorAPI(currentInput));
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className={classes.root}>
      <NotificationsPage />
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" noWrap className={classes.apptitle}>
            <b>{customLabel("bishop.bishop").toUpperCase()}</b>
          </Typography>
          <Typography variant="h6" color="inherit" noWrap className={classes.apptitle}>
            <b>{configuration.name ? configuration.name : customLabel("function.customer")}</b>
          </Typography>
          <Typography variant="h6" color="inherit" noWrap className={classes.apptitle}>
            <b><Clock format={'HH:mm:ss'} ticking={true} timezone={'Europe/Rome'} /></b>
          </Typography>
          <img height="50" alt="ultrafab_logo" src={logobishop} />
          <IconButton className={classes.fullIcon} onClick={() => toggleFullScreen(document.body)}>
            <FullscreenIcon style={{ color: "white" }} fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <AppBar position="static">
            <Tabs value={tab} onChange={handleChangeTab} aria-label="simple tabs example" variant="scrollable" scrollButtons="auto">
              <Tab label={customLabel("maintenerView.ordinary")} />
              <Tab label={customLabel("maintenerView.extraordinary")} />
            </Tabs>
          </AppBar>
          {maintener && tab === 0 ? <Ordinary /> : maintener && tab === 1 ? <Extraordinary /> : null}
        </Container>
      </main>
      <Dialog fullWidth maxWidth="md" open={!maintener} >
        <DialogTitle>
          <div className={classes.title}>
            <div>{customLabel("maintenerView.insertMaintenerCode")}</div>
            <div>
              <IconButton onClick={() => toggleFullScreen(document.body)}>
                <FullscreenIcon style={{ color: "black" }} fontSize="large" />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.form}>
            <Input onFocus={handleFocus} inputRef={(input) => {
              if (input !== null) {
                input.focus();
              }
            }}
              value={currentInput ? currentInput : ""} fullWidth name="operator" onChange={handleChangeInput}></Input>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.dialogButton} onClick={handleAddOperator} variant="contained"
            disabled={loading || !currentInput || currentInput === ""} color="primary">{customLabel("button.moveOn")}
          </Button>
        </DialogActions>
      </Dialog >
    </div>
  )
}

export default MaintenerViewPage;