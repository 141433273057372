
export const INDEX_MACHINES = 0;
export const INDEX_USERS = 1;

export const EXTRAORDINARY_LABEL = "EXTRAORDINARY";

export const COLOR_GENERIC_PRODUCTION = " #00b660";
export const COLOR_VARIOUS = "repeating-linear-gradient(135deg,white,#94edfb 10px, white 10px, #94edfb 20px)";

export const NAME_PRODUCTION_GENERIC = "PRODUZIONE GENERICA";
export const NAME_VARIOUS = "VARIO";

export const EXTRA_LEGEND_ITEMS = [{ id: 1 + "_" + NAME_PRODUCTION_GENERIC, name: NAME_PRODUCTION_GENERIC, backgroundColor: COLOR_GENERIC_PRODUCTION },
{ id: 2 + "_" + NAME_VARIOUS, name: NAME_VARIOUS, backgroundColor: COLOR_VARIOUS }];
