import React, { useEffect } from 'react';
import {
    Table, TableBody, Paper, Button, Dialog, DialogTitle,
    DialogActions, DialogContent, Divider
} from '@material-ui/core';
import { customLabel } from '../../utility/customLabel';
import { useSelector, useDispatch } from 'react-redux';
import CheckForm from "../../processTypes/CheckForm";
import { mesStyles } from "../../utility/ultrafabStyles";
import {
    checksSelector, getListChecksByNameAPI, setCheckToSee, setReload as setChecksReload, changeFilter as changeChecksFilter,
    resetState as resetStateChecks
} from '../../checks/slice';
import TableChecksDetails from '../../checks/TableChecksDetails';
import { renderChecksByType } from "../../checks/utility";
import SimpleTableHeader from '../../utility/SimpleTableHeader';
import ButtonProcessStapling from '../../utility/ButtonProcessStapling';
import { configurationSelector } from '../../configuration/slice';

const Checks = ({ odp, editProcess, loading, editCheck, currentChecks, changeCheck, resetCheck, addCheck, updateCheckAPI, createCheckAPI, setCheck }) => {
    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { checkList, checkToSee, results: checksResults, filter: checksFilter, reload: checksReload, loading: loadingChecks } = useSelector(checksSelector);
    const { configuration } = useSelector(configurationSelector);

    useEffect(() => {
        if (checksReload && checkToSee) {
            dispatch(getListChecksByNameAPI(checksFilter));
        }
    }, [dispatch, checksFilter, checksReload, checkToSee])

    /**
     * 
     * @param {*} odpId 
     * @param {*} editProcessId 
     * @param {*} currentCheck 
     * @param {*} stapling 
     */
    const setChecksDetails = (odpId, editProcessId, currentCheck, stapling) => {
        dispatch(setCheckToSee({ check: currentCheck, odpId: odpId, processId: editProcessId, stapling: stapling }));
        dispatch(setChecksReload());
    };

    const unSetChecksDetails = () => {
        dispatch(resetStateChecks());
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(changeChecksFilter({ name: "rowsPerPage", value: parseInt(event.target.value, 10) }))
        dispatch(changeChecksFilter({ name: "page", value: 0 }))
        dispatch(setChecksReload());
    };
    const handleChangePage = (event, newPage, doReload) => {
        dispatch(changeChecksFilter({ name: "page", value: newPage }))
        if (doReload) {
            dispatch(setChecksReload());
        }
    };

    //apre aggiunta check
    const onAddCheck = () => {
        dispatch(addCheck());
    };
    //modifica editCheck
    const onChangeCheck = event => {
        let name = event.target.name
        let value = event.target.value
        if (event.target.type === "checkbox") {
            value = event.target.checked;
        }
        dispatch(changeCheck({ name: name, value: value }));
    };
    //apre modifica check
    const onEditCheck = (check) => {
        dispatch(setCheck(check));
    };

    //rimuove inserimento check
    const cancelEditCheck = () => {
        dispatch(resetCheck());
    };
    //salvo check
    const saveCheck = () => {
        if (editCheck.id === "new") {
            dispatch(createCheckAPI(odp, editProcess, editCheck))
        } else {
            dispatch(updateCheckAPI(odp, editProcess, editCheck))
        }
    }

    return (
        <React.Fragment>
            <Paper className={myClasses.processDetailsPaper}>
                {editProcess.stapling.id !== null ? (
                    <React.Fragment>
                        <ButtonProcessStapling message={customLabel("process.processInStapling").toUpperCase()} stapling={editProcess.stapling} />
                        <Divider />
                    </React.Fragment>
                ) : null}
                <div className={myClasses.buttonsFlexRight}>
                    <Button color="primary" disabled={loading || editProcess.stapling.id !== null}
                        onClick={() => onAddCheck()}
                    >
                        {customLabel("button.add")}
                    </Button>
                </div>
                <Divider />
                <Table size="small" stickyHeader>
                    <SimpleTableHeader list={[customLabel("processType.check.type").toUpperCase(), customLabel("function.name"), customLabel("function.value"),
                    customLabel("function.date"), customLabel("function.operator").toUpperCase(), customLabel("function.note").toUpperCase(), customLabel("function.actions").toUpperCase()]} />
                    <TableBody>
                        {!odp.maintenance ? renderChecksByType(odp, false, editProcess, currentChecks.filter((l) => l.start), customLabel("processType.check.start").toUpperCase(), true, editProcess.stapling.id === null, setChecksDetails, onEditCheck, configuration.decimals) : null}
                        {!odp.maintenance ? renderChecksByType(odp, false, editProcess, currentChecks.filter((l) => l.pause), customLabel("processType.check.pause").toUpperCase(), true, editProcess.stapling.id === null, setChecksDetails, onEditCheck, configuration.decimals) : null}
                        {!odp.maintenance ? renderChecksByType(odp, false, editProcess, currentChecks.filter((l) => l.restart), customLabel("processType.check.restart").toUpperCase(), true, editProcess.stapling.id === null, setChecksDetails, onEditCheck, configuration.decimals) : null}
                        {renderChecksByType(odp, false, editProcess, currentChecks.filter((l) => l.close), customLabel("processType.check.close").toUpperCase(), true, editProcess.stapling.id === null, setChecksDetails, onEditCheck, configuration.decimals)}
                        {!odp.maintenance ? renderChecksByType(odp, false, editProcess, currentChecks.filter((l) => l.onDemand), customLabel("processType.check.onDemand").toUpperCase(), true, editProcess.stapling.id === null, setChecksDetails, onEditCheck, configuration.decimals) : null}
                    </TableBody>
                </Table>
            </Paper>
            {/*Dialog visualizzare lo storico di un controllo*/}
            <Dialog open={checkToSee ? true : false} keepMounted maxWidth={"md"} fullWidth={true}>
                <DialogTitle>
                    {customLabel("function.historicalCheck")}: {checkToSee.name}
                </DialogTitle>
                <DialogContent>
                    <TableChecksDetails checkList={checkList} page={checksFilter.page} setPage={handleChangePage}
                        rowsPerPage={checksFilter.rowsPerPage} setRowsPerPage={handleChangeRowsPerPage} results={checksResults}
                        showOperator={true} decimals={configuration.decimals} loading={loadingChecks} />
                </DialogContent>
                <DialogActions>
                    <Button disabled={loadingChecks} onClick={unSetChecksDetails} color="default">{customLabel("button.close")}</Button>
                </DialogActions>
            </Dialog>

            {/*Dialog per modificare/aggiungere un check*/}
            {editCheck !== false ? (
                <Dialog open={editCheck !== false} keepMounted maxWidth={"sm"} fullWidth>
                    <DialogTitle>{customLabel("processType.check.check")}</DialogTitle>
                    <DialogContent>
                        <CheckForm maintenance={odp.maintenance} processCheck={editCheck} onChange={onChangeCheck}
                            vars={editProcess.vars.filter((v) => v.operatorEdit && v.operatorView)} configuration={configuration} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={saveCheck} color="primary"
                            disabled={editCheck.name === "" || editCheck.type === "" || loading || (!editCheck.start && !editCheck.pause && !editCheck.restart && !editCheck.close && !editCheck.onDemand)
                                || (editCheck.type === "var" && (editCheck.varName === null || editCheck.varName === ""))}>
                            {customLabel("button.save")}
                        </Button>
                        <Button onClick={cancelEditCheck} disabled={loading} color="default">{customLabel("button.close")}</Button>
                    </DialogActions>
                </Dialog>
            ) : null}
        </React.Fragment>
    )
}

export default Checks
