import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: "none"
    },
    popoverContent: {
        pointerEvents: "auto"
    }
}));

const PopoverItem = ({ content, open, anchorEl, params, openPopover, closePopover, anchorVertical, anchorHorizontal, transformVertical, transormHorizontal }) => {

    const classes = useStyles()
    if (params.process.process_id !== undefined) {
        return (
            <div>
                <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: anchorVertical,
                        horizontal: anchorHorizontal,
                    }}
                    transformOrigin={{
                        vertical: transformVertical,
                        horizontal: transormHorizontal,
                    }}
                    PaperProps={{ onMouseEnter: (e) => openPopover(e, params.type, params.process), onMouseLeave: closePopover }}
                    disableRestoreFocus
                >
                    {content()}
                </Popover>
            </div>
        );
    }
    return null;
}

export default PopoverItem;