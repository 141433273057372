import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';
import { SERVER_URL } from "../../constants";
import { logout, refreshAPI } from "../main/slice";
import { customMaintenancePhaseFilter } from "../maintenancePhases/utility";
import { showError, showSuccess } from "../notifications/slice";
import { customLabel } from "../utility/customLabel";
import { customProcessTypeFilter } from "./utility";

export const initialState = {
  loading: false,
  hasErrors: false,
  processTypes: false,
  reload: false,
  results: 0,
  editmode: false,
  processType: false,
  processTypeedit: false,
  deleting: false,
  isCreated: false,
  isDeleted: false,
  check: false,
  processVar: false,
  processRole: false,
  processTypeFilter: false,
  maintenancePhaseFilter: false,
  editMode:false,
  reasons: false
};

const processTypesSlice = createSlice({
  name: "processTypes",
  initialState,
  reducers: {
    resetState: (state) => {
      if (!state.processTypeFilter && !state.maintenancePhaseFilter) {
        Object.assign(state, initialState);
      } else if (state.processTypeFilter && state.maintenancePhaseFilter) {
        let tempState = {};
        let currentProcessFilter = { ...state.processTypeFilter }
        let currentMaintenanceFilter = { ...state.maintenancePhaseFilter }
        Object.assign(tempState, initialState);
        tempState.processTypeFilter = { ...currentProcessFilter }
        tempState.maintenancePhaseFilter = { ...currentMaintenanceFilter }
        Object.assign(state, tempState);
      } else if (state.processTypeFilter && !state.maintenancePhaseFilter) {
        let tempState = {};
        let currentFilter = { ...state.processTypeFilter }
        Object.assign(tempState, initialState);
        tempState.processTypeFilter = { ...currentFilter }
        Object.assign(state, tempState);
      } else if (!state.processTypeFilter && state.maintenancePhaseFilter) {
        let tempState = {};
        let currentFilter = { ...state.maintenancePhaseFilter }
        Object.assign(tempState, initialState);
        tempState.maintenancePhaseFilter = { ...currentFilter }
        Object.assign(state, tempState);
      }
    },
    gettingList: (state) => {
      state.loading = true;
      state.reload = false;
      state.hasErrors = false;
    },
    getListSuccess: (state, { payload }) => {
      state.processTypes = payload[1];
      state.results = payload[0].total_entries;
      state.loading = false;
    },
    setReload: (state) => {
      state.reload = true;
    },
    reset: state => {
      state.processType = false
      state.processTypeedit = false
      state.editmode = false
      state.isDeleted = false
      state.isCreated = false
    },
    getting: state => {
      state.processType = false
      state.isCreated = false
      state.isDeleted = false
      state.loading = true
      state.editmode = false
      state.reload = false
      state.hasErrors = false
    },
    getSuccess: (state, { payload }) => {
      state.processType = payload
      state.loading = false
    },
    creating: state => {
      state.loading = true
      state.hasErrors = false
    },
    create: state => {
      let newprocessType = { id: "new", code: "", name: "", multiple: false, autostart: false, external: false, maintenance: false, autoComponentConsumption: false }
      state.processType = newprocessType
      state.processTypeedit = newprocessType
      state.editmode = true
    },
    createSuccess: (state, { payload }) => {
      state.processType = payload
      state.processTypeedit = false
      state.loading = false
      state.isCreated = true
    },
    resetEdit: state => {
      state.editmode = true
      state.processTypeedit = state.processType
      state.loading = false
      state.hasErrors = true
    },
    edit: state => {
      state.editmode = true
      state.processTypeedit = state.processType
    },
    cancelEdit: state => {
      state.editmode = false
      state.processTypeedit = false
    },
    change: (state, { payload }) => {
      let u = state.processTypeedit
      u[payload.name] = payload.value
      state.processTypeedit = u
    },
    updating: state => {
      state.loading = true
      state.hasErrors = false
    },
    updateSuccess: (state, { payload }) => {
      state.processType = payload
      state.loading = false
      state.editmode = false
    },
    confirmDelete: state => {
      state.deleting = !state.deleting
    },
    deleting: state => {
      state.deleting = false
      state.loading = true
      state.hasErrors = false
    },
    deleteSuccess: state => {
      state.loading = false
      state.isDeleted = true
    },
    linkingObject: state => {
      state.loading = true
      state.hasErrors = false
    },
    linkingObjectSuccess: state => {
      state.loading = false
      state.reload = true
    },
    creatingCheck: state => {
      state.loading = true
      state.hasErrors = false
    },
    createCheckSuccess: state => {
      state.loading = false
      state.check = false
      state.reload = true
    },
    updatingCheck: state => {
      state.loading = true
      state.hasErrors = false
    },
    updateCheckSuccess: state => {
      state.loading = false
      state.check = false
      state.reload = true
    },
    deletingCheck: state => {
      state.loading = true
      state.hasErrors = false
    },
    deleteCheckSuccess: state => {
      state.loading = false
      state.reload = true
    },
    newCheck: state => {
      state.check = {
        "id": "new", "type": "", start: false, close: false, restart: false, pause: false, onDemand: false, name: "",
        varName: "", color: ""
      }
    },
    selectCheck: (state, action) => {
      state.check = action.payload
    },
    changeCheck: (state, { payload }) => {
      let c = state.check
      if (payload.name === "type" && payload.value !== "var") {
        c["varName"] = "";
      }
      c[payload.name] = payload.value
      state.check = c
    },
    creatingVar: state => {
      state.loading = true
      state.hasErrors = false
    },
    createVarSuccess: state => {
      state.loading = false
      state.processVar = false
      state.reload = true
    },
    updatingVar: state => {
      state.loading = true
      state.hasErrors = false
    },
    updateVarSuccess: state => {
      state.loading = false
      state.processVar = false
      state.reload = true
    },
    deletingVar: state => {
      state.loading = true
      state.hasErrors = false
    },
    deleteVarSuccess: state => {
      state.loading = false
      state.reload = true
    },
    newVar: state => {
      state.processVar = { "id": "new", "type": "", multiple: false, name: "", incremental: false, operatorEdit: false, adminEdit: false, askArticle: false, editable: false, serial: false, operatorView: false, measure: "", resettable: true, askConfirmation:false }
    },
    selectVar: (state, action) => {
      state.processVar = action.payload.var
      state.editMode = action.payload.editMode
      if(state.processVar){
        state.reasons = action.payload.var.reasons
      }else{
        state.reasons = false
      }
    },
    changeVar: (state, { payload }) => {
      let c = state.processVar
      c[payload.name] = payload.value
      state.processVar = c
    },
    enableErrors: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    sortProcessTypeVarsSuccess: (state) => {
      state.reload = true;
      state.loading = false;
    },
    saveReasonsSuccess: (state) => {
      state.reload = true;
      state.loading = false;
      state.processVar = false;
      state.editMode = false;
    },
    removeReasonSuccess: (state, {payload}) => {
      state.reload = true;
      state.loading = false;
      let r = _.cloneDeep(state.reasons)
      state.reasons = r.filter(x => x.id !== payload.id);
    },
    selectRole: (state, action) => {
      state.processRole = action.payload
    },
    changeRole: (state, { payload }) => {
      let c = state.processRole
      c[payload.name] = payload.value
      state.processRole = c
    },
    updatingRole: state => {
      state.loading = true
      state.hasErrors = false
    },
    updateRoleSuccess: state => {
      state.loading = false
      state.processRole = false
      state.reload = true
    },
    changeProcessTypeFilter: (state, { payload }) => {
      state.processTypeFilter[payload.name] = payload.value;
    },
    /**
     * 
     * @param {*} state 
     * @param {boolean} payload showFilter 
     */
    initProcessTypeFilter: (state, { payload }) => {
      let tempFilter = { ...customProcessTypeFilter };
      tempFilter["showFilter"] = payload;
      state.processTypeFilter = { ...tempFilter };
    },
    changeMaintenancePhaseFilter: (state, { payload }) => {
      state.maintenancePhaseFilter[payload.name] = payload.value;
    },
    /**
     * 
     * @param {*} state 
     * @param {boolean} payload showFilter 
     */
    initMaintenancePhaseFilter: (state, { payload }) => {
      let tempFilter = { ...customMaintenancePhaseFilter };
      tempFilter["showFilter"] = payload;
      state.maintenancePhaseFilter = { ...tempFilter };
    }
  },
});

export const { gettingList, getListSuccess, setReload, reset, getting, getSuccess, create, creating, createSuccess, linkingObject, linkingObjectSuccess,
  edit, cancelEdit, change, updating, updateSuccess, confirmDelete, deleting, deleteSuccess, resetEdit,
  loadingProcessTypes, loadingProcessTypesSuccess, creatingCheck, createCheckSuccess, updatingCheck, updateCheckSuccess, deletingCheck, deleteCheckSuccess,
  newCheck, selectCheck, changeCheck, creatingVar, createVarSuccess, updatingVar, updateVarSuccess, deletingVar, deleteVarSuccess,
  newVar, selectVar, changeVar, enableErrors, sortProcessTypeVarsSuccess, resetState, selectRole, changeRole, updatingRole, updateRoleSuccess,
  changeProcessTypeFilter, initProcessTypeFilter, changeMaintenancePhaseFilter, initMaintenancePhaseFilter, saveReasonsSuccess,removeReasonSuccess
} = processTypesSlice.actions;

export const processTypesSelector = (state) => state.processTypes;
export default processTypesSlice.reducer;

/**
 * 
 * @param {*} page 
 * @param {*} rowsPerPage 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} name 
 * @param {*} code 
 * @param {*} maintenance 
 * @returns 
 */
export function getListAPI(page, rowsPerPage, orderBy, order, name, code, maintenance) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(gettingList());
    dispatch(reset());
    let url = SERVER_URL + "/api/processType?page=" + page + "&per_page=" + rowsPerPage + "&orderBy=" + orderBy + "&order=" + order + "&maintenance=" + maintenance;
    if (name !== false) {
      url = url + "&name=" + name;
    }
    if (code !== false) {
      url = url + "&code=" + code;
    }
    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getListSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function updateAPI(processType) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  let params = { processType: processType }
  return async dispatch => {
    dispatch(updating())
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updateSuccess(data))
        if (processType.maintenance) {
          dispatch(showSuccess("function.operationSuccess"))
        } else {
          dispatch(showSuccess("function.operationSuccess"))
        }
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(resetEdit())
        dispatch(showError(data.message))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function createAPI(processType) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(creating())
    let params = { processType: processType }
    const response = await fetch(SERVER_URL + '/api/processType', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(createSuccess(data))
        if (processType.maintenance) {
          dispatch(showSuccess("function.operationSuccess"))
        } else {
          dispatch(showSuccess("function.operationSuccess"))
        }
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function getAPI(id) {
  return async dispatch => {
    dispatch(getting())
    const response = await fetch(SERVER_URL + '/processType/' + id, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(getSuccess(data))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function deleteAPI(processType) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(deleting())
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(deleteSuccess(data))
        if (processType.maintenance) {
          dispatch(showSuccess("function.operationSuccess"))
        } else {
          dispatch(showSuccess("function.operationSuccess"))
        }
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function removeMachineAPI(processType, machine) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(linkingObject())
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/machine/' + machine.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(linkingObjectSuccess())
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function addMachinesAPI(processType, machines) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(linkingObject())
    let params = { machines: machines }
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/machine', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(linkingObjectSuccess())
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}
export function removeUserEventTypeAPI(processType, userEventType) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(linkingObject())
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/userEventType/' + userEventType.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(linkingObjectSuccess())
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function addUserEventTypesAPI(processType, userEventTypes) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(linkingObject())
    let params = { userEventTypes: userEventTypes }
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/userEventType', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(linkingObjectSuccess())
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}
export function removeRoleAPI(processType, role) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(linkingObject())
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/role/' + role.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(linkingObjectSuccess())
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function addRolesAPI(processType, roles) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(linkingObject())
    let params = { roles: roles }
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/role', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(linkingObjectSuccess())
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function updateRoleAPI(processType, processRole) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(updatingRole())
    let params = { "link": { "unmountProcess": processRole.unmountProcess } }
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/role/' + processRole.id, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updateRoleSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function createCheckAPI(processType, check) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(creatingCheck())
    let params = { check: check }
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/check', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(createCheckSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function updateCheckAPI(processType, check) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(updatingCheck())
    let params = { check: check }
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/check/' + check.id, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updateCheckSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function deleteCheckAPI(processType, check) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(deletingCheck())
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/check/' + check.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(deleteCheckSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function createVarAPI(processType, processVar) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(creatingVar())
    let params = { var: processVar }
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/var', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(createVarSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function updateVarAPI(processType, processVar) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(updatingVar())
    let params = { var: processVar }
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/var/' + processVar.id, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updateVarSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function deleteVarAPI(processType, processVar) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(deletingVar())
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/var/' + processVar.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });
    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(deleteVarSuccess(data))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}
export function sortProcessTypeVarsAPI(processType, vars) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    let params = { vars: vars };
    const response = await fetch(
      SERVER_URL + "/api/processType/" + processType.id + "/var/sort",
      {
        mode: "cors",
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(params),
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(sortProcessTypeVarsSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function removeReasonAPI(processType, processVar, reason) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(linkingObject())
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/var/' + processVar.id+'/reason/'+reason.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(removeReasonSuccess(reason))
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}
export function saveReasonsAPI(processType, processVar, reasons) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    let params = { reasons: reasons };
    const response = await fetch(
      SERVER_URL + "/api/processType/" + processType.id + "/var/"+processVar.id+"/reason",
      {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(params),
      }
    );

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(saveReasonsSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function removeMeasureToolAPI(processType, measureTool) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(linkingObject())
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/measureTool/' + measureTool.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(linkingObjectSuccess())
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function addMeasureToolsAPI(processType, measureTools) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(linkingObject())
    let params = { measureTools: measureTools }
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/measureTool', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(linkingObjectSuccess())
        dispatch(showSuccess("function.operationSuccess"))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function updateCycleTimesAPI(processType, machines) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    let params = { machines: machines }
    const response = await fetch(SERVER_URL + '/api/processType/' + processType.id + '/machine/cycleTime', {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 403) {
        dispatch(logout())
      } else if (response.status !== 200) {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}