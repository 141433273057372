import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  articlesSelector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete, getListAPI, changeObject, getTreeAPI, cancelIsCreated
} from "./slice";
import { bomsSelector, getListAPI as getBomsAPI } from "../boms/slice";
import DocumentsPage from "../documents/DocumentsPage";
import { Box, Typography } from "@material-ui/core";
import { Form } from "./Form";
import CycleTimesPage from "./CycleTimesPage";
import { Details } from "./Details";
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { customLabel } from "../utility/customLabel";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";

const ArticlePage = ({ match }) => {
  const dispatch = useDispatch();
  const myClasses = mesStyles();
  const { article, articleedit, editmode, deleting, isCreated, isDeleted, articles, tree, loading, expanded, hasErrors } = useSelector(articlesSelector);
  const { boms } = useSelector(bomsSelector);

  useEffect(() => {
    const { id } = match.params;
    //se l'articolo è stato creato ed è già avvenuto il redirect in fondo alla pagina, rimuovo isCreated
    if (isCreated && article.id.toString() === id) {
      dispatch(cancelIsCreated())
    }
    // se l'articolo è appena stato selezionato dalla table list o è cambiato l'id nell'url:
    if (!isCreated && (article === false || article.id.toString() !== id) && !loading && !hasErrors) {
      if (id !== "new") {
        // se l'id nell'url è !== da new me lo faccio dare dal server
        dispatch(getAPI(id));
      } else {
        // se l'id nell'url è === new creo l'oggetto
        dispatch(create());
      }
    }
  }, [dispatch, match, article, loading, isCreated, hasErrors]);

  useEffect(() => {
    if (!articles && article && !loading) {
      dispatch(getListAPI(0, 10000, "name", "asc", false, false, false, false, false));
    }

  }, [dispatch, articles, article, loading]);

  useEffect(() => {
    if (articles && article && !boms && !loading && !hasErrors) {
      dispatch(getBomsAPI(0, 100000, "name", "asc", false, false));
    }
  }, [dispatch, articles, article, boms, loading, hasErrors]);

  useEffect(() => {
    const { id } = match.params;
    if (!loading && !tree && article && boms && articles && id !== "new" && article.bom.id !== "" && (!articleedit || (articleedit && articleedit.bom.id !== "")) && article.bom.id !== null && !hasErrors) {
      dispatch(getTreeAPI(article));
    }
  }, [dispatch, match, article, tree, articles, boms, loading, hasErrors, articleedit]);

  const renderBom = (id, bom, index) => {
    return (
      <TreeItem key={bom.process.name + "_" + index} nodeId={id + "_process_" + bom.process.name} label={bom.process.name}>
        {bom.articles.map(p => {
          let newId = id + "_article_" + p.code;
          return (
            <TreeItem key={p.code} nodeId={newId} label={p.article.code + " " + p.article.name + " (" + p.name + ") --" + newId}>
              {p.bomProcesses.map(bp => {
                return renderBom(newId, bp)
              })}
            </TreeItem>
          )
        })}
      </TreeItem>
    )
  }

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    dispatch(change({ name: name, value: value }));
  };

  const handleChangeObject = (name, value) => {
    if (value !== false) {
      dispatch(change({ name: name, value: value, boms: boms }))
    }
    else {
      dispatch(changeObject({ name: name, value: false }))
    }
  };

  const toggleDelete = (event) => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(articleedit));
  };

  const onEdit = () => {
    dispatch(edit());
  };
  const doDelete = () => {
    dispatch(deleteAPI(article));
  };

  const save = () => {
    if (article.id === "new") {
      dispatch(createAPI(articleedit));
    } else {
      dispatch(updateAPI(articleedit));
    }
  };

  const renderarticle = () => {
    if (article !== false && !editmode && match.params.id === article.id.toString())
      return (
        <div>
          <Details article={article} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete} tree={tree} loading={loading} />
          {article.bom.id !== null ? <CycleTimesPage article={article} /> : null}
          {tree ? (
            <PaperForm>
              <div className={myClasses.titleFormSpaceBetween}>
                <Typography component="h1" variant="h6" color="inherit"> {customLabel("article.bomTree")}</Typography>
              </div>
              <div className={myClasses.tableContainer}>
                <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} expanded={expanded}>
                  <TreeItem nodeId={"distinta_" + tree.bom.code} label={tree.bom.name}>
                    {tree.bom.bomProcesses.map((b, index) => {
                      let id = "distinta_" + tree.bom.code;
                      return renderBom(id, b, index)
                    })}
                  </TreeItem>
                </TreeView>
              </div>
            </PaperForm>
          ) : null}
          <PaperForm>
            <DocumentsPage parentId={article.id} parentType="articles" extLoading={loading} title={true} size="small" />
          </PaperForm>
        </div>
      );
    if (article !== false && editmode)
      return (
        <Form articleedit={articleedit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save}
          articles={articles} boms={boms} handleChangeObject={handleChangeObject} loading={loading}
        />
      );
    return;
  };

  if (isDeleted && match.params.id === article.id.toString()) {
    return <Redirect to={"/articles/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/articles/" + article.id} />;
  }

  return <Box>{renderarticle()}</Box>;
};

export default ArticlePage;
