import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import arrayMove from "array-move";
import { Container, Draggable } from "react-smooth-dnd";
import { customLabel } from "../utility/customLabel";
import { Typography, Grid, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { odpSmartSelector, onChangeSort, addProcess, removeProcess } from "./slice";
import { mesStyles } from "../utility/ultrafabStyles";

const Processes = () => {
  const myClasses = mesStyles();
  const dispatch = useDispatch();
  const { processes, bomProcesses } = useSelector(odpSmartSelector)

  const onDrop = ({ removedIndex, addedIndex }) => {
    let newArray = arrayMove(processes, removedIndex, addedIndex);
    dispatch(onChangeSort(newArray));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={5}>
        <div className={myClasses.titleFormSpaceBetween}>
          <Typography variant="h6">{customLabel("smartOdp.availableProcesses")}</Typography>
        </div>
        <div className={myClasses.listProcessContainer}>
          {bomProcesses ?
            <List>
              {bomProcesses.map(p => {
                return (
                  <ListItem key={p.id} className={myClasses.listItemProcess}>
                    <ListItemText primary={p.name} />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => dispatch(addProcess(p))}><ControlPointIcon /></IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </List> : null}
        </div>
      </Grid>
      <Grid item xs={12} md={1}>
      </Grid>
      <Grid item xs={12} md={5}>
        <div className={myClasses.titleFormSpaceBetween}>
          <Typography variant="h6">{customLabel("smartOdp.addedProcesses")}</Typography>
        </div>
        <div className={myClasses.listProcessContainer}>
          {processes ?
            <List>
              <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
                {processes.map((p, i) => {
                  return (
                    <Draggable key={p.id + "_" + i}>
                      <ListItem className={myClasses.listItemProcess}>
                        <ListItemText primary={p.name} />
                        <ListItemIcon className="drag-handle">
                          <DragIndicatorIcon style={{ "cursor": "pointer" }} />
                        </ListItemIcon>
                        <ListItemSecondaryAction>
                          <IconButton onClick={() => dispatch(removeProcess(p))}><HighlightOffIcon /></IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Draggable>
                  )
                })}
              </Container>
            </List>
            : null}
        </div>
      </Grid>
    </Grid>
  )
}

export default Processes;