import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { userEventsSelector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete } from "./slice";
import { rolesSelector, getListAPI as getRolesAPI } from "../roles/slice";
import { Box } from "@material-ui/core";

import { Form } from "./Form";
import { Details } from "./Details";

const UserEventPage = ({ match }) => {
  const dispatch = useDispatch();
  const { userEvent, userEventedit, editmode, deleting, isCreated, isDeleted, userEvents, loading } = useSelector(userEventsSelector);

  const { roles } = useSelector(rolesSelector);

  const handleChange = (event, isCheck) => {
    let name = event.target.name;
    let value = event.target.value;
    dispatch(
      change({ name: name, value: value, roles: roles, isCheck: isCheck })
    );
  };

  const toggleDelete = (event) => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(userEventedit));
  };

  const onEdit = () => {
    dispatch(edit());
  };
  const doDelete = () => {
    dispatch(deleteAPI(userEvent));
  };

  const save = () => {
    if (userEvent.id === "new") {
      dispatch(createAPI(userEventedit));
    } else {
      dispatch(updateAPI(userEventedit));
    }
  };

  useEffect(() => {
    dispatch(getRolesAPI(0, 100000, "authority", "asc", false));
    const { id } = match.params;
    if (id !== "new") {
      dispatch(getAPI(id));
    } else {
      dispatch(create());
    }
  }, [dispatch, match]);

  const renderuserEvent = () => {
    if (userEvent !== false && !editmode && match.params.id === userEvent.id.toString()) {
      return (
        <Details userEvent={userEvent} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete} loading={loading} />
      );
    }
    if (userEvent !== false && editmode) {
      return (
        <Form userEventedit={userEventedit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save} userEvents={userEvents} roles={roles} loading={loading} />
      );
    }
    return;
  };

  if (isDeleted && match.params.id === userEvent.id.toString()) {
    return <Redirect to={"/admin/userEvents/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/admin/userEvents/" + userEvent.id} />;
  }

  return <Box>{renderuserEvent()}</Box>;
};

export default UserEventPage;
