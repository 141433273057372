import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import useInterval from 'react-useinterval';
import { machineEventsSelector } from "../../machineEvents/slice";
import {
    getOdpEventsAPI,
    getStaplingChecksAPI,
    getStaplingComponentsAPI,
    getStaplingListAPI,
    getStaplingMachineEventAPI,
    getStaplingMachineVarsAPI,
    getStaplingUserEventsAPI,
    getStaplingVarsAPI,
    odpsSelector,
    reloadingStaplingOdpAPI,
    setReloadStaplingMachineEvents
} from '../slice';

const ReloadingStaplingOdp = ({configuration}) => {
    const dispatch = useDispatch();
    const { odp, reload, reloading, loading, events,
        errorsStaplingList, errorsOdpEvents, staplingMachineEvent,
        errorsStaplingMachineEvent, staplingList, errorsStaplingVars, staplingVars, errorsStaplingChecks, staplingChecks,
        staplingMachineVars, errorsStaplingMachineVars, loadingStaplingMachineVars, loadingStaplingChecks, loadingStaplingVars,
        loadingStaplingMachineEvent, loadingStaplingList, loadingStaplingUserEvents, errorsStaplingUserEvents, staplingUserEvents, staplingComponents } = useSelector(odpsSelector);
    const { machineEvents } = useSelector(machineEventsSelector);

    useInterval(() => updatingOdp(), 10000);
    //bloccato se finestra dialog checks e vars
    const updatingOdp = () => {
        //if (!newMachineEvent) {
        if (odp) {
            dispatch(reloadingStaplingOdpAPI(odp, errorsStaplingList, errorsStaplingMachineEvent, errorsStaplingVars, errorsStaplingMachineVars, errorsStaplingChecks, machineEvents, errorsOdpEvents, errorsStaplingUserEvents, configuration.retrieveLotInfo))
            dispatch(setReloadStaplingMachineEvents())
        }
    };

    useEffect(() => {
        if (reload && !loading && !reloading) {
            dispatch(reloadingStaplingOdpAPI(odp, errorsStaplingList, errorsStaplingMachineEvent, errorsStaplingVars, errorsStaplingMachineVars, errorsStaplingChecks, machineEvents, errorsOdpEvents, errorsStaplingUserEvents, configuration.retrieveLotInfo))
            dispatch(setReloadStaplingMachineEvents())
        };
    }, [dispatch, odp, reload, loading, reloading, errorsStaplingList, errorsStaplingMachineEvent, errorsStaplingVars, errorsStaplingMachineVars,
        errorsStaplingChecks, machineEvents, errorsOdpEvents, errorsStaplingUserEvents, configuration.retrieveLotInfo])

    //DOPPIO: primo caricamento di events, dopo di che con lo use interval
    useEffect(() => {
        if (odp && odp.id !== "new" && machineEvents && !events && !errorsOdpEvents) {
            dispatch(getOdpEventsAPI(odp, machineEvents))
        }
    }, [dispatch, odp, machineEvents, events, errorsOdpEvents])

    //PINZATURA: primo caricamento evento macchina
    useEffect(() => {
        if (!staplingMachineEvent && odp && odp.stapling && !errorsStaplingMachineEvent && !loadingStaplingMachineEvent) {
            dispatch(getStaplingMachineEventAPI(odp));
        };
    }, [dispatch, odp, staplingMachineEvent, errorsStaplingMachineEvent, loadingStaplingMachineEvent])

    //PINZATURA: primo caricamento eventi utente
    useEffect(() => {
        if (!staplingUserEvents && odp && odp.stapling && !errorsStaplingUserEvents && !loadingStaplingUserEvents) {
            dispatch(getStaplingUserEventsAPI(odp));
        };
    }, [dispatch, odp, staplingUserEvents, errorsStaplingUserEvents, loadingStaplingUserEvents])

    //PINZATURA: primo caricamento lista lavorazioni
    useEffect(() => {
        if (!staplingList && odp && odp.stapling && !errorsStaplingList && !loadingStaplingList) {
            dispatch(getStaplingListAPI(odp));
        }
    }, [dispatch, odp, staplingList, errorsStaplingList, loadingStaplingList])

    //PINZATURA: primo caricamento variabili
    useEffect(() => {
        if (!staplingVars && odp && odp.stapling && !errorsStaplingVars && !loadingStaplingVars) {
            dispatch(getStaplingVarsAPI(odp));
        }
    }, [dispatch, odp, staplingVars, errorsStaplingVars, loadingStaplingVars])

    //PINZATURA: primo caricamento checks
    useEffect(() => {
        if (!staplingChecks && odp && odp.stapling && !errorsStaplingChecks && !loadingStaplingChecks) {
            dispatch(getStaplingChecksAPI(odp));
        }
    }, [dispatch, odp, staplingChecks, errorsStaplingChecks, loadingStaplingChecks])

    //PINZATURA: primo caricamento stapling machineVars
    useEffect(() => {
        if (!staplingMachineVars && odp && odp.stapling && !errorsStaplingMachineVars && !loadingStaplingMachineVars) {
            dispatch(getStaplingMachineVarsAPI(odp));
        }
    }, [dispatch, odp, staplingMachineVars, errorsStaplingMachineVars, loadingStaplingMachineVars])

    useEffect(() => {
        if (!staplingComponents && odp && odp.stapling) {
            dispatch(getStaplingComponentsAPI(odp, configuration.retrieveLotInfo,true));
        }
    }, [dispatch, odp, staplingComponents, configuration.retrieveLotInfo])

    return (
        null
    )
};

export default ReloadingStaplingOdp;
