import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../../utility/customLabel";
import { makeStyles } from '@material-ui/core/styles';
import {
  TableRow, TableHead, TableCell, TableBody, TableContainer,
  TableSortLabel, TablePagination, Table, TextField, Checkbox, IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from "@material-ui/lab";
import { selector, loadMaintenancesAPI, setReload, initFilter, changeFilter, addToProcess, removeToProcess, sendToProcessAPI } from './slice';
import moment from "moment";
import { bishopFormats } from '../../../constants';
import { Redirect } from 'react-router'
import { StyledTableRow } from "../../utility/TableList";
import { maintenanceTypesSelector, getListAPI as getMaintenanceTypes } from "../../maintenanceTypes/slice";
import { workcentresSelector, getListAPI as getWorkcentresAPI } from "../../workcentres/slice";
import { odpStatusesSelector, getListAPI as getOdpStatusesAPI } from "../../odpStatuses/slice";
import { machinesSelector, getFullListAPI as getMachinesAPI } from "../../machines/slice";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { doChange } from '../slice';
import PaperAutocomplete from "../../utility/PaperAutocomplete";
import { configurationSelector } from "../../configuration/slice";
import ButtonsFragment from "./ButtonsFragment";
import { useAnchor } from '../../customHooks/useAnchor';
import PopoverLegend from '../../utility/PopoverLegend';

const useStyles = makeStyles((theme) => ({
  root: {
    //marginTop: "8px"
  },
  title: {
    flex: '1 1 100%',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  defaultOdp: {
    borderLeftWidth: "10px",
    borderLeftStyle: "solid",
  },
  selected: {
    backgroundColor: "#0000001a",
    marginLeft: theme.spacing()
  }
}));

const MaintenancesStatusPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { odps, total, filter, toProcess, reload, loading } = useSelector(selector);
  const { maintenanceTypes } = useSelector(maintenanceTypesSelector);
  const { workcentres } = useSelector(workcentresSelector);
  const { odpStatuses } = useSelector(odpStatusesSelector);
  const { machines } = useSelector(machinesSelector);
  const [redirect, setRedirect] = React.useState(false);
  const { configuration } = useSelector(configurationSelector);
  const [anchorPopover, { handleOpen: handleOpenPopover, handleClose: handleClosePopover }, openPopover] = useAnchor();

  useEffect(() => {
    dispatch(getMaintenanceTypes(0, 99999, "name", "asc", false, false, false, false));
    dispatch(getWorkcentresAPI(0, 10000, "code", "asc", false, false));
    dispatch(getMachinesAPI());
    dispatch(getOdpStatusesAPI(0, 10000, "name", "asc", false));
    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    if (!filter && configuration) {
      dispatch(initFilter(configuration.showFilters))
    }
  }, [configuration, filter, dispatch])

  useEffect(() => {
    dispatch(setReload());
  }, [dispatch, filter]);

  useEffect(() => {
    if (filter && reload && !loading) {
      dispatch(loadMaintenancesAPI(filter));
    }
  }, [dispatch, filter, reload, loading])

  const handleChangePage = (event, newPage) => {
    dispatch(changeFilter({ name: "page", value: newPage }))
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changeFilter({ name: "rowsPerPage", value: parseInt(event.target.value, 10) }))
    dispatch(changeFilter({ name: "page", value: 0 }))
  };

  const onRequestSort = (property) => {
    const isAsc = filter.orderBy === property && filter.order === "asc";
    let newOrder = isAsc ? "desc" : "asc";
    dispatch(changeFilter({ name: "order", value: newOrder }))
    dispatch(changeFilter({ name: "orderBy", value: property }))
  };

  const onChangeFilterAutocomplete = (event, value, reason, name) => {
    dispatch(changeFilter({ name: "page", value: 0 }));
    if (reason === "clear") {
      changeFilters(name, false);
    } else {
      changeFilters(name, value);
    }
  };

  const changeFilters = (name, value) => {
    dispatch(changeFilter({ name: name, value: value }));
  };

  const handleChangeDate = (name, value) => {
    if (name.endsWith("From")) {
      const newValue = value.startOf('day')
      dispatch(changeFilter({ name: name, value: new Date(newValue.format()).getTime() }))
    }
    else if (name.endsWith("To")) {
      const newValue = value.endOf('day')
      dispatch(changeFilter({ name: name, value: new Date(newValue.format()).getTime() }))
    }
  };

  const handleChangeWork = (event) => {
    if (event.target.checked) {
      dispatch(addToProcess(event.target.name))
    } else {
      dispatch(removeToProcess(event.target.name))
    }
  }

  const handleResetDate = (e, name) => {
    e.stopPropagation();
    dispatch(changeFilter({ name: name, value: false }))
  }

  const toggleFilter = () => {
    if (!filter.showFilter) {
      dispatch(initFilter(true));
    } else {
      dispatch(initFilter(false));
    }
  }

  const resetFilter = () => {
    dispatch(initFilter(true));
  };

  const handleConfirmToWork = () => {
    dispatch(sendToProcessAPI(toProcess));
  }

  if (redirect) {
    dispatch(doChange('/maintenances'))
    return <Redirect to={redirect} />;
  }

  return (
    <div className={classes.root}>
      <ButtonsFragment filter={filter} toggleFilter={toggleFilter} emptyFilter={resetFilter} handleConfirm={handleConfirmToWork} disabledConfirm={toProcess.length === 0} />
      {filter ? (
        <React.Fragment>
          <TableContainer style={{ maxHeight: "90%" }}>
            <Table stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'small'}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    {customLabel("function.toMaintenance")}
                  </TableCell>
                  <TableCell sortDirection={filter.orderBy === "p.odp.maintenanceType.name" ? filter.order : false}>
                    <TableSortLabel active={filter.orderBy === "p.odp.maintenanceType.name"} direction={filter.orderBy === "p.odp.maintenanceType.name" ? filter.order : "asc"} onClick={() => onRequestSort("p.odp.maintenanceType.name")}>
                      {customLabel("maintenance.maintenanceType")}
                      {filter.orderBy === "p.odp.maintenanceType.name" ? (<span className={classes.visuallyHidden}>{filter.order === "desc" ? "sorted descending" : "sorted ascending"}</span>) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    {customLabel("machine.machine")}
                  </TableCell>
                  <TableCell>
                    {customLabel("workcentre.workcentre")}
                  </TableCell>
                  <TableCell>
                    <TableSortLabel active={filter.orderBy === "p.odp.deliveryDate"} direction={filter.orderBy === "p.odp.deliveryDate" ? filter.order : "asc"} onClick={() => onRequestSort("p.odp.deliveryDate")}>
                      {customLabel("maintenance.deliveryDate")}
                      {filter.orderBy === "p.odp.deliveryDate" ? (<span className={classes.visuallyHidden}>{filter.order === "desc" ? "sorted descending" : "sorted ascending"}</span>) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel active={filter.orderBy === "p.odp.status"} direction={filter.orderBy === "p.odp.status" ? filter.order : "asc"} onClick={() => onRequestSort("p.odp.status")}>
                      {customLabel("odp.status")}
                      {filter.orderBy === "p.odp.status" ? (<span className={classes.visuallyHidden}>{filter.order === "desc" ? "sorted descending" : "sorted ascending"}</span>) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    {customLabel("odp.startDate")}
                  </TableCell>
                  <TableCell>
                    {customLabel("odp.endDate")}
                  </TableCell>
                </TableRow>
                {filter.showFilter ? (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      {maintenanceTypes ? (
                        <Autocomplete
                          PaperComponent={PaperAutocomplete}
                          options={maintenanceTypes} filterSelectedOptions size="small"
                          getOptionSelected={(option, value) => option.id === value.id}
                          onChange={(event, value, reason) => onChangeFilterAutocomplete(event, value, reason, "maintenanceType")}
                          getOptionLabel={(option) => option.extraordinary ? customLabel("maintenance.extraordinary").toUpperCase() : option.name}
                          renderInput={(params) => (<TextField {...params} placeholder={customLabel("maintenanceType.maintenanceTypes")} />)}
                          value={filter.maintenanceType ? filter.maintenanceType : null}
                        />
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        PaperComponent={PaperAutocomplete}
                        options={machines ? machines : []} filterSelectedOptions size="small"
                        getOptionSelected={(option, value) => option.id === value.id}
                        onChange={(event, value, reason) => onChangeFilterAutocomplete(event, value, reason, "machine")}
                        getOptionLabel={(option) => option.code}
                        renderInput={(params) => (<TextField {...params} placeholder={customLabel("machine.machine")} />)}
                        value={filter.machine ? filter.machine : null} />
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        PaperComponent={PaperAutocomplete}
                        options={workcentres ? workcentres : []} filterSelectedOptions size="small"
                        getOptionSelected={(option, value) => option.id === value.id}
                        onChange={(event, value, reason) => onChangeFilterAutocomplete(event, value, reason, "workcentre")}
                        getOptionLabel={(option) => option.code}
                        renderInput={(params) => (<TextField {...params} placeholder={customLabel("workcentre.workcentre")} />)}
                        value={filter.workcentre ? filter.workcentre : null} />
                    </TableCell>
                    <TableCell>
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DatePicker
                          InputProps={{
                            endAdornment: (
                              <IconButton size="small" onClick={(e) => handleResetDate(e, "deliveryDateFrom")}>
                                <CloseIcon />
                              </IconButton>
                            )
                          }}
                          disableToolbar
                          allowKeyboardControl={false}
                          variant="inline"
                          format={bishopFormats.L}
                          autoOk
                          onChange={(date) => handleChangeDate("deliveryDateFrom", date)}
                          label={customLabel("function.from")}
                          value={filter.deliveryDateFrom ? filter.deliveryDateFrom : null}
                        />
                      </MuiPickersUtilsProvider>
                      <br />
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DatePicker
                          InputProps={{
                            endAdornment: (
                              <IconButton size="small" onClick={(e) => handleResetDate(e, "deliveryDateTo")}>
                                <CloseIcon />
                              </IconButton>
                            )
                          }}
                          disableToolbar
                          allowKeyboardControl={false}
                          variant="inline"
                          format={bishopFormats.L}
                          autoOk
                          onChange={(date) => handleChangeDate("deliveryDateTo", date)}
                          label={customLabel("function.to")}
                          value={filter.deliveryDateTo ? filter.deliveryDateTo : null}
                        />
                      </MuiPickersUtilsProvider>
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        PaperComponent={PaperAutocomplete}
                        options={odpStatuses ? odpStatuses : []} filterSelectedOptions
                        getOptionSelected={(option, value) => option.id === value.id}
                        onChange={(event, value, reason) => onChangeFilterAutocomplete(event, value, reason, "status")}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (<TextField {...params} placeholder={customLabel("odpStatus.odpStatus")} />)}
                        value={filter.status ? filter.status : null} />
                    </TableCell>
                    <TableCell>
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DatePicker
                          InputProps={{
                            endAdornment: (
                              <IconButton size="small" onClick={(e) => handleResetDate(e, "startDateFrom")}>
                                <CloseIcon />
                              </IconButton>
                            )
                          }}
                          disableToolbar
                          allowKeyboardControl={false}
                          variant="inline"
                          format={bishopFormats.L}
                          autoOk
                          onChange={(date) => handleChangeDate("startDateFrom", date)}
                          label={customLabel("function.from")}
                          value={filter.startDateFrom ? filter.startDateFrom : null}
                        />
                      </MuiPickersUtilsProvider>
                      <br />
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DatePicker
                          InputProps={{
                            endAdornment: (
                              <IconButton size="small" onClick={(e) => handleResetDate(e, "startDateTo")}>
                                <CloseIcon />
                              </IconButton>
                            )
                          }}
                          disableToolbar
                          allowKeyboardControl={false}
                          variant="inline"
                          format={bishopFormats.L}
                          autoOk
                          onChange={(date) => handleChangeDate("startDateTo", date)}
                          label={customLabel("function.to")}
                          value={filter.startDateTo ? filter.startDateTo : null}
                        />
                      </MuiPickersUtilsProvider>
                    </TableCell>
                    <TableCell>
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DatePicker
                          InputProps={{
                            endAdornment: (
                              <IconButton size="small" onClick={(e) => handleResetDate(e, "endDateFrom")}>
                                <CloseIcon />
                              </IconButton>
                            )
                          }}
                          disableToolbar
                          allowKeyboardControl={false}
                          variant="inline"
                          format={bishopFormats.L}
                          autoOk
                          onChange={(date) => handleChangeDate("endDateFrom", date)}
                          label={customLabel("function.from")}
                          value={filter.endDateFrom ? filter.endDateFrom : null}
                        />
                      </MuiPickersUtilsProvider>
                      <br />
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DatePicker
                          InputProps={{
                            endAdornment: (
                              <IconButton size="small" onClick={(e) => handleResetDate(e, "endDateTo")}>
                                <CloseIcon />
                              </IconButton>
                            )
                          }}
                          disableToolbar
                          allowKeyboardControl={false}
                          variant="inline"
                          format={bishopFormats.L}
                          autoOk
                          onChange={(date) => handleChangeDate("endDateTo", date)}
                          label={customLabel("function.to")}
                          value={filter.endDateTo ? filter.endDateTo : null}
                        />
                      </MuiPickersUtilsProvider>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableHead>
              {odps ? (
                <TableBody>
                  {odps.map((row, index) => {
                    return (
                      <StyledTableRow style={{ cursor: "pointer" }} key={row.id}>
                        <TableCell align="center">
                          {!row.toBeProcessed ?
                            <Checkbox checked={toProcess.indexOf(row.id.toString()) > -1} onChange={handleChangeWork} name={row.id.toString()} color="default" /> : null
                          }
                        </TableCell>
                        <TableCell onClick={() => setRedirect(`/maintenances/${row.id}`)}>
                          {row.extraordinary ? customLabel("maintenance.extraordinary").toUpperCase() : row.maintenanceType.name}
                        </TableCell>
                        <TableCell onClick={() => setRedirect(`/maintenances/${row.id}`)}>
                          {row.machine.code} - {row.machine.name}
                        </TableCell>
                        <TableCell onClick={() => setRedirect(`/maintenances/${row.id}`)}>
                          {row.machine.workcentre.code} - {row.machine.workcentre.name}
                        </TableCell>
                        <TableCell onClick={() => setRedirect(`/maintenances/${row.id}`)}>
                          {row.deliveryDate !== null ? moment(row.deliveryDate).format(bishopFormats.L) : null}
                        </TableCell>
                        <TableCell onClick={() => setRedirect(`/maintenances/${row.id}`)} className={classes.defaultOdp} style={{ borderLeftColor: row.status.backgroundColor }}
                          onMouseEnter={(e) => handleOpenPopover(e.currentTarget, false)} onMouseLeave={handleClosePopover}>
                          {row.status.name}
                        </TableCell>
                        <TableCell onClick={() => setRedirect(`/maintenances/${row.id}`)}>
                          {row.startDate !== null ? moment(row.startDate).format(bishopFormats.LT) : null}
                        </TableCell>
                        <TableCell onClick={() => setRedirect(`/maintenances/${row.id}`)}>
                          {row.endDate !== null ? moment(row.endDate).format(bishopFormats.LT) : null}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
          <TablePagination labelRowsPerPage={customLabel("function.rowsPerPage")} rowsPerPageOptions={[1, 2, 5, 10, 25]} component="div" count={total} rowsPerPage={filter.rowsPerPage} page={filter.page} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage} />
          {anchorPopover ? (
            <PopoverLegend name={customLabel("legend.legend").toUpperCase()} list={odpStatuses} open={openPopover} anchorEl={anchorPopover}
              openPopover={handleOpenPopover} closePopover={handleClosePopover}
              anchorVertical={"center"} anchorHorizontal={"left"} transformVertical={"center"} transormHorizontal={"right"} />
          ) : null}
        </React.Fragment>
      ) : null}
    </div>
  );
}
export default MaintenancesStatusPage