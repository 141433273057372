import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export const useSocketOnce = (lastMessage,process,callback) => {
    const [lastTimestamp, setLastTimestamp] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        if (lastMessage != null && lastTimestamp !== lastMessage.timeStamp) {
            callback(lastMessage)
            setLastTimestamp(lastMessage.timeStamp)
        }
    }, [dispatch, lastMessage, process, lastTimestamp, setLastTimestamp, callback])


    return null;
}