import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfHome from "./ConfHome";
import {
    configurationSelector,
    edit,
    getAPI
} from "./slice";

const ConfigurationPage = () => {
    const dispatch = useDispatch();
    const { configuration } = useSelector(configurationSelector);

    useEffect(() => {
        dispatch(getAPI());
    }, [dispatch]);

    const onEdit = () => {
        dispatch(edit());
    };

    const renderconfiguration = () => {
        if (configuration !== false)
            return (
                <React.Fragment>
                    <ConfHome edit={onEdit} />
                </React.Fragment>
            );
        return;
    };

    return <Box>{renderconfiguration()}</Box>;
};

export default ConfigurationPage;
