import React from "react";
import { Link } from "react-router-dom";
import {
  Typography, Button, FormControl, InputLabel, Input
} from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockIcon from '@material-ui/icons/Lock';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";

export const Details = ({ maintenanceType, edit, toggleDelete, deleting, doDelete, loading }) => {
  const myClasses = mesStyles();

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">{customLabel("maintenanceType.maintenanceType")}</Typography>
        <LockIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50} disabled>
          <InputLabel htmlFor="code">{customLabel("maintenanceType.name")}</InputLabel>
          <Input value={maintenanceType.name} />
        </FormControl>
      </form>
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple key={maintenanceType.id} disabled={loading} onClick={edit} color="primary">{customLabel("button.edit")}</Button>
        <Button color="secondary" disabled={loading} onClick={toggleDelete}>{customLabel("button.delete")}</Button>
        <Button component={Link} to={`/admin/maintenanceTypes`} disabled={loading}>{customLabel("button.back")}</Button>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={toggleDelete} disabled={loading} />
    </PaperForm>
  );
};
