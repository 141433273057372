import React from "react";
import {
    Box, Table, TableHead, TableRow, TableCell, TableBody
} from "@material-ui/core";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";

const Labels = ({ configuration }) => {
    const myClasses = mesStyles();
    return (
        <Box className={myClasses.confDetailsBox}>
            <Table size="small" style={{ pointerEvents: 'none', opacity: 0.5 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>{customLabel("configuration.labelCode")}</TableCell>
                        <TableCell>{customLabel("configuration.labelValue")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {configuration && configuration.labels && configuration.labels.map((l) => {
                        return (
                            <TableRow key={l.id}>
                                <TableCell>{l.code}</TableCell>
                                <TableCell>{l.value}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </Box>
    );
};
export default Labels;