import MomentUtils from "@date-io/moment";
import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from "@material-ui/core";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { customLabel } from "../utility/customLabel";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { Redirect } from 'react-router';
import { bishopFormats } from '../../constants';
import PaperAutocomplete from "./PaperAutocomplete";
import { mesStyles } from "./ultrafabStyles";

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export const GreyRow = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  selected: {
    backgroundColor: "#0000001a"
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
  actionCell: {
    textAlign: "right",
  },
}));

export default function EnhancedTable(props) {
  const { items, headers, mainObject, redirectObject, setPage, setRowsPerPage, results,
    onRequestSort, changeFilters, resetFilter, filter, applyReload } = props;

  const myClasses = mesStyles();
  const classes = useStyles();
  const [redirect, setRedirect] = React.useState(false);
  const [emptyRows, setEmptyRows] = React.useState(0)
  const isInitialMount = useRef(true);

  useEffect(() => {
    setEmptyRows(filter.rowsPerPage - items.length)
  }, [items, setEmptyRows, filter.rowsPerPage])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      let reloadTimeout = setTimeout(() => {
        applyReload()
      }, 500)
      return () => {
        clearTimeout(reloadTimeout)
      }
    }
  }, [filter, applyReload])

  const onChangeFilterAutocomplete = (event, value, reason, name) => {
    setPage(null, 0);
    if (reason === "clear") {
      changeFilters(name, false);
    } else {
      changeFilters(name, value);
    }
  };

  const onDoChangeFilter = (name, value) => {
    setPage(null, 0);
    changeFilters(name, value);
  }

  const onChangeFilter = (event) => {
    //clearTimeout(reloadTimeout)
    let name = event.target.name
    let value = event.target.value
    onDoChangeFilter(name, value);
    /* reloadTimeout = setTimeout(() => {
      applyReload()
    }, 2000); */
  };

  const returnCheck = (val) => {
    if (val) {
      return <CheckIcon />
    }
    else {
      return null
    }
  }

  const returnBooleanValue = (val, values) => {
    if (val) {
      return values[1].name
    }
    else {
      return values[0].name
    }
  }

  const returnObjectList = (list) => {
    return list.map((item, i) => {
      return <span key={item.name}>{item.name}{list.length > 1 && i !== list.length - 1 ? ", " : null}</span>
    })
  }

  const handleChangeDate = (name, value) => {
    if (name.endsWith("From")) {
      const newValue = value.startOf('day')
      changeFilters(name, new Date(newValue.format()).getTime())
    }
    else if (name.endsWith("To")) {
      const newValue = value.endOf('day')
      changeFilters(name, new Date(newValue.format()).getTime())
    }
  };

  const handleResetDate = (e, name) => {
    e.stopPropagation();
    changeFilters(name, false)
  };

  const renderOptionValue = (val, option) => {
    if (val) {
      return option[val] != null && option[val] !== undefined ? option[val] : customLabel("function.notAvailable")
    }
    return option.name
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  if (filter) {
    return (
      <div className={classes.root}>
        <TableContainer style={{ maxHeight: "90%" }}>
          <Table stickyHeader className={classes.table} size="small">
            <TableHead>
              <TableRow>
                {Object.keys(headers).map((h) => {
                  return (
                    <TableCell key={h} align={headers[h].type === "numeric" || headers[h].type === "date" || headers[h].type === "datetime" ? "right" : "left"} sortDirection={filter.orderBy === h ? filter.order : false}>
                      <TableSortLabel active={filter.orderBy === h} direction={filter.orderBy === h ? filter.order : "asc"} onClick={() => onRequestSort(h)}>
                        {customLabel(mainObject + h)}
                        {filter.orderBy === h ? (<span className={classes.visuallyHidden}>{filter.order === "desc" ? "sorted descending" : "sorted ascending"}</span>) : null}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
              {filter.showFilter ? (
                <React.Fragment>
                  <TableRow>
                    {Object.keys(headers).map((h, i) => {
                      if (headers[h].filter && headers[h].type !== "date" && headers[h].type !== "datetime") {
                        let hf = h
                        if (h.match(".")) {
                          hf = h.split(".")[0]
                        }
                        return (
                          <TableCell key={"filter" + h + i}>
                            {props[hf + "List"] !== false ? (
                              <Autocomplete
                                PaperComponent={PaperAutocomplete}
                                multiple={headers[h].multiple !== undefined ? headers[h].multiple : false}
                                options={props[hf + "List"]} filterSelectedOptions
                                value={filter[hf] ? filter[hf] : headers[h].multiple !== undefined ? [] : null}
                                getOptionSelected={(option, value) => headers[h].type === "booleanString" || headers[h].type === "object" || headers[h].type === "objectList" ? option.id === value.id : value}
                                onChange={(event, value, reason) => onChangeFilterAutocomplete(event, value, reason, hf)}
                                getOptionLabel={(option) => headers[h].type === "booleanString" || headers[h].type === "object" || headers[h].type === "objectList" ? renderOptionValue(headers[h].val, option) : headers[h].type === "stringL" ? customLabel(mainObject + h + "." + option) : option}
                                renderInput={(params) => (<TextField {...params} placeholder={customLabel(mainObject + h)} />)} />) : null}
                            {props[hf + "List"] === false ? (
                              <TextField name={h} onChange={onChangeFilter} value={filter[hf] ? filter[hf] : ""} />
                            ) : null}
                          </TableCell>
                        );
                      } else if (headers[h].filter && (headers[h].type === "date" || headers[h].type === "datetime")) {
                        return (
                          <TableCell key={"filter" + h} align="right">
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                              <DatePicker
                                className={myClasses.datePickerTable}
                                InputProps={{
                                  endAdornment: (
                                    <IconButton size="small" onClick={(e) => handleResetDate(e, headers[h]["name"] + "From")}>
                                      <CloseIcon />
                                    </IconButton>
                                  )
                                }}
                                value={filter[headers[h]["name"] + "From"] ? filter[headers[h]["name"] + "From"] : null}
                                onChange={(date) => handleChangeDate(headers[h]["name"] + "From", date)}
                                label={customLabel("function.from")}
                                format={bishopFormats.L}
                                autoOk
                                variant="inline"
                                disableToolbar
                                allowKeyboardControl={false}
                              />
                            </MuiPickersUtilsProvider>
                            <br />
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                              <DatePicker className={myClasses.datePickerTable}
                                InputProps={{
                                  endAdornment: (
                                    <IconButton size="small" onClick={(e) => handleResetDate(e, headers[h]["name"] + "To")}>
                                      <CloseIcon />
                                    </IconButton>
                                  )
                                }}
                                label={customLabel("function.to")}
                                value={filter[headers[h]["name"] + "To"] ? filter[headers[h]["name"] + "To"] : null}
                                onChange={(date) => handleChangeDate(headers[h]["name"] + "To", date)}
                                format={bishopFormats.L}
                                autoOk
                                variant="inline"
                                disableToolbar
                                allowKeyboardControl={false}
                              />
                            </MuiPickersUtilsProvider>
                          </TableCell>
                        )
                      } else {
                        return <TableCell key={"filter" + h}></TableCell>;
                      }
                    })}
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={Object.keys(headers).length}>
                      <Button onClick={() => resetFilter()} color="secondary">{customLabel("button.emptyFilters")}</Button>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ) : null}
            </TableHead>
            <TableBody>
              {items.map((row) => {
                return (
                  <StyledTableRow style={{ cursor: "pointer" }} key={row.id} onClick={() => setRedirect(`/${redirectObject}/${row.id}`)}>
                    {Object.keys(headers).map((h, i) => {
                      let obj = headers[h];
                      let val
                      if (h.match(".")) {
                        val = h.split(".")[1]
                        h = h.split(".")[0]
                      }
                      if (row[h] !== null) {
                        return (
                          <TableCell key={row.id + h + i} align={obj.type === "numeric" || obj.type === "date" || obj.type === "datetime" ? "right" : "justify"}>
                            {obj.type === "object" ? renderOptionValue(val, row[h]) : obj.type === "objectList" ? returnObjectList(row[headers[h].name]) :
                              obj.type === "date" ? moment(row[h]).format(bishopFormats.L) : obj.type === "datetime" ? moment(row[h]).format(bishopFormats.LTS) :
                                obj.type === "boolean" ? returnCheck(row[h]) : obj.type === "booleanString" ? returnBooleanValue(row[h], props[h + "List"]) :
                                  obj.type === "stringL" ? customLabel(mainObject + h + "." + row[h]) : row[h]}
                          </TableCell>
                        );
                      } else {
                        return <TableCell key={row.id + h}></TableCell>;
                      }
                    })}
                  </StyledTableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={Object.keys(headers).length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination labelRowsPerPage={customLabel("function.rowsPerPage")} rowsPerPageOptions={[2, 5, 10, 25, 50, 100]} component="div" count={results} rowsPerPage={filter.rowsPerPage} page={filter.page} onChangePage={(event, page) => setPage(event, page)} onChangeRowsPerPage={setRowsPerPage} />
      </div>
    );
  }
  return null
}
