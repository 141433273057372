import { Box, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { getListAPI as getOdpStatusesAPI, odpStatusesSelector, } from "../odpStatuses/slice";
import { getListAPI as getProcessStatusesAPI, processStatusesSelector, } from "../processStatuses/slice";
import CallbacksPage from "./CallbacksPage";
import { Details } from "./Details";
import { Form } from "./Form";
import MachinesPage from "./MachinesPage";
import ReasonsPage from "./ReasonsPage";
import {
  cancelEdit, change,
  confirmDelete,
  create,
  createAPI, deleteAPI, edit,
  getAPI,
  getListAPI as getMachineEventsAPI,
  machineEventsSelector,
  updateAPI
} from "./slice";

const MachineEventPage = ({ match }) => {
  const dispatch = useDispatch();
  const { machineEvent, machineEventedit, editmode, deleting, isCreated, isDeleted, reload, loading,
  } = useSelector(machineEventsSelector);
  const { odpStatuses } = useSelector(odpStatusesSelector);
  const { processStatuses } = useSelector(processStatusesSelector);

  useEffect(() => {
    if (reload === true && loading === false) {
      dispatch(getAPI(machineEvent.id));
    }
  }, [dispatch, reload, loading, machineEvent]);

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    dispatch(
      change({ name: name, value: value, odpStatuses: odpStatuses, processStatuses: processStatuses }));
  };

  const toggleDelete = (event) => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(machineEventedit));
  };

  const onEdit = () => {
    dispatch(edit());
  };
  const doDelete = () => {
    dispatch(deleteAPI(machineEvent));
  };

  const save = () => {
    if (machineEvent.id === "new") {
      dispatch(createAPI(machineEventedit));
    } else {
      dispatch(updateAPI(machineEventedit));
    }
  };

  useEffect(() => {
    dispatch(getOdpStatusesAPI(0, 100000, "name", "asc", false));
    dispatch(getProcessStatusesAPI(0, 100000, "name", "asc", false));
    dispatch(getMachineEventsAPI(0, 10000, "name", "asc", false));

    const { id } = match.params;
    if (id !== "new") {
      dispatch(getAPI(id));
    } else {
      dispatch(create());
    }
  }, [dispatch, match]);

  const rendermachineEvent = () => {
    if (
      machineEvent !== false &&
      !editmode &&
      match.params.id === machineEvent.id.toString()
    )
      return (
        <Grid spacing={2} container alignItems="flex-start" justify="flex-start"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Details machineEvent={machineEvent} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting}
              doDelete={doDelete} loading={loading} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ReasonsPage machineEvent={machineEvent} loading={loading} />
            <CallbacksPage machineEvent={machineEvent} loading={loading} />
            <MachinesPage machineEvent={machineEvent} extLoading={loading} />
          </Grid>
        </Grid>
      );
    if (machineEvent !== false && editmode)
      return (
        <Form machineEventedit={machineEventedit} handleChange={handleChange} cancelEdit={onCancelEdit}
          save={save} odpStatuses={odpStatuses} processStatuses={processStatuses} loading={loading} />);
    return;
  };

  if (isDeleted && match.params.id === machineEvent.id.toString()) {
    return <Redirect to={"/admin/machineEvents/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/admin/machineEvents/" + machineEvent.id} />;
  }

  return <Box>{rendermachineEvent()}</Box>;
};

export default MachineEventPage;
