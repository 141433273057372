import React from 'react'
import { Link } from 'react-router-dom'
import { FormControl, InputLabel, Input, Divider, Button, Typography, FormControlLabel, Checkbox, MenuItem, Select } from '@material-ui/core'
import { customLabel } from "../utility/customLabel";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";

export const Form = ({ processTypeedit, handleChange, cancelEdit, save, maintenance, loading }) => {

  const myClasses = mesStyles();

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit"> {customLabel(maintenance ? "maintenancePhase.maintenancePhase" : "processType.processType")}</Typography>
        <LockOpenIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="code">{customLabel("processType.code")}</InputLabel>
          <Input autoFocus name="code" error={processTypeedit.code === ""} value={processTypeedit.code} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="name">{customLabel("processType.name")}</InputLabel>
          <Input name="name" error={processTypeedit.name === ""} value={processTypeedit.name} onChange={handleChange} />
        </FormControl>
        {processTypeedit.id !== "new" && !maintenance ? (
          <React.Fragment>
            <FormControl className={myClasses.form50}>
              <InputLabel htmlFor="type">{customLabel("processType.validPieces")}</InputLabel>
              <Select name="validPieces" value={processTypeedit.validPieces ? processTypeedit.validPieces : ""} onChange={handleChange}>
                <MenuItem value=""><i>{customLabel("function.none")}</i></MenuItem>
                {processTypeedit.vars.map(c => {
                  return <MenuItem key={c.id} value={c.name}>{c.name}</MenuItem>
                })}
              </Select>
            </FormControl>
            <FormControl className={myClasses.form50}>
              <InputLabel htmlFor="type">{customLabel("processType.rejectedPieces")}</InputLabel>
              <Select name="rejectedPieces" value={processTypeedit.rejectedPieces ? processTypeedit.rejectedPieces : ""} onChange={handleChange}>
                <MenuItem value=""><i>{customLabel("function.none")}</i></MenuItem>
                {processTypeedit.vars.map(c => {
                  return <MenuItem key={c.id} value={c.name}>{c.name}</MenuItem>
                })}
              </Select>
            </FormControl>
            <FormControl className={myClasses.form50}>
              <InputLabel htmlFor="type">{customLabel("processType.totalPieces")}</InputLabel>
              <Select name="totalPieces" value={processTypeedit.totalPieces ? processTypeedit.totalPieces : ""} onChange={handleChange}>
                <MenuItem value=""><i>{customLabel("function.none")}</i></MenuItem>
                {processTypeedit.vars.map(c => {
                  return <MenuItem key={c.id} value={c.name}>{c.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </React.Fragment>
        ) : null}
        {!maintenance ?
          <React.Fragment>
            <br />
            <FormControlLabel
              control={<Checkbox checked={processTypeedit.multiple} onChange={handleChange} name="multiple" color="default" />}
              label={customLabel("processType.multiple")}
            />
            <FormControlLabel
              control={<Checkbox checked={processTypeedit.autostart} onChange={handleChange} name="autostart" color="default" />}
              label={customLabel("processType.autostart")}
            />
            <FormControlLabel
              control={<Checkbox checked={processTypeedit.external} onChange={handleChange} name="external" color="default" />}
              label={customLabel("processType.external")}
            />
            <FormControlLabel
              control={<Checkbox checked={processTypeedit.autoComponentConsumption} onChange={handleChange} name="autoComponentConsumption" color="default" />}
              label={customLabel("processType.autoComponentConsumption")}
            />
          </React.Fragment> : null
        }
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple onClick={save} disabled={processTypeedit.name === "" || processTypeedit.code === "" || loading} color="primary">{customLabel("button.save")}</Button>
        {processTypeedit.id !== "new" ?
          <Button focusRipple onClick={cancelEdit} disabled={loading}>{customLabel("button.cancel")}</Button>
          :
          <Button component={Link} to={maintenance ? `/admin/maintenancePhases` : `/admin/processTypes`} disabled={loading}>{customLabel("button.back")}</Button>
        }
      </div>
    </PaperForm>
  )
}
