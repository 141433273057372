import React, { useEffect } from "react";
import clsx from "clsx";
import { ListItem, ListItemText, ListItemIcon, Collapse } from "@material-ui/core";
import classNames from "classnames";
import LockIcon from "@material-ui/icons/Lock";
import HomeIcon from "@material-ui/icons/Home";
import WorkIcon from "@material-ui/icons/Work";
import BuildIcon from "@material-ui/icons/Build";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import PersonIcon from "@material-ui/icons/Person";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import StoreIcon from "@material-ui/icons/Store";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import UpdateIcon from '@material-ui/icons/Update';
import LayersIcon from '@material-ui/icons/Layers';
import DvrIcon from '@material-ui/icons/Dvr';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { customLabel } from "../utility/customLabel";
import { mainSelector, logout } from "../main/slice";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, List, Divider } from "@material-ui/core";
import TimelineIcon from '@material-ui/icons/Timeline';
import ExtensionIcon from '@material-ui/icons/Extension';
import TransportIcon from '@material-ui/icons/EmojiTransportation';
import SettingsIcon from '@material-ui/icons/Settings';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import SpeedIcon from '@material-ui/icons/Speed';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { selector, doChange, addNavigation } from "./slice";
import { resetState as resetArticles } from "../articles/slice";
import { resetState as resetCustomerOrders } from "../customerOrders/slice";
import { resetState as resetCustomers } from "../customers/slice";
import { resetState as resetMeasureTools } from "../measureTools/slice";
import { resetState as resetBoms } from "../boms/slice";
//import { resetState as resetSmartOdp } from "../smartOdp/slice";
import { resetState as resetUsers } from "../users/slice";
import { resetState as resetWorkcentres } from "../workcentres/slice";
import { resetState as resetUserEvents } from "../userEvents/slice";
import { resetState as resetMachineEvents } from "../machineEvents/slice"
import { resetState as resetOdps } from "../odps/slice";
import { resetState as resetSuppliers } from "../suppliers/slice";
import { liveDataItems, registryItems, adminItems, menuLabel } from "./utility";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: "#0000001a",
  },
  drawerPaper: {
    paddingTop: theme.spacing(7.5),
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    paddingTop: theme.spacing(7.5),
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  pointer: {
    cursor: "pointer"
  }
}));


const MenuPage = ({ currentUser, configuration }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [adminOpen, setAdminOpen] = React.useState(false);
  const [liveDataOpen, setLiveDataOpen] = React.useState(false);
  const [registryOpen, setRegistryOpen] = React.useState(false);
  const { isAdmin, isSuperAdmin } = useSelector(mainSelector);
  const { current } = useSelector(selector);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlePageChange = (liveData, registryData, adminData) => {
    setLiveDataOpen(liveData)
    setRegistryOpen(registryData)
    setAdminOpen(adminData)
  }

  const handleClick = (item) => {
    if (item === menuLabel.liveData) {
      handlePageChange(!liveDataOpen, false, false)
    } else if (item === menuLabel.registryData) {
      handlePageChange(false, !registryOpen, false)
    } else if (item === menuLabel.adminData) {
      handlePageChange(false, false, !adminOpen)
    }
  }

  useEffect(() => {
    if (liveDataItems.includes(current.split('/')[1])) {
      handlePageChange(true, false, false)
    } else if (registryItems.includes(current.split('/')[1])) {
      handlePageChange(false, true, false)
    } else if (adminItems.includes(current.split('/')[2])) {
      handlePageChange(false, false, true)
    }
  }, [current])

  const change = (url, nav) => {
    //per ricaricare oggetti smartodp
    if (url === "/smart_odp") {
      dispatch(resetArticles());
      dispatch(resetBoms());
      dispatch(resetMeasureTools());
      dispatch(resetCustomerOrders());
      dispatch(resetCustomers());
    }
    //svuoto users e workcentres per avere timeline aggiornata
    if (url === "/timeline") {
      dispatch(resetUsers());
      dispatch(resetWorkcentres());
      dispatch(resetUserEvents());
      dispatch(resetMachineEvents());
      //quando vado su dettagli rimane currentOdp vecchio
      dispatch(resetOdps());
    }
    //svuoto odps perché può essere usato per crea commessa da ordine cliente
    if (url === "/customerOrders") {
      dispatch(resetOdps());
    }
    if (url === "/odpStatus") {
      //quando vado su dettagli rimane currentOdp vecchio
      dispatch(resetOdps());
    }
    if (url === "/odps") {
      dispatch(resetSuppliers());
    }
    dispatch(doChange(url))
    let name = customLabel(nav)
    dispatch(addNavigation({ url: url, name: name }))
  };

  const goToOperatorView = () => {
    window.location.href = window.location.origin + "/operatorView"
  }

  return (
    <Drawer variant="permanent" classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }} open={open}>
      <List>
        {!open ? (
          <ListItem style={{ height: "30px" }} >
            <ListItemIcon onClick={handleDrawerOpen} ><ChevronRightIcon /></ListItemIcon>
          </ListItem>
        ) : (
          <ListItem style={{ height: "30px" }}>
            <ListItemIcon className={classes.pointer} onClick={() => handleDrawerClose()} ><ChevronLeftIcon /></ListItemIcon>
            <ListItemText className={classes.pointer} title="logout" onClick={() => dispatch(logout())} primary={currentUser.name} />
          </ListItem>
        )}
        <Divider />
        <ListItem button component={Link} onClick={() => change("/", "menuItem.home")} to="/" className={classNames({ [classes.selected]: current === "/" })}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary={customLabel("menuItem.home")} />
        </ListItem>
        <ListItem button onClick={() => handleClick("liveData")}>
          <ListItemIcon><LiveTvIcon /></ListItemIcon>
          <ListItemText primary={customLabel("menuItem.eventsInProgress")} />
          {liveDataOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={liveDataOpen} timeout="auto" unmountOnExit>
          <ListItem button component={Link} onClick={() => change("/timeline", "menuItem.timeline")} to="/timeline" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/timeline") > -1 })}>
            <ListItemIcon><TimelineIcon /></ListItemIcon>
            <ListItemText primary={customLabel("menuItem.timeline")} />
          </ListItem>
          <ListItem button component={Link} onClick={() => change("/odpStatus", "menuItem.odpStatuses")} to="/odpStatus" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/odpStatus") > -1 && current.indexOf("/odpStatuses") === -1 })}>
            <ListItemIcon><HourglassEmptyIcon /></ListItemIcon>
            <ListItemText primary={customLabel("menuItem.odpStatuses")} />
          </ListItem>
          <ListItem button component={Link} onClick={() => change("/machineStatus", "menuItem.machineStatuses")} to="/machineStatus" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/machineStatus") > -1 && current.indexOf("/machineStatuses") === -1 })}>
            <ListItemIcon><DonutLargeIcon /></ListItemIcon>
            <ListItemText primary={customLabel("menuItem.machineStatuses")} />
          </ListItem>
          <ListItem button component={Link} onClick={() => change("/planner", "menuItem.planner")} to="/planner" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/planner") > -1 })}>
            <ListItemIcon><UpdateIcon /></ListItemIcon>
            <ListItemText primary={customLabel("menuItem.planner")} />
          </ListItem>
          <ListItem button component={Link} onClick={() => change("/workcentreView", "menuItem.workcentreView")} to="/workcentreView" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/workcentreView") > -1 })}>
            <ListItemIcon><StoreIcon /></ListItemIcon>
            <ListItemText primary={customLabel("menuItem.workcentreView")} />
          </ListItem>
          {/* <ListItem button component={Link} onClick={() => change("/manageOdps", "menuItem.advancement")} to="/manageOdps" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/manageOdps") > -1 })}>
              <ListItemIcon><PlaylistAddCheckIcon /></ListItemIcon>
              <ListItemText primary={customLabel("menuItem.advancement")} />
            </ListItem> */}
          {configuration && configuration.enableMaintenance ? (
            <ListItem button component={Link} onClick={() => change("/maintenancesTimeline", "menuItem.maintenances")} to="/maintenancesTimeline" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/maintenancesTimeline") > -1 })}>
              <ListItemIcon><BuildIcon /></ListItemIcon>
              <ListItemText primary={customLabel("menuItem.maintenances")} />
            </ListItem>
          ) : null}
        </Collapse>
        <ListItem button onClick={() => handleClick("registryData")}>
          <ListItemIcon><ImportContactsIcon /></ListItemIcon>
          <ListItemText primary={customLabel("menuItem.registry")} />
          {registryOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={registryOpen} timeout="auto" unmountOnExit>
          <ListItem button component={Link} onClick={() => change("/workcentres", "menuItem.workcentres")} to="/workcentres" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/workcentres") > -1 })}>
            <ListItemIcon><StoreIcon /></ListItemIcon>
            <ListItemText primary={customLabel("menuItem.workcentres")} />
          </ListItem>
          <ListItem button component={Link} onClick={() => change("/customers", "menuItem.customers")} to="/customers" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/customers") > -1 })}>
            <ListItemIcon><SupervisedUserCircleIcon /></ListItemIcon>
            <ListItemText primary={customLabel("menuItem.customers")} />
          </ListItem>
          <ListItem button component={Link} onClick={() => change("/suppliers", "menuItem.suppliers")} to="/suppliers" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/suppliers") > -1 })}>
            <ListItemIcon><PermContactCalendarIcon /></ListItemIcon>
            <ListItemText primary={customLabel("menuItem.suppliers")} />
          </ListItem>
          <ListItem button component={Link} onClick={() => change("/articles", "menuItem.articles")} to="/articles" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/articles") > -1 })}>
            <ListItemIcon><ExtensionIcon /></ListItemIcon>
            <ListItemText primary={customLabel("menuItem.articles")} />
          </ListItem>
          <ListItem button component={Link} onClick={() => change("/customerOrders", "menuItem.orderCustomers")} to="/customerOrders" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/customerOrders") > -1 })}>
            <ListItemIcon><TransportIcon /></ListItemIcon>
            <ListItemText primary={customLabel("menuItem.orderCustomers")} />
          </ListItem>
          <ListItem button component={Link} onClick={() => change("/odps", "menuItem.odps")} to="/odps" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/odps") > -1 })}>
            <ListItemIcon><WorkIcon /></ListItemIcon>
            <ListItemText primary={customLabel("menuItem.odps")} />
          </ListItem>
          <ListItem button component={Link} onClick={() => change("/smart_odp", "menuItem.odps")} to="/smart_odp" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/smart_odp") > -1 })}>
            <ListItemIcon><WorkIcon /></ListItemIcon>
            <ListItemText primary={customLabel("menuItem.smartOdp")} />
          </ListItem>
          <ListItem button component={Link} onClick={() => change("/measureTools", "menuItem.measureTools")} to="/measureTools" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/measureTools") > -1 })}>
            <ListItemIcon><SpeedIcon /></ListItemIcon>
            <ListItemText primary={customLabel("menuItem.measureTools")} />
          </ListItem>
          {configuration && configuration.enableMaintenance ? (
            <ListItem button component={Link} onClick={() => change("/maintenances", "menuItem.maintenances")} to="/maintenances" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/maintenances") > -1 && current.indexOf("/maintenancesTimeline") === -1 })}>
              <ListItemIcon><BuildIcon /></ListItemIcon>
              <ListItemText primary={customLabel("menuItem.maintenances")} />
            </ListItem>
          ) : null}
        </Collapse>
        <ListItem button onClick={() => goToOperatorView()}>
          <ListItemIcon><DirectionsWalkIcon /></ListItemIcon>
          <ListItemText primary={customLabel("menuItem.operatorView")} />
        </ListItem>
        {isAdmin ? (
          <div>
            <ListItem button onClick={() => handleClick("adminData")}>
              <ListItemIcon><LockIcon /></ListItemIcon>
              <ListItemText primary={customLabel("menuItem.admin")} />
              {adminOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={adminOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} onClick={() => change("/admin/boms", "menuItem.boms")} to="/admin/boms" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/admin/boms") > -1 })}>
                  <ListItemIcon><FormatListBulletedIcon /></ListItemIcon>
                  <ListItemText primary={customLabel("menuItem.boms")} />
                </ListItem>
                <ListItem button component={Link} onClick={() => change("/admin/odpStatuses", "menuItem.odpStatuses")} to="/admin/odpStatuses" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/admin/odpStatuses") > -1 })}>
                  <ListItemIcon><SlowMotionVideoIcon /></ListItemIcon>
                  <ListItemText primary={customLabel("menuItem.odpStatuses")} />
                </ListItem>
                <ListItem button component={Link} onClick={() => change("/admin/processTypes", "menuItem.processTypes")} to="/admin/processTypes" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/admin/processTypes") > -1, })}>
                  <ListItemIcon><LayersIcon /></ListItemIcon>
                  <ListItemText primary={customLabel("menuItem.processTypes")} />
                </ListItem>
                <ListItem button component={Link} onClick={() => change("/admin/processStatuses", "menuItem.processStatuses")} to="/admin/processStatuses" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/admin/processStatuses") > -1 })}>
                  <ListItemIcon><PlaylistPlayIcon /></ListItemIcon>
                  <ListItemText primary={customLabel("menuItem.processStatuses")} />
                </ListItem>
                {configuration && configuration.enableMaintenance ? (
                  <React.Fragment>
                    <ListItem button component={Link} onClick={() => change("/admin/maintenanceTypes")} to="/admin/maintenanceTypes" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/admin/maintenanceTypes") > -1, })}>
                      <ListItemIcon><BuildIcon /></ListItemIcon>
                      <ListItemText primary={customLabel("menuItem.maintenanceTypes")} />
                    </ListItem>
                    <ListItem button component={Link} onClick={() => change("/admin/maintenancePhases")} to="/admin/maintenancePhases" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/admin/maintenancePhases") > -1, })}>
                      <ListItemIcon><DvrIcon /></ListItemIcon>
                      <ListItemText primary={customLabel("menuItem.maintenancePhases")} />
                    </ListItem>
                  </React.Fragment>
                ) : null}
                <ListItem button component={Link} onClick={() => change("/admin/machineEvents", "menuItem.eventsMachine")} to="/admin/machineEvents" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/admin/machineEvents") > -1 })}>
                  <ListItemIcon><DateRangeIcon /></ListItemIcon>
                  <ListItemText primary={customLabel("menuItem.eventsMachine")} />
                </ListItem>
                {configuration && configuration.showUserEvents ? (
                  <ListItem button component={Link} onClick={() => change("/admin/userEvents", "menuItem.userEvents")} to="/admin/userEvents" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/admin/userEvents") > -1, })}>
                    <ListItemIcon><EventNoteIcon /></ListItemIcon>
                    <ListItemText primary={customLabel("menuItem.userEvents")} />
                  </ListItem>
                ) : null}
                {isSuperAdmin ? (
                  <React.Fragment>
                    <ListItem button component={Link} onClick={() => change("/admin/users", "menuItem.users")} to="/admin/users" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/admin/users") > -1 })}>
                      <ListItemIcon><PersonIcon /></ListItemIcon>
                      <ListItemText primary={customLabel("menuItem.users")} />
                    </ListItem>
                    <ListItem button component={Link} onClick={() => change("/admin/roles", "menuItem.roles")} to="/admin/roles" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/admin/roles") > -1 })}>
                      <ListItemIcon><HowToRegIcon /></ListItemIcon>
                      <ListItemText primary={customLabel("menuItem.roles")} />
                    </ListItem>
                  </React.Fragment>
                ) : null}
                <ListItem button component={Link} onClick={() => change("/admin/buttons", "menuItem.buttons")} to="/admin/buttons" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/admin/buttons") > -1 })}>
                  <ListItemIcon><RadioButtonCheckedIcon /></ListItemIcon>
                  <ListItemText primary={customLabel("menuItem.buttons")} />
                </ListItem>
                <ListItem button component={Link} onClick={() => change("/admin/configuration", "menuItem.configuration")} to="/admin/configuration" className={classNames(classes.nested, { [classes.selected]: current.indexOf("/admin/configuration") > -1 })}>
                  <ListItemIcon><SettingsIcon /></ListItemIcon>
                  <ListItemText primary={customLabel("menuItem.configuration")} />
                </ListItem>
              </List>
            </Collapse>
          </div>
        ) : null}
      </List>
      <Divider />
    </Drawer>
  );
}
export default MenuPage;