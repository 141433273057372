import React from 'react'
import { TableRow, TableCell } from "@material-ui/core";

/**
 * Restituisce una TableRow con una sola TableCell con key e a capo valore
 * @param {string} title 
 * @param {string} value 
 * @param {boolean} newLine value newLine
 * @param {boolean} boboldValueld value in grassetto
 * @returns TableRow
 */
const RowKeyValue = ({ title, value, newLine, boldValue }) => {
    return (
        <TableRow>
            <TableCell>
                {title}:{newLine ? <br /> : " "}{boldValue ? <b>{value}</b> : value}
            </TableCell>
        </TableRow>
    )
}

export default RowKeyValue
