import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { SERVER_URL } from "../../constants";
import { Document, Page, pdfjs } from 'react-pdf';
import { documentsSelector, resetCurrentDocument } from "./slice";
import { selector as dashbboardSelector } from "../dashboard/slice";
import classNames from "classnames";
import {
    IconButton, DialogContentText,
    Dialog, DialogActions, DialogTitle, Button, DialogContent
} from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { mesStyles } from '../utility/ultrafabStyles';

const DocumentDialog = ({ parentId, parentType }) => {
    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { download, currentDocument } = useSelector(documentsSelector);
    const { current } = useSelector(dashbboardSelector)
    const [numPages, setNumPages] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [zoom, setZoom] = useState(0.8);

    //per fix bug failed to load pdf !!!TENERE CONTROLLATO!!!
    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, []);
    //carica il numero di pagine del pdf selezionato
    const onDocumentLoadSuccess = (numPages) => {
        const pages = numPages._pdfInfo.numPages;
        setNumPages(pages);
    };
    //aumenta lo zoom per il visualizzatore PDF
    const zoomIn = () => {
        if (zoom <= 4.0) {
            setZoom(zoom + 1);
        }
    };
    //diminuisce lo zoom per il visualizzatore PDF
    const zoomOut = () => {
        if (zoom > 0.8) {
            setZoom(zoom - 1);
        }
    };
    //conferma per scaricare il documento
    const handleDownload = () => {
        window.open(SERVER_URL + "/document/" + currentDocument.id);
        dispatch(resetCurrentDocument());
    };
    //annullamento del download del documento
    const handleRejectDownload = () => {
        dispatch(resetCurrentDocument());
    };
    //chiude dialog mettendo a false current document e resettando gli state di pdf
    const closeDialog = () => {
        dispatch(resetCurrentDocument());
        setNumPages(false);
        setPageNumber(1);
        setZoom(0.8);
    };
    //restituisce nel dialog il documento in base al formato
    const renderDocument = () => {
        if (currentDocument && currentDocument.format === "image") {
            return (
                <div className={myClasses.docImageContainer}>
                    <img className={myClasses.docImageMedia}
                        src={SERVER_URL + "/document/" + currentDocument.id} alt={currentDocument.name}
                    />
                </div >
            );
        }
        else if (currentDocument && currentDocument.format === "pdf") {
            return (
                <div className={myClasses.docPdfContainer}>
                    <Document loading={customLabel("function.loadingPdf")} error={customLabel("function.failedPdf")} file={SERVER_URL + "/document/" + currentDocument.id}
                        onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} scale={zoom} />
                    </Document>
                </div>
            )
        }
        else {
            return null;
        };
    }

    return (
        <div>
            {/*dialog per mostrare il documento, se formato accettato */}
            <Dialog fullWidth maxWidth="xl" open={currentDocument && currentDocument.parentId === parentId && currentDocument.parentType === parentType && !download}>
                <DialogTitle style={{ padding: "2px 24px" }}>
                    {currentDocument.name}
                </DialogTitle>
                <DialogContent>
                    {renderDocument()}
                </DialogContent>
                <DialogActions style={{ padding: "16px 24px" }}>
                    {currentDocument && currentDocument.format === "pdf" ? (
                        <div>
                            <IconButton disabled={numPages === 1 || !numPages || (numPages > 1 && pageNumber === 1)} onClick={() => setPageNumber(pageNumber - 1)}>
                                <ArrowLeftIcon />
                            </IconButton>
                            <IconButton disabled={numPages === 1 || !numPages || (numPages > 1 && pageNumber === numPages)} onClick={() => setPageNumber(pageNumber + 1)}>
                                <ArrowRightIcon />
                            </IconButton>
                            <p style={{ textAlign: "center" }}>{customLabel("function.page")} {pageNumber} {customLabel("function.of")} {numPages}</p>
                        </div>)
                        : null}
                    <div style={{ flex: "1 0 0" }} />
                    {currentDocument && currentDocument.format === "pdf" ? (
                        <div>
                            <IconButton onClick={() => zoomOut()} disabled={zoom <= 0.8 || !numPages}>
                                <RemoveIcon />
                            </IconButton>
                            <IconButton onClick={() => zoomIn()} disabled={zoom >= 4.0 || !numPages}>
                                <AddIcon />
                            </IconButton>
                            <p style={{ textAlign: "center" }}>{customLabel("function.zoom")}</p>
                        </div>)
                        : null}
                    <div style={{ flex: "1 0 0" }} />
                    <Button variant={current && current.indexOf("operatorView") === -1 ? "text" : "contained"} onClick={() => closeDialog()} color="secondary"
                        className={classNames({ [myClasses.operatorButton]: current.indexOf("/operatorView") > -1 })}>
                        {customLabel("button.cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*dialog per chiedere conferma download documento*/}
            <Dialog open={download && currentDocument && currentDocument.parentId === parentId && currentDocument.parentType === parentType} aria-describedby="alert-dialog-slide-description">
                <DialogTitle id="alert-dialog-slide-title">
                    {customLabel("function.confirm").toUpperCase()}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {customLabel("function.confirmDownload")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={current && current.indexOf("operatorView") === -1 ? "text" : "contained"} onClick={handleDownload} color="primary"
                        className={classNames({ [myClasses.operatorButton]: current.indexOf("/operatorView") > -1 })}>
                        {customLabel("button.confirm")}
                    </Button>
                    <Button variant={current && current.indexOf("operatorView") === -1 ? "text" : "contained"} onClick={handleRejectDownload} color="secondary"
                        className={classNames({ [myClasses.operatorButton]: current.indexOf("/operatorView") > -1 })}>
                        {customLabel("button.cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DocumentDialog;
