import React from "react";
import { customLabel } from "../utility/customLabel";
import { Paper, Table, TableRow, TableCell, AppBar, Tabs, Tab } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import { SERVER_URL } from "../../constants";

const useStyles = makeStyles((theme) => ({
    left: {
        margin: "5px",
        minHeight: "710px",
        maxHeight: "710px",
        overflow: "auto",
    },
    actionButton: {
        margin: "5px",
        width: '100%',
        padding: "15px",
    },
    alarm: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold'
    }
}));

const CurrentProcess = ({ machine, configuration }) => {
    const classes = useStyles();
    //const cnc = process.env.PUBLIC_URL + "/cnc.jpg";
    const [tabIndex, setTabIndex] = React.useState(0);
    //impostazione postazione salvate nel localStorage 

    //tab del menu in alto
    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <React.Fragment>
            <Paper className={classes.left}>
                <AppBar position="static">
                    <Tabs value={tabIndex} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                        <Tab label={machine ? machine.code : ''} key={"summary"} value={0} />
                    </Tabs>
                </AppBar>
                <div style={{ maxHeight: "680px", overflow: "auto" }}>
                    {tabIndex === 0 ? (
                        <Table size={"small"}>
                            <tbody>
                                <TableRow>
                                    <TableCell colSpan={2} align="center" height={222}>
                                        {machine && machine.image !== null ? (
                                            <img src={SERVER_URL + "/machine/" + machine.id + "/image?"} height={185} alt="immagine-macchina" />
                                        ) : (
                                            // <img src={cnc} alt="immagine_default_macchina" height={185} />
                                            <b>{customLabel("function.notAvailable").toUpperCase()}</b>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{customLabel("machine.machine")}:</TableCell>
                                    <TableCell><b>{machine && machine.code ? machine.code : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{customLabel("workcentre.workcentre")}:</TableCell>
                                    <TableCell><b>{machine && machine.workcentre ? machine.workcentre.code : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{customLabel("process.feasibleProcesses")}:</TableCell>
                                    <TableCell><b>{machine && machine.processTypes.length > 0 ? machine.processTypes.map((pt, i) => `${pt.name}${machine.processTypes[i + 1] ? ', ' : ''}`) : <b>{customLabel("function.notAvailable").toUpperCase()}</b>}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}><b>{customLabel("machine.machineVars")}:</b></TableCell>
                                </TableRow>
                                {machine && machine.vars.length > 0 ? machine.vars.map((mv) =>
                                    <TableRow key={mv.id}>
                                        <TableCell>{mv.name}:</TableCell>
                                        <TableCell>
                                            {mv.type === "boolean" && mv.value === "false" ? <ClearIcon /> : mv.type === "boolean" && mv.value === "true" ? <CheckIcon /> :
                                                mv.value !== null && mv.type !== "number" ? <b>{mv.value}</b> : mv.value !== null && mv.type === "number" ? <b>{Number(mv.value).toFixed(configuration.decimals)}</b> :
                                                    "-"}
                                        </TableCell>
                                    </TableRow>
                                ) : <TableRow><TableCell style={{ borderBottom: "none" }}><b>{customLabel("function.notAvailable").toUpperCase()}</b></TableCell></TableRow>}
                            </tbody>
                        </Table>
                    ) : null}
                </div>
            </Paper>
        </React.Fragment >
    );
}

export default CurrentProcess;