import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { StepLabel, Step, Stepper, Button, Typography } from '@material-ui/core';
import { customLabel } from "../utility/customLabel";
import { odpSmartSelector, preAddProcesses, createAPI, setOdp, cancelIsCreated } from "./slice";
import { bomsSelector, getAPI as getBomAPI } from "../boms/slice";
import Form from './Form'
import Processes from './Processes'
import Vars from './Vars'
import Recap from './Recap'
import MeasureTools from './MeasureTools';
import { Redirect } from "react-router-dom";
import { reset } from "../odps/slice";
import { mesStyles, PaperForm } from "../utility/ultrafabStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  resetButton: {
    textAlign: "center",
    margin: theme.spacing(2)
  },
  instructions: {
    textAlign: "center",
    margin: theme.spacing(3)
  }
}));

function getSteps() {
  return (
    [customLabel("smartOdp.intro"), customLabel("smartOdp.createOdp"), customLabel("smartOdp.processes"), customLabel("smartOdp.vars"), customLabel("smartOdp.measureTools"),
    customLabel("function.confirm")]
  )
}

const SmartOdpPage = ({ match }) => {
  const classes = useStyles();
  const myClasses = mesStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const { odpedit, customerOrderedit, odp, isCreated, hasErrors, loading, processes } = useSelector(odpSmartSelector)
  const { bom } = useSelector(bomsSelector)

  useEffect(() => {
    if (isCreated) {
      dispatch(cancelIsCreated())
    }
  }, [dispatch, isCreated]);

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    if (bom && odpedit.bom.id === bom.id) {
      dispatch(preAddProcesses(bom))
    }
  }, [dispatch, odpedit, bom]);


  const handleNext = () => {
    window.scrollTo(0, 0)
    if (activeStep === 1) {
      dispatch(getBomAPI(odpedit.bom.id))
    }
    if (activeStep === 4) {
      dispatch(setOdp())
    }
    if (activeStep === 5) {
      dispatch(createAPI(odp))
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    window.scrollTo(0, 0)
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  if (isCreated) {
    return <Redirect to={"/odps/" + isCreated.id} />;
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            {!loading ? (
              <React.Fragment>
                <Typography variant="h6" className={classes.instructions}>{!hasErrors ? customLabel("smartOdp.allStepCompleted") : customLabel("smartOdp.errorOnOdpCreation")}</Typography>
                <div className={classes.resetButton}>
                  <Button variant="contained" onClick={handleReset}>{customLabel("button.reset")}</Button>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        ) : (
          <PaperForm>
            {activeStep === 0 ?
              <div className={myClasses.divSmartCreation}>{customLabel("smartOdp.introText")}</div> :
              activeStep === 1 ? <Form />
                : activeStep === 2 ? <Processes />
                  : activeStep === 3 ? <Vars />
                    : activeStep === 4 ? <MeasureTools />
                      : activeStep === 5 ? <Recap />
                        : null}
            <div className={myClasses.buttonsSmartProgress}>
              <Button disabled={activeStep === 0} onClick={handleBack}>{customLabel("button.back")}</Button>
              <Button variant="contained" color="primary" onClick={handleNext}
                disabled={activeStep !== 0 && (odpedit.quantity === '' || (odpedit.customerOrder.id === "" && customerOrderedit.customer.id === '') || odpedit.code === "" || odpedit.bom.id === ""
                  || (odpedit.article.id === "" && odpedit.article.id === "") || (activeStep === 2 && !processes) || (activeStep === 2 && processes.length === 0))
                } >
                {activeStep === steps.length - 1 ? customLabel("button.save") : activeStep === 0 ? customLabel("button.begin") : customLabel("button.next")}
              </Button>
            </div>
          </PaperForm>
        )}
      </div>
    </div >
  );
}

export default SmartOdpPage;