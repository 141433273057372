export const customFilter = {
    mesName: "roles",
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "authority",
    showFilter: false,
    authority: false
}

export const headers = {
    authority: { filter: true, type: "string" },
};

