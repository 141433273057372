import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Table,
    TableBody,
    TableCell, TableRow,
    Typography
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useInterval from 'react-useinterval';
import { bishopFormats } from '../../constants';
import { useAnchor } from "../customHooks/useAnchor";
import { doChange } from "../dashboard/slice";
import { getListAPI as getOdpStatusesAPI, odpStatusesSelector } from "../odpStatuses/slice";
import LegendPreview from "../utility/LegendPreview";
import PopoverLegend from "../utility/PopoverLegend";
import { customLabel } from "../utility/customLabel";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import { setColor } from "../utility/vari";
import { selector, updateInterfaceAPI } from "./slice";
import { configurationSelector } from "../configuration/slice";

const CustomerOrderDetails = () => {
    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { customerOrder } = useSelector(selector);
    const { odpStatuses } = useSelector(odpStatusesSelector);
    const [anchorPopover, { handleOpen: handleOpenPopover, handleClose: handleClosePopover }, openPopover] = useAnchor();
    const { configuration } = useSelector(configurationSelector)

    useInterval(() => dispatch(updateInterfaceAPI(customerOrder)), 10000);

    useEffect(() => {
        dispatch(getOdpStatusesAPI(0, 10000, "name", "asc", false));
    }, [dispatch]);

    const changeUrl = () => {
        dispatch(doChange("/odps"));
    };

    const renderOdps = () => {
        if (customerOrder && customerOrder.odps && customerOrder.odps.length === 0) {
            return (
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                {customLabel("customerOrder.noOdpsRelated")}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )
        }
        else if (customerOrder && customerOrder.odps && customerOrder.odps.length > 0) return (
            customerOrder.odps.map((o) => {
                return (
                    <Accordion key={o.id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                            className={myClasses.accordionSummary} style={{ backgroundColor: o.backgroundColor, color: setColor(o.backgroundColor) }}>
                            <Typography>
                                {o.extCode && o.extCode !== "" && o.extCode !== null ? o.code + " (" + o.extCode + ")" : o.code}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={myClasses.accordionDetails}>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{customLabel("customerOrder.startDate")}</TableCell>
                                        <TableCell>{o.startDate !== null ? moment(o.startDate).format(bishopFormats.LTS) : null}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("customerOrder.endDate")}</TableCell>
                                        <TableCell>{o.endDate !== null ? moment(o.endDate).format(bishopFormats.LTS) : null}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("article.article")}</TableCell>
                                        <TableCell>{o.article.code}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("odp.quantity")}</TableCell>
                                        <TableCell>{o.quantity.toFixed(configuration.decimals)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{customLabel("odp.status")}</TableCell>
                                        <TableCell>{o.status !== null ? o.status : customLabel("processStatus.odpToStart")}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} align="center">
                                            <Button component={Link} to={`/odps/` + o.id} color="primary" onClick={changeUrl}>
                                                {customLabel("button.details")}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                )
            })
        )
        else return null
    }


    return (
        <React.Fragment>
            <PaperForm>
                <div className={myClasses.titleFormSpaceBetween}>
                    <Typography component="h1" variant="h6" color="inherit">
                        {customLabel("customerOrder.progressOrder")}
                    </Typography>
                </div>
                <div className={myClasses.tableContainer}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>{customLabel("customerOrder.quantity")}</TableCell>
                                <TableCell>{customerOrder.totalQuantity}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{customLabel("customerOrder.doingQuantity")}</TableCell>
                                <TableCell>{customerOrder.doingQuantity}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{customLabel("customerOrder.doneQuantity")}</TableCell>
                                <TableCell>{customerOrder.doneQuantity}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{customLabel("customerOrder.endDate")}</TableCell>
                                <TableCell>{customerOrder.endDate !== null ? moment(customerOrder.endDate).format(bishopFormats.LTS) : null}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </PaperForm>
            <PaperForm>
                <div className={myClasses.titleFormSpaceBetween}>
                    <Typography component="h1" variant="h6" color="inherit">
                        {customLabel("customerOrder.odpsRelated")}
                    </Typography>
                    <div className={myClasses.dashedUnderline} onMouseEnter={(e) => handleOpenPopover(e.currentTarget, false)} onMouseLeave={handleClosePopover}>
                        <LegendPreview name={customLabel("legend.legend")} />
                    </div>
                </div>
                <div className={myClasses.paperContainer}>
                    {renderOdps()}
                </div>
            </PaperForm>
            {anchorPopover ? (
                <PopoverLegend name={customLabel("legend.legend").toUpperCase()} list={odpStatuses} open={openPopover} anchorEl={anchorPopover}
                    openPopover={handleOpenPopover} closePopover={handleClosePopover}
                    anchorVertical={"top"} anchorHorizontal={"center"} transformVertical={"bottom"} transormHorizontal={"center"} />
            ) : null}
        </React.Fragment >
    )
}

export default CustomerOrderDetails
