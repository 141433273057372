import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";

/**
 * 
 * @param {boolean} flag per apertura dialog, può essere anche un object
 * @param {string} title titolo dialog
 * @param {function} form funzione che restituisce il dialog content
 * @param {function} actions funzione che restituisce dialog actions
 * @returns 
 */
const DialogSplit = ({ flag, title, form, actions }) => {
    return (
        <Dialog open={flag} keepMounted maxWidth={"md"} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {form()}
            </DialogContent>
            <DialogActions style={{ padding: "16px 24px" }}>
                {actions()}
            </DialogActions>
        </Dialog>
    )
}
export default DialogSplit;
