import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { operatorViewSelector, getDocumentsAPI, getStaplingDocumentsAPI } from "../slice";
import { customLabel } from '../../utility/customLabel';
import { Box } from "@material-ui/core";
import { MACHINES } from "../utility/constants";
import DocumentsTable from "../docs/DocumentsTable";

const OperatorDocStapling = () => {
  const dispatch = useDispatch();
  const { documentsMachine, documentsErrors, errors, loadingDocs, modeStapling, documentsStapling,
    machine, index, odp, staplingObjects } = useSelector(operatorViewSelector);

  useEffect(() => {
    //documenti per pinzatura
    if (modeStapling && odp) {
      //API per caricare i documenti di macchina una volta creata la pinzatura
      if (!documentsMachine && !loadingDocs && !errors && !documentsErrors) {
        dispatch(getDocumentsAPI(machine.id, MACHINES, false, false))
      };
    };
    //API per caricare tutti i documenti della pinzatura dopo aver caricato quelli della macchina
    if (staplingObjects && staplingObjects.length > 0 && documentsMachine) {
      staplingObjects.forEach((p, ind) => {
        if (p.docs === false && (ind === 0 || (ind > 0 && staplingObjects[ind - 1]["docs"] !== false)) && !loadingDocs) {
          dispatch(getStaplingDocumentsAPI(p.id, p.type, false, true));
        }
      })
    }
  }, [dispatch, machine, modeStapling, odp, documentsMachine, index, loadingDocs, errors, documentsErrors, staplingObjects, documentsStapling])

  const renderDocuments = () => {
    return (
      <React.Fragment>
        {machine ? (
          <DocumentsTable title={customLabel("machine.machine")} documents={documentsMachine} />
        ) : null}
        {documentsStapling ? (
          <DocumentsTable title={customLabel("stapling.stapling")} documents={documentsStapling} />
        ) : null}
      </React.Fragment>
    )
  }
  return (
    <Box style={{ maxHeight: "642px", overflow: "auto" }}>
      {renderDocuments()}
    </Box>
  )
}
export default OperatorDocStapling;
