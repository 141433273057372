import React from 'react';
import { useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { Table, TableHead, TableRow, TableBody, TableCell, Divider, Typography } from '@material-ui/core';
import { odpSmartSelector } from "./slice";
import moment from "moment";
import { bishopFormats } from '../../constants';
import { mesStyles } from "../utility/ultrafabStyles";

const Recap = () => {
  const myClasses = mesStyles();
  const { odp } = useSelector(odpSmartSelector)

  const renderVars = (p) => {
    if (p && p.vars && p.vars.length > 0) {
      return p.vars.map((v) => {
        return (
          <span key={p.sortOrder + "_" + v.name + "_" + v.type} className={myClasses.listItemProcessSquare}>{v.name}</span>
        )
      })
    } else {
      return (
        <span className={myClasses.alarmFont}>{customLabel("function.notAvailable").toUpperCase()}</span>
      )
    }
  };

  const renderMeasureTools = (p) => {
    if (p && p.measureTools && p.measureTools.length > 0) {
      return p.measureTools.map((m) => {
        return (
          <span key={p.sortOrder + "_" + m.measureTool_id} className={myClasses.listItemProcessSquare}>{m.name}</span>
        )
      })
    } else {
      return (
        <span className={myClasses.alarmFont}>{customLabel("function.notAvailable").toUpperCase()}</span>
      )
    }
  }

  return (
    <div>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography variant="h5">{customLabel("smartOdp.confirm")}</Typography><br />
      </div>
      <div className={myClasses.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{customLabel("odp.code")}</TableCell>
              <TableCell>{customLabel("odp.quantity")}</TableCell>
              <TableCell>{customLabel("odp.customer.customer")}</TableCell>
              <TableCell>{customLabel("article.article")}</TableCell>
              <TableCell>{customLabel("odp.deliveryDate")}</TableCell>
              <TableCell>{customLabel("customerOrder.customerOrder")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{odp.code}</TableCell>
              <TableCell>{odp.quantity}</TableCell>
              <TableCell>{odp.customer.code}</TableCell>
              <TableCell>{odp.article.code}</TableCell>
              <TableCell>{moment(odp.deliveryDate).format(bishopFormats.L)}</TableCell>
              <TableCell>{odp.customerOrder.id !== '' ? odp.customerOrder.code : customLabel("function.new")}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <Divider className={myClasses.dividerMarginVert2} />
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography variant="h6">{customLabel("process.processes")}</Typography>
      </div>
      <div className={myClasses.listProcessContainer}>
        {odp.processes.map(p => {
          return (
            <div key={p.sortOrder}>
              <div><b>{p.name}</b></div>
              <br />
              <i>{customLabel("processType.var.vars")}</i>
              <br /><br />
              {renderVars(p)}
              <br /><br />
              <i>{customLabel("measureTool.measureTools")}</i>
              <br /><br />
              {renderMeasureTools(p)}
              <Divider className={myClasses.dividerMarginVert2} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Recap;