import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../constants";
import { showError, showSuccess } from "../notifications/slice";
import { logout, refreshAPI } from "../main/slice";
import { customLabel } from "../utility/customLabel";
import { customFilter } from "./utility";

export const initialState = {
    loading: false,
    hasErrors: false,
    buttons: false,
    reload: false,
    results: 0,
    button: false,
    buttonedit: false,
    editmode: false,
    deleting: false,
    isCreated: false,
    isDeleted: false,
    filter: false
};

const buttonsSlice = createSlice({
    name: "buttons",
    initialState,
    reducers: {
        resetState: (state) => {
            if (!state.filter) {
                Object.assign(state, initialState);
            } else {
                let tempState = {};
                let currentFilter = { ...state.filter }
                Object.assign(tempState, initialState);
                tempState.filter = { ...currentFilter }
                Object.assign(state, tempState);
            }
        },
        gettingList: (state) => {
            state.loading = true;
            state.reload = false;
            state.hasErrors = false;
        },
        getListSuccess: (state, { payload }) => {
            state.buttons = payload[1];
            state.results = payload[0].total_entries;
            state.loading = false;
        },
        getListSuccessNoApi: (state, { payload }) => {
            state.loading = false;
            state.buttons = payload;
        },
        setReload: (state) => {
            state.reload = true;
        },
        reset: (state) => {
            state.button = false;
            state.buttonedit = false;
            state.editmode = false;
            state.isDeleted = false;
            state.isCreated = false;
        },
        getting: (state) => {
            state.button = false;
            state.isCreated = false;
            state.isDeleted = false;
            state.loading = true;
            state.editmode = false;
            state.hasErrors = false;
            state.reload = false
        },
        getSuccess: (state, { payload }) => {
            state.button = payload;
            state.loading = false;
        },
        creating: (state) => {
            state.loading = true;
            state.hasErrors = false;
        },
        create: (state) => {
            let newbutton = {
                id: "new", name: "", api: "", type: "", note: "", sendProcess: false, sendUser: false, sendOdp: false, sendCustomerOrder: false, sendMachineEvent: false,
                sendUserEvent: false, askValue: false, nameValue: "", sendMachine: false, showInOdp: false, showInStapling: false, showInMaintenance: false, askConfirmation: false
            };
            state.button = newbutton;
            state.buttonedit = newbutton;
            state.editmode = true;
        },
        createSuccess: (state, { payload }) => {
            state.button = payload;
            state.buttonedit = false;
            state.loading = false;
            state.isCreated = true;
        },
        resetEdit: (state) => {
            state.editmode = true;
            state.buttonedit = state.button;
            state.loading = false;
            state.hasErrors = true;
        },
        edit: (state) => {
            state.editmode = true;
            state.buttonedit = state.button;
        },
        cancelEdit: (state) => {
            state.editmode = false;
            state.buttonedit = false;
        },
        change: (state, { payload }) => {
            let u = state.buttonedit;
            u[payload.name] = payload.value;
            state.buttonedit = u;
        },
        updating: (state) => {
            state.loading = true;
            state.hasErrors = false;
        },
        updateSuccess: (state, { payload }) => {
            state.button = payload;
            state.loading = false;
            state.editmode = false;
        },
        confirmDelete: (state) => {
            state.deleting = !state.deleting;
        },
        deleting: (state) => {
            state.deleting = false;
            state.loading = true;
            state.hasErrors = false;
        },
        deleteSuccess: (state) => {
            state.loading = false;
            state.isDeleted = true;
        },
        enableErrors: (state) => {
            state.loading = false;
            state.hasErrors = true;
        },
        linkingObject: state => {
            state.loading = true
            state.hasErrors = false
        },
        linkingObjectSuccess: state => {
            state.loading = false
            state.reload = true
        },
        changeFilter: (state, { payload }) => {
            state.filter[payload.name] = payload.value;
        },
        /**
         * 
         * @param {*} state 
         * @param {boolean} payload showFilter 
         */
        initFilter: (state, { payload }) => {
            let tempFilter = { ...customFilter };
            tempFilter["showFilter"] = payload;
            state.filter = { ...tempFilter };
        }
    },
});

export const { gettingList, getListSuccess, setReload, reset, getting, getSuccess, creating, create, createSuccess,
    resetButton, change, edit, cancelEdit, updating, updateSuccess, linkingObject, linkingObjectSuccess, confirmDelete, deleting, deleteSuccess, enableErrors,
    resetState, changeFilter, initFilter } = buttonsSlice.actions;
export const buttonsSelector = (state) => state.buttons;
export default buttonsSlice.reducer;

export function getListAPI(page, rowsPerPage, orderBy, order, name, type, api) {

    return async (dispatch) => {
        dispatch(gettingList());
        dispatch(reset());
        let url = SERVER_URL + `/button?page=${page}&per_page=${rowsPerPage}&orderBy=${orderBy}&order=${order}`
        if (name !== false) {
            url = url + `&name=${name}`
        }
        if (type !== false) {
            url = url + `&type=${type}`
        }
        if (api !== false) {
            url = url + `&api=${api}`
        }
        const response = await fetch(url, {
            mode: "cors",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        try {
            const data = await response.json();
            if (response.status === 200) {
                dispatch(getListSuccess(data));
            } else if (response.status === 403) {
                dispatch(logout());
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors());
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    };
}

export function updateAPI(button) {
    let access_token = "";
    if (localStorage.getItem("bishop_current_user") != null) {
        access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
            .access_token;
    }
    let params = { button: button };
    return async (dispatch) => {
        dispatch(updating());
        const response = await fetch(SERVER_URL + "/api/button/" + button.id, {
            mode: "cors",
            method: "PUT",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + access_token,
            },
            body: JSON.stringify(params),
        });
        try {
            const data = await response.json();
            if (response.status === 200) {
                dispatch(updateSuccess(data));
                dispatch(showSuccess("function.operationSuccess"));
            } else if (response.status === 403) {
                dispatch(logout());
            } else {
                dispatch(enableErrors());
                dispatch(resetButton());
                dispatch(showError(data.message));
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    };
}
export function createAPI(button) {
    let access_token = "";
    if (localStorage.getItem("bishop_current_user") != null) {
        access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
            .access_token;
    }
    return async (dispatch) => {
        dispatch(creating());
        let params = { button: button };
        const response = await fetch(SERVER_URL + "/api/button", {
            mode: "cors",
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + access_token,
            },
            body: JSON.stringify(params),
        });
        try {
            const data = await response.json();
            if (response.status === 200) {
                dispatch(createSuccess(data));
                dispatch(showSuccess("function.operationSuccess"));
            } else if (response.status === 403) {
                dispatch(logout());
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors());
                dispatch(resetButton());
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    };
}

export function getAPI(id) {
    let access_token = "";
    if (localStorage.getItem("bishop_current_user") != null) {
        access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
            .access_token;
    }
    return async (dispatch) => {
        dispatch(getting());
        const response = await fetch(SERVER_URL + "/api/button/" + id, {
            mode: "cors",
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + access_token,
            },
        });
        try {
            const data = await response.json();
            if (response.status === 200) {
                dispatch(getSuccess(data));
            } else if (response.status === 403) {
                dispatch(logout());
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors());
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    };
}
export function deleteAPI(button) {
    let access_token = "";
    if (localStorage.getItem("bishop_current_user") != null) {
        access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
            .access_token;
    }
    return async (dispatch) => {
        dispatch(deleting());
        const response = await fetch(SERVER_URL + "/api/button/" + button.id, {
            mode: "cors",
            method: "DELETE",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + access_token,
            },
        });
        try {
            const data = await response.json();
            if (response.status === 200) {
                dispatch(deleteSuccess(data));
                dispatch(showSuccess("function.operationSuccess"));
            } else if (response.status === 403) {
                dispatch(logout());
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors());
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    };
}

export function addRolesAPI(button, roles) {
    let access_token = ""
    if (localStorage.getItem('bishop_current_user') != null) {
        access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
    }
    return async dispatch => {
        dispatch(linkingObject())
        let params = { roles: roles }
        const response = await fetch(SERVER_URL + '/api/button/' + button.id + '/role', {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + access_token
            },
            body: JSON.stringify(params)
        });

        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(linkingObjectSuccess())
                dispatch(showSuccess("function.operationSuccess"))
            } else if (response.status === 403) {
                dispatch(logout())
            } else {
                dispatch(showError(data.message))
                dispatch(enableErrors())
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    }
}

export function removeRoleAPI(button, role) {
    let access_token = ""
    if (localStorage.getItem('bishop_current_user') != null) {
        access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
    }
    return async dispatch => {
        dispatch(linkingObject())
        const response = await fetch(SERVER_URL + '/api/button/' + button.id + '/role/' + role.id, {
            mode: 'cors',
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + access_token
            }
        });

        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(linkingObjectSuccess())
                dispatch(showSuccess("function.operationSuccess"))
            } else if (response.status === 403) {
                dispatch(logout())
            } else {
                dispatch(showError(data.message))
                dispatch(enableErrors());
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    }
}

export function addProcessTypesAPI(button, processTypes) {
    let access_token = ""
    if (localStorage.getItem('bishop_current_user') != null) {
        access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
    }
    return async dispatch => {
        dispatch(linkingObject())
        let params = { processTypes: processTypes }
        const response = await fetch(SERVER_URL + '/api/button/' + button.id + '/processType', {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + access_token
            },
            body: JSON.stringify(params)
        });

        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(linkingObjectSuccess())
                dispatch(showSuccess("function.operationSuccess"))
            } else if (response.status === 403) {
                dispatch(logout())
            } else {
                dispatch(showError(data.message))
                dispatch(enableErrors())
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    }
}

export function removeProcessTypeAPI(button, processType) {
    let access_token = ""
    if (localStorage.getItem('bishop_current_user') != null) {
        access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
    }
    return async dispatch => {
        dispatch(linkingObject())
        const response = await fetch(SERVER_URL + '/api/button/' + button.id + '/processType/' + processType.id, {
            mode: 'cors',
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + access_token
            }
        });

        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(linkingObjectSuccess())
                dispatch(showSuccess("function.operationSuccess"))
            } else if (response.status === 403) {
                dispatch(logout())
            } else {
                dispatch(showError(data.message))
                dispatch(enableErrors());
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    }
}