import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import DragDrop from '../configuration/DragDrop'
import { customLabel } from "../utility/customLabel";

const TableListConfig = ({ open, close, headers, item, mainObject }) => {
    const [selectedItems, setSelectedItems] = useState()
    let savedItems = JSON.parse(localStorage.getItem(item))

    let keys = Object.keys(headers)
    let result = Object.keys(headers)
    for (let k in keys) {
        if (headers[keys[k]].properties) {
            let p = headers[keys[k]].properties
            result.splice(result.indexOf(keys[k]), 1)
            for (let x in p) {
                result.push(keys[k] + "." + p[x])
            }
        }
    }
    let labelsList = result.map(e => ({ id: e, translatedLabel: mainObject + e }))

    const onChange = (data) => {
        setSelectedItems(data.value)
    }

    const save = () => {
        let temp = selectedItems.split(",")
        localStorage.setItem(item, JSON.stringify(temp))
        close()
    }

    return (
        <Dialog open={open} keepMounted fullWidth>
            <DialogTitle>
                {customLabel("odp.customize")}
            </DialogTitle>
            <DialogContent>
                <DragDrop labelsSaved={savedItems ? savedItems.join(",") : ""} labelsList={labelsList} name={item} change={onChange} />
            </DialogContent>
            <DialogActions>
                <Button onClick={save} color="primary">
                    {customLabel("button.confirm")}
                </Button>
                <Button onClick={close} color="default">
                    {customLabel("button.close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TableListConfig