import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";

/**
 * Componente dialog con un testo, un tasto conferma e uno annulla
 * @param {boolean} flag per apertura dialog, può essere anche un object
 * @param {string} text testo all'interno di dialog content
 * @param {function} confirm funzione chiamata sul tasto conferma
 * @param {function} cancel funzione chiamata sul tasto cancella
 * @param {boolean} disabled valore che quando true mette disabled i due tasti del dialog
 * @returns componente
 */
const DialogConfirmAction = ({ text, flag, confirm, cancel, disabled }) => {
    return (
        <Dialog open={flag ? true : false} keepMounted maxWidth={"sm"} fullWidth>
            <DialogTitle>{customLabel("function.confirm").toUpperCase()}</DialogTitle>
            <DialogContent>
                {text}
            </DialogContent>
            <DialogActions>
                <Button onClick={confirm} color="primary" disabled={disabled}>
                    {customLabel("button.confirm")}
                </Button>
                <Button onClick={cancel} color="default" disabled={disabled}>
                    {customLabel("button.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogConfirmAction;
