import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Clock from 'react-live-clock';
import {
    CssBaseline, Grid, Button, TableBody, Dialog, DialogActions, DialogContent, DialogTitle,
    AppBar, Toolbar, IconButton, Typography, Paper, Table, TableCell, TableRow, FormControlLabel, Checkbox,
    ListItem, ListItemText, List, InputLabel, Input, FormControl, TableHead
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { customLabel } from "../utility/customLabel";
import T from "i18n-react"
import NotificationsPage from "../notifications/NotificationsPage";
import { workcentresSelector, getListAPI as getWorkcentresAPI } from "../workcentres/slice";
import { rolesSelector, getListWithoutAPI as getRolesAPI } from "../roles/slice";
import { configurationSelector, getAPI as getConfigurationAPI } from "../configuration/slice";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { toggleFullScreen } from "../utility/vari";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        paddingRight: 24,
    },
    appBar: {
        backgroundColor: "#d01a28",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
        textDecoration: "none",
        fontWeight: "normal",
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        padding: theme.spacing(1),
    },
    paper: {
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
    selected: {
        backgroundColor: "#0000001a",
    },
    operator: {
        dispay: "flex",
        alignItems: "center"
    },
    fullIcon: {
        marginLeft: theme.spacing(10)
    },
    form: {
        marginBottom: "20px",
        width: "calc(50%)",
    },
    updateButton: {
        marginBottom: "20px",
        margintTop: "10px",
        marginLeft: "10px",
        width: "50%"
    },
    goButton: {
        marginBottom: "20px",
        marginTop: "20px",
        marginLeft: "10px",
        width: "50%"
    },
    formCheck: {
        marginBottom: "20px",
        width: "100%"
    },
    formDiv: {
        padding: theme.spacing(0, 2)
    },
    warning: {
        color: theme.palette.secondary.light,
        fontWeight: "bold"
    },
    tableCell1Button: {
        width: "120px"
    }
}));
const SettingsPage = () => {
    const logobishop = process.env.PUBLIC_URL + "/logobishop.png";
    const classes = useStyles();
    const dispatch = useDispatch();
    const { workcentres } = useSelector(workcentresSelector);
    const { roles } = useSelector(rolesSelector);
    const { configuration, loading: loadingConf, hasErrors: errorsConf } = useSelector(configurationSelector);
    const [machineSaved, setMachineSaved] = React.useState([]);
    const [workcentreSaved, setWorkcentreSaved] = React.useState([]);
    const [roleSaved, setRoleSaved] = React.useState([]);
    const [changeWorkcentre, setChangeWorkcentre] = React.useState(false);
    const [workstation, setWorkstation] = React.useState("");
    const [machineDialog, setMachineDialog] = React.useState(false);
    const [roleDialog, setRoleDialog] = React.useState(false);

    if (configuration === false && !loadingConf && !errorsConf) {
        dispatch(getConfigurationAPI());
    }
    if (configuration && configuration.label !== null) {
        T.setTexts(JSON.parse(configuration.label), {
            notFound: (key) => `${key} <- missing!`,
        });
    }

    useEffect(() => {
        //crea campi localStorage se non ci sono
        if (localStorage.getItem("machines") === null) {
            localStorage.setItem("machines", "[]")
        };
        if (localStorage.getItem("workcentres") === null) {
            localStorage.setItem("workcentres", "[]")
        };
        if (localStorage.getItem("roles") === null) {
            localStorage.setItem("roles", "[]")
        };
        if (localStorage.getItem("workstation") === null) {
            localStorage.setItem("workstation", "")
        };
        if (localStorage.getItem("changeWorkcentre") === null) {
            localStorage.setItem("changeWorkcentre", "false")
        };
        dispatch(getWorkcentresAPI(0, 10000, "code", "asc", false, false));
        dispatch(getRolesAPI(0, 10000, "authority", "asc", false));
        dispatch(getConfigurationAPI());

        //salvo nello useState roles, workcentres e machines attualmente nel local storage
        setMachineSaved(JSON.parse(localStorage.getItem("machines")));
        setWorkcentreSaved(JSON.parse(localStorage.getItem("workcentres")));
        setRoleSaved(JSON.parse(localStorage.getItem("roles")));
        if (localStorage.getItem("changeWorkcentre") === "true") {
            setChangeWorkcentre(true)
        } else {
            setChangeWorkcentre(false)
        }
        setWorkstation(localStorage.getItem("workstation"));
    }, [dispatch]);

    const addMachine = (m) => {
        let existMachine = false;
        let existWorkcentre = false;
        let currentMachines = machineSaved;
        let workcentreIndex = false;
        if (currentMachines.length > 0) {
            for (let i = 0; i < currentMachines.length; i++) {
                if (currentMachines[i].id.toString() === m.id.toString()) {
                    existMachine = true;
                }
            }
        }
        if (!existMachine) {
            currentMachines.push(m);
            setMachineSaved([...currentMachines]);
            localStorage.setItem("machines", JSON.stringify(machineSaved))
        }
        //verifico se esiste workcentre
        let currentWorkcentres = workcentreSaved;
        if (currentWorkcentres.length > 0) {
            for (let i = 0; i < currentWorkcentres.length; i++) {
                if (currentWorkcentres[i].id.toString() === m.workcentre.id.toString()) {
                    existWorkcentre = true;
                    workcentreIndex = i;
                }
            }
        }
        //se il workcentre se esiste lo rimuovo per poi aggiungerlo dopo con le macchine aggiornate
        if (existWorkcentre) {
            currentWorkcentres.splice(workcentreIndex, 1);
        };
        //aggiungo il workcentre
        const indexOfW = workcentres.findIndex((i) => {
            return i.id.toString() === m.workcentre.id.toString();
        })
        if (indexOfW !== -1) {
            currentWorkcentres.push(workcentres[indexOfW]);
            setWorkcentreSaved([...currentWorkcentres]);
            localStorage.setItem("workcentres", JSON.stringify(workcentreSaved))
        }
    };

    const addRole = (r) => {
        let existRole = false;
        let currentRoles = roleSaved;
        if (currentRoles.length > 0) {
            for (let i = 0; i < currentRoles.length; i++) {
                if (currentRoles[i].id.toString() === r.id.toString()) {
                    existRole = true;
                }
            }
        }
        if (!existRole) {
            currentRoles.push(r);
            setRoleSaved([...currentRoles]);
            localStorage.setItem("roles", JSON.stringify(roleSaved));
        }
    }

    const returnMachineButton = (m) => {
        let existMachine = false;
        if (machineSaved.length > 0) {
            for (let i = 0; i < machineSaved.length; i++) {
                if (m.id.toString() === machineSaved[i].id.toString()) {
                    existMachine = true;
                }
            }
        }
        if (existMachine) {
            return (
                <Button
                    color="secondary" onClick={() => removeMachine(m)}>{customLabel("button.remove")}
                </Button>
            )
        }
        else {
            return (
                <Button
                    color="primary" onClick={() => addMachine(m)}>{customLabel("button.add")}
                </Button>
            )
        }
    }
    const returnRoleButton = (r) => {
        let existRole = false;
        if (roleSaved.length > 0) {
            for (let i = 0; i < roleSaved.length; i++) {
                if (r.id.toString() === roleSaved[i].id.toString()) {
                    existRole = true;
                }
            }
        }
        if (existRole) {
            return (
                <Button
                    color="secondary" onClick={() => removeRole(r)}>{customLabel("button.remove")}
                </Button>
            )
        }
        else {
            return (
                <Button
                    color="primary" onClick={() => addRole(r)}>{customLabel("button.add")}
                </Button>
            )
        }
    }
    const removeMachine = (m) => {
        let currentMachines = machineSaved;
        let currentWorkcentres = workcentreSaved;
        const machineToCancel = currentMachines.findIndex((i) => {
            return i.id.toString() === m.id.toString()
        })
        if (machineToCancel !== -1) {
            currentMachines.splice(machineToCancel, 1);
            setMachineSaved([...currentMachines]);
            localStorage.setItem("machines", JSON.stringify(machineSaved));
        }
        //tolgo i workcentre con zero macchine
        let existAnother = false;
        const currentId = m.workcentre.id.toString();
        for (let i = 0; i < machineSaved.length; i++) {
            if (currentId === machineSaved[i].workcentre.id.toString()) {
                existAnother = true;
            }
        }
        if (!existAnother) {
            const workcentreToCancel = currentWorkcentres.findIndex((i) => {
                return i.id.toString() === currentId;
            })
            if (workcentreToCancel !== -1) {
                currentWorkcentres.splice(workcentreToCancel, 1);
                setWorkcentreSaved([...currentWorkcentres]);
                localStorage.setItem("workcentres", JSON.stringify(workcentreSaved));
            }
        }
    };

    const removeRole = (r) => {
        let currentRoles = roleSaved;
        const roleToCancel = currentRoles.findIndex((i) => {
            return i.id.toString() === r.id.toString();
        })
        if (roleToCancel !== -1) {
            currentRoles.splice(roleToCancel, 1);
            setRoleSaved([...currentRoles]);
            localStorage.setItem("roles", JSON.stringify(roleSaved));
        }
    }

    const resetMachines = () => {
        localStorage.setItem("machines", JSON.stringify([]));
        localStorage.setItem("workcentres", JSON.stringify([]));
        setMachineSaved(JSON.parse(localStorage.getItem("machines")));
        setWorkcentreSaved(JSON.parse(localStorage.getItem("workcentres")));
    };
    const resetRoles = () => {
        localStorage.setItem("roles", JSON.stringify([]));
        setRoleSaved(JSON.parse(localStorage.getItem("roles")));
    };

    const enableDialogMachine = () => {
        setMachineDialog(true);
    };
    const enableDialogRole = () => {
        setRoleDialog(true);
    };


    const renderMachines = () => {
        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{customLabel("machine.machine")}</TableCell>
                        <TableCell>{customLabel("machine.external")}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {workcentres && workcentres.length > 0 ? (
                        workcentres.map(w => {
                            return w.machines.map(m => {
                                if (configuration.showExtMachines || (!configuration.showExtMachines && !m.external)) {
                                    return (
                                        <TableRow key={m.id}>
                                            <TableCell>
                                                {w.code}: {m.code}-{m.name}
                                            </TableCell>
                                            <TableCell className={classes.tableCell1Button}>
                                                {m.external ? (
                                                    <CheckIcon />
                                                ) : null}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableCell1Button}>
                                                {returnMachineButton(m)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                } else {
                                    return null;
                                }
                            }
                            )
                        })
                    ) :
                        customLabel("function.notAvailable").toUpperCase()
                    }
                </TableBody>
            </Table>
        )
    };

    const renderRoles = () => {
        return (
            <Table size="small">
                <TableBody>
                    {roles && roles.length > 0 ? (
                        roles.map((r) => {
                            return (
                                <TableRow key={r.id}>
                                    <TableCell>
                                        {r.authority}
                                    </TableCell>
                                    <TableCell>
                                        {returnRoleButton(r)}
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    ) :
                        customLabel("function.notAvailable").toUpperCase()
                    }
                </TableBody>
            </Table>
        )
    };

    const renderMachinesSelected = () => {
        if (machineSaved.length === 0) {
            return (
                <div className={classes.warning}>
                    {customLabel("function.notAvailable").toUpperCase()}
                </div>
            )
        } else if (machineSaved.length > 0) {
            return (
                machineSaved.map((m) => {
                    return (
                        <ListItem key={m.id}>
                            <ListItemText primary={m.code + " - " + m.workcentre.code} />
                        </ListItem>
                    )
                })
            )
        }
    };
    const renderRolesSelected = () => {
        if (roleSaved.length === 0) {
            return (
                <div className={classes.warning}>
                    {customLabel("function.notAvailable")}
                </div>
            )
        } else if (roleSaved.length > 0) {
            return (
                roleSaved.map((r) => {
                    return (
                        <ListItem key={r.id}>
                            <ListItemText primary={r.authority} />
                        </ListItem>
                    )
                })
            )
        }
    };
    const handleChangeWorkcentre = (event) => {
        let value;
        if (event.target.checked) {
            value = "true";
        } else {
            value = "false"
        }
        localStorage.setItem("changeWorkcentre", value);
        if (localStorage.getItem("changeWorkcentre") === "true") {
            setChangeWorkcentre(true);
        } else {
            setChangeWorkcentre(false);
        }
    };

    const handleChangeWorkstation = (event) => {
        const value = event.target.value;
        localStorage.setItem("workstation", value);
        setWorkstation(localStorage.getItem("workstation"));
    };
    const goToOperatorView = () => {
        window.location.href = window.location.origin + "/operatorView"
    };

    const addAllMachines = () => {
        let workcentresToAdd = [];
        let machinesToAdd = [];
        if (workcentres && workcentres.length > 0) {
            workcentres.forEach((w) => {
                if (configuration.showExtMachines || (!configuration.showExtMachines && w.machines.some(m => !m.external))) {
                    workcentresToAdd.push(w)
                }
                w.machines.forEach((m) => {
                    if (configuration.showExtMachines || (!configuration.showExtMachines && !m.external)) {
                        machinesToAdd.push(m)
                    }
                })
            })
        }
        setWorkcentreSaved([...workcentresToAdd]);
        setMachineSaved([...machinesToAdd]);
        localStorage.setItem("workcentres", JSON.stringify(workcentresToAdd));
        localStorage.setItem("machines", JSON.stringify(machinesToAdd));
    }

    const addAllRoles = () => {
        let rolesToAdd = [];
        if (roles && roles.length > 0) {
            roles.forEach((r) => {
                rolesToAdd.push(r)
            })
        }
        setRoleSaved([...rolesToAdd]);
        localStorage.setItem("roles", JSON.stringify(rolesToAdd));
    }

    return (
        <div className={classes.root}>
            <NotificationsPage />
            <CssBaseline />
            <AppBar position="absolute" className={classes.appBar}
            >
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                        <b>{customLabel("bishop.bishop").toUpperCase()}</b>
                    </Typography>
                    <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                        <b>{configuration.name ? configuration.name : customLabel("function.customer")}</b>
                    </Typography>
                    <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                        <b><Clock format={'HH:mm:ss'} ticking={true} timezone={'Europe/Rome'} /></b>
                    </Typography>
                    <img height="50" alt="ultrafab_logo" src={logobishop} />
                    <IconButton className={classes.fullIcon} onClick={() => toggleFullScreen(document.body)}>
                        <FullscreenIcon style={{ color: "white" }} fontSize="large" />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Paper className={classes.paper}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                                {customLabel("settings.machinesSelected")}
                            </Typography>
                            <List>
                                {renderMachinesSelected()}
                            </List>
                            <Button onClick={() => enableDialogMachine()} className={classes.updateButton}
                                variant="contained" color="primary">{customLabel("button.update")}
                            </Button>
                            <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                                {customLabel("settings.rolesSelected")}
                            </Typography>
                            <List>
                                {renderRolesSelected()}
                            </List>
                            <Button onClick={() => enableDialogRole()} className={classes.updateButton}
                                variant="contained" color="primary">{customLabel("button.update")}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                                {customLabel("settings.customizeWorkstation")}
                            </Typography>
                            <div className={classes.formDiv}>
                                <FormControl className={classes.form}>
                                    <InputLabel htmlFor="workstation">{customLabel("settings.workstationName")}</InputLabel>
                                    <Input name="workstation" value={workstation} onChange={handleChangeWorkstation} />
                                </FormControl>
                                <FormControlLabel labelPlacement="end" className={classes.formCheck}
                                    control={
                                        <Checkbox checked={changeWorkcentre} name="changeWorkcentre"
                                            onChange={handleChangeWorkcentre} color="default" />
                                    }
                                    label={customLabel("settings.changeWorkcentreEnabled")}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                                {customLabel("operatorView.operatorView")}
                            </Typography>
                            <Button onClick={() => goToOperatorView()} className={classes.goButton}
                                color="primary" variant="contained">{customLabel("button.go")}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </main >
            <Dialog fullWidth maxWidth="md" open={machineDialog}>
                <DialogTitle>
                    {customLabel("settings.manageMachinesOfWorkstation")}
                </DialogTitle>
                <DialogContent>
                    {renderMachines()}
                </DialogContent>
                <DialogActions style={{ padding: "16px 24px" }}>
                    <Button variant="contained" onClick={() => resetMachines()} color="primary">{customLabel("button.reset")}</Button>
                    <Button variant="contained" onClick={() => addAllMachines()} color="primary">{customLabel("button.allFem")}</Button>
                    <div style={{ flex: "1 0 0" }} />
                    <Button variant="contained" onClick={() => setMachineDialog(false)} color="secondary">{customLabel("button.close")}</Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth maxWidth="md" open={roleDialog}>
                <DialogTitle>
                    {customLabel("settings.manageRolesoFWorkstation")}
                </DialogTitle>
                <DialogContent>
                    {renderRoles()}
                </DialogContent>
                <DialogActions style={{ padding: "16px 24px" }}>
                    <Button variant="contained" onClick={() => resetRoles()} color="primary">{customLabel("button.reset")}</Button>
                    <Button variant="contained" onClick={() => addAllRoles()} color="primary">{customLabel("button.all")}</Button>
                    <div style={{ flex: "1 0 0" }} />
                    <Button variant="contained" onClick={() => setRoleDialog(false)} color="secondary">{customLabel("button.close")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default SettingsPage;