export const currentList = {
    started: {
        url: "started",
        loading: "loadingStarted",
        errors: "errorsStarted",
        total: "totalStarted",
        odps: "odpsStarted",
        filter: "startedFilter",
        tabIndex: 0
    },
    paused: {
        url: "paused",
        loading: "loadingPaused",
        errors: "errorsPaused",
        total: "totalPaused",
        odps: "odpsPaused",
        filter: "pausedFilter",
        tabIndex: 1
    },
    notStarted: {
        url: "notStarted",
        loading: "loadingNotStarted",
        errors: "errorsNotStarted",
        total: "totalNotStarted",
        odps: "odpsNotStarted",
        filter: "notStartedFilter",
        tabIndex: 2
    },
    notScheduled: {
        url: "notScheduled",
        loading: "loadingNotScheduled",
        errors: "errorsNotScheduled",
        total: "totalNotScheduled",
        odps: "odpsNotScheduled",
        filter: "notScheduledFilter",
        tabIndex: 3
    },
    external: {
        url: "external",
        loading: "loadingExternal",
        errors: "errorsExternal",
        total: "totalExternal",
        odps: "odpsExternal",
        filter: "externalFilter",
        tabIndex: 4
    },
    closed: {
        url: "closed",
        loading: "loadingClosed",
        errors: "errorsClosed",
        total: "totalClosed",
        odps: "odpsClosed",
        filter: "closedFilter",
        tabIndex: 5
    }
}

export const customFilter = {
    page: 0,
    rowsPerPage: 10,
    code: false,
    customer: false,
    customerOrder: false,
    showFilter: false,
    article: false,
    supplier: false,
    deliveryDate: false,
    machine: false,
    workcentre: false
}

