import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../constants";
import { showError } from "../notifications/slice";
import { customLabel } from "../utility/customLabel";

export const initialState = {
    loading: false,
    loadingMachineEvent: false,
    loadingLastOdps: false,
    loadingNextOdps: false,
    loadingNextMain: false,
    reloading: false, //loading usato per aggiornamento / useInterval()
    hasErrors: false,
    updatingErrors: false, //errors in caso di errori sull'aggiornamento / useInterval()
    machine: false,
    currentProcess: false,
    machineEvent: false,
    lastOdps: false,
    nextOdps: false,
    nextMaintenances: false,
    selectedMachine: false,
    processVars: false,
    machineEventStapling: false,
    stapling: false,
    staplingVars: false,
    colors: false,
    customEvents: false,
    current: window.location.pathname,
};

const machineViewSlice = createSlice({
    name: "machineView",
    initialState,
    reducers: {
        getting: state => {
            state.loading = true
            state.hasErrors = false
        },
        gettingMachineEvent: (state) => {
            state.loadingMachineEvent = true;
            state.hasErrors = false;
        },
        gettingLastOdps: state => {
            state.loadingLastOdps = true;
            state.hasErrors = false;
        },
        gettingNextOdps: state => {
            state.loadingNextOdps = true;
            state.hasErrors = false;
        },
        gettingNextMain: state => {
            state.loadingNextMain = true;
            state.hasErrors = false;
        },
        updating: state => {
            state.reloading = true;
            state.updatingErrors = false;
        },
        getOdpsSuccess: (state, { payload }) => {
            state.loading = false;
            state.odps = payload;
        },
        getMaintenancesSuccess: (state, { payload }) => {
            state.loading = false;
            state.maintenances = payload;
        },
        enableErrors: (state) => {
            state.loading = false;
            state.loadingLastOdps = false;
            state.loadingNextMain = false;
            state.loadingNextOdps = false;
            state.hasErrors = true;
        },
        /*         getMachineSuccess: (state, { payload }) => {
                    state.loading = false;
                    state.selectedMachine[payload.index] = payload.data;
                }, */
        getMachineEventSuccess: (state, { payload }) => {
            state.loadingMachineEvent = false;
            if (payload.machineEvent !== null) {
                state.machineEvent = payload.machineEvent
                state.currentProcess = payload.machineEvent.process;
                state.stapling = false
            } else if (payload.machineEventStapling !== null) {
                state.machineEventStapling = payload.machineEventStapling
                state.currentProcess = false
            }
        },
        getNextOdpsSuccess: (state, { payload }) => {
            state.loadingNextOdps = false;
            state.nextOdps = payload.odps.slice(0, 5);
        },
        getLastOdpsSuccess: (state, { payload }) => {
            state.loadingLastOdps = false;
            //qui il max lo passo direttamente nella chiamata get
            state.lastOdps = payload;
        },
        getNextMaintenancesSuccess: (state, { payload }) => {
            state.loadingNextMain = false;
            state.nextMaintenances = payload.odps.slice(0, 5);
        },
        enableUpdatingErrors: state => {
            state.reloading = false;
            state.updatingErrors = true;
        },
        resetData: state => {
            state.machineEvent = false
            state.machineEventStapling = false
            state.lastOdps = false
            state.nextOdps = false
            state.currentProcess = false
            state.stapling = false
            state.processVars = false
            state.staplingVars = false
        },
        fillProcessVars: (state, { payload }) => {
            state.processVars = payload
        },
        integrateOdpStapling: (state, { payload }) => {
            state.stapling = payload
        },
        fillStaplingVars: (state, { payload }) => {
            state.staplingVars = payload
        },
        doChange: (state, { payload }) => {
            state.current = payload;
        },
        getEventsSuccess: (state, { payload }) => {
            state.loadingEvent = false
            let colors = {}
            for (let e in payload.me) {
                let mt = payload.me[e]
                colors[mt.name] = mt.backgroundColor
            }
            let events = []
            for (let x in payload.data["events"]) {
                let me = payload.data["events"][x]
                for (let e in payload.me) {
                    let mt = payload.me[e]
                    if (mt.name === me.name) {
                        let v = 0
                        if (payload.data.tot[0] !== null) {
                            v = me.duration / payload.data.tot[0].duration * 100
                        }
                        events.push({ id: mt.name, label: mt.name, color: mt.backgroundColor, value: v.toFixed(2), svalue: me.duration })
                    }
                }
            }
            //state[payload.callbackItem] = events;
            state.customEvents = events;
            state.colors = colors;
        },
    },
});

export const { getting, gettingMachineEvent, getOdpsSuccess, getMaintenancesSuccess, enableErrors, getMachineSuccess, getMachineEventSuccess, updateSuccess, getLastOdpsSuccess,
    getNextMaintenancesSuccess, getNextOdpsSuccess, updating, enableUpdatingErrors, gettingLastOdps, gettingNextMain, gettingNextOdps, resetData, fillProcessVars, integrateOdpStapling, fillStaplingVars, doChange, getEventsSuccess } = machineViewSlice.actions;

export const machineViewSelector = (state) => state.machineView;
export default machineViewSlice.reducer;

export function getMachineAPI(machineId) {
    let access_token = "";
    if (localStorage.getItem("bishop_current_user") != null) {
        access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
            .access_token;
    }
    return async (dispatch) => {
        dispatch(getting());
        const response = await fetch(SERVER_URL + "/machine/" + machineId,
            {
                mode: "cors",
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    Authorization: "Bearer " + access_token,
                },
            }
        );

        const data = await response.json();
        if (response.status === 200) {
            dispatch(getMachineSuccess(data));
        } else {
            dispatch(showError(data.message));
            dispatch(enableErrors());
        }
    };
}

export function getMachineEventAPI(machineId, maintenance) {
    return async (dispatch) => {
        dispatch(gettingMachineEvent());
        const response = await fetch(SERVER_URL + "/machine/" + machineId + "/activity?pause=true&maintenance=" + maintenance, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
        });
        const data = await response.json();
        if (response.status === 200) {
            if (data.machineEventStapling) {
                dispatch(integrateOdpStaplingAPI(data.machineEventStapling.odp))
                dispatch(getStaplingVarsAPI(data.machineEventStapling.odp))
            }
            dispatch(getMachineEventSuccess(data));
        } else {
            dispatch(enableErrors());
            dispatch(showError(data.message));
        }
    };
};

export function integrateOdpStaplingAPI(odp) {
    return async (dispatch) => {
        dispatch(getting())
        const response = await fetch(SERVER_URL + '/odp/' + odp.id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
        });
        const data = await response.json();
        if (response.status === 200) {
            dispatch(integrateOdpStapling(data));
        } else {
            dispatch(enableErrors());
            dispatch(showError(data.message));
        }
    };
};

export function getStaplingVarsAPI(odp) {
    return async dispatch => {
        dispatch(getting())
        const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/stapling/vars", {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            }
        });
        const data = await response.json()
        if (response.status === 200) {
            dispatch(fillStaplingVars(data));
        } else {
            dispatch(enableErrors());
            dispatch(showError(data.message));
        }
    }
};

export function getNextOdpsAPI(machineId, page, per_page) {
    return async (dispatch) => {
        dispatch(gettingNextOdps())
        const response = await fetch(SERVER_URL + "/machine/" + machineId + "/odp?maintenance=false&page=" + page + "&per_page=" + per_page, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
        });
        const data = await response.json();
        if (response.status === 200) {
            dispatch(getNextOdpsSuccess(data));
        } else {
            dispatch(enableErrors());
            dispatch(showError(data.message));
        }
    };
};
export function getNextMaintenancesAPI(machineId, page, per_page) {
    return async (dispatch) => {
        dispatch(gettingNextMain())
        const response = await fetch(SERVER_URL + "/machine/" + machineId + "/odp?maintenance=true&page=" + page + "&per_page=" + per_page, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
        });
        const data = await response.json();
        if (response.status === 200) {
            dispatch(getNextMaintenancesSuccess(data));
        } else {
            dispatch(enableErrors());
            dispatch(showError(data.message));
        }
    };
};
export function getLastOdpsAPI(machineId) {
    return async (dispatch) => {
        dispatch(gettingLastOdps())
        const response = await fetch(SERVER_URL + "/odp/closed?maintenance=false&machine=" + machineId + "&max=5", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
        });
        const data = await response.json();
        if (response.status === 200) {
            dispatch(getLastOdpsSuccess(data));
        } else {
            dispatch(enableErrors());
            dispatch(showError(data.message));
        }
    };
};

export function getProcessVarsAPI(process) {
    return async dispatch => {
        const response = await fetch(SERVER_URL + "/process/" + process.id + "/vars", {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            }
        });
        const data = await response.json()
        if (response.status === 200) {
            dispatch(fillProcessVars(data));
        } else {
            dispatch(enableErrors());
            dispatch(showError(data.message));
        }
    }
};

export function getEvents(machine, from, to, machineEvents) {
    return async (dispatch) => {
        const response = await fetch(SERVER_URL + "/machine/" + machine.id + "/events?from=" + from + "&to=" + to,
            {
                mode: "cors",
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8"
                },
            }
        );

        try {
            const data = await response.json();
            if (response.status === 200) {
                dispatch(getEventsSuccess({ data: data, me: machineEvents }));
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors());
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    };
}