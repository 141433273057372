import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from 'react';

/**
 * 
 * @param {boolean} flag per apertura dialog, può essere anche un object
 * @param {string} title titolo dialog
 * @param {function} form funzione che restituisce il dialog content
 * @param {function} actions funzione che restituisce dialog actions
 * @param {boolean} transparentBackround se true non scurisce il background sottostante
 * @returns 
 */
const DialogOperator = ({ flag, title, form, actions, transparentBackround, check, justifyColor }) => {
    let checkHasColor = check !== undefined && check !== null && check.color
    let jc = justifyColor !== undefined && justifyColor !== null && justifyColor
    return (
        <Dialog
            BackdropProps={transparentBackround ? { style: { backgroundColor: "transparent" } } : null}
            open={flag} fullWidth keepMounted maxWidth={"md"} PaperProps={{ style: { backgroundColor: checkHasColor ? check.color : jc ? jc : null } }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {form()}
            </DialogContent>
            <DialogActions style={{ padding: "16px 24px" }}>
                {actions()}
            </DialogActions>
        </Dialog>
    )
}
export default DialogOperator;
