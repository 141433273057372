import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { bishopFormats } from '../../constants';
import { customLabel } from "../utility/customLabel";
import { Typography, FormControl, InputLabel, Input, TextField, FormControlLabel, Checkbox, Grid, Divider } from '@material-ui/core';
import { selector as customerOrdersSelector, getListAPI as getCustomerOrdersAPI } from "../customerOrders/slice"
import { bomsSelector, getListAPI as getBomsAPI } from "../boms/slice"
import { customersSelector, getListAPI as getCustomersAPI } from "../customers/slice"
import { articlesSelector, getListAPI as getArticlesAPI } from "../articles/slice"
import { measureToolsSelector, getListAPI as getMeasureToolsAPI } from "../measureTools/slice";
import { odpSmartSelector, change, changeObject, changeCO, changeObjectCO } from "./slice";
import { mesStyles } from "../utility/ultrafabStyles";
import PaperAutocomplete from '../utility/PaperAutocomplete';

const Form = () => {
  const myClasses = mesStyles();
  const dispatch = useDispatch();
  const { customerOrders } = useSelector(customerOrdersSelector)
  const { customers } = useSelector(customersSelector)
  const { boms } = useSelector(bomsSelector)
  const { articles } = useSelector(articlesSelector)
  const { measureTools } = useSelector(measureToolsSelector);
  const { odpedit, loading, customerOrderedit } = useSelector(odpSmartSelector)

  useEffect(() => {
    // carico gli ordini clienti (se non è già stato fatto)
    if (!loading && customerOrders === false) {
      dispatch(getCustomerOrdersAPI(0, 100000, "code", "asc", false, false, false, false, false, false));
    }
  }, [dispatch, customerOrders, loading]);

  useEffect(() => {
    // una volta caricati gli ordini cliente, carico le distinte (se non è già stato fatto)
    if (!loading && customerOrders !== false && boms === false) {
      dispatch(getBomsAPI(0, 100000, "name", "asc", false, false));
    }
  }, [dispatch, customerOrders, boms, loading]);

  useEffect(() => {
    // una volta caricati gli ordini cliente e le distinte, carico i clienti (se non è già stato fatto)
    if (!loading && customerOrders !== false && boms !== false && customers === false) {
      dispatch(getCustomersAPI(0, 100000, "code", "asc", false, false));
    }
  }, [dispatch, customerOrders, boms, customers, loading]);

  useEffect(() => {
    // una volta caricati gli ordini cliente, le distinte, i clienti, carico gli articoli (se non è già stato fatto)
    if (!loading && customerOrders !== false && boms !== false && customers !== false && articles === false) {
      dispatch(getArticlesAPI(0, 100000, "code", "asc", false, false, false, false, false));
    }
  }, [dispatch, customerOrders, boms, customers, articles, loading]);

  useEffect(() => {
    // una volta caricati gli ordini cliente, le distinte, i clienti, gli articoli, carico gli strumenti di misura
    if (!loading && customerOrders !== false && boms !== false && customers !== false && articles !== false && measureTools === false) {
      dispatch(getMeasureToolsAPI(0, 100000, "name", "asc", false, false, false, false));
    }
  }, [dispatch, customerOrders, boms, customers, articles, loading, measureTools]);

  const onChangeFilterAutocompleteCO = (name, value, reason) => {
    if (reason === "clear") {
      handleChangeObjectCO(name, false)
    } else {
      handleChangeObjectCO(name, value.id)
    }
  };

  const onChangeFilterAutocomplete = (name, value, reason) => {
    if (reason === "clear") {
      handleChangeObject(name, false)
    } else {
      handleChangeObject(name, value.id)
    }
  };

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    dispatch(change({ name: name, value: value, customerOrders: customerOrders, customers: customers, articles: articles, boms: boms }));
  };

  const handleChangeDate = (name, value) => {
    dispatch(change({ name: name, value: new Date(value.format()).getTime() }));
  };

  const handleChangeObject = (name, value) => {
    if (value !== false) {
      dispatch(change({ name: name, value: value, customerOrders: customerOrders, customers: customers, articles: articles, boms: boms }))
    }
    else {
      dispatch(changeObject({ name: name, value: false }))
    }
  };
  const handleChangeObjectCO = (name, value) => {
    if (value !== false) {
      dispatch(changeCO({ name: name, value: value, customers: customers }))
    }
    else {
      dispatch(changeObjectCO({ name: name, value: false }))
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <div className={myClasses.titleFormSpaceBetween}>
            <Typography variant="h6">{customLabel("customerOrder.customerOrder")}</Typography>
          </div>
          <div className={myClasses.subtitle}>
            <i>{customLabel("smartOdp.selectFromList")}</i>
          </div>
          <form className={myClasses.formContainer} noValidate autoComplete="off">
            {customerOrders ? (
              <Autocomplete
                PaperComponent={PaperAutocomplete}
                className={myClasses.form50}
                disabled={customerOrderedit.customer.id !== ''}
                style={{ display: "inline-block" }}
                value={odpedit.customerOrder.id !== "" ? odpedit.customerOrder : null}
                getOptionSelected={(option, value) => odpedit.customerOrder.id !== '' ? option.id === value.id : null}
                onChange={(event, value, reason) => onChangeFilterAutocomplete("customerOrder.id", value, reason)}
                options={customerOrders}
                getOptionLabel={(option) => option.code}
                id="customerOrderAutocomplete"
                renderInput={(params) => <TextField {...params} label={customLabel("odp.customerOrder")} error={odpedit.customerOrderCode === "" && odpedit.customerOrder.id === '' && customerOrderedit.customer.id === ''} />
                }
              />
            ) : null}
            <FormControl className={myClasses.form50} disabled={odpedit.startDate !== null}>
              <InputLabel shrink htmlFor="customer">{customLabel("odp.customer.customer")}</InputLabel>
              <Input disabled name="customer.id" value={odpedit.customer.name ? odpedit.customer.code + " - " + odpedit.customer.name : ""} error={odpedit.customer.id === "" && customerOrderedit.customer.id === ''} />
            </FormControl>
            <Divider className={myClasses.dividerMarginVert1} />
          </form>
          <div className={myClasses.subtitle}>
            <i>{customLabel("smartOdp.addNew")}</i>
          </div>
          {customers ? (
            <form className={myClasses.formContainer} noValidate autoComplete="off">
              <FormControl className={myClasses.form50} margin="normal" disabled={odpedit.startDate !== null}>
                <InputLabel htmlFor="customerOrderCode">{customLabel("odp.code")}</InputLabel>
                <Input autoFocus name="customerOrderCode" value={odpedit.customerOrderCode} error={odpedit.customerOrder.id === '' && odpedit.customerOrderCode === ''} onChange={handleChange} />
              </FormControl>
              <Autocomplete
                PaperComponent={PaperAutocomplete}
                disabled={odpedit.customerOrder.id !== ''}
                style={{ display: "inline-block" }}
                className={myClasses.form50}
                value={customerOrderedit.customer.id !== "" ? customerOrderedit.customer : null}
                getOptionSelected={(option, value) => customerOrderedit.customer.id !== '' ? option.id === value.id : null}
                onChange={(event, value, reason) => onChangeFilterAutocompleteCO("customer.id", value, reason)}
                options={customers}
                getOptionLabel={(option) => option.code + " - " + option.name}
                id="customerAutocomplete"
                renderInput={(params) => <TextField {...params} label={customLabel("customer.customer")} error={customerOrderedit.customer.id === '' && odpedit.customer.id === ""} />
                }
              />
            </form>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <div className={myClasses.titleFormSpaceBetween}>
            <Typography variant="h6">{customLabel("odp.odp")}</Typography>
          </div>
          <form className={myClasses.formContainer} noValidate autoComplete="off">
            <FormControl className={myClasses.form50} margin="normal" disabled={odpedit.startDate !== null}>
              <InputLabel htmlFor="code">{customLabel("odp.code")}</InputLabel>
              <Input autoFocus name="code" error={odpedit.code === ""} value={odpedit.code} onChange={handleChange} />
            </FormControl>
            <FormControl className={myClasses.form50} margin="normal">
              <InputLabel htmlFor="extcode">{customLabel("odp.extCode")}</InputLabel>
              <Input name="extCode" value={odpedit.extCode} onChange={handleChange} />
            </FormControl>
            {articles ? (
              <Autocomplete
                PaperComponent={PaperAutocomplete}
                disabled={odpedit.startDate !== null}
                style={{ display: "inline-block" }}
                className={myClasses.form50}
                value={odpedit.article.id !== "" ? odpedit.article : null}
                getOptionSelected={(option, value) => odpedit.article.id !== '' ? option.id === value.id : null}
                onChange={(event, value, reason) => onChangeFilterAutocomplete("article.id", value, reason)}
                options={articles}
                getOptionLabel={(option) => option.code + " - " + option.name}
                id="articleAutocomplete"
                renderInput={(params) => <TextField {...params} label={customLabel("article.article")} error={odpedit.article.id === ''} />
                }
              />)

              : null}
            {boms ? (
              <Autocomplete
                PaperComponent={PaperAutocomplete}
                disabled={odpedit.startDate !== null}
                style={{ display: "inline-block" }}
                className={myClasses.form50}
                value={odpedit.bom.id !== "" ? odpedit.bom : null}
                getOptionSelected={(option, value) => odpedit.bom.id !== '' ? option.id === value.id : null}
                onChange={(event, value, reason) => onChangeFilterAutocomplete("bom.id", value, reason)}
                options={boms}
                getOptionLabel={(option) => option.code + " - " + option.name}
                id="bomsAutocomplete"
                renderInput={(params) => <TextField {...params} label={customLabel("odp.bom")} error={odpedit.bom.id === ''} />
                }
              />)
              : null}
            <FormControl className={myClasses.form50} disabled={odpedit.startDate !== null}>
              <InputLabel htmlFor="quantity">{customLabel("odp.quantity")}</InputLabel>
              <Input name="quantity" error={odpedit.quantity < 0 || odpedit.quantity === ""} value={odpedit.quantity}
                onChange={handleChange} type="number" />
            </FormControl>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <DatePicker className={myClasses.form50}
                disableToolbar
                allowKeyboardControl={false}
                variant="inline"
                format={bishopFormats.L}
                autoOk
                onChange={(date) => handleChangeDate("deliveryDate", date)}
                label={customLabel("odp.deliveryDate")}
                value={odpedit.deliveryDate}
              />
            </MuiPickersUtilsProvider>
            <FormControlLabel labelPlacement="end" className={myClasses.form50} margin="normal"
              control={
                <Checkbox checked={odpedit.toBeProcessed} onChange={handleChange} name="toBeProcessed" color="default" />
              }
              label={customLabel("odp.toBeProcessed")}
            />
            <FormControl className={myClasses.form50}>
              <TextField
                variant="outlined" name="note" label={customLabel("function.note")} multiline rows={4} value={odpedit.note === null ? "" : odpedit.note}
                onChange={handleChange}
              />
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Form;