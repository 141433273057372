export const buttonType = {
    rest: "rest",
    newWindow: "newWindow"
}

export const customFilter = {
    mesName: "buttons",
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "name",
    showFilter: false,
    name: false,
    type: false,
    api: false
}

export const headers = {
    name: { filter: true, type: "string" },
    type: { filter: true, type: "stringL" },
    api: { filter: true, type: "string" },
};

