import React from "react";
import { Link } from "react-router-dom";
import {
  Typography, Button, Table, TableCell, TableRow, TableBody
} from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockIcon from '@material-ui/icons/Lock';
import CheckIcon from '@material-ui/icons/Check';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";

export const Details = ({ odpStatus, edit, toggleDelete, deleting, doDelete, loading }) => {
  const myClasses = mesStyles();

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">{customLabel("odpStatus.odpStatus")}</Typography>
        <LockIcon />
      </div>
      <div className={myClasses.tableContainer}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell> {customLabel("odpStatus.name")}</TableCell>
              <TableCell>{odpStatus.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell> {customLabel("odpStatus.systemStatus")}</TableCell>
              <TableCell>
                {odpStatus.systemStatus ? <CheckIcon /> : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{customLabel("odpStatus.backgroundColor")}</TableCell>
              <TableCell>
                {odpStatus.backgroundColor ? (
                  <div className={myClasses.backgroundColor}
                    style={{ backgroundColor: odpStatus.backgroundColor }}
                  ></div>
                ) : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple key={odpStatus.id} onClick={edit} color="primary" disabled={loading}>
          {customLabel("button.edit")}
        </Button>
        <Button color="secondary" onClick={toggleDelete} disabled={odpStatus.systemStatus || loading}>
          {customLabel("button.delete")}
        </Button>
        <Button component={Link} to={`/admin/odpStatuses`} disabled={loading}>
          {customLabel("button.back")}
        </Button>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={toggleDelete} disabled={loading} />
    </PaperForm>
  );
};
