export const headers = {
    maintenanceType: { filter: true, type: "object" },
    deliveryDate: { filter: true, type: "date", name: "deliveryDate" },
    machine: { filter: true, type: "object" },
    toBeProcessed: { filter: true, type: "booleanString" },
    extraordinary: { filter: true, type: "booleanString" }
};

export const customFilter = {
    mesName: "maintenances",
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "deliveryDate",
    showFilter: false,
    extraordinary: false,
    toBeProcessed: false,
    maintenanceType: false,
    machine: false,
    deliveryDateFrom: false,
    deliveryDateTo: false
}