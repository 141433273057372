import React from "react";
import { Link } from "react-router-dom";
import { ChromePicker } from "react-color";
import { Grid, FormControl, InputLabel, Input, Button, Typography, MenuItem, Select, Checkbox, FormControlLabel } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";

export const Form = ({ machineEventedit, handleChange, cancelEdit, save, odpStatuses, processStatuses, loading }) => {
  const myClasses = mesStyles();

  const handleChangeComplete = (color) => {
    //active color
    let c = "rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + ",1)";
    let e = { preventDefault: function () { }, persist: function () { } };
    let t = { name: "backgroundColor", value: c };
    //disactive color
    let c2 = "rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + ",0.4)";
    let e2 = { preventDefault: function () { }, persist: function () { } };
    let t2 = { name: "disactiveBackgroundColor", value: c2 };
    e["target"] = t;
    e2["target"] = t2
    handleChange(e);
    handleChange(e2)
  };
  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h3" variant="h6" color="inherit">
          {customLabel("machineEvent.machineEvent")}
        </Typography>
        <LockOpenIcon />
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
          <form className={myClasses.formContainer} noValidate autoComplete="off">
            <FormControl className={myClasses.form50} disabled={machineEventedit.systemEvent}>
              <InputLabel htmlFor="name">
                {customLabel("machineEvent.name")}
              </InputLabel>
              <Input autoFocus name="name" error={machineEventedit.name === ""} value={machineEventedit.name}
                onChange={handleChange} />
            </FormControl>
            <br />
            {odpStatuses ? (
              <FormControl margin="normal" className={myClasses.form50} disabled={machineEventedit.systemEvent}>
                <InputLabel htmlFor="odpStatus">
                  {customLabel("odpStatus.odpStatus")}
                </InputLabel>
                <Select name="odpStatus.id" onChange={handleChange}
                  value={machineEventedit.odpStatus ? machineEventedit.odpStatus.id : ""}
                  autoWidth={true}
                  error={!machineEventedit.odpStatus}>
                  <MenuItem value="">
                    <option></option>
                  </MenuItem>
                  {odpStatuses.map((os) => {
                    return (
                      <MenuItem key={os.id} value={os.id}>
                        {os.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : null}
            <br />
            {processStatuses ? (
              <FormControl margin="normal" className={myClasses.form50} disabled={machineEventedit.systemEvent}>
                <InputLabel htmlFor="processStatus">
                  {customLabel("processStatus.processStatus")}
                </InputLabel>
                <Select name="processStatus.id" onChange={handleChange}
                  value={machineEventedit.processStatus ? machineEventedit.processStatus.id : ""}
                  autoWidth={true} error={!machineEventedit.processStatus}
                >
                  <MenuItem value="">
                    <option></option>
                  </MenuItem>
                  {processStatuses.map((ps) => {
                    return (
                      <MenuItem key={ps.id} value={ps.id}>
                        {ps.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : null}
            <br />
            <FormControl margin="normal">
              <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="excludeFromKPI" onChange={handleChange} checked={machineEventedit.excludeFromKPI} />}
                label={customLabel("machineEvent.excludeFromKPI")} />
            </FormControl>
            <FormControl margin="normal">
              <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="productive" onChange={handleChange} checked={machineEventedit.productive} />}
                label={customLabel("machineEvent.productive")} />
            </FormControl>
            <FormControl margin="normal">
              <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="setup" onChange={handleChange} checked={machineEventedit.setup} />}
                label={customLabel("machineEvent.setup")} />
            </FormControl>
          </form>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className={myClasses.formContainer}>
            <FormControl margin="normal">
              <InputLabel htmlFor="backgroundColor">
                {customLabel("machineEvent.backgroundColor")}
              </InputLabel>
              <Input name="backgroundColor" value={machineEventedit.backgroundColor !== false ? machineEventedit.backgroundColor : ""}
                error={machineEventedit.backgroundColor === "" || machineEventedit.backgroundColor === false} onChange={handleChange} />
            </FormControl>
            <ChromePicker color={machineEventedit.backgroundColor !== false ? machineEventedit.backgroundColor : "rgba(0,0,0)"}
              onChangeComplete={(color) => handleChangeComplete(color)} />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className={myClasses.formContainer}>
            <FormControl margin="normal" >
              <InputLabel htmlFor="disactiveBackgroundColor">
                {customLabel("machineEvent.disactiveBackgroundColor")}
              </InputLabel>
              <Input name="disactiveBackgroundColor"
                value={machineEventedit.disactiveBackgroundColor !== false ? machineEventedit.disactiveBackgroundColor : ""}
                error={machineEventedit.disactiveBackgroundColor === "" || machineEventedit.disactiveBackgroundColor === false} onChange={handleChange} />
            </FormControl>
            <ChromePicker color={machineEventedit.disactiveBackgroundColor !== false ? machineEventedit.disactiveBackgroundColor : "rgba(0,0,0)"}
              onChangeComplete={(color) => handleChangeComplete(color)} />
          </div>
        </Grid>
      </Grid>
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple onClick={save} disabled={machineEventedit.name === "" || machineEventedit.disactiveBackgroundColor === "" || machineEventedit.backgroundColor === ""
          || !machineEventedit.odpStatus || !machineEventedit.processStatus || machineEventedit.disactiveBackgroundColor === false
          || machineEventedit.backgroundColor === false || loading}
          color="primary">
          {customLabel("button.save")}
        </Button>
        {machineEventedit.id !== "new" ? (
          <Button focusRipple onClick={cancelEdit} disabled={loading}>
            {customLabel("button.cancel")}
          </Button>
        ) : (
          <Button component={Link} to={`/admin/machineEvents`} disabled={loading}>
            {customLabel("button.back")}
          </Button>
        )}
      </div>
    </PaperForm>
  );
};
