import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Container, Grid, CardMedia, Card, CardActions, CardContent, Paper } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { Link } from "react-router-dom";
import { doChange } from "./slice";
import { StylesProvider } from "@material-ui/core/styles";
import "./homepage.css"
import { resetState as resetArticles } from "../articles/slice";
import { resetState as resetCustomerOrders } from "../customerOrders/slice";
import { resetState as resetCustomers } from "../customers/slice";
import { resetState as resetMeasureTools } from "../measureTools/slice";
import { resetState as resetBoms } from "../boms/slice";
import { SERIAL_NUMBER } from "../../constants";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },
  fastLinks: {
    padding: theme.spacing(2),
    textAlign: "center",
    marginTop: theme.spacing(6)
  },
  logobishop: {
    marginRight: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    width: "100%"
  },
  heroContent: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: "center"
  },
  cardGrid: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '45.25%', // 53,25 = 16:9
  },
  cardContent: {
    flexGrow: 1,
    paddingBottom: 0,
    paddingTop: theme.spacing(0)
  },
  cardActions: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0
    }
  },
  bottomRight: {
    position: 'fixed',
    bottom: -20,
    right: 2,
  }
}));
const HomePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const timelineImage = process.env.PUBLIC_URL + "/timeline.png";
  const odpStatusImage = process.env.PUBLIC_URL + "/odp_status.png";
  const smartOdpImage = process.env.PUBLIC_URL + "/smartOdp.png";
  const machineStatusImage = process.env.PUBLIC_URL + "/stato-macchine.png";

  const change = (url) => {
    //per ricaricare oggetti smartodp
    if (url === "/smart_odp") {
      dispatch(resetArticles());
      dispatch(resetBoms());
      dispatch(resetMeasureTools());
      dispatch(resetCustomerOrders());
      dispatch(resetCustomers());
    }
    dispatch(doChange(url))
  };

  /*const goToOperatorView = () => {
    window.location.href = window.location.origin + "/operatorView"
  };*/

  return (
    <StylesProvider>
      <Paper>
        <Container maxWidth="xl" className={classes.heroContent}>
          <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
            {customLabel("bishop.bishopLightMes")}
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
            {customLabel("bishop.descriptionBishop")}
          </Typography>
        </Container>
      </Paper>
      <Container className={classes.cardGrid} maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image={timelineImage}
                title="Timeline"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h6" align="center">
                  {customLabel("timeline.timeline")}
                </Typography>
                <Typography>
                  {customLabel("bishop.timelineDescription")}
                </Typography>
              </CardContent>
              <CardActions>
                <Button className={classes.button} color="primary" component={Link} onClick={() => change("/timeline")} to="/timeline">
                  {customLabel("button.go")}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image={odpStatusImage}
                title="Stato commesse"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h6" align="center">
                  {customLabel("odpStatus.odpStatuses")}
                </Typography>
                <Typography>
                  {customLabel("bishop.odpStatusDescription")}
                </Typography>
              </CardContent>
              <CardActions>
                <Button className={classes.button} color="primary" component={Link} onClick={() => change("/odpStatus")} to="/odpStatus">
                  {customLabel("button.go")}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image={machineStatusImage}
                title="Stato macchine"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h6" align="center">
                  {customLabel("bishop.machineStatus")}
                </Typography>
                <Typography>
                  {customLabel("bishop.machineStatusDescription")}
                </Typography>
              </CardContent>
              <CardActions>
                <Button className={classes.button} color="primary" component={Link} onClick={() => change("/machineStatus")} to="/machineStatus">
                  {customLabel("button.go")}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image={smartOdpImage}
                title="Commessa +"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h6" align="center">
                  {customLabel("bishop.odpPlus")}
                </Typography>
                <Typography>
                  {customLabel("bishop.odpPlusDescription")}
                </Typography>
              </CardContent>
              <CardActions>
                <Button className={classes.button} color="primary" component={Link} onClick={() => change("/smart_odp")} to="/smart_odp">
                  {customLabel("button.go")}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <h4 className={classes.bottomRight}>{SERIAL_NUMBER}</h4>
      </Container>
    </StylesProvider>
  );

};

export default HomePage;
