import React from "react";
import { Link } from "react-router-dom";
import { FormControl, InputLabel, Input, Divider, Button, Typography, TextField } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";

export const Form = ({ measureTooledit, handleChange, cancelEdit, save, loading }) => {
  const myClasses = mesStyles();

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">{customLabel("measureTool.measureTool")}</Typography>
        <LockOpenIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="code">{customLabel("measureTool.code")}</InputLabel>
          <Input autoFocus name="code" error={measureTooledit.code === ""} value={measureTooledit.code} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="name">{customLabel("measureTool.name")}</InputLabel>
          <Input name="name" error={measureTooledit.name === ""} value={measureTooledit.name} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="serial">{customLabel("measureTool.serial")}</InputLabel>
          <Input name="serial" value={measureTooledit.serial} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="measure">{customLabel("measureTool.measure")}</InputLabel>
          <Input name="measure" value={measureTooledit.measure} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="constructor">{customLabel("measureTool.constructor")}</InputLabel>
          <Input name="constructor" value={measureTooledit.constructor} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form100}>
          <TextField
            variant="outlined" name="note" label={customLabel("function.note")} multiline rows={4} value={measureTooledit.note}
            onChange={handleChange}
          />
        </FormControl>
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple onClick={save} disabled={measureTooledit.name === "" || measureTooledit.code === "" || loading} color="primary">{customLabel("button.save")}</Button>
        {measureTooledit.id !== "new" ? (
          <Button focusRipple onClick={cancelEdit} disabled={loading}>{customLabel("button.cancel")}</Button>
        ) : (
          <Button component={Link} to={`/measureTools`} disabled={loading}>{customLabel("button.back")}</Button>
        )}
      </div>
    </PaperForm>
  );
};
