import React from 'react'
import { TableRow, Button, TableCell } from "@material-ui/core";
import { splitStyles } from "./splitStyles";
import { customLabel } from "../../utility/customLabel";
import { bishopFormats } from '../../../constants';
import moment from 'moment'

const ProcessFragment = ({ process, onClickSplit, onClickDelete, validPieces, startDate, quantity, machine, disabledSplit, disabledDelete, onClickEdit }) => {
    const classes = splitStyles();

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    {machine}
                </TableCell>
                <TableCell>
                    {quantity}
                </TableCell>
                <TableCell>
                    {startDate !== null ? moment(startDate).format(bishopFormats.LTS) : null}
                </TableCell>
                <TableCell>
                    {validPieces}
                </TableCell>
                <TableCell align="right">
                    <Button className={classes.actionButton} color="primary" variant="contained" disabled={disabledSplit} onClick={onClickSplit}>
                        {customLabel("button.split")}
                    </Button>
                    <Button className={classes.actionButton} color="secondary" variant="contained" onClick={onClickEdit}>
                        {customLabel("button.edit")}
                    </Button>
                    <Button className={classes.actionButton} color="secondary" variant="contained" disabled={disabledDelete} onClick={onClickDelete}>
                        {customLabel("button.delete")}
                    </Button>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default ProcessFragment
