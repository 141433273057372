import React from 'react'
import { mesStyles } from './ultrafabStyles';
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const ButtonProcessStapling = ({ message, stapling }) => {
    const myClasses = mesStyles();

    return (
        <React.Fragment>
            <div className={myClasses.staplingButtonDiva}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-around"
                }}>
                    <IconButton style={{ borderRadius: 0 }} component={Link} to={`/odps/` + stapling.id} >
                        <ArrowRightAltIcon style={{ margin: "auto" }} color="secondary" />
                        <div className={myClasses.alertMessage}>
                            {message}
                        </div>
                    </IconButton>
                </div>
            </div >
        </React.Fragment>
    )
}

export default ButtonProcessStapling;