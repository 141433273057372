import React from "react";
import { Link } from "react-router-dom";
import {
  Typography, FormControl, InputLabel, Input, Divider, Button, TextField, Grid
} from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { bishopFormats } from '../../constants';
import CustomerOrderDetails from './CustomerOrderDetails';
import LockIcon from '@material-ui/icons/Lock';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";

export const Details = ({ customerOrder, edit, toggleDelete, deleting, doDelete, config, loading }) => {
  const myClasses = mesStyles();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <PaperForm>
            <div className={myClasses.titleFormSpaceBetween}>
              <Typography className={myClasses.note} component="h1" variant="h6" color="inherit">
                {customLabel("customerOrder.customerOrder")}
              </Typography>
              <LockIcon />
            </div>
            <form className={myClasses.formContainer} noValidate autoComplete="off">
              <FormControl disabled className={myClasses.form50}>
                <InputLabel htmlFor="code">{customLabel("customerOrder.code")}</InputLabel>
                <Input value={customerOrder.code} />
              </FormControl>
              <FormControl disabled className={myClasses.form50}>
                <InputLabel htmlFor="customer">{customLabel("customerOrder.customer")}</InputLabel>
                <Input value={customerOrder.customer.name} />
              </FormControl>
              {
                config && config.showArticleOrder ? (
                  <React.Fragment>
                    <FormControl disabled className={myClasses.form50}>
                      <InputLabel htmlFor="article">{customLabel("article.article")}</InputLabel>
                      <Input value={customerOrder.article.id !== null ? customerOrder.article.code : ""} />
                    </FormControl>
                    <FormControl disabled className={myClasses.form50}>
                      <InputLabel htmlFor="quantity">{customLabel("customerOrder.quantity")}</InputLabel>
                      <Input value={customerOrder.quantity} />
                    </FormControl>
                  </React.Fragment>
                ) : null
              }
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  className={myClasses.form50}
                  disableToolbar
                  variant="inline"
                  format={bishopFormats.L}
                  margin="normal"
                  disabled={true}
                  label={customLabel("customerOrder.date")}
                  value={customerOrder.date}
                />
              </MuiPickersUtilsProvider>
              {/*   <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  className={myClasses.form50}
                  disableToolbar
                  variant="inline"
                  format={bishopFormats.L}
                  margin="normal"
                  disabled={true}
                  label={customLabel("customerOrder.deliveryDate")}
                  value={customerOrder.deliveryDate}
                />
              </MuiPickersUtilsProvider> */}
              <FormControl disabled className={myClasses.form100}>
                <TextField
                  disabled variant="outlined" name="note" multiline label={customLabel("function.note")} rows={2}
                  value={customerOrder.note && customerOrder.note !== null ? customerOrder.note : ""}
                />
              </FormControl>
              <FormControl disabled className={myClasses.form100}>
                <TextField
                  disabled variant="outlined" name="note2" multiline label={customLabel("function.operatorViewNote")} rows={2}
                  value={customerOrder.note2 && customerOrder.note2 !== null ? customerOrder.note2 : ""}
                />
              </FormControl>
              <FormControl disabled className={myClasses.form100}>
                <TextField
                  disabled variant="outlined" name="note3" multiline label={customLabel("function.odpListNote")} rows={2}
                  value={customerOrder.note3 && customerOrder.note3 !== null ? customerOrder.note3 : ""}
                />
              </FormControl>
            </form>
            <Divider />
            <div className={myClasses.buttonsFormUpdate}>
              <Button style={{ float: "left" }} component={Link} to={"/odps/new/" + customerOrder.id} color="primary" disabled={loading}>
                {customLabel("button.createOdp")}
              </Button>
              <Button focusRipple key={customerOrder.id} onClick={edit} color="primary" disabled={loading}>
                {customLabel("button.edit")}
              </Button>
              <Button color="secondary" onClick={toggleDelete}
                disabled={loading || (customerOrder && customerOrder.odps.length > 0 && customerOrder.odps.some((o) => o.startDate !== null))}>
                {customLabel("button.delete")}
              </Button>
              <Button component={Link} to={`/customerOrders`} disabled={loading}>
                {customLabel("button.back")}
              </Button>
            </div>
          </PaperForm>
          <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
            confirm={doDelete} cancel={toggleDelete} disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <CustomerOrderDetails />
        </Grid>
      </Grid>
    </React.Fragment >
  );
};
