//import _ from 'lodash';
import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';
import { SERVER_NOT_RESPONDING_MESSAGE, SERVER_URL } from "../../constants";
import { setServerNotResponding } from "../main/slice";
import { resetErrors as resetNotificationErrors, showError, showSuccess } from "../notifications/slice";
import * as CONST from "./utility/constants";
import {
    ERRORS_COMPONENTS, ERRORS_MACHINE_EVENT_TYPES, ERRORS_MACHINE_VARS, ERRORS_PROCESS_CHECKS, ERRORS_PROCESS_VARS, ERRORS_STAPLING_CHECKS,
    ERRORS_STAPLING_MACHINE_EVENT, ERRORS_STAPLING_ODP, ERRORS_STAPLING_OEE, ERRORS_STAPLING_USER_EVENT, ERRORS_STAPLING_VARS, ERRORS_USER_EVENT_TYPES,
    LOADING_COMPONENTS, LOADING_MACHINE_EVENT_TYPES, LOADING_MACHINE_VARS, LOADING_PROCESS_CHECKS, LOADING_PROCESS_VARS, LOADING_STAPLING_CHECKS,
    LOADING_STAPLING_MACHINE_EVENT, LOADING_STAPLING_ODP, LOADING_STAPLING_OEE, LOADING_STAPLING_USER_EVENT, LOADING_STAPLING_VARS, LOADING_USER_EVENTS_TYPES
} from "./utility/loadings";
import { getCurrentValue } from "./utility/utility";

const doResetOdp = (state) => {
    state.index = CONST.INDEX_ODP;
    state.currentValue = false;
    state.odp = false;
    state.currentOdp = false;
    state.modeStapling = false;
    state.staplingList = false;
    state.staplingArticles = false;
    state.allProcesses = false;
    state.allProcessesResultAPI = false;
    state.process = false;
    state.components = false;
    state.lotInfo = false;
    state.articleListForced = false;
    state.newProcesses = false;
    state.oldProcesses = false;
    //reset checks
    for (let i = 0; i < CONST.LIST_ALL_CHECKS.length; i++) {
        state[CONST.LIST_ALL_CHECKS[i]] = false;
    }
    state.errors = false;
    state.vars = false;
    state.userEventTypes = false;
    state.machineEventTypes = false;
    state.machineEventTypeReasons = false;
    //per quando si fa cambia commessa
    state.machineEvent = false;
    state.odpsAvailable = false;
    state.machineEventType = false;
    state.machineEventReason = false;
    for (let i = 0; i < CONST.LIST_ALL_INTERACTIONS.length; i++) {
        state[CONST.LIST_ALL_INTERACTIONS[i]] = false;
    }
}

const selectSimpleOdp = (state, payload) => {
    const newOdps = payload.odps.slice();
    const oldOdps = payload.rodps.slice();
    const sOdps = payload.sodps.slice();
    //controlla se la commessa selezionata è al interno di newOdps
    const indexNew = newOdps.findIndex((i) => {
        if (payload.extCode !== undefined && payload.extCode) {
            return i.code.toString() === state.currentValue.toString() ||
                (i.extCode && i.extCode !== "" && i.extCode.toString() === state.currentValue.toString());
        } else {
            return i.code.toString() === state.currentValue.toString();
        }
    });
    if (indexNew !== -1) {
        state.odp = newOdps[indexNew];
        state.index = CONST.INDEX_PROCESS;
        state.currentValue = false;
    }
    //controlla se la commessa selezionata è al interno di oldOdps
    const indexOld = oldOdps.findIndex((i) => {
        if (payload.extCode !== undefined && payload.extCode) {
            return i.code.toString() === state.currentValue.toString() ||
                (i.extCode && i.extCode !== "" && i.extCode.toString() === state.currentValue.toString());
        } else {
            return i.code.toString() === state.currentValue.toString();
        }
    });
    if (indexOld !== -1) {
        state.odp = oldOdps[indexOld];
        state.index = CONST.INDEX_PROCESS;
        state.currentValue = false;
    }
    //controlla se la commessa selezionata è al interno delle pinzature sodps
    //pinzature non serve controllo extCode
    const indexStapling = sOdps.findIndex((i) => {
        return i.code.toString() === state.currentValue.toString();
    });
    if (indexStapling !== -1) {
        state.odp = sOdps[indexStapling];
        state.index = CONST.INDEX_PROCESS;
        state.currentValue = false;
        state.modeStapling = true;
    }
    if (state.odp !== false) {
        state.enterOdpKey = false
    }
}

export const initialState = {
    loading: false,
    errors: false,
    reloading: false,
    loadingDocs: false,
    documentsErrors: false,
    index: 0,
    currentValue: false,
    operator: false,
    modeMaintenance: false, //discriminante per mostrare vista operatore normale o vista manutenzioni
    userEventTypes: false,
    userEvent: false,
    userEventChecked: false,
    userEventType: false, //variabile temporanea a cui assegno l'evento con cui faro la chiamata
    workcentre: false,
    machine: false,
    machinesAvailable: false,
    machineEvent: false,
    machineEventChecked: false,
    machineEventAdded: false, //indica se il machineEvent esiste sulla macchina selezionata e se è stata fatta la chiamata per prenderne i parametri
    machineEventType: false,
    machineEventTypes: false,
    machineEventTypeReasons: false,
    odp: false,
    odpsAvailable: false,
    rodpsAvailable: false,
    sodpsAvailable: false, //commesse pinzatura
    process: false,
    newProcesses: false,
    oldProcesses: false,
    startChecks: false,
    startChecksShow: false,
    closeChecks: false,
    closeChecksShow: false,
    pauseChecks: false,
    pauseChecksShow: false,
    restartChecks: false,
    restartChecksShow: false,
    onDemandChecks: false,
    onDemandChecksShow: false,
    noteChecks: false, //si riferisce al valore delle note del relativo check che vado ad inserire nell'input
    vars: false,
    choosenVar: false,
    causalizeVar: false,
    causalizationObj: false,
    documentsMachine: false,
    documentsMaintenance: false,
    documentsProcessType: false,
    documentsArticle: false,
    documentsProcess: false,
    documentsOdp: false,
    operatorsLogged: [],
    interfaceNeedUpdate: false,
    checkAutostart: false, //viene messa a true quando il process corrente è non avviato o in pausa
    oee: false,
    oee_efficiency: false,
    oee_availability: false,
    oee_quality: false,
    choosenButton: false,
    listWorkcentres: false, //caricato al inizio, non viene mai resettato
    listMachines: false, //caricato al inizio, non viene mai resettato
    rolesSaved: false, //stringa con i ruoli salvati nel local storage da usare per la chiamata degli utenti
    reloadDocMachine: false,
    reloadDocProcess: false,
    reloadDocOdp: false,
    reloadDocProcessType: false,
    reloadDocArticle: false,
    reloadDocMaintenance: false,
    machineVars: false,
    userEvents: false,
    components: false,
    lotList: false,
    currentConsum: false,
    forceConsum: false,
    forceMessage: false,
    errorSettings: false, //viene messe a true quando dati DB e localStorage non coincidono, non viene resettato a false fino a quando non si fa F5
    modeStapling: false, // serve per indicare che la modalità in corso è pinzatura
    staplingList: false,
    allProcesses: false,
    allProcessesResultAPI: false,
    currentOdp: false, //usato per tenere traccia del odp cliccato al interno del dialog index 3
    staplingObjects: false,//oggetti da tener traccia come parent per i documenti
    documentsStapling: false, //tutti i documenti relativi a staplingObjects
    loadingUserEventTypes: false,
    errorsUserEventTypes: false,
    loadingMachineEventTypes: false,
    errorsMachineEventTypes: false,
    loadingMachineVars: false,
    errorsMachineVars: false,
    loadingStaplingChecks: false,
    errorsStaplingChecks: false,
    loadingStaplingVars: false,
    errorsStaplingVars: false,
    loadingStaplingOdp: false,
    errorsStaplingOdp: false,
    loadingProcessChecks: false,
    errorsProcessChecks: false,
    loadingProcessVars: false,
    errorsProcessVars: false,
    loadingComponents: false,
    errorsComponents: false,
    staplingOee: false,
    loadingStaplingOee: false,
    errorsStaplingOee: false,
    loadingStaplingMachineEvent: false,
    errorsStaplingMachineEvent: false,
    loadingStaplingUserEvent: false,
    errorsStaplingUserEvent: false,
    manualInteractionProcess: false,
    manualInteractionEvent: false,
    manualInteractionStapling: false,
    unmountProcess: false, // usato per decidere che tipo di pausa fare
    extChecks: false,
    flagDialog: {
        flagMainChecks: false, //check manutenzione
        flagStart: false, //relativo ai check di avvio
        flagClose: false, //relativo ai check di chiusura
        flagPause: false, //relativo ai check di pausa
        flagRestart: false,//relativo ai check di riprendi
        flagOnDemand: false,//relativo ai check su comando
        flagUserEventType: false, //per dialog inserisci userEvent
        flagMachineEventType: false, //per dialog inserisci machineEvent
        flagMachineEventReason: false, //per dialog giustifica machineEvent
        flagVars: false,//dialog con lista variabile da inserire
        flagInsertVar: false,//dialog con variabile scelta da inserire il valore
        flagButtons: false, //dialog lista bottoni
        flagInsertButton: false, //dialog valore bottone scelta da inserire nell'input, se askValue
        flagChoosePause: false,
        flagStopProcess: false, //conferma di voler fermare la lavorazione
        flagCompConsum: false,
        flagCompWaste: false,
        flagCompSubstitute: false,
        flagExtChecks: false,
        flagInsertCheck: false,
        flagVarCheck: false,
        flagAskConfirmation: false
    },
    //flagDialog: FLAG_ALL_DIALOG
    choosenCheck: false,
    choosenUserEvent: false,
    eventFromStart: false,//evento utente, se true l avviamento viene dopo avvia lavorazione, false da inizia evento
    articleToWaste: false,//usato nella pinzatura per decidere la variabile pezzi scarti quale articolo
    staplingArticles: false,
    componentDetails: false,
    lotRequiredComponents: false,
    machineEventReason: false,
    reverseQuantity: false,
    reverseDialogId: false,
    endDeclaration: false
};
const operatorViewSlice = createSlice({
    name: "operatorView",
    initialState,
    reducers: {
        changeCurrentValue: (state, { payload }) => {
            state.currentValue = payload.value;
            state.endDeclaration = payload.endDeclaration
            if (state.index === CONST.INDEX_ODP) {
                const newOdps = [...state.odpsAvailable];
                const oldOdps = [...state.rodpsAvailable];
                const sOdps = [...state.sodpsAvailable];
                //controlla se la commessa selezionata è al interno di newOdps
                const indexNew = newOdps.findIndex((i) => {
                    if (payload.extCode !== undefined && payload.extCode && i.extCode !== "") {
                        return i.code.toString() === state.currentValue.toString() || i.extCode.toString() === state.currentValue.toString();;
                    } else {
                        return i.code.toString() === state.currentValue.toString();
                    }
                });
                if (indexNew !== -1) {
                    state.currentOdp = newOdps[indexNew];
                }
                //controlla se la commessa selezionata è al interno di oldOdps
                const indexOld = oldOdps.findIndex((i) => {
                    if (payload.extCode !== undefined && payload.extCode && i.extCode !== "") {
                        return i.code.toString() === state.currentValue.toString() || i.extCode.toString() === state.currentValue.toString();
                    } else {
                        return i.code.toString() === state.currentValue.toString();
                    }
                });
                if (indexOld !== -1) {
                    state.currentOdp = oldOdps[indexOld];
                }
                //controlla se la commessa selezionata è al interno delle pinzature sodps
                //pinzature non serve controllo extCode
                const indexStapling = sOdps.findIndex((i) => {
                    return i.code.toString() === state.currentValue.toString();
                });
                if (indexStapling !== -1) {
                    state.currentOdp = sOdps[indexStapling];
                }
                if (indexNew === -1 && indexOld === -1 && indexStapling === -1) {
                    state.currentOdp = false;
                }
            }
        },
        resetCurrentValue: (state) => {
            state.currentValue = false;
            state.endDeclaration = false
        },
        setWorkcentres: (state, { payload }) => {
            let workcentres = [];
            let localWorkcentres = [...payload.localWorkcentres];
            let dbWorkcentres = [...payload.dbWorkcentres];
            localWorkcentres.forEach((localw) => {
                let exist = dbWorkcentres.findIndex((dbw) => {
                    return (dbw.id.toString() === localw.id.toString() && dbw.code === localw.code);
                })
                if (exist !== -1) {
                    workcentres.push(dbWorkcentres[exist])
                } else {
                    state.errorSettings = { type: "centro lavoro", localCode: localw.code };
                }
            })
            state.listWorkcentres = workcentres;
        },
        setMachines: (state, { payload }) => {
            let machines = [];
            let localMachines = [...payload.localMachines];
            let dbMachines = [...payload.dbMachines];
            localMachines.forEach((localm) => {
                let exist = dbMachines.findIndex((dbm) => {
                    return (dbm.id.toString() === localm.id.toString() && dbm.code === localm.code);
                })
                if (exist !== -1) {
                    machines.push(dbMachines[exist])
                } else {
                    state.errorSettings = { type: "macchina", localCode: localm.code };
                }
            })
            state.listMachines = machines;
        },
        setRolesSaved: (state, { payload }) => {
            let tempList = "";
            let localRoles = [...payload.localRoles];
            let dbRoles = [...payload.dbRoles];
            localRoles.forEach((localr) => {
                let exist = dbRoles.findIndex((dbr) => {
                    return (dbr.id.toString() === localr.id.toString() && dbr.authority === localr.authority);
                })
                if (exist !== -1) {
                    if (tempList === "") {
                        tempList = dbRoles[exist].authority;
                    } else {
                        tempList = tempList + "," + dbRoles[exist].authority;
                    }
                } else {
                    state.errorSettings = { type: "ruolo", localCode: localr.authority };
                }
            })
            state.rolesSaved = tempList;
        },
        changeOperator: (state, { payload }) => {
            //reset operatore, per usare la stessa funzione quando si cambia l utente loggato
            state.userEvent = false;
            state.userEventChecked = false;
            state.userEventTypes = false;
            state.userEventType = false;
            state.workcentre = false;
            state.machine = false;
            state.machinesAvailable = false;
            state.machineEvent = false;
            state.machineEventChecked = false;
            state.machineEventAdded = false;
            state.machineEventReason = false;
            state.machineEventType = false;
            state.machineEventTypes = false;
            state.machineEventTypeReasons = false;
            state.odp = false;
            state.currentOdp = false;
            state.modeStapling = false;
            state.staplingList = false;
            state.staplingArticles = false;
            state.allProcesses = false;
            state.allProcessesResultAPI = false;
            state.odpsAvailable = false;
            state.rodpsAvailable = false;
            state.sodpsAvailable = false;
            state.process = false;
            state.lotList = false;
            state.substituteOk = false;
            state.lotInfo = false;
            state.articleListForced = false;
            state.scrapList = false;
            state.substituteList = false;
            state.components = false;
            state.newProcesses = false;
            state.userEventTypes = false;
            state.documentsMachine = false;
            state.documentsMaintenance = false;
            state.documentsProcessType = false;
            state.documentsArticle = false;
            state.documentsProcess = false;
            state.documentsOdp = false;
            state.documentsStapling = false;
            state.staplingObjects = false;
            //reset checks
            for (let i = 0; i < CONST.LIST_ALL_CHECKS.length; i++) {
                state[CONST.LIST_ALL_CHECKS[i]] = false;
            }
            state.vars = false;
            state.machineVars = false;
            state.index = CONST.INDEX_OPERATOR;
            //nuovo operatore
            state.loading = false;
            state.currentValue = false;
            state.operator = payload;
            //controllo se l'utente ha un ruolo manutentore nei suoi ruoli
            const roles = [...payload.roles];
            for (let i = 0; i < roles.length; i++) {
                if (roles[i].maintenance) {
                    state.modeMaintenance = true;
                    i = roles.length;
                } else {
                    state.modeMaintenance = false;
                }
            }
            //qui parte la chiamata per caricare eventuali eventi macchina associati al operatore
            //aggiunta del nuovo operatore alla lista di quelli loggati se non c'è già
            if (state.operatorsLogged.length === 0) {
                state.operatorsLogged.push(payload);
            }
            else {
                const indexOfOperator = state.operatorsLogged.findIndex((i) => {
                    return i.id.toString() === payload.id.toString();
                });
                //se non esiste viene aggiunto
                if (indexOfOperator === -1) {
                    state.operatorsLogged.push(payload);
                }
            }
            //reset interazioni
            for (let i = 0; i < CONST.LIST_ALL_INTERACTIONS.length; i++) {
                state[CONST.LIST_ALL_INTERACTIONS[i]] = false;
            }
        },
        noUserEvent: (state) => {
            state.loading = false;
            state.userEventChecked = true;//cosi non fa piu la chiamata
            if(state.index === CONST.INDEX_OPERATOR||state.index === CONST.INDEX_USER_EVENT){
                let lw = _.cloneDeep(state.listWorkcentres)
                if (lw && lw.length === 1) {
                    state.workcentre = lw[0];
                    state.currentValue = false;
                    //solo macchine del localStorage
                    let localMachines = _.cloneDeep(state.listMachines)
                    let machines = lw[0]["machines"]
                    let finalMachines = [];
                    for (let i = 0; i < machines.length; i++) {
                        for (let j = 0; j < localMachines.length; j++) {
                            if (machines[i].id.toString() === localMachines[j].id.toString()) {
                                finalMachines.push(_.cloneDeep(localMachines[j]))
                            }
                        }
                    }
                    state.index = CONST.INDEX_MACHINE;
                    state.machinesAvailable = finalMachines;
                    if (finalMachines.length === 1) {
                        state.machine = finalMachines[0];
                        state.currentValue = false;
                    }
                } else {
                    state.index = CONST.INDEX_WORKCENTRE;
                }
            }else{
                state.index = CONST.INDEX_OPERATOR;
                state.userEvents = false;
            }
        },
        chooseUserEvent: (state, { payload }) => {
            state.loading = false;
            state.userEventChecked = true;//cosi non fa piu la chiamata
            state.index = CONST.INDEX_USER_EVENT;
            state.currentValue = "";
            state.userEvents = payload;
        },
        fillUserEvents: (state, { payload }) => {
            state.loading = false;
            state.userEvents = payload;
        },
        //qui deve partire la chiamata getUserEventAPI che chiama questo reducer
        changeUserEvent: (state, { payload }) => {
            state.loading = false;
            state.userEventChecked = true;//cosi non fa piu la chiamata
            state.userEvent = payload.userEvent;
            state.userEventType = payload.userEvent.type;
            state.workcentre = payload.userEvent.workcentre;
            //solo macchine del localStorage
            let localMachines = [...state.listMachines]
            let machines = [...state.workcentre["machines"]]
            let finalMachines = [];
            for (let i = 0; i < machines.length; i++) {
                let exist = false;
                for (let j = 0; j < localMachines.length; j++) {
                    if (machines[i].id.toString() === localMachines[j].id.toString()) {
                        exist = true;
                    }
                }
                if (exist) {
                    finalMachines.push(machines[i])
                }
            }
            if (payload.machineEvent !== null) {
                state.machineEvent = payload.machineEvent;
            }
            state.machinesAvailable = [...finalMachines];
            state.machine = payload.userEvent.machine;
        },
        integrateUserEvent: (state, { payload }) => {
            state.loading = false;
            state.process = payload.process;
            state.odp = payload.process.odp;
            if (state.process.status.id === 3 || state.process.status.id === 5) {
                state.checkAutostart = true;
            }
            state.index = CONST.INDEX_INTERFACE;
        },
        resetErrors: (state) => {
            state.errors = false;
        },
        changeWorkcentre: (state) => {
            const indexOfWorkcentre = state.listWorkcentres.findIndex((i) => {
                return i.code.toString().toLowerCase() === state.currentValue.toString().toLowerCase();
            });
            if (indexOfWorkcentre !== -1) {
                state.workcentre = state.listWorkcentres[indexOfWorkcentre];
                //solo macchine del localStorage
                let localMachines = [...state.listMachines]
                let machines = [...state.workcentre["machines"]]
                let finalMachines = [];
                for (let i = 0; i < machines.length; i++) {
                    for (let j = 0; j < localMachines.length; j++) {
                        if (machines[i].id.toString() === localMachines[j].id.toString()) {
                            finalMachines.push(localMachines[j])
                        }
                    }
                }
                state.machinesAvailable = [...finalMachines];
                state.index = CONST.INDEX_MACHINE;
                state.currentValue = false;
            }
            if (state.machinesAvailable.length === 1) {
                state.machine = state.machinesAvailable[0];
                state.currentValue = false;
            }
        },
        changeMachine: (state, { payload }) => {
            const listMachines = payload.machines.slice();
            const indexOfMachine = listMachines.findIndex((i) => {
                return i.code.toString().toLowerCase() === state.currentValue.toString().toLowerCase();
            });
            if (indexOfMachine !== -1) {
                state.machine = listMachines[indexOfMachine];
                state.currentValue = false;
            }
        },
        //qui deve partire la chiamata getMachineEventAPI, che chiamo questo reducer
        changeMachineEvent: (state, { payload }) => {
            state.loading = false;
            if (payload.machineEvent !== null) {
                state.machineEvent = payload.machineEvent;
                state.machineEventType = payload.machineEvent.type;
                state.machineEventTypeReasons = payload.machineEvent.type.reasons;
                state.machineEventChecked = true;//cosi non fa piu la chiamata
                state.odp = payload.machineEvent.odp;
                state.process = payload.machineEvent.process;
                if (state.process.status.id === 3 || state.process.status.id === 5) {
                    state.checkAutostart = true;
                }
            } else if (payload.machineEventStapling !== null) {
                state.machineEvent = payload.machineEventStapling;
                state.machineEventType = payload.machineEventStapling.type;
                state.machineEventTypeReasons = payload.machineEventStapling.type.reasons;
                state.machineEventChecked = true;// cosi non fa piu la chiamata
                state.odp = payload.machineEventStapling.odp;
                state.modeStapling = true;
            } else {
                state.machineEventChecked = true;// cosi non fa piu la chiamata
                state.index = CONST.INDEX_ODP;
            }
        },
        updateStaplingMachineEvent: (state, { payload }) => {
            state.loadingStaplingMachineEvent = false;
            const interval = payload.interval;
            const data = payload.data;
            if (state.index === CONST.INDEX_INTERFACE) {
                if (interval && state.manualInteractionEvent) {
                    state.manualInteractionEvent = false;
                } else {
                    state.machineEvent = data;
                    state.machineEventType = data.type;
                    state.machineEventTypeReasons = data.type.reasons;
                }
            }
        },
        //qui deve partire la chiamata integrateEventAPI se sulla macchina selezionata è in corso un machineEvent e modeStapling false
        integrateMachineEvent: (state, { payload }) => {
            state.loading = false;
            state.machineEventAdded = true;
            if (payload.oee !== undefined && payload.oee.length > 0) {
                state.oee = payload.oee[0];
            } else {
                state.oee = false;
            }
            if (payload.oee_efficiency !== undefined && payload.oee_efficiency.length > 0) {
                state.oee_efficiency = payload.oee_efficiency[0];
            } else {
                state.oee_efficiency = false;
            }
            if (payload.oee_availability !== undefined && payload.oee_availability.length > 0) {
                state.oee_availability = payload.oee_availability[0];
            } else {
                state.oee_availability = false;
            }
            if (payload.oee_quality !== undefined && payload.oee_quality.length > 0) {
                state.oee_quality = payload.oee_quality[0];
            } else {
                state.oee_quality = false;
            }
            //aggiorno il process per avere anche i measuretools e cycletimes
            state.process = payload.process;
            state.index = CONST.INDEX_INTERFACE;
        },
        //qui deve partire la chiamata integrateOdpStaplingAPI se sulla macchina selezionata è in corso un machineEvent e modeStapling true
        integrateOdpStapling: (state, { payload }) => {
            state.loading = false;
            state.odp = payload;
            state.modeStapling = true;
        },
        //qui parte la chiamata per getStaplingListAPI per ricevere le lavorazioni della pinzatura
        integrateStaplingList: (state, { payload }) => {
            state.loading = false;
            state.staplingList = payload;
            let articles = payload.map((p) => p.article);
            articles = _.uniqBy(articles, "id");
            state.staplingArticles = [...articles];
            state.index = CONST.INDEX_INTERFACE;
        },
        //qui deve partire la chiamata getMachineOdpsAPI
        changeOdp: (state, { payload }) => {
            state.enterOdpKey = payload.enter
            state.currentOdp = false;
            if (!payload.stapling) {
                selectSimpleOdp(state, payload)
            } else {
                //STAPLING
                state.modeStapling = true;
                const newOdps = payload.odps.slice();
                //controlla se la commessa selezionata è al interno di newOdps
                const indexNew = newOdps.findIndex((i) => {
                    if (payload.extCode !== undefined && payload.extCode) {
                        return i.code.toString() === state.currentValue.toString() ||
                            (i.extCode && i.extCode !== "" && i.extCode.toString() === state.currentValue.toString());
                    } else {
                        return i.code.toString() === state.currentValue.toString();
                    }
                });
                if (indexNew !== -1) {
                    state.odp = newOdps[indexNew];
                    //state.index = INDEX_PROCESS;
                    state.currentValue = false;
                }
            }
        },
        //qui deve partire la chiamata getOdpProcessesAPI che chiama fillProcesses nel caso di odp normale
        fillProcesses: (state, { payload }) => {
            state.loading = false;
            let oldP = []
            let newP = []
            for (let i = 0; i < payload.length; i++) {
                if (payload[i].endDate !== null && payload[i].repeatable) {
                    oldP.push(payload[i])
                } else {
                    newP.push(payload[i])
                }
            }
            state.newProcesses = [...newP];
            state.oldProcesses = [...oldP];
            if (payload.length === 1) {
                state.process = payload[0];
                state.index = CONST.INDEX_INTERFACE;
                if (state.process.status.id === 3 || state.process.status.id === 5) {
                    state.checkAutostart = true;
                }
                state.currentValue = false;
            }
        },
        changeStaplingProcess: (state, { payload }) => {
            const newP = payload.newP.slice();
            const indexNewP = newP.findIndex((i) => {
                return i.id.toString() === state.currentValue.toString();
            });
            let currentStaplingList = [];
            if (indexNewP !== -1) {
                currentStaplingList.push(newP[indexNewP]);
                state.index = CONST.INDEX_STAPLING;
                state.currentValue = false;
                state.staplingList = [...currentStaplingList];
            }
        },
        changeProcess: (state, { payload }) => {
            const newP = payload.newP.slice();
            const oldP = payload.oldP.slice();
            //controlla il process selezionato si trova all'interno di newProcesses
            const indexNewP = newP.findIndex((i) => {
                return i.id.toString() === state.currentValue.toString();
            });
            if (indexNewP !== -1) {
                state.process = newP[indexNewP];
                state.index = CONST.INDEX_INTERFACE;
                //usato per avviare lavorazioni automatiche se sono da avviare o in pausa
                if (state.process.status.id === 3 || state.process.status.id === 5) {
                    state.checkAutostart = true;
                }
                state.currentValue = false;
            }
            //controlla se il process selezionato si trova all' interno di oldProcesses
            const indexOldP = oldP.findIndex((i) => {
                return i.id.toString() === state.currentValue.toString();
            });
            if (indexOldP !== -1) {
                state.process = oldP[indexOldP];
                state.startChecks = [...state.process.startChecksDef];
                state.closeChecks = [...state.process.closeChecksDef];
                state.pauseChecks = [...state.process.pauseChecksDef];
                state.restartChecks = [...state.process.restartChecksDef];
                state.onDemandChecks = [...state.process.onDemandChecksDef];
                state.startChecksShow = [...state.process.startChecksDef];
                state.closeChecksShow = [...state.process.closeChecksDef];
                state.pauseChecksShow = [...state.process.pauseChecksDef];
                state.restartChecksShow = [...state.process.restartChecksDef];
                state.onDemandChecksShow = [...state.process.onDemandChecksDef];
                state.index = CONST.INDEX_INTERFACE;
                if (state.process.status.id === 3 || state.process.status.id === 5) {
                    state.checkAutostart = true;
                }
                state.currentValue = false;
            }
        },
        changeUserEventType: (state, { payload }) => {
            const listEvents = payload.events.slice();
            const indexOfEvent = listEvents.findIndex((i) => {
                return i.id.toString() === payload.value.toString();
            });
            if (indexOfEvent !== -1) {
                state.userEventType = listEvents[indexOfEvent];
                state.choosenUserEvent = _.cloneDeep(listEvents[indexOfEvent]);

            } else {
                state.userEventType = false;
                state.choosenUserEvent = false;
            }
        },
        changeMachineEventType: (state, { payload }) => {
            const listEvents = payload.events.slice();
            const indexOfEvent = listEvents.findIndex((i) => {
                return i.id.toString() === payload.value.toString();
            });
            if (indexOfEvent !== -1) {
                state.machineEventType = listEvents[indexOfEvent];
            } else {
                state.machineEventType = false;
            }
        },
        changeMachineEventReason: (state, { payload }) => {
            const listEvents = payload.events.slice();
            const indexOfEvent = listEvents.findIndex((i) => {
                return i.reason.id.toString() === payload.value.toString();
            });
            if (indexOfEvent !== -1) {
                state.machineEventReason = listEvents[indexOfEvent].reason;
            } else {
                state.machineEventReason = false;
            }
        },
        fillOdps: (state, { payload }) => {
            state.odpsAvailable = [...payload.odps];
            state.rodpsAvailable = [...payload.rodps];
            state.sodpsAvailable = [...payload.sodps];
            if (payload.odps.length === 1 && state.enterOdpKey) {
                selectSimpleOdp(state, { odps: payload.odps, rodps: payload.rodps, sodps: payload.sodps, stapling: payload.stapling, extCode: payload.extCode })
            }
            state.enterOdpKey = false;
            state.loading = false;
        },
        initiateStapling: (state, { payload }) => {
            state.loading = false;
            let list = [];
            for (let i = 0; i < payload.length; i++) {
                list.push(payload[i])
            }
            state.staplingList = [...list];
            state.index = CONST.INDEX_STAPLING;
        },
        initiateMultipleStapling: (state, { payload }) => {
            state.loading = false;
            state.index = CONST.INDEX_PROCESS;
            state.newProcesses = [...payload];
        },
        fillAllprocesses: (state, { payload }) => {
            state.loading = false;
            state.allProcessesResultAPI = payload
            state.allProcesses = [];
            let allP = [...state.staplingList];
            let myP = []
            let noDuplicateProcesses = [];
            for (let i = 0; i < payload.length; i++) {
                if (!noDuplicateProcesses.some(s => s["id"] === payload[i].id)) {
                    noDuplicateProcesses.push(payload[i]);
                }
            }
            for (let i = 0; i < noDuplicateProcesses.length; i++) {
                //se il process non è già dentro quelli selezionati (staplingList) lo aggiungo
                if (!allP.some(s => s["id"] === noDuplicateProcesses[i].id)) {
                    myP.push(noDuplicateProcesses[i])
                } else {
                    //se il process è già dentro quelli selezionati (staplingList) lo rimuovo
                    const indexP = myP.findIndex((p) => {
                        return p.id.toString() === noDuplicateProcesses[i].id.toString()
                    })
                    if (indexP !== -1) {
                        myP = myP.splice(indexP, 1)
                    }
                }
            }
            state.allProcesses = [...myP];
        },
        changeStaplingList: (state, { payload }) => {
            let currentList = [...state.staplingList];
            let availableList = [...state.allProcesses];
            const indexCurrent = currentList.findIndex((i) => {
                return i.id.toString() === payload.id.toString();
            });
            const indexAll = availableList.findIndex((i) => {
                return i.id.toString() === payload.id.toString();
            });
            //se non è selezionato aggiungo process a quelli selezionati e lo rimuovo da quelli disponibili
            if (indexCurrent === -1 && indexAll !== -1) {
                currentList.push(payload);
                availableList.splice(indexAll, 1)
            } else {
                //se è selezionato rimuovo process da quelli selezionati e lo metto in quelli disponibili
                currentList.splice(indexCurrent, 1);
                availableList.push(payload);
            }
            state.staplingList = currentList;
            state.allProcesses = availableList;
            state.currentValue = false;
            if (state.staplingList.length === 0) {
                doResetOdp(state)
            }
        },
        startStapling: (state) => {
            state.index = CONST.INDEX_INTERFACE;
            state.odp = false;
            state.currentValue = false;
            state.machineVars = false;
        },
        fillDocuments: (state, { payload }) => {
            state.loadingDocs = false;
            let currentDocs = [...payload.data];
            let typeDoc = payload.parentType;
            if (currentDocs && currentDocs.length > 0) {
                for (let i = 0; i < currentDocs.length; i++) {
                    let m = currentDocs[i].mime !== null ? currentDocs[i].mime : currentDocs[i].url.split(".")[currentDocs[i].url.split(".").length - 1]
                    if (m.substring(0, 5) === "image" || m.toLowerCase().match(/.(jpg|jpeg|png|gif)$/i)) {
                        currentDocs[i].format = "image";
                    } else if (m.split('/').pop().toLowerCase() === "pdf") {
                        currentDocs[i].format = "pdf";
                    }
                    else {
                        currentDocs[i].format = "other";
                    }
                    currentDocs[i]["parentId"] = payload.parentId;
                    currentDocs[i]["parentType"] = payload.parentType;
                }
            }
            switch (typeDoc) {
                case CONST.MACHINES:
                    state.documentsMachine = [...currentDocs];
                    break;
                case CONST.MAINTENANCE_TYPES:
                    state.documentsMaintenance = [...currentDocs];
                    break;
                case CONST.PROCESS_TYPES:
                    state.documentsProcessType = [...currentDocs];
                    break;
                case CONST.ARTICLES:
                    state.documentsArticle = [...currentDocs];
                    break;
                case CONST.ODPS:
                    state.documentsOdp = [...currentDocs];
                    break;
                case CONST.PROCESSES:
                    state.documentsProcess = [...currentDocs];
                    break;
                default:
                    break;
            }

        },
        enableLoading: (state) => {
            state.loading = true;
            state.errors = false;
        },
        enableErrors: (state) => {
            state.loading = false;
            state.errors = true;
        },
        enableOdpProgressErrors: (state) => {
            state.loading = false;
            state.errors = true;
            state.interfaceNeedUpdate = true;
            state.choosenUserEvent = false;
        },
        enableStaplingProgressErrors: (state) => {
            state.loading = false;
            state.reloading = false;
            state.errors = true;
            state.interfaceNeedUpdate = true;
        },
        enableMachineEventErrors: (state) => {
            state.loading = false;
            state.errors = true;
            state.machineEventType = false;
            state.machineEventReason = false;
            state.machineEventTypeReasons = false;
            state.flagDialog[CONST.FLAG_MACHINE_EVENT_TYPE] = false;
            state.flagDialog[CONST.FLAG_MACHINE_EVENT_REASON] = false;
            state.interfaceNeedUpdate = true;
        },
        resetUserEvents: (state, { payload }) => {
            state.index = CONST.INDEX_USER_EVENT;
            state.currentValue = "";
            state.modeMaintenance = false;
            state.userEventTypes = false;
            state.userEvent = false;
            state.userEventChecked = false;
            state.userEventType = false;
            state.workcentre = false;
            state.machine = false;
            state.machinesAvailable = false;
            state.machineEvent = false;
            state.machineEventChecked = false;
            state.machineEventAdded = false;
            state.machineEventType = false;
            state.machineEventReason = false;
            state.machineEventTypes = false;
            state.machineEventTypeReasons = false;
            state.odp = false;
            state.currentOdp = false;
            state.modeStapling = false;
            state.staplingList = false;
            state.staplingArticles = false;
            state.allProcesses = false;
            state.allProcessesResultAPI = false;
            state.odpsAvailable = false;
            state.rodpsAvailable = false;
            state.sodpsAvailable = false;
            state.process = false;
            state.lotList = false;
            state.substituteOk = false;
            state.scrapList = false;
            state.substituteList = false;
            state.components = false;
            state.lotInfo = false;
            state.articleListForced = false;
            state.newProcesses = false;
            state.oldProcesses = false;
            state.userEventTypes = false;
            state.documentsMachine = false;
            state.documentsMaintenance = false;
            state.documentsProcessType = false;
            state.documentsArticle = false;
            state.documentsProcess = false;
            state.documentsOdp = false;
            state.documentsStapling = false;
            state.staplingObjects = false;
            //reset checks
            for (let i = 0; i < CONST.LIST_ALL_CHECKS.length; i++) {
                state[CONST.LIST_ALL_CHECKS[i]] = false;
            }
            state.vars = false;
            state.machineVars = false;
            state.checkAutostart = false;
            state.errors = false;
            for (let i = 0; i < CONST.LIST_ALL_INTERACTIONS.length; i++) {
                state[CONST.LIST_ALL_INTERACTIONS[i]] = false;
            }
            //test
            state.listMachines = false;
            state.listWorkcentres = false;
            state.rolesSaved = false;
        },
        resetOperator: (state, { payload }) => {
            //payload.value serve per eliminare l operatore dalla lista di quelli loggati attualmente
            //se true toglie operatore dalla lista dei loggati, se false lascia in memoria e procede con la registrazione di un altro operatore
            if (payload.value) {
                const indexOfOperator = state.operatorsLogged.findIndex((i) => {
                    return i.id.toString() === state.operator.id.toString();
                });
                if (indexOfOperator > -1) {
                    state.operatorsLogged.splice(indexOfOperator, 1);
                }
            }
            state.index = CONST.INDEX_OPERATOR;
            state.currentValue = false;
            state.userEvents = false;
            state.operator = false;
            state.modeMaintenance = false;
            state.userEventTypes = false;
            state.userEvent = false;
            state.userEventChecked = false;
            state.userEventType = false;
            state.workcentre = false;
            state.machine = false;
            state.machinesAvailable = false;
            state.machineEvent = false;
            state.machineEventChecked = false;
            state.machineEventAdded = false;
            state.machineEventType = false;
            state.machineEventReason = false;
            state.machineEventTypes = false;
            state.machineEventTypeReasons = false;
            state.odp = false;
            state.currentOdp = false;
            state.modeStapling = false;
            state.staplingList = false;
            state.staplingArticles = false;
            state.allProcesses = false;
            state.allProcessesResultAPI = false;
            state.odpsAvailable = false;
            state.rodpsAvailable = false;
            state.sodpsAvailable = false;
            state.process = false;
            state.lotList = false;
            state.substituteOk = false;
            state.scrapList = false;
            state.substituteList = false;
            state.components = false;
            state.lotInfo = false;
            state.articleListForced = false;
            state.newProcesses = false;
            state.oldProcesses = false;
            state.userEventTypes = false;
            state.documentsMachine = false;
            state.documentsMaintenance = false;
            state.documentsProcessType = false;
            state.documentsArticle = false;
            state.documentsProcess = false;
            state.documentsOdp = false;
            state.documentsStapling = false;
            state.staplingObjects = false;
            //reset checks
            for (let i = 0; i < CONST.LIST_ALL_CHECKS.length; i++) {
                state[CONST.LIST_ALL_CHECKS[i]] = false;
            }
            state.vars = false;
            state.machineVars = false;
            state.checkAutostart = false;
            state.errors = false;
            for (let i = 0; i < CONST.LIST_ALL_INTERACTIONS.length; i++) {
                state[CONST.LIST_ALL_INTERACTIONS[i]] = false;
            }
            //test
            state.listMachines = false;
            state.listWorkcentres = false;
            state.rolesSaved = false;
        },
        resetWorkcentre: (state) => {
            state.index = CONST.INDEX_WORKCENTRE;
            state.currentValue = false;
            state.userEvent = false;
            state.userEventType = false;
            state.workcentre = false;
            state.machine = false;
            state.machineEventTypes = false;
            state.machineEventTypeReasons = false;
            state.machinesAvailable = false;
            state.machineEvent = false;
            state.machineEventChecked = false;
            state.machineEventType = false;
            state.machineEventReason = false;
            state.odp = false;
            state.currentOdp = false;
            state.modeStapling = false;
            state.staplingList = false;
            state.staplingArticles = false;
            state.allProcesses = false;
            state.allProcessesResultAPI = false;
            state.odpsAvailable = false;
            state.rodpsAvailable = false;
            state.sodpsAvailable = false;
            state.process = false;
            state.components = false;
            state.lotInfo = false;
            state.articleListForced = false;
            state.lotList = false;
            state.substituteOk = false;
            state.scrapList = false;
            state.substituteList = false;
            state.newProcesses = false;
            state.oldProcesses = false;
            //reset checks
            for (let i = 0; i < CONST.LIST_ALL_CHECKS.length; i++) {
                state[CONST.LIST_ALL_CHECKS[i]] = false;
            }
            state.userEventTypes = false;
            state.documentsMachine = false;
            state.documentsMaintenance = false;
            state.documentsProcessType = false;
            state.documentsArticle = false;
            state.documentsProcess = false;
            state.documentsOdp = false;
            state.checkAutostart = false;
            state.errors = false;
            state.vars = false;
            state.machineVars = false;
            state.machineEventAdded = false;
            //test
            state.listMachines = false;
            state.listWorkcentres = false;
            //se esiste un solo workcentre salta l'inserimento
            if (state.listWorkcentres && state.listWorkcentres.length === 1) {
                state.workcentre = state.listWorkcentres[0];
                state.currentValue = false;
                //solo macchine del localStorage
                let localMachines = [...state.listMachines]
                let machines = [...state.workcentre["machines"]]
                let finalMachines = [];
                for (let i = 0; i < machines.length; i++) {
                    let exist = false;
                    for (let j = 0; j < localMachines.length; j++) {
                        if (machines[i].id.toString() === localMachines[j].id.toString()) {
                            exist = true;
                        }
                    }
                    if (exist) {
                        finalMachines.push(machines[i])
                    }
                }
                state.index = CONST.INDEX_MACHINE;
                state.machinesAvailable = [...finalMachines];
                if (state.machinesAvailable.length === 1) {
                    state.machine = state.machinesAvailable[0];
                    state.currentValue = false;
                }
            }
            for (let i = 0; i < CONST.LIST_ALL_INTERACTIONS.length; i++) {
                state[CONST.LIST_ALL_INTERACTIONS[i]] = false;
            }
        },
        resetMachine: (state) => {
            state.index = CONST.INDEX_MACHINE;
            state.currentValue = false;
            state.machine = false;
            state.machineEvent = false;
            state.machineEventChecked = false;
            state.machineEventAdded = false;
            state.machineEventType = false;
            state.machineEventReason = false;
            state.machineEventTypes = false;
            state.machineEventTypeReasons = false;
            state.odp = false;
            state.currentOdp = false;
            state.modeStapling = false;
            state.staplingList = false;
            state.staplingArticles = false;
            state.allProcesses = false;
            state.allProcessesResultAPI = false;
            state.odpsAvailable = false;
            state.rodpsAvailable = false;
            state.sodpsAvailable = false;
            state.process = false;
            state.components = false;
            state.lotInfo = false;
            state.articleListForced = false;
            state.lotList = false;
            state.substituteOk = false;
            state.scrapList = false;
            state.substituteList = false;
            state.newProcesses = false;
            state.oldProcesses = false;
            //reset checks
            for (let i = 0; i < CONST.LIST_ALL_CHECKS.length; i++) {
                state[CONST.LIST_ALL_CHECKS[i]] = false;
            }
            state.errors = false;
        },
        resetOdp: (state) => {
            doResetOdp(state)
        },
        resetStaplingProcess: (state) => {
            state.index = CONST.INDEX_PROCESS;
            state.modeStapling = true;
            state.staplingList = false;
            state.staplingArticles = false;
            state.allProcesses = false;
            state.allProcessesResultAPI = false;
            state.currentValue = false;
            state.process = false;
            state.errors = false;
        },
        resetUserEventType: (state) => {
            state.currentValue = false;
            state.userEventType = false;
            state.choosenUserEvent = false;
            state.flagDialog[CONST.FLAG_USER_EVENT_TYPE] = false;
            state.eventFromStart = false;
        },
        resetMachineEventReason: (state) => {
            state.currentValue = false;
            state.machineEventType = false;
            state.machineEventReason = false;
            state.machineEventReasons = false;
            state.flagDialog[CONST.FLAG_MACHINE_EVENT_TYPE] = false;
            state.flagDialog[CONST.FLAG_MACHINE_EVENT_REASON] = false;
        },
        resetMachineEventType: (state) => {
            state.currentValue = false;
            state.machineEventType = false;
            state.machineEventReason = false;
            state.flagDialog[CONST.FLAG_MACHINE_EVENT_TYPE] = false;
            state.flagDialog[CONST.FLAG_MACHINE_EVENT_REASON] = false;
        },
        updateProcess: (state, { payload }) => {
            state.loading = false;
            state.process = payload;
            state.machineEventTypeReasons = false;
            //id 3-4 --> pausa-chiusa
            if (state.process.status.id === 3 || state.process.status.id === 4) {
                //se lavorazione mulitpla cancella start date una volta messa in pausa
                if (state.process.type.multiple) {
                    state.process.startDate = null;
                }
            }
            state.machineEventReason = false;
            state.choosenUserEvent = false;
        },
        addUserEvent: (state, { payload }) => {
            state.userEvent = payload;
            state.flagDialog[CONST.FLAG_USER_EVENT_TYPE] = false;
            state.loading = false;
            state.choosenUserEvent = false;
            state.eventFromStart = false;
        },
        resetUserEvent: (state) => {
            state.loading = false;
            state.userEvent = false;
            //tolto perché dopo inzia concludi evento utente e cambia postazione non faceva chiamata
            //state.userEventChecked = false;
            state.userEventType = false;
        },
        addMachineEvent: (state, { payload }) => {
            state.loading = false;
            state.machineEventReason = false
            state.flagDialog[CONST.FLAG_MACHINE_EVENT_TYPE] = false;
            state.flagDialog[CONST.FLAG_MACHINE_EVENT_REASON] = false;
            state.machineEvent = payload.machineEvent;
            state.machineEventTypeReasons = payload.machineEvent.type.reasons;
            if (!payload.stapling) {
                state.process = payload.machineEvent.process;
            }
        },
        resetMachineEvent: (state) => {
            state.machineEvent = false;
            state.machineEventType = false;
            state.machineEventReason = false;
            state.machineEventTypeReasons = false;
            state.loading = false;
            state.interfaceNeedUpdate = true;
        },
        enableFlagUserEventType: (state, { payload }) => {
            state.flagDialog[CONST.FLAG_USER_EVENT_TYPE] = true;
            state.eventFromStart = payload;
        },
        enableFlagMachineEventType: (state) => {
            state.flagDialog[CONST.FLAG_MACHINE_EVENT_TYPE] = true;
        },
        enableFlagMachineEventReason: (state) => {
            state.flagDialog[CONST.FLAG_MACHINE_EVENT_REASON] = true;
        },
        enableFlagUserEvents: (state) => {
            if(state.userEvent===false|| (state.userEvents.length>0 && state.userEvents[0].id!==state.userEvent.id)){
                state.flagDialog[CONST.FLAG_USER_EVENTS] = true;
            }
            state.loading = false;
        },
        disableFlagUserEvents: (state) => {
            state.flagDialog[CONST.FLAG_USER_EVENTS] = false;
            state.loading = false;
        },
        enableFlagStartChecks: (state) => {
            state.flagDialog[CONST.FLAG_START] = true;
            state.currentValue = null;
            state.flagDialog[CONST.FLAG_USER_EVENT_TYPE] = false;
            state.eventFromStart = false;
        },
        enableFlagCloseChecks: (state) => {
            state.flagDialog[CONST.FLAG_CLOSE] = true;
            state.currentValue = null;
            //chiudo dialog conferma ferma lavorazione
            state.flagDialog[CONST.FLAG_STOP_PROCESS] = false;
        },
        enableFlagPauseChecks: (state) => {
            state.flagDialog[CONST.FLAG_PAUSE] = true;
            state.currentValue = null;
            //chiudo dialog scegli tipo pausa
            state.flagDialog[CONST.FLAG_CHOOSE_PAUSE] = false;
        },
        enableFlagRestartChecks: (state) => {
            state.flagDialog[CONST.FLAG_RESTART] = true;
            state.currentValue = null;
            state.flagDialog[CONST.FLAG_USER_EVENT_TYPE] = false;
            state.eventFromStart = false;
        },
        disableFlagChecks: (state) => {
            state.flagDialog[CONST.FLAG_START] = false;
            state.flagDialog[CONST.FLAG_CLOSE] = false;
            state.flagDialog[CONST.FLAG_PAUSE] = false;
            state.flagDialog[CONST.FLAG_RESTART] = false;
            state.currentValue = false;
            state.noteChecks = false;
            //per il reload su interfaccia normale e pinzatura
            state.interfaceNeedUpdate = true;
            //metto a false unmountprocess per sicurezza
            state.unmountProcess = false;
            state.choosenUserEvent = false;
            state.articleToWaste = false;
            state.endDeclaration = false;
        },
        disableStaplingFlagChecks: (state) => {
            state.flagDialog[CONST.FLAG_START] = false;
            state.flagDialog[CONST.FLAG_CLOSE] = false;
            state.flagDialog[CONST.FLAG_PAUSE] = false;
            state.flagDialog[CONST.FLAG_RESTART] = false;
            state.currentValue = false;
            state.noteChecks = false;
            //metto a false unmountprocess per sicurezza
            state.unmountProcess = false;
        },
        /**
         * @param {string} payload.type tipo del check
         * @param {object} payload.timestamp false se check normali/bloccanti, true se checkOnDemand non bloccante
         */
        updateChecks: (state, { payload }) => {
            if (payload.type === "onDemand") {
                state.interfaceNeedUpdate = true;
            }
            state.loading = false;
            state.noteChecks = false;
            state.endDeclaration = false
            if (payload.type === "start") {
                state.startChecks.splice(0, 1);
                state.currentValue = getCurrentValue([...state.startChecks], [...state.vars]);
            }
            else if (payload.type === "close") {
                state.closeChecks.splice(0, 1);
                state.currentValue = getCurrentValue([...state.closeChecks], [...state.vars]);
            }
            else if (payload.type === "pause") {
                state.pauseChecks.splice(0, 1);
                state.currentValue = getCurrentValue([...state.pauseChecks], [...state.vars]);
            }
            else if (payload.type === "restart") {
                state.restartChecks.splice(0, 1);
                state.currentValue = getCurrentValue([...state.restartChecks], [...state.vars]);
            }
            //se onDemand, getCurrentValue non serve in quanto il preset è chiamato per ogni variabile
            else if (payload.type === "onDemand" && !payload.timestamp) {
                state.extChecks.splice(0, 1);
                state.currentValue = null;
                state.flagDialog[CONST.FLAG_ON_DEMAND] = false
                state.interfaceNeedUpdate = true;
            }
            //check esterni non bloccanti
            else if (payload.type === "onDemand" && payload.timestamp) {
                let extChecks = [...state.extChecks];
                extChecks.splice(extChecks.findIndex(c => c.timeReceived === payload.timestamp), 1);
                state.flagDialog[CONST.FLAG_INSERT_CHECK] = false;
                if (extChecks && extChecks.length > 0) {
                    state.flagDialog[CONST.FLAG_EXT_CHECKS] = true;
                }
                state.currentValue = false;
                state.choosenCheck = false;
                state.extChecks = extChecks;
            }
        },
        enableExtChecksErrors: (state) => {
            state.loading = false;
            state.errors = true;
            state.flagDialog[CONST.FLAG_INSERT_CHECK] = false;
            state.flagDialog[CONST.FLAG_EXT_CHECKS] = true;
            state.currentValue = false;
            state.choosenCheck = false;
        },
        enableFlagExtChecks: (state) => {
            state.flagDialog[CONST.FLAG_EXT_CHECKS] = true;
        },
        disableFlagExtChecks: (state) => {
            state.flagDialog[CONST.FLAG_EXT_CHECKS] = false;
        },
        enableFlagInsertCheck: (state, { payload }) => {
            state.flagDialog[CONST.FLAG_EXT_CHECKS] = false;
            state.flagDialog[CONST.FLAG_INSERT_CHECK] = true;
            state.choosenCheck = payload;
            state.currentValue = null;
            state.noteChecks = false;
        },
        disableFlagInsertCheck: (state) => {
            state.flagDialog[CONST.FLAG_EXT_CHECKS] = true;
            state.flagDialog[CONST.FLAG_INSERT_CHECK] = false;
            state.choosenCheck = false;
            state.currentValue = false;
            state.articleToWaste = false;
        },
        enableFlagVars: (state) => {
            state.flagDialog[CONST.FLAG_VARS] = true;
        },
        disableFlagVars: (state) => {
            state.flagDialog[CONST.FLAG_VARS] = false;
        },
        enableFlagInsertVar: (state, { payload }) => {
            state.flagDialog[CONST.FLAG_VARS] = false;
            state.flagDialog[CONST.FLAG_INSERT_VAR] = true;
            state.causalizeVar = payload.causalize
            if(state.causalizeVar){
                state.causalizationObj = []
            }
            state.choosenVar = payload.var;
            //prevalorizzo var con ultimo valore, se modificabile
            if (payload.var.value !== null && payload.var.editable) {
                if (payload.var.type === "boolean" && payload.var.value === "true") {
                    state.currentValue = true;
                }
                if (payload.var.type === "boolean" && payload.var.value === "false") {
                    state.currentValue = false;
                }
                if (payload.var.type !== "boolean") {
                    state.currentValue = payload.var.value;
                }
            } else {
                state.currentValue = null;
            }
        },
        disableFlagInsertVar: (state) => {
            state.flagDialog[CONST.FLAG_VARS] = true;
            state.flagDialog[CONST.FLAG_INSERT_VAR] = false;
            state.choosenVar = false;
            state.causalizationObj = false;
            state.currentValue = false;
            state.articleToWaste = false;
        },
        addRowToCausalizationObj: (state) => {
            state.causalizationObj.push({"value":1,"reason":'',"article":false})
        },
        removeRowToCausalizationObj: (state, {payload}) => {
            let obj = _.cloneDeep(state.causalizationObj)
            obj.splice(payload,1)
            state.causalizationObj = obj
        },
        changeCausalizationObj: (state, {payload}) => {
            let obj = _.cloneDeep(state.causalizationObj)
            obj[payload.key][payload.item] = payload.value
            state.causalizationObj = obj
        },
        /**
         * 
         * @param {object} payload.data 
         * @param {boolean} payload.varCheck 
         */
        updateVars: (state, { payload }) => {
            state.loading = false;
            state.flagDialog[CONST.FLAG_INSERT_VAR] = false;
            if (!payload.varCheck) {
                state.flagDialog[CONST.FLAG_VARS] = true;
                state.currentValue = false;
            }
            state.choosenVar = false;
            state.causalizationObj = false;
            const idToChange = payload.data.id;
            const indexOfVar = state.vars.findIndex((v) => {
                return v.id.toString() === idToChange.toString();
            });
            if (indexOfVar !== -1) {
                payload.data["value"] = payload.data.type === "number" ? Number(payload.data.value) : payload.data.value
                state.vars[indexOfVar] = payload.data;
            }
            else {
                state.vars.push(payload.data);
            }
            if (!payload.varCheck) {
                state.interfaceNeedUpdate = true;
            }
            state.articleToWaste = false;
        },
        updateLiveProcess: (state, { payload }) => {
            state.reloading = false;
            state.interfaceNeedUpdate = false;
            const interval = payload.interval;
            const data = payload.data;
            if (state.index === CONST.INDEX_INTERFACE) {
                //se la chiamata è partita prima di un interazione con l'interfaccia non aggiorno il process
                if (interval && state.manualInteractionProcess) {
                    state.manualInteractionProcess = false;
                } else {
                    state.process = data.process;
                    if (data.oee !== undefined && data.oee.length > 0) {
                        state.oee = data.oee[0]
                    }
                    if (data.oee_efficiency !== undefined && data.oee_efficiency.length > 0) {
                        state.oee_efficiency = data.oee_efficiency[0]
                    }
                    if (data.oee_quality !== undefined && data.oee_quality.length > 0) {
                        state.oee_quality = data.oee_quality[0]
                    }
                    if (data.oee_availability !== undefined && data.oee_availability.length > 0) {
                        state.oee_availability = data.oee_availability[0]
                    }
                }
            }
        },
        updateLiveUserEvent: (state, { payload }) => {
            if (state.index === CONST.INDEX_INTERFACE) {
                state.userEvent = payload.userEvent;
                state.userEventType = payload.userEvent.type;
                if (!state.modeStapling) {
                    state.odp = payload.userEvent.odp;
                    state.userEventChecked = true;//cosi non fa piu la chiamata, VERIFICARE SE SERVE
                }
                //al momento tolto in quanto poi parte in automatico la lavorazione
                /*if (state.process.status.id === 3 || state.process.status.id === 5) {
                    state.checkAutostart = true;
                }*/
            }
        },
        updateLiveMachineEvent: (state, { payload }) => {
            if (state.index === CONST.INDEX_INTERFACE) {
                const data = payload.data;
                const interval = payload.interval;
                //se la chiamata è partita prima di un interazione con l'evento macchina non aggiorno l'evento macchina
                if (interval && state.manualInteractionEvent) {
                    state.manualInteractionEvent = false;
                } else {
                    state.machineEvent = data.machineEvent;
                    state.machineEventType = data.machineEvent.type;
                    state.machineEventTypeReasons = data.machineEvent.type.reasons;
                    state.odp = data.machineEvent.odp;
                    if (data.oee !== undefined && data.oee.length > 0) {
                        state.oee = data.oee[0]
                    } else {
                        state.oee = false
                    }
                    if (data.oee_efficiency !== undefined && data.oee_efficiency.length > 0) {
                        state.oee_efficiency = data.oee_efficiency[0]
                    } else {
                        state.oee_efficiency = false
                    }
                    if (data.oee_availability !== undefined && data.oee_availability.length > 0) {
                        state.oee_availability = data.oee_availability[0]
                    } else {
                        state.oee_availability = false
                    }
                    if (data.oee_quality !== undefined && data.oee_quality.length > 0) {
                        state.oee_quality = data.oee_quality[0]
                    } else {
                        state.oee_quality = false
                    }
                }
            }
        },
        //non mette userEventChecked a false per non fare la chiamata su cambia postazione
        resetUserEventUpdate: (state) => {
            state.userEvent = false;
            state.userEventType = false;
        },
        resetMachineEventUpdate: (state, { payload }) => {
            if (payload.stapling) {
                state.loadingStaplingMachineEvent = false;
            }
            //non controllo l'index in quanto già mette le variabili a false
            if (payload.interval && state.manualInteractionEvent) {
                state.manualInteractionEvent = false;
            } else {
                state.machineEventTypeReasons = false;
                state.machineEventType = false;
                state.machineEventReason = false;
                state.machineEvent = false;
            }
        },
        disableCheckAutostart: (state) => {
            state.checkAutostart = false;
        },
        fillUserEventTypes: (state, { payload }) => {
            state.loadingUserEventTypes = false;
            state.loading = false;
            if (!state.userEventTypes) {
                state.userEventTypes = [...payload];
            } else {
                let currentEvents = [...state.userEventTypes];
                let newEvents = [...payload];
                newEvents.forEach((e) => {
                    const indexEvent = currentEvents.findIndex((currentE) => {
                        return e.id.toString() === currentE.id.toString();
                    })
                    if (indexEvent === -1) {
                        currentEvents.push(e);
                    }
                })
                state.userEventTypes = currentEvents;
            }
        },
        enableReloading: (state) => {
            state.reloading = true;
            state.errors = false;
        },
        disableReloading: (state) => {
            state.reloading = false;
        },
        enableDocumentsLoading: (state) => {
            state.loadingDocs = true;
            state.documentsErrors = false;
        },
        enableDocumentsErrors: (state) => {
            state.loadingDocs = false;
            state.documentsErrors = true;
        },
        enableFlagButtons: (state) => {
            state.flagDialog[CONST.FLAG_BUTTONS] = true;
        },
        disableFlagButtons: (state) => {
            state.flagDialog[CONST.FLAG_BUTTONS] = false;
        },
        disableLoading: (state) => {
            state.loading = false;
        },
        /**
         * 
         * @param {boolean} payload.varCheck 
         */
        enableVarsErrors: (state, { payload }) => {
            state.loading = false;
            state.errors = true;
            state.flagDialog[CONST.FLAG_INSERT_VAR] = false;
            if (!payload.varCheck) {
                state.flagDialog[CONST.FLAG_VARS] = true;
            }
            state.currentValue = false;
            state.choosenVar = false;
            state.causalizationObj = false;
            state.articleToWaste = false;
        },
        enableChecksErrors: (state) => {
            state.loading = false;
            state.errors = true;
            state.flagDialog[CONST.FLAG_START] = false;
            state.flagDialog[CONST.FLAG_CLOSE] = false;
            state.flagDialog[CONST.FLAG_PAUSE] = false;
            state.flagDialog[CONST.FLAG_RESTART] = false;
            state.flagDialog[CONST.FLAG_ON_DEMAND] = false;
            state.currentValue = null;
            state.noteChecks = false;
            state.interfaceNeedUpdate = true;
            //metto a false unmountprocess per sicurezza
            state.unmountProcess = false;
            state.choosenUserEvent = false;
        },
        fillMachineEventTypes: (state, { payload }) => {
            state.loadingMachineEventTypes = false;
            state.machineEventTypes = payload;
        },
        disableInterfaceNeedUpdate: (state) => {
            state.interfaceNeedUpdate = false;
            state.loading = false;
        },
        changeNoteChecks: (state, { payload }) => {
            state.noteChecks = payload.value;
        },
        enableReloadingOdpErrors: (state) => {
            state.reloading = false;
            //state.processErrors = true;
            state.errors = true;
            state.interfaceNeedUpdate = false;
        },
        enableFlagInsertButton: (state, { payload }) => {
            state.flagDialog[CONST.FLAG_BUTTONS] = false;
            state.flagDialog[CONST.FLAG_INSERT_BUTTON] = true;
            state.choosenButton = payload;
            state.currentValue = false;
        },
        disableFlagInsertButton: (state) => {
            state.flagDialog[CONST.FLAG_INSERT_BUTTON] = false;
            state.flagDialog[CONST.FLAG_BUTTONS] = true;
            state.choosenButton = false;
            state.currentValue = false;
        },
        enableFlagAskConfirmation: (state, {payload}) => {
            state.flagDialog[CONST.FLAG_BUTTONS] = false;
            state.flagDialog[CONST.FLAG_ASK_CONFIRMATION] = true;
            state.choosenButton = payload;
            state.currentValue = false;
        },
        disableFlagAskConfirmation: (state) => {
            state.flagDialog[CONST.FLAG_ASK_CONFIRMATION] = false;
            state.flagDialog[CONST.FLAG_BUTTONS] = true;
            state.choosenButton = false;
            state.currentValue = false;
        },
        runButtonSuccess: (state) => {
            state.loading = false;
            state.flagDialog[CONST.FLAG_INSERT_BUTTON] = false;
            state.flagDialog[CONST.FLAG_BUTTONS] = true;
            state.flagDialog[CONST.FLAG_ASK_CONFIRMATION] = false;
            state.currentValue = false;
            state.choosenButton = false;
        },
        runButtonError: (state) => {
            state.loading = false;
            state.errors = true;
            state.flagDialog[CONST.FLAG_INSERT_BUTTON] = false;
            state.flagDialog[CONST.FLAG_ASK_CONFIRMATION] = false;
            state.flagDialog[CONST.FLAG_BUTTONS] = true;
            state.currentValue = false;
            state.choosenButton = false;
        },
        enableReloadDocs: (state, { payload }) => {
            let typeDoc = payload;
            switch (typeDoc) {
                case CONST.MACHINES:
                    state.reloadDocMachine = true;
                    break;
                case CONST.MAINTENANCE_TYPES:
                    state.reloadDocMaintenance = true;
                    break;
                case CONST.PROCESS_TYPES:
                    state.reloadDocProcessType = true;
                    break;
                case CONST.ARTICLES:
                    state.reloadDocArticle = true;
                    break;
                case CONST.ODPS:
                    state.reloadDocOdp = true;
                    break;
                case CONST.PROCESSES:
                    state.reloadDocProcess = true;
                    break;
                default:
                    break;
            }
        },
        disableReloadDocs: (state, { payload }) => {
            let typeDoc = payload;
            switch (typeDoc) {
                case CONST.MACHINES:
                    state.reloadDocMachine = false;
                    break;
                case CONST.MAINTENANCE_TYPES:
                    state.reloadDocMaintenance = false;
                    break;
                case CONST.PROCESS_TYPES:
                    state.reloadDocProcessType = false;
                    break;
                case CONST.ARTICLES:
                    state.reloadDocArticle = false;
                    break;
                case CONST.ODPS:
                    state.reloadDocOdp = false;
                    break;
                case CONST.PROCESSES:
                    state.reloadDocProcess = false;
                    break;
                default:
                    break;
            }
        },
        fillComponents: (state, { payload }) => {
            state.reloading = false; //serve perche usato come loading api
            state.loading = false; //serve perche chiamato su save e update
            if (state.index === CONST.INDEX_INTERFACE) {
                state.components = payload.data;
                state.lotRequiredComponents = payload.data.filter(c => c.lotRequired)
            }
        },
        getLotListSuccess: (state, { payload }) => {
            state.lotList = JSON.parse(payload.r);
            state.reloading = false; //serve perche usato come loading api
            state.loading = false; //serve perche chiamato su save e update
        },
        getLotInfoSuccess: (state, { payload }) => {
            if(payload.articleForced){
                if(!state.articleListForced){
                    state.articleListForced = {}
                }
                state.articleListForced = payload.data
                if (payload.data.length === 1) {
                    state.currentConsum["substitute"] = payload.data[0].itemno
                    state.currentConsum["newlotqty"] = payload.data[0].quantity
                    state.currentConsum["newComponentCode"] = payload.data[0].itemno
                    state.currentConsum["description"] = payload.data[0].description
                    state.substituteOk = true
                }
            }else{
                if(!state.lotInfo){
                    state.lotInfo = {}
                }
                if (payload.data.length > 0) {
                    state.lotInfo[payload.lot] = payload.data[0].quantity
                }
            }
            state.reloading = false; //serve perche usato come loading api
            state.loading = false; //serve perche chiamato su save e update
        },
        lotInfoError: (state) => {
            state.articleListForced = false
        },
        getScrapListSuccess: (state, { payload }) => {
            state.scrapList = JSON.parse(payload.r);
            state.reloading = false; //serve perche usato come loading api
            state.loading = false; //serve perche chiamato su save e update
        },
        getSubstituteListSuccess: (state, { payload }) => {
            state.substituteList = JSON.parse(payload.r);
            state.reloading = false; //serve perche usato come loading api
            state.loading = false; //serve perche chiamato su save e update
        },
        /**
         * 
         * @param {int} compId
         * @param {array} components
         * @param {int} decimals 
         * @param {boolean} payload.waste se false apre dialogo consumo se true apre dialog scarto
         */
        setNewConsum: (state, { payload }) => {
            const reverseQuantity = payload.reverseQuantity !== undefined ? - payload.reverseQuantity : null
            state.reverseDialogId = state.componentDetails
            state.reverseQuantity = reverseQuantity
            const autoComponentConsumption = payload.autoComponentConsumption
            const lotToReverse = payload.lotToReverse !== undefined ? payload.lotToReverse : null
            const scrapReason = payload.scrapReason !== undefined ? payload.scrapReason : null
            const components = payload.components.slice();
            const consumedQuantity = payload.consumedQuantity
            const indexOfComp = components.findIndex((c) => {
                return c.id.toString() === payload.compId.toString();
            });
            if (indexOfComp !== -1) {
                let component = components[indexOfComp];
                let insertedQuantity = 0;
                for (let x in component.consumptions) {
                    insertedQuantity = insertedQuantity + component.consumptions[x].quantity
                }
                let remainingQuantity = component.expectedQuantity - insertedQuantity;
                let newConsum;
                if (!payload.waste && !payload.substitute) {
                    let q = (payload.totalPieces !== 0 && component.consumptionFactor !== null) || payload.useAlwaysFactor ? (component.consumptionFactor * payload.totalPieces) - insertedQuantity : remainingQuantity.toFixed(payload.decimals)
                    let consumed = component.consumptions && component.consumptions.length > 0
                    let currentLot = consumed ? component.consumptions[0]?.lot : ""
                    let lotqty = consumed ? component.consumptions[0]?.lotqty : null
                    newConsum = {
                        componentCode: component.code,
                        substitute: '',
                        expectedLot: component.expectedLot, expectedQuantity: component.expectedQuantity !== null ? component.expectedQuantity.toFixed(payload.decimals) : 0,
                        componentId: component.id, close: autoComponentConsumption ? false : true,
                        id: "new", quantity: autoComponentConsumption ? 0 : reverseQuantity !== null ? reverseQuantity : q,
                        lot: payload.useAlwaysFactor && consumedQuantity === 0 ? currentLot : lotToReverse ? lotToReverse : "", lotqty: payload.useAlwaysFactor && consumedQuantity === 0 ? lotqty : null, notes: "",
                        startDate: null, endDate: null, lotRequired: component.lotRequired, consumedQuantity: consumedQuantity, consumptionFactor: component.consumptionFactor, description: ""
                    }
                    state.substituteOk = false
                } else if (payload.waste) {
                    newConsum = {
                        componentCode: component.code, lot: lotToReverse ? lotToReverse : component.consumptions && component.consumptions.length > 0 ? component.consumptions[0]?.lot : "",
                        quantity: reverseQuantity !== null ? reverseQuantity : "1", componentId: component.id, consumptionFactor: component.consumptionFactor, scrapReason: scrapReason ? scrapReason : null
                    }
                } else {
                    newConsum = { componentCode: component.code, componentId: component.id, substitute: '' }
                }
                newConsum.quantity = parseFloat(newConsum.quantity)
                state.currentConsum = newConsum;
                if (!payload.waste && !payload.substitute) {
                    state.flagDialog[CONST.FLAG_COMP_CONSUM] = true;
                    if (reverseQuantity) {
                        state.componentDetails = null
                    }
                } else if (payload.waste) {
                    state.flagDialog[CONST.FLAG_COMP_WASTE] = true;
                    if (reverseQuantity) {
                        state.componentDetails = null
                    }
                } else {
                    state.flagDialog[CONST.FLAG_COMP_SUB] = true;
                }
            }
        },
        setForceConsumMessage: (state) => {
            state.forceConsum = true
            state.loading = false
        },
        setEditConsum: (state, { payload }) => {
            const autoComponentConsumption = payload.autoComponentConsumption
            const components = payload.components.slice();
            const indexOfComp = components.findIndex((c) => {
                return c.id.toString() === payload.compId.toString();
            });
            if (indexOfComp !== -1) {
                let component = components[indexOfComp];
                const indexOfConsum = component.consumptions.findIndex((c) => {
                    return c.id.toString() === payload.consumId.toString();
                });
                if (indexOfConsum !== -1) {
                    let consum = component.consumptions[indexOfConsum];
                    let newConsum = {
                        componentCode: component.code,
                        expectedLot: component.expectedLot, expectedQuantity: component.expectedQuantity,
                        componentId: component.id, close: autoComponentConsumption ? false : true, lotqty: null,
                        id: consum.id, quantity: consum.quantity, lot: consum.lot, notes: consum.notes,
                        startDate: consum.startDate, endDate: consum.endDate, lotRequired: component.lotRequired,
                    }
                    /*   if (consum.endDate !== null) {
                          newConsum["close"] = true;
                      } */
                    state.currentConsum = newConsum;
                    state.flagDialog[CONST.FLAG_COMP_CONSUM] = true;
                }
            }
        },
        changeCurrentConsum: (state, { payload }) => {
            if (payload.name === "lot" && state.forceConsum) {
                state.forceConsum = false;
            }
            let temp = state.currentConsum;
            temp[payload.name] = payload.value;
            if(payload.name==="substitute"){
                let i = state.articleListForced.findIndex(v => v.itemno === payload.value)
                let temp = state.currentConsum;
                temp.newComponentCode = payload.value
                temp.newComponentCode = payload.value
                if (i <0) {
                    state.substituteOk = false
                }else{
                    temp.newlotqty = state.articleListForced[i].quantity
                    temp.description = state.articleListForced[i].description
                    state.substituteOk = true
                }
            }
            if (payload.name === "lot") {
                let insertedLot = state.lotList.find(lot => lot.lot === payload.value)
                temp.lotqty = insertedLot !== undefined ? insertedLot.qty : null
            }
            state.currentConsum = temp
        },
        changeCurrentConsumByEnter: (state, { payload }) => {
            let i = state.lotList.findIndex(v => v.lot === payload)
            let temp = state.currentConsum;
            temp.lot = payload
            if (i > -1) {
                temp.lotqty = state.lotList[i].qty
                temp.description = state.lotList[i].description
            }
            state.currentConsum = temp;
        },
        changeCurrentCodeByEnter: (state, { payload }) => {
            let i = state.articleListForced.findIndex(v => v.itemno === payload)
            let temp = state.currentConsum;
            temp.newComponentCode = payload
            if (i <0) {
                state.substituteOk = false
            }else{
                state.substituteOk = true
                temp.newlotqty = state.articleListForced[i].quantity
                temp.description = state.articleListForced[i].description
                state.currentConsum = temp;
            }
        },
        unsetCurrentConsum: (state) => {
            state.currentConsum = false;
            state.forceConsum = false;
            state.flagDialog[CONST.FLAG_COMP_CONSUM] = false;
            state.flagDialog[CONST.FLAG_COMP_WASTE] = false;
            state.flagDialog[CONST.FLAG_COMP_SUB] = false;
            if(state.reverseQuantity && state.reverseDialogId){
                state.componentDetails = state.reverseDialogId
                state.reverseDialogId = false
                state.reverseQuantity = false
            }
            state.lotList = false;
            state.substituteOk = false;
            state.scrapList = false;
            state.substituteList = false;
            state.errors = false;
        },
        fillStaplingComponents: (state, { payload }) => {
            state.loadingComponents = false;
            state.loading = false; //serve perche chiamato su save e update
            if (state.index === CONST.INDEX_INTERFACE) {
                state.components = payload;
            }
        },
        enableFlagStopProcess: (state) => {
            state.flagDialog[CONST.FLAG_STOP_PROCESS] = true;
        },
        disableFlagStopProcess: (state) => {
            state.flagDialog[CONST.FLAG_STOP_PROCESS] = false;
        },
        fillProcessChecks: (state, { payload }) => {
            state.loadingProcessChecks = false;
            let checksData = payload
            if (state.index === CONST.INDEX_INTERFACE) {
                Object.keys(checksData).forEach(checkType => {
                    checksData[checkType] = checksData[checkType].filter(check => (check.type === "component" && state.components.length > 0) || check.type !== "component");
                });
                state.startChecks = checksData.startChecks;
                state.pauseChecks = checksData.pauseChecks;
                state.closeChecks = checksData.closeChecks;
                state.restartChecks = checksData.restartChecks;
                state.onDemandChecks = checksData.onDemandChecks;
                state.startChecksShow = [...checksData.startChecks];
                state.pauseChecksShow = [...checksData.pauseChecks];
                state.closeChecksShow = [...checksData.closeChecks];
                state.restartChecksShow = [...checksData.restartChecks];
                state.onDemandChecksShow = [...checksData.onDemandChecks];
            }
        },
        resetDocuments: (state) => {
            state.documentsMachine = false;
            state.documentsMaintenance = false;
            state.documentsProcessType = false;
            state.documentsArticle = false;
            state.documentsProcess = false;
            state.documentsOdp = false;
            //aggiunto per pinzatura
            state.documentsStapling = false;
        },
        /**
         * 
         * @param {*} state 
         * @param {object} payload devo passare due parametri: odp per aggiornare state e reload per aggiornare dati, false sul create
         */
        updateStaplingOdp: (state, { payload }) => {
            state.loading = false;
            state.odp = payload.odp;
            if (payload.reload) {
                state.interfaceNeedUpdate = true;
            }
        },
        updateLiveStaplingOdp: (state, { payload }) => {
            state.interfaceNeedUpdate = false;
            state.loadingStaplingOdp = false;
            let staplingList = _.cloneDeep(state.staplingList)
            let staplingPieces = payload.data.staplingProcesses.map(p => ({ id: p.id, totalPieces: p.totalPieces, validPieces: p.validPieces, rejectedPieces: p.rejectedPieces }))
            staplingPieces.forEach(x => {
                let index = staplingList.findIndex(v => v.id === x.id)
                if (index !== -1) {
                    staplingList[index].totalPieces = x.totalPieces
                    staplingList[index].validPieces = x.validPieces
                    staplingList[index].rejectedPieces = x.rejectedPieces
                }
            });
            state.staplingList = staplingList
            if (state.index === CONST.INDEX_INTERFACE) {
                const data = payload.data;
                const interval = payload.interval;
                //se chiamata partita prima di un interazione con la pinzatura non l'aggiorno in quanto dati obsoleti
                if (interval && state.manualInteractionStapling) {
                    state.manualInteractionStapling = false;
                } else {
                    state.odp = data;
                }
            }
        },
        fillStaplingVars: (state, { payload }) => {
            state.loadingStaplingVars = false;
            if (state.index === CONST.INDEX_INTERFACE) {
                payload.forEach(v => {
                    if (v.type === "number") {
                        v.value = Number(v.value)
                    }
                })
                state.vars = payload;
            }
        },
        fillProcessVars: (state, { payload }) => {
            state.loadingProcessVars = false;
            if (state.index === CONST.INDEX_INTERFACE) {
                payload.forEach(v => {
                    if (v.type === "number") {
                        v.value = Number(v.value)
                    }
                })
                state.vars = payload;
            }
        },
        fillMachineVars: (state, { payload }) => {
            state.loadingMachineVars = false;
            if (state.index === CONST.INDEX_INTERFACE) {
                state.machineVars = payload;
            }
        },
        fillStaplingChecks: (state, { payload }) => {
            state.loadingStaplingChecks = false;
            let checksData = payload
            if (state.index === CONST.INDEX_INTERFACE) {
                Object.keys(checksData).forEach(checkType => {
                    checksData[checkType] = checksData[checkType].filter(check => (check.type === "component" && state.components.length > 0) || check.type !== "component");
                });
                state.startChecks = checksData.startChecks;
                state.pauseChecks = checksData.pauseChecks;
                state.closeChecks = checksData.closeChecks;
                state.restartChecks = checksData.restartChecks;
                state.onDemandChecks = checksData.onDemandChecks;
                state.startChecksShow = [...checksData.startChecks];
                state.pauseChecksShow = [...checksData.pauseChecks];
                state.closeChecksShow = [...checksData.closeChecks];
                state.restartChecksShow = [...checksData.restartChecks];
                state.onDemandChecksShow = [...checksData.onDemandChecks];
            }
        },
        fillStaplingOee: (state, { payload }) => {
            state.loadingStaplingOee = false;
            if (state.index === CONST.INDEX_INTERFACE) {
                if (payload.oee !== undefined && payload.oee.length > 0) {
                    state.oee = payload.oee[0]
                }
                if (payload.oee_efficiency !== undefined && payload.oee_efficiency.length > 0) {
                    state.oee_efficiency = payload.oee_efficiency[0]
                }
                if (payload.oee_quality !== undefined && payload.oee_quality.length > 0) {
                    state.oee_quality = payload.oee_quality[0]
                }
                if (payload.oee_availability !== undefined && payload.oee_availability.length > 0) {
                    state.oee_availability = payload.oee_availability[0]
                }
                state.staplingOee = payload;
            }
        },
        createStaplingObjects: (state) => {
            let documents = [];
            let processes = [...state.staplingList];
            processes.forEach((p) => {
                let entryOdp = { id: p.odp.id, type: CONST.ODPS, docs: false };
                documents.push(entryOdp);
                let entryProcess = { id: p.id, type: CONST.PROCESSES, docs: false };
                documents.push(entryProcess);
                let entryArticle = { id: p.article.id, type: CONST.ARTICLES, docs: false };
                const indexArticle = documents.findIndex((d) => {
                    return d.id === entryArticle.id && d.type === entryArticle.type;
                })
                if (indexArticle === -1) {
                    documents.push(entryArticle);
                }
                let entryProcessType = { id: p.type.id, type: CONST.PROCESS_TYPES, docs: false };
                const indexProcessType = documents.findIndex((d) => {
                    return d.id === entryProcessType.id && d.type === entryProcessType.type;
                })
                if (indexProcessType === -1) {
                    documents.push(entryProcessType);
                }
            })
            let articles = processes.map((p) => p.article);
            articles = _.uniqBy(articles, "id");
            state.staplingArticles = [...articles];
            state.staplingObjects = [...documents];
        },
        fillStaplingDocuments: (state, { payload }) => {
            state.loadingDocs = false;
            let type = payload.type;
            let id = payload.id;
            let docs = [...payload.data];
            let currentDocs;
            if (state.documentsStapling !== false) {
                currentDocs = [...state.documentsStapling];
            } else {
                currentDocs = [];
            }
            if (docs && docs.length > 0) {
                for (let i = 0; i < docs.length; i++) {
                    if (docs[i].mime.substring(0, 5) === "image") {
                        docs[i].format = "image";
                    } else if (docs[i].mime.split('/').pop() === "pdf") {
                        docs[i].format = "pdf";
                    }
                    else {
                        docs[i].format = "other";
                    }
                    docs[i]["parentId"] = id;
                    docs[i]["parentType"] = type;
                    currentDocs.push(docs[i]);
                }
            }
            state.documentsStapling = [...currentDocs];

            let currentObjects = [...state.staplingObjects];
            for (let i = 0; i < currentObjects.length; i++) {
                if (currentObjects[i]["type"] === type && currentObjects[i]["id"] === id) {
                    currentObjects[i]["docs"] = true;
                }
            }
            state.staplingObjects = [...currentObjects];
        },
        enableCustomLoading: (state, { payload }) => {
            state[payload.loading] = true;
            state[payload.errors] = false;
        },
        disableCustomLoading: (state, { payload }) => {
            state[payload.loading] = false;
        },
        enableCustomErrors: (state, { payload }) => {
            state[payload.loading] = false;
            state[payload.errors] = true;
            if (payload.loading === LOADING_PROCESS_CHECKS) {
                state.startChecks = [];
                state.pauseChecks = [];
                state.closeChecks = [];
                state.restartChecks = [];
                state.onDemandChecks = [];
                state.startChecksShow = [];
                state.pauseChecksShow = [];
                state.closeChecksShow = [];
                state.restartChecksShow = [];
                state.onDemandChecksShow = [];
            }
        },
        /**
         * 
         * @param {string} payload.name 
         * @param {boolean} payload.value
         */
        changeManualInteraction: (state, { payload }) => {
            state[payload.name] = payload.value;
        },
        /**
         * 
         * @param {boolean} payload 
         */
        setUnmountProcess: (state, { payload }) => {
            state.unmountProcess = payload;
        },
        enableFlagChoosePause: (state) => {
            state.flagDialog[CONST.FLAG_CHOOSE_PAUSE] = true;
            state.unmountProcess = null;
        },
        disableFlagChoosePause: (state) => {
            state.flagDialog[CONST.FLAG_CHOOSE_PAUSE] = false;
        },
        addExtCheck: (state, { payload }) => {
            const onDemandChecks = [...state.onDemandChecks];
            let tempChecks;
            const checkName = payload.name;
            const timestamp = payload.timestamp;
            if (state.extChecks) {
                tempChecks = [...state.extChecks];
            } else {
                tempChecks = [];
            }
            const checkIndex = onDemandChecks.findIndex((c) => {
                return c.name.toString() === checkName.toString();
            })
            if (checkIndex !== -1) {
                let checkToAdd = onDemandChecks[checkIndex];
                checkToAdd.timeReceived = timestamp;
                if ((checkToAdd.type === "component" && state.components.length > 0) || checkToAdd.type !== "component") {
                    tempChecks.push(checkToAdd);
                }
            }
            state.extChecks = tempChecks;
        },
        enableFlagOnDemand: (state) => {
            state.flagDialog[CONST.FLAG_ON_DEMAND] = true;
            state.currentValue = null;
        },
        setArticleToWaste: (state, { payload }) => {
            if (payload.value && payload.articles && payload.articles.length > 0) {
                let indexArticle = payload.articles.findIndex((a) => {
                    return a.id.toString() === payload.value.toString()
                })
                if (indexArticle !== -1) {
                    state.articleToWaste = payload.articles[indexArticle];
                }
            } else if(payload.articles.length===1){
                state.articleToWaste = payload.articles[0]
            } else {
                state.articleToWaste = false;
            }
        },
        /**
         * 
         * @param {object} payload.var 
         */
        presetVarCurrentValue: (state, { payload }) => {
            if (payload.var.value !== null && payload.var.editable) {
                if (payload.var.type === "boolean" && payload.var.value === "true") {
                    state.currentValue = true;
                }
                if (payload.var.type === "boolean" && payload.var.value === "false") {
                    state.currentValue = false;
                }
                if (payload.var.type !== "boolean") {
                    state.currentValue = payload.var.value;
                }
            } else {
                state.currentValue = null;
            }
        },
        toggleComponentDetails: (state, { payload }) => {
            state.componentDetails = payload
        }
    }
});

export const { enableCheck, disableCheck, changeOperator, changeWorkcentre, changeMachine, fillOdps, fillProcesses, changeOdp, enableFlagUserEventType, changeMachineEvent,
    changeProcess, changeCurrentValue, fillMachines, enableLoading, enableErrors, updateProcess, changeUserEvent, updateVars, updateLiveProcess,
    resetMachine, resetOdp, resetOperator, resetUserEvents, resetWorkcentre, changeUserEventType, resetUserEventType, resetMachineEventType,resetMachineEventReason, addUserEvent,
    resetUserEvent, enableFlagMachineEventType,enableFlagMachineEventReason, changeMachineEventType, changeMachineEventReason, addMachineEvent, resetMachineEvent, enableFlagVars, disableFlagVars,
    enableFlagStartChecks, enableFlagCloseChecks, enableFlagPauseChecks, enableFlagRestartChecks, disableFlagChecks, updateChecks, enableMachineEventErrors,
    enableFlagInsertVar, disableFlagInsertVar, fillDocuments, disableCheckAutostart, enableOdpProgressErrors,
    updateLiveMachineEvent, updateLiveUserEvent, fillUserEventTypes, enableReloading, disableReloading, resetErrors, enableDocumentsErrors, enableDocumentsLoading,
    enableFlagButtons, disableFlagButtons, disableLoading, setWorkcentres, setMachines, resetUserEventUpdate, enableVarsErrors,
    fillMachineEventTypes, disableInterfaceNeedUpdate, setForceConsumMessage, changeCurrentCodeByEnter,
    setRolesSaved, changeNoteChecks, enableReloadingOdpErrors, enableChecksErrors, enableFlagInsertButton, enableFlagUserEvents, disableFlagUserEvents, fillUserEvents,
    disableFlagInsertButton, runButtonSuccess, runButtonError, integrateMachineEvent, resetMachineEventUpdate, enableReloadDocs, disableReloadDocs,
    fillComponents, setNewConsum, setEditConsum, unsetCurrentConsum, changeCurrentConsum, changeCurrentConsumByEnter, enableFlagStopProcess, disableFlagStopProcess, fillProcessChecks,
    resetDocuments, getLotListSuccess, getLotInfoSuccess, lotInfoError, getScrapListSuccess, initiateStapling, changeStaplingList, getSubstituteListSuccess,
    fillAllprocesses, startStapling, updateStaplingOdp, integrateOdpStapling, integrateStaplingList, resetCurrentValue, fillStaplingVars,
    fillMachineVars, fillStaplingChecks, fillStaplingDocuments, createStaplingObjects, enableStaplingProgressErrors, updateLiveStaplingOdp, noUserEvent,
    integrateUserEvent, initiateMultipleStapling, changeStaplingProcess, resetStaplingProcess, enableCustomErrors, enableCustomLoading,
    disableStaplingFlagChecks, fillProcessVars, fillStaplingComponents, fillStaplingOee, updateStaplingMachineEvent, changeManualInteraction,
    setUnmountProcess, addExtCheck, enableFlagChoosePause, disableFlagChoosePause, enableFlagOnDemand, enableFlagExtChecks, disableFlagExtChecks,
    enableFlagInsertCheck, disableFlagInsertCheck, enableExtChecksErrors, setArticleToWaste, presetVarCurrentValue, disableCustomLoading, toggleComponentDetails,chooseUserEvent,
    enableFlagAskConfirmation, disableFlagAskConfirmation, addRowToCausalizationObj, removeRowToCausalizationObj, changeCausalizationObj
} = operatorViewSlice.actions;

export const operatorViewSelector = (state) => state.operatorView;
export default operatorViewSlice.reducer;

//Chiamata in get che restituisce l'oggetto operatore nel caso il codice selezionato esista
export function getOperatorAPI(code, roles) {
    return async (dispatch) => {
        dispatch(enableLoading())
        try {
            const response = await fetch(SERVER_URL + "/user/code/" + code + "?roles=" + roles.toString(), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(changeOperator(data));
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors());
            }
        } catch (e) {
            dispatch(enableErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};
//Chiamata in get che restituisce gli odp disponibili per la macchina selezionata
export function getMachineOdpsAPI(id, userId, maintenance, code, page, per_page, stapling, extCode) {
    return async (dispatch) => {
        dispatch(enableLoading())
        try {
            const response = await fetch(SERVER_URL + "/machine/" + id + "/odp?user=" + userId + "&maintenance=" + maintenance + "&code=" + code + "&page=" + page + "&per_page=" + per_page, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                data["stapling"] = stapling
                data["extCode"] = extCode
                dispatch(fillOdps(data));
            } else {
                dispatch(resetMachine());
                dispatch(enableErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(resetMachine());
            dispatch(enableErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};
//Chiamata in get che restituisce le lavorazioni disponibili per l odp e la machine selezionati
export function getOdpProcessesAPI(odp, machine, operator, modeStapling) {
    return async (dispatch) => {
        dispatch(enableLoading());
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/process/open?machine=" + machine.id + "&user=" + operator.id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                if (!modeStapling) {
                    dispatch(fillProcesses(data));
                } else {
                    if (data.length === 1) {
                        dispatch(initiateStapling(data));
                    } else {
                        dispatch(initiateMultipleStapling(data));
                    }
                }
            } else {
                dispatch(resetOdp());
                dispatch(enableErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(resetOdp());
            dispatch(enableErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};
//Chiamata in get che restituisce le lavorazioni disponibili per la pinzatura
export function getAllProcessesAPI(machine, user, process, code, page, per_page, source_code) {
    return async (dispatch) => {
        dispatch(enableLoading());
        try {
            const response = await fetch(SERVER_URL + "/process/open?machine=" + machine.id + "&user=" + user.id + "&processType=" + process.type.id + "&code=" + code + "&page=" + page + "&per_page=" + per_page + "&source_code=" + process.code, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(fillAllprocesses(data));
            } else {
                dispatch(resetOdp());
                dispatch(enableErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(resetOdp());
            dispatch(enableErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};
/**
 * 
 * @param {*} odp 
 * @param {*} process 
 * @param {*} user 
 * @param {*} machine
 * @param {object} userEvent se valorizzato, chiamata callBack che crea userEvent
 * @returns 
 */
export function startProcessAPI(odp, process, user, machine, userEvent) {
    return async dispatch => {
        dispatch(enableLoading());
        dispatch(disableFlagChecks());
        dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: true }))
        let params = { user: { id: user.id }, machine: { id: machine.id } }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/process/" + process.id + "/start", {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(updateProcess(data));
                dispatch(showSuccess("function.operationSuccess"));
                if (userEvent) {
                    dispatch(startUserEventAPI(process, false, user, userEvent, machine));
                }
            } else {
                dispatch(enableOdpProgressErrors());
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: false }))
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableOdpProgressErrors());
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: false }))
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * 
 * @param {*} odp 
 * @param {*} process 
 * @param {*} user 
 * @param {*} machine 
 * @returns 
 */
export function pauseProcessAPI(odp, process, user, machine) {
    return async dispatch => {
        dispatch(enableLoading());
        dispatch(disableFlagChecks());
        dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: true }))
        let params = { user: { id: user.id }, machine: { id: machine.id } }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/process/" + process.id + "/pause", {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(updateProcess(data));
                //dispatch(disableFlagChoosePause());
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableOdpProgressErrors());
                //dispatch(disableFlagChoosePause());
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: false }))
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableOdpProgressErrors());
            //dispatch(disableFlagChoosePause());
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: false }))
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * 
 * @param {*} odpId 
 * @param {*} processId 
 * @param {*} userId 
 * @param {*} machineId 
 * @returns 
 */
export function stopProcessAPI(odp, process, user, machine) {
    return async dispatch => {
        dispatch(enableLoading());
        dispatch(disableFlagChecks());
        dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: true }));
        let params = { user: { id: user.id }, machine: { id: machine.id } }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/process/" + process.id + "/stop", {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(updateProcess(data));
                dispatch(showSuccess("function.operationSuccess"));
                dispatch(disableFlagStopProcess());
            } else {
                dispatch(enableOdpProgressErrors());
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: false }));
                dispatch(disableFlagStopProcess());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableOdpProgressErrors());
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: false }));
            dispatch(disableFlagStopProcess());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * Chiamata per dichiarare l'evento utente, uno dei due primi params deve essere false in base a odp normale o pinzatura
 * @param {object} process false se chiamata per pinzatura
 * @param {object} stapling false se chiamata per odp normale
 * @param {object} operator 
 * @param {object} eventType 
 * @param {object} machine 
 * @returns 
 */
export function startUserEventAPI(process, stapling, operator, eventType, machine) {
    return async dispatch => {
        dispatch(enableLoading());
        let params;
        if (process) {
            params = { process: process, user: operator, eventType: eventType, machine: machine }
        } else if (stapling) {
            params = { stapling: stapling, user: operator, eventType: eventType, machine: machine }
        }
        try {
            const response = await fetch(SERVER_URL + "/userEvent/start", {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(addUserEvent(data));
                dispatch(showSuccess("function.operationSuccess"));
                dispatch(getUserEventAPI(operator, false, false));
            } else {
                dispatch(enableErrors());
                dispatch(resetUserEventType());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableErrors());
            dispatch(resetUserEventType());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * Chiamata per fermare un evento utente in corso
 * @param {object} userEvent
 * @param {boolean} move se true fa il resetWorkcentre in modo da permettere al operatore di spostarsi
 * @param {boolean} reloadUserEvents se true fa il reload degli user event
 * @returns 
 */
export function stopUserEventAPI(userEvent, move, reloadUserEvents, inputOperator) {
    return async dispatch => {
        dispatch(enableLoading());
        //try {
            const response = await fetch(SERVER_URL + "/userEvent/" + userEvent.id + "/stop", {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(resetUserEvent());
                dispatch(showSuccess("function.operationSuccess"));
                //in caso venga cliccato su Spostati, per chiudere l evento utente in corso e cambiare centro di lavoro
                if (move === true) {
                    dispatch(resetWorkcentre())
                }
                if(reloadUserEvents){
                    dispatch(getUserEventAPI(userEvent.user, false, inputOperator));
                }
            } else {
                dispatch(enableErrors());
                dispatch(showError(data.message));
            }
        /*} catch (e) {
            dispatch(enableErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }*/
    }
};
//Chiamata in get che verifica se ci sono eventiMacchina associati al utente selezionato
export function getUserEventAPI(operator, singleUserEvent, inputOperator) {
    return async (dispatch) => {
        dispatch(enableLoading());
        try {
            const response = await fetch(SERVER_URL + "/user/" + operator.id + "/activity", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                if (data.userEvents.length>0) {
                    if(inputOperator){
                        if(singleUserEvent){
                            dispatch(changeUserEvent({userEvent:data.userEvents[0],machineEvent:data.machineEvent}));
                            if (data.userEvents[0].process.id !== null) {
                                dispatch(integrateEventAPI(data.userEvents[0].odp, data.userEvents[0].process, data.machine, data.userEvents[0].user, true, false));
                            } else if (data.userEvents[0].stapling.id !== null) {
                                dispatch(integrateOdpStaplingAPI(data.userEvents[0].stapling));
                            }
                        } else {
                            dispatch(chooseUserEvent(data.userEvents));
                        }
                    }else{
                        dispatch(enableFlagUserEvents())
                        dispatch(fillUserEvents(data.userEvents));
                    }
                } else if(inputOperator){
                    dispatch(noUserEvent());
                } else {
                    dispatch(disableFlagUserEvents());
                    dispatch(fillUserEvents(data.userEvents));
                }
            } else {
                dispatch(resetOperator({ value: true }))
                dispatch(enableErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(resetOperator({ value: true }))
            dispatch(enableErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};
//Chiamata in get che verifica se ci sono eventi utente per questo process
export function getOdpUserEventAPI(odp, process, operator) {
    return async (dispatch) => {
        dispatch(enableLoading());
        try {
            const response = await fetch(SERVER_URL + "/odp/" +odp.id+"/process/"+process.id+"/userEvent/" + operator.id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                if (data.userEvent !== null) {
                    dispatch(updateLiveUserEvent(data))
                } else {
                    dispatch(resetUserEventUpdate())
                }
            } else {
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: false }))
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: false }))
                dispatch(enableReloadingOdpErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: false }))
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: false }))
            dispatch(enableReloadingOdpErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};
//Chiamata in get che verifica se ci sono eventiMacchina associati alla macchina selezionata
export function getMachineEventAPI(machine, maintenance, operator) {
    return async (dispatch) => {
        dispatch(enableLoading());
        try {
            const response = await fetch(SERVER_URL + "/machine/" + machine.id + "/activity?pause=false&maintenance=" + maintenance, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(changeMachineEvent(data));
                if (data.machineEvent !== null) {
                    dispatch(integrateEventAPI(data.machineEvent.odp, data.machineEvent.process, machine, operator, false, true));
                } else if (data.machineEventStapling !== null) {
                    dispatch(integrateOdpStaplingAPI(data.machineEventStapling.odp));
                }
            } else {
                dispatch(resetMachine());
                dispatch(enableErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(resetMachine());
            dispatch(enableErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};
/**
 * 
 * @param {object} user 
 * @param {object} process 
 * @param {object} odp usato per chiamata componenti
 * @param {boolean} enableComponents 
 * @returns 
 */
export function getUserEventTypesAPI(user, process, odp, enableComponents, retrieveLotInfo) {
    return async (dispatch) => {
        dispatch(enableCustomLoading({ loading: LOADING_USER_EVENTS_TYPES, errors: ERRORS_USER_EVENT_TYPES }));
        try {
            const response = await fetch(SERVER_URL + "/user/" + user.id + "/eventTypes/" + process.type.id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(fillUserEventTypes(data));
                if (enableComponents) {
                    dispatch(getComponentsAPI(odp, process, retrieveLotInfo,false));
                }
            } else {
                dispatch(enableCustomErrors({ loading: LOADING_USER_EVENTS_TYPES, errors: ERRORS_USER_EVENT_TYPES }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableCustomErrors({ loading: LOADING_USER_EVENTS_TYPES, errors: ERRORS_USER_EVENT_TYPES }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};
/**
 * Chiamata per giustificare evento macchina, normale o pinzatura, parametrizzando i primi due params
 * @param {object} process false per pinzatura
 * @param {object} stapling false per odp normale
 * @param {object} operator 
 * @param {object} machineEventReason 
 * @param {object} machine 
 * @returns 
 */
export function justifyMachineEventAPI(process, stapling, operator, machineEventReason, machine, currentEvent) {
    return async dispatch => {
        dispatch(enableLoading());
        dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: true }))
        let params;
        if (process) {
            params = { process: process, user: operator, reason: machineEventReason, machine: machine }
        } else {
            params = { stapling: stapling, user: operator, reason: machineEventReason, machine: machine }
        }
        try {
            const response = await fetch(SERVER_URL + "/machineEvent/"+currentEvent.id+"/justify", {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(addMachineEvent({ machineEvent: data, stapling: process ? false : true }));
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableMachineEventErrors());
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: false }))
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableMachineEventErrors());
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: false }))
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * Chiamata per avviare evento macchina, normale o pinzatura, parametrizzando i primi due params
 * @param {object} process false per pinzatura
 * @param {object} stapling false per odp normale
 * @param {object} operator 
 * @param {object} machineEventType 
 * @param {object} machine 
 * @returns 
 */
export function startMachineEventAPI(process, stapling, operator, machineEventType, machine) {
    return async dispatch => {
        dispatch(enableLoading());
        dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: true }))
        let params;
        if (process) {
            params = { process: process, user: operator, eventType: machineEventType, machine: machine }
        } else {
            params = { stapling: stapling, user: operator, eventType: machineEventType, machine: machine }
        }
        try {
            const response = await fetch(SERVER_URL + "/machineEvent", {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(addMachineEvent({ machineEvent: data, stapling: process ? false : true }));
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableMachineEventErrors());
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: false }))
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableMachineEventErrors());
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: false }))
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
export function stopMachineEventAPI(machineEvent, machine) {
    return async dispatch => {
        dispatch(enableLoading());
        dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: true }))
        try {
            let params = { machine: machine }
            const response = await fetch(SERVER_URL + "/machineEvent/" + machineEvent.id, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(resetMachineEvent());
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableMachineEventErrors());
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: false }))
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableMachineEventErrors());
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: false }))
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
//chiamata che invia i vari check, nota che type non viene usato per la chiamata ma per aggiornare il relativo state
/**
 * 
 * @param {*} odp 
 * @param {*} process 
 * @param {*} checkId 
 * @param {*} value 
 * @param {*} user 
 * @param {*} machine
 * @param {*} type specifica se check di start, close, restart, pause, onDemand
 * @param {*} notes 
 * @param {boolean} maintenance false se inserimento checks normali, true per inserimento checks di manutenzione
 * @param {object} timestamp valorizzato se check esterno non bloccante, false altrimenti
 * @returns 
 */
export function insertChecksAPI(odp, process, checkId, value, user, machine, type, notes, maintenance, timestamp) {
    return async dispatch => {
        dispatch(enableLoading());
        let params = { value: value, user: user, type: type, notes: notes, machine: machine }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/process/" + process.id + "/check/" + checkId, {
                mode: 'cors',
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(updateChecks({ type: type, timestamp: timestamp }));
                dispatch(reloadingInterfaceOdpModeAPI(odp, process, machine, user, false, false, maintenance, false, false))
            } else {
                dispatch(enableChecksErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableChecksErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};

export function justifyVarsAPI(odp, process, processVar, causalizationObj, user, machine) {
    return async dispatch => {
        dispatch(enableLoading());
        let params = { value: causalizationObj, user: user, machine: machine }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/process/" + process.id + "/var/" + processVar.id+"/justify", {
                mode: 'cors',
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(updateVars({ data: data, varCheck: false }));
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableVarsErrors({ varCheck: false }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableVarsErrors({ varCheck: false }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
export function justifyStaplingVarsAPI(odp, processVar, causalizationObj, user, machine) {
    return async dispatch => {
        dispatch(enableLoading());
        let params = { value: causalizationObj, user: user, machine: machine }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/stapling/var/" + processVar.id+"/justify", {
                mode: 'cors',
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(updateVars({ data: data, varCheck: false }));
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableVarsErrors({ varCheck: false }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableVarsErrors({ varCheck: false }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * 
 * @param {*} odp 
 * @param {*} process 
 * @param {*} processVar 
 * @param {*} value 
 * @param {*} user 
 * @param {*} machine 
 * @param {boolean} varCheck true se chiamata viene fatta come callback di insertchecks api,decide se mostrare dialog elenco var da inserire
 * @param {*} checkId
 * @param {*} checkType
 * @param {*} checkTime
 * e per non fare il reloading del interfaccia
 * @returns 
 */
export function insertVarsAPI(odp, process, processVar, value, user, machine, varCheck, checkId, checkType, checkTime) {
    return async dispatch => {
        dispatch(enableLoading());
        let params = { value: value, user: user, machine: machine }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/process/" + process.id + "/var/" + processVar.id, {
                mode: 'cors',
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(updateVars({ data: data, varCheck: varCheck }));
                 if (varCheck) {
                    dispatch(insertChecksAPI(odp, process, checkId, value, user, machine, checkType, false, false, checkTime));
                }
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableVarsErrors({ varCheck: varCheck }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableVarsErrors({ varCheck: varCheck }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * 
 * @param {*} odp 
 * @param {*} process 
 * @param {*} machine 
 * @param {*} user 
 * @param {*} enableComponents 
 * @param {boolean} interval true se chiamato dal interval, false se chiamata dall'interazione o da errori
 * @param {boolean} modeMaintenance
 * @returns 
 */
export function reloadingInterfaceOdpModeAPI(odp, process, machine, user, enableComponents, interval, modeMaintenance, loadChecks, retrieveLotInfo) {
    return async (dispatch) => {
        dispatch(enableReloading());
        try {
            const response = await fetch(SERVER_URL + '/odp/' + odp.id + '/process/' + process.id + '/details?machine=' + machine.id + '&user=' + user.id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                if (data.userEvent !== null) {
                    dispatch(updateLiveUserEvent(data))
                } else {
                    dispatch(resetUserEventUpdate())
                }
                if (data.machineEvent !== null) {
                    dispatch(updateLiveMachineEvent({ data: data, interval: interval }))
                } else {
                    dispatch(resetMachineEventUpdate({ interval: interval, stapling: false }))
                }
                dispatch(updateLiveProcess({ data: data, interval: interval }));
                dispatch(getProcessVarsAPI(process));

                if (modeMaintenance && loadChecks) {
                    dispatch(getProcessChecksAPI(odp, process))
                } else if (!modeMaintenance){
                    dispatch(getMachineVarsAPI(machine));
                    if (enableComponents) {
                        dispatch(getComponentsAPI(odp, process,retrieveLotInfo,loadChecks));
                    } else if(loadChecks) {
                        dispatch(getProcessChecksAPI(odp, process))
                    }
                }
            } else {
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: false }))
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: false }))
                dispatch(enableReloadingOdpErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: false }))
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: false }))
            dispatch(enableReloadingOdpErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};

export function runButtonAPI(button) {
    return async (dispatch) => {
        dispatch(enableLoading());
        try {
            const response = await fetch(SERVER_URL + "/button/" + button.id + "/run", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify(button)
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(runButtonSuccess());
                dispatch(showSuccess({ message: data.message, noTranslate: true }));
            } else {
                dispatch(runButtonError());
                dispatch(showError(JSON.parse(data.message).message));
            }
        } catch (e) {
            dispatch(runButtonError());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};
export function getMachineEventTypesAPI(machine) {
    return async dispatch => {
        dispatch(enableCustomLoading({ loading: LOADING_MACHINE_EVENT_TYPES, errors: ERRORS_MACHINE_EVENT_TYPES }));
        try {
            const response = await fetch(SERVER_URL + "/machine/" + machine.id + "/eventTypes", {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(fillMachineEventTypes(data));

            } else {
                dispatch(enableCustomErrors({ loading: LOADING_MACHINE_EVENT_TYPES, errors: ERRORS_MACHINE_EVENT_TYPES }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableCustomErrors({ loading: LOADING_MACHINE_EVENT_TYPES, errors: ERRORS_MACHINE_EVENT_TYPES }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * Chiamata che viene fatta in seguito a getUserEventAPI o getMachineEventAPI, parametrizzando gli ultimi due params, per recuperare le informazioni mancanti 
 * necessarie all'interfaccia
 * @param {object} odp 
 * @param {object} process 
 * @param {object} machine 
 * @param {object} user 
 * @param {boolean} userEvent true per integrare userEvent
 * @param {boolean} machineEvent true per integrare machineEvent
 * @returns 
 */
export function integrateEventAPI(odp, process, machine, user, userEvent, machineEvent) {
    return async (dispatch) => {
        dispatch(enableLoading());
        try {
            const response = await fetch(SERVER_URL + '/odp/' + odp.id + '/process/' + process.id + '/details?machine=' + machine.id + '&user=' + user.id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                if (machineEvent) {
                    dispatch(integrateMachineEvent(data));
                } else if (userEvent) {
                    dispatch(integrateUserEvent(data));
                }
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors());
                if (machineEvent) {
                    dispatch(resetMachine());
                }
            }
        } catch (e) {
            dispatch(enableErrors());
            if (machineEvent) {
                dispatch(resetMachine());
            }
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};
//se modeStapling true viene fatta questa chiamata al posto di quella precedente
export function integrateOdpStaplingAPI(odp) {
    return async (dispatch) => {
        dispatch(enableLoading());
        try {
            const response = await fetch(SERVER_URL + '/odp/' + odp.id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(integrateOdpStapling(data));
                dispatch(getStaplingListAPI(data));
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors());
                dispatch(resetMachine());
            }
        } catch (e) {
            dispatch(enableErrors());
            dispatch(resetMachine());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};
//dopo aver recuperato l odp di tipo stapling chiamata per ricevere staplingList(processes)
export function getStaplingListAPI(odp) {
    return async (dispatch) => {
        dispatch(enableLoading());
        try {
            const response = await fetch(SERVER_URL + '/odp/' + odp.id + "/stapling/processes", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(integrateStaplingList(data))
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors());
                dispatch(resetMachine());
            }
        } catch (e) {
            dispatch(enableErrors());
            dispatch(resetMachine());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};
/**
 * 
 * @param {int} parentId id oggetto
 * @param {string} parentType tipologia oggetto
 * @param {boolean} reload flag per primo caricamento oppure per reloading
 * @param {boolean} stapling flag a true per docs pinzatura false per docs normali
 * @returns lista documenti
 */
export function getDocumentsAPI(parentId, parentType, reload, stapling) {
    return async dispatch => {
        if (reload) {
            dispatch(enableReloadDocs(parentType));
        } else {
            dispatch(enableDocumentsLoading());
        }
        try {
            const response = await fetch(SERVER_URL + '/document?parentId=' + parentId + '&parentType=' + parentType, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                if (!stapling) {
                    dispatch(fillDocuments({ data: data, parentType: parentType, parentId: parentId }))
                } else {
                    dispatch(fillStaplingDocuments({ data: data, type: parentType, id: parentId }))
                }
                if (reload) {
                    dispatch(disableReloadDocs(parentType));
                }
            } else {
                if (reload) {
                    dispatch(disableReloadDocs(parentType));
                }
                dispatch(enableDocumentsErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            if (reload) {
                dispatch(disableReloadDocs(parentType));
            }
            dispatch(enableDocumentsErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};

export function getComponentsAPI(odp, process, retrieveLotInfo,loadChecks) {
    return async dispatch => {
        dispatch(enableReloading());
        try {
            const response = await fetch(SERVER_URL + '/odp/' + odp.id + '/process/' + process.id + '/component/', {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(fillComponents({ data: data }))
                if(retrieveLotInfo){
                    for(let c in data){
                        if(data[c].consumptions.length > 0 && data[c].lotRequired){
                            console.log(data[c])
                            dispatch(loadLotInfoAPI(odp, process, data[c].id, data[c].consumptions[0].id, data[c].consumptions[0].lot, odp.stapling, false))
                        }
                    }
                }
                if(loadChecks){
                    dispatch(getProcessChecksAPI(odp, process))
                }
            } else {
                dispatch(enableReloadingOdpErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableReloadingOdpErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
//NON METTO ERRORS A TRUE, SERVE???????
/**
 * 
 * @param {*} odp 
 * @param {*} process 
 * @param {*} machine 
 * @param {*} user 
 * @param {*} consum 
 * @param {*} stapling 
 * @param {*} checkConsum 
 * @param {*} force 
 * @returns 
 */
export function updateComponentAPI(odp, process, machine, user, consum, stapling, force, forceConfig, checkConsum, retrieveLotInfo) {
    return async dispatch => {
        dispatch(enableLoading());
        let params = {
            close: consum.close,
            force: force,
            consumption: {
                lot: consum.lot, notes: consum.notes, quantity: consum.quantity, lotqty: consum.lotqty
            },
            user: { id: user.id }, machine: { id: machine.id }
        }
        let url;
        if (!stapling) {
            url = '/odp/' + odp.id + '/process/' + process.id + '/component/' + consum.componentId + '/consumption/' + consum.id;
        } else {
            url = '/odp/' + odp.id + '/stapling/component/' + consum.componentId + '/consumption/' + consum.id
        }
        try {
            const response = await fetch(SERVER_URL + url, {
                mode: 'cors',
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                if (!stapling) {
                    dispatch(getComponentsAPI(odp, process,retrieveLotInfo,false));
                } else {
                    dispatch(getStaplingComponentsAPI(odp, retrieveLotInfo,false))
                }
                dispatch(unsetCurrentConsum());
                if (checkConsum) {
                    dispatch(changeCurrentValue({ value: true }));
                }
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableErrors());
                dispatch(unsetCurrentConsum());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableErrors());
            dispatch(unsetCurrentConsum());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
//NON METTO ERRORS A TRUE, SERVE???????
/**
 * 
 * @param {*} odp 
 * @param {*} process 
 * @param {*} machine 
 * @param {*} user 
 * @param {*} consum 
 * @param {*} stapling 
 * @param {*} checkConsum 
 * @param {*} force 
 * @returns 
 */
export function saveCompConsumAPI(odp, process, machine, user, consum, stapling, force, forceConfig, checkConsum, retrieveLotInfo, insertArticleIfForcedLot) {
    return async dispatch => {
        dispatch(enableLoading());
        let params = {
            close: consum.close,
            force: force,
            consumption: {
                lot: consum.lot, notes: consum.notes, quantity: consum.quantity, lotqty: consum.lotqty, substitute: consum.newComponentCode, description: consum.description, newlotqty: consum.newlotqty
            },
            user: { id: user.id }, machine: { id: machine.id }
        }
        let url;
        if (!stapling) {
            url = '/odp/' + odp.id + '/process/' + process.id + '/component/' + consum.componentId + '/consumption/';
        } else {
            url = '/odp/' + odp.id + '/stapling/component/' + consum.componentId + '/consumption/'
        }
        try {
            const response = await fetch(SERVER_URL + url, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                if (!stapling) {
                    dispatch(getComponentsAPI(odp, process, retrieveLotInfo,false));
                } else {
                    dispatch(getStaplingComponentsAPI(odp, retrieveLotInfo,false))
                }
                dispatch(unsetCurrentConsum());
                if (checkConsum) {
                    dispatch(changeCurrentValue({ value: true }));
                }
                dispatch(showSuccess("function.operationSuccess"));
            } else if (forceConfig) {
                dispatch(setForceConsumMessage())
                if(insertArticleIfForcedLot){
                    dispatch(loadLotInfoAPI(odp, process, consum.componentId, 0, consum.lot, stapling, true))
                }
            } else {
                dispatch(enableErrors());
                dispatch(unsetCurrentConsum());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableErrors());
            dispatch(unsetCurrentConsum());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * 
 * @param {*} odp 
 * @param {*} process 
 * @param {*} machine 
 * @param {*} user 
 * @param {*} consum 
 * @param {*} stapling
 * @param {boolean} checkWaste 
 * @returns 
 */
export function saveCompWasteAPI(odp, process, machine, user, consum, stapling, checkWaste, retrieveLotInfo) {
    return async dispatch => {
        dispatch(enableLoading());
        let params = {
            waste: {
                lot: consum.lot, quantity: consum.quantity, reason: consum.scrapReason
            },
            user: { id: user.id }, machine: { id: machine.id }
        }
        let url;
        if (!stapling) {
            url = '/odp/' + odp.id + '/process/' + process.id + '/component/' + consum.componentId + '/waste/';
        } else {
            url = '/odp/' + odp.id + '/stapling/component/' + consum.componentId + '/waste/'
        }
        try {
            const response = await fetch(SERVER_URL + url, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                if (!stapling) {
                    dispatch(getComponentsAPI(odp, process, retrieveLotInfo,false));
                } else {
                    dispatch(getStaplingComponentsAPI(odp, retrieveLotInfo,false))
                }
                dispatch(unsetCurrentConsum());
                if (checkWaste) {
                    dispatch(changeCurrentValue({ value: true }));
                }
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableErrors());
                dispatch(unsetCurrentConsum());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableErrors());
            dispatch(unsetCurrentConsum());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
export function substituteCompAPI(odp, process, machine, user, consum, stapling, retrieveLotInfo) {
    return async dispatch => {
        dispatch(enableLoading());
        let params = {
            component: { id: consum.componentId },
            substitute: consum.substitute,
            user: { id: user.id }, machine: { id: machine.id }
        }
        let url;
        if (!stapling) {
            url = '/odp/' + odp.id + '/process/' + process.id + '/component/' + consum.componentId + '/changeComponent/';
        } else {
            url = '/odp/' + odp.id + '/stapling/component/' + consum.componentId + '/changeComponent/'
        }
        try {
            const response = await fetch(SERVER_URL + url, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                if (!stapling) {
                    dispatch(getComponentsAPI(odp, process, retrieveLotInfo,false));
                } else {
                    dispatch(getStaplingComponentsAPI(odp, retrieveLotInfo,false))
                }
                dispatch(unsetCurrentConsum());
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableErrors());
                dispatch(unsetCurrentConsum());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableErrors());
            dispatch(unsetCurrentConsum());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
export function loadLotListAPI(odp, process, consId, stapling) {
    return async dispatch => {
        dispatch(enableLoading());
        let url;
        if (!stapling) {
            url = '/odp/' + odp.id + '/process/' + process.id + '/component/' + consId + '/lotList/'
        } else {
            url = '/odp/' + odp.id + '/stapling/component/' + consId + '/lotList/'
        }
        try {
            const response = await fetch(SERVER_URL + url, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(getLotListSuccess(data));
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors());
            }
        } catch (e) {
            dispatch(enableErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
export function loadLotInfoAPI(odp, process, compId, consId, lot, stapling, articleForced) {
    return async dispatch => {
        let url;
        if (!stapling) {
            url = '/odp/' + odp.id + '/process/' + process.id + '/component/' + compId + '/lotInfo/' + consId + '?lot=' + lot
        } else {
            url = '/odp/' + odp.id + '/stapling/component/' + compId + '/lotInfo/' + consId + '?lot=' + lot
        }
        try {
            const response = await fetch(SERVER_URL + url, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(getLotInfoSuccess({data: data, lot: lot, articleForced:articleForced}));
            } else {
                dispatch(lotInfoError());
                dispatch(showError(data.message));
                dispatch(enableErrors());
            }
        } catch (e) {
            dispatch(enableErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
}
export function loadScrapListAPI(odp, process, consId, stapling) {
    return async dispatch => {
        dispatch(enableLoading());
        let url;
        if (!stapling) {
            url = '/odp/' + odp.id + '/process/' + process.id + '/component/' + consId + '/scrapList/'
        } else {
            url = '/odp/' + odp.id + '/stapling/component/' + consId + '/scrapList/'
        }
        try {
            const response = await fetch(SERVER_URL + url, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(getScrapListSuccess(data));
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors());
            }
        } catch (e) {
            dispatch(enableErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
export function loadSubstituteListAPI(odp, process, consId, stapling) {
    return async dispatch => {
        dispatch(enableLoading());
        let url;
        if (!stapling) {
            url = '/odp/' + odp.id + '/process/' + process.id + '/component/' + consId + '/substituteList/'
        } else {
            url = '/odp/' + odp.id + '/stapling/component/' + consId + '/substituteList/'
        }
        try {
            const response = await fetch(SERVER_URL + url, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(getSubstituteListSuccess(data));
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors());
            }
        } catch (e) {
            dispatch(enableErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
//carico e valorizzo tutti i checks relativi alla lavorazione
export function getProcessChecksAPI(odp, process) {
    return async dispatch => {
        dispatch(enableCustomLoading({ loading: LOADING_PROCESS_CHECKS, errors: ERRORS_PROCESS_CHECKS }));
        try {
            const response = await fetch(SERVER_URL + '/odp/' + odp.id + '/process/' + process.id + '/uniqueChecks/', {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(fillProcessChecks(data))
            } else {
                dispatch(enableCustomErrors({ loading: LOADING_PROCESS_CHECKS, errors: ERRORS_PROCESS_CHECKS }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableCustomErrors({ loading: LOADING_PROCESS_CHECKS, errors: ERRORS_PROCESS_CHECKS }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
export function createStaplingAPI(processes, machine, user) {
    return async dispatch => {
        dispatch(enableLoading());
        const processesId = [];
        for (let i = 0; i < processes.length; i++) {
            let current = { id: processes[i].id };
            processesId.push(current);
        }
        let params = { processes: processesId, machine: { id: machine.id }, user: { id: user.id } }
        try {
            const response = await fetch(SERVER_URL + "/odp/createStapling", {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(updateStaplingOdp({ odp: data, reload: false }));
                dispatch(createStaplingObjects());
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableStaplingProgressErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableStaplingProgressErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
export function startStaplingAPI(odp, machine, user) {
    return async dispatch => {
        dispatch(enableLoading());
        dispatch(disableStaplingFlagChecks());
        dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: true }))
        let params = { machine: { id: machine.id }, user: { id: user.id } }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/startStapling", {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(updateStaplingOdp({ odp: data, reload: true }));
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableStaplingProgressErrors());
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: false }))
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableStaplingProgressErrors());
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: false }))
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * 
 * @param {*} odp 
 * @param {*} machine 
 * @param {*} user 
 * @returns 
 */
export function pauseStaplingAPI(odp, machine, user) {
    return async dispatch => {
        dispatch(enableLoading());
        dispatch(disableStaplingFlagChecks());
        dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: true }))
        let params = { machine: { id: machine.id }, user: { id: user.id } }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/pauseStapling", {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(updateStaplingOdp({ odp: data, reload: true }));
                //dispatch(disableFlagChoosePause());
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableStaplingProgressErrors());
                //dispatch(disableFlagChoosePause());
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: false }))
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableStaplingProgressErrors());
            //dispatch(disableFlagChoosePause());
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: false }))
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};

export function stopStaplingAPI(odp, machine, user) {
    return async dispatch => {
        dispatch(enableLoading());
        dispatch(disableStaplingFlagChecks());
        dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: true }))
        let params = { machine: { id: machine.id }, user: { id: user.id } }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/stopStapling", {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(updateStaplingOdp({ odp: data, reload: true }));
                dispatch(disableFlagStopProcess());
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(disableFlagStopProcess());
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: false }))
                dispatch(enableStaplingProgressErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(disableFlagStopProcess());
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: false }))
            dispatch(enableStaplingProgressErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * Restituisce le variabili disponibili per l odp di tipo pinzatura in questione
 * @param {object} odp odp di tipo pinztura
 * @returns elenco vars disponibili
 */
export function getProcessVarsAPI(process) {
    return async dispatch => {
        dispatch(enableCustomLoading({ loading: LOADING_PROCESS_VARS, errors: ERRORS_PROCESS_VARS }));
        try {
            const response = await fetch(SERVER_URL + "/process/" + process.id + "/vars?operatorView=true", {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(fillProcessVars(data));
            } else {
                dispatch(enableCustomErrors({ loading: LOADING_PROCESS_VARS, errors: ERRORS_PROCESS_VARS }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableCustomErrors({ loading: LOADING_PROCESS_VARS, errors: ERRORS_PROCESS_VARS }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * Restituisce le variabili disponibili per l odp di tipo pinzatura in questione
 * @param {object} odp odp di tipo pinztura
 * @returns elenco vars disponibili
 */
export function getStaplingVarsAPI(odp) {
    return async dispatch => {
        dispatch(enableCustomLoading({ loading: LOADING_STAPLING_VARS, errors: ERRORS_STAPLING_VARS }));
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/stapling/vars", {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(fillStaplingVars(data));
            } else {
                dispatch(enableCustomErrors({ loading: LOADING_STAPLING_VARS, errors: ERRORS_STAPLING_VARS }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableCustomErrors({ loading: LOADING_STAPLING_VARS, errors: ERRORS_STAPLING_VARS }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * Restituisce le machineVars disponibili per la macchina in questione
 * @param {object} machine macchina usata per fare la chiamata
 * @returns elenco machineVars disponibili
 */
export function getMachineVarsAPI(machine) {
    return async dispatch => {
        dispatch(enableCustomLoading({ loading: LOADING_MACHINE_VARS, errors: ERRORS_MACHINE_VARS }));
        try {
            const response = await fetch(SERVER_URL + "/machine/" + machine.id + "/machineVar", {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(fillMachineVars(data));
            } else {
                dispatch(enableCustomErrors({ loading: LOADING_MACHINE_VARS, errors: ERRORS_MACHINE_VARS }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableCustomErrors({ loading: LOADING_MACHINE_VARS, errors: ERRORS_MACHINE_VARS }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * Restituisce gli odpcheck associati al odp di tipo pinzatura in questione
 * @param {object} odp odp di tipo pinzatura
 * @returns checks disponibili
 */
export function getStaplingChecksAPI(odp) {
    return async dispatch => {
        dispatch(enableCustomLoading({ loading: LOADING_STAPLING_CHECKS, errors: ERRORS_STAPLING_CHECKS }));
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/stapling/uniqueChecks", {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(fillStaplingChecks(data));
            } else {
                dispatch(enableCustomErrors({ loading: LOADING_STAPLING_CHECKS, errors: ERRORS_STAPLING_CHECKS }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableCustomErrors({ loading: LOADING_STAPLING_CHECKS, errors: ERRORS_STAPLING_CHECKS }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * Inserisce i controlli della pinzatura
 * @param {*} odp odp pinzatura
 * @param {*} checkId id del check, precisamente checks[0].id
 * @param {*} value valore del check
 * @param {*} user operatore che inserisce il check
 * @param {*} machine
 * @param {string} type close/start/pause/restart
 * @param {object} notes false se non inserite oppure stringa
 * @param {object} timestamp valorizzato se check esterno non bloccante, false altrimenti

 * @returns check inserito
 */
export function insertStaplingChecksAPI(odp, checkId, value, user, machine, type, notes, timestamp) {
    return async dispatch => {
        dispatch(enableLoading());
        let params = { value: value, user: user, type: type, notes: notes, machine: machine }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/stapling/check/" + checkId, {
                mode: 'cors',
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(updateChecks({ type: type, timestamp: timestamp }));
                /* if (varToInsert) {
                    dispatch(insertStaplingVarsAPI(odp, varToInsert, value, user, machine, varToInsert ? articleWaste : false, varToInsert ? true : false))
                } */
            } else {
                dispatch(enableChecksErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableChecksErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 *  Carica i documenti di un determinato oggetto identificato da id e type
 * @param {int} parentId id oggetto
 * @param {string} parentType tipologia oggetto
 * @param {boolean} reload flag per primo caricamento oppure per reloading
 * @returns lista documenti
 */
export function getStaplingDocumentsAPI(parentId, parentType, reload) {
    return async dispatch => {
        if (reload) {
            dispatch(enableReloadDocs(parentType));
        } else {
            dispatch(enableDocumentsLoading());
        }
        try {
            const response = await fetch(SERVER_URL + '/document?parentId=' + parentId + '&parentType=' + parentType, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(fillStaplingDocuments({ data: data, type: parentType, id: parentId }))
                if (reload) {
                    dispatch(disableReloadDocs(parentType));
                }
            } else {
                if (reload) {
                    dispatch(disableReloadDocs(parentType));
                }
                dispatch(enableDocumentsErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            if (reload) {
                dispatch(disableReloadDocs(parentType));
            }
            dispatch(enableDocumentsErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
//chiamata che inserisce variabili della pinzatura
/**
 * 
 * @param {*} odp 
 * @param {*} odpVar 
 * @param {*} value 
 * @param {*} user 
 * @param {*} machine 
 * @param {*} article 
 * @param {*} varCheck
 * @param {*} checkId
 * @param {*} checkType
 * @param {*} checkTime
 * @returns 
 */
export function insertStaplingVarsAPI(odp, odpVar, value, user, machine, article, varCheck, checkId, checkType, checkTime) {
    return async dispatch => {
        dispatch(enableLoading());
        let articleId;
        if (article) {
            articleId = article.id;
        } else {
            articleId = null;
        }
        let params = { value: value, user: user, machine: machine, article: { id: articleId } }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/stapling/var/" + odpVar.id, {
                mode: 'cors',
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(updateVars({ data: data, varCheck: varCheck }));
                if (varCheck) {
                    dispatch(insertStaplingChecksAPI(odp, checkId, value, user, machine, checkType, false, checkTime))
                }
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableVarsErrors({ varCheck: varCheck }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableVarsErrors({ varCheck: varCheck }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};

/**
 * 
 * @param {*} odp 
 * @param {*} machine 
 * @param {*} operator 
 * @param {*} enableComponents 
 * @param {*} interval 
 * @returns 
 */
export function reloadingInterfaceStaplingModeAPI(odp, machine, operator, enableComponents, interval, retrieveLotInfo, loadChecks) {
    return async dispatch => {
        dispatch(enableCustomLoading({ loading: LOADING_STAPLING_ODP, errors: ERRORS_STAPLING_ODP }));
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(disableInterfaceNeedUpdate());
                dispatch(updateLiveStaplingOdp({ data: data, interval: interval }));
                dispatch(getStaplingVarsAPI(odp));
                dispatch(getMachineVarsAPI(machine));
                dispatch(getStaplingMachineEventAPI(machine, true));
                dispatch(getStaplingUserEventAPI(odp, operator))
                dispatch(getStaplingOeeAPI(data));
                if (enableComponents) {
                    dispatch(getStaplingComponentsAPI(data, retrieveLotInfo,loadChecks));
                } else if(loadChecks) {
                    dispatch(getStaplingChecksAPI(data));
                }
            } else {
                dispatch(disableInterfaceNeedUpdate());
                dispatch(enableCustomErrors({ loading: LOADING_STAPLING_ODP, errors: ERRORS_STAPLING_ODP }));
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: false }));
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: false }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(disableInterfaceNeedUpdate());
            dispatch(enableCustomErrors({ loading: LOADING_STAPLING_ODP, errors: ERRORS_STAPLING_ODP }));
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_EVENT, value: false }));
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: false }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
export function getStaplingComponentsAPI(odp, retrieveLotInfo,loadChecks) {
    return async dispatch => {
        dispatch(enableCustomLoading({ loading: LOADING_COMPONENTS, errors: ERRORS_COMPONENTS }));
        try {
            const response = await fetch(SERVER_URL + '/odp/' + odp.id + '/stapling/components/', {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(fillStaplingComponents(data))
                if (retrieveLotInfo) {
                    for (let c in data) {
                        if (data[c].consumptions.length > 0 && data[c].lotRequired) {
                            dispatch(loadLotInfoAPI(odp, false, data[c].id, data[c].consumptions[0].id, data[c].consumptions[0].lot, odp.stapling, false))
                        }
                    }
                }
                if(loadChecks){
                    dispatch(getStaplingChecksAPI(odp))
                }
            } else {
                dispatch(enableCustomErrors({ loading: LOADING_COMPONENTS, errors: ERRORS_COMPONENTS }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableCustomErrors({ loading: LOADING_COMPONENTS, errors: ERRORS_COMPONENTS }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
export function getStaplingOeeAPI(odp) {
    return async dispatch => {
        dispatch(enableCustomLoading({ loading: LOADING_STAPLING_OEE, errors: ERRORS_STAPLING_OEE }));
        try {
            const response = await fetch(SERVER_URL + '/odp/' + odp.id + '/stapling/oee/', {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                }
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(fillStaplingOee(data))
            } else {
                dispatch(enableCustomErrors({ loading: LOADING_STAPLING_OEE, errors: ERRORS_STAPLING_OEE }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableCustomErrors({ loading: LOADING_STAPLING_OEE, errors: ERRORS_STAPLING_OEE }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
/**
 * Update periodico machineevent pinzatura
 * @param {object} machine 
 * @param {boolean} interval 
 * @returns 
 */
export function getStaplingMachineEventAPI(machine, interval) {
    return async (dispatch) => {
        dispatch(enableCustomLoading({ loading: LOADING_STAPLING_MACHINE_EVENT, errors: ERRORS_STAPLING_MACHINE_EVENT }));
        try {
            const response = await fetch(SERVER_URL + "/machine/" + machine.id + "/activity?maintenance=" + false, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                if (data.machineEventStapling !== null) {
                    dispatch(updateStaplingMachineEvent({ data: data.machineEventStapling, interval: interval }));
                } else {
                    dispatch(resetMachineEventUpdate({ interval: interval, stapling: true }));
                }
            } else {
                dispatch(enableCustomErrors({ loading: LOADING_STAPLING_MACHINE_EVENT, errors: ERRORS_STAPLING_MACHINE_EVENT }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableCustomErrors({ loading: LOADING_STAPLING_MACHINE_EVENT, errors: ERRORS_STAPLING_MACHINE_EVENT }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};

export function getStaplingUserEventAPI(odp, user) {
    return async (dispatch) => {
        dispatch(enableCustomLoading({ loading: LOADING_STAPLING_USER_EVENT, errors: ERRORS_STAPLING_USER_EVENT }));
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/stapling/userEvent/user/" + user.id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                if (data.userEvent !== null) {
                    dispatch(updateLiveUserEvent(data))
                } else {
                    dispatch(resetUserEventUpdate())
                }
            }
            else {
                dispatch(enableCustomErrors({ loading: LOADING_STAPLING_USER_EVENT, errors: ERRORS_STAPLING_USER_EVENT }));
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableCustomErrors({ loading: LOADING_STAPLING_USER_EVENT, errors: ERRORS_STAPLING_USER_EVENT }));
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
};

export function unmountProcessAPI(odp, process, user, machine) {
    return async dispatch => {
        dispatch(enableLoading());
        dispatch(disableFlagChecks());
        dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: true }))
        let params = { user: { id: user.id }, machine: { id: machine.id } }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/process/" + process.id + "/unmount", {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(updateProcess(data));
                //dispatch(disableFlagChoosePause());
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableOdpProgressErrors());
                //dispatch(disableFlagChoosePause());
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: false }))
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableOdpProgressErrors());
            //dispatch(disableFlagChoosePause());
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_PROCESS, value: false }))
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};

export function unmountStaplingAPI(odp, user, machine) {
    return async dispatch => {
        dispatch(enableLoading());
        dispatch(disableFlagChecks());
        dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: true }))
        let params = { user: { id: user.id }, machine: { id: machine.id } }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/unmountStapling", {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                //dispatch(updateStaplingOdp({ odp: data, reload: true }));
                //dispatch(disableFlagChoosePause());
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableStaplingProgressErrors());
                //dispatch(disableFlagChoosePause());
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: false }))
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableStaplingProgressErrors());
            //dispatch(disableFlagChoosePause());
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: false }))
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};
export function removeStaplingProcess(odp, process, machine, user) {
    return async dispatch => {
        dispatch(enableLoading());
        dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: true }))
        let params = { user: { id: user.id }, machine: { id: machine.id } }
        try {
            const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/stapling/process/"+process.id, {
                mode: 'cors',
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(params)
            });
            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(showSuccess("function.operationSuccess"));
                dispatch(getStaplingListAPI(odp))
            } else {
                dispatch(enableStaplingProgressErrors());
                //dispatch(disableFlagChoosePause());
                dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: false }))
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(enableStaplingProgressErrors());
            //dispatch(disableFlagChoosePause());
            dispatch(changeManualInteraction({ name: CONST.MANUAL_INTERACTION_STAPLING, value: false }))
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};