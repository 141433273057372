import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    maintenancesSelector, reloadingOdpAPI, getOdpEventsAPI, getProcessUserEventsAPI, getProcessVarsAPI, setReloadMachineEvents, getProcessMachineVarsAPI,
    setReloadProcessEvents, getProcessChecksAPI
} from '../../maintenances/slice';
import useInterval from 'react-useinterval';
import { machineEventsSelector } from "../../machineEvents/slice";

const ReloadingMaintenance = () => {
    const dispatch = useDispatch();
    const { odp, reload, reloading, loading, odpedit, editProcess, editVar, flagEditBom, events,
        errorsOdpEvents, errorsVars, errorsProcessMachineVars, errorsUserEvents, errorsChecks } = useSelector(maintenancesSelector);
    const { machineEvents } = useSelector(machineEventsSelector);

    useInterval(() => updatingOdp(), 10000);

    //bloccato se finestra dialog checks e vars
    const updatingOdp = () => {
        //if (!newMachineEvent) {
        if (!odpedit && odp && !editVar && !flagEditBom && odp.id !== "new") {
            dispatch(reloadingOdpAPI(odp, machineEvents, errorsOdpEvents))
        }
    };
    //DOPPIO: ricarico l'odp quando reload a true
    useEffect(() => {
        if (reload && !loading && !reloading && odp.id !== "new") {
            dispatch(reloadingOdpAPI(odp, machineEvents, errorsOdpEvents));
        };
    }, [dispatch, odp, reload, loading, reloading, machineEvents, errorsOdpEvents])

    //DOPPIO: primo caricamento di events, dopo di che con lo use interval
    useEffect(() => {
        if (odp && odp.id !== "new" && machineEvents && !events && !errorsOdpEvents) {
            dispatch(getOdpEventsAPI(odp, machineEvents))
        }
    }, [dispatch, odp, machineEvents, events, errorsOdpEvents])

    //STANDARD: viene chiamato ogni volta che cambia l odp e l edit process, quindi su ogni useInterval oppure dopo reloadodp
    useEffect(() => {
        if (editProcess && odp) {
            dispatch(setReloadMachineEvents());
            dispatch(setReloadProcessEvents());
            if (!errorsVars) {
                dispatch(getProcessVarsAPI(odp, editProcess));
            }
            if (!errorsProcessMachineVars) {
                dispatch(getProcessMachineVarsAPI(odp, editProcess));
            }
            if (!errorsChecks) {
                dispatch(getProcessChecksAPI(odp, editProcess));
            }
        }
    }, [dispatch, editProcess, odp, errorsVars, errorsProcessMachineVars, errorsChecks])

    //STANDARD: ricarico eventi utente ogni qualvolta cambia l'odp, quind ogni useInterval
    useEffect(() => {
        if (odp && odp.id !== "new" && odp.processes.length > 0) {
            odp.processes.forEach(p => {
                if ((p.startDate !== null && p.endDate === null) && !errorsUserEvents) {
                    dispatch(getProcessUserEventsAPI(odp, p))
                }
            })
        }
    }, [dispatch, odp, errorsUserEvents])

    /*     //STANDARD: tenere sempre aggiornato il currentProcess quando cambia l'odp
        useEffect(() => {
            if (odp && odp.id !== "new" && odp.currentProcess !== null && odp.currentProcess >= 0 && odp.processes.length > 0) {
                dispatch(setCurrentProcess({ currentProcessIndex: odp.currentProcess, processes: odp.processes }))
            } else {
                dispatch(unSetCurrentProcess())
            }
        }, [dispatch, odp]); */

    return (
        null
    )
};

export default ReloadingMaintenance;