import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { customLabel } from "../utility/customLabel";
import DocumentDialog from "./DocumentDialog";
import { makeStyles } from '@material-ui/core/styles';
import { documentsSelector, getListApi, createAPI, deleteAPI, updateAPI, select, change, newDocument, setCurrentDocument, setDownload } from './slice'
import { Typography, Button, Table, IconButton, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, FormControl, Input } from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import { mesStyles } from '../utility/ultrafabStyles';
import DialogConfirmAction from '../utility/DialogConfirmAction';

const useStyles = makeStyles((theme) => ({
  sfirstCol: {
    width: "15%",
  },
  ssecondCol: {
    width: "25%",
  },
  sthirdCol: {
    width: "30%",
    textAlign: "right"
  },
  afourthCol: {
    width: "30%",
  }
}));

const DocumentsPage = ({ parentId, parentType, extLoading, title, size }) => {
  const dispatch = useDispatch();
  const myClasses = mesStyles();
  const classes = useStyles();
  const { loading, reload, document, documents } = useSelector(documentsSelector);

  useEffect(() => {
    dispatch(getListApi(parentId, parentType))
  }, [dispatch, parentId, parentType]);


  const [deleting, setDeleting] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(false);

  if (reload && !loading) {
    dispatch(getListApi(reload.parentId, reload.parentType))
  };

  const onAdd = () => {
    dispatch(newDocument({ parentId: parentId, parentType: parentType }))
  };
  const onDelete = (document) => {
    setDeleting(document)
  };
  const cancelDelete = () => {
    setDeleting(false)
  };
  const doDelete = () => {
    dispatch(deleteAPI(deleting, parentId, parentType));
    setDeleting(false)
  };
  const onEdit = (document) => {
    dispatch(select({ document: document, parentId: parentId, parentType: parentType }))
  };
  const cancelEdit = () => {
    dispatch(select({ document: false, parentId: false, parentType: false }));
    setSelectedFile(false);
  };
  const save = () => {
    if (document.id === "new") {
      dispatch(createAPI(document, selectedFile, document.parentId, document.parentType))
    } else {
      dispatch(updateAPI(document));
    };
    setSelectedFile(false);
  };
  const onChange = event => {
    let name = event.target.name
    let value = event.target.value
    dispatch(change({ name: name, value: value }));
  };
  const onSelectFile = event => {
    //per evitare che si rompi nel caso l'utente schiacci annulla su dialog di inserimento del file
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files)
    }
  };
  //seleziona il documento per visualizzarlo, abilitati per formati solo pdf e image
  const handleViewDocument = (doc) => {
    dispatch(setCurrentDocument({ doc: doc, parentId: parentId, parentType: parentType }))
  }
  //seleziona il documento per il download, abilitato per tutti i formati
  const handleGetDocument = (doc) => {
    dispatch(setDownload({ doc: doc, parentId: parentId, parentType: parentType }));
  }
  return (
    <React.Fragment>
      <div className={myClasses.titleFormSpaceBetween}>
        {title ? (
          <Typography component="h1" variant="h6" color="inherit"> {customLabel("document.documents")}</Typography>
        ) : null}
      </div>
      <div className={myClasses.tableContainer}>
        <Table size={size}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.firstCol}>{customLabel("document.name")}</TableCell>
              <TableCell className={classes.secondCol}>{customLabel("document.originalFileName")}</TableCell>
              <TableCell style={{ textAlign: "center" }} align="center" className={classes.thirdCol}>{customLabel("function.actions")}</TableCell>
              <TableCell align="right" className={myClasses.tableCell2Buttons} >
                <Button color="primary" onClick={onAdd} disabled={loading || extLoading}>{customLabel("button.add")}</Button>
                <Button color="default" disabled={loading || extLoading} onClick={() => dispatch(getListApi(parentId, parentType))}>{customLabel("button.reload")}</Button>
              </TableCell>
            </TableRow>
          </TableHead>
          {documents[parentType + "_" + parentId] ? (
            <TableBody>
              {documents[parentType + "_" + parentId].map(d => {
                return (
                  <TableRow key={d.id}>
                    <TableCell className={classes.firstCol}>{d.name}</TableCell>
                    <TableCell className={classes.secondCol}>{d.originalFileName}</TableCell>
                    <TableCell className={classes.thirdCol} align="center" style={{ textAlign: "center" }}>
                      <IconButton color="primary" variant="contained" onClick={() => handleViewDocument(d)}
                        disabled={d.format === "image" || d.format === "pdf" ? false : true}>
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton color="primary" variant="contained" onClick={() => handleGetDocument(d)} disabled={loading || extLoading}>
                        <GetAppIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right" className={myClasses.tableCell2Buttons}>
                      <Button onClick={() => { onEdit(d) }} color="primary" disabled={loading || extLoading}>{customLabel("button.edit")}</Button>
                      <Button onClick={() => { onDelete(d) }} color="secondary" disabled={loading || extLoading}>{customLabel("button.delete")}</Button>
                    </TableCell>
                  </TableRow>
                )
              })
              }
            </TableBody>
          ) : null}
        </Table>
      </div>
      {
        document !== false && document.parentId === parentId && document.parentType === parentType ? (
          <Dialog open={document !== false} keepMounted maxWidth={"sm"} fullWidth id={parentId + "_" + parentType}>
            <DialogTitle>{customLabel("document.document")}</DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="name">{customLabel("document.name")}</InputLabel>
                  <Input name="name" value={document.name} autoFocus onChange={onChange} error={document.name === ""} />
                </FormControl>
                {document.id === "new" ? (
                  <div className={myClasses.insertFileDiv}>
                    <Button
                      component="label"
                      color="primary"
                      disabled={loading}
                    >
                      {customLabel("button.upload")}
                      <input
                        type="file"
                        hidden
                        onChange={onSelectFile}
                      />
                    </Button>
                    <InputLabel>{selectedFile ? selectedFile[0].name : customLabel("document.noFileSelected")}</InputLabel>
                  </div>
                ) : null}
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={save} color="primary" disabled={document.name === "" || (!selectedFile && document.id === "new") || loading} >{customLabel("button.save")}</Button>
              <Button onClick={cancelEdit} disabled={loading} color="default">{customLabel("button.close")}</Button>
            </DialogActions>
          </Dialog>
        ) : null
      }
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={cancelDelete} disabled={loading} />
      <DocumentDialog parentId={parentId} parentType={parentType} />
    </React.Fragment >
  )
}

export default DocumentsPage;
