import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { mesStyles } from '../utility/ultrafabStyles';
import { customLabel } from "../utility/customLabel";

/**
 * @param {string} labelsSaved stringa di etichette salvate in configuration
 * @param {array} labelsList lista di etichette da passare al drag&drop
 * @param {string} name nome dell'etichetta da passare a change
 * @param {string} labelType tipo di etichetta
 * @returns 
 */
const DragDrop = ({ labelsSaved, labelsList, name, labelType, change }) => {
    const myClasses = mesStyles();

    const renderAvailable = () => {
        const result = labelsList.filter((i) => labelsSaved.split(",").every((c) => i.id !== c));
        return result
    }

    const renderSelected = (result = []) => {
        if (labelsSaved === "") {
            result = []
        } else {
            for (let i = 0, a = labelsSaved.split(','); i < a.length; i++) {
                let id = a[i] //article.code / article.name
                let translatedLabel
                let indexLabel = labelsList.findIndex((l) => {
                    return l.id === id
                })
                if (indexLabel !== -1) {
                    translatedLabel = (labelsList[indexLabel].translatedLabel)
                    result.push({ id, translatedLabel })
                }
            }
        }
        return result
    }
    const [items, setItems] = useState(renderAvailable());
    const [selected, setSelected] = useState(renderSelected());

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nice
        userSelect: 'none',
        padding: 8,
        margin: `0 0 4px 0`,
        // change background colour if dragging
        background: isDragging ? 'lightgreen' : 'white',

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? 'lightblue' : 'lightgrey',
        padding: 4,
        width: 200,
    });

    const getList = (id) => {
        if (id === "droppable") {
            return items;
        }
        else if (id === "droppable2") {
            return selected;
        }
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );

            if (source.droppableId === 'droppable') {
                setItems(items);
            }
            else if (source.droppableId === 'droppable2') {
                setSelected(items);
                change({ name: name, value: items.map((s) => s['id']).join() })
            }
        } else {
            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );
            setItems(result.droppable);
            setSelected(result.droppable2);
            change({ name: name, value: result.droppable2.map((s) => s['id']).join() })
        }
    };

    return (
        <>
            <p className={myClasses.labelDragDrop}>{labelType}</p>
            <div className={myClasses.dragDropContainer}>
                <p style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.54)', cursor: 'default', margin: '0 0 5px 0' }}>Disponibili</p>
                <p style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.54)', cursor: 'default', margin: '0 0 5px 0' }}>Selezionate</p>
            </div>
            <div className={myClasses.dragDropContainer}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}>
                                {items.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>
                                                {customLabel(item.translatedLabel)}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <Droppable droppableId="droppable2">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}>
                                {selected.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>
                                                {customLabel(item.translatedLabel)}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </>
    );
}

export default DragDrop