import React from "react";
import { Link } from "react-router-dom";
import {
  FormControl, Button, Typography, TextField, Grid, InputLabel, Select, MenuItem
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab"
import { customLabel } from "../utility/customLabel";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { bishopFormats } from '../../constants';
import PaperAutocomplete from "../utility/PaperAutocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
    "& .Mui-disabled": {
      color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
    }
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },
  buttonContainer: {
    textAlign: "right",
    marginTop: theme.spacing(2)
  },
  form: {
    width: "calc(25% - 32px)",
  },
  note: {
    width: "calc(100% - 32px)",
  },
  title: {
    margin: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between"
  }
}));
export const Form = ({ odpedit, handleChange, handleChangeDate, handleChangeObject, cancelEdit, save, maintenanceTypes, machines }) => {

  const classes = useStyles();

  const onChangeFilterAutocomplete = (name, value, reason) => {
    if (reason === "clear") {
      handleChangeObject(name, false)
    } else {
      handleChangeObject(name, value.id)
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={classes.title}>
            <Typography className={classes.note} component="h3" variant="h6" color="inherit">
              {customLabel("maintenance.maintenance")}
            </Typography>
          </div>
          <form className={classes.root} noValidate autoComplete="off">
            {machines ? (
              <Autocomplete
                PaperComponent={PaperAutocomplete}
                disabled={odpedit.startDate !== null}
                style={{ display: "inline-block" }}
                className={classes.form}
                value={odpedit.machine.id !== "" ? odpedit.machine : null}
                getOptionSelected={(option, value) => odpedit.machine.id !== '' ? option.id === value.id : null}
                onChange={(event, value, reason) => onChangeFilterAutocomplete("machine.id", value, reason)}
                options={machines}
                getOptionLabel={(option) => option.code + " - " + option.name}
                id="machineAutocomplete"
                renderInput={(params) => <TextField {...params} label={customLabel("maintenance.machine")} error={odpedit.machine.id === ''} />
                }
              />)
              : null}
            {maintenanceTypes ? (
              <Autocomplete
                PaperComponent={PaperAutocomplete}
                disabled={odpedit.startDate !== null || odpedit.machine.id === ""}
                style={{ display: "inline-block" }}
                className={classes.form}
                value={odpedit.maintenanceType.id !== "" ? odpedit.maintenanceType : ""}
                getOptionSelected={(option, value) => odpedit.maintenanceType.id !== '' ? option.id === value.id : ""}
                onChange={(event, value, reason) => onChangeFilterAutocomplete("maintenanceType.id", value, reason)}
                options={maintenanceTypes}
                getOptionLabel={(option) => option.name}
                id="maintenanceTypeAutocomplete"
                renderInput={(params) => <TextField {...params} label={customLabel("maintenance.maintenanceType")} error={odpedit.maintenanceType.id === ''} />
                }
              />)
              : null}
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <DatePicker className={classes.form}
                disableToolbar
                allowKeyboardControl={false}
                variant="inline"
                format={bishopFormats.L}
                autoOk
                onChange={(date) => handleChangeDate("deliveryDate", date)}
                label={customLabel("maintenance.deliveryDate")}
                value={odpedit.deliveryDate}
                disabled={odpedit.startDate !== null}
              />
            </MuiPickersUtilsProvider>
            <FormControl className={classes.form} disabled={odpedit.startDate !== null}>
              <InputLabel htmlFor="toBeProcessed">{customLabel("maintenance.toBeProcessed")}</InputLabel>
              <Select name="toBeProcessed" value={odpedit.toBeProcessed} onChange={handleChange}>
                <MenuItem value={true}>{customLabel("maintenance.maintenance")}</MenuItem>
                <MenuItem value={false}>{customLabel("maintenance.deadline")}</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.note}>
              <TextField
                variant="outlined" name="note" label={customLabel("function.note")} multiline rows={4} value={odpedit.note === null ? "" : odpedit.note}
                onChange={handleChange}
              />
            </FormControl>
          </form>
          <div className={classes.buttonContainer}>
            <Button focusRipple onClick={save} color="primary" disabled={odpedit.machine.id === "" || odpedit.maintenanceType.id === ""}>{customLabel("button.save")}</Button>
            {odpedit.id !== "new" ? (
              <Button focusRipple onClick={cancelEdit}>
                {customLabel("button.cancel")}
              </Button>
            ) : (
              <Button component={Link} to={`/maintenances`}>
                {customLabel("button.back")}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>

  );
};
