import { Checkbox, FormControl, FormControlLabel, Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from 'react';
import { customLabel } from "../utility/customLabel";
import { mesStyles } from "../utility/ultrafabStyles";

const VarForm = ({ processVar, onChange }) => {

    const myClasses = mesStyles();

    return (
        <form className={myClasses.dialogForm} noValidate autoComplete="off">
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="name">{customLabel("processType.name")}</InputLabel>
                <Input name="name" value={processVar.name} autoFocus onChange={onChange} error={processVar.name === ""} />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="type">{customLabel("processType.var.type")}</InputLabel>
                <Select name="type" value={processVar.type} onChange={onChange} error={processVar.type === ""}>
                    <MenuItem value="boolean">{customLabel("function.boolean")}</MenuItem>
                    <MenuItem value="string">{customLabel("function.string")}</MenuItem>
                    <MenuItem value="number">{customLabel("function.number")}</MenuItem>
                    <MenuItem value="timer">{customLabel("function.timer")}</MenuItem>
                </Select>
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="measure">{customLabel("processType.measure")}</InputLabel>
                <Input name="measure" value={processVar.measure} onChange={onChange} />
            </FormControl>
            <FormControl margin="normal">
                <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="multiple" onChange={onChange} checked={processVar.multiple} />}
                    label={customLabel("processType.multiple")} />
            </FormControl>
            <FormControl margin="normal">
                <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="incremental" onChange={onChange} checked={processVar.incremental} />}
                    label={customLabel("processType.incremental")} />
            </FormControl>
            <FormControl margin="normal">
                <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="operatorView" onChange={onChange} checked={processVar.operatorView} />}
                    label={customLabel("operatorView.operatorView")} />
            </FormControl>
            <FormControl margin="normal">
                <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="operatorEdit" onChange={onChange} checked={processVar.operatorEdit} />}
                    label={customLabel("processType.operatorEdit")} />
            </FormControl>
            <FormControl margin="normal">
                <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="adminEdit" onChange={onChange} checked={processVar.adminEdit} />}
                    label={customLabel("processType.adminEdit")} />
            </FormControl>
            <FormControl margin="normal">
                <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="editable" onChange={onChange} checked={processVar.editable} />}
                    label={customLabel("processType.editable")} />
            </FormControl>
            <FormControl margin="normal">
                <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="serial" onChange={onChange} checked={processVar.serial} />}
                    label={customLabel("processType.serial")} />
            </FormControl>
            <FormControl margin="normal">
                <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="askArticle" onChange={onChange} checked={processVar.askArticle} />}
                    label={customLabel("processType.askArticle")} />
            </FormControl>
            <FormControl margin="normal">
                <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="resettable" onChange={onChange} checked={processVar.resettable} />}
                    label={customLabel("processType.resettable")} />
            </FormControl>
            <FormControl margin="normal">
                <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="askConfirmation" onChange={onChange} checked={processVar.askConfirmation} />}
                    label={customLabel("processType.askConfirmation")} />
            </FormControl>
        </form>
    )
}

export default VarForm