import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: "#0000001a"
  }
}));

export const Item = ({ machine, select, m }) => {

  const classes = useStyles();

  return (
    <TableRow style={{ cursor: "pointer" }} key={m.id} className={classNames({ [classes.selected]: m.id === machine.id })} onClick={() => select(m)}>
      <TableCell>{m.code}</TableCell>
      <TableCell>{m.name}</TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}
