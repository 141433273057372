
import React from 'react'
import { customLabel } from "../utility/customLabel";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
const DialogForm = ({ form, title, open, closeDisabled, saveDisabled, save, close }) => {
    return (
        <Dialog open={open} keepMounted maxWidth={"sm"} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {form()}
            </DialogContent>
            <DialogActions>
                <Button onClick={save} color="primary" disabled={saveDisabled} >{customLabel("button.save")}</Button>
                <Button onClick={close} disabled={closeDisabled} color="default">{customLabel("button.close")}</Button>
            </DialogActions>
        </Dialog>
    )
}
export default DialogForm;