import { Button, Dialog, DialogActions, DialogTitle, Divider, Typography } from '@material-ui/core';
import React, { useEffect } from "react";
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router';
import useInterval from 'react-useinterval';
import { bishopMoment } from "../../../constants";
import { useAnchor } from "../../customHooks/useAnchor";
import { getListAPI as getMachineEventsAPI, machineEventsSelector } from "../../machineEvents/slice";
import { getFullListAPI as getMachinesAPI, machinesSelector } from "../../machines/slice";
import { resetErrors, showError } from "../../notifications/slice";
import ProcessForm from '../../odps/editProcess/ProcessForm';
import { getListAPI as getProcessStatusesAPI, processStatusesSelector } from "../../processStatuses/slice";
import PopoverLegend from "../../utility/PopoverLegend";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";
import { getListAPI as getWorkcentresAPI, workcentresSelector } from "../../workcentres/slice";
import { doChange } from "../slice";
import { Machine } from './Machine';
import { enableEditing, getExpiredOrphanProcesses, getOrphanProcesses, getProcesses, onChangeProcess, selectProcess, selector, setFirst, setLast, setUpMachines, updateProcess, updateProcessMachine } from './slice';

export default function PlannerPage() {
  const myClasses = mesStyles();
  const dispatch = useDispatch();
  const { processes, editing, savingProcess, selectedProcess, orphans, expiredOrphans } = useSelector(selector);
  const { workcentres } = useSelector(workcentresSelector);
  const { processStatuses } = useSelector(processStatusesSelector);
  const { machineEvents } = useSelector(machineEventsSelector);
  const { machines } = useSelector(machinesSelector);
  const [redirect, setRedirect] = React.useState(false);
  const [draggingProcess, setDraggingProcess] = React.useState(false);
  const [selectedOdp, selectOdp] = React.useState(false);
  const [selectedProcessMachine, setSelectedProcessMachine] = React.useState(false);
  const [anchorPopoverPs, { handleOpen: handleOpenPopoverPs, handleClose: handleClosePopoverPs }, openPopoverPs] = useAnchor();
  const [anchorPopoverMe, { handleOpen: handleOpenPopoverMe, handleClose: handleClosePopoverMe }, openPopoverMe] = useAnchor();

  useInterval(() => reloadProcesses(), 60000);

  const reloadProcesses = () => {
    if (machines && selectedProcess === false && (editing === false || Object.keys(editing).length === 0)) {
      machines.forEach(m => {
        dispatch(getProcesses(m, false));
      })
      dispatch(getOrphanProcesses(false));
      dispatch(getExpiredOrphanProcesses(false));
    }
  }

  useEffect(() => {
    if (machines) {
      dispatch(setUpMachines(machines));
    }
  }, [dispatch, machines])

  useEffect(() => {
    if (machines && processes) {
      machines.forEach(m => {
        if (!editing[m.code] && processes[m.code] === false) {
          dispatch(getProcesses(m, true));
        }
      })
      if (!editing["ORPHAN"] && orphans === false) {
        dispatch(getOrphanProcesses(true));
      }
      if (!editing["EXPIRED_ORPHAN"] && expiredOrphans === false) {
        dispatch(getExpiredOrphanProcesses(true));
      }
    }
  }, [dispatch, machines, editing, processes, orphans, expiredOrphans]);

  useEffect(() => {
    dispatch(getWorkcentresAPI(0, 10000, "code", "asc", false, false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMachinesAPI());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProcessStatusesAPI(0, 10000, "name", "asc", false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMachineEventsAPI(0, 10000, "name", "asc", false));
  }, [dispatch]);

  const settingRedirect = () => {
    setRedirect(`/odps/${selectedProcess.odp.id}`);
    dispatch(selectProcess(false))
    dispatch(doChange("/odps"));
  }

  const openProcessDetails = (process) => {
    let machine
    if (process.machine.id !== null) {
      let newMachineIndex = machines.findIndex((m) => {
        return m.id === process.machine.id;
      })
      machine = machines[newMachineIndex];
    } else if (bishopMoment(process.deliveryDate).diff(bishopMoment()) >= 0) {
      machine = { code: "ORPHAN" }
    } else {
      machine = { code: "EXPIRED_ORPHAN" }
    }
    setSelectedProcessMachine(machine);
    dispatch(selectProcess(process))
  }

  const cancelProcess = () => {
    dispatch(selectProcess(false))
  }

  const saveProcess = () => {
    let newMachineIndex = machines.findIndex((m) => {
      return m.id === selectedProcess.machine.id;
    })
    let machine = false;
    if (newMachineIndex > -1) {
      machine = machines[newMachineIndex];
      dispatch(enableEditing(machine))
    } else if (bishopMoment(selectedProcess.deliveryDate).diff(bishopMoment()) >= 0) {
      machine = { code: "ORPHAN" }
      dispatch(enableEditing(machine))
    } else {
      machine = { code: "EXPIRED_ORPHAN" }
      dispatch(enableEditing(machine))
    }
    dispatch(enableEditing(selectedProcessMachine))
    selectOdp(false)
    dispatch(updateProcess(selectedProcess, selectedProcessMachine, machine));
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    if (result[0] === undefined) {
      return null;
    }
    return result;
  };

  const onDragStart = (item) => {
    dispatch(resetErrors())
    if (item.source.droppableId !== "ORPHAN" && item.source.droppableId !== "EXPIRED_ORPHAN") {
      let newMachineIndex = machines.findIndex((m) => {
        return m.id.toString() === item.source.droppableId;
      })
      let machine = machines[newMachineIndex];
      let index = processes[machine.code].findIndex((p) => {
        return p.id.toString() === item.draggableId;
      })
      let process = processes[machine.code][index];
      setDraggingProcess(process)
    } else {
      let index = orphans.findIndex((p) => {
        return p.id.toString() === item.draggableId;
      })
      if (index > -1) {
        let process = orphans[index];
        setDraggingProcess(process)
      } else {
        let index = expiredOrphans.findIndex((p) => {
          return p.id.toString() === item.draggableId;
        })
        let process = expiredOrphans[index];
        setDraggingProcess(process)
      }
    }
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      setDraggingProcess(false)
      selectOdp(false)
      return;
    }
    if (draggingProcess.status.id !== 5 && draggingProcess.status.id !== 3) {
      dispatch(showError("Lavorazione in corso!"));
      setDraggingProcess(false)
      selectOdp(false)
      return;
    }
    let newMachineIndex = machines.findIndex((m) => {
      return m.id.toString() === result.destination.droppableId;
    })
    if (newMachineIndex > -1) {
      let newMachine = machines[newMachineIndex];
      let accept = newMachine.processTypes.map(function (pt) { return pt.code })
      if (accept.indexOf(draggingProcess.type.code) < 0) {
        dispatch(showError("Lavorazione/macchina non compatibile!"));
        setDraggingProcess(false)
        selectOdp(false)
        return;
      }
      let olds = processes[newMachine.code]
      if (olds === undefined) {
        olds = []
      }
      const items = reorder(olds, result.source.index, result.destination.index);
      let oldMachine = false
      if (result.destination.droppableId !== result.source.droppableId) {
        if (result.source.droppableId !== "ORPHAN" && result.source.droppableId !== "EXPIRED_ORPHAN") {
          let oldMachineIndex = machines.findIndex((m) => {
            return m.id.toString() === result.source.droppableId;
          })
          oldMachine = machines[oldMachineIndex];
          dispatch(enableEditing(oldMachine))
        } else if (bishopMoment(draggingProcess.deliveryDate).diff(bishopMoment()) >= 0) {
          oldMachine = { code: "ORPHAN" }
          dispatch(enableEditing(oldMachine))
        } else {
          oldMachine = { code: "EXPIRED_ORPHAN" }
          dispatch(enableEditing(oldMachine))
        }
        if (items !== null) {
          items.splice(result.destination.index, 0, draggingProcess)
        }
      }
      setDraggingProcess(false)
      selectOdp(false)
      dispatch(updateProcessMachine(newMachine, result.draggableId, items, result.destination.index, oldMachine))
    } else {
      setDraggingProcess(false)
      selectOdp(false)
    }

  }

  const handleChangeProcess = (event) => {
    let name = event.target.name;
    let value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else if ((name === "quantity" || name === "expectedSetupTime") && event.target.value !== "") {
      value = event.target.valueAsNumber;
    }
    else if (name === "expectedSetupTime" && event.target.value === "") {
      value = null;
    } else {
      value = event.target.value;
    }
    dispatch(onChangeProcess({ name: name, value: value }));
  };

  const onSelectOdp = (odp) => {
    if (selectedOdp !== false && selectedOdp.id === odp.id) {
      selectOdp(false)
    } else {
      selectOdp(odp)
    }
  }

  //se clicco su un paper vengo reindirizzato al odp corrispondente
  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (processes && machines) {
    return (
      <React.Fragment>
        <div className={myClasses.headerSpaceBetween}>
          <Typography variant="h6" id="tableTitle" component="h1">
            {customLabel("function.planner")}
          </Typography>
        </div>
        <Divider />
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {machines.map((machine) => {
            return (
              <Machine key={machine.id} machine={machine} processes={processes} openProcessDetails={openProcessDetails}
                handleClosePopoverMe={handleClosePopoverMe} handleOpenPopoverMe={handleOpenPopoverMe}
                handleClosePopoverPs={handleClosePopoverPs} handleOpenPopoverPs={handleOpenPopoverPs} editing={editing}
                draggingProcess={draggingProcess} selectOdp={onSelectOdp} selectedOdp={selectedOdp} />)
          })}

          <Machine machine={"ORPHAN"} processes={orphans} openProcessDetails={openProcessDetails} orphans={orphans} expiredOrphans={expiredOrphans}
            handleClosePopoverMe={handleClosePopoverMe} handleOpenPopoverMe={handleOpenPopoverMe}
            handleClosePopoverPs={handleClosePopoverPs} handleOpenPopoverPs={handleOpenPopoverPs} editing={editing}
            draggingProcess={draggingProcess} selectOdp={onSelectOdp} selectedOdp={selectedOdp} />

          <Machine machine={"EXPIRED_ORPHAN"} processes={expiredOrphans} openProcessDetails={openProcessDetails} orphans={orphans} expiredOrphans={expiredOrphans}
            handleClosePopoverMe={handleClosePopoverMe} handleOpenPopoverMe={handleOpenPopoverMe}
            handleClosePopoverPs={handleClosePopoverPs} handleOpenPopoverPs={handleOpenPopoverPs} editing={editing} d
            raggingProcess={draggingProcess} selectOdp={onSelectOdp} selectedOdp={selectedOdp} />

        </DragDropContext>
        {selectedProcess !== false ? (
          <Dialog open={true} keepMounted maxWidth={"lg"} fullWidth>
            <DialogTitle>{selectedProcess.code} - {customLabel("process.process")}: {selectedProcess.name}</DialogTitle>
            <ProcessForm process={selectedProcess} handleChange={handleChangeProcess} workcentres={workcentres}
              onChangeProcess={onChangeProcess} lockProcesses={selectedProcess.odp.bom.id !== null ? selectedProcess.odp.bom.lockProcesses : true} />
            <DialogActions>
              <Button onClick={settingRedirect} disabled={savingProcess} color="default">{customLabel("odp.seeOdp")}</Button>
              <Button onClick={() => dispatch(setFirst(selectedProcess))} disabled={savingProcess} color="primary">{customLabel("function.setFirst")}</Button>
              <Button onClick={() => dispatch(setLast(selectedProcess))} disabled={savingProcess} color="primary">{customLabel("function.setLast")}</Button>
              <Button onClick={saveProcess} color="primary" disabled={savingProcess || process.quantity < 0 || process.quantity === ""}>{customLabel("button.save")}</Button>
              <Button onClick={cancelProcess} color="default" disabled={savingProcess}>{customLabel("button.close")}</Button>
            </DialogActions>
          </Dialog>
        ) : null}
        {anchorPopoverPs ? (
          <PopoverLegend name={customLabel("legend.legend").toUpperCase()} list={processStatuses} open={openPopoverPs} anchorEl={anchorPopoverPs}
            openPopover={handleOpenPopoverPs} closePopover={handleClosePopoverPs}
            anchorVertical={"center"} anchorHorizontal={"right"} transformVertical={"top"} transormHorizontal={"left"} />
        ) : null}
        {anchorPopoverMe ? (
          <PopoverLegend name={customLabel("legend.legend").toUpperCase()} list={machineEvents} open={openPopoverMe} anchorEl={anchorPopoverMe}
            openPopover={handleOpenPopoverMe} closePopover={handleClosePopoverMe}
            anchorVertical={"center"} anchorHorizontal={"left"} transformVertical={"top"} transormHorizontal={"left"} />
        ) : null}
      </React.Fragment >
    );
  }
  return null;
}