import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Input, InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PaperAutocomplete from "../../utility/PaperAutocomplete";
import { customLabel } from '../../utility/customLabel';
import {
    changeCurrentCodeByEnter,
    changeCurrentConsum,
    changeCurrentConsumByEnter,
    operatorViewSelector,
    saveCompConsumAPI, saveCompWasteAPI, substituteCompAPI,
    toggleComponentDetails,
    unsetCurrentConsum,
    updateComponentAPI
} from "../slice";
import DialogOperator from "../utility/DialogOperator";
import { opViewStyles } from "../utility/customStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(2),
        },
        "& .Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
        }
    }
}));


const ComponentDialog = ({ configuration }) => {
    const classes = useStyles();
    const customClasses = opViewStyles();
    const dispatch = useDispatch();
    const [lotOpen, setLotOpen] = React.useState(false);
    const [articleOpen, setArticleOpen] = React.useState(false);
    const { flagDialog, process, odp, currentConsum, operator, machine, loading, lotList, scrapList, substituteList, forceConsum, modeStapling, articleListForced, substituteOk } = useSelector(operatorViewSelector);

    let staplingAutoComponentConsumption = odp.staplingProcesses?.every(obj => obj.autoComponentConsumption)
    let forceConfig = false
    if (configuration.forceComponent && configuration.forceWithVerifyComponent) {
        forceConfig = true
    } else if (configuration.forceComponent && !configuration.forceWithVerifyComponent) {
        if (currentConsum && currentConsum.expectedLot === "") {
            forceConfig = true
        } else {
            forceConfig = false
        }
    }

    let filteredLotList = (lotList && currentConsum) && lotList.filter(l => Number(l.qty) >= currentConsum.consumptionFactor)

    const closeDialogComponent = () => {
        dispatch(unsetCurrentConsum());
        if (currentConsum.id !== "new" && !flagDialog.flagCompWaste && !flagDialog.flagCompSubstitute) {
            dispatch(toggleComponentDetails(currentConsum.componentId))
        }
    }

    const handleChange = (event) => {
        let name = event.target.name;
        let value;
        if (name === "quantity" && event.target.value !== "") {
            value = parseFloat(event.target.value)
        } else {
            value = event.target.value;
        }
        if (event.target.type === "checkbox") {
            value = event.target.checked;
        }
        dispatch(changeCurrentConsum({ name: name, value: value }));
    };

    /**
     * 
     * @param {boolean} waste se false api consumo se true api scarto
     */
    const onClickConfirmAction = (waste, substitute) => {
        if (!waste && !substitute) {
            if (currentConsum.id === "new") {
                dispatch(saveCompConsumAPI(odp, process, machine, operator, currentConsum, odp.stapling, forceConsum, forceConfig, flagDialog.flagStart || flagDialog.flagPause || flagDialog.flagRestart || flagDialog.flagClose || flagDialog.flagOnDemand || flagDialog.flagInsertCheck ? true : false, configuration.retrieveLotInfo, configuration.insertArticleIfForcedLot))
            } else {
                dispatch(updateComponentAPI(odp, process, machine, operator, currentConsum, odp.stapling, forceConsum, forceConfig, flagDialog.flagStart || flagDialog.flagPause || flagDialog.flagRestart || flagDialog.flagClose || flagDialog.flagOnDemand || flagDialog.flagInsertCheck ? true : false, configuration.retrieveLotInfo, configuration.insertArticleIfForcedLot))
                dispatch(toggleComponentDetails(currentConsum.componentId))
            }
        } else if (waste) {
            dispatch(saveCompWasteAPI(odp, process, machine, operator, currentConsum, odp.stapling, flagDialog.flagStart || flagDialog.flagPause || flagDialog.flagRestart || flagDialog.flagClose || flagDialog.flagOnDemand || flagDialog.flagInsertCheck ? true : false, configuration.retrieveLotInfo))
        } else if (substitute) {
            dispatch(substituteCompAPI(odp, process, machine, operator, currentConsum, odp.stapling, flagDialog.flagStart || flagDialog.flagPause || flagDialog.flagRestart || flagDialog.flagClose || flagDialog.flagOnDemand || flagDialog.flagInsertCheck ? true : false, configuration.retrieveLotInfo))

        }
    }

    const renderConsumContentDialog = () => {
        return (
            <form className={classes.root} noValidate autoComplete="off">
                <FormControl disabled className={customClasses.form50}>
                    <InputLabel htmlFor="expectedLot">{customLabel("component.expectedLot")}</InputLabel>
                    <Input value={currentConsum && currentConsum.expectedLot !== null ? currentConsum.expectedLot : ""} />
                </FormControl>
                <FormControl disabled className={customClasses.form50}>
                    <InputLabel htmlFor="expectedQuantity">{customLabel("component.expectedQuantity")}</InputLabel>
                    <Input value={currentConsum && currentConsum.expectedQuantity !== null ? currentConsum.expectedQuantity : ""} />
                </FormControl>
                <FormControl disabled className={customClasses.form50}>
                    {lotList && currentConsum.lotRequired && flagDialog.flagCompConsum ? (
                        <Autocomplete
                            PaperComponent={PaperAutocomplete}
                            options={
                                configuration.hideComponentLot
                                    ? filteredLotList.filter(option => option.lot !== currentConsum.lot)
                                    : lotList.filter(option => option.lot !== currentConsum.lot)
                            }
                            freeSolo
                            getOptionLabel={option => `${option.lot} (${customLabel("component.quantity")}: ${option.qty}${option.description ? `, ${customLabel("component.description")}: ${option.description}` : ''})`}
                            filterOptions={(option) => option}
                            onChange={(event, option) => {
                                if (option?.lot != null) {
                                    dispatch(changeCurrentConsum({ name: "lot", value: option.lot }));
                                    dispatch(changeCurrentConsum({ name: "lotqty", value: option.qty }));
                                    dispatch(changeCurrentConsum({ name: "description", value: option.description }));
                                } else {
                                    dispatch(changeCurrentConsum({ name: "lot", value: "" }));
                                    dispatch(changeCurrentConsum({ name: "lotqty", value: null }));
                                    dispatch(changeCurrentConsum({ name: "description", value: "" }));
                                }
                            }}
                            onInputChange={(event) => {
                                if (configuration.enableFreeLotComponents && event !== null && event.type === "change") {
                                    dispatch(changeCurrentConsum({ name: "lotqty", value: null }));
                                    dispatch(changeCurrentConsum({ name: "lot", value: event.target.value }))
                                }
                            }}
                            inputValue={currentConsum.lot}
                            openOnFocus={true}
                            open={lotOpen}
                            onOpen={() => setLotOpen(true)}
                            onClose={() => setLotOpen(false)}
                            getOptionSelected={(option, value) => currentConsum.lotqty !== null ? option.lot === value.lot : null}
                            value={currentConsum.lotqty !== null ? currentConsum.lot : null}
                            renderInput={(params) => (
                                <TextField {...params} label={customLabel("component.lot")}
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === 'Enter') {
                                                dispatch(changeCurrentConsumByEnter(e.target.value))
                                                setLotOpen(false);
                                                e.stopPropagation();
                                            }
                                        }
                                    }} />
                            )}
                        />
                    ) : loading ? <p>{customLabel("function.loading").toUpperCase()}</p> : null}
                </FormControl>
                {(!modeStapling && !process.autoComponentConsumption) || (modeStapling && !staplingAutoComponentConsumption) ? (
                    <FormControl className={customClasses.form50} margin="normal" disabled={loading || currentConsum.endDate !== null}>
                        <InputLabel htmlFor="quantity">{customLabel("component.quantity")}</InputLabel>
                        <Input autoFocus name="quantity" value={currentConsum && currentConsum.quantity !== null && currentConsum.quantity !== "" ? parseFloat(currentConsum.quantity.toFixed(configuration.componentDecimals)) : ""} onChange={handleChange} type="number" error={currentConsum.quantity === ""} />
                    </FormControl>
                ) : null}
                {currentConsum && currentConsum.description ? <Typography component="p" variant="body1">{`${customLabel("component.description")}: ${currentConsum.description}`}</Typography> : null}
                <FormControl className={customClasses.form50}>
                    <TextField disabled={loading || currentConsum.endDate !== null}
                        variant="outlined" name="notes" label={customLabel("function.note")} multiline rows={4} onChange={handleChange}
                        value={currentConsum && currentConsum.notes !== null ? currentConsum.notes : ""}
                    />
                </FormControl>
                {((!modeStapling && !process.autoComponentConsumption) || (modeStapling && !staplingAutoComponentConsumption)) && configuration.showCloseLot ? (
                    <FormControlLabel labelPlacement="end" className={customClasses.form50} margin="normal" disabled={loading || currentConsum.endDate !== null}
                        control={
                            <Checkbox checked={currentConsum && currentConsum.close ? true : false} name="close" color="default" onChange={handleChange} />
                        }
                        label={customLabel("component.closeLot")}
                    />
                ) : null}
                {forceConsum && configuration.insertArticleIfForcedLot ? (
                    <FormControl disabled className={customClasses.form50}>
                    {articleListForced ? (
                        <Autocomplete
                            PaperComponent={PaperAutocomplete}
                            options={
                                articleListForced.filter(option => option.itemno !== currentConsum.substitute)
                            }
                            freeSolo
                                getOptionLabel={option => `${option.itemno} (${customLabel("component.quantity")}: ${option.quantity}, ${option.description ? `${customLabel("component.description")}: ${option.description}` : ''})`}
                            filterOptions={(option) => option}
                            onChange={(event, option) => {
                                if (option?.itemno != null) {
                                    dispatch(changeCurrentConsum({ name: "substitute", value: option.itemno }));
                                } else {
                                    dispatch(changeCurrentConsum({ name: "substitute", value: "" }));
                                }
                            }}
                            onInputChange={(event) => {
                                if (event !== null && event.type === "change") {
                                    dispatch(changeCurrentConsum({ name: "substitute", value: event.target.value }))
                                }
                            }}
                            inputValue={currentConsum.substitute}
                            openOnFocus={true}
                            open={articleOpen}
                            onOpen={() => setArticleOpen(true)}
                            onClose={() => setArticleOpen(false)}
                            getOptionSelected={(option, value) => option.substitute === value.itemno}
                            value={currentConsum.substitute}
                            renderInput={(params) => (
                                <TextField {...params} label={customLabel("component.substitute")}
                                    error={!substituteOk}
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === 'Enter') {
                                                dispatch(changeCurrentCodeByEnter(e.target.value))
                                                setArticleOpen(false);
                                                e.stopPropagation();
                                            }
                                        }
                                    }} />
                            )}
                        />
                    ) : loading ? <p>{customLabel("function.loading").toUpperCase()}</p> : null}
                    </FormControl>
                ) : null}
                {forceConsum && configuration.insertArticleIfForcedLot && (currentConsum.consumedQuantity === null || currentConsum.consumedQuantity === 0 ) ? <Typography component="h6" variant="h6" color="secondary">{customLabel("component.doForceConsumArticle")}</Typography> : null}
                {forceConsum && !configuration.insertArticleIfForcedLot ? <Typography component="h6" variant="h6" color="secondary">{customLabel("component.doForceConsum")}</Typography> : null}
            </form>
        )
    }
    const renderConsumActionsDialog = () => {
        return (
            <React.Fragment>
                <Button className={customClasses.dialogButton} variant="contained" color="primary" onClick={() => onClickConfirmAction(false, false)}
                    disabled={currentConsum.endDate !== null || 
                        (forceConsum && configuration.insertArticleIfForcedLot && !substituteOk) ||
                        (currentConsum.lotRequired && currentConsum.lot === "") || 
                         currentConsum.quantity === "" ||
                        (currentConsum.quantity < 0 && currentConsum.consumedQuantity == null) ||
                        (currentConsum.quantity + currentConsum.consumedQuantity < 0) || (currentConsum.lotqty !== null && currentConsum.quantity > currentConsum.lotqty) || loading} >
                    {customLabel("button.confirm")}
                </Button>
                <Button className={customClasses.dialogButton} variant="contained" color="secondary" onClick={closeDialogComponent} disabled={loading}>
                    {customLabel("button.close")}
                </Button>
            </React.Fragment>
        )
    }

    const renderWasteContentDialog = () => {
        return (
            <React.Fragment>
                <form className={classes.root} noValidate autoComplete="off">
                    <FormControl disabled className={customClasses.form50}>
                        {lotList && flagDialog.flagCompWaste ? (
                            <Autocomplete
                                PaperComponent={PaperAutocomplete}
                                options={
                                    configuration.hideComponentLot
                                        ? filteredLotList.filter(option => option.lot !== currentConsum.lot)
                                        : lotList.filter(option => option.lot !== currentConsum.lot)
                                }
                                freeSolo
                                getOptionLabel={(option) => option.lot + " (" + customLabel("component.quantity") + ": " + option.qty + ")"}
                                filterOptions={(option) => option}
                                onChange={(event, option) => {
                                    if (option?.lot != null) {
                                        dispatch(changeCurrentConsum({ name: "lot", value: option.lot }));
                                    } else {
                                        dispatch(changeCurrentConsum({ name: "lot", value: "" }));
                                    }
                                }}
                                onInputChange={(event) => {
                                    if (configuration.enableFreeLotComponents && event !== null && event.type === "change") {
                                        dispatch(changeCurrentConsum({ name: "lot", value: event.target.value }))
                                    }
                                }}
                                inputValue={currentConsum.lot}
                                openOnFocus={true}
                                open={lotOpen}
                                onOpen={() => setLotOpen(true)}
                                onClose={() => setLotOpen(false)}
                                getOptionSelected={(option, value) => currentConsum.lotqty !== null ? option.lot === value.lot : null}
                                value={currentConsum.lotqty !== null ? currentConsum.lot : null}
                                renderInput={(params) => (
                                    <TextField {...params} label={customLabel("component.lot")}
                                        inputProps={{
                                            ...params.inputProps,
                                            onKeyDown: (e) => {
                                                if (e.key === 'Enter') {
                                                    dispatch(changeCurrentConsumByEnter(e.target.value))
                                                    setLotOpen(false);
                                                    e.stopPropagation();
                                                }
                                            }
                                        }} />
                                )}
                            />
                        ) : loading ? <p>{customLabel("function.loading").toUpperCase()}</p> : null}
                    </FormControl>
                    <FormControl className={customClasses.form50} margin="normal" disabled={loading}>
                        <InputLabel htmlFor="quantity">{customLabel("component.quantity")}</InputLabel>
                        <Input autoFocus name="quantity" value={currentConsum && currentConsum.quantity !== undefined && currentConsum.quantity !== "" ? currentConsum.quantity : ""} onChange={handleChange} type="number"
                            error={currentConsum.quantity === "" || currentConsum.quantity === 0} />
                    </FormControl>
                    {configuration.scrapReasonComponents ? (
                        <FormControl className={customClasses.form50}>
                            <InputLabel htmlFor="reason">{customLabel("component.scrapReason")}</InputLabel>
                            {scrapList ? (
                                <Select name="scrapReason" value={currentConsum.scrapReason ?? ""} onChange={handleChange}>
                                    {scrapList.map((s) => {
                                        return (
                                            <MenuItem key={s.code} value={s.code}>{s.description}</MenuItem>
                                        )
                                    })}
                                </Select>
                            ) : null}
                        </FormControl>) : null}
                </form>
            </React.Fragment>
        )
    }

    const renderSubstituteContentDialog = () => {
        return (
            <React.Fragment>
                <form className={classes.root} noValidate autoComplete="off">
                    {substituteList && flagDialog.flagCompSubstitute ? (
                        <Autocomplete
                            PaperComponent={PaperAutocomplete}
                            value={currentConsum.substitute !== '' ? currentConsum.substitute : null} options={substituteList}
                            getOptionLabel={(option) => option.itemno !== undefined ? option.itemno : option}
                            getOptionSelected={(option, value) => currentConsum.substitute !== '' ? option.itemno === value : null}
                            onChange={(event, option) => {
                                if (option?.itemno != null) {
                                    dispatch(changeCurrentConsum({ name: "substitute", value: option.itemno }));
                                } else {
                                    dispatch(changeCurrentConsum({ name: "substitute", value: "" }));
                                }
                            }}
                            renderInput={(params) => (<TextField {...params} label={customLabel("component.substitute")} />)} />
                    ) : loading ? <p>{customLabel("function.loading").toUpperCase()}</p> : null}
                </form>
            </React.Fragment>
        )
    }

    const renderWasteActionsDialog = () => {
        return (
            <React.Fragment>
                <Button className={customClasses.dialogButton} variant="contained" color="primary" onClick={() => onClickConfirmAction(true, false)}
                    disabled={(currentConsum.lotRequired && currentConsum.lot === "") || currentConsum.quantity === "" || currentConsum.quantity === 0 || loading} >
                    {customLabel("button.confirm")}
                </Button>
                <Button className={customClasses.dialogButton} variant="contained" color="secondary" onClick={closeDialogComponent} disabled={loading}>
                    {customLabel("button.close")}
                </Button>
            </React.Fragment>
        )
    }

    const renderSubstituteActionsDialog = () => {
        return (
            <React.Fragment>
                <Button className={customClasses.dialogButton} variant="contained" color="primary" onClick={() => onClickConfirmAction(false, true)}
                    disabled={currentConsum.substitute === ""} >
                    {customLabel("button.confirm")}
                </Button>
                <Button className={customClasses.dialogButton} variant="contained" color="secondary" onClick={closeDialogComponent} disabled={loading}>
                    {customLabel("button.close")}
                </Button>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <DialogOperator flag={flagDialog.flagCompConsum && currentConsum ? true : false} title={customLabel("component.consumptionOfComponent") + ": " + currentConsum.componentCode} form={renderConsumContentDialog}
                actions={renderConsumActionsDialog} transparentBackround={false} />
            <DialogOperator flag={flagDialog.flagCompWaste && currentConsum ? true : false} title={customLabel("component.wasteOfComponent") + ": " + currentConsum.componentCode} form={renderWasteContentDialog}
                actions={renderWasteActionsDialog} transparentBackround={false} />
            <DialogOperator flag={flagDialog.flagCompSubstitute && currentConsum ? true : false} title={customLabel("component.substitutionOfComponent") + ": " + currentConsum.componentCode} form={renderSubstituteContentDialog}
                actions={renderSubstituteActionsDialog} transparentBackround={false} />
        </React.Fragment>
    )
}

export default ComponentDialog
