import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DialogConfirmAction from "../utility/DialogConfirmAction";
import DialogForm from '../utility/DialogForm';
import Sortable from "../utility/Sortable";
import { customLabel } from "../utility/customLabel";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import ReasonForm from './ReasonForm';
import VarForm from './VarForm';
import { changeVar, createVarAPI, deleteVarAPI, newVar, processTypesSelector, removeReasonAPI, saveReasonsAPI, selectVar, sortProcessTypeVarsAPI, updateVarAPI } from './slice';

const ProcessVarsPage = ({ processType, maintenance, loading }) => {
  const dispatch = useDispatch()
  const myClasses = mesStyles();
  const { processVar, editMode } = useSelector(processTypesSelector)
  const [sorting, setSorting] = React.useState(false);
  const [newArray, setNewArray] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [newReasons, setReasons] = React.useState([]);

  const onSort = () => {
    setNewArray(processType.vars);
    setSorting(true);
  };

  const cancelSort = () => {
    setNewArray(false);
    setSorting(false);
  };

  const doSort = () => {
    dispatch(sortProcessTypeVarsAPI(processType, newArray));
    setNewArray(false);
    setSorting(false);
  };

  const onChangeSort = (newArray) => {
    setNewArray(newArray);
  };

  const onAdd = () => {
    dispatch(newVar())
  }

  const onDelete = (processVar) => {
    setDeleting(processVar)
  }
  const cancelDelete = () => {
    setDeleting(false)
  }

  const doDelete = () => {
    dispatch(deleteVarAPI(processType, deleting));
  }

  const onEdit = (processVar) => {
    dispatch(selectVar({var:processVar,editMode:"edit"}))
  }

  const onEditReasons = (processVar) => {
    dispatch(selectVar({var:processVar,editMode:"reason"}))
  }
  const cancelEdit = () => {
    dispatch(selectVar({var:false,reason:false}))
  }

  const save = () => {
    if (processVar.id === "new") {
      dispatch(createVarAPI(processType, processVar))
    } else {
      dispatch(updateVarAPI(processType, processVar))

    }
  }

  const removeReason = (reason) => {
    dispatch(removeReasonAPI(processType, processVar, reason));
  };

  const saveReasons = () => {
    dispatch(saveReasonsAPI(processType, processVar, newReasons))
  }

  const onChange = event => {
    let name = event.target.name
    let value = event.target.value
    if (event.target.type === "checkbox") {
      value = event.target.checked
    }
    dispatch(changeVar({ name: name, value: value }));
  };

  const renderVarForm = () => {
    return (<VarForm processVar={processVar} onChange={onChange} />)
  }
  const renderReasonsForm = () => {
    return (<ReasonForm removeReason={removeReason} newReasons={newReasons} setReasons={setReasons} />)
  }

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit"> {customLabel(maintenance ? "maintenancePhase.vars" : "processType.var.vars")}</Typography>
      </div>
      <div className={myClasses.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{customLabel("processType.name")}</TableCell>
              <TableCell>{customLabel("processType.var.type")}</TableCell>
              <TableCell>{customLabel("processType.multiple")}</TableCell>
              <TableCell>{customLabel("operatorView.operatorView")}</TableCell>
              <TableCell>{customLabel("processType.operatorEdit")}</TableCell>
              <TableCell>{customLabel("processType.adminEdit")}</TableCell>
              <TableCell>{customLabel("processType.editable")}</TableCell>
              <TableCell>{customLabel("processType.serial")}</TableCell>
              <TableCell>{customLabel("processType.askArticle")}</TableCell>
              <TableCell>{customLabel("processType.askConfirmation")}</TableCell>
              <TableCell>{customLabel("processType.resettable")}</TableCell>
              <TableCell>{customLabel("processType.var.reasons")}</TableCell>
              <TableCell>{customLabel("processType.var.results")}</TableCell>
              <TableCell className={myClasses.tableCell2Buttons} align="right">
                <Button onClick={onAdd} disabled={loading} color="primary">{customLabel("button.add")}</Button>
                <Button onClick={onSort} disabled={loading} color="default">{customLabel("button.sort")}</Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processType.vars.map(c => {
              return (
                <TableRow key={c.id}>
                  <TableCell>{c.measure !== "" && c.measure !== null ? c.name + " (" + c.measure + ")" : c.name}</TableCell>
                  <TableCell>{c.type === "boolean" ? customLabel("function.boolean") : c.type === "number" ? customLabel("function.number") : customLabel("function.string")}</TableCell>
                  <TableCell>{c.multiple ? <CheckIcon /> : null}</TableCell>
                  <TableCell>{c.operatorView ? <CheckIcon /> : null}</TableCell>
                  <TableCell>{c.operatorEdit ? <CheckIcon /> : null}</TableCell>
                  <TableCell>{c.adminEdit ? <CheckIcon /> : null}</TableCell>
                  <TableCell>{c.editable ? <CheckIcon /> : null}</TableCell>
                  <TableCell>{c.serial ? <CheckIcon /> : null}</TableCell>
                  <TableCell>{c.askArticle ? <CheckIcon /> : null}</TableCell>
                  <TableCell>{c.askConfirmation ? <CheckIcon /> : null}</TableCell>
                  <TableCell>{c.resettable ? <CheckIcon /> : null}</TableCell>
                  <TableCell>{c.reasons.map(c => c.reason.name).join(", ")}</TableCell>
                  <TableCell>{c.results.map(c => c.result.name).join(", ")}</TableCell>
                  <TableCell className={myClasses.tableCell2Buttons} align="right">
                    <Button onClick={() => { onEditReasons(c) }} color="primary" disabled={loading}>{customLabel("button.reasons")}</Button>
                    <Button onClick={() => { onEdit(c) }} color="primary" disabled={loading}>{customLabel("button.edit")}</Button>
                    <Button onClick={() => { onDelete(c) }} color="secondary" disabled={loading}>{customLabel("button.delete")}</Button>
                  </TableCell>
                </TableRow>
              )
            })
            }
          </TableBody>
        </Table>

        {processVar !== false && editMode!=="reason"? (
          <DialogForm open={processVar !== false} title={customLabel(maintenance ? "maintenancePhase.var" : "processType.var.var")}
            closeDisabled={loading} saveDisabled={processVar.name === "" || processVar.type === "" || loading} close={cancelEdit}
            form={renderVarForm} save={save} />
        ) : processVar !== false && editMode==="reason"? 
          <DialogForm open={processVar !== false && !maintenance} title={customLabel("processType.var.reasons")}
          closeDisabled={loading} close={cancelEdit} form={renderReasonsForm} save={saveReasons} /> : null}

        <Dialog open={sorting} keepMounted fullWidth={true}>
          <DialogTitle>{customLabel("function.sort")}</DialogTitle>
          <DialogContent>
            {newArray !== false ? (
              <Sortable items={newArray} onChange={onChangeSort} />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={doSort} color="primary" disabled={loading}>
              {customLabel("button.confirm")}
            </Button>
            <Button onClick={cancelSort} color="default" disabled={loading}>
              {customLabel("button.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={cancelDelete} disabled={loading} />
    </PaperForm>
  )
}

export default ProcessVarsPage
