import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  selector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete, changeObject
} from "./slice";
import { Box } from "@material-ui/core";
import { Form } from "./Form";
import { Details } from "./Details";
import { customersSelector, getListAPI as getCustomersAPI, } from "../customers/slice";
import { articlesSelector, getListAPI as getArticlesAPI } from "../articles/slice";
import { configurationSelector, getAPI as getConfigurationAPI } from "../configuration/slice";
import { odpsSelector } from "../odps/slice";
import { getListAPI as getProcessTypesAPI } from "../processTypes/slice";

const CustomerOrderPage = ({ match }) => {
  const dispatch = useDispatch();
  const { customerOrder, customerOrderedit, editmode, deleting, isCreated, isDeleted, customerOrders, loading, editCustomerAlert
  } = useSelector(selector);
  const { customers } = useSelector(customersSelector);
  const { articles } = useSelector(articlesSelector);
  const { configuration, loading: loadingConf, hasErrors: errorsConf } = useSelector(configurationSelector);
  const { loading: loadingOdps } = useSelector(odpsSelector);

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    dispatch(change({ name: name, value: value, customers: customers, articles: articles }));
  };

  const handleChangeDate = (name, value) => {
    dispatch(change({ name: name, value: new Date(value.format()).getTime() }));
  };

  const toggleDelete = (event) => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(customerOrderedit));
  };

  const onEdit = () => {
    dispatch(edit());
  };

  const doDelete = () => {
    dispatch(deleteAPI(customerOrder));
  };

  const save = () => {
    if (customerOrder.id === "new") {
      dispatch(createAPI(customerOrderedit));
    } else {
      dispatch(updateAPI(customerOrderedit));
    }
  };

  if (configuration === false && !loadingConf && !errorsConf) {
    dispatch(getConfigurationAPI());
  }

  const handleChangeObject = (name, value) => {
    if (value !== false) {
      dispatch(change({ name: name, value: value, customers: customers, articles: articles }))
    }
    else {
      dispatch(changeObject({ name: name, value: false }))
    }
  };

  useEffect(() => {
    const { id } = match.params;
    dispatch(getCustomersAPI(0, 100000, "code", "asc", false, false));
    dispatch(getArticlesAPI(0, 100000, "code", "asc", false, false, false, false, false));
    dispatch(getProcessTypesAPI(0, 100000, "code", "asc", false, false));

    if (id !== "new") {
      dispatch(getAPI(id));
    } else {
      dispatch(create());
    }
  }, [dispatch, match]);

  const rendercustomerOrder = () => {
    if (customerOrder !== false && !editmode && match.params.id === customerOrder.id.toString()) {
      return (
        <div>
          <Details customerOrder={customerOrder} edit={onEdit} toggleDelete={toggleDelete}
            deleting={deleting} doDelete={doDelete} config={configuration} loading={loading || loadingOdps} />
        </div>
      );
    }
    if (customerOrder !== false && editmode) {
      return (
        <Form customers={customers} articles={articles} customerOrderedit={customerOrderedit} handleChange={handleChange} handleChangeDate={handleChangeDate}
          cancelEdit={onCancelEdit} save={save} customerOrders={customerOrders} config={configuration} handleChangeObject={handleChangeObject} loading={loading} editCustomerAlert={editCustomerAlert} />
      );
    }
    return;
  };

  if (isDeleted && match.params.id === customerOrder.id.toString()) {
    return <Redirect to={"/customerOrders/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/customerOrders/" + customerOrder.id} />;
  }

  return <Box>{rendercustomerOrder()}</Box>;
};

export default CustomerOrderPage;
