
export const customFilter = {
    showFilter: false,
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "p.odp.code",
    code: false,
    deliveryDateFrom: false,
    deliveryDateTo: false,
    startDateFrom: false,
    startDateTo: false,
    endDateFrom: false,
    endDateTo: false,
    status: false,
    maintenanceType: false,
    machine: false,
    workcentre: false,
}

export const TAB_INDEX_TIMELINE = 0;
export const TAB_INDEX_STATUS = 1;

export const COLOR_DEADLINE = " #8dd8dd";
export const COLOR_MAINTENANCE = "#ffaffa";

export const NAME_DEADLINE = "SCADENZA";
export const NAME_MAINTENANCE = "MANUTENZIONE";

export const EXTRA_LEGEND_ITEMS = [{ id: 1 + "_" + NAME_DEADLINE, name: NAME_DEADLINE, backgroundColor: COLOR_DEADLINE },
{ id: 2 + "_" + NAME_MAINTENANCE, name: NAME_MAINTENANCE, backgroundColor: COLOR_MAINTENANCE }];

