import React from "react";
import { Link } from "react-router-dom";
import {
  FormControl, InputLabel, Input, Divider, Button, Typography, TextField
} from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Autocomplete } from "@material-ui/lab";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import PaperAutocomplete from "../utility/PaperAutocomplete";

export const Form = ({ supplieredit, handleChange, cancelEdit, save, workcentres, handleChangeObject, loading }) => {
  const myClasses = mesStyles();

  const onChangeFilterAutocomplete = (name, value, reason) => {
    if (reason === "clear") {
      handleChangeObject(name, false)
    } else {
      handleChangeObject(name, value)
    }
  };
  const renderMachines = () => {
    let machines = [];
    if (workcentres && workcentres.length > 0) {
      workcentres.forEach((w) => {
        w.machines.forEach((m) => {
          if (m.external) {
            machines.push(m)
          }
        })
      })
    }
    return machines;
  };

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h3" variant="h6" color="inherit">
          {customLabel("supplier.supplier")}
        </Typography>
        <LockOpenIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="code">{customLabel("supplier.code")}</InputLabel>
          <Input autoFocus name="code" error={supplieredit.code === ""} value={supplieredit.code} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="name">{customLabel("supplier.name")}</InputLabel>
          <Input name="name" error={supplieredit.name === ""} value={supplieredit.name} onChange={handleChange}
          />
        </FormControl>
        {workcentres ? (
          <Autocomplete
            PaperComponent={PaperAutocomplete}
            style={{ display: "inline-block" }}
            className={myClasses.form50}
            value={supplieredit.machine.id !== "" && supplieredit.machine.id !== null ? supplieredit.machine : null}
            getOptionSelected={(option, value) => supplieredit.machine.id !== null && supplieredit.machine.id !== '' ? option.id === value.id : null}
            onChange={(event, value, reason) => onChangeFilterAutocomplete("machine", value, reason)}
            options={renderMachines()}
            getOptionLabel={(option) => option.code + " - " + option.name}
            id="machineAutocomplete"
            renderInput={(params) => <TextField error={supplieredit.machine.id === ""}
              {...params} label={customLabel("supplier.externalMachine")} />
            }
          />)
          : null}
        <FormControl className={myClasses.form100}>
          <TextField
            variant="outlined" name="note" label={customLabel("function.note")} multiline rows={4} value={supplieredit.note}
            onChange={handleChange}
          />
        </FormControl>
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple onClick={save}
          disabled={supplieredit.name === "" || supplieredit.code === "" || supplieredit.machine.id === "" || loading}
          color="primary"
        >
          {customLabel("button.save")}
        </Button>
        {supplieredit.id !== "new" ? (
          <Button focusRipple onClick={cancelEdit} disabled={loading}>
            {customLabel("button.cancel")}
          </Button>
        ) : (
          <Button component={Link} to={`/suppliers`} disabled={loading}>
            {customLabel("button.back")}
          </Button>
        )}
      </div>
    </PaperForm>
  );
};
