import React from "react";
import { Paper, AppBar, Tab, Tabs } from '@material-ui/core';
import { customLabel } from '../../utility/customLabel';
import { INDEX_REALTIME, INDEX_OEE, INDEX_CHECKS } from "./utility";
import ChecksDetails from "./ChecksDetails";
import OeeDetails from "./OeeDetails";
import RealTimeDetails from "./RealTimeDetails";
import { operatorViewSelector } from "../slice";
import MaintenanceVars from "./MaintenanceVars";
import { useSelector } from "react-redux";
import { opViewStyles } from "../utility/customStyles";

const RealTimePage = ({ configuration }) => {
  const customClasses = opViewStyles();
  const [tabIndex, setTabIndex] = React.useState(INDEX_REALTIME);
  const { modeMaintenance } = useSelector(operatorViewSelector);

  //sposta il tab 
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <React.Fragment>
      <Paper className={customClasses.rightPaper}>
        <AppBar position="static">
          {!modeMaintenance ? (
            <Tabs value={tabIndex} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
              <Tab label={customLabel("operatorView.realTimeData")} key={"realTimeData"} value={INDEX_REALTIME} />
              <Tab label={customLabel("operatorView.oee")} key={"oee"} value={INDEX_OEE} />
              <Tab label={customLabel("operatorView.checks")} key={"checks"} value={INDEX_CHECKS} />
            </Tabs>
          ) :
            <Tabs value={tabIndex} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
              <Tab label={customLabel("operatorView.maintenanceVars")} key={"maintenanceVars"} value={INDEX_REALTIME} />
              <Tab label={customLabel("maintenancePhase.checks")} key={"checks"} value={INDEX_CHECKS} />
            </Tabs>
          }
        </AppBar>
        {!modeMaintenance ? (
          <div style={{ maxHeight: "642px", overflow: "auto" }}>
            {tabIndex === INDEX_REALTIME ? (
              <RealTimeDetails configuration={configuration} />
            ) :
              tabIndex === INDEX_OEE ?
                <OeeDetails />
                :
                tabIndex === INDEX_CHECKS ? (
                  <ChecksDetails configuration={configuration} />
                )
                  : null}
          </div>
        ) : (
          <div style={{ maxHeight: "642px", overflow: "auto" }}>
            {tabIndex === INDEX_REALTIME ? (
              <MaintenanceVars configuration={configuration} />
            ) : tabIndex === INDEX_CHECKS ? (
              <ChecksDetails configuration={configuration} />
            ) : null}
          </div>
        )
        }
      </Paper>
    </React.Fragment>
  );
};
export default RealTimePage;