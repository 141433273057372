import React from 'react';
import { useSelector } from "react-redux";
import { configurationSelector } from "./slice";
import { customLabel } from "../utility/customLabel";
import { Tab, AppBar, Paper, Tabs, Button, Divider, Dialog, DialogTitle } from "@material-ui/core";
import { BoxForm, mesStyles } from "../utility/ultrafabStyles";
import ConfForm from "./ConfForm";
import Company from "./details/Company";
import Operator from "./details/Operator";
import Components from "./details/Components";
import General from "./details/General";
import Evolution from "./details/Evolution";
import Timeline from "./details/Timeline";
import Planner from "./details/Planner";
import Report from "./details/Report";
import { tabIndexConfig } from "./utility";
import Stapling from './details/Stapling';
import Labels from './details/Labels';

const ConfHome = ({ edit }) => {
    const [tabIndex, setTabIndex] = React.useState(0);
    const myClasses = mesStyles();
    const { configuration, editmode, loading, incremental } = useSelector(configurationSelector);

    //sposta il tab fra menu e documents
    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <BoxForm>
            <AppBar position="static">
                <Tabs value={tabIndex} onChange={handleChangeTab} style={{ backgroundColor: "white", color: "black" }}
                    variant="scrollable" scrollButtons="auto">
                    <Tab label={customLabel("configuration.company")} key={"company"} value={tabIndexConfig["company"]} />
                    <Tab label={customLabel("configuration.evolution")} key={"evolution"} value={tabIndexConfig["evolution"]} />
                    <Tab label={customLabel("configuration.general")} key={"general"} value={tabIndexConfig["general"]} />
                    <Tab label={customLabel("configuration.operatorView")} key={"operatorView"} value={tabIndexConfig["operatorView"]} />
                    <Tab label={customLabel("configuration.components")} key={"components"} value={tabIndexConfig["components"]} />
                    <Tab label={customLabel("timeline.timeline")} key={"timeline"} value={tabIndexConfig["timeline"]} />
                    <Tab label={customLabel("function.planner")} key={"planner"} value={tabIndexConfig["planner"]} />
                    <Tab label={customLabel("function.report")} key={"report"} value={tabIndexConfig["report"]} />
                    <Tab label={customLabel("stapling.stapling")} key={"stapling"} value={tabIndexConfig["stapling"]} />
                    <Tab label={customLabel("configuration.labels")} key={"labels"} value={tabIndexConfig["labels"]} />
                </Tabs>
            </AppBar>
            <div style={{ marginTop: "8px" }}></div>
            <Paper className={myClasses.confDetailsPaper}>
                {tabIndex === tabIndexConfig["company"] ? (
                    <Company configuration={configuration} />
                ) : tabIndex === tabIndexConfig["evolution"] ? (
                    <Evolution configuration={configuration} />
                )
                    : tabIndex === tabIndexConfig["general"] ? (
                        <General configuration={configuration} />
                    )
                        : tabIndex === tabIndexConfig["operatorView"] ? (
                            <Operator configuration={configuration} />
                        )
                            : tabIndex === tabIndexConfig["components"] ? (
                                <Components configuration={configuration} />
                            )
                                : tabIndex === tabIndexConfig["timeline"] ? (
                                    <Timeline configuration={configuration} />
                                )
                                    : tabIndex === tabIndexConfig["planner"] ? (
                                        <Planner configuration={configuration} />
                                    )
                                        : tabIndex === tabIndexConfig["report"] ? (
                                            <Report configuration={configuration} />
                                        )
                                            : tabIndex === tabIndexConfig["stapling"] ? (
                                                <Stapling configuration={configuration} incremental={incremental} />
                                            )
                                                : tabIndex === tabIndexConfig["labels"] ? (
                                                    <Labels configuration={configuration} />
                                                )
                                                    : null
                }
                <Divider />
                <div className={myClasses.buttonsDivBottom}>
                    <div className={myClasses.buttonsFlexRight}>
                        <Button onClick={edit} color="primary" disabled={loading}>
                            {customLabel("button.edit")}
                        </Button>
                    </div>
                </div>
            </Paper>
            <Dialog open={configuration && editmode ? true : false} keepMounted maxWidth={"lg"} fullWidth>
                <DialogTitle>{customLabel("configuration.configuration")}</DialogTitle>
                <ConfForm />
            </Dialog>
        </BoxForm>
    )
};



export default ConfHome;
