import React from "react";
import { ResponsivePie } from '@nivo/pie'
import { setColor } from "../utility/vari.js";

export const MyPie = ({ data, getColor, showArcLinkLabel }) => {

  const getPercColor = (e) => {
    if (e && e.color) {
      return (
        setColor(e.color)
      )
    } else {
      return "black"
    }
  };

  const getMargins = () => {
    if (showArcLinkLabel) {
      return { top: 40, right: 80, bottom: 80, left: 80 }
    } else {
      return { top: 20, right: 20, bottom: 20, left: 20 }
    }
  }

  return (
    <ResponsivePie data={data}
      arcLabelsTextColor={getPercColor}
      margin={getMargins()}
      innerRadius={0.4}
      padAngle={1}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      enableArcLinkLabels={showArcLinkLabel}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      colors={getColor}
      arcLabel={(e) => e.value + "%"}
      tooltip={({ datum: { id, value, color } }) => (
        <div style={{ padding: 5, background: '#fff', borderRadius: "3px" }}>
          <strong>{id}: {value}%</strong>
        </div>
      )}
    />
  );
};

