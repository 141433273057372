import React from 'react'
import { Paper, Table } from "@material-ui/core";
import { splitStyles } from "./utility/splitStyles";
import { customLabel } from "../utility/customLabel";
import RowKeyValue from './utility/RowKeyValue';

const CurrentSelection = ({ process, machine, operator, workcentre, q, configuration }) => {
    const classes = splitStyles();

    return (
        <React.Fragment>
            <Paper className={classes.selectionPaper}>
                <div className={classes.selectionDiv}>
                    <Table size={"small"}>
                        <tbody>
                            <RowKeyValue title={customLabel("operatorView.operator")} value={operator.name} newLine={true} boldValue={true} />
                            <RowKeyValue title={customLabel("workcentre.workcentre")} value={workcentre.code} newLine={true} boldValue={true} />
                            <RowKeyValue title={customLabel("machine.machine")} value={machine.code} newLine={true} boldValue={true} />
                            <RowKeyValue title={customLabel("customerOrder.customerOrder")} value={process.odp?.customerOrder?.code} newLine={true} boldValue={true} />
                            <RowKeyValue title={customLabel("customer.customer")} value={process.odp?.customer?.name} newLine={true} boldValue={true} />
                            <RowKeyValue title={customLabel("odp.odp")} value={process.odp?.code} newLine={true} boldValue={true} />
                            <RowKeyValue title={customLabel("process.quantity")} value={Number(q.toFixed(configuration.decimals))} newLine={true} boldValue={true} />
                            <RowKeyValue title={customLabel("article.article")} value={process.article?.name} newLine={true} boldValue={true} />
                        </tbody>
                    </Table>
                </div>
            </Paper>
        </React.Fragment>
    )
}

export default CurrentSelection
