import React from 'react'
import { Link } from 'react-router-dom'
import {
  Typography, FormControl, InputLabel, Input, Divider, Button, TextField
} from '@material-ui/core'
import { customLabel } from "../utility/customLabel";
import LockIcon from '@material-ui/icons/Lock';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from '../utility/DialogConfirmAction';

export const Details = ({ workcentre, edit, toggleDelete, deleting, doDelete, loading, loadingMachines }) => {
  const myClasses = mesStyles();
  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit"> {customLabel("workcentre.workcentre")}</Typography>
        <LockIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50} disabled>
          <InputLabel htmlFor="code">{customLabel("workcentre.code")}</InputLabel>
          <Input value={workcentre.code} />
        </FormControl>
        <FormControl className={myClasses.form50} disabled>
          <InputLabel htmlFor="name">{customLabel("workcentre.name")}</InputLabel>
          <Input value={workcentre.name} />
        </FormControl>
        <FormControl className={myClasses.form100} disabled>
          <TextField disabled
            variant="outlined" name="note" multiline label={customLabel("function.note")} rows={4}
            value={workcentre.note}
          />
        </FormControl>
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button disabled={loading || loadingMachines} focusRipple key={workcentre.id} onClick={edit} color="primary">{customLabel("button.edit")}</Button>
        <Button disabled={loading || loadingMachines} color="secondary" onClick={toggleDelete}>{customLabel("button.delete")}</Button>
        <Button disabled={loading || loadingMachines} component={Link} to={`/workcentres`}>{customLabel("button.back")}</Button>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={toggleDelete} disabled={loading || loadingMachines} />
    </PaperForm>
  )
}
