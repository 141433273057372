import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Popover, Typography, List, ListItem } from '@material-ui/core';
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: "none"
    },
    popoverContent: {
        pointerEvents: "auto",
        padding: "8px"
    },
    list: {
        padding: '3px 0px',
        margin: '0px 0px'
    },
    listItem: {
        padding: '1px 8px',
        margin: '0px 0px'
    },
}));
/**
 * 
 * @param {string} text 
 * @param {string} open 
 * @param {string} anchorEl 
 * @param {string} closePopover 
 * @param {string} anchorVertical 
 * @param {string} anchorHorizontal 
 * @param {string} transformVertical 
 * @param {string} transormHorizontal 
 * @returns 
 */
const PopoverLinkItem = ({ text, open, anchorEl, openPopover, closePopover, anchorVertical, anchorHorizontal, transformVertical, transormHorizontal,
    parentUrl, parentId, changeUrl }) => {

    const classes = useStyles()
    return (
        <div>
            {text ? (
                <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: anchorVertical,
                        horizontal: anchorHorizontal,
                    }}
                    transformOrigin={{
                        vertical: transformVertical,
                        horizontal: transormHorizontal,
                    }}
                    onClose={closePopover}
                    disableRestoreFocus
                    PaperProps={{ onMouseEnter: (e) => openPopover(e.currentTarget, anchorEl, text, parentId, parentUrl), onMouseLeave: closePopover }}

                >
                    {parentId && parentUrl ? (
                        <List className={classes.list}>
                            <ListItem className={classes.listItem} button onClick={() => changeUrl(parentUrl)} component={Link} to={`/${parentUrl}/${parentId}`}>
                                <Typography variant="body1">
                                    <b>{text}</b>
                                </Typography>
                            </ListItem>

                        </List>
                    ) : (
                        <List className={classes.list}>
                            <ListItem className={classes.listItem}>
                                <Typography variant="body1">
                                    <b>{text}</b>
                                </Typography>
                            </ListItem>
                        </List>
                    )}
                </Popover>
            ) : null}
        </div>
    );
}

export default PopoverLinkItem;