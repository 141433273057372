import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../../constants";
import { showError, showSuccess } from "../../notifications/slice";
import { logout, refreshAPI } from "../../main/slice";
import { customLabel } from "../../utility/customLabel";

export const initialState = {
    loading: false,
    reloading: false,//usato per i bottoni di avvia, pausa, ferma, evento macchina
    hasErrors: false,
    odps: false,
    total: 0,
    reload: false,
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "p.odp.code",
    code: "",
    visible: false,
    customerOrder: "",
    customer: false,
    article: false,
    state: false,
    deliveryDateFrom: false,
    deliveryDateTo: false,
    currentOdp: false,
    currentProcess: false,
    processErrors: false, // variabile utilizzata per ricaricare gli odp nel caso di errori su avvia, pausa, ferma, evento macchina
    listVarFlag: false, // fase 1 inserimento variabile
    insertVarFlag: false,
    choosenVar: false, // fase 2 inserimento variabile
    currentVar: false,
};

const manageOdpsSlice = createSlice({
    name: "manageOdps",
    initialState,
    reducers: {
        enableErrors: (state) => {
            state.hasErrors = true;
            state.loading = false;
            state.reloading = false;
        },
        loadingOdps: (state) => {
            state.loading = true;
            state.hasErrors = false;
            state.reload = false;
        },
        enableLoading: (state) => {
            state.loading = true;
            state.hasErrors = false;
        },
        enableReloading: (state) => {
            state.reloading = true;
            state.hasErrors = false;
            state.processErrors = false;
        },
        loadOdpsSuccess: (state, { payload }) => {
            state.loading = false;
            state.processErrors = false;
            state.odps = payload[1];
            state.total = payload[0].total_entries;
            state.reload = false;
            //se currentOdp è settato viene aggiornato con il nuovo valore
            if (state.currentOdp) {
                let oldId = state.currentOdp.id;
                const indexOfOdp = state.odps.findIndex((i) => {
                    return i.id.toString() === oldId.toString();
                });
                if (indexOfOdp !== -1) {
                    state.currentOdp = state.odps[indexOfOdp];
                } else {
                    state.odp = false;
                }
            }
            //se currentProcess è settato viene aggiornato con il nuovo valore
            if (state.currentProcess && state.listVarFlag) {
                let oldProcessId = state.currentProcess.id;
                const indexOfProcess = state.currentOdp.processes.findIndex((i) => {
                    return i.id.toString() === oldProcessId.toString();
                });
                if (indexOfProcess !== -1) {
                    state.currentProcess = state.currentOdp.processes[indexOfProcess];
                } else {
                    state.currentProcess = false;
                }
            }
        },
        setReload: (state) => {
            state.reload = true;
        },
        changeFilter: (state, { payload }) => {
            state[payload.name] = payload.value;
        },
        initFilter: (state) => {
            state.page = 0;
            state.rowsPerPage = 10;
            state.order = "asc";
            state.orderBy = "p.odp.code";
            state.code = "";
            state.visible = false;
            state.customerOrder = "";
            state.customer = false;
            state.article = false;
            state.state = false;
        },
        setCurrentOdp: (state, { payload }) => {
            state.currentOdp = payload;
        },
        unSetCurrentOdp: (state) => {
            state.currentOdp = false;
        },
        setCurrentProcess: (state, { payload }) => {
            state.currentProcess = payload;
        },
        unSetCurrentProcess: (state) => {
            state.currentProcess = false;
        },
        //imposta reload a true per effetturare la chiamata getListAPI
        updateProcessOperator: (state) => {
            state.reloading = false;
            state.reload = true;
        },
        //imposta a true processErrors per ricaricare gli odp
        enableProcessErrors: (state) => {
            state.reloading = false;
            state.hasErrors = true;
            state.processErrors = true;
        },
        changeCurrentVar: (state, { payload }) => {
            state.currentVar = payload.value;
        },
        enableListVarFlag: (state) => {
            state.listVarFlag = true;
        },
        disableListVarFlag: (state) => {
            state.listVarFlag = false;
        },
        enableInsertVarFlag: (state, { payload }) => {
            state.listVarFlag = false;
            state.insertVarFlag = true;
            state.currentVar = null;
            state.choosenVar = payload;
        },
        disableInsertVarFlag: (state) => {
            state.listVarFlag = true;
            state.insertVarFlag = false;
            state.choosenVar = false;
            state.currentVar = false;
        },
        varInsertedSuccess: (state) => {
            state.reloading = false;
            state.currentVar = false;
            state.insertVarFlag = false;
            state.listVarFlag = true;
            state.reload = true;
            state.choosenVar = false;
        },
        enableVarErrors: (state) => {
            state.hasErrors = true;
            state.reloading = false;
            state.insertVarFlag = false;
            state.listVarFlag = true;
            state.currentVar = false;
            state.reload = true;
            state.choosenVar = false;
        }
    },
});

export const { enableErrors, loadingOdps, enableLoading, loadOdpsSuccess, setReload, initFilter, changeFilter, setCurrentOdp, unSetCurrentOdp, setCurrentProcess,
    unSetCurrentProcess, updateProcessOperator, enableReloading, enableProcessErrors, enableListVarFlag, disableListVarFlag,
    enableInsertVarFlag, disableInsertVarFlag, changeCurrentVar, varInsertedSuccess, enableVarErrors } = manageOdpsSlice.actions;

export const manageOdpsSelector = (state) => state.manageOdps;

export default manageOdpsSlice.reducer;

export function getListAPI(page, rowsPerPage, orderBy, order, code, customerOrder, customer, status, article,
    deliveryDateFrom, deliveryDateTo) {
    let access_token = "";
    if (localStorage.getItem("bishop_current_user") != null) {
        access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
            .access_token;
    }
    return async (dispatch) => {
        dispatch(loadingOdps());
        let url = SERVER_URL + "/api/odp/available?page=" + page + "&per_page=" + rowsPerPage + "&orderBy=" + orderBy + "&order=" + order
        url = url + "&code=" + code
        url = url + "&customerOrder=" + customerOrder

        if (customer) {
            url = url + "&customer=" + customer.id
        }
        if (status) {
            url = url + "&status=" + status.id
        }
        if (article) {
            url = url + "&article=" + article.id
        }
        if (deliveryDateFrom) {
            url = url + "&deliveryDateFrom=" + deliveryDateFrom
        }
        if (deliveryDateTo) {
            url = url + "&deliveryDateTo=" + deliveryDateTo
        }

        const response = await fetch(url, {
            mode: "cors",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + access_token,
            },
        });

        try {
            const data = await response.json();
            if (response.status === 200) {
                dispatch(loadOdpsSuccess(data));
            } else if (response.status === 403) {
                dispatch(logout());
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors());
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    };
}
export function startProcessAPI(odpId, processId, username, machineId) {
    return async dispatch => {
        dispatch(enableReloading());
        let params = { user: { username: username }, machine: { id: machineId } }
        const response = await fetch(SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/start", {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(params)
        });

        const data = await response.json()
        try {
            if (response.status === 200) {
                dispatch(updateProcessOperator(data));
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableProcessErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableProcessErrors());
        }
    }
};
export function pauseProcessAPI(odpId, processId, username, machineId) {
    return async dispatch => {
        dispatch(enableReloading());
        let params = { user: { username: username }, machine: { id: machineId } }
        const response = await fetch(SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/pause/", {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(params)
        });

        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(updateProcessOperator(data));
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableProcessErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableProcessErrors());
        }
    }
};
export function stopProcessAPI(odpId, processId, username, machineId) {
    return async dispatch => {
        dispatch(enableReloading());
        let params = { user: { username: username }, machine: { id: machineId } }
        const response = await fetch(SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/stop/", {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(params)
        });

        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(updateProcessOperator(data));
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableProcessErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableProcessErrors());
        }
    }
};
export function addMachineEventAPI(process, operator, machineEventType, machine) {
    return async dispatch => {
        dispatch(enableReloading());
        let params = { process: process, user: operator, eventType: machineEventType, machine: machine }
        const response = await fetch(SERVER_URL + "/machineEvent/", {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(params)
        });
        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(updateProcessOperator(data));
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableProcessErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableProcessErrors());
        }
    }
};
export function removeMachineEventAPI(machineEventId) {
    return async dispatch => {
        dispatch(enableReloading());
        const response = await fetch(SERVER_URL + "/machineEvent/" + machineEventId, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
        });
        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(updateProcessOperator(data));
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableProcessErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableProcessErrors());
        }
    }
};
export function insertVarsAPI(odpId, processId, varId, value, username, machineId) {
    return async dispatch => {
        dispatch(enableReloading());
        let params = { value: value, user: { username: username }, machine: { id: machineId } }
        const response = await fetch(SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/var/" + varId, {
            mode: 'cors',
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(params)
        });

        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(varInsertedSuccess());
                dispatch(showSuccess("function.operationSuccess"));
            } else {
                dispatch(enableVarErrors());
                dispatch(showError(data.message));
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableVarErrors());
        }
    }
};
