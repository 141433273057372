import React from "react";
import { Link } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab"
import { FormControl, InputLabel, Input, Divider, Button, Typography, TextField, MenuItem, Select } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import PaperAutocomplete from "../utility/PaperAutocomplete";

export const Form = ({ articleedit, handleChange, handleChangeObject, cancelEdit, save, boms, loading }) => {
  const myClasses = mesStyles();

  const onChangeFilterAutocomplete = (name, value, reason) => {
    if (reason === "clear") {
      handleChangeObject(name, false)
    } else {
      handleChangeObject(name, value.id)
    }
  };

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h3" variant="h6" color="inherit">
          {customLabel("article.article")}
        </Typography>
        <LockOpenIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="code">{customLabel("article.code")}</InputLabel>
          <Input autoFocus name="code" error={articleedit.code === ""} value={articleedit.code} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form50} margin="normal" >
          <InputLabel htmlFor="extcode">{customLabel("article.extCode")}</InputLabel>
          <Input name="extCode" value={articleedit.extCode !== null ? articleedit.extCode : ""} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="name">{customLabel("article.name")}</InputLabel>
          <Input name="name" error={articleedit.name === ""} value={articleedit.name} onChange={handleChange}
          />
        </FormControl>
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="name">{customLabel("article.type.type")}</InputLabel>
          <Select name="type" value={articleedit.type ? articleedit.type : ""} onChange={handleChange} error={articleedit.type === ""}>
            <MenuItem value=""></MenuItem>
            <MenuItem value="F">{customLabel("article.type.F")}</MenuItem>
            <MenuItem value="SF">{customLabel("article.type.SF")}</MenuItem>
            <MenuItem value="RM">{customLabel("article.type.RM")}</MenuItem>
          </Select>
        </FormControl>
        {boms ? (
          <Autocomplete
            PaperComponent={PaperAutocomplete}
            style={{ display: "inline-block" }}
            className={myClasses.form50}
            value={articleedit.bom.id !== "" && articleedit.bom.id !== null ? articleedit.bom : null}
            getOptionSelected={(option, value) => articleedit.bom.id !== null && articleedit.bom.id !== '' ? option.id === value.id : null}
            onChange={(event, value, reason) => onChangeFilterAutocomplete("bom.id", value, reason)}
            options={boms}
            getOptionLabel={(option) => option.code + " - " + option.name}
            id="bomAutocomplete"
            renderInput={(params) => <TextField {...params} label={customLabel("bom.bom")} />
            }
          />)
          : null}
        <FormControl className={myClasses.form50}>
          <InputLabel htmlFor="measure">{customLabel("article.measureUnit")}</InputLabel>
          <Input name="measure" error={articleedit.measure === ""} value={articleedit.measure} onChange={handleChange} />
        </FormControl>
        <FormControl className={myClasses.form100}>
          <TextField
            variant="outlined" name="note" label={customLabel("function.note")} multiline rows={4} value={articleedit.note ? articleedit.note : ""}
            onChange={handleChange}
          />
        </FormControl>
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple onClick={save} disabled={articleedit.name === "" || articleedit.code === "" || articleedit.measure === "" || articleedit.type === "" || loading}
          color="primary"
        >
          {customLabel("button.save")}
        </Button>
        {articleedit.id !== "new" ? (
          <Button focusRipple onClick={cancelEdit} disabled={loading}>
            {customLabel("button.cancel")}
          </Button>
        ) : (
          <Button component={Link} to={`/articles`} disabled={loading}>
            {customLabel("button.back")}
          </Button>
        )}
      </div>
    </PaperForm>
  );
};
