import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../constants";
import { showError, showSuccess } from "../notifications/slice";
import _ from 'lodash';
import { customFilter } from "./utility";

export const initialState = {
  loading: false,
  errors: false,
  maintener: false,
  currentInput: '',
  odps: false,
  extOdps: false,
  odp: false,
  check: false,
  checkDefs: false,
  filter: false,
  reload: false
};
const maintenerViewSlice = createSlice({
  name: "maintenerView",
  initialState,
  reducers: {
    enableLoading: (state) => {
      state.loading = true;
      state.errors = false;
    },
    enableErrors: (state) => {
      state.errors = false;
    },
    resetErrors: (state) => {
      state.errors = false;
    },
    changeCurrentInput: (state, { payload }) => {
      state.currentInput = payload
    },
    changeOperator: (state, { payload }) => {
      state.loading = false;
      const roles = [...payload.roles];
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].maintenance) {
          state.maintener = payload
          i = roles.length;
        }
      }
    },
    fillOdps: (state, { payload }) => {
      state.odps = payload
    },
    fillExtOdps: (state, { payload }) => {
      state.extOdps = payload
    },
    create: (state) => {
      state.odp = { "id": '', "machine": { "id": '' }, "note": '' }
    },
    handleChange: (state, { payload }) => {
      if (payload.name === "machine.id") {
        if (!payload.value) {
          state.odp["machine"] = { "id": '' }
        } else {
          let lista = payload.machines.slice();
          let indexOfMts = lista.findIndex((l) => {
            return l.id === payload.value
          });
          state.odp["machine"] = lista[indexOfMts]
        }
      } else {
        state.odp[payload.name] = payload.value;
      }
    },
    cancelCreate: (state) => {
      state.odp = false
    },
    createSuccess: (state, { payload }) => {
      state.extOdps.unshift(payload)
      state.odp = payload
    },
    updateSuccess: (state, { payload }) => {
      let ms = _.cloneDeep(state.extOdps)
      let index = ms.findIndex(v => {
        return v.id === payload.id;
      });
      ms[index] = payload
      state.extOdps = [...ms]
    },
    select: (state, { payload }) => {
      state.odp = payload
    },
    addCheck: (state) => {
      state.check = { id: '', name: '', notes: '' }
    },
    cancelAddCheck: (state) => {
      state.check = false
    },
    selectCheck: (state, { payload }) => {
      state.check = payload
    },
    handleChangeCheck: (state, { payload }) => {
      state.check[payload.name] = payload.value;
    },
    updateCheckSuccess: (state, { payload }) => {
      state.odp = payload.odp;
      state.checkDefs = payload.checkDefs
      state.check = false
    },
    createCheckSuccess: (state, { payload }) => {
      state.odp = payload.odp;
      state.checkDefs = payload.checkDefs
      state.check = false
    },
    fillCheckDefs: (state, { payload }) => {
      state.checkDefs = payload
    },
    removeOdp: (state, { payload }) => {
      if (payload.ext) {
        let ms = _.cloneDeep(state.extOdps)
        let index = ms.findIndex(v => {
          return v.id === payload.process.id;
        });
        state.extOdps = [...ms.filter((_, i) => i !== index)]
      } else {
        let ms = _.cloneDeep(state.odps)
        let index = ms.findIndex(v => {
          return v.id === payload.process.id;
        });
        state.odps = [...ms.filter((_, i) => i !== index)]
      }
    },
    changeFilter: (state, { payload }) => {
      state.filter[payload.name] = payload.value;
    },
    initFilter: (state, { payload }) => {
      let tempFilter = { ...customFilter };
      tempFilter["showFilter"] = payload;
      state.filter = { ...tempFilter };
    },
    setReload: (state) => {
      state.reload = true;
    },
  },
});

export const { enableLoading, enableErrors, resetErrors, changeCurrentInput, changeOperator, fillOdps, handleChange, cancelCreate,
  createSuccess, create, fillExtOdps, select, updateSuccess, selectCheck, addCheck, cancelAddCheck, handleChangeCheck, updateCheckSuccess, createCheckSuccess,
  fillCheckDefs, removeOdp, changeFilter, initFilter, setReload
} = maintenerViewSlice.actions;
export const maintenerViewSelector = (state) => state.maintenerView;
export default maintenerViewSlice.reducer;

export function getOperatorAPI(code) {
  return async (dispatch) => {
    dispatch(enableLoading())
    const response = await fetch(SERVER_URL + "/user/code/" + code, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    const data = await response.json();
    if (response.status === 200) {
      dispatch(changeOperator(data));
    } else {
      dispatch(showError(data.message));
      dispatch(enableErrors());
    }
  };
};

//Chiamata in get che restituisce le manutenzioni da fare in base all'utente
export function getUserOdpsAPI(user, maintenance, extraordinary, filter) {
  return async (dispatch) => {
    dispatch(enableLoading())
    let url = SERVER_URL + "/user/" + user.id + "/odp?maintenance=" + maintenance + "&extraordinary=" + extraordinary
    if (filter?.machine) {
      url = url + "&machine=" + filter.machine.id
    }
    if (filter?.maintenanceType) {
      url = url + "&maintenanceType=" + filter.maintenanceType.id
    }
    if (filter?.deliveryDateFrom) {
      url = url + "&deliveryDateFrom=" + filter.deliveryDateFrom
    }
    if (filter?.deliveryDateTo) {
      url = url + "&deliveryDateTo=" + filter.deliveryDateTo
    }
    if (filter?.dateCreatedFrom) {
      url = url + "&deliveryDateFrom=" + filter.dateCreatedFrom
    }
    if (filter?.dateCreatedTo) {
      url = url + "&deliveryDateTo=" + filter.dateCreatedTo
    }
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    const data = await response.json();
    if (response.status === 200) {
      if (extraordinary) {
        dispatch(fillExtOdps(data));
      } else {
        dispatch(fillOdps(data));
      }
    } else {
      dispatch(enableErrors());
      dispatch(showError(data.message));
    }
  };
};

//Chiamata in get che restituisce i check defs già creati
export function getCheckDefsAPI() {
  return async (dispatch) => {
    dispatch(enableLoading())
    const response = await fetch(SERVER_URL + "/maintenance/check", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    const data = await response.json();
    if (response.status === 200) {
      dispatch(fillCheckDefs(data));
    } else {
      dispatch(enableErrors());
      dispatch(showError(data.message));
    }
  };
};

export function createAPI(odp) {
  return async (dispatch) => {
    dispatch(enableLoading());
    let params = { odp: odp };
    const response = await fetch(SERVER_URL + "/extraordinaryMaintenance", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors())
      }
    } catch (e) {
      dispatch(showError(e.message));
      dispatch(enableErrors())
    }
  };
}
export function updateAPI(odp) {
  return async (dispatch) => {
    dispatch(enableLoading());
    let params = { odp: odp };
    const response = await fetch(SERVER_URL + "/extraordinaryMaintenance/" + odp.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors())
      }
    } catch (e) {
      dispatch(showError(e.message));
      dispatch(enableErrors())
    }
  };
}
export function createCheckAPI(odp, check) {
  return async (dispatch) => {
    dispatch(enableLoading());
    let params = { check: check };
    const response = await fetch(SERVER_URL + "/extraordinaryMaintenance/" + odp.id + "/check/", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createCheckSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors())
      }
    } catch (e) {
      dispatch(showError(e.message));
      dispatch(enableErrors())
    }
  };
}
export function updateCheckAPI(odp, check) {
  return async (dispatch) => {
    dispatch(enableLoading());
    let params = { check: check };
    const response = await fetch(SERVER_URL + "/extraordinaryMaintenance/" + odp.id + "/check/" + check.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateCheckSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors())
      }
    } catch (e) {
      dispatch(showError(e.message));
      dispatch(enableErrors())
    }
  };
}

export function startAPI(p, maintener, ext) {
  return async dispatch => {
    dispatch(enableLoading());
    let params = { user: { id: maintener.id }, machine: { id: p.machine.id } }
    const response = await fetch(SERVER_URL + "/odp/" + p.odp.id + "/process/" + p.id + "/start", {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params)
    });

    const data = await response.json()
    if (response.status === 200) {
      dispatch(removeOdp({ ext: ext, process: data }))
      dispatch(showSuccess("function.operationSuccess"));
    } else {
      dispatch(showError(data.message));
    }
  }
};