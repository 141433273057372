import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { customLabel } from "../utility/customLabel";
import { bomsSelector, getAPI, createBomProcessAPI, deleteBomProcessAPI, updateBomProcessAPI, newBomProcess, selectBomProcess, changeBomProcess, changeBomProcessObject, addArticlesAPI, removeArticleAPI, sortBomProcessesAPI } from './slice'
import { processTypesSelector, getListAPI as getProcessTypes } from "../processTypes/slice";
import { articlesSelector, getListAPI as getArticles } from "../articles/slice";
import classnames from 'classnames';
import {
  Typography, Button, TextField, Table, TableBody, TableCell, TableHead, TableRow,
  Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, Select,
  MenuItem, FormControl, FormControlLabel, Checkbox
} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import { Autocomplete } from "@material-ui/lab"
import Sortable from "../utility/Sortable";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import PaperAutocomplete from '../utility/PaperAutocomplete';

const BomProcessesPage = ({ bom }) => {
  const dispatch = useDispatch()
  const myClasses = mesStyles();
  const { loading, reload, bomProcess } = useSelector(bomsSelector)
  const { processTypes } = useSelector(processTypesSelector);
  const { articles } = useSelector(articlesSelector);
  const [deleting, setDeleting] = React.useState(false);
  const [addingArticles, setAddingArticles] = React.useState(false);
  const [al, setAl] = React.useState([]);
  const [sorting, setSorting] = React.useState(false);
  const [newArray, setNewArray] = React.useState(false);

  useEffect(() => {
    dispatch(getProcessTypes(0, 100000, "name", "asc", false, false));
    dispatch(getArticles(0, 100000, "code", "asc", false, false, false, false, false));
  }, [dispatch]);

  useEffect(() => {
    if (reload === true && loading === false) {
      dispatch(getAPI(bom.id));
    }

  }, [dispatch, reload, loading, bom])

  const onSort = () => {
    setNewArray(bom.bomProcesses);
    setSorting(true);
  };

  const cancelSort = () => {
    setNewArray(false);
    setSorting(false);
  };

  const doSort = () => {
    dispatch(sortBomProcessesAPI(bom, newArray));
    setNewArray(false);
    setSorting(false);
  };

  const onChangeSort = (newArray) => {
    setNewArray(newArray);
  };

  const onAdd = () => {
    dispatch(newBomProcess())
  }

  const onDelete = (bomProcess) => {
    setDeleting(bomProcess)
  }
  const cancelDelete = () => {
    setDeleting(false)
  }

  const doDelete = () => {
    dispatch(deleteBomProcessAPI(bom, deleting));
  }

  const onAddArticle = (bomProcess, availableArticles) => {
    setAddingArticles({ b: bomProcess, a: availableArticles })
  }

  const cancelAddArticles = () => {
    setAddingArticles(false)
  }

  const confirmAddArticles = () => {
    dispatch(addArticlesAPI(bom, addingArticles.b, al));
    setAddingArticles(false)
  }

  const onEdit = (bomProcess) => {
    dispatch(selectBomProcess(bomProcess))
  }
  const cancelEdit = () => {
    dispatch(selectBomProcess(false))
  }

  const save = () => {
    if (bomProcess.id === "new") {
      dispatch(createBomProcessAPI(bom, bomProcess))
    } else {
      dispatch(updateBomProcessAPI(bom, bomProcess))

    }
  }

  const handleChangeObject = (name, value) => {
    if (value !== false) {
      dispatch(changeBomProcess({ name: name, value: value, processTypes: processTypes }))
    }
    else {
      dispatch(changeBomProcessObject({ name: name, value: false }))
    }
  };

  const onChangeFilterAutocomplete = (name, value, reason) => {
    if (reason === "clear") {
      handleChangeObject(name, false)
    } else {
      handleChangeObject(name, value.id)
    }
  };

  const onChange = event => {
    let name = event.target.name
    let value = event.target.value
    if (event.target.type === "checkbox") {
      value = event.target.checked
    }
    dispatch(changeBomProcess({ name: name, value: value }));
  };

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit"> {customLabel("bom.bomProcess.bomProcesses")}</Typography>
      </div>
      <div className={myClasses.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={myClasses.tableCellArticle}>{customLabel("bom.bomProcess.processType")}</TableCell>
              <TableCell className={myClasses.tableCellArticle}>{customLabel("bom.bomProcess.articles")}</TableCell>
              <TableCell className={myClasses.tableCellArticle}>{customLabel("bom.bomProcess.optional")}</TableCell>
              <TableCell className={myClasses.tableCellArticle}>{customLabel("bom.bomProcess.repeatable")}</TableCell>
              <TableCell className={myClasses.tableCellArticle}>{customLabel("bom.bomProcess.nextMode")}</TableCell>
              <TableCell className={classnames(myClasses.tableCellArticle, myClasses.tableCell2Buttons)} align="right">
                <Button onClick={onSort} disabled={loading} color="default">{customLabel("button.sort")}</Button>
                <Button onClick={onAdd} disabled={loading} color="primary">{customLabel("button.add")}</Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bom.bomProcesses.map(b => {
              if (articles !== false) {
                const availableArticles = articles.filter((a) =>
                  b.articles.every((ba) => a.id !== ba.article.id)
                );
                return (
                  <TableRow key={b.id}>
                    <TableCell className={myClasses.tableCellArticle}>{b.processType.name} - {b.processType.code}</TableCell>
                    <TableCell className={myClasses.tableCellArticle}>
                      <Table size={"small"}>
                        <TableHead>
                          <TableRow>
                            <TableCell>{customLabel("article.code")}</TableCell>
                            <TableCell>{customLabel("article.name")}</TableCell>
                            <TableCell align="right" className={myClasses.tableCell1Button}>
                              <Button onClick={() => { onAddArticle(b, availableArticles) }} disabled={loading} color="primary">{customLabel("button.add")}</Button>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {b.articles.map(a => {
                            return (
                              <TableRow key={a.id}>
                                <TableCell>{a.article.code}</TableCell>
                                <TableCell>{a.article.name}</TableCell>
                                <TableCell align="right" className={myClasses.tableCell1Button}>
                                  <Button onClick={() => { dispatch(removeArticleAPI(bom, b, a)) }} disabled={loading} color="secondary">{customLabel("button.remove")}</Button>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableCell>
                    <TableCell className={myClasses.tableCellArticle}>{b.optional ? <CheckIcon /> : null}</TableCell>
                    <TableCell className={myClasses.tableCellArticle}>{b.repeatable ? <CheckIcon /> : null}</TableCell>
                    <TableCell className={myClasses.tableCellArticle}>{b.nextMode !== "" ? customLabel("bom.bomProcess." + b.nextMode) : null}</TableCell>
                    <TableCell className={classnames(myClasses.tableCellArticle, myClasses.tableCell2Buttons)} align="right">
                      <Button onClick={() => { onEdit(b) }} disabled={loading} color="primary">{customLabel("button.edit")}</Button>
                      <Button onClick={() => { onDelete(b) }} disabled={loading} color="secondary">{customLabel("button.delete")}</Button>
                    </TableCell>
                  </TableRow>
                )
              }
              return null;
            })
            }
          </TableBody>
        </Table>
        <Dialog open={sorting} keepMounted fullWidth={true}>
          <DialogTitle>
            {customLabel("bom.sort")}
          </DialogTitle>
          <DialogContent>
            {newArray !== false ? (
              <Sortable items={newArray} onChange={onChangeSort} />
            ) : null}
            {bom.bomProcesses && bom.bomProcesses.some((b) => b.articles && b.articles.length > 0) ?
              <Typography component="p" variant="body1" className={myClasses.alertMessage}>
                {customLabel("bom.alertEdit")}
              </Typography>
              : null
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={doSort} color="primary" disabled={loading}>
              {customLabel("button.confirm")}
            </Button>
            <Button onClick={cancelSort} color="default" disabled={loading}>
              {customLabel("button.close")}
            </Button>
          </DialogActions>
        </Dialog>
        {bomProcess !== false ? (
          <Dialog open={bomProcess !== false} keepMounted maxWidth={"sm"} fullWidth={true}>
            <DialogTitle>{customLabel("bom.bomProcess.bomProcess")}</DialogTitle>
            <DialogContent>
              <form className={myClasses.dialogForm} noValidate autoComplete="off">
                {processTypes ? (
                  <Autocomplete
                    PaperComponent={PaperAutocomplete}
                    className={myClasses.form100}
                    value={bomProcess.processType.id !== "" && bomProcess.processType.id !== null ? bomProcess.processType : null}
                    getOptionSelected={(option, value) => bomProcess.processType.id !== null && bomProcess.processType.id !== '' ? option.id === value.id : null}
                    onChange={(event, value, reason) => onChangeFilterAutocomplete("processType.id", value, reason)}
                    options={processTypes}
                    getOptionLabel={(option) => option.code + " - " + option.name}
                    id="bomProcessAutocomplete"
                    renderInput={(params) => <TextField {...params} label={customLabel("processType.processTypes")} />
                    }
                  />)
                  : null}
                <FormControl margin="normal" fullWidth>
                  <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="optional" onChange={onChange} checked={bomProcess.optional} />}
                    label={customLabel("bom.bomProcess.optional")} />
                </FormControl>
                <FormControl margin="normal" fullWidth>
                  <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="repeatable" onChange={onChange} checked={bomProcess.repeatable} />}
                    label={customLabel("bom.bomProcess.repeatable")} />
                </FormControl>
                {bom.lockProcesses ?
                  <FormControl margin="normal" fullWidth className={myClasses.form100}>
                    <InputLabel htmlFor="nextMode">{customLabel("bom.bomProcess.nextMode")}</InputLabel>
                    <Select name="nextMode" value={bomProcess.nextMode} onChange={onChange}>
                      <MenuItem value="">{customLabel("function.none")}</MenuItem>
                      <MenuItem value="open">{customLabel("bom.bomProcess.open")}</MenuItem>
                      <MenuItem value="close">{customLabel("bom.bomProcess.close")}</MenuItem>
                    </Select>
                  </FormControl>
                  : null}
              </form>
              {bomProcess.id === "new" && bom.bomProcesses && bom.bomProcesses.some((b) => b.articles && b.articles.length > 0) ? (
                <Typography component="p" variant="body1" className={myClasses.alertMessage} style={{ marginTop: "16px" }}>
                  {customLabel("bom.alertEdit")}
                </Typography>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={save} color="primary" disabled={bomProcess.processType.id === "" || loading} >{customLabel("button.save")}</Button>
              <Button onClick={cancelEdit} color="default" disabled={loading}>{customLabel("button.close")}</Button>
            </DialogActions>
          </Dialog>
        ) : null}
        {addingArticles !== false ? (
          <Dialog open={addingArticles !== false} keepMounted maxWidth={"sm"} fullWidth={true}>
            <DialogTitle>{customLabel("bom.bomProcess.articles")}</DialogTitle>
            <DialogContent>
              <Autocomplete
                PaperComponent={PaperAutocomplete}
                value={al}
                onChange={(event, newValue) => {
                  setAl(newValue);
                }}
                multiple
                options={addingArticles.a}
                getOptionLabel={(option) => option.code + " - " + option.name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={customLabel("article.articles")}
                  />
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={confirmAddArticles} color="primary" disabled={al.length === 0 || loading} >{customLabel("button.save")}</Button>
              <Button onClick={cancelAddArticles} disabled={loading} color="default">{customLabel("button.close")}</Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </div>

      <Dialog open={deleting ? true : false} keepMounted maxWidth={"sm"} fullWidth={true}>
        <DialogTitle>{customLabel("function.confirm").toUpperCase()}</DialogTitle>
        <DialogContent>
          {customLabel("function.confirmDelete")}
          {bom.bomProcesses && bom.bomProcesses.some((b) => b.articles && b.articles.length > 0) ?
            <Typography component="p" variant="body1" className={myClasses.alertMessage} style={{ marginTop: "16px" }}>
              {customLabel("bom.alertEdit")}
            </Typography>
            : null
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={doDelete} color="primary" disabled={loading} >{customLabel("button.confirm")}</Button>
          <Button onClick={cancelDelete} disabled={loading} color="default">{customLabel("button.cancel")}</Button>
        </DialogActions>
      </Dialog>

    </PaperForm>
  )
}

export default BomProcessesPage
