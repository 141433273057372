import React from "react";
import { Link } from "react-router-dom";
import {
  Typography, Button, Table, TableCell, TableRow, TableBody
} from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockIcon from '@material-ui/icons/Lock';
import CheckIcon from '@material-ui/icons/Check';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";

export const Details = ({ processStatus, edit, toggleDelete, deleting, doDelete, loading }) => {
  const myClasses = mesStyles();

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">{customLabel("processStatus.processStatus")}</Typography>
        <LockIcon />
      </div>
      <div className={myClasses.tableContainer}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell> {customLabel("processStatus.name")}</TableCell>
              <TableCell>{processStatus.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell> {customLabel("processStatus.systemStatus")}</TableCell>
              <TableCell>
                {processStatus.systemStatus ? <CheckIcon /> : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{customLabel("processStatus.backgroundColor")}</TableCell>
              <TableCell>
                {processStatus.backgroundColor ? (
                  <div className={myClasses.backgroundColor}
                    style={{ backgroundColor: processStatus.backgroundColor }}
                  ></div>
                ) : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple key={processStatus.id} onClick={edit} color="primary" disabled={loading}>{customLabel("button.edit")}</Button>
        <Button color="secondary" onClick={toggleDelete} disabled={processStatus.systemStatus || loading}>{customLabel("button.delete")}</Button>
        <Button component={Link} to={`/admin/processStatuses`} disabled={loading}>{customLabel("button.back")}</Button>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={toggleDelete} disabled={loading} />
    </PaperForm>
  );
};
