import React from 'react'
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Typography, FormControlLabel, Checkbox, FormControl, InputLabel, Input, Divider, Button } from '@material-ui/core'
import { customLabel } from "../utility/customLabel";
import LockIcon from '@material-ui/icons/Lock';
import { configurationSelector } from "../configuration/slice";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";

export const Details = ({ role, edit, toggleDelete, deleting, doDelete, loading }) => {
  const myClasses = mesStyles();
  const { configuration } = useSelector(configurationSelector);

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit"> {customLabel("role.role")}</Typography>
        <LockIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl disabled className={myClasses.form50}>
          <InputLabel htmlFor="authority">{customLabel("role.name")}</InputLabel>
          <Input value={role.authority} />
        </FormControl>
        {configuration && configuration.enableMaintenance ? (
          <FormControlLabel control={<Checkbox checked={role.maintenance} disabled={true} name="maintenance" color="default" />} label={customLabel("maintenance.maintenance")} />
        ) : null}
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple key={role.id} onClick={edit} color="primary" disabled={role.id === 1 || role.id === 2 || role.id === 3 || loading}>
          {customLabel("button.edit")}
        </Button>
        <Button color="secondary" onClick={toggleDelete} disabled={role.id === 1 || role.id === 2 || role.id === 3 || loading}>
          {customLabel("button.delete")}
        </Button>
        <Button component={Link} to={`/admin/roles`} disabled={loading}>{customLabel("button.back")}</Button>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={toggleDelete} disabled={loading} />
    </PaperForm>
  )
}
