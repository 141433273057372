import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Button, Table, FormControl, Input, TextField, InputLabel, TableHead, TableCell, TableRow, TableBody, Divider } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab"
import { splitStyles } from "./utility/splitStyles";
//import { mesStyles } from '../utility/ultrafabStyles'
import { customLabel } from "../utility/customLabel";
import { splitSelector, handleCustomFlag, editSplitFields, restoreSplitFields, selectProcess, getSplitListAPI, createSplitAPI, deleteSplitAPI, editSplitAPI } from "./slice";
import ProcessFragment from "./utility/ProcessFragment";
import { FLAG_SPLIT_PROCESS, FLAG_DELETE_PROCESS, FLAG_EDIT_PROCESS } from './utility/constants';
import DialogSplit from './dialogs/DialogSplit';
import { editSplitPayload } from "./utility/constants";
import PaperAutocomplete from '../utility/PaperAutocomplete';
import { configurationSelector } from '../configuration/slice';

const MainSplit = ({ process, machine, operator, workcentre, q }) => {
    const classes = splitStyles();
    //const myClasses = mesStyles()
    const dispatch = useDispatch();
    const [isEdited, setIsEdited] = React.useState(false);
    const { flagSplitProcess, flagDeleteProcess, flagEditProcess, newMachine, newQuantity, machines, processes, currentProcess, newProcess, loading, thisProcess } = useSelector(splitSelector);
    const { configuration } = useSelector(configurationSelector)

    useEffect(() => {
        if (!loading && (processes === false || (thisProcess !== false && thisProcess.id !== process.id))) {
            dispatch(getSplitListAPI(process))
        }
    }, [dispatch, process, processes, thisProcess, loading])

    const onClickSplitButton = (currentProcess) => {
        dispatch(selectProcess({ process: currentProcess, machine: machine }))
        dispatch(handleCustomFlag({ flag: FLAG_SPLIT_PROCESS, open: true }));
    }
    const onClickEditButton = (currentProcess) => {
        setIsEdited(false)
        dispatch(handleCustomFlag({ flag: FLAG_EDIT_PROCESS, open: true }));
        dispatch(selectProcess({ process: currentProcess, machine: currentProcess.machine.id ? currentProcess.machine : null }))
    }
    const onClickDeleteButton = (currentProcess) => {
        dispatch(selectProcess({ process: currentProcess, machine: false }))
        dispatch(handleCustomFlag({ flag: FLAG_DELETE_PROCESS, open: true }));
        if (processes.length === 2) {
            let indexOfM = processes.findIndex((p) => {
                return p.id.toString() !== currentProcess.id.toString();
            });
            dispatch(editSplitFields({ name: "newProcess", value: processes[indexOfM].id }))
        }
    }

    const onChangeFilterAutocomplete = (name, value, reason) => {
        if (reason === "clear") {
            changeSplitValues(name, false);
        } else {
            if (name === editSplitPayload.newMachine) {
                changeSplitValues(name, value.machine_id);
            } else if (name === editSplitPayload.newProcess) {
                changeSplitValues(name, value.id);
            }
        }
    };
    const handleChangeInput = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (name === editSplitPayload.newQuantity & flagEditProcess) {
            if (value.toString() !== currentProcess.quantity.toString()) {
                setIsEdited(true)
            } else {
                setIsEdited(false)
            }
        }
        changeSplitValues(name, value);
    };
    const changeSplitValues = (name, value) => {
        if (value !== false) {
            dispatch(editSplitFields({ name: name, value: value, machines: machines }))
        }
        else {
            dispatch(editSplitFields({ name: name, value: false }))
        }
    };

    let availableMachines = [{ "id": null, "code": "NN", "name": "Nessuna macchina" }]
    if (machines) {
        for (let mac in machines) {
            let m = machines[mac]
            if (m.workcentre.id === workcentre.id && (!flagEditProcess || ((currentProcess.machine.id === null || currentProcess.machine.id === m.machine_id) && m.machine_id === machine.id && process.id === currentProcess.id) || process.id !== currentProcess.id)) {
                availableMachines.push(m)
            }
        }
    }
    const renderFormSplitDialog = () => {
        return (
            <React.Fragment>
                <Autocomplete
                    PaperComponent={PaperAutocomplete}
                    style={{ display: "inline-block" }}
                    className={classes.form100}
                    value={newMachine ? newMachine : null}
                    getOptionSelected={(option, value) => newMachine ? option.machine_id === value.machine_id : null}
                    //getOptionDisabled={option => option.machine_id.toString() === (currentProcess.machine.id !== null ? currentProcess.machine.id.toString() : machine.id.toString())}
                    onChange={(event, value, reason) => onChangeFilterAutocomplete(editSplitPayload.newMachine, value, reason)}
                    options={availableMachines}
                    getOptionLabel={(option) => option.code + " - " + option.name}
                    id="machineAutocomplete"
                    renderInput={(params) => <TextField {...params} label={customLabel("machine.machine")} />
                    }
                />
                <Divider component="br" />
                <Divider component="br" />
                <FormControl className={classes.form100}>
                    <InputLabel error={!newQuantity || newQuantity < 0 || newQuantity > currentProcess.quantity || newQuantity > (currentProcess.quantity - currentProcess.totalPieces) || newQuantity > (currentProcess.quantity - currentProcess.rejectedPieces) || newQuantity > (currentProcess.quantity - currentProcess.validPieces)} htmlFor="newQuantity">{customLabel("process.quantity")}</InputLabel>
                    <Input name={editSplitPayload.newQuantity} value={newQuantity !== false ? newQuantity : ""} onChange={handleChangeInput} type="number"
                        error={!newQuantity || newQuantity < 0 || newQuantity > currentProcess.quantity || newQuantity > (currentProcess.quantity - currentProcess.totalPieces) || newQuantity > (currentProcess.quantity - currentProcess.rejectedPieces) || newQuantity > (currentProcess.quantity - currentProcess.validPieces)} />
                </FormControl>
            </React.Fragment>
        )
    }
    const renderFormEditDialog = () => {
        return (
            <React.Fragment>
                <Autocomplete
                    PaperComponent={PaperAutocomplete}
                    style={{ display: "inline-block" }}
                    className={classes.form100}
                    value={newMachine ? newMachine : null}
                    getOptionSelected={(option, value) => newMachine ? option.machine_id === value.machine_id : null}
                    //getOptionDisabled={option => option.machine_id.toString() === (currentProcess.machine.id !== null ? currentProcess.machine.id.toString() : machine.id.toString())}
                    onChange={(event, value, reason) => onChangeFilterAutocomplete(editSplitPayload.newMachine, value, reason)}
                    options={availableMachines}
                    getOptionLabel={(option) => option.code + " - " + option.name}
                    id="machineAutocomplete"
                    renderInput={(params) => <TextField {...params} label={customLabel("machine.machine")} />
                    }
                />
                <Divider component="br" />
                <Divider component="br" />
                <FormControl className={classes.form100} disabled={processes && processes.length === 1}>
                    <InputLabel error={!newQuantity || newQuantity < 0 || newQuantity > q || newQuantity < currentProcess.totalPieces || newQuantity < currentProcess.rejectedPieces || newQuantity < currentProcess.validPieces} htmlFor="newQuantity">{customLabel("process.quantity")}</InputLabel>
                    <Input name={editSplitPayload.newQuantity} value={newQuantity !== false ? newQuantity : ""} onChange={handleChangeInput} type="number"
                        error={!newQuantity || newQuantity < 0 || newQuantity > q || newQuantity < currentProcess.totalPieces || newQuantity < currentProcess.rejectedPieces || newQuantity < currentProcess.validPieces} />
                </FormControl>
                <Divider component="br" />
                <Divider component="br" />
                <p>{customLabel("split.selectEditQuantity")}</p>
                {processes ? (
                    <Autocomplete
                        PaperComponent={PaperAutocomplete}
                        style={{ display: "inline-block" }}
                        className={classes.form100}
                        value={newProcess ? newProcess : null}
                        getOptionSelected={(option, value) => newProcess ? option.id === value.id : null}
                        onChange={(event, value, reason) => onChangeFilterAutocomplete(editSplitPayload.newProcess, value, reason)}
                        options={availableProcesses ? availableProcesses : []}
                        getOptionLabel={(option) => option ? renderProcessData(option) : null}
                        id="machineAutocomplete"
                        renderInput={(params) => <TextField {...params} label={customLabel("split.split")} error={!newProcess && isEdited && processes.length !== 1} />
                        }
                    />
                ) : null}
            </React.Fragment>
        )
    }
    const renderActionsSplitDialog = () => {
        return (
            <React.Fragment>
                <Button className={classes.dialogButton} variant="contained" color="primary" disabled={!newQuantity || newQuantity < 0 || newQuantity >= currentProcess.quantity || newQuantity >= (currentProcess.quantity - currentProcess.totalPieces - currentProcess.rejectedPieces) || newQuantity >= (currentProcess.quantity - currentProcess.validPieces)} onClick={() => confirmAddSplit()} >
                    {customLabel("button.confirm")}
                </Button>
                <Button className={classes.dialogButton} variant="contained" color="secondary" onClick={() => cancelAddSplit()}>
                    {customLabel("button.cancel")}
                </Button>
            </React.Fragment>
        )
    }

    const confirmAddSplit = () => {
        dispatch(createSplitAPI(currentProcess, currentProcess.odp.id, currentProcess.id, newMachine, newQuantity, operator, machine))
        dispatch(handleCustomFlag({ flag: FLAG_SPLIT_PROCESS, open: false }));
        dispatch(restoreSplitFields())
    }

    const cancelAddSplit = () => {
        dispatch(handleCustomFlag({ flag: FLAG_SPLIT_PROCESS, open: false }));
        dispatch(restoreSplitFields());
    }

    let availableProcesses = []
    if (processes && currentProcess) {
        availableProcesses = processes.filter((p) =>
            p.id !== currentProcess.id
        )
    }

    const renderProcessData = (option) => {
        if (option.machine.id !== null) {
            return customLabel("machine.machine") + " " + option.machine.code + ": " + customLabel("odp.quantity") + " " + option.quantity.toString()
        } else {
            return customLabel("odp.quantity") + " " + option.quantity.toString()
        }
    }

    const renderFormDeleteDialog = () => {
        return (
            <React.Fragment>
                <p>{customLabel("split.selectDeleteQuantity")}</p>
                {processes ? (
                    <Autocomplete
                        PaperComponent={PaperAutocomplete}
                        style={{ display: "inline-block" }}
                        className={classes.form100}
                        value={newProcess ? newProcess : null}
                        getOptionSelected={(option, value) => newProcess ? option.id === value.id : null}
                        onChange={(event, value, reason) => onChangeFilterAutocomplete(editSplitPayload.newProcess, value, reason)}
                        options={availableProcesses ? availableProcesses : []}
                        getOptionLabel={(option) => option ? renderProcessData(option) : null}
                        id="machineAutocomplete"
                        renderInput={(params) => <TextField {...params} label={customLabel("split.split")} error={!newProcess} />
                        }
                    />
                ) : null}
            </React.Fragment>
        )
    }

    const renderActionsEditDialog = () => {
        return (
            <React.Fragment>
                <Button className={classes.dialogButton} variant="contained" color="primary" disabled={(!newProcess && isEdited && processes.length !== 1) || newQuantity === 0 || newQuantity > q || newQuantity < currentProcess.totalPieces || newQuantity < currentProcess.rejectedPieces || newQuantity < currentProcess.validPieces} onClick={() => confirmEditSplit()}>
                    {customLabel("button.confirm")}
                </Button>
                <Button className={classes.dialogButton} variant="contained" color="secondary" onClick={() => cancelEditSplit()}>
                    {customLabel("button.cancel")}
                </Button>
            </React.Fragment>
        )
    }
    const renderActionsDeleteDialog = () => {
        return (
            <React.Fragment>
                <Button className={classes.dialogButton} variant="contained" color="primary" disabled={!newProcess} onClick={() => confirmDeleteSplit()}>
                    {customLabel("button.confirm")}
                </Button>
                <Button className={classes.dialogButton} variant="contained" color="secondary" onClick={() => cancelDeleteSplit()}>
                    {customLabel("button.cancel")}
                </Button>
            </React.Fragment>
        )
    }

    const confirmDeleteSplit = () => {
        dispatch(deleteSplitAPI(currentProcess, currentProcess.odp.id, currentProcess.id, newProcess.id, operator, currentProcess.machine, process.type.id))
        dispatch(handleCustomFlag({ flag: FLAG_DELETE_PROCESS, open: false }));
        dispatch(restoreSplitFields());
    }

    const cancelDeleteSplit = () => {
        dispatch(handleCustomFlag({ flag: FLAG_DELETE_PROCESS, open: false }));
        dispatch(restoreSplitFields());
    }

    const confirmEditSplit = () => {
        dispatch(editSplitAPI(currentProcess, currentProcess.odp.id, currentProcess.id, newMachine, newQuantity, operator, newProcess.id))
        dispatch(handleCustomFlag({ flag: FLAG_EDIT_PROCESS, open: false }));
        setIsEdited(false)
        dispatch(restoreSplitFields());
    }

    const cancelEditSplit = () => {
        dispatch(handleCustomFlag({ flag: FLAG_EDIT_PROCESS, open: false }));
        dispatch(restoreSplitFields());
    }

    return (
        <React.Fragment>
            <Paper className={classes.selectionPaper}>
                <div className={classes.selectionDiv}>
                    <Table size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{customLabel("split.machine")}</TableCell>
                                <TableCell>{customLabel("split.quantity")}</TableCell>
                                <TableCell>{customLabel("split.startDate")}</TableCell>
                                <TableCell>{customLabel("split.progress")}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {processes ? processes.map((p) => {
                                return (
                                    <ProcessFragment key={p.id} machine={p.machine.code !== null ? p.machine.code : p.machine.code === null ? p.machineEvent.machine.code : p.machineEvent.machine.code === null ? machine.code : null} quantity={p.quantity.toFixed(configuration.decimals)} startDate={p.startDate} validPieces={p.validPieces ? p.validPieces : null} onClickSplit={() => onClickSplitButton(p)} onClickDelete={() => onClickDeleteButton(p)}
                                        disabledSplit={false} onClickEdit={() => onClickEditButton(p)} disabledDelete={(processes && processes.length === 1) || process.id === p.id || p.startDate !== null} />
                                )
                            }) : null}
                        </TableBody>
                    </Table>
                </div>
                {loading ? <div>{customLabel("function.loading").toUpperCase()}</div> : null}
            </Paper>
            <DialogSplit flag={flagSplitProcess} title={customLabel("split.splitProcess")} form={() => renderFormSplitDialog()} actions={() => renderActionsSplitDialog()} />
            <DialogSplit flag={flagEditProcess} title={customLabel("split.editProcess")} form={() => renderFormEditDialog()} actions={() => renderActionsEditDialog()} />
            <DialogSplit flag={flagDeleteProcess} title={customLabel("split.deleteProcess")} form={() => renderFormDeleteDialog()} actions={() => renderActionsDeleteDialog()} />

        </React.Fragment>
    )
}

export default MainSplit
