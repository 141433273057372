import React from 'react'
import { Button } from '@material-ui/core';
import { customLabel } from '../../../../utility/customLabel';

/**
 * Restituisce due bottoni: Mostra/nascondi filtri e Svuota filtri
 * @param {object} filter 
 * @param {function} toggleFilter 
 * @param {function} emptyFilter 
 * @returns 
 */
const ButtonsFragment = ({ filter, toggleFilter, emptyFilter }) => {
    return (
        <React.Fragment>
            <Button color="default" onClick={() => toggleFilter()} >
                {filter.showFilter ? customLabel("button.hideFilters") : customLabel("button.showFilters")}
            </Button>
            {filter.showFilter ? (
                <Button color="secondary" onClick={() => emptyFilter()} >
                    {customLabel("button.emptyFilters")}
                </Button>
            ) : null}
        </React.Fragment>
    )
}

export default ButtonsFragment
