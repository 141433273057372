import { Input, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import _ from 'lodash';
import moment from "moment";
import { customLabel } from "../utility/customLabel";

export const DarkerTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
        }
    }
})(TextField);

export const DarkerInput = withStyles({
    root: {
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.8)" // (default alpha is 0.38)
        }
    }
})(Input);

export const toggleFullScreen = (elem) => {
    // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
    if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
        if (elem.requestFullScreen) {
            elem.requestFullScreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullScreen) {
            elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    } else {
        if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }
};
//per impostare il colore del testo in base allo sfondo
export const setColor = (color) => {
    if (color === null || color === "" || !color) {
        return "black";
    };
    if (color.substring(0, 4) !== "rgba") {
        return "black";
    };
    let rgb = getRGB(color);
    const brightness = Math.round(((parseInt(rgb.red) * 299) +
        (parseInt(rgb.green) * 587) +
        (parseInt(rgb.blue) * 114)) / 1000);
    const textColor = (brightness > 125) ? "black" : "white";
    return textColor;
};
export const getRGB = (str) => {
    let match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
    return match ? {
        red: match[1],
        green: match[2],
        blue: match[3]
    } : {};
};

/**
 * Calcola il tempo rimanente alla lavorazione in base a quantità, pezzi prodotti e tempo ciclo. Se esce t negativo restituisco zeri (pezzi prodotti raggiunti)
 * @param {object} process 
 * @returns tempo rimanente nel formato hh:mm
 */
export const calcRemainingTime = (cycleTime, validPieces, totalPieces, quantity, short) => {
    let t = short ? customLabel("function.notAvailableShort").toUpperCase() : customLabel("function.notAvailable").toUpperCase();
    if (cycleTime !== null) {
        let q = validPieces !== null ? validPieces : totalPieces !== null ? totalPieces : 0
        let rt = (quantity - q) * cycleTime;
        if (typeof (rt) === "number" && rt >= 0) {
            t = moment.duration(rt, "seconds").format("HH:mm", { trim: false });
        }
        if (typeof (rt) === "number" && rt < 0) {
            rt = 0;
            t = moment.duration(rt, "seconds").format("HH:mm", { trim: false });
        }
    }
    return t;
}
export const calcRemainingSetupTime = (expectedSetupTime, setupTime) => {
    let rst = expectedSetupTime !== null ? setupTime !== null ? expectedSetupTime - setupTime : expectedSetupTime : null
    let t = null;
    if (typeof (rst) === "number" && rst >= 0) {
        t = "setup: " + moment.duration(rst, "seconds").format("HH:mm", { trim: false });
    }
    if (typeof (rst) === "number" && rst < 0) {
        rst = 0;
        t = "setup: " + moment.duration(rst, "seconds").format("HH:mm", { trim: false });
    }
    return t;
}

/**
 * Converte i secondi in ore:minuti nel formato hh:mm passati
 * @param {number} seconds 
 * @returns tempo calcolato nel formato hh:mm
 */
export const secondsToHours = (seconds) => {
    let t = customLabel("function.notAvailable").toUpperCase();
    if (seconds && seconds !== null && typeof (seconds) === "number") {
        t = moment.duration(seconds, "seconds").format("HH:mm", { trim: false })
    }
    return t;
}

/**
 * Restituisce l'elemento di una lista di oggetti in base al id passato come parametro
 * @param {array} list 
 * @param {integer} id 
 * @returns elemento se esiste false altrimenti
 */
export const getElementListById = (list, id) => {
    let element = false
    if (list && list.length > 0) {
        let indexElement = list.findIndex((l) => {
            return l.id.toString() === id.toString()
        })
        if (indexElement !== -1) {
            element = _.cloneDeep(list[indexElement]);
        }
    }
    return element;
}

export const secondsToDhms = (seconds) => {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + " gg " : "";
    var hDisplay = h > 0 ? h + " h " : "";
    var mDisplay = m > 0 ? m + " m " : "";
    var sDisplay = s > 0 ? s + " s" : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
};