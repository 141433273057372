import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Input, InputLabel
} from "@material-ui/core";
import React from "react";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";

const Evolution = ({ configurationedit, handleChange }) => {
    const myClasses = mesStyles();

    return (
        <div className={myClasses.dialogForm500}>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="name">{customLabel("bishop.evolution")}</InputLabel>
                <Input name="evoUrl" value={configurationedit.evoUrl !== "null" && configurationedit.evoUrl !== null ? configurationedit.evoUrl : ""} onChange={handleChange} />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="generatorTopLevel">{customLabel("configuration.generatorTopLevel")}</InputLabel>
                <Input name="generatorTopLevel" value={configurationedit.generatorTopLevel !== "null" && configurationedit.generatorTopLevel !== null ? configurationedit.generatorTopLevel : ""} onChange={handleChange} />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="generatorLocation">{customLabel("configuration.generatorLocation")}</InputLabel>
                <Input name="generatorLocation" value={configurationedit.generatorLocation !== "null" && configurationedit.generatorLocation !== null ? configurationedit.generatorLocation : ""} onChange={handleChange} />
            </FormControl>
            <FormControlLabel labelPlacement="end"
                control={
                    <Checkbox checked={configurationedit.sendCurrentMachineEvent} onChange={handleChange} name="sendCurrentMachineEvent" color="default" />
                }
                label={customLabel("configuration.sendCurrentMachineEvent")}
            />
        </div>
    );
};
export default Evolution;