import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  workcentresSelector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete, getListAPI
} from "./slice";
import { machinesSelector } from "../machines/slice";
import { reset as resetMachines } from "../machines/slice";
import { Box } from "@material-ui/core";
import { Form } from "./Form";
import { Details } from "./Details";
import { MachinesPage } from "../machines/MachinesPage";

const WorkcentrePage = ({ match }) => {
  const dispatch = useDispatch();
  const { workcentre, workcentreedit, editmode, deleting, isCreated, isDeleted, workcentres, loading } = useSelector(workcentresSelector);
  const { loading: loadingMachines } = useSelector(machinesSelector);

  useEffect(() => {
    dispatch(getListAPI(0, 10, "code", "asc", false, false));
  }, [dispatch, isCreated])

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    dispatch(change({ name: name, value: value }));
  };

  const toggleDelete = (event) => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(workcentreedit));
    dispatch(resetMachines())
  };

  const onEdit = () => {
    dispatch(edit());
  };
  const doDelete = () => {
    dispatch(deleteAPI(workcentre));
    dispatch(resetMachines())
  };

  const save = () => {
    if (workcentre.id === "new") {
      dispatch(createAPI(workcentreedit));
    } else {
      dispatch(updateAPI(workcentreedit));
    }
  };

  useEffect(() => {
    const { id } = match.params;
    if (id !== "new") {
      dispatch(getAPI(id));
    } else {
      dispatch(create());
    }
  }, [dispatch, match]);

  const renderWorkcentre = () => {
    if (workcentre !== false && !editmode && match.params.id === workcentre.id.toString()) {
      return (
        <div>
          <Details workcentre={workcentre} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete}
            loading={loading} loadingMachines={loadingMachines} />
          <MachinesPage workcentre={workcentre} workcentreLoading={loading} workcentres={workcentres} />
        </div>
      );
    }
    if (workcentre !== false && editmode) {
      return (
        <Form workcentreedit={workcentreedit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save} workcentres={workcentres}
          loading={loading} loadingMachines={loadingMachines} />
      );
    }
    return null;
  };

  if (isDeleted && match.params.id === workcentre.id.toString()) {
    return <Redirect to={"/workcentres/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/workcentres/" + workcentre.id} />;
  }

  return <Box>{renderWorkcentre()}</Box>;
};

export default WorkcentrePage;
