import { Button, Paper, Table, TableCell, TableContainer, TableRow } from "@material-ui/core";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router';
import { configurationSelector } from '../../configuration/slice';
import { useAnchor } from '../../customHooks/useAnchor';
import { doChange } from '../../dashboard/slice';
import { machineEventsSelector } from '../../machineEvents/slice';
import { userEventsSelector } from '../../userEvents/slice';
import DialogConfirmAction from '../../utility/DialogConfirmAction';
import PopoverLegend from '../../utility/PopoverLegend';
import ProgressBarOneLine from '../../utility/ProgressBarOneLine';
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";
import MachineEvents from '../progressOdp/MachineEvents';
import UserEvents from '../progressOdp/UserEvents';
import { odpsSelector, reopenStaplingAPI, restoreStaplingAPI, stopStaplingAPI } from "../slice";

const ListProcesses = () => {
    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { odp, loading, staplingList } = useSelector(odpsSelector);
    const [closeFlag, setCloseFlag] = React.useState(false);
    const [reopenFlag, setReopenFlag] = React.useState(false);
    const [restoreFlag, setRestoreFlag] = React.useState(false);
    const [redirectOdp, setRedirectOdp] = React.useState(false);
    const { machineEvents } = useSelector(machineEventsSelector);
    const { userEvents } = useSelector(userEventsSelector);
    const { configuration } = useSelector(configurationSelector)
    const [anchorMachine, { handleOpen: handleOpenMachinePopover, handleClose: handleCloseMachinePopover }, openMachinePopover] = useAnchor();
    const [anchorUser, { handleOpen: handleOpenUserPopover, handleClose: handleCloseUserPopover }, openUserPopover] = useAnchor();

    const enableCloseFlag = () => {
        setCloseFlag(true);
    };
    const confirmClose = () => {
        dispatch(stopStaplingAPI(odp));
        setCloseFlag(false);
    };
    const cancelActions = () => {
        setCloseFlag(false);
        setRestoreFlag(false);
        setReopenFlag(false);
    };

    const settingRestore = () => {
        setRestoreFlag(true);
        //setCurrentMachineId(machineId);
    };
    const confirmRestore = () => {
        dispatch(restoreStaplingAPI(odp));
        setRestoreFlag(false);
        //setCurrentMachineId(false);
    };
    const settingReopen = () => {
        setReopenFlag(true);
        //setCurrentMachineId(machineId);
    };
    const confirmReopen = () => {
        dispatch(reopenStaplingAPI(odp));
        setReopenFlag(false);
        //setCurrentMachineId(false);
    };

    const handleClickRedirect = (id) => {
        dispatch(doChange("/odps"));
        setRedirectOdp(`/odps/${id}`)
    }

    if (redirectOdp) {
        return <Redirect to={redirectOdp} />;
    }

    const renderProcesses = () => {
        if (odp && staplingList && staplingList.length > 0) {
            return (
                staplingList.map((p) => {
                    return (
                        <TableRow key={p.id} style={{ cursor: "pointer" }} onClick={() => handleClickRedirect(p.odp.id)}>
                            <TableCell style={{ wordBreak: "break-all" }}>
                                {p.odp.code}
                            </TableCell>
                            <TableCell style={{ wordBreak: "break-all" }}>
                                {p.article.name}
                            </TableCell>
                            <TableCell style={{ wordBreak: "break-all" }}>
                                {p.odp.customer.name}
                            </TableCell>
                            <TableCell align="right">
                                {p.quantity}
                            </TableCell>
                        </TableRow>
                    )
                })
            )
        } else {
            return (
                <TableRow>
                    <TableCell colSpan={4}>{customLabel("odp.noProcessesAvailable")}</TableCell>
                </TableRow>
            )
        }
    }
    return (
        <React.Fragment>
            <Paper className={myClasses.odpDetailsPaperMD}>
                <TableContainer className={myClasses.odpDetailsContainerMD}>
                    <Table size="small">
                        <tbody>
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    <b>{customLabel("process.processes")}</b>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{customLabel("odp.odp")}</TableCell>
                                <TableCell>{customLabel("article.article")}</TableCell>
                                <TableCell>{customLabel("customer.customer")}</TableCell>
                                <TableCell align="right">{customLabel("process.quantity")}
                                </TableCell>
                            </TableRow>
                            {renderProcesses()}
                            {odp && staplingList && staplingList.length > 0 ?
                                <>
                                    <TableRow>
                                        <TableCell align="center" colSpan={4}>
                                            <ProgressBarOneLine key={odp.id} total={odp.quantity}
                                                current={odp.validPieces !== null ? odp.validPieces : odp.totalPieces !== null ? odp.totalPieces - odp.rejectedPieces : 0}
                                                measure={staplingList[0].article.measure} />
                                        </TableCell>
                                    </TableRow>
                                    <MachineEvents p={staplingList[0]} handleOpenPopover={handleOpenMachinePopover} handleClosePopover={handleCloseMachinePopover} />
                                    {configuration.showUserEvents ? (
                                        <UserEvents p={staplingList[0]} handleOpenPopover={handleOpenUserPopover} handleClosePopover={handleCloseUserPopover} />
                                    ) : null}
                                </> : null
                            }
                        </tbody>
                    </Table>
                </TableContainer>
                <div className={myClasses.buttonsDivBottom}>
                    <div className={myClasses.buttonsFlexBetween}>
                        <Button color="secondary" disabled={loading || odp.startDate === null || odp.endDate !== null} onClick={() => enableCloseFlag()}>
                            {customLabel("stapling.closeStapling")}
                        </Button>
                        <Button color="primary" disabled={loading || odp.startDate === null || odp.endDate === null || (staplingList && staplingList.some(n => n.next.startDate !== null))}
                            onClick={() => settingReopen()}>
                            {customLabel("button.reopen")}
                        </Button>
                        <Button color="primary" disabled={loading || odp.startDate === null || (staplingList && staplingList.some(n => n.next.startDate !== null))}
                            onClick={() => settingRestore()}>
                            {customLabel("button.restore")}
                        </Button>
                    </div>
                </div>
                {anchorMachine ? (
                    <PopoverLegend name={customLabel("legend.legend").toUpperCase()} list={machineEvents} open={openMachinePopover} anchorEl={anchorMachine}
                        openPopover={handleOpenMachinePopover} closePopover={handleCloseMachinePopover}
                        anchorVertical={"bottom"} anchorHorizontal={"right"} transformVertical={"center"} transormHorizontal={"left"} />
                ) : null}
                {anchorUser ? (
                    <PopoverLegend name={customLabel("legend.legend").toUpperCase()} list={userEvents} open={openUserPopover} anchorEl={anchorUser}
                        openPopover={handleOpenUserPopover} closePopover={handleCloseUserPopover}
                        anchorVertical={"bottom"} anchorHorizontal={"right"} transformVertical={"center"} transormHorizontal={"left"} />
                ) : null}
            </Paper>
            <DialogConfirmAction flag={closeFlag} text={customLabel("stapling.confirmStop")} confirm={confirmClose}
                cancel={cancelActions} disabled={loading} />
            <DialogConfirmAction flag={restoreFlag} text={customLabel("stapling.confirmRestore")} confirm={confirmRestore}
                cancel={cancelActions} disabled={loading} />
            <DialogConfirmAction flag={reopenFlag} text={customLabel("stapling.confirmReopen")} confirm={confirmReopen}
                cancel={cancelActions} disabled={loading} />
        </React.Fragment>
    )
};

export default ListProcesses;
