import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import {
  machineEventsSelector, deleteCallbackAPI, updateCallbackAPI, createCallbackAPI, changeCallback, newCallback, selectCallback, resetCallback
} from "./slice";
import {
  Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle,
  DialogContent, DialogActions, InputLabel, Select, FormControl
} from "@material-ui/core";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";

const CallbacksPage = ({ machineEvent, loading }) => {
  const dispatch = useDispatch();
  const myClasses = mesStyles();
  const { callback, machineEvents } = useSelector(machineEventsSelector);
  const [deleting, setDeleting] = React.useState(false);

  const onAdd = () => {
    dispatch(newCallback());
  };

  const cancel = () => {
    dispatch(resetCallback());
  };

  const save = () => {
    if (callback.id === "new") {
      dispatch(createCallbackAPI(callback, machineEvent.id));
    } else {
      dispatch(updateCallbackAPI(callback, machineEvent.id));
    }
  }

  const onDelete = (callback) => {
    setDeleting(callback);
  };

  const cancelDelete = () => {
    setDeleting(false);
  };

  const deleteCallback = () => {
    dispatch(deleteCallbackAPI(deleting, machineEvent.id));
    setDeleting(false);
  };

  const onUpdate = (callback) => {
    dispatch(selectCallback(callback));
  };

  const handleChangeCallback = (event, isChecked) => {
    let value, name;
    name = event.target.name;
    value = event.target.value;
    dispatch(
      changeCallback({
        name: name,
        value: value,
        machineEvents: machineEvents,
      })
    );
  };

  const renderFreeEvents = (type) => {
    if (machineEvents !== false) {
      const list = machineEvents.filter((item) => item.id !== machineEvent.id);
      return list.map((machine) => (
        <option key={machine.id} value={machine.id}>
          {machine.name}
        </option>
      ));
    } else return null;
  };

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">
          {customLabel("machineEvent.callback.callbacks")}
        </Typography>
      </div>
      <div className={myClasses.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{customLabel("machineEvent.callback.when")}</TableCell>
              <TableCell>{customLabel("machineEvent.callback.whatToDo")}</TableCell>
              <TableCell>{customLabel("machineEvent.callback.onWhich")}</TableCell>
              <TableCell align="right" className={myClasses.tableCell1Button}>
                <Button onClick={onAdd} color="primary" disabled={loading}>
                  {customLabel("button.add")}
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {machineEvent.generatedCallbacks.map((o) => {
              return (
                <TableRow key={o.id}>
                  <TableCell
                    style={
                      o.generatorActionType === "start"
                        ? { color: "blue" }
                        : { color: "red" }
                    }
                  >
                    {customLabel("machineEvent.callback." + o.generatorActionType)}
                  </TableCell>
                  <TableCell
                    style={
                      o.actionType === "open"
                        ? { borderLeft: "2px solid green" }
                        : { borderLeft: "2px solid red" }
                    }
                  >
                    {customLabel("machineEvent.callback." + o.actionType)}
                  </TableCell>
                  <TableCell>{o.action.name}</TableCell>
                  <TableCell align="right" className={myClasses.tableCell2Buttons}>
                    <Button onClick={() => onUpdate(o)} color="primary" disabled={loading}>
                      {customLabel("button.edit")}
                    </Button>
                    <Button onClick={() => onDelete(o)} color="secondary" disabled={loading}>
                      {customLabel("button.delete")}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {/*dialog per elimina*/}
        <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
          confirm={deleteCallback} cancel={cancelDelete} disabled={loading} />
        {/*Dialog per add */}
        {callback !== false ? (
          <Dialog maxWidth={"md"} fullWidth open={callback !== false} keepMounted>
            <DialogTitle>
              {customLabel("machineEvent.callback.newCallback")}
            </DialogTitle>
            <DialogContent>
              <form className={myClasses.dialogForm} noValidate autoComplete="off">
                <FormControl fullWidth onChange={handleChangeCallback} margin="normal">
                  <InputLabel>
                    {customLabel("machineEvent.callback.when")}
                  </InputLabel>
                  <Select value={callback.generatorActionType} native name="generatorActionType">
                    <option disabled value={""}></option>
                    <option value={"start"}>
                      {customLabel("machineEvent.callback.start")}
                    </option>
                    <option value={"stop"}>
                      {customLabel("machineEvent.callback.stop")}
                    </option>
                  </Select>
                </FormControl>
                <FormControl fullWidth onChange={handleChangeCallback} margin="normal">
                  <InputLabel>
                    {customLabel("machineEvent.callback.whatToDo")}
                  </InputLabel>
                  <Select value={callback.actionType} disabled={true} native name="actionType">
                    <option value={"open"}>
                      {customLabel("machineEvent.callback.open")}
                    </option>
                    <option value={"close"}>
                      {customLabel("machineEvent.callback.close")}
                    </option>
                  </Select>
                </FormControl>
                <FormControl fullWidth onChange={handleChangeCallback} margin="normal">
                  <InputLabel>
                    {customLabel("machineEvent.callback.onWhich")}
                  </InputLabel>
                  <Select value={callback.action.id} native name="action.id">
                    <option disabled value={""}></option>
                    {renderFreeEvents()}
                  </Select>
                </FormControl>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={save} color="primary"
                disabled={callback.action.id === "" || callback.actionType === "" || callback.generatorActionType === "" || loading}>
                {customLabel("button.confirm")}
              </Button>
              <Button onClick={cancel} color="default" disabled={loading}>
                {customLabel("button.close")}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </div>
    </PaperForm>
  );
};

export default CallbacksPage;
