import { createSlice } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../notifications/slice";
import { logout, refreshAPI } from "../main/slice";
import { SERVER_URL } from "../../constants";
import { customLabel } from "../utility/customLabel";
import { customFilter } from "./utility";

export const initialState = {
  loading: false,
  reloading: false, //usato per useInterval
  hasErrors: false,
  customerOrders: false,
  articleList: false,
  results: 0,
  reload: false,
  editmode: false,
  customerOrder: false,
  customerOrderedit: false,
  deleting: false,
  isCreated: false,
  isDeleted: false,
  filter: false,
  editCustomerAlert: false,
  initialCustomer: false
};

const customerOrdersSlice = createSlice({
  name: "customerOrders",
  initialState,
  reducers: {
    resetState: (state) => {
      if (!state.filter) {
        Object.assign(state, initialState);
      } else {
        let tempState = {};
        let currentFilter = { ...state.filter }
        Object.assign(tempState, initialState);
        tempState.filter = { ...currentFilter }
        Object.assign(state, tempState);
      }
    },
    gettingList: (state) => {
      state.loading = true;
      state.reload = false;
      state.hasErrors = false;
    },
    getListSuccess: (state, { payload }) => {
      state.customerOrders = payload[1];
      state.results = payload[0].total_entries;
      state.loading = false;
    },
    setReload: (state) => {
      state.reload = true;
    },
    reset: (state) => {
      state.customerOrder = false;
      state.customerOrderedit = false;
      state.editmode = false;
      state.isDeleted = false;
      state.isCreated = false;
    },
    getting: (state) => {
      state.isCreated = false;
      state.isDeleted = false;
      state.loading = true;
      state.editmode = false;
      state.hasErrors = false;
    },
    reloading: (state) => {
      state.reloading = true;
      state.hasErrors = false;
    },
    getSuccess: (state, { payload }) => {
      state.customerOrder = payload;
      state.loading = false;
      state.reloading = false;
      state.hasErrors = false;
    },
    creating: (state) => {
      state.loading = true;
    },
    create: (state) => {
      let newcustomerOrder = {
        id: "new", name: "", code: "", customer: { id: '' }, quantity: "0", date: new Date().getTime(),
        note: "", note2: "", note3: "", article: { id: "" }, startDate: null
      };
      state.customerOrder = newcustomerOrder;
      state.customerOrderedit = newcustomerOrder;
      state.editmode = true;
      state.editCustomerAlert = false;
      state.initialCustomer = false;
    },
    createSuccess: (state, { payload }) => {
      state.customerOrder = payload;
      state.customerOrderedit = false;
      state.loading = false;
      state.isCreated = true;
      //svuoto in modo che se faccio crea commessa viene fatta la chiamata per aver gli ordini aggiornati compreso quello appena creato
      state.customerOrders = false;
      state.results = false;
    },
    resetEdit: (state) => {
      state.editmode = true;
      state.customerOrderedit = state.customerOrder;
      state.loading = false;
      state.hasErrors = true;
    },
    edit: (state) => {
      state.editmode = true;
      state.customerOrderedit = state.customerOrder;
      /* const deliveryDate = new Date(state.customerOrderedit.deliveryDate).getTime()
      state.customerOrderedit.deliveryDate = deliveryDate */
      const date = new Date(state.customerOrderedit.date).getTime()
      state.customerOrderedit.date = date
      state.initialCustomer = state.customerOrderedit.customer.id
    },
    cancelEdit: (state) => {
      state.editmode = false;
      state.customerOrderedit = false;
      state.editCustomerAlert = false
      state.initialCustomer = false
    },
    change: (state, { payload }) => {
      let u = state.customerOrderedit;
      if (payload.name === "customer.id") {
        state.editCustomerAlert = state.initialCustomer ? payload.value !== state.initialCustomer : false
        let lista = payload.customers.slice();
        let indexOfC = lista.findIndex((l) => {
          return l.id === payload.value;
        });
        u["customer"] = lista[indexOfC];
      } else if (payload.name === "article.id") {
        let lista2 = payload.articles.slice();
        let indexOfA = lista2.findIndex((l) => {
          return l.id === payload.value;
        });
        u["article"] = lista2[indexOfA];
      } else {
        u[payload.name] = payload.value;
      }
      state.customerOrderedit = u;
    },
    changeObject: (state, { payload }) => {
      let u = state.customerOrderedit;
      if (payload.name === "customer.id") {
        u["customer"] = { id: "" }
      }
      if (payload.name === "article.id") {
        u["article"] = { id: "" }
      }
      state.customerOrderedit = u;
    },
    updating: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    updateSuccess: (state, { payload }) => {
      state.customerOrder = payload;
      state.loading = false;
      state.editmode = false;
      state.editCustomerAlert = false;
    },
    confirmDelete: (state) => {
      state.deleting = !state.deleting;
    },
    deleting: (state) => {
      state.deleting = false;
      state.loading = true;
      state.hasErrors = false;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.isDeleted = true;
    },
    enableErrors: (state) => {
      state.loading = false;
      state.reloading = false;
      state.hasErrors = true;
    },
    changeFilter: (state, { payload }) => {
      state.filter[payload.name] = payload.value;
    },
    /**
     * 
     * @param {*} state 
     * @param {boolean} payload showFilter 
     */
    initFilter: (state, { payload }) => {
      let tempFilter = { ...customFilter };
      tempFilter["showFilter"] = payload;
      state.filter = { ...tempFilter };
    }
  },
});

export const { resetState, gettingList, getListSuccess, setReload, reset, getting, getSuccess, create, creating, createSuccess, edit,
  cancelEdit, change, updating, updateSuccess, confirmDelete, deleting, deleteSuccess, resetEdit, loadingcustomerOrders,
  loadingcustomerOrdersSuccess, enableErrors, changeObject, reloading, changeFilter, initFilter } = customerOrdersSlice.actions;

export const selector = (state) => state.customerOrders;

export default customerOrdersSlice.reducer;

export function updateAPI(customerOrder) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { customerOrder: customerOrder };
  return async (dispatch) => {
    dispatch(updating());
    const response = await fetch(SERVER_URL + "/api/customerOrder/" + customerOrder.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(resetEdit());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function createAPI(customerOrder) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(creating());
    let params = { customerOrder: customerOrder };
    const response = await fetch(SERVER_URL + "/api/customerOrder", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function getAPI(id) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(getting());
    const response = await fetch(SERVER_URL + "/api/customerOrder/" + id, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function deleteAPI(customerOrder) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(deleting());
    const response = await fetch(SERVER_URL + "/api/customerOrder/" + customerOrder.id, {
      mode: "cors",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(deleteSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function getListAPI(page, rowsPerPage, orderBy, order, customer, code, endDateFrom, endDateTo, dateFrom, dateTo) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(gettingList());
    dispatch(reset());
    let url = SERVER_URL + "/api/customerOrder?page=" + page + "&per_page=" + rowsPerPage + "&orderBy=" + orderBy + "&order=" + order;
    if (customer !== false) {
      url = url + "&customer=" + customer.id;
    }
    if (code !== false) {
      url = url + "&code=" + code;
    }
    if (endDateFrom) {
      url = url + "&endDateFrom=" + endDateFrom
    }
    if (endDateTo) {
      url = url + "&endDateTo=" + endDateTo
    }
    if (dateFrom) {
      url = url + "&dateFrom=" + dateFrom
    }
    if (dateTo) {
      url = url + "&dateTo=" + dateTo
    }

    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getListSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
//stessa chiamata getAPI ma utilizzando reloading al posto di loading
export function updateInterfaceAPI(customerOrder) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(reloading());
    const response = await fetch(SERVER_URL + "/api/customerOrder/" + customerOrder.id, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
