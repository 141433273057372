import React from "react";
import {
    Input, InputLabel, FormControl
} from "@material-ui/core";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";

const Report = ({ configurationedit, handleChange }) => {
    const myClasses = mesStyles();

    return (
        <div className={myClasses.dialogForm500}>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="reportUrl">{customLabel("configuration.reportUrl")}</InputLabel>
                <Input name="reportUrl" type="string"
                    value={configurationedit.reportUrl !== false ? configurationedit.reportUrl : ""}
                    onChange={handleChange} />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="reportUrl">{customLabel("configuration.reportTemplates")}</InputLabel>
                <Input name="reportTemplates" type="string"
                    value={configurationedit.reportTemplates !== false ? configurationedit.reportTemplates : ""}
                    onChange={handleChange} />
            </FormControl>
        </div>
    );
};
export default Report;