import React from "react";
import { Link } from "react-router-dom";
import { Typography, FormControl, InputLabel, Input, Divider, Button, FormControlLabel, Checkbox } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockIcon from '@material-ui/icons/Lock';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from "../utility/DialogConfirmAction";

export const Details = ({ user, edit, toggleDelete, deleting, doDelete, roles, loading }) => {
  const myClasses = mesStyles();

  const roleChecked = (id) => {
    let index = user.roles.findIndex((r) => r.id === id)
    if (index > -1) {
      return true
    } else {
      return false
    }
  }

  return (
    <PaperForm>
      <div className={myClasses.titleFormSpaceBetween}>
        <Typography component="h1" variant="h6" color="inherit">
          {customLabel("user.user")}
        </Typography>
        <LockIcon />
      </div>
      <form className={myClasses.formContainer} noValidate autoComplete="off">
        <FormControl className={myClasses.form50}>
          <InputLabel disabled htmlFor="code">{customLabel("user.code")}</InputLabel>
          <Input disabled value={user.code ? user.code : ""} />
        </FormControl>
        <FormControl className={myClasses.form50} disabled>
          <InputLabel htmlFor="username">{customLabel("user.username")}</InputLabel>
          <Input id="username" value={user.username} />
        </FormControl>
        <FormControl disabled className={myClasses.form50}>
          <InputLabel htmlFor="firstName">{customLabel("user.firstName")}</InputLabel>
          <Input value={user.firstName} />
        </FormControl>
        <FormControl disabled className={myClasses.form50}>
          <InputLabel htmlFor="lastName">{customLabel("user.lastName")}</InputLabel>
          <Input value={user.lastName} />
        </FormControl>
        <FormControl disabled className={myClasses.form50}>
          <InputLabel htmlFor="email">{customLabel("user.email")}</InputLabel>
          <Input id="email" value={user.email} />
        </FormControl>
        <FormControlLabel disabled control={<Checkbox checked={user.enabled} color="default" />} label={customLabel("user.enabled")} />
        {roles ?
          (<div>
            <InputLabel htmlFor="roles">{customLabel("user.roles")}</InputLabel>
            {roles.map((r) => {
              return (<FormControlLabel key={r.id} disabled control={<Checkbox checked={roleChecked(r.id)} />} label={r.authority} />);
            })}
          </div>) : null}
      </form>
      <Divider />
      <div className={myClasses.buttonsFormUpdate}>
        <Button focusRipple key={user.id} onClick={edit} color="primary" disabled={user.username === "admin" || loading}>
          {customLabel("button.edit")}
        </Button>
        <Button color="secondary" onClick={toggleDelete} disabled={user.username === "admin" || loading}>{customLabel("button.delete")}</Button>
        <Button component={Link} to={`/admin/users`} disabled={loading}>{customLabel("button.back")}</Button>
      </div>
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={toggleDelete} disabled={loading} />
    </PaperForm>
  );
};
