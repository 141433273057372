import { AppBar, Box, Tab, Tabs } from "@material-ui/core/";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { configurationSelector, getAPI as getConfigurationAPI } from "../../configuration/slice";
import { labelTimeline, labelTimelineMachines, labelTimelineWorkcentres } from '../../configuration/utility';
import { customLabel } from "../../utility/customLabel";
import { doChange } from '../slice';
import MachinesTimeline from "./MachinesTimeline";
import UsersTimeline from "./UsersTimeLine";
import { INDEX_MACHINES, INDEX_USERS } from './utility';

const TimelinePage = () => {
  const dispatch = useDispatch();
  const { configuration } = useSelector(configurationSelector);
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    dispatch(getConfigurationAPI())
  }, [dispatch]);

  const renderSecondaryTitleUsers = (event) => {
    switch (configuration.labelTimeline) {
      case labelTimeline["odpCode"]: return ""
      case labelTimeline["odpProcessTypeCode"]: return " - " + event.processCode;
      case labelTimeline["odpProcessTypeName"]: return " - " + event.processName;
      case labelTimeline["odpCustomerCode"]: return " - " + event.customerCode;
      case labelTimeline["odpCustomerName"]: return " - " + event.customerName;;
      case labelTimeline["odpArticleCode"]: return " - " + event.articleCode;
      case labelTimeline["odpArticleName"]: return " - " + event.articleName;
      default: return "";
    }
  }

  const renderSecondaryTitleMachines = (event) => {
    switch (configuration.labelTimeline) {
      case labelTimeline["odpCode"]: return ""
      case labelTimeline["odpProcessTypeName"]: return " - " + event.name;
      case labelTimeline["odpCustomerCode"]: return " - " + event.customerCode;
      case labelTimeline["odpCustomerName"]: return " - " + event.customerName;;
      case labelTimeline["odpArticleCode"]: return " - " + event.articleCode;
      case labelTimeline["odpArticleName"]: return " - " + event.articleName;
      default: return "";
    }
  }

  const renderMachineTitle = (m) => {
    switch (configuration.labelTimelineMachines) {
      case labelTimelineMachines["code"]: return m.code
      case labelTimelineMachines["name"]: return m.name;
      default: return "";
    }
  }

  const renderWorkcentreTitle = (w) => {
    switch (configuration.labelTimelineWorkcentres) {
      case labelTimelineWorkcentres["code"]: return w.code
      case labelTimelineWorkcentres["name"]: return w.name;
      default: return "";
    }
  }

  const changeUrlOdps = () => {
    dispatch(doChange("/odps"));
  };

  const changeUrlMaintenance = () => {
    dispatch(doChange("/maintenances"))
  }

  return (
    <React.Fragment>
      <AppBar position="static">
        <Tabs style={{ backgroundColor: "white", color: "black" }} value={tabIndex} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
          <Tab label={customLabel("machineEvent.machineEvents")} key={"machineEvents"} value={INDEX_MACHINES} />
          {configuration.showUserEvents ? (
            <Tab label={customLabel("userEvent.userEvents")} key={"userEvents"} value={INDEX_USERS} />
          ) : null}
        </Tabs>
      </AppBar>
      <Box>
        {tabIndex === INDEX_MACHINES ? (
          <MachinesTimeline renderSecondaryTitle={renderSecondaryTitleMachines} renderMachineTitle={renderMachineTitle} changeUrlOdps={changeUrlOdps}
            changeUrlMaintenance={changeUrlMaintenance} renderWorkcentreTitle={renderWorkcentreTitle} />
        ) : tabIndex === INDEX_USERS ? (
          <UsersTimeline renderSecondaryTitle={renderSecondaryTitleUsers} changeUrlOdps={changeUrlOdps} changeUrlMaintenance={changeUrlMaintenance} />
        ) : null}
      </Box>
    </React.Fragment>
  )
}

export default TimelinePage
