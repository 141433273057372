import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { removeProcessTypeAPI, addProcessTypesAPI, sortProcessTypesAPI } from "./slice";
import { processTypesSelector, getListAPI as getProcessTypes } from "../processTypes/slice";
import {
  Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions, TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Sortable from "../utility/Sortable";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import PaperAutocomplete from "../utility/PaperAutocomplete";

const ProcessTypesPage = ({ maintenanceType, loading }) => {
  const myClasses = mesStyles();
  const dispatch = useDispatch();
  const { processTypes } = useSelector(processTypesSelector);
  const [removeProcessType, setRemoveProcessType] = React.useState(false);
  const [linking, setLinking] = React.useState(false);
  const [sorting, setSorting] = React.useState(false);
  const [newArray, setNewArray] = React.useState(false);
  const [wl, setWl] = React.useState([]);

  useEffect(() => {
    dispatch(getProcessTypes(0, 100000, "name", "asc", false, false, true));
  }, [dispatch]);

  const cancelUnlink = () => {
    setRemoveProcessType(false);
  };

  const doUnlink = () => {
    dispatch(removeProcessTypeAPI(maintenanceType, removeProcessType));
    setRemoveProcessType(false);
  };

  const onLink = () => {
    setLinking(true);
  };

  const cancelLink = () => {
    setLinking(false);
    setWl([]);
  };

  const doLink = () => {
    dispatch(addProcessTypesAPI(maintenanceType, wl));
    setWl([]);
    setLinking(false);
  };

  const onSort = () => {
    setNewArray(maintenanceType.processTypesLinks);
    setSorting(true);
  };

  const cancelSort = () => {
    setNewArray(false);
    setSorting(false);
  };

  const doSort = () => {
    dispatch(sortProcessTypesAPI(maintenanceType, newArray));
    setNewArray(false);
    setSorting(false);
  };

  const onChangeSort = (newArray) => {
    setNewArray(newArray);
  };

  const onUnlick = (w) => {
    setRemoveProcessType(w);
  };

  if (processTypes !== false) {
    const availableProcessTypes = processTypes.filter((w) =>
      maintenanceType.processTypesLinks.every((pw) => w.id !== pw.processType_id)
    );

    return (
      <PaperForm>
        <div className={myClasses.titleFormSpaceBetween}>
          <Typography component="h1" variant="h6" color="inherit">
            {customLabel("maintenanceType.processTypes")}
          </Typography>
        </div>
        <div className={myClasses.tableContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{customLabel("processType.name")}</TableCell>
                <TableCell className={myClasses.tableCell2Buttons} align="right">
                  <Button color="default" onClick={onSort} disabled={loading}>
                    {customLabel("button.sort")}
                  </Button>
                  <Button color="primary" onClick={onLink} disabled={loading}>
                    {customLabel("button.link")}
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {maintenanceType.processTypesLinks.map((p) => {
                return (
                  <TableRow key={p.id}>
                    <TableCell>{p.name}</TableCell>
                    <TableCell className={myClasses.tableCell1Button} align="right">
                      <Button color="secondary" onClick={() => { onUnlick(p) }} disabled={loading}>{customLabel("button.unlink")}</Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <Dialog open={removeProcessType !== false} keepMounted>
          <DialogTitle>{customLabel("function.confirm").toUpperCase()}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {customLabel("function.confirmUnlink")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={doUnlink} color="primary" disabled={loading}>
              {customLabel("button.confirm")}
            </Button>
            <Button onClick={cancelUnlink} color="default" disabled={loading}>
              {customLabel("button.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={linking} keepMounted fullWidth>
          <DialogTitle>{customLabel("maintenanceType.linkProcessTypes")}</DialogTitle>
          <DialogContent>
            <Autocomplete
              PaperComponent={PaperAutocomplete}
              value={wl}
              onChange={(event, newValue) => {
                setWl(newValue);
              }}
              multiple
              options={availableProcessTypes}
              getOptionLabel={(option) => option.code + " - " + option.name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={customLabel("maintenanceType.processType")}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={doLink} color="primary" disabled={loading}>
              {customLabel("button.confirm")}
            </Button>
            <Button onClick={cancelLink} color="default" disabled={loading}>
              {customLabel("button.close")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={sorting} keepMounted fullWidth={true}>
          <DialogTitle>{customLabel("maintenanceType.sortingProcessTypes")}</DialogTitle>
          <DialogContent>
            {newArray !== false ? (
              <Sortable items={newArray} onChange={onChangeSort} />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={doSort} color="primary" disabled={loading}>
              {customLabel("button.confirm")}
            </Button>
            <Button onClick={cancelSort} color="default" disabled={loading}>
              {customLabel("button.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </PaperForm>
    );
  } else return null;
};

export default ProcessTypesPage;
