import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Input, FormControlLabel, Checkbox, Typography, Table, TableBody, TableCell, TableRow, TableHead, Button, Select, InputLabel,
  FormControl, MenuItem, Dialog, DialogActions, DialogTitle, DialogContent
} from '@material-ui/core';
import { odpSmartSelector, cancelAddVar, addVar, removeVar, saveVar, changeVar, sortVars } from "./slice";
import { customLabel } from "../utility/customLabel";
import Sortable from "../utility/Sortable";
import CheckIcon from '@material-ui/icons/Check';
import { mesStyles } from "../utility/ultrafabStyles";
import DialogConfirmAction from '../utility/DialogConfirmAction';

const Vars = () => {
  const myClasses = mesStyles();
  const dispatch = useDispatch();
  const { processes, processVar } = useSelector(odpSmartSelector)
  const [deleting, setDeleting] = React.useState(false);
  const [sorting, setSorting] = React.useState(false);
  const [newArray, setNewArray] = React.useState(false);

  const onSort = (p) => {
    setNewArray(p.vars);
    setSorting(p);
  };

  const cancelSort = () => {
    setNewArray(false);
    setSorting(false);
  };

  const doSort = () => {
    dispatch(sortVars({ process: sorting, vars: newArray }))
    setNewArray(false);
    setSorting(false);
  };

  const onChangeSort = (newArray) => {
    setNewArray(newArray);
  };

  const onDelete = (process, processVar) => {
    setDeleting({ process: process, var: processVar })
  }

  const cancelDelete = () => {
    setDeleting(false)
  }

  const doDelete = () => {
    dispatch(removeVar({ process: deleting.process, var: deleting.var }));
    setDeleting(false)
  }

  const onChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked
    }
    dispatch(changeVar({ name: name, value: value }));
  }

  const save = () => {
    dispatch(saveVar())
  }

  //controlla che non esista già una variabile con lo stesso nome
  const checkVarName = () => {
    let nameExist = false;
    for (let i = 0; i < processVar.process.vars.length; i++) {
      if (processVar.name === processVar.process.vars[i].name) {
        nameExist = true;
      }
    }
    if (processVar.name === "" || nameExist) {
      return true;
    } else {
      return false;
    }
  }
  const checkVarCreate = () => {
    let nameExist = false;
    for (let i = 0; i < processVar.process.vars.length; i++) {
      if (processVar.name === processVar.process.vars[i].name) {
        nameExist = true;
      }
    }
    if (processVar.name === "" || processVar.type === "" || nameExist) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div>
      {processes.map(p => {
        return (
          <div key={p.sortOrder}>
            <div className={myClasses.titleFormSpaceBetween}>
              <Typography variant="h6">{p.name}</Typography>
            </div>
            <div className={myClasses.tableContainer}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{customLabel("processType.name")}</TableCell>
                    <TableCell>{customLabel("processType.var.type")}</TableCell>
                    <TableCell>{customLabel("processType.serial")}</TableCell>
                    <TableCell align="right" className={myClasses.tableCell2Buttons}>
                      <Button onClick={() => dispatch(addVar(p))} color="primary">{customLabel("button.add")}</Button>
                      <Button onClick={() => onSort(p)} color="default" disabled={p.vars.length === 0}>{customLabel("button.sort")}</Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {p.vars.map(c => {
                    return (
                      <TableRow key={p.sortOrder + "-" + c.name + "-" + c.type}>
                        <TableCell>{c.measure !== "" && c.measure !== null ? c.name + " (" + c.measure + ")" : c.name}</TableCell>
                        <TableCell>{c.type === "boolean" ? customLabel("function.boolean") : c.type === "number" ? customLabel("function.number") : customLabel("function.string")}</TableCell>
                        <TableCell>{c.serial ? <CheckIcon /> : null}</TableCell>
                        <TableCell className={myClasses.tableCell1Button} align="right">
                          <Button onClick={() => { onDelete(p, c) }} color="secondary">{customLabel("button.delete")}</Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                  }
                </TableBody>
              </Table>
            </div>
          </div>
        )
      })}
      {processVar !== false ? (
        <Dialog open={processVar !== false ? true : false} keepMounted maxWidth={"sm"} fullWidth={true}>
          <DialogTitle>{customLabel("processType.var.var")}</DialogTitle>
          <DialogContent>
            <form className={myClasses.dialogForm} noValidate autoComplete="off">
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="name">{customLabel("processType.name")}</InputLabel>
                <Input name="name" value={processVar.name} autoFocus onChange={onChange} error={checkVarName()} />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="type">{customLabel("processType.var.type")}</InputLabel>
                <Select name="type" value={processVar.type} onChange={onChange} error={processVar.type === ""}>
                  <MenuItem value="boolean">{customLabel("function.boolean")}</MenuItem>
                  <MenuItem value="string">{customLabel("function.string")}</MenuItem>
                  <MenuItem value="number">{customLabel("function.number")}</MenuItem>
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="measure">{customLabel("processType.measure")}</InputLabel>
                <Input name="measure" value={processVar.measure} onChange={onChange} />
              </FormControl>
              <FormControl margin="normal">
                <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="serial" onChange={onChange} checked={processVar.serial} />}
                  label={customLabel("processType.serial")} />
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={save} color="primary" disabled={checkVarCreate()} >
              {customLabel("button.save")}
            </Button>
            <Button onClick={() => dispatch(cancelAddVar())} color="default">{customLabel("button.close")}</Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <DialogConfirmAction flag={deleting} text={customLabel("function.confirmDelete")}
        confirm={doDelete} cancel={cancelDelete} disabled={false} />
      <Dialog open={sorting ? true : false} keepMounted fullWidth>
        <DialogTitle>{customLabel("function.sort")}</DialogTitle>
        <DialogContent>
          {newArray !== false ? (
            <Sortable items={newArray} onChange={onChangeSort} />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={doSort} color="primary">
            {customLabel("button.confirm")}
          </Button>
          <Button onClick={cancelSort} color="default">
            {customLabel("button.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Vars;