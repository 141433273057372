import React from "react";
import { Link } from "react-router-dom";
import { FormControl, InputLabel, Input, Divider, Button, Typography, Select, MenuItem, FormControlLabel, Checkbox, TextField } from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import { buttonType } from "./utility";

export const Form = ({ buttonedit, handleChange, cancelEdit, save, loading }) => {
    const myClasses = mesStyles();

    return (
        <PaperForm>
            <div className={myClasses.titleFormSpaceBetween}>
                <Typography component="h1" variant="h6" color="inherit">{customLabel("buttons.button")}</Typography>
                <LockOpenIcon />
            </div>
            <form className={myClasses.formContainer} noValidate autoComplete="off">
                <FormControl className={myClasses.form50}>
                    <InputLabel htmlFor="name">{customLabel("buttons.name")}</InputLabel>
                    <Input autoFocus name="name" error={buttonedit.name === ""} value={buttonedit.name} onChange={handleChange} />
                </FormControl>
                <FormControl className={myClasses.form50}>
                    <InputLabel htmlFor="type">{customLabel("buttons.type.type")}</InputLabel>
                    <Select name="type" value={buttonedit.type} onChange={handleChange} error={buttonedit.type === ""}>
                        <MenuItem value={buttonType.rest}>{customLabel("buttons.rest")}</MenuItem>
                        <MenuItem value={buttonType.newWindow}>{customLabel("buttons.newWindow")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl className={myClasses.form50}>
                    <InputLabel htmlFor="api">{customLabel("buttons.api")}</InputLabel>
                    <Input name="api" error={buttonedit.api === ""} value={buttonedit.api} onChange={handleChange} />
                </FormControl>
                <Divider component="br" />
                <FormControl margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="sendProcess" checked={buttonedit.sendProcess} onChange={handleChange} />}
                        label={customLabel("configButton.sendProcess")} />
                </FormControl>
                <FormControl margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="sendUser" checked={buttonedit.sendUser} onChange={handleChange} />}
                        label={customLabel("configButton.sendUser")} />
                </FormControl>
                <FormControl margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="sendOdp" checked={buttonedit.sendOdp} onChange={handleChange} />}
                        label={customLabel("configButton.sendOdp")} />
                </FormControl>
                <FormControl margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="sendCustomerOrder" checked={buttonedit.sendCustomerOrder} onChange={handleChange} />}
                        label={customLabel("configButton.sendCustomerOrder")} />
                </FormControl>
                <FormControl margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="sendMachineEvent" checked={buttonedit.sendMachineEvent} onChange={handleChange} />}
                        label={customLabel("configButton.sendMachineEvent")} />
                </FormControl>
                <FormControl margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="sendMachine" checked={buttonedit.sendMachine} onChange={handleChange} />}
                        label={customLabel("configButton.sendMachine")} />
                </FormControl>
                <FormControl margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="sendUserEvent" checked={buttonedit.sendUserEvent} onChange={handleChange} />}
                        label={customLabel("configButton.sendUserEvent")} />
                </FormControl>
                <br />
                <FormControl margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="showInOdp" checked={buttonedit.showInOdp} onChange={handleChange} />}
                        label={customLabel("configButton.showInOdp")} />
                </FormControl>
                <FormControl margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="showInStapling" checked={buttonedit.showInStapling} onChange={handleChange} />}
                        label={customLabel("configButton.showInStapling")} />
                </FormControl>
                <FormControl margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="showInMaintenance" checked={buttonedit.showInMaintenance} onChange={handleChange} />}
                        label={customLabel("configButton.showInMaintenance")} />
                </FormControl>
                <br />
                <FormControl margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="askConfirmation" checked={buttonedit.askConfirmation} onChange={handleChange} />}
                        label={customLabel("configButton.askConfirmation")} />
                </FormControl>
                <FormControl margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="askValue" checked={buttonedit.askValue} onChange={handleChange} />}
                        label={customLabel("configButton.askValue")} />
                </FormControl>
                <FormControl margin="normal" disabled={buttonedit.askValue ? false : true}>
                    <InputLabel htmlFor="name">{customLabel("configButton.nameValue")}</InputLabel>
                    <Input name="nameValue" value={buttonedit.nameValue} error={buttonedit.askValue && buttonedit.nameValue === ""} onChange={handleChange} />
                </FormControl>
                <Divider component="br" />
                <FormControl className={myClasses.form100}>
                    <TextField
                        variant="outlined" name="note" multiline label={customLabel("function.note")} rows={4}
                        value={buttonedit.note !== null ? buttonedit.note : ""}
                    />
                </FormControl>
            </form>
            <Divider />
            <div className={myClasses.buttonsFormUpdate}>
                <Button focusRipple onClick={save}
                    disabled={buttonedit.name === "" || buttonedit.type === "" || buttonedit.api === "" || loading} color="primary">{customLabel("button.save")}</Button>
                {buttonedit.id !== "new" ? (
                    <Button focusRipple onClick={cancelEdit} disabled={loading}>{customLabel("button.cancel")}</Button>
                ) : (
                    <Button component={Link} to={`/admin/buttons`} disabled={loading}>{customLabel("button.back")}</Button>
                )}
            </div>
        </PaperForm >
    );
};