import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { maintenanceTypesSelector, removeMachineAPI, addMachinesAPI, selectMachine, changeMachine, updateMachineAPI } from "./slice";
import { machinesSelector, getFullListAPI as getMachines } from "../machines/slice";
import { workcentresSelector, getListAPI as getWorkcentres } from "../workcentres/slice";
import { Typography, Button, Input, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Tabs, Tab, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import PaperAutocomplete from "../utility/PaperAutocomplete";

const MachinesPage = ({ maintenanceType, loading }) => {
  const dispatch = useDispatch();
  const myClasses = mesStyles();
  const { machine } = useSelector(maintenanceTypesSelector);
  const { machines } = useSelector(machinesSelector);
  const { workcentres } = useSelector(workcentresSelector);
  const [removeMachine, setRemoveMachine] = React.useState(false);
  const [linking, setLinking] = React.useState(false);
  const [wl, setWl] = React.useState([]);
  const [ml, setMl] = React.useState([]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getMachines(0, 100000, "name", "asc", false, false));
    dispatch(getWorkcentres(0, 100000, "name", "asc", false, false));
  }, [dispatch]);

  const onUnlick = (w) => {
    setRemoveMachine(w);
  };
  const onConfigure = (w) => {
    dispatch(selectMachine(w))
  };
  const cancelConfigure = () => {
    dispatch(selectMachine(false))
  };

  const updateMachine = () => {
    dispatch(updateMachineAPI(maintenanceType, machine))
  }

  const onChangeConfigure = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    dispatch(changeMachine({ name: name, value: value, vars: machine.machine.vars }));
  }

  const cancelUnlink = () => {
    setRemoveMachine(false);
  };

  const doUnlink = () => {
    dispatch(removeMachineAPI(maintenanceType, removeMachine));
    setRemoveMachine(false);
  };

  const onLink = () => {
    setLinking(true);
  };

  const cancelLink = () => {
    setLinking(false);
    setWl([]);
  };

  const doLink = () => {
    dispatch(addMachinesAPI(maintenanceType, ml, wl));
    setLinking(false);
    setWl([]);
    setMl([]);
  };

  if (machines !== false && workcentres !== false) {
    const availableMachines = machines.filter((w) =>
      maintenanceType.machinesLinks.every((pw) => w.id !== pw.machine.id)
    );

    return (
      <PaperForm>
        <div className={myClasses.titleFormSpaceBetween}>
          <Typography component="h1" variant="h6" color="inherit">{customLabel("machine.machines")}</Typography>
        </div>
        <div className={myClasses.tableContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{customLabel("workcentre.workcentre")}</TableCell>
                <TableCell>{customLabel("machine.name")}</TableCell>
                <TableCell>{customLabel("machine.code")}</TableCell>
                <TableCell>{customLabel("maintenanceType.deadlineType")}</TableCell>
                <TableCell>{customLabel("maintenanceType.value")}</TableCell>
                <TableCell onClick={onLink} className={myClasses.tableCell1Button} align="right">
                  <Button color="primary" disabled={loading}>{customLabel("button.link")}</Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {maintenanceType.machinesLinks.map((w) => {
                return (
                  <TableRow key={w.id}>
                    <TableCell>{w.machine.workcentre.name}</TableCell>
                    <TableCell>{w.machine.name}</TableCell>
                    <TableCell>{w.machine.code}</TableCell>
                    <TableCell>{w.deadlineType !== null && w.deadlineType !== "" ? customLabel("maintenanceType." + w.deadlineType) : null}</TableCell>
                    <TableCell>{w.deadlineType === "frequency" ?
                      w.frequency :
                      w.deadlineType === "workingHours" ?
                        w.workingHours :
                        w.deadlineType === "varValue" ?
                          w.var.name + " > " + w.varValue
                          : null}
                    </TableCell>
                    <TableCell className={myClasses.tableCell2Buttons} align="right">
                      <Button onClick={() => { onConfigure(w) }} color="primary" disabled={loading}>{customLabel("button.configure")}</Button>
                      <Button onClick={() => { onUnlick(w) }} color="secondary" disabled={loading}>{customLabel("button.unlink")}</Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <Dialog open={removeMachine !== false} keepMounted>
          <DialogTitle>{customLabel("function.confirm").toUpperCase()}</DialogTitle>
          <DialogContent><DialogContentText>{customLabel("function.confirmUnlink")}</DialogContentText></DialogContent>
          <DialogActions>
            <Button onClick={doUnlink} color="primary" disabled={loading}>{customLabel("button.confirm")}</Button>
            <Button onClick={cancelUnlink} color="default" disabled={loading}>{customLabel("button.cancel")}</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={linking} keepMounted fullWidth>
          <DialogTitle>{customLabel("maintenanceType.linkMachines")}</DialogTitle>
          <DialogContent>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="scrollable" scrollButtons="auto">
              <Tab label={customLabel("maintenanceType.findMachines")} />
              <Tab label={customLabel("maintenanceType.findByWorkcentres")} />
            </Tabs>
            <br />
            {value === 0 ? (
              <Autocomplete
                PaperComponent={PaperAutocomplete}
                value={ml} onChange={(event, newValue) => { setMl(newValue) }} multiple options={availableMachines}
                getOptionLabel={(option) => option.code + " - " + option.name + " (" + option.workcentre.code + ")"} filterSelectedOptions
                renderInput={(params) => (<TextField {...params} label={customLabel("machine.machine")} error={ml.length === 0} />)} />
            ) : value === 1 ? (
              <Autocomplete
                PaperComponent={PaperAutocomplete}
                value={wl} onChange={(event, newValue) => { setWl(newValue) }} multiple options={workcentres}
                getOptionLabel={(option) => option.code + " - " + option.name} filterSelectedOptions
                renderInput={(params) => (<TextField {...params} label={customLabel("workcentre.workcentre")} error={wl.length === 0} />)} />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={doLink} color="primary" disabled={(wl.length === 0 && ml.length === 0) || loading}>{customLabel("button.confirm")}</Button>
            <Button onClick={cancelLink} color="default" disabled={loading}>{customLabel("button.close")}</Button>
          </DialogActions>
        </Dialog>

        {machine ? (
          <Dialog open={machine !== false} keepMounted maxWidth="sm" fullWidth>
            <DialogTitle>{customLabel("button.configure")} - {machine.machine.name}</DialogTitle>
            <DialogContent>
              <form className={myClasses.dialogForm} noValidate autoComplete="off">
                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="type">{customLabel("maintenanceType.deadlineType")}</InputLabel>
                  <Select name="deadlineType" value={machine.deadlineType !== null ? machine.deadlineType : ""} onChange={onChangeConfigure}>
                    <MenuItem value="frequency">{customLabel("maintenanceType.frequency")}</MenuItem>
                    <MenuItem value="workingHours">{customLabel("maintenanceType.workingHours")}</MenuItem>
                    <MenuItem value="varValue">{customLabel("maintenanceType.varValue")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl margin="normal" fullWidth className={machine.deadlineType === null || machine.deadlineType !== "frequency" ? myClasses.hiddenForm : null}>
                  <InputLabel htmlFor="frequency">{customLabel("maintenanceType.frequencyDays")}</InputLabel>
                  <Input name="frequency" type="number" value={machine.frequency !== null ? machine.frequency : ""} autoFocus onChange={onChangeConfigure} error={machine.deadlineType === "frequency" && machine.frequency === ""} />
                </FormControl>
                <FormControl margin="normal" fullWidth className={machine.deadlineType === null || machine.deadlineType !== "workingHours" ? myClasses.hiddenForm : null}>
                  <InputLabel htmlFor="workingHours">{customLabel("maintenanceType.workingHours")}</InputLabel>
                  <Input name="workingHours" type="number" value={machine.workingHours ? machine.workingHours : ""} autoFocus onChange={onChangeConfigure} error={machine.deadlineType === "workingHours" && machine.workingHours === ""} />
                </FormControl>
                <FormControl margin="normal" fullWidth className={machine.deadlineType === null || machine.deadlineType !== "varValue" ? myClasses.hiddenForm : null}>
                  <InputLabel htmlFor="workingHours">{customLabel("maintenanceType.var")}</InputLabel>
                  <Select name="var" value={machine.var !== null && machine.var.id !== null ? machine.var.id : ""} onChange={onChangeConfigure} error={machine.deadlineType === "varValue" && (machine.var === null || machine.var.id === null)} >
                    {machine.machine.vars.map((v) => {
                      return <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>
                <FormControl margin="normal" fullWidth className={machine.deadlineType === null || machine.deadlineType !== "varValue" ? myClasses.hiddenForm : null}>
                  <InputLabel htmlFor="varValue">{customLabel("maintenanceType.varValue")}</InputLabel>
                  <Input name="varValue" value={machine.varValue ? machine.varValue : ""} autoFocus onChange={onChangeConfigure} error={machine.deadlineType === "varValue" && (machine.varValue === "" || machine.varValue === null)} />
                </FormControl>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={updateMachine} color="primary" disabled={
                machine.deadlineType === null || loading ||
                (machine.deadlineType === "varValue" && (machine.var.id === null || machine.varValue === null || machine.varValue === "")) ||
                (machine.deadlineType === "workingHours" && (machine.workingHours === null || machine.workingHours === "")) ||
                (machine.deadlineType === "frequency" && (machine.frequency === null || machine.frequency === ""))}>
                {customLabel("button.confirm")}
              </Button>
              <Button onClick={cancelConfigure} disabled={loading} color="default">
                {customLabel("button.close")}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </PaperForm>
    );
  }
  return null;
};

export default MachinesPage;
