import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from '../../utility/customLabel';
import {
  operatorViewSelector, changeUserEventType, startUserEventAPI, resetUserEventType
} from "../slice";
import { Button } from "@material-ui/core";
import DialogOperator from "../utility/DialogOperator";
import { opViewStyles } from "../utility/customStyles";

/**
 * 
 * @param {boolean} existChecks di avvio o ripresa a seconda dello stato del process
 * @param {function} onConfirm funzione da chiamare sul conferma
 * @returns 
 */
const UserEventDialog = ({ existChecks, onConfirm }) => {
  const customClasses = opViewStyles();
  const dispatch = useDispatch();
  const { machine, operator, odp, loading, userEventTypes, userEventType,
    flagDialog, process, modeStapling, eventFromStart, errorsUserEventTypes } = useSelector(operatorViewSelector);

  //pulsanti che contengono gli userEventType, sul click valorizza lo stato userEventType
  const handleChangEventType = (id) => {
    dispatch(changeUserEventType({ value: id, events: userEventTypes }));
  };

  const renderDialogContent = () => {
    if (errorsUserEventTypes) {
      return (
        <React.Fragment>
          {customLabel("function.loadingError").toUpperCase()}
        </React.Fragment>
      )
    }
    if (userEventTypes && userEventTypes.length > 0) {
      return (
        userEventTypes.map((e) => {
          return (
            <div key={e.id}>
              <Button value={e.code} className={customClasses.innerButton} color={userEventType.id === e.id ? "primary" : "default"}
                variant={userEventType.id === e.id ? "contained" : "outlined"} onClick={() => handleChangEventType(e.id)} disabled={loading}>
                {e.name}
              </Button>
            </div>

          )
        })
      )
    } else return (
      <React.Fragment>
        {customLabel("function.notAvailable").toUpperCase()}
      </React.Fragment>
    )
  }

  const renderDialogActions = () => {
    return (
      <React.Fragment>
        <Button className={customClasses.dialogButton} variant="contained" onClick={() => handleStartEvent()} disabled={userEventType === false || loading} color="primary">{customLabel("button.confirm")}</Button>
        <Button className={customClasses.dialogButton} variant="contained" onClick={() => handleCloseDialog()} disabled={loading} color="secondary">{customLabel("button.close")}</Button>
      </React.Fragment>
    )
  }
  //API che crea evento utente
  const handleStartEvent = () => {
    if (eventFromStart && existChecks && onConfirm) {
      onConfirm()
    } else {
      if (!modeStapling) {
        dispatch(startUserEventAPI(process, false, operator, userEventType, machine));
      } else {
        dispatch(startUserEventAPI(false, odp, operator, userEventType, machine));
      }
    }
  };
  //chiude dialog di aggiunta del userEventType
  const handleCloseDialog = () => {
    dispatch(resetUserEventType());
  };


  return (
    <DialogOperator flag={flagDialog.flagUserEventType} title={customLabel("operatorView.insertUserEvent")}
      form={() => renderDialogContent()} actions={() => renderDialogActions()} transparentBackround={false} />
  );
}

export default UserEventDialog;