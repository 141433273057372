import React from "react";
import { Link } from "react-router-dom";
import {
  FormControl, InputLabel, Input, Divider, Button, Typography, TextField, Grid
} from "@material-ui/core";
import { customLabel } from "../utility/customLabel";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { bishopFormats } from '../../constants';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Autocomplete } from "@material-ui/lab"
import { PaperForm, mesStyles } from "../utility/ultrafabStyles";
import PaperAutocomplete from "../utility/PaperAutocomplete";

export const Form = ({ customerOrderedit, handleChange, handleChangeDate, cancelEdit, save, customers, articles, config, handleChangeObject, loading, editCustomerAlert }) => {
  const myClasses = mesStyles();

  const onChangeFilterAutocomplete = (name, value, reason) => {
    if (reason === "clear") {
      handleChangeObject(name, false)
    } else {
      handleChangeObject(name, value.id)
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <PaperForm>
            <div className={myClasses.titleFormSpaceBetween}>
              <Typography className={myClasses.note} component="h3" variant="h6" color="inherit">
                {customLabel("customerOrder.customerOrder")}
              </Typography>
              <LockOpenIcon />
            </div>
            <form className={myClasses.formContainer} noValidate autoComplete="off">
              <FormControl className={myClasses.form50}>
                <InputLabel htmlFor="code">{customLabel("customerOrder.code")}</InputLabel>
                <Input autoFocus name="code" error={customerOrderedit.code === ""} value={customerOrderedit.code} onChange={handleChange} />
              </FormControl>
              {customers ? (
                <>
                  <Autocomplete
                    PaperComponent={PaperAutocomplete}
                    style={{ display: "inline-block" }}
                    className={myClasses.form50}
                    value={customerOrderedit.customer.id !== "" ? customerOrderedit.customer : null}
                    getOptionSelected={(option, value) => customerOrderedit.customer.id !== '' ? option.id === value.id : null}
                    onChange={(event, value, reason) => onChangeFilterAutocomplete("customer.id", value, reason)}
                    options={customers}
                    getOptionLabel={(option) => option.code + " - " + option.name}
                    id="customerAutocomplete"
                    renderInput={(params) => <TextField {...params} label={customLabel("customer.customer")} error={customerOrderedit.customer.id === ''} />
                    }
                  />
                  {editCustomerAlert && customerOrderedit.odps.length > 0 ? (
                    <div className={myClasses.form100} style={{ display: 'inline-block' }}>
                      <Typography component="p" variant="body1" color="secondary" align="center">{customLabel("customerOrder.editCustomerAlert")}</Typography>
                    </div>
                  ) : null}
                </>
              ) : null}
              {
                config && config.showArticleOrder ? (
                  <React.Fragment>
                    {articles ? (
                      <Autocomplete
                        PaperComponent={PaperAutocomplete}
                        disabled={customerOrderedit.startDate !== null}
                        style={{ display: "inline-block" }}
                        className={myClasses.form50}
                        value={customerOrderedit.article.id !== "" ? customerOrderedit.article : null}
                        getOptionSelected={(option, value) => customerOrderedit.article.id !== '' ? option.id === value.id : null}
                        onChange={(event, value, reason) => onChangeFilterAutocomplete("article.id", value, reason)}
                        options={articles}
                        getOptionLabel={(option) => option.code + " - " + option.name}
                        id="articleAutocomplete"
                        renderInput={(params) => <TextField {...params} label={customLabel("article.article")} />
                        }
                      />
                    ) : null}
                    <FormControl className={myClasses.form50}>
                      <InputLabel htmlFor="quantity">{customLabel("customerOrder.quantity")}</InputLabel>
                      <Input name="quantity" value={customerOrderedit.quantity} onChange={handleChange} type="number"
                        error={customerOrderedit.quantity < 0 || customerOrderedit.quantity === ""} />
                    </FormControl>
                  </React.Fragment>
                ) : null
              }
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DatePicker className={myClasses.form50}
                  disableToolbar
                  allowKeyboardControl={false}
                  variant="inline"
                  format={bishopFormats.L}
                  margin="normal"
                  autoOk
                  onChange={(date) => handleChangeDate("date", date)}
                  label={customLabel("customerOrder.date")}
                  value={customerOrderedit.date}
                />
              </MuiPickersUtilsProvider>
              {/*    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DatePicker className={myClasses.form50}
                  disableToolbar
                  allowKeyboardControl={false}
                  variant="inline"
                  format={bishopFormats.L}
                  margin="normal"
                  autoOk
                  onChange={(date) => handleChangeDate("deliveryDate", date)}
                  label={customLabel("customerOrder.deliveryDate")}
                  value={customerOrderedit.deliveryDate}
                />
              </MuiPickersUtilsProvider> */}
              <FormControl className={myClasses.form100}>
                <TextField
                  variant="outlined" name="note" label={customLabel("function.note")} multiline rows={2}
                  value={customerOrderedit.note && customerOrderedit.note !== null ? customerOrderedit.note : ""}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl className={myClasses.form100}>
                <TextField
                  variant="outlined" name="note2" label={customLabel("function.operatorViewNote")} multiline rows={2}
                  value={customerOrderedit.note2 && customerOrderedit.note2 !== null ? customerOrderedit.note2 : ""}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl className={myClasses.form100}>
                <TextField
                  variant="outlined" name="note3" label={customLabel("function.odpListNote")} multiline rows={2}
                  value={customerOrderedit.note3 && customerOrderedit.note3 !== null ? customerOrderedit.note3 : ""}
                  onChange={handleChange}
                />
              </FormControl>
            </form>
            <Divider />
            <div className={myClasses.buttonsFormUpdate}>
              <Button focusRipple onClick={save} color="primary"
                disabled={loading || customerOrderedit.code === "" || customerOrderedit.customer.id === "" ||
                  (config && config.showArticleOrder && customerOrderedit.quantity < 0) ||
                  (config && config.showArticleOrder && customerOrderedit.quantity === "")}>
                {customLabel("button.save")}
              </Button>
              {customerOrderedit.id !== "new" ? (
                <Button focusRipple onClick={cancelEdit} disabled={loading}>
                  {customLabel("button.cancel")}
                </Button>
              ) : (
                <Button component={Link} to={`/customerOrders`} disabled={loading}>
                  {customLabel("button.back")}
                </Button>
              )}
            </div>
          </PaperForm>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
