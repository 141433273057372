export const customMaintenancePhaseFilter = {
    mesName: "maintenancePhases",
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "code",
    showFilter: false,
    code: false,
    name: false,
    maintenance: true
}

export const headers = {
    code: { filter: true, type: "string" },
    name: { filter: true, type: "string" },
};