import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  processTypesSelector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create,
  confirmDelete
} from '../processTypes/slice'
import { Box } from "@material-ui/core";
import { Form } from '../processTypes/Form'
import { Details } from '../processTypes/Details'
import UserEventTypesPage from '../processTypes/UserEventTypesPage'
import RolesPage from '../processTypes/RolesPage'
import ChecksPage from '../processTypes/ChecksPage'
import VarsPage from '../processTypes/VarsPage'

const MaintenanceTypePage = ({ match }) => {
  const dispatch = useDispatch()
  const { processType, processTypeedit, editmode, deleting, isCreated, isDeleted, processTypes, loading, reload } = useSelector(processTypesSelector)

  const handleChange = event => {
    let name = event.target.name
    let value = event.target.value
    if (event.target.type === "checkbox") {
      value = event.target.checked
    }
    dispatch(change({ name: name, value: value }));
  };

  const toggleDelete = event => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(processTypeedit))
  }

  const onEdit = () => {
    dispatch(edit())
  }
  const doDelete = () => {
    dispatch(deleteAPI(processType))
  }

  const save = () => {
    if (processType.id === "new") {
      dispatch(createAPI(processTypeedit));
    } else {
      dispatch(updateAPI(processTypeedit));
    }
  };

  useEffect(() => {
    const { id } = match.params
    if (id !== "new") {
      dispatch(getAPI(id))
    } else {
      dispatch(create())
    }
  }, [dispatch, match])

  useEffect(() => {
    if (processType !== false && editmode && !processType.maintenance) {
      dispatch(change({ name: "maintenance", value: true }));
    }
  }, [dispatch, editmode, processType])

  useEffect(() => {
    if (reload === true && loading === false) {
      dispatch(getAPI(processType.id));
    }
  }, [dispatch, reload, loading, processType])

  const renderProcessType = () => {
    if (processType !== false && !editmode && match.params.id === processType.id.toString()) {
      return (
        <div>
          <Details processType={processType} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete} maintenance={true}
            loading={loading} />
          <VarsPage processType={processType} maintenance={true} loading={loading} />
          <ChecksPage processType={processType} maintenance={true} loading={loading} />
          <UserEventTypesPage processType={processType} maintenance={true} loading={loading} />
          <RolesPage processType={processType} loading={loading} />
        </div>
      )
    }
    if (processType !== false && editmode) {
      return <Form processTypeedit={processTypeedit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save}
        processTypes={processTypes} maintenance={true} loading={loading} />
    }
    return
  }

  if (isDeleted && match.params.id === processType.id.toString()) {
    return (<Redirect to={"/admin/maintenancePhases/"} />)
  }

  if (isCreated) {
    return (<Redirect to={"/admin/maintenancePhases/" + processType.id} />)
  }

  return (
    <Box>
      {renderProcessType()}
    </Box>
  )
}

export default MaintenanceTypePage
