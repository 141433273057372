import { createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../../constants";
import { showError, resetErrors as resetNotificationErrors } from "../notifications/slice";
import { setServerNotResponding } from "../main/slice";
import { SERVER_NOT_RESPONDING_MESSAGE } from "../../constants";

//usato per valorizzare lo storico di un controllo, in questo slice si tratta di checkList
//usato in odpAdmin
export const initialState = {
    loading: false,
    hasErrors: false,
    checkList: false,
    reload: false,
    results: 0,
    checkToSee: false,
    filter: {
        mesName: "checksFilter",
        page: 0,
        rowsPerPage: 10,
        odpId: false,
        processId: false,
        checkName: false,
        stapling: false
    },
};

const checksSlice = createSlice({
    name: "checks",
    initialState,
    reducers: {
        resetState: (state) => {
            Object.assign(state, initialState);
        },
        gettingList: (state) => {
            state.loading = true;
            state.reload = false;
            state.hasErrors = false;
        },
        getListSuccess: (state, { payload }) => {
            state.checkList = payload[1];
            state.results = payload[0].total_entries;
            state.loading = false;
        },
        setReload: (state) => {
            state.reload = true;
        },
        enableErrors: (state) => {
            state.loading = false;
            state.hasErrors = true;
            state.reload = false;
        },
        setCheckToSee: (state, { payload }) => {
            state.checkToSee = payload.check;
            state.filter["odpId"] = payload.odpId;
            state.filter["stapling"] = payload.stapling;
            state.filter["processId"] = payload.processId;
            state.filter["checkName"] = payload.check.name;
        },
        unSetCheckToSee: (state) => {
            state.checkToSee = false;
        },
        changeFilter: (state, { payload }) => {
            state.filter[payload.name] = payload.value;
        },
        initFilter: (state) => {
            state.filter = {
                mesName: "checksFilter",
                page: 0,
                rowsPerPage: 10,
                odpId: false,
                processId: false,
                checkName: false,
                stapling: false
            }
        },
    },
});

export const { resetState, gettingList, getListSuccess, setReload, enableErrors, setCheckToSee, unSetCheckToSee, changeFilter, initFilter } = checksSlice.actions;
export const checksSelector = (state) => state.checks;
export default checksSlice.reducer;

export function getListChecksByNameAPI(filter) {
    let url;
    if (!filter.stapling) {
        url = SERVER_URL + "/odp/" + filter.odpId + "/process/" + filter.processId + "/check/name/" + filter.checkName + "/details" +
            "?page=" + filter.page + "&per_page=" + filter.rowsPerPage;
    } else {
        url = SERVER_URL + "/odp/" + filter.odpId + "/stapling/check/name/" + filter.checkName + "/details" +
            "?page=" + filter.page + "&per_page=" + filter.rowsPerPage;
    }
    return async dispatch => {
        dispatch(gettingList());
        try {
            const response = await fetch(url, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            });

            const data = await response.json()
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(getListSuccess(data));
            } else {
                dispatch(resetState());
                dispatch(showError(data.message));
            }
        } catch (e) {
            dispatch(resetState());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    }
};

