import { Box, Paper } from '@material-ui/core/';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const layout = {
    header: {
        fontSize: "1.5em",
        color: "blue"
    },
    footer: {
        fontSize: "0.8em"
    },
    legendSquare: {
        width: "30px",
        height: "15px",
        border: "solid 1px black",
        marginTop: "auto"
    }
};

export const opViewStyles = makeStyles((theme) => ({
    opViewPageContainer: {
        padding: theme.spacing(1),
    },
    grayBackgroundOperator: {
        backgroundColor: "#0000001a",
    },
    leftPaper: {
        margin: "5px",
        minHeight: "710px",
        maxHeight: "710px",
        overflow: "auto"
    },
    middlePaper: {
        margin: "5px",
        minHeight: "710px",
        maxHeight: "710px",
        overflow: "auto",
    },
    rightPaper: {
        margin: "5px",
        borderColor: "rgb(206, 53, 33)",
        minHeight: "710px",
        maxHeight: "710px",
        //overflow: "auto"
    },
    alarmColor: {
        color: theme.palette.secondary.main
    },
    actionButton: {
        margin: "5px",
        width: '100%',
        padding: "15px",
    },
    actionButtonManagePages: {
        margin: "5px",
        padding: "15px",
    },
    buttonContainer: {
        margin: theme.spacing(2),
    },
    listButton: {
        width: "250px",
        height: "100px",
        marginTop: "10px",
        margin: theme.spacing(2),
    },
    dialogButton: {
        padding: "15px",
        fontSize: "15pt !important"
    },
    form50: {
        width: "calc(50% - 32px)",
    },
    innerButton: {
        width: '100%',
        marginTop: "5px",
        padding: "15px",
        fontSize: "15pt !important",
        textTransform: "none"
    },
    innerButton83: {
        width: '83%',
        marginTop: "5px",
        padding: "15px",
        fontSize: "15pt !important",
        textTransform: "none"
    },
    innerButton15: {
        width: '15%',
        marginTop: "5px",
        marginLeft: "15px",
        padding: "15px",
        fontSize: "15pt !important",
        textTransform: "none"
    },
    formAvailableStaplingProcesses: {
        textAlign: "center",
        marginTop: theme.spacing(2)
    },
    listStaplingContainer: {
        height: "450px",
        overflowY: "auto"
    },
    width100: {
        width: "100%"
    },
    containerStaplingProcessesChoice: {
        height: "100px"
    },
    staplingButton: {
        width: '100%',
        marginTop: "5px",
        padding: "10px",
        fontSize: "10pt !important",
        textTransform: "none"
    },
    modeMaintenanceColor: {
        color: "red"
    },
    modeProductionColor: {
        color: "green"
    },
    selectedButtonInput: {
        width: '100%',
        marginTop: "5px",
        padding: "15px",
        fontSize: "15pt !important",
        textTransform: "none",
        marginBottom: '5px'
    },
    listContainerList: {
        maxHeight: "450px",
        overflow: "auto",
    },
    extraOdpsContainer: {
        textAlign: "center",
        marginTop: "10px"
    },
    titleInputSection: {
        display: "flex",
        justifyContent: "space-between"
    },
    formInputSection: {
        textAlign: "center",
    },
    formNoteManagePages: {
        marginTop: theme.spacing() / 2
    },
    divTitleManagePages: {
        textAlign: "center"
    },
    booleanForm: {
        marginTop: "20px",
        display: "flex",
        justifyContent: "space-around"
    },
    unmountButton: {
        width: "250px",
        height: "100px",
        marginTop: "10px",
        margin: theme.spacing(2),
    },
    tableCellBreak100: {
        wordWrap: 'break-word',
        maxWidth: "100px",
        minWidth: "100px",
    },
    tableCellBreak110: {
        wordWrap: 'break-word',
        maxWidth: "110px",
        minWidth: "110px",
    },
    tableCellBreak130: {
        wordWrap: 'break-word',
        maxWidth: "130px",
        minWidth: "130px",
    },
    tableCellBreak140: {
        wordWrap: 'break-word',
        maxWidth: "140px",
        minWidth: "140px",
    },
    tableCellBreak210: {
        wordWrap: 'break-word',
        maxWidth: "210px",
        minWidth: "210px",
    },
    okCTColor: {
        color: "green"
    },
    koCTColor: {
        color: "red"
    },
}));

export const PaperForm = withStyles((theme) => ({
    root: {
        margin: theme.spacing() / 2,
        padding: theme.spacing() / 2,
        overflowX: "auto"
    }
}))(Paper);

export const BoxForm = withStyles((theme) => ({
    root: {
        margin: theme.spacing() / 2,
    }
}))(Box);

