import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { measureToolsSelector, } from "../measureTools/slice";
import { odpSmartSelector, setCurrentProcessForLinking, setCurrentProcessForUnlinking, unsetCurrentProcess, confirmLink, confirmUnlink } from "./slice";
import { Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { mesStyles } from "../utility/ultrafabStyles";
import PaperAutocomplete from "../utility/PaperAutocomplete";

const MeasureTools = () => {
  const myClasses = mesStyles();
  const dispatch = useDispatch();
  const { processes, availableTools } = useSelector(odpSmartSelector)
  const { measureTools } = useSelector(measureToolsSelector);
  const [removeMeasureTool, setRemoveMeasureTool] = React.useState(false);
  const [linking, setLinking] = React.useState(false);
  const [wl, setWl] = React.useState([]);

  const onUnlick = (w, p) => {
    setRemoveMeasureTool(w);
    dispatch(setCurrentProcessForUnlinking({ process: p, measureTool: w }))
  };

  const doUnlink = () => {
    dispatch(confirmUnlink());
    setRemoveMeasureTool(false);
  };

  const cancelUnlink = () => {
    setRemoveMeasureTool(false);
    dispatch(unsetCurrentProcess());
  };

  const onLink = (p) => {
    setLinking(true);
    dispatch(setCurrentProcessForLinking({ process: p, measureTools: measureTools }))
  };

  const doLink = (w) => {
    setLinking(false);
    setWl([]);
    dispatch(confirmLink(w));
  };

  const cancelLink = () => {
    setLinking(false);
    setWl([]);
    dispatch(unsetCurrentProcess());
  };

  return (
    <div>
      {processes.map(p => {
        return (
          <div key={p.sortOrder}>
            <div className={myClasses.titleFormSpaceBetween}>
              <Typography component="h1" variant="h6" color="inherit">{p.name}</Typography>
            </div>
            <div className={myClasses.tableContainer}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{customLabel("measureTool.code")}</TableCell>
                    <TableCell>{customLabel("measureTool.name")}</TableCell>
                    <TableCell>{customLabel("measureTool.serial")}</TableCell>
                    <TableCell className={myClasses.tableCell1Button} align="right">
                      <Button onClick={() => onLink(p)} color="primary">{customLabel("button.link")}</Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {p.measureTools.map((w) => {
                    return (
                      <TableRow key={p.sortOrder + "-" + w.measureTool_id}>
                        <TableCell>{w.code}</TableCell>
                        <TableCell>{w.name}</TableCell>
                        <TableCell>{w.serial}</TableCell>
                        <TableCell align="right" className={myClasses.tableCell1Button}>
                          <Button onClick={() => onUnlick(w, p)} color="secondary">
                            {customLabel("button.unlink")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        )
      })}
      <Dialog open={removeMeasureTool ? true : false} keepMounted>
        <DialogTitle>{customLabel("function.confirm").toUpperCase()}</DialogTitle>
        <DialogContent><DialogContentText>{customLabel("function.confirmUnlink")}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => doUnlink()} color="primary">{customLabel("button.confirm")}</Button>
          <Button onClick={cancelUnlink} color="default">{customLabel("button.cancel")}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={linking ? true : false} keepMounted fullWidth>
        <DialogTitle>{customLabel("processType.linkMeasureTools")}</DialogTitle>
        <DialogContent>
          <div className={myClasses.dialogForm}>
            <Autocomplete PaperComponent={PaperAutocomplete}
              value={wl} onChange={(event, newValue) => { setWl(newValue) }} multiple
              options={availableTools ? availableTools : []}
              getOptionLabel={(option) => option.name} filterSelectedOptions
              renderInput={(params) => (<TextField {...params} label={customLabel("measureTool.measureTool")} error={wl.length === 0} />)} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => doLink(wl)} color="primary" disabled={wl.length === 0}>{customLabel("button.confirm")}</Button>
          <Button onClick={cancelLink} color="default">{customLabel("button.close")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MeasureTools;
