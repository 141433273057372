import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { operatorViewSelector, getDocumentsAPI } from "../slice";
import { customLabel } from '../../utility/customLabel';
import { Box } from "@material-ui/core";
import useInterval from 'react-useinterval';
import { MACHINES, ARTICLES, PROCESSES, ODPS, PROCESS_TYPES, MAINTENANCE_TYPES } from "../utility/constants";
import DocumentsTable from "../docs/DocumentsTable";
import { INDEX_INTERFACE } from "../utility/constants";

const OperatorDocPage = () => {
  const dispatch = useDispatch();
  const { documentsMachine, documentsProcessType, documentsArticle, documentsMaintenance, documentsOdp,
    documentsProcess, modeMaintenance, process, odp, machine, index, reloadDocMachine, reloadDocArticle, reloadDocProcess,
    reloadDocProcessType, reloadDocOdp, documentsErrors, reloadDocMaintenance, userEventTypes, errors, loadingDocs, modeStapling } = useSelector(operatorViewSelector);

  useEffect(() => {
    //documenti lavorazione normale
    if (!modeStapling) {
      //API per caricare i documenti di macchina, process, article, e se previsto manutenzione
      if (index === INDEX_INTERFACE && !documentsMachine && !loadingDocs && !errors && !documentsErrors && userEventTypes) {
        dispatch(getDocumentsAPI(machine.id, MACHINES, false, false))
      };
      if (index === INDEX_INTERFACE && !documentsMaintenance && documentsMachine && !loadingDocs && !errors && !documentsErrors && modeMaintenance) {
        dispatch(getDocumentsAPI(odp.maintenanceType.id, MAINTENANCE_TYPES, false, false))
      };
      if (index === INDEX_INTERFACE && !documentsProcessType && documentsMachine && !loadingDocs && !errors && !documentsErrors && !modeMaintenance) {
        dispatch(getDocumentsAPI(process.type.id, PROCESS_TYPES, false, false))
      };
      if (index === INDEX_INTERFACE && !documentsArticle && documentsProcessType && !loadingDocs && !errors && !documentsErrors && !modeMaintenance) {
        dispatch(getDocumentsAPI(process.article.id, ARTICLES, false, false))
      };
      if (index === INDEX_INTERFACE && !documentsProcess && documentsArticle && !loadingDocs && !errors && !documentsErrors && !modeMaintenance) {
        dispatch(getDocumentsAPI(process.id, PROCESSES, false, false))
      };
      if (index === INDEX_INTERFACE && !documentsOdp && documentsProcess && !loadingDocs && !errors && !documentsErrors && !modeMaintenance) {
        dispatch(getDocumentsAPI(odp.id, ODPS, false, false))
      };
    };
  }, [dispatch, documentsOdp, documentsProcess, documentsArticle, documentsMaintenance, documentsMachine, documentsProcessType, errors, loadingDocs, process,
    modeMaintenance, userEventTypes, odp, machine, documentsErrors, index, modeStapling]);

  useEffect(() => {
    //documenti per pinzatura
    if (modeStapling && odp) {
      //API per caricare i documenti di macchina, process, article, e se previsto manutenzione
      if (index === INDEX_INTERFACE && !documentsMachine && !loadingDocs && !errors && !documentsErrors) {
        dispatch(getDocumentsAPI(machine.id, MACHINES, false, false))
      };
    };
  }, [dispatch, machine, modeStapling, odp, documentsMachine, index, loadingDocs, errors, documentsErrors])

  //aggiornamento documenti
  useInterval(() => reloadingDocMachine(), 20000);
  useInterval(() => reloadingDocMaintenance(), 20000);
  useInterval(() => reloadingDocProcessType(), 20000);
  useInterval(() => reloadingDocProcess(), 20000);
  useInterval(() => reloadingDocArticle(), 20000);
  useInterval(() => reloadingDocOdp(), 20000);
  //useInterval(() => dispatch(resetDocuments()), 5000)

  //metodi per aggiornamento documenti
  const reloadingDocMachine = () => {
    if (!reloadDocMachine && index === INDEX_INTERFACE && documentsOdp && !documentsErrors) {
      dispatch(getDocumentsAPI(machine.id, MACHINES, true, false))
    }
  }
  const reloadingDocMaintenance = () => {
    if (!reloadDocMaintenance && index === INDEX_INTERFACE && documentsOdp && !documentsErrors && modeMaintenance) {
      dispatch(getDocumentsAPI(odp.maintenanceType.id, MAINTENANCE_TYPES, true, false))
    }
  }
  const reloadingDocProcessType = () => {
    if (!reloadDocProcessType && index === INDEX_INTERFACE && documentsOdp && !documentsErrors && !modeMaintenance) {
      dispatch(getDocumentsAPI(process.type.id, PROCESS_TYPES, true, false))
    }
  }
  const reloadingDocArticle = () => {
    if (!reloadDocArticle && index === INDEX_INTERFACE && documentsOdp && !documentsErrors && !modeMaintenance) {
      dispatch(getDocumentsAPI(process.article.id, ARTICLES, true, false))
    }
  }
  const reloadingDocProcess = () => {
    if (!reloadDocProcess && index === INDEX_INTERFACE && documentsOdp && !documentsErrors && !modeMaintenance) {
      dispatch(getDocumentsAPI(process.id, PROCESSES, true, false))
    }
  }
  const reloadingDocOdp = () => {
    if (!reloadDocOdp && index === INDEX_INTERFACE && documentsOdp && !documentsErrors && !modeMaintenance) {
      dispatch(getDocumentsAPI(odp.id, ODPS, true, false))
    }
  }

  const renderDocuments = () => {
    return (
      <React.Fragment>
        {machine ? (
          <DocumentsTable title={customLabel("machine.machine")} documents={documentsMachine} parentId={machine.id} parentType={MACHINES} />
        ) : null}
        {modeMaintenance && odp && odp.maintenanceType ? (
          <DocumentsTable title={customLabel("maintenance.maintenance")} documents={documentsMaintenance} parentId={odp.maintenanceType.id} parentType={MAINTENANCE_TYPES} />
        ) : null}
        {!modeMaintenance && process && process.type ? (
          <DocumentsTable title={customLabel("processType.processType")} documents={documentsProcessType} parentId={process.type.id} parentType={PROCESS_TYPES} />
        ) : null}
        {!modeMaintenance && process && process.article ? (
          <DocumentsTable title={customLabel("article.article")} documents={documentsArticle} parentId={process.article.id} parentType={ARTICLES} />
        ) : null}
        {!modeMaintenance && process ? (
          <DocumentsTable title={customLabel("process.process")} documents={documentsProcess} parentId={process.id} parentType={PROCESSES} />
        ) : null}
        {!modeMaintenance && odp ? (
          <DocumentsTable title={customLabel("odp.odp")} documents={documentsOdp} parentId={odp.id} parentType={ODPS} />
        ) : null}
      </React.Fragment>
    )
  }

  return (
    <Box style={{ maxHeight: "642px", overflow: "auto" }}>
      {renderDocuments()}
    </Box>
  )
}
export default OperatorDocPage;
