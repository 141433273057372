//STANDARD
export const LOADING_MACHINE_EVENTS = "loadingMachineEvents";
export const ERRORS_MACHINE_EVENTS = "errorsMachineEvents";
export const LOADING_ODP_EVENTS = "loadingOdpEvents";
export const ERRORS_ODP_EVENTS = "errorsOdpEvents";
export const LOADING_VARS = "loadingVars";
export const ERRORS_VARS = "errorsVars";
export const LOADING_CHECKS = "loadingChecks";
export const ERRORS_CHECKS = "errorsChecks";
export const LOADING_PROCESS_MACHINE_VARS = "loadingProcessMachineVars";
export const ERRORS_PROCESS_MACHINE_VARS = "errorsProcessMachineVars";
export const LOADING_USER_EVENTS = "loadingUserEvents";
export const ERRORS_USER_EVENTS = "errorsUserEvents";
export const LOADING_PROCESS_EVENTS = "loadingProcessEvents";
export const ERRORS_PROCESS_EVENTS = "errorsProcessEvents";
export const LOADING_DR_PROCESS_EVENTS = "loadingDrProcessEvents";
export const ERRORS_DR_PROCESS_EVENTS = "errorsDrProcessEvents";
export const LOADING_COMPONENTS = "loadingComponents";
export const ERROR_COMPONENTS = "errorsComponents";
//PINZATURA
export const LOADING_STAPLING_LIST = "loadingStaplingList";
export const ERRORS_STAPLING_LIST = "errorsStaplingList";
export const LOADING_STAPLING_MACHINE_EVENT = "loadingStaplingMachineEvent";
export const ERRORS_STAPLING_MACHINE_EVENT = "errorsStaplingMachineEvent";
export const LOADING_STAPLING_VARS = "loadingStaplingVars";
export const ERRORS_STAPLING_VARS = "errorsStaplingVars";
export const LOADING_STAPLING_CHECKS = "loadingStaplingChecks";
export const ERRORS_STAPLING_CHECKS = "errorsStaplingChecks";
export const LOADING_STAPLING_MACHINE_VARS = "loadingStaplingMachineVars";
export const ERRORS_STAPLING_MACHINE_VARS = "errorsStaplingMachineVars";
export const LOADING_STAPLING_USER_EVENTS = "loadingStaplingUserEvents";
export const ERRORS_STAPLING_USER_EVENTS = "errorsStaplingUserEvents";
export const LOADING_STAPLING_MACHINE_EVENTS = "loadingStaplingMachineEvents"
export const ERRORS_STAPLING_MACHINE_EVENTS = "errorsStaplingMachineEvents"
export const LOADING_STAPLING_COMPONENTS = "loadingStaplingComponents";
export const ERROR_STAPLING_COMPONENTS = "errorsStaplingComponents";