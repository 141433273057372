import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { maintenanceTypesSelector, getAPI, updateAPI, createAPI, deleteAPI, edit, cancelEdit, change, create, confirmDelete } from "./slice";
import { Box } from "@material-ui/core";
import { Form } from "./Form";
import { Details } from "./Details";
import ProcessTypesPage from "./ProcessTypesPage";
import MachinesPage from "./MachinesPage";
import DocumentsPage from '../documents/DocumentsPage';
import { PaperForm } from "../utility/ultrafabStyles";

const MaintenanceTypePage = ({ match }) => {
  const dispatch = useDispatch();
  const { maintenanceType, maintenanceTypeedit, editmode, deleting, isCreated, isDeleted, loading } = useSelector(maintenanceTypesSelector);

  const handleChange = (event, isCheck) => {
    let name = event.target.name;
    let value = event.target.value;
    dispatch(change({ name: name, value: value, isCheck: isCheck }));
  };

  const toggleDelete = (event) => {
    dispatch(confirmDelete());
  };

  const onCancelEdit = () => {
    dispatch(cancelEdit(maintenanceTypeedit));
  };

  const onEdit = () => {
    dispatch(edit());
  };
  const doDelete = () => {
    dispatch(deleteAPI(maintenanceType));
  };

  const save = () => {
    if (maintenanceType.id === "new") {
      dispatch(createAPI(maintenanceTypeedit));
    } else {
      dispatch(updateAPI(maintenanceTypeedit));
    }
  };

  useEffect(() => {
    const { id } = match.params;
    if (id !== "new") {
      dispatch(getAPI(id));
    } else {
      dispatch(create());
    }
  }, [dispatch, match]);

  const rendermaintenanceType = () => {
    if (maintenanceType !== false && !editmode && match.params.id === maintenanceType.id.toString()) {
      return (
        <div>
          <Details maintenanceType={maintenanceType} edit={onEdit} toggleDelete={toggleDelete} deleting={deleting} doDelete={doDelete} loading={loading} />
          <ProcessTypesPage maintenanceType={maintenanceType} loading={loading} />
          <MachinesPage maintenanceType={maintenanceType} loading={loading} />
          <PaperForm>
            <DocumentsPage parentId={maintenanceType.id} parentType="maintenance_types" extLoading={loading} title={true} size="small" />
          </PaperForm>
        </div>
      );
    }
    if (maintenanceType !== false && editmode) {
      return (
        <Form maintenanceTypeedit={maintenanceTypeedit} handleChange={handleChange} cancelEdit={onCancelEdit} save={save} loading={loading} />
      );
    }
    return;
  };

  if (isDeleted && match.params.id === maintenanceType.id.toString()) {
    return <Redirect to={"/admin/maintenanceTypes/"} />;
  }

  if (isCreated) {
    return <Redirect to={"/admin/maintenanceTypes/" + maintenanceType.id} />;
  }

  return <Box>{rendermaintenanceType()}</Box>;
};

export default MaintenanceTypePage;
